import React from "react"
import ContentLoader from "react-content-loader"

const ServicesSkeletonsSmall = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={400}
        viewBox="0 0 353 600"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >

        {/*left column*/}
        <circle cx="33" cy="81" r="33" />
        <rect x="80" y="68" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="162" r="33" />
        <rect x="80" y="149" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="243" r="33" />
        <rect x="80" y="230" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="324" r="33" />
        <rect x="80" y="311" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="405" r="33" />
        <rect x="80" y="392" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="486" r="33" />
        <rect x="80" y="473" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="567" r="33" />
        <rect x="80" y="554" rx="0" ry="0" width="226" height="25" />

        <circle cx="33" cy="648" r="33" />
        <rect x="80" y="635" rx="0" ry="0" width="226" height="25" />

        {/*right column*/}

        {/*<circle cx="396" cy="81" r="33" />*/}
        {/*<rect x="445" y="68" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="162" r="33" />*/}
        {/*<rect x="445" y="149" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="243" r="33" />*/}
        {/*<rect x="445" y="230" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="324" r="33" />*/}
        {/*<rect x="445" y="311" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="405" r="33" />*/}
        {/*<rect x="445" y="392" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="486" r="33" />*/}
        {/*<rect x="445" y="473" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="567" r="33" />*/}
        {/*<rect x="445" y="554" rx="0" ry="0" width="226" height="25" />*/}

        {/*<circle cx="396" cy="648" r="33" />*/}
        {/*<rect x="445" y="635" rx="0" ry="0" width="226" height="25" />*/}

    </ContentLoader>
)

export default ServicesSkeletonsSmall
