import React, { FC } from 'react';
import './index.scss';
import VerticalIcon from "../CustomsComponents/VerticalIcon/VerticalIcon";
import {useAppSelector} from "../../hooks/redux";

interface IProps {
    currency: string;
    cardName?: string;
    cardPan?: string;
    balance?: string;
    exp?:string;
    cvv?: string;
    options?: string[];
    getSelectOption: (value: string) => void;
    isRequisites: boolean;
}

const aznLogo = require('../../img/azn-logo.svg').default;
const usdLogo = require('../../img/usd-logo.svg').default;

const DigitalCard: FC<IProps> = (props) => {
    const {
        balance,
        cardPan,
        options,
        currency,
        cardName,
        isRequisites,
        getSelectOption,
    } = props;

    const { digitalCard } = useAppSelector(state => state.cardsReducer);

    const getCardCurrencyLogo = () => (
        currency === 'AZN' ? aznLogo : usdLogo
    )

    const getCardIcon = () => {
        if(cardPan?.startsWith('2') || cardPan?.startsWith('5')) {
            return require("../../img/master-silver.svg").default
        } else if(cardPan?.startsWith('4')) {
            return require("../../img/visa-silver.svg").default
        }
    }

    const hiddenPan = `${cardPan?.substring(0, 4)}  ****  ****  ${cardPan?.substring(cardPan?.length - 4)}`


    return (
        <div className='digital-card'>
            <div className="digital-card__header">
                <div className="digital-card__header-currency">
                    <img src={getCardCurrencyLogo()} alt=""/>
                </div>
                <div className="digital-card__title">{cardName}</div>
                <div className="digital-card__header-right">
                    <div className="digital-card__header-logo">
                        <img src={getCardIcon()} alt=""/>
                    </div>
                    <div className="digital-card__icon">
                        <VerticalIcon options={options} getSelectOption={(data: string) => getSelectOption(data)}/>
                    </div>
                </div>

            </div>
            <div className="digital-card__body">
                <div className="digital-card__content">
                    {isRequisites && !digitalCard.isProcessLoading ? digitalCard?.requisites?.full_pan : hiddenPan}
                </div>
            </div>
            <div className="digital-card__footer">
                <div className="digital-card__balance">
                    {balance}
                    <span>{currency}</span>
                </div>
                <div className="digital-card__footer-info">
                    <div className="digital-card__info-box" style={{marginRight: '10px'}}>
                        <div className="digital-card__info-title">EX/DT</div>
                        <div className="digital-card__info-text">
                            {isRequisites && !digitalCard.isProcessLoading ? digitalCard?.requisites?.exp_date : '**/**'}
                        </div>
                    </div>
                    <div className="digital-card__info-box">
                        <div className="digital-card__info-title">CVV</div>
                        <div className="digital-card__cvv">
                            {
                                isRequisites && !digitalCard.isProcessLoading
                                    ? digitalCard?.requisites?.cvv
                                    :   <img src={require("../../img/stars.svg").default} alt=""/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalCard;