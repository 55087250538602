import React from 'react'
import { connect } from 'react-redux'
import { Languages } from '../../redux/reducers/language/languageData'
import "./index.scss";
import { t } from 'i18next'
import Select from 'react-select';

const fixAmount = amount => {
    if (
        amount === null ||
        amount === undefined ||
        amount === '' ||
        amount === false
    ) {
        return ''
    }
    return parseFloat(amount).toFixed(2).toString()
}

class AdditionalFields extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subName: '',
            subVal: '',
            isDebtsModalVisible: false,
            additionalDebtFields: [],
            isActive: false,
            debtValue: '',
            isDebtTotal: false,
            amountValue: '',
            additionalFields: [],
            additionalFieldsBackendReturn:[],
            debtsList: [],
            debtTitle: '',
            maxAmount: ''
        }
    }

    componentDidMount() {
        this.setState({
            ...this.processCheckData(
                this.props.checkData,
                this.props.service,
                this.props.defaultValue,
            ),
        })
    }

    processCheckData(json, service, defaultAmount) {
        const { language:selectedLanguage, langKey } = this.props

        const language = Languages(selectedLanguage)

        const additionalFields = []
        const additionalFieldsBackendReturn = []
        const debtsList = []
        let amountValue = defaultAmount || ''
        let fixedAmount = false
        let cantPayWithoutDebt = false
        let debt = ''
        if (!json || !json.info) {
            return {
                additionalFields,
                additionalFieldsBackendReturn,
                debtsList,
                amountValue,
                fixedAmount,
                debt,
                cantPayWithoutDebt,
            }
        }

        if(json.info?.additionalFields && json.info?.additionalFields?.keyValueString) {
          json.info?.additionalFields?.keyValueString.forEach(item => {
            additionalFieldsBackendReturn.push({
              localName:item?.mutliLangValueString[langKey],
              value:item.value
            })
          });
        }

        if (json.info.person && json.info.person !== '') {
            additionalFields.push({
                localName: language.person,
                value: json.info.person,
            })
            if (json.info.debt && json.info.debt !== 0 && json.info.values.serviceInfoItem.length == 0) {
                const value = service.id == 992 || service.id == 997 ? (json.info.debt/100).toFixed(2) : json.info.debt.toFixed(2)
                debtsList.push({
                    label: value,
                    title: value,
                    id: value,
                    key: value,
                    value: value,
                    fixedAmount: json.info?.fixedAmount,
                })
                this.pickDebt(debtsList[0])
                if (!json.info.debt.toString().includes('-')) {
                    debt = json.info.debt.toString()
                    amountValue = json.info.debt.toString()
                    this.onChange({ amountValue })
                }
                // additionalFields.push({
                //     localName: language.debt,
                //     value: service.id == 992 || service.id == 997 ? (json.info.debt/100).toFixed(2) : json.info.debt.toFixed(2),
                // })
            }
        }
        if (json?.info?.values?.serviceInfoItem.length) {
            for (const i in json?.info.values?.serviceInfoItem) {
                const item = json?.info?.values?.serviceInfoItem[i]
                const amount = (item?.amount / 100).toFixed(2)
                let debt = {
                    id: '',
                    title: '',
                    label: '',
                    value: '',
                    key: '',
                    additionalDebtFields: [],
                    fixedAmount: item.fixedAmount,
                    cantPayWithoutDebt: item.cantPayWithoutDebt,
                    showAmount: item.showAmount,
                }
                debt.label = `${amount} ${item.currency} ${item.localeName[langKey]}`
                debt.value = amount < 0 ? 0 : amount
                debt.key = item?.value
                debt.id = item?.value
                debt.title = item?.title
                debt.additionalDebtFields = item?.additionalInfoBasedOnServiceInfoItem
                debtsList.push(debt)
            }
            amountValue = debtsList[0]?.value || ''
            fixedAmount = debtsList[0]?.fixedAmount
            cantPayWithoutDebt = debtsList[0]?.cantPayWithoutDebt
            this.pickDebt(debtsList[0])
        }
        if (
            service.amountType === 'FIXED' &&
            !json.info.values.serviceInfoItem.length
        ) {
            amountValue = json.info.fixedAmount ? fixAmount(json.info.fixedAmount) : fixAmount(json.info.debt);
            fixedAmount = json.info.fixedAmount ? fixAmount(json.info.fixedAmount) : fixAmount(json.info.debt);
            // this.onChange({ additionalFields: additionalFields, amountValue })
            this.props.onChange({
                additionalFields: additionalFields,
                amountValue,
                isAmountCorrect: true,
            })
        } else {
            amountValue = fixAmount(amountValue)
        }
        debt = fixAmount(debt)
        return {
            additionalFields,
            additionalFieldsBackendReturn,
            debtsList,
            amountValue,
            fixedAmount,
            debt,
            cantPayWithoutDebt,
            isFirstAmount: amountValue ? true : false,
        }
    }

    pickDebt(debt) {
        const maxPay = debt.value
        const amountValue = fixAmount(debt?.value)
        this.setState({
            amountValue,
            debtTitle: amountValue > 0 ? t('selectDebt') : t('selectAdvance'),
            subName: debt?.title,
            subVal: debt?.id,
            maxAmount: amountValue,
            additionalDebtFields: debt?.additionalDebtFields,
            debtValue: debt,
            fixedAmount: debt?.fixedAmount,
            cantPayWithoutDebt: debt?.cantPayWithoutDebt,
            isFirstAmount:
                parseFloat(amountValue) || parseFloat(debt?.fixedAmount) ? true : false,
        })
        this.onChange({
            subName: debt?.title,
            subVal: debt?.id,
            additionalDebtFields: debt?.additionalDebtFields,
            amountValue,
        })
    }

    renderForDebts() {
        return (
            <div className='service_fields-item'>
                <label className='service_fields-item-title'>{this.state.debtTitle}</label>
                <Select
                    aria-label="service_fields"
                    classNamePrefix="custom-select"
                    options={this.state.debtsList}
                    onChange={item => this.pickDebt(item)}
                    value={this.state.debtValue}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.label}
                />
            </div>
        )
    }

    renderTextAdditionalField(localName, value) {
        return (
            <div className='service_fields-item'>
                <p className='service_fields-item-title'>
                    {localName && localName}
                </p>
                <input value={value} readOnly />
            </div>
        )
    }

    renderDebtAdditionalField() {
        const { additionalDebtFields } = this.state
        const { langKey } = this.props
        return (
            <div className='service_fields-item service_debt-additional-field-items'>
                {additionalDebtFields.map((item, index) => {
                    return (
                        <div className='service_debt-additional-field-item' key={index}>
                            <div className='service_debt-additional-field-item-title'>
                                {item.mutliLangValueString[langKey].replace(/<[^>]+>/g, ' ')}
                            </div>
                            <div className='service_debt-additional-field-item-body'>
                                {item.value}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    calculateCommission() {
        const {
            service: { minimalComission, percent },
        } = this.props
        const { amountValue } = this.state
        if (percent === 0) {
            return minimalComission
        } else {
            const commission = Math.round(Number(amountValue) * (percent / 100)) / 100
            if (commission < minimalComission) {
                return minimalComission
            } else return commission
        }
    }

    onCheckBoxPress() {
        this.setState({ isDebtTotal: !this.state.isDebtTotal })
        if (!this.state.isDebtTotal) {
            this.setState({ amountValue: this.state.debt })
            this.onChange({ amountValue: this.state.debt })
        } else {
            this.setState({ amountValue: fixAmount(this.state.fixedAmount) })
            this.onChange({ amountValue: fixAmount(this.state.fixedAmount) })
        }
    }

    onChange(v) {
        if (v.amountValue !== undefined) {
            if (!v.amountValue) {
                v.isAmountCorrect = false
            } else {
                const {
                    service: { minAmount, maxAmount },
                } = this.props
                v.amountValue = fixAmount(v.amountValue)
                const min = (minAmount / 100).toFixed(2)
                let max = (maxAmount / 100).toFixed(2)
                v.isAmountCorrect =
                    Number(v.amountValue) >= Number(min) &&
                    Number(v.amountValue) <= Number(max)
            }
        }
        this.props.onChange(v)
    }

    render() {
        const { language: selectedLanguage } = this.props
        const language = Languages(selectedLanguage)
        const {
            currency,
            groupId,
            service: {
                floatAmount,
                id,
                categoryId,
                minAmount,
                maxAmount,
                minimalComission,
                amountType,
            },
            defaultValue,
            checkData: {
                info: {
                    maxAmount: infoMaxAmount,
                    maxAmountSetByGetInfo,
                    values: {
                        serviceInfoItem
                    }
                }
            }
        } = this.props
        const {
            additionalFields,
            additionalFieldsBackendReturn,
            additionalDebtFields,
            debtsList,
            amountValue,
            isActive,
            fixedAmount,
            debt,
            debtTitle,
            cantPayWithoutDebt,
            isFirstAmount,
        } = this.state

        const min = (minAmount / 100).toFixed(2)
        let max = (maxAmount / 100).toFixed(2)

        if (categoryId === 9 && id === 1275 && (groupId === 31 || groupId === 32)) {
            max = '500'
        }

        const exchangeCurrency = this.props.checkData.info.exchangeCurrency
        const exchangeRate = this.props.checkData.info.exchangeRate
        const exchangeMin = (minAmount*exchangeRate/100).toFixed(2)
        const exchangeMax = (maxAmount*exchangeRate/100).toFixed(2)
        let amountValueExchange = amountValue*exchangeRate

        const getMaxInCheckInfo = infoMaxAmount > 0;
        const getMaxInDebt = infoMaxAmount === 0;
        let correctMax;

        if(maxAmountSetByGetInfo) {
            if(getMaxInCheckInfo) {
                correctMax = (infoMaxAmount / 100).toFixed(2);
            } else {
                correctMax = this.state.maxAmount;
            }
        } else {
            correctMax = Number((maxAmount / 100).toFixed(2))
        }

        return (
            <div className='service_fields'>

                {additionalFields.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className='service_fields-item service_fields-item--mini-margin'
                        >
                            {this.renderTextAdditionalField(item?.localName, item?.value)}
                        </div>
                    )
                })}
                {additionalFieldsBackendReturn.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className='service_fields-item service_fields-item--mini-margin'
                        >
                            {this.renderTextAdditionalField(item?.localName, item?.value)}
                        </div>
                    )
                })}
                {!!debtsList?.length && this.renderForDebts()}
                {!!additionalDebtFields?.length && this.renderDebtAdditionalField()}
                {!debtsList?.length && Number(debt) && !fixAmount(this.state.fixedAmount) ? (
                    <div className='service_fields-item service_fields-item--checkbox service_fields-item--mini-margin'>
                        <label htmlFor='debt-checkbox'>{language.debtTotal}</label>
                        <input
                            id='debt-checkbox'
                            type='checkbox'
                            checked={this.state.isDebtTotal}
                            onChange={() => this.onCheckBoxPress()}
                        />
                    </div>
                ) : <></>}
                {
                    this.props.checkData?.info?.amountType === 'FIXED' ? (
                        <div
                            className='service_fields-item service_fields-item--mini-margin'
                        >
                            {this.renderTextAdditionalField(language.sum + (currency || ' AZN'), amountValue)}
                        </div>
                    ) :
                    <>
                    <div className='service_fields-item'>
                        <div>
                            <p className='service_fields-item-title'>
                                {(amountValue !== '' || isActive) &&
                                    language.sum + (currency || ' AZN')}
                            </p>

                            <input
                                readOnly={this.state?.isDebtTotal}
                                style={
                                    (amountValue && Number(amountValue) < Number(min)) ||
                                    (amountValue && Number(amountValue) > Number((correctMax)))
                                        ? { color: 'red' }
                                        : {}
                                }
                                onFocus={() => this.setState({ isActive: true })}
                                onBlur={() => this.setState({ isActive: false })}
                                onChange={e => {
                                    if (amountType === 'FIXED' || debtsList.length !== 0) {
                                        if (!debtsList.length) {
                                            return
                                        }

                                        if (parseFloat(amountValue).toFixed(2) <= 0) {
                                            if (cantPayWithoutDebt) {
                                                return
                                            }
                                        }

                                        if (cantPayWithoutDebt && fixedAmount) {
                                            return
                                        }

                                        if (isFirstAmount && fixedAmount) {
                                            return
                                        }
                                    }
                                    if (!floatAmount) {
                                        const isFloatInAmount = amountValue.includes('.')
                                        if (
                                            !/^\d+$/.test(e.target.value) &&
                                            e.target.value &&
                                            !isFloatInAmount
                                        ) {
                                            return
                                        }
                                    }
                                    this.setState({ amountValue: e.target.value })
                                    if (e.target.value.trim() === '') {
                                        this.onChange({ amountValue: '' })
                                    } else {
                                        this.onChange({
                                            amountValue: e.target.value.replace(',', '.'),
                                        })
                                    }
                                }}
                                value={amountValue}
                                placeholder={
                                    this.state.isActive
                                        ? ''
                                        : language.sum + ', ' + (currency || 'AZN')
                                }
                            />
                        </div>
                        <div>
                            <span
                                style={
                                    amountValue && Number(amountValue) < Number(min)
                                        ? { color: 'red' }
                                        : {}
                                }
                            >
                            {language.min}
                            </span>{' '}
                            {min} {currency || 'AZN '}
                            <span
                                style={
                                    // amountValue && Number(amountValue) > Number(max)
                                    amountValue && Number(amountValue) > Number(correctMax)
                                        ? { color: 'red' }
                                        : {}
                                }
                            >
                            {language.max}
                            </span>{' '}
                            {correctMax} {currency || 'AZN'}
                        </div>
                        <div>
                            {minimalComission !== 0 &&
                                amountValue !== '' &&
                                Number(amountValue) > 0 && (
                                    <div>
                                        {'+' +
                                            this.calculateCommission() +
                                            ' ' +
                                            (currency || 'AZN') +
                                            ' ' +
                                            language.commission.toLowerCase()}
                                    </div>
                                )}
                        </div>
                    </div>
                    {exchangeCurrency ?
                    <div className='service_fields-item'>
                        <div>
                            <p className='service_fields-item-title'>
                                {language.sum + ' ' + (exchangeCurrency)}
                            </p>
                            <input
                                style={
                                    (amountValue && Number(amountValue) < Number(min)) ||
                                    (amountValue && Number(amountValue) > Number(max))
                                        ? { color: 'red' }
                                        : {}
                                }
                                value={Number(amountValueExchange).toFixed(2)}
                                readOnly
                            />
                        </div>
                        <div>
                            <span
                                style={
                                    amountValue && Number(amountValue) < Number(min)
                                        ? { color: 'red' }
                                        : {}
                                }
                            >
                            {language.min}
                            </span>{' '}
                            {exchangeMin} {exchangeCurrency}
                            <span
                                style={
                                    amountValueExchange && Number(amountValue) > Number(max)
                                        ? { color: 'red' }
                                        : {}
                                }
                            >
                            {' ' + language.max}
                            </span>
                            {' ' + exchangeMax + ' ' + exchangeCurrency}
                        </div>
                    </div> : <></>
                    }
                </>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { language,langKey  } = state.changeLangSlice
    return {
        language, langKey
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFields)
