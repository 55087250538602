import React from 'react';
import CardsBox from '../../CardsBox/CardsBox';

interface CardsSectionProps {
  isDataLoaded: boolean;
  setInstrument: (data: any) => void;
  isHubSolutionPaymentMethod: boolean;
}

const CardsSection: React.FC<CardsSectionProps> = ({ isDataLoaded, setInstrument, isHubSolutionPaymentMethod }) => {
  if (!isDataLoaded) return null;

  return (
    <div className="top-up-balance__cards-box">
      <CardsBox
        setInstrument={setInstrument}
        currentHubSolutionPaymentMethodHidden={!isHubSolutionPaymentMethod}
      />
    </div>
  );
};

export default CardsSection;
