const FavoriteImage = ({favorite}) => {
  return (
    <img
      className="favorites__img"
      src={"https://www.e-pul.az/epul_api/images/" + favorite.friendlyName + ".png"}
      onError={(event) => {event.target.src = 'https://placehold.co/60x60/EF5C27/FFF'}}
      alt="favorite-icon"
    />
  )
}

export default FavoriteImage;