import React from 'react';
import './index.scss'
import Register from "../../Components/RegistrationLeftMain/Register";

const Registration = () => {
    return (
        <div className='auth-page'>
            <Register onlyEntry/>
        </div>
    );
};

export default Registration;