import {IMessages} from "../../models/Messages";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchMessages} from "../actions/messageActions";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitialState {
    sid: string
    error: string;
    messages: IMessages | null
    isLoading: boolean,
    unReadCount: number
}

const initialState: InitialState = {
    sid: "",
    error: '',
    messages: null,
    isLoading: false,
    unReadCount: 0
}

export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchMessages.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchMessages.fulfilled.type]: (state, action: PayloadAction<IMessages>) => {
            state.isLoading = false
            state.error = ""
            state.messages = action.payload
        },
        [fetchMessages.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        }
    }
})

const selectMessagesState = (state: RootState) => state.messages;

export const messagesSelector = createSelector(
    [selectMessagesState],
    (messages) => messages
  );


export default messagesSlice.reducer