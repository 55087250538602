import React, {useState} from 'react';
import './AsanImza.scss'
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import AsideSettings from "../AsideSettings/AsideSettings";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "../../Button/Button";
import {TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {asanLogin} from "../../../redux/actions/authActions";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";

const AsanImzaBind = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [openAsanInfoModal, setOpenAsanInfoModal] = useState(false);
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const goBack = () => navigate(`/epay/${language}/settings`);
    const asanUser = useAppSelector(state => state.authReducer.asanUser)

    console.log("location asan imza info", location)
    const {profile} = useAppSelector(
        (state) => state.authReducer
    );

    const {register, handleSubmit, setError, formState: {errors, isValid}} = useForm({
        defaultValues: {
            asanImzaId: ''
        },
        mode: 'onChange'
    })

    const onSubmit = async (values: { asanImzaId: string }) => {
        const callback = () => setOpenAsanInfoModal(true);
        if (await dispatch(asanLogin({
            phone: profile?.user?.phone,
            asanImzaId: values.asanImzaId
        }, 'bind', callback))) {
            setOpenAsanInfoModal(true)
        } else {
            setOpenAsanInfoModal(true)
        }
    }

    return (
        <LayoutEmpty goBack={goBack} navTitle={t("settings")}>
            <div className="wrap-imza">
                <div className="wrap-imza__body">
                    {/*<NavigationComponent onClick={goBack} title={t("settings")}/>*/}
                    <h2 className="notifications-body__title">{"Asan Imza"}</h2>
                    <div className="wrap-imza__body-content">
                        <div
                            className="wrap-imza__body-content-text">{t("digitalAuthentication")}</div>
                        <div
                            className="wrap-imza__body-content-text">{t("electronicAuthentication")}</div>
                        <div
                            className="wrap-imza__body-content-text">{t("cardContainingDigital")}</div>
                        <a className="wrap-imza__body-content-about" target="_blank"
                           href="https://asanimza.az/asan-imza-ru/2013/what-is-the-asan-imza-2/"
                           rel="noreferrer">{t("readMore")}</a>
                        {!profile?.user?.pincode
                            ? <>
                                <div
                                    className="wrap-imza__body-content-text">{t("canAttachYour")}</div>
                                <div className="wrap-imza__footer">
                                    <div className="wrap-imza__footer-w">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <TextField color="warning"
                                                       variant="standard"
                                                       margin="normal"
                                                       label={`${t("userIDAsan")}`}
                                                       helperText={errors.asanImzaId?.message}
                                                       error={Boolean(errors.asanImzaId?.message)}
                                                       fullWidth {...register('asanImzaId', {required: `${t("enterUserIDAsan")}`})}/>
                                            <div className="wrap-imza__footer-btn">
                                                <Button
                                                    buttonText={`${t("registration")}`}
                                                    minWidth={220}
                                                    background="#EF5C27"
                                                    color="#FFFFFF"
                                                    borderRadius={15}
                                                    padding="14px 42px"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                    </div>
                </div>
                {asanUser?.asanUser?.surname && (
                    <CustomModal active={openAsanInfoModal} setActive={setOpenAsanInfoModal}>
                        <div style={{ display: "flex", flexDirection: "column", width: 450, height: 300, padding: 10}}>
                            <div style={{ display: "flex", justifyContent: "end", marginBottom: "20px", width: "80%", margin: "0 auto"}}>
                                <div style={{cursor: "pointer"}} onClick={() => setOpenAsanInfoModal(false)}>
                                    <img src={require("../../../img/close-icon.svg").default}/>
                                </div>
                            </div>
                            <div style={{display: "flex", textAlign: "center", fontSize: "18px"}}>
                                {t("verificationCodeAsan")}
                            </div>
                            <div style={{display: "flex", justifyContent: "center", paddingTop: 20, fontSize: 22, color: "blue"}}>
                                <div className="modal__text">{asanUser?.asanUser?.verificationCode}</div>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", paddingTop: 20}}>
                                <Button
                                    buttonText={t("rejection")}
                                    color="#FFFFFF"
                                    padding="14px 80px"
                                    minWidth={200}
                                    borderRadius={15}
                                    background="rgba(239, 92, 39, 1)"
                                    onClick={() => setOpenAsanInfoModal(false)}
                                />
                            </div>
                        </div>
                    </CustomModal>
                )}

                <div className="wrap-imza-right">
                    <AsideSettings/>
                </div>
            </div>
        </LayoutEmpty>
    );
};

export default AsanImzaBind;
