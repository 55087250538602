import React  from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import "./index.scss";

const CustomMenuRegistration = ({ item }) => {
  const { pathname } = useLocation();
  const { language} = useAppSelector(state => state.changeLangSlice)

  let activeStyle = {
    textDecoration: "none",
    backgroundColor: "#FFEAE9",
  };

  const isActive = item.path === pathname ? "menu-registration--active" : "";
  return (
    <NavLink
      to={`/epay/${language}/${item.path}`}
      style={() => (item.path === pathname ? activeStyle : undefined)}
      key={item.id}
      className={`menu-registration ${isActive}`}
    >
      <div className="menu-registration__image-main">
        <img
            src={item.img}
            loading={"lazy"}
            className="menu-registration__image"
            alt={item.title} width={30} height={26}/>
        <p className="menu-registration__text">
          {item.title}
          {item.count ? (
            <span className="menu-registration__count">
              {item.count > 99 ? "99+" : item.count}
            </span>
          ) : (
            <span>{""}</span>
          )}
        </p>
      </div>
    </NavLink>
  );
};

export default CustomMenuRegistration;
