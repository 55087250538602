import React from "react";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import Invoice from "../../Invoice/Invoice";

interface CreateInvoiceModalProps {
  active: boolean;
  setActive: (active: boolean) => void;
  amountValue: string;
}

const CreateInvoiceModal: React.FC<CreateInvoiceModalProps> = ({ active, setActive, amountValue }) => (
  <CustomModal active={active} setActive={setActive}>
    <div className="qr-modal__header">
      <div className="qr-modal__close" onClick={() => setActive(false)}>
        <img src={require("../../../img/close-icon.svg").default} alt=""/>
      </div>
    </div>
    <Invoice amount={amountValue}/>
  </CustomModal>
);

export default CreateInvoiceModal;
