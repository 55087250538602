import { useEffect, useState } from "react";
import useWalletServiceData from "./useWalletServiceData";

const useLoadData = (c2w: any, language: string) => {
  const getWalletServiceData = useWalletServiceData("", c2w, language);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isHubSolutionPaymentMethod, setIsHubSolutionPaymentMethod] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const serviceData: any = getWalletServiceData();

      if (!serviceData) return;

      let isHubSolutionPaymentMethod = false;

      const availablePaymentSystem = serviceData?.availablePaymentSystem;

      availablePaymentSystem.forEach((item: any) => {
        if (item.paymentSystem === "HUB_SOLUTION" && item.paymentProcessor === "HUB_SOLUTION") {
          isHubSolutionPaymentMethod = true;
          return;
        }
      });

      setIsHubSolutionPaymentMethod(true);
      setIsDataLoaded(true);
    };
    loadData();
  }, [c2w]);

  return { isDataLoaded, isHubSolutionPaymentMethod };
};

export default useLoadData;
