import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    pt: 2,
    pl: 1.5,
    pb: 1,
};

interface ModalComponentProps {
    topUpText?: string;
    deleteText?: string;
    renameText?: string;
    modalWidth?: number;
    defaultText?: string;
    requisitesTExt?: string;
}

const ModalComponent = ({deleteText, defaultText, requisitesTExt, topUpText, renameText, modalWidth}: ModalComponentProps) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} width={modalWidth}>
                    <Typography id="modal-modal-description" sx={{color: "#EF5C27", cursor: "pointer"}}>
                        {deleteText}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2, cursor: "pointer"}}>
                        {defaultText}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2, cursor: "pointer"}}>
                        {renameText}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2, cursor: "pointer"}}>
                        {requisitesTExt}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2, cursor: "pointer"}}>
                        {topUpText}
                    </Typography>
                </Box>
            </Modal>

        </div>
    );
};

export default ModalComponent;