import React, {FC, useEffect, useState} from 'react';
import './index.scss';
import LayoutWithApp from "../../layouts/LayoutWithApp/LayoutWithApp";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import AddCheckboxIn from "../../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import {useForm} from "react-hook-form";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import CustomCalendar from "../../Calendar/Calendar";
import {useAppSelector} from "../../../hooks/redux";
import moment from "moment/moment";
import CPhoneInput from "../../CustomsComponents/PhoneInput";
import PhoneInput,  { isValidPhoneNumber } from "react-phone-number-input";

interface IProps {

}

const CreateDigitalCard: FC<IProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { profileDetails, profile } = useAppSelector((state) => state.authReducer);

    const [showCalendar, setShowCalendar] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        getValues,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    });


    const goBack = () => {
        navigate(-1)
    }

    const birthdayFormated = moment(
        new Date(Number(profileDetails?.birthDate))
    ).format("YYYY-MM-DD");

    const emptyDate =
        birthdayFormated === "0000-12-31" ||
        birthdayFormated === "0001-01-01" ||
        birthdayFormated === "Invalid date";

    const finalDate = emptyDate
        ? new Date()
        : moment(new Date(Number(profileDetails?.birthDate))).toDate();
    
    useEffect(() => {
        setValue('email', profile?.user?.email || '' )
        setValue('name', profile?.user?.firstName || '')
        setValue('lastName', profile?.user?.lastName || '')
        setValue('phone', profile?.user?.phone || '' )
        setValue('birthday', moment(finalDate).format('YYYY.MM.DD'));
        setValue('gender', profileDetails?.gender === 1 ? "Male" : (profileDetails?.gender === 2 ? 'Female' : 'Not selected'))
    }, [finalDate, profile?.user])

    return(
        <LayoutWithApp navTitle={t("back")} goBack={goBack}>
            <div className="virtual-card">
                <div className="virtual-card__title">
                    Потратив всего лишь <span>5 секунд</span>, приобретите Виртуальную Карту EPUL и <span>обезопасьте свои онлайн-платежи!</span>
                </div>
                <div className="virtual-card__info-box">
                    <div className="virtual-card__info-point">
                        <div className="virtual-card__point-image">
                            <img src={require('../../../img/dots-icon.svg').default} alt=""/>
                        </div>
                        <div className="virtual-card__point-text">Делайте безопасные онлайн-платежи без регистрации своей основной карты в различных интернет-магазинах и сайтах оплаты</div>
                    </div>
                    <div className="virtual-card__info-point">
                        <div className="virtual-card__point-image">
                            <img src={require('../../../img/dots-icon.svg').default} alt=""/>
                        </div>
                        <div className="virtual-card__point-text">
                            Используя функцию “Cash by Code”, обналичивайте сумму, имеющуюся на балансе карты через терминалы Bank of Baku
                        </div>
                    </div>
                    <div className="virtual-card__info-point">
                        <div className="virtual-card__point-image">
                            <img src={require('../../../img/dots-icon.svg').default} alt=""/>
                        </div>
                        <div className="virtual-card__point-text">
                            Пополняйте баланс виртуальной карты при помощи других карт или E-Кошелка
                        </div>
                    </div>
                    <div className="virtual-card__info-point">
                        <div className="virtual-card__point-image">
                            <img src={require('../../../img/dots-icon.svg').default} alt=""/>
                        </div>
                        <div className="virtual-card__point-text">
                            Оплачивайте через Виртуальную Карту EPUL и получайте бонусы
                        </div>
                    </div>
                </div>
                <div className="virtual-card__hint">Внимание! Вводите данные латинскими буквами!</div>
                <form action="" className="virtual-card__form">
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Name</label>
                        <input
                            {...register('name', {required: true})}
                            className="virtual-card__field-input"
                            type="text"
                            // value={name.value}
                        />
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Last name</label>
                        <input
                            {...register('lastName', {required: true})}
                            type="text"
                            className="virtual-card__field-input"
                        />
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Middle name</label>
                        <input
                            {...register('middleName', {required: true})}
                            type="text"
                            className="virtual-card__field-input phone-input"/>
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Gender</label>
                        <input
                            {...register('gender', {required: true})}
                            type="text"
                            className="virtual-card__field-input"
                            onClick={() => setIsSelectOpen((pr) => !pr)}
                        />
                        {/*{ isSelectOpen*/}
                        {/*    ? <div className="virtual-card__select">*/}
                        {/*        <div className="virtual-card__select-option">Female</div>*/}
                        {/*        <div className="virtual-card__select-option">Male</div>*/}
                        {/*    </div>*/}
                        {/*    : null*/}
                        {/*}*/}
                        <div className="virtual-card__select">
                            <div className="virtual-card__select-option">Not selected</div>
                            <div className="virtual-card__select-option">Female</div>
                            <div className="virtual-card__select-option">Male</div>
                        </div>

                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Birthday</label>
                        <input
                            {...register('birthday', {required: true})}
                            type="text"
                            onClick={() => setShowCalendar(true)}
                            className="virtual-card__field-input"
                        />
                        <CustomModal active={showCalendar} setActive={setShowCalendar}>
                            <CustomCalendar
                                title={"birthday"}
                                // defaultBirthday={new Date(profileDetails?.birthDate!)}
                                setModalActive={setShowCalendar}
                                value={finalDate}
                                onChange={(e) => {
                                    console.log("birthDate", e.getTime());
                                }}
                            />
                        </CustomModal>
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Phone</label>
                        <CPhoneInput
                            {...register('phone', {required: true})}
                            type="text"
                            control={control}
                            className="virtual-card__field-input phone-input"
                        />
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Your email</label>
                        <input
                            {...register('email', {required: true})}
                            type="text"
                            className="virtual-card__field-input"/>
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Pin code</label>
                        <input
                            {...register('pinCode')}
                            type="text"
                            className="virtual-card__field-input"/>
                    </div>
                    <div className="virtual-card__field">
                        <label htmlFor="" className="virtual-card__field-label">Secret word</label>
                        <input
                            {...register('secretWord', {required: true})}
                            type="text"
                            className="virtual-card__field-input"/>
                    </div>
                    <div className="virtual-card__field">
                        <div className="virtual-card__field-checkbox">
                            <AddCheckboxIn />
                            <div className="virtual-card__field-checkbox">
                                Я согласен(на) с  <a href='#'> условиями </a>
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="virtual-card__btn" value='Создать виртуальную карту' />
                </form>

                <div className="virtual-card__hint">Продолжая вы соглашаетесь с условиями сервиса</div>
                <div className="virtual-card__info-box">
                    <div className="virtual-card__info-title">Условия сервиса:</div>
                    <p className="virtual-card__info-text">
                        Срок действия Виртуальной Карты 2 месяца и вы ее сможете использовать только в этот период.
                    </p>
                    <p className="virtual-card__info-text">
                        Виртуальная карта может быть открыта на максимальную сумму до 500 AZN. Карту можно заказать в AZN или USD.
                    </p>
                    <p className="virtual-card__info-text">
                        Плата за услуги (комиссия) по приобретению виртуальной карты зависит от суммы вносимой на баланс карты и составляет: 2 AZN при внесении суммы до 100 AZN и 2% от вносимой суммы при внесении суммы выше 100 AZN.
                    </p>
                </div>
            </div>
        </LayoutWithApp>
    )
}

export default CreateDigitalCard;