import React, {useState, memo} from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";
import Button from "../Button/Button";
import {useAppDispatch} from "../../hooks/redux";
import {useForm} from "react-hook-form";
import {
    fetchRegister,
} from "../../redux/actions/authActions";
import "./index.scss";
import {useTranslation} from "react-i18next";
import {notify} from "../../helper";
import CPhoneInput from "../CustomsComponents/PhoneInput";

const {default: dotsImage} = require('../../img/dots-icon.svg');
const visibility = require('../../img/visibility.png');
const visibilityOff = require('../../img/visibility_off.png');

const RegistrationForm = memo(() => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const {
        register,
        handleSubmit,control,
        formState: {errors, isValid},
    } = useForm({
        defaultValues: {
            phone: "",
            password: "",
            agree: false,
        },
        mode: "onChange",
    });
    const registrationTime = [
        {
            img: dotsImage,
            name: t("saveYourAccounts"),
        },
        {
            img: dotsImage,
            name: t("saveYourPaymentHistory"),
        },
        {
            img: dotsImage,
            name: t("automaticallyPayYourBills"),
        },
        {
            img: dotsImage,
            name: t("accumulateBonuses"),
        },
    ];
    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const onSubmit = (values: any) => {
        dispatch(fetchRegister({
            password: values.password,
            phone: values.phone,
            confirmPassword: values.password
        })).then(r => {
            if (r.payload?.resultCodes === 'INVALID_USERNAME_OR_PASSWORD') notify(t('invalidUsernamePassword'))
        } )
    }

    return (
        <>
            <div className="no-registration-main__header-div">
                <div className="no-registration-main__header-div-text">
                    {t("saveTime")}
                </div>
            </div>
            <div className="no-registration-main__header-head">
                {registrationTime.map((item, id) => (
                    <div key={id} className="no-registration-main__header-image-wrap">
                        <img
                            width={26}
                            height={26}
                            loading={"lazy"}
                            className="no-registration-main__header-img"
                            src={item.img}
                            alt={'dots'}
                        />
                        <div className="no-registration-main__header-item">{item.name}</div>
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CPhoneInput control={control}/>
                <div style={{
                    position: 'relative'
                }}>
                    <TextField
                        label={`${t("password")}`}
                        color="warning"
                        margin="normal"
                        type={showPassword ? "text" : "password"}
                        helperText={errors.password?.message}
                        error={Boolean(errors.password?.message)}
                        variant="standard"
                        fullWidth
                        {...register("password", {required: `${t("enterYourPassword")}`})}
                    />
                    <img
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: '13px',
                            cursor: 'pointer'
                        }}
                        src={showPassword ? visibility : visibilityOff}
                        alt={'show password'}
                        loading={"lazy"}
                        width={24}
                        height={24}
                    />
                </div>
                <Box my={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="warning"
                                {...register("agree", {required: `${t("confirmAgreement")}`})}
                            />
                        }
                        label={
                            <>
                                <Typography style={{color: "warning", fontSize: "14px"}}>
                                    {t("acceptConditions")}
                                </Typography>
                                <Typography
                                    component="span"
                                    style={{color: "warning", fontSize: "14px"}}
                                >
                                    <a
                                        target="_blank"
                                        className="terms-link"
                                        href="https://www.e-pul.az/epay/ru/resources/pdf/e33898de-6302-4756-8f0c-5f6c5218e02e_terms_ru.pdf"
                                        rel="noreferrer"
                                    >
                                        {t("agreements")}
                                    </a>
                                </Typography>
                            </>
                        }
                    />
                </Box>
                <div className="no-registration-main__register-button">
                    <Button
                        disabled={!isValid}
                        type="submit"
                        buttonText={`${t("signUp")}`}
                        background="#EF5C27"
                        padding="14px 0"
                        borderRadius={15}
                        color="#FFFFFF"
                        margin="0 auto"
                        width="100%"
                    />
                </div>
            </form>
        </>
    );
});

export default RegistrationForm;
