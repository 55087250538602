import React, {FC, memo, useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import "./index.scss"
import {LocaleName, Service} from "../../models/Categories";
import Button from '../Button/Button';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks/redux";
import {categoriesInfo} from "../../consts";

interface ServiceMainProps {
    filteredList: Service[]
}

const imagePerRow = 9;
const show = require("../../img/show.svg").default;

const ServiceSearch: FC<ServiceMainProps> = memo(({filteredList}) => {
    const {t} = useTranslation()
    const [next, setNext] = useState(imagePerRow);
    const {langKey, language} = useAppSelector(state => state.changeLangSlice)
    const auth = useAppSelector((state) => state.authReducer);
    const {categories, services} = useAppSelector(state=> state.categoriesReducer)

    const handleMoreImage = () => {
        setNext(next + imagePerRow);
    };

    useEffect(() => {
        setNext(imagePerRow);
    }, [filteredList]);


    const createLink = (item: any) => {
        const category = categories?.find((category: any) => category?.id === item?.categoryId);
        return item?.subName?.startsWith('https://') ? item.subName : auth.isAuth ? 
        `/epay/${language}/payment-add/category/${category?.name}/${item?.friendlyName}` :
        `/epay/${language}/category/${category?.name}/${item?.friendlyName}`
    }



    return (
        <div className='home'>
            <div className="service-main">
                {/*{filteredList.slice(0, next).map((service) => (*/}
                {/*    <>*/}
                {/*        <Link*/}
                {/*            state={{serviceId:service.id}} to={createLink(service)}*/}
                {/*            className="pay-communal__left-wrap-container"*/}
                {/*        >*/}
                {/*            <div className="pay-communal__left-wrap-container-image">*/}
                {/*                <img*/}
                {/*                    className="pay-communal__left-wrap-container-image-pic"*/}
                {/*                    src={*/}
                {/*                        "https://www.e-pul.az/epul_api/images/" +*/}
                {/*                        service.friendlyName +*/}
                {/*                        ".png"*/}
                {/*                    }*/}
                {/*                    alt=""*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="pay-communal__left-wrap-container-title">*/}
                {/*                {service.localName[langKey as keyof LocaleName]}*/}
                {/*            </div>*/}
                {/*        </Link>*/}
                {/*    </>*/}

                {/*))}*/}
                {filteredList.slice(0, next).map((service) => (
                    <>
                        <Link state={{serviceId:service.id}} to={createLink(service)} key={service.id}
                              className="service-main__item">
                            <div className="service-main__icon">
                                <img
                                    src={"https://www.e-pul.az/epul_api/images/" + service.friendlyName +".png"}
                                    alt="Logo" className="service-main__img filtered-img"
                                    onError={(event) => {
                                        //@ts-ignore
                                        event.target.src = 'https://placehold.co/60x60/EF5C27/FFF';
                                    }}/>
                            </div>
                            <div className="service-main__text">
                                {service.localName[langKey as keyof LocaleName]}
                                {/*{item.localName.valueRu}*/}
                            </div>
                        </Link>
                    </>

                ))}
            </div>

            {
                next < filteredList.length && (
                    <div className="catalogue-blocks__button">
                        <Button
                            buttonText={`${t("uploadMore")}`}
                            background={"none"}
                            color="#EF5C27"
                            padding="16.5px 27px 16.5px 27px"
                            borderRadius={15}
                            margin="0 10px 0 0"
                            icon={show}
                            onClick={handleMoreImage}
                        />
                    </div>
                )
            }
        </div>
    )
})

export default ServiceSearch;
