import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  callWithYouId: 0,
};

const callWithSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setCallWithYouId(state, action) {
      state.callWithYouId = action.payload;
    },
  },
});

export const { setCallWithYouId } = callWithSlice.actions;

export default callWithSlice.reducer;
