import React, {FC, useEffect} from 'react';
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import "./index.scss"
import HeaderText from "../../CustomsComponents/HeaderText";
import SelectedCategory from "../../CustomsComponents/SelectedCategory";
import {useNavigate} from "react-router-dom";
import { autoPaymentsListSliceActions } from '../../../redux/slices/autoPaymentsSlice';
import {useDispatch} from "react-redux";
import { AppDispatch } from '../../../redux/store';
import {useAppSelector} from "../../../hooks/redux";
import Loading from '../../Loading/Loading';
import {useTranslation} from "react-i18next";

interface CreateAutoPaymentProps {

}

const CreateAutoPayment: FC = (props: CreateAutoPaymentProps) => {
    const {t} = useTranslation()
    const dispatch = useDispatch<AppDispatch>();
    const {data, isLoading} = useAppSelector(state => state.categoriesReducer);


    const navigate = useNavigate();
    const goBack = () => navigate(-1);


    useEffect(() => {
        // dispatch(autoPaymentsListSliceActions.setAutoPaymentsState({
        //     isCreate: true,
        //     isEdit: false,
        //     btnValue: 'Далее',
        //     path: '/payment-page',
        // }))

    }, [dispatch])

    const createAutoPaymentPath = '/auto-payment-transaction/isCreate';

    return (
        <div className="create-auto-payment">
            <NavigationComponent onClick={goBack} title={t("back")}/>
            <div className="create-auto-payment__header">
                <div className="create-auto-payment__header-text-wrap">
                    <HeaderText title={t("createAutopay")}/>
                    <p className="create-auto-payment__header-select-category">{t("chooseCategory")}</p>
                </div>
                <div>
                    {
                        isLoading ? <Loading/> : <SelectedCategory
                            categories={data?.categories?.category}
                            path={createAutoPaymentPath}
                        />
                    }
                </div>
            </div>

        </div>
    );
};

export default CreateAutoPayment;
