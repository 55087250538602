export const categoryMeta = {
  en: {
    keywords: {
      1: 'mobil operators,replenish the balance of the azercell,replenish the balance of the bakcell,replenish the balance of the nar,azercell online,bakcell online,nar online,pay beeline georgia, pay beeline kazakhstan, pay beeline kyrgyzstan, pay beeline russia, pay beeline tajikistan, pay beeline ukraine, pay freenet roaming, pay geocell, pay kievstar, pay lifecell, pay megafon, pay mtc, pay skype, pay viber, pay simtravel, pay tele2, pay yota.',
      2: 'utilities,pay utilities online,payment of utilities via the Internet,payment for utility services without a fee,to pay utility bills by card online,pay online for gas,pay online for water,pay online for the light,pay the debt for water,pay a debt for gas.',
      3: 'internet providers,pay the debt for the Internet provider,to pay for your Internet service provider,baktelekom.net pay, azevrotel internet pay,activeol pay, aile net pay, azdatakom pay, aztelekom.net pay, avirtel pay, azfibernet pay, alfanet pay, azgoldnet pay, alfanet optik pay, azstarnet pay, connect internet pay, teleport pay, fibernet pay, birlink(enginet) pay, fibernet telekom pay, izone pay, nar citynet pay, nar home internet pay, naxinternet pay, sazz pay, gamma skynet pay, smart online pay, sntv (Internet) pay, telekominvest pay, uninet pay, ultel, azeronline, dataplus, eurosel, globalnetworks, metronet, pilot pay, superonline pay, transeurocom pay, katv1 pay.',
      4: 'tv providers, pay for cable tv,atv+ pay,aile tv pay, bbtv pay,connect tv pay, nntv pay, nar home tv pay, ra tv pay, rahat tv pay, sntv pay, smart tv+ pay, ultel ip tv pay, katv1 pay.',
      6: 'online pay stationary phone debt, find out the debt of a stationary phone, aztelekom-telefon pay, azeurotel-telefon pay, ultel telefon pay, naxcivan telekom pay, transeurocom-telefon pay',
      7: 'online games,entertainment services,odnoklassniki, vkontakte, мой мир, topaz, 11x11, 2pay, 4game, affected zone tactics, allodi online, armored warface, art of war 2, black desert, bloody world, bs.ru, combat arms, crossfire, crossout, dragon nest, galaxy, gamenet, войны престолов, ganja wars, гладиаторы, league of legends, haddan.ru, jade dynasty, джаггернаут, lava online, легенда (фэо-минор), легенда (фэо-прайм), мини игры, panzar, парапа город танцев, кодекс пиратов, revelation, sky2fly, skyforge, спарта, star conflict, tanki x, total influence online, троцарствие, варвары, world of tanks.',
      8: 'other services, ali & nino pay, avira pay, bitdefender pay, eset nod32 pay, kaspersky pay, bonuslar.az pay, destek pay, domofon smart home pay, just smile pay, lalafo pay, turbo.az pay, standardization institute pay, gilan real estate pay, gilan qafqaz hotels pay, hayat management pay, coca cola pay.',
      9: 'banking, afb bank pay, albukart pay, access bank pay, amrahbank pay, atabank pay, azercredit pay, azer turk bank pay, bank avrasiya pay, bank vtb (azerbaycan) pay, bank of baku pay, bolkart pay, brandcredit pay, ebmafinans pay, fintrend pay, flexikredi pay, finca pay, kapital bank pay, molbulak pay, mugambank pay, nikoil bank pay, rabitabank pay, bank respublika pay, seba kredit pay, silkway bank pay, turan bank pay, unibank pay, worldcard pay, yapi kredi bank azerbaycan pay.',
      5: 'payment system, yandex деньги pay, azcard pay, facebook games pay, google play pay, moneta.ru pay, qiwi euro pay, qiwi rub pay, qiwi usd pay, webmoney wme pay, webmoney wmr pay, webmoney wmz pay, xbox live pay.',
      10: 'insurance,pay insurance, baki sigorta pay insurance, pasha life pay insurance, qala heyat pay insurance, yeni polis elde et pay insurance, atesgah-movcud polis odenisi pay insurance.',
      11: 'entrant of payment, dim (state examination center), eas (european-azerbaijan school) payment, edutech payment, it step payment.',
      12: 'pay a penalty for the car, pay a penalty, check a penalty,Ministry of İnternal Affairs pay a penalty, Ministry of Taxes pay a penalty, The State Customs Committee pay a penalty, The Ministry of Labor and Social Protection pay a penalty, The Ministry of Environment and Natural Resources pay a penalty.',
      14: 'pay taxi services online,pay transport expenses online,transport payments,taxi payments,pay taxi online',
      15: 'pay government payments online,state duty payments,state payments,government payments',
      16: 'online store payments, kontakt home pay online, baku electronics pay online, pay coca-cola online, shop credits online.',
    },
    description: {
      1: '➤ ➤ Replenish the balance of a mobile phone online in Baku 📱 Azercell 💳 Bakcell 📞 Nar 💰 Megafon ✅ safe 💳 with any bank card of Azerbaijan ☎️ * 1919| EPUL',
      2: '➤ ➤ Pay utilities online in Baku 💳 payment for electricity ⚡️ жхх 💰 rent 🔒 safe ✅ with any bank card of Azerbaijan 📞 * 1919| EPUL',
      3: '➤ ➤ Pay for internet online in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 📞 * 1919| EPUL',
      4: '➤ ➤ Pay TV provider online in Baku 📺 pay for cable 💳 with any bank card of Azerbaijan ✅ safe ⚡️ fast ✨ reliable ✨ * 1919| EPUL',
      5: '➤ ➤ Fill the balance Online wallet in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919| EPUL',
      6: '➤ ➤ Payment for home (landline) phone online in Baku ☎️ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 📞 * 1919| EPUL',
      7: '➤ ➤ Payment of the game online in Baku ✅ safe 💳 with any bank card of Azerbaijan 👍 just ⚡️ fast ✨ hopefully 📞 * 1919| EPUL',
      8: '➤ ➤ Buy antivirus, book and replenish other services ✅ safe 💳 with any bank card of Azerbaijan 👍 just ⚡️ fast ✨ hopefully 📞 * 1919| EPUL',
      9: '➤ ➤ Pay bank 💰 financial services online in Baku ✅ safe 💳 with any bank card of Azerbaijan 💵 bank loans ⚡️ fast ✨ reliable 👍 simple 👍 * 1919| EPUL',
      10: '➤ ➤ Payment of insurance online in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopeful 👍 simple 📞 * 1919| EPUL',
      11: '➤ ➤ Payment for education online in Baku 🎓 safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopeful 👍 simple 📞 * 1919| EPUL',
      13: '➤ ➤ Fill in the software online in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919| EPUL',
      12: '➤ ➤ Payment of fine online in Baku 🚗 Payment of fine for car 💥 dtp ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopeful 👍 simple 📞 * 1919| EPUL',
      14: '➤ ➤ Taxi payment online in Baku 🚕 payment of transport costs ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919| EPUL',
      15: '➤ ➤ Payment of state payments online in Baku ⚖️ state duties ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopeful 👍 simple 📞 * 1919| EPUL',
      16: '➤ ➤ Payment of purchases 🛒 online in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919| EPUL',
      18: '➤ ➤ Payment of services of travel companies online in Baku ✈️ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopeful 👍 simple 📞 * 1919| EPUL',
    },
    title: {
      1: 'Replenish the account of the mobile operator online with bank card in Baku | EPUL',
      2: 'Payment for utilities online in Baku | EPUL',
      3: 'Fill the internet with an online bank card in Baku | EPUL',
      4: 'Payment for cable TV online bank card in Baku | EPUL',
      5: 'Replenishment of online card payment systems in Baku | EPUL',
      6: 'Payment for home phone online bank card in Baku | EPUL',
      7: 'Replenish the playing card account online in Baku | EPUL',
      8: 'Purchase antivirus, books and other services | EPUL',
      9: 'Pay bank and financial services online in Baku | EPUL',
      10: 'Payment of insurance services (insurance) bank card online in Baku | EPUL',
      11: 'Pay for card education online in Baku | EPUL',
      13: 'Fill in the software provision of the card in Baku | EPUL',
      12: 'Payment of car fines online card | EPUL',
      14: 'Taxi bank card payment online | EPUL',
      15: 'Payment of state payments online card | EPUL',
      16: 'Buy online with an online bank card | EPUL',
      18: 'Payment for the services of travel companies with cards in Baku | EPUL',
    },
    notFoundKeywords:
      'online payment, pay online, transfer money from card to card, utilities online pay, mobile operators online pay, internet providers online pay, tv providers online pay, stationary phones online pay, entertainment services online pay, other services online pay, banking online pay, payment system online pay, insurance online pay, education online pay, penalties online pay.',
  },
  ru: {
    keywords: {
      2: 'коммунальные услуги, оплатить коммунальные услуги онлайн, оплата коммунальных услуг через интернет, оплата коммунальных услуг без комиссии, оплатить коммунальные услуги картой онлайн, оплатить онлайн за газ, оплатить онлайн за воду, оплатить онлайн за свет, оплатить долг за свет, оплатить долг за воду, оплатить долг за газ.',
      1: 'мобильные операторы, пополнить баланс азерсель, пополнить баланс баксель, пополнить баланс нар, азерсель онлайн, баксель онлайн, нар онлайн, загрузить деньги на азерсель, загрузить деньги на баксель, загрузить деньги на нар, beeline georgia пополнить баланс, beeline kazakhstan пополнить баланс, beeline kyrgyzstan пополнить баланс, beeline russia пополнить баланс, beeline tajikistan пополнить баланс, beeline ukraine пополнить баланс, freenet roaming пополнить баланс, geocell пополнить баланс, kievstar пополнить баланс, lifecell пополнить баланс, megafon пополнить баланс, mtc пополнить баланс, skype пополнить баланс, viber пополнить баланс, simtravel пополнить баланс, tele2 пополнить баланс, yota пополнить баланс.',
      3: 'интернет провайдеры, оплатить долг за интернет, заплатить за интернет, baktelekom.net оплатить, azevrotel internet оплатить, activeol оплатить, aile net оплатить, azdatakom оплатить, aztelekom.net оплатить, avirtel оплатить, azfibernet оплатить, alfanet оплатить, azgoldnet оплатить, alfanet optik оплатить, azstarnet оплатить, connect internet оплатить, teleport оплатить, fibernet оплатить, birlink(enginet) оплатить, fibernet telekom оплатить, izone оплатить, nar citynet оплатить, nar home internet оплатить, naxinternet оплатить, sazz оплатить, gamma skynet оплатить, smart online оплатить, sntv (Internet) оплатить, telekominvest оплатить, uninet оплатить, ultel оплатить, azeronline оплатить, dataplus оплатить, eurosel оплатить, globalnetworks оплатить, metronet оплатить, pilot оплатить, superonline оплатить, transeurocom оплатить, katv1 оплатить.',
      4: 'тв провайдеры, оплатить за кабельное тв ,катв оплатить онлайн,atv+ оплатить,aile tv оплатить, bbtv оплатить, connect tv оплатить, nntv оплатить, nar home tv оплатить, ra tv оплатить, rahat tv оплатить, sntv оплатить, smart tv+ оплатить, ultel ip tv оплатить, katv1 оплатить.',
      6: 'онлайн оплатить долг домашнего телефона,узнать долг домашнего телефона, оплатить долг стационарного телефона,узнать долг стационарного телефона, aztelekom-telefon оплатить, azeurotel-telefon оплатить, ultel telefon оплатить, naxcivan telekom оплатить, transeurocom-telefon оплатить.',
      7: 'онлайн игры,развлекательные сервисы,odnoklassniki, vkontakte, мой мир, topaz, 11x11, 2pay, 4game, affected zone tactics, allodi online, armored warface, art of war 2, black desert, bloody world, bs.ru, combat arms, crossfire, crossout, dragon nest, galaxy, gamenet, войны престолов, ganja wars, гладиаторы, league of legends, haddan.ru, jade dynasty, джаггернаут, lava online, легенда (фэо-минор), легенда (фэо-прайм), мини игры, panzar, парапа город танцев, кодекс пиратов, revelation, sky2fly, skyforge, спарта, star conflict, tanki x, total influence online, троецарствие, варвары, world of tanks.',
      8: 'другие сервисы, ali & nino оплатить, avira оплатить, bitdefender оплатить, eset nod32 оплатить, kaspersky оплатить, bonuslar.az оплатить, destek оплатить, domofon smart home оплатить, just smile оплатить, lalafo оплатить, turbo.az оплатить, standardization institute оплатить, gilan real estate оплатить, gilan qafqaz hotels оплатить, hayat management оплатить, coca cola оплатить',
      9: 'оплатить банковский кредит, банкинг, afb bank оплатить, albukart оплатить, access bank оплатить, amrahbank оплатить, atabank оплатить, azercredit оплатить, azer turk bank оплатить, bank avrasiya оплатить, bank vtb (azerbaycan) оплатить, bank of baku оплатить, bolkart оплатить, brandcredit оплатить, ebmafinans оплатить, fintrend оплатить, flexikredi оплатить, finca оплатить, kapital bank оплатить, molbulak оплатить, mugambank оплатить, nikoil bank оплатить, rabitabank оплатить, bank respublika оплатить, seba kredit оплатить, silkway bank оплатить, turan bank оплатить, unibank оплатить, worldcard оплатить, yapi kredi bank azerbaycan оплатить',
      5: 'платежные системы, yandex деньги оплатить, azcard оплатить, facebook games оплатить, google play оплатить, moneta.ru оплатить, qiwi euro оплатить, qiwi rub оплатить, qiwi usd оплатить, webmoney wme оплатить, webmoney wmr оплатить, webmoney wmz оплатить, xbox live оплатить',
      10: 'страхование, baki sigorta оплатить, pasha life оплатить страховку, qala heyat оплатить страховку, yeni polis elde et оплатить страховку, atesgah-movcud polis odenisi оплатить страховку',
      11: 'абитуриент оплаты,dim(государственно экзаменационный центр) оплаты,eas(европейско-азербайджанская школа) оплаты,edutech оплаты,it step оплаты',
      14: 'оплатить услуги такси онлайн, оплатить транспортные расходы онлайн, транспортные платежи, оплатить такси, оплатить такси онлайн',
      15: 'оплачивать государственные платежи онлайн, государственные пошлины, государственные платежи, государственные платежи',
      16: 'платежи в интернет-магазине, kontakt home оплатить онлайн, baku electronics оплатить онлайн, оплатить Coca-Cola онлайн, купить кредиты онлайн',
    },
    description: {
      1: '➤ ➤ Пополнить баланс мобильного телефона онлайн в Баку 📱 Azercell 💳 Bakcell 📞 Nar 💰 Megafon ✅ безопасно 💳 с любой банковской карты Азербайджана ☎️ *1919 | EPUL',
      2: '➤ ➤ Оплатить коммуналку онлайн в Баку 💳 оплата за электроэнергию ⚡️ жкх 💰 квартплату 🔒 безопасно ✅ с любой банковской карты Азербайджана 📞 *1919 | EPUL',
      3: '➤ ➤ Заплатить за интернет онлайн в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 📞 *1919 | EPUL',
      4: '➤ ➤ Оплата TV провайдера онлайн в Баку 📺 заплатить за кабельное 💳 с любой банковской карты Азербайджана ✅ безопасно ⚡️ быстро ✨ надежно 📞 *1919 | EPUL',
      5: '➤ ➤ Пополните баланс Онлайн кошелька в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      6: '➤ ➤ Оплата за домашний (стационарный) телефон онлайн в Баку ☎️ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 📞 *1919 | EPUL',
      7: '➤ ➤ Оплата игр онлайн в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана 👍 просто ⚡️ быстро ✨ надежно 📞 *1919 | EPUL',
      8: '➤ ➤ Покупка антивируса, книг и пополнение других сервисов ✅ безопасно 💳 с любой банковской карты Азербайджана 👍 просто ⚡️ быстро ✨ надежно 📞 *1919 | EPUL',
      9: '➤ ➤ Оплатить банковские 💰 финансовые услуги онлайн в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана 💵 банковские кредиты ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      10: '➤ ➤ Оплата страховки онлайн в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      11: '➤ ➤ Оплата за образование онлайн в Баку 🎓 безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      13: '➤ ➤ Пополнить программное обеспечение онлайн в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      12: '➤ ➤ Оплата штрафа онлайн в Баку 🚗 оплата штрафа за машину 💥 дтп ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      14: '➤ ➤ Оплата такси онлайн в Баку 🚕 оплата транспортных расходов ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      15: '➤ ➤ Оплата государственных платежей онлайн в Баку ⚖️ государственные пошлины ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      16: '➤ ➤ Оплата покупок 🛒 в интернете онлайн в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
      18: '➤ ➤ Оплата услуг туристических компаний онлайн в Баку ✈️ безопасно 💳 с любой банковской карты Азербайджана  ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
    },
    title: {
      1: 'Пополнить счет мобильного оператора онлайн банковской картой в Баку | EPUL',
      2: 'Оплата коммунальных услуг онлайн в Баку | EPUL',
      3: 'Пополнить интернет онлайн банковской картой в Баку | EPUL',
      4: 'Оплата кабельного ТВ онлайн банковской картой в Баку | EPUL',
      5: 'Пополнение платежных систем онлайн картой в Баку | EPUL',
      6: 'Оплата домашнего телефона онлайн банковской картой в Баку | EPUL',
      7: 'Пополнение игрового аккаунта картой онлайн в Баку | EPUL',
      8: 'Покупка антивируса, книг и пополнение других сервисов | EPUL',
      9: 'Оплатить банковские и финансовые услуги онлайн в Баку | EPUL',
      10: 'Оплата услуг страхования (страховки) банковской картой онлайн в Баку | EPUL',
      11: 'Оплатить образование картой онлайн в Баку | EPUL',
      13: 'Пополнить программное обеспечение картой в Баку | EPUL',
      12: 'Оплата автомобильных штрафов онлайн картой | EPUL',
      14: 'Оплата такси банковской картой онлайн | EPUL',
      15: 'Оплата государственных платежей онлайн картой | EPUL',
      16: 'Оплата покупок в интернете онлайн банковской картой | EPUL',
      18: 'Оплата услуг туристических компаний картой в Баку | EPUL',
    },
    notFoundKeywords:
      'эпул, e-pul, epul, оплати онлайн,  система онлайн оплат, онлайн оплатить, онлайн оплатить счета, онлайн оплаты баку, приём платежей онлайн, онлайн оплаты через интернет, оплата через интернет банковской карту, онлайн оплаты на сайте, переводы с карты на карту, коммунальные услуги онлайн оплатить, мобильный оператор онлайн оплатить, интернет провайдер онлайн оплатить, тв провайдер онлайн оплатить, стационарные телефоны онлайн оплатить, развлекательные сервисы онлайн оплатить, другие услуги онлайн оплатить, банковские услуги онлайн оплатить, платежные системы онлайн платить, страхование онлайн оплатить, образование онлайн оплатить, штрафы онлайн оплатить.',
  },
  az: {
    keywords: {
      2: 'azerisu, azerisiq, azeriqaz, naxsu, naxcivan qaz, kommunal xidmetler, kommunal odemeler onlayn, internetnen kommunal borcu odemek, kartnan kommunal borcu odemek, komissiasiz kommunal borcu odemek, azeriqaz odemek, azersu odemek, azerisiq odemek, isiq pulu odemek, qaz pulunu odemek, su pulunu odemek',
      1: 'mobil operatorlar, balans artirmaq, kontur yuklemek, onlayn balans artirmaq, azercell balans artirmaq, bakcell balans artirmaq, nar balans artirmaq, beeline georgia odemek, beeline kazakhstan odemek, beeline kyrgyzstan odemek, beeline russia balans artirmaq, beeline tajikistan balans artirmaq, beeline ukraine balans artirmaq, freenet roaming balans artirmaq, geocell balans artirmaq, kievstar balans artirmaq, lifecell balans artirmaq, megafon balans artirmaq, mtc balans artirmaq, skype balans artirmaq, viber balans artirmaq, simtravel balans artirmaq, tele2 balans artirmaq, yota balans artirmaq',
      3: 'internet provayderler, internet pulu odemek, onlayn internet borcu odemek, baktelekom.net odemek, azevrotel internet odemek, activeol odemek, aile net odemek, azdatakom odemek, aztelekom.net odemek, avirtel odemek, azfibernet odemek, alfanet odemek, azgoldnet odemek, alfanet optik odemek, azstarnet odemek, connect internet odemek, teleport odemek, fibernet odemek, birlink(enginet) odemek, fibernet telekom odemek, izone odemek, nar citynet odemek, nar home internet odemek, naxinternet odemek, sazz odemek, gamma skynet odemek, smart online odemek, sntv (Internet) odemek, telekominvest odemek, uninet odemek, ultel odemek, azeronline odemek, dataplus odemek, eurosel odemek, globalnetworks odemek, metronet odemek, pilot odemek, superonline odemek, transeurocom odemek, katv1 internet odemek',
      4: 'tv provayderler, katv odemek, ailetv odemek, televizor pulunu odemek, atv+ odemek, aile tv odemek, bbtv odemek, connect tv odemek, nntv odemek, nar home tv odemek, ra tv odemek, rahat tv odemek, sntv odemek, smart tv+ odemek, ultel ip tv odemek, katv1 odemek',
      6: 'ev telefonun borcunu onlayn odemek, ev telefonun borcunu oyrenmek, telefon pulunu odemek, telefon borcunu oyrenmek, aztelekom-telefon odemek, azeurotel-telefon odemek, ultel telefon odemek, naxcivan telekom odemek, transeurocom-telefon odemek',
      7: 'onlayn oyunlar, eylence xidmetleri, odnoklassniki, vkontakte, moy mir, topaz, 11x11, 2pay, 4game, affected zone tactics, allodi online, armored warface, art of war 2, black desert, bloody world, bs.ru, combat arms, crossfire, crossout, dragon nest, galaxy, gamenet, voyny prestolov, ganja wars, gladiatory, league of legends, haddan.ru, jade dynasty, dzheggernaut, lava online, legenda (feo-minor), legenda (feo-praim), mini igry, panzar, parapa gorod tantsev, kodeks piratov, revelation, sky2fly, skyforge, sparta, star conflict, tanki x, total influence online, troetsarstvie, varvary, world of tanks',
      8: 'diger xidmetler, ali & nino odemek, avira odemek, bitdefender odemek, eset nod32 odemek, kaspersky odemek, bonuslar.az odemek, destek odemek, domofon smart home odemek, just smile odemek, lalafo odemek, turbo.az odemek, standardization institute odemek, gilan real estate odemek, gilan qafqaz hotels odemek, hayat management odemek, coca cola odemek',
      9: 'bank kredit borcunu odemek, bankciliq, afb bank odemek, albukart odemek, access bank odemek, amrahbank odemek, atabank odemek, azercredit odemek, azer turk bank odemek, bank avrasiya odemek, bank vtb (azerbaycan) odemek, bank of baku odemek, bolkart odemek, brandcredit odemek, ebmafinans odemek, fintrend odemek, flexikredi odemek, finca odemek, kapital bank odemek, molbulak odemek, mugambank odemek, nikoil bank odemek, rabitabank odemek, bank respublika odemek, seba kredit odemek, silkway bank odemek, turan bank odemek, unibank odemek, worldcard odemek, yapi kredi bank azerbaycan odemek',
      5: 'odenis sistemleri, yandex dengi odemek, azcard odemek, facebook games odemek, google play odemek, moneta.ru odemek, qiwi euro odemek, qiwi rub odemek, qiwi usd odemek, webmoney wme odemek, webmoney wmr odemek, webmoney wmz odemek, xbox live odemek',
      10: 'sigorta, sigortani onlayn odemek, baki sigorta odemek, pasha life sigorta odemek, qala heyat  sigorta odemek, yeni polis elde et sigorta odemek, atesgah-movcud polis odenisi sigorta odemek.',
      11: 'abituriyent odemeleri, dim (dovlet imtahan merkezi) odemeleri, eas (avropa azerbaycan mektebi) odemeleri,edutech odemeleri,it step odemeleri.',
      12: 'avtomobil cerimesini odemek,masin cerimesini odemek,cerimeni oyrenmek,dovlet yol polisi cerimeleri odemek,dyp cerimeleri odemek,daxili isler nazirliyi cerimesini odemek, vergiler nazirliyi cerimesini odemek, dovlet gomruk komitesi cerimesini odemek, emek ve ehalinin sosial mudafiesi nazirliyi cerimesini odemek, ekologiya ve tabii servetler nazirliyi cerimesini odemek.',
      14: 'taksi xidm\u0259tl\u0259rini online \u00f6d\u0259,n\u0259qliyyat x\u0259rcl\u0259rini onlayn \u00f6d\u0259,transport \u00f6d\u0259ni\u015fl\u0259ri,n\u0259qliyyat \u00f6d\u0259ni\u015fl\u0259ri,taksi \u00f6d\u0259ni\u015fl\u0259ri,taksi onlayn \u00f6d\u0259',
      11: 'abituriyent odemeleri, dim (dovlet imtahan merkezi) odemeleri, eas (avropa azerbaycan mektebi) odemeleri, edutech odemeleri, it step odemeleri',
      12: 'avtomobil cerimesini odemek, masin cerimesini odemek, cerimeni oyrenmek, dovlet yol polisi cerimeleri odemek, dyp cerimeleri odemek, daxili isler nazirliyi cerimesini odemek, vergiler nazirliyi cerimesini odemek, dovlet gomruk komitesi cerimesini odemek, emek ve ehalinin sosial mudafiesi nazirliyi cerimesini odemek, ekologiya ve tabii servetler nazirliyi cerimesini odemek',
      14: 'taksi xidmətlərini online ödə, nəqliyyat xərclərini onlayn ödə, transport ödənişləri, nəqliyyat ödənişləri, taksi ödənişləri, taksi onlayn ödə',
      15: 'dövlət rüsumlarınızı onlayn ödəyin, dövlət rüsum ödənişləri, dövlət ödənişləri',
      16: 'online mağaza ödənişləri, kontakt home online ödə, baku electronics online ödə, coca-cola online ödə, mağaza kreditlərini onlayn ödə',
    },
    description: {
      1: '➤ ➤ Bakıda mobil telefon balansını onlayn artırmaq ➤ Azercell 💳 Bakcell 📞 Nar 💰 Megafon ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ☎️ * 1919 | EPUL',
      2: '➤ ➤ Bakıda kommunal xidmətləri onlayn ödəmək 💳 ödəniş işıq pulu ödənişi ⚡️ mənzil və kommunal xidmətlər 💰 kirayə 🔒 təhlükəsiz ✅ Azərbaycanda istənilən bank kartından 📞 * 1919 | EPUL',
      3: '➤ ➤ Bakıda onlayn internet pulu ödəmək ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ✨ tez ✨ etibarlı 📞 * 1919 | EPUL',
      4: '➤ ➤ Bakıda TV provayderini onlayn ödəmək, 📺 kabel televiziyası üçün ödəniş 💳 Azərbaycanda istənilən bank kartından ✅ təhlükəsiz ⚡️ sürətli ✨ etibarlı ✨📞 * 1919 | EPUL',
      5: '➤ ➤ Bakıda elektron pul kisəsini onlayn artırmaq ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından doldurun ⚡️ tez ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      6: '➤ ➤ Bakıda onlayn (ev telefonu) telefon üçün ödəniş ☎️ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli, etibarlı 📞 * 1919 | EPUL',
      7: '➤ ➤ Bakıda oyunlar üçün onlayn ödəniş ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından 👍 rahat ⚡️ sürətli ✨ etibarlı 📞 * 1919 | EPUL',
      8: '➤ ➤ Antivirus almaq, kitab almaq və digər xidmətlərin balansını artırmaq ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından 👍 rahat ⚡️ sürətli ✨ etibarlı 📞 * 1919 | EPUL',
      9: '➤ ➤ Bakıda bank və 💰 maliyyə xidmətləri onlayn ödəmək ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından 💵 bank kreditləri ⚡️ sürətli ✨etibarlı 👍 rahat 📞 * 1919 | EPUL',
      10: '➤ ➤ Bakıda onlayn sığorta ödənişi ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      11: '➤ ➤ Bakıda onlayn təhsil haqqı ödənişi 🎓 təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      13: '➤ ➤ Bakıda proqramı təminatını onlayn artırmaq ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      12: '➤ ➤ Bakıda cərimənin onlayn ödənilməsi 🚗 avtomobil cəriməsinin ödənişi 💥 Qəza ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat* 1919 | EPUL',
      14: '➤ ➤ Bakıda taksi üçün onlayn ödəniş 🚕 nəqliyyat xərclərinin ödənişi ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      15: '➤ ➤ Bakıda dövlət ödənişlərinin onlayn ödənişi ⚖️ dövlət rüsumları ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      16: '➤ ➤ Bakıda İnternetlə alış-verişinin  🛒 onlayn ödəmək ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
      18: '➤ ➤ Bakıda turizm şirkətləri xidmətlərinin onlayn ödənişi ✈️ təhlükəsiz 💳 Azərbaycanda istənilən bank kartından ⚡️ sürətli ✨ etibarlı 👍 rahat 📞 * 1919 | EPUL',
    },
    title: {
      1: 'Bakıda bank kartı ilə mobil operatorun hesabını onlayn artıqmaq | EPUL',
      2: 'Bakıda kommunal xidmətlərin onlayn ödənişi | EPUL',
      3: 'Bakıda bank kartı ilə interneti onlayn ödəmək | EPUL',
      4: 'Bakıda bank kartı ilə onlayn kabel televiziyası ödənişi | EPUL',
      5: 'Bakıda bank kartl ilə ödəniş sistemlərinin onlayn balansını artırmaq | EPUL',
      6: 'Bakıda bank kartı ilə onlayn ev telefonu ödənişi | EPUL',
      7: 'Bakıda bank kartı ilə onlayn oyun hesabını artırmaq | EPUL',
      8: 'Antivirus, kitab almaq və digər xidmətlərin hesablarını artırmaq | EPUL',
      9: 'Bakıda bank və maliyyə xidmətlərini onlayn ödəmək | EPUL',
      10: 'Bakıda bank kartı ilə sığorta xidmətlərinin (sığorta) ödənişi | EPUL',
      11: 'Bakıda bank kartı ilə təhsil haqqını onlayn odəmək | EPUL',
      13: 'Bakıda bank kartı ilə proqram təminatının onlayn artırılması | EPUL',
      12: 'Avtomobil cərimələrinin kartla onlayn ödənilməsi | EPUL',
      14: 'Taksi xidmətlərinin kartla onlayn ödənilməsi | EPUL',
      15: 'Dövlət ödənişlərinin kartla onlayn ödənilməsi | EPUL',
      16: 'Bank kartı ilə internet alış-verişini ödəmək | EPUL',
      18: 'Bakıda bank kartı ilə turizm şirkətlərinin xidmətlərini onlayn ödəmək | EPUL',
    },
    notFoundKeywords:
      'onlayn ödəniş, onlayn ödəmə, kartdan karta pul köçürmə, kommunal xidmətlər onlayn ödəmə, mobil operatorlar onlayn ödəmə, internet provayderləri onlayn ödəmə, tv provayderləri onlayn ödəmə, stasionar telefonlar onlayn ödəmə, əyləncə xidmətləri onlayn ödəmə, digər xidmətlər onlayn ödəmə, onlayn bankçılıq ödəmə, ödəniş sistemi onlayn ödəmə, sığorta onlayn ödəmə, təhsil onlayn ödəmə, cərimələr onlayn ödəmə.',
  },
};

export const serviceMeta = {
  en: {
    description:
      'in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919 | EPUL',
    title: 'in Baku via the Internet | EPUL',
    mobile: {
      content: (
        <>
          <h2>Advantageous and convenient mobile phone top-up</h2>
          <p>
            Need to top up your mobile phone online? Use the terminal, buy a
            top-up card, or choose another alternative? A similar service is
            available on a smartphone and a browser. The service allows you to
            top up your mobile account quickly and easily. All operators of
            Azerbaijan are available on one platform. Minimum commission, high
            speed of process completion, and convenience for each customer.
          </p>
          <p>
            Paying for a mobile connection is easy. The E-PUL website will help
            you to top up your mobile account around the clock effortlessly. It
            is the easiest way for you. It also includes all operators at the
            same time.
          </p>
          <h2>How to top up a mobile phone?</h2>
          <p>
            Choose the most convenient option: registration on the website or in
            the application. The platform is available on iOS and Android.
          </p>
          <ol>
            <li>
              Add an active bank card or open a virtual one in the system.
            </li>
            <li>Select an operator and top up your phone number balance.</li>
            <li>
              In a matter of minutes, funds will be credited to your account.
            </li>
          </ol>
          <p>
            Everything is fast, simple, easy! The service has an interface that
            is not complicated. There are step-by-step instructions to follow.
            It also provides answers to the most common questions. Do you need
            any help? You can get it on the website in the appropriate section.
          </p>
          <h2>
            What is good about paying for your mobile phone on the E-PUL
            service?
          </h2>
          <ul>
            <li>
              All operators of Azerbaijan, international operators are available
              on the website. A few clicks and the payment process is finished.
            </li>
            <li>
              Convenience and simplicity for each customer. Payment is made
              through a smartphone or computer. Funds are credited to the
              account extremely quickly, without any technical glitches.
            </li>
            <li>Ability to top up at any time.</li>
            <li>
              It is absolutely safe to use the service. The service work is
              confirmed by appropriate certificates.
            </li>
            <li>
              {' '}
              The virtual card, opened in the application, can only be used for
              these payments. You can also use an existing bank card.
            </li>
          </ul>
          <p>
            Topping up in Baku is available 24/7. Choose the convenient E-PUL
            service on your phone or computer. Save time on payments. Maximum
            simplicity and reliability is offered to all customers!
          </p>
        </>
      ),
      22: 'azercell, azercell recharge the balance online, sim recharge the balance online.',
      21: 'azercell, azercell recharge the balance online, sim recharge the balance online.',
      142: 'bakcell, bakcell recharge the balance online, cin recharge the balance online.',
      790: 'bakcell, bakcell recharge the balance online, cin recharge the balance online.',
      157: 'nar, nar recharge the balance online',
      402: 'nar, nar recharge the balance online',
      791: 'nar, nar recharge the balance online',
      614: 'beeline georgia, beeline georgia recharge the balance online.',
      619: 'beeline kazakhstan, beeline kazakhstan recharge the balance online.',
      617: 'beeline kyrgyzstan, beeline kyrgyzstan recharge the balance online.',
      615: 'beeline russia, beeline russia recharge the balance online.',
      618: 'beeline tajikistan, beeline tajikistan recharge the balance online.',
      616: 'beeline ukraine, beeline ukraine recharge the balance online.',
      393: 'naxtel, naxtel recharge the balance online.',
      710: 'freenet, freenet recharge the balance online.',
      620: 'geocell, geocell recharge the balance online.',
      794: 'kievstar, kievstar recharge the balance online.',
      793: 'lifecell, lifecell recharge the balance online.',
      792: 'megafon, megafon recharge the balance online.',
      621: 'mtc, mtc recharge the balance online.',
      622: 'mtc, mtc recharge the balance online.',
      568: 'skype, skype recharge the balance online.',
      755: 'viber, viber recharge the balance online.',
      906: 'simtravel, simtravel recharge the balance online.',
      907: 'tele2, tele2 recharge the balance online.',
      908: 'yota, yota recharge the balance online.',
    },
    utilities: {
      content: (
        <>
          <h2>Payment of utilities: simple and fast!</h2>
          <p>
            Paying utility bills on the Internet is a real godsend! A great
            offer of our modern time to send funds online. Forget about standing
            in queues. It is obsolete. Nowadays, the process of paying for your
            utility bills will be easy and enjoyable. It will take you a little
            time and you will do it with a minimum expenditure of effort. Saving
            templates and the ability to pay for utilities in a couple of
            clicks. The easiest way of paying for utilities is always available
            on your smartphone.
          </p>
          <p>
            E-PUL is an electronic payment system. Convenience is our middle
            name. You only need to register on the platform. After that, the
            payment service provides all types of housing payments at once. No
            queues, no problems! Spend time doing more enjoyable activities than
            paying for your utility bills!
          </p>
          <h2>How does the system work?</h2>
          <ul>
            <li role="presentation">
              The registration will take a few minutes.
            </li>
            <li role="presentation">
              Add the desired bank card or opening a virtual card. If you prefer
              a virtual card, open it in a few clicks. Topping up is possible
              through the use of electronic wallets.
            </li>
            <li role="presentation">
              Make different types of payments through the Internet or
              application. It is enough to enter the required service and fill
              in the details. Templates are saved for your convenience.
            </li>
            <li role="presentation">
              Save your time and effort! Pay anywhere!
            </li>
          </ul>
          <h2>E-PUL advantages</h2>
          <ul>
            <li role="presentation">
              Utility payment in Baku in one application. Add funds in the
              payment service at any time of the day quickly and easily.
            </li>
            <li role="presentation">
              Ability to use a virtual card. Instead of the main bank card, open
              a virtual one. It can only be used for utility bills.
            </li>
            <li role="presentation">
              The reliability of the service in Baku and Azerbaijan is confirmed
              by certificates. The system is absolutely safe. The terms of
              cooperation are transparent and understandable for everyone.
            </li>
            <li role="presentation">
              Saving time, effort, money. Payment for electricity, gas, water,
              etc. It is always at hand!
            </li>
            <li role="presentation">
              The application works on all smartphones. Android and iOS fans can
              use it.
            </li>
            <li role="presentation">
              Bonuses are available for customers. You can find out more about
              special benefits on the website.
            </li>
            <li role="presentation">
              The interface is intuitive. Do you have any questions? Take
              advantage of the recommendations and tips.
            </li>
          </ul>
          <p>
            Paying for utility bills has become a simple and enjoyable process.
            It makes no difference - to pay in the app or via the browser! Save
            time and effort, forget about waiting in long queues. Control
            payments from your smartphone or other gadgets.
          </p>
        </>
      ),
      description:
        'in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919 | EPUL',
      title: 'in Baku via the Internet | EPUL',
      425: 'azersu pay through smart cards, find out the bill for water, pay for water online by smart card, recharge the card balance for water.',
      127: 'azerigaz pay through smart cards, find out the gas debt, pay for gas online by smart card, recharge the card balance for gas.',
      130: 'azerigaz pay online, find out the debt for gas, pay for gas online.',
      144: 'azerisig, azerisig to pay online, learn a debt for light, light bill online.',
      515: 'azerisig, azerisig to pay online, learn a debt for light, light bill online.',
      132: 'azersu pay online, find out the debt for water, pay for water online.',
      516: 'azersu pay online, find out the debt for water, pay for water online.',
      389: 'azeristilik, azeristilik pay online, find out the debt for heating, pay for heating online.',
      547: 'azeristilik, azeristilik pay online, find out the debt for heating, pay for heating online.',
      394: 'naxsu pay online, find out the debt for water naxichivan, pay for water online naxchivan.',
      396: 'naxisig, naxisig to pay online, learn a debt for light naxcivan, light bill online naxcivan.',
      395: 'naxgaz pay online, find out the debt for gas naxcivan, pay for gas online naxcivan.',
    },
    phone: {
      description:
        'in Baku ✅ safe 💳 with any bank card of Azerbaijan ⚡️ fast ✨ hopefully 👍 simple 📞 * 1919 | EPUL',
      title: 'in Baku via the Internet | EPUL',
      386: 'aztelekom phone online to pay the debt, to know the duty of aztelekom phone, to pay the debt aztelekom phone, find debt aztelekom phone',
      803: 'aztelekom phone online to pay the debt, to know the duty of aztelekom phone, to pay the debt aztelekom phone, find debt aztelekom phone',
      816: 'azeurotel phone online to pay the debt, to know the duty of azeurotel phone, to pay the debt azeurotel phone, find debt azeurotel phone',
      815: 'transeurocom phone online to pay the debt, to know the duty of transeurocom phone, to pay the debt transeurocom phone, find debt transeurocom phone',
      163: 'ultel phone online to pay the debt, to know the duty of ultel phone, to pay the debt ultel phone, find debt ultel phone',
      932: 'ultel phone online to pay the debt, to know the duty of ultel phone, to pay the debt ultel phone, find debt ultel phone',
      146: 'catel phone online to pay the debt, to know the duty of catel phone, to pay the debt catel phone, find debt catel phone',
      934: 'catel phone online to pay the debt, to know the duty of catel phone, to pay the debt catel phone, find debt catel phone',
      399: 'naxcivan phone online to pay the debt, to know the duty of naxcivan phone, to pay the debt naxcivan phone, find debt naxcivan phone',
    },
    online_store: {
      905: 'online pay coca cola debt, find out the debt of coca cola',
      935: 'baku-electronics pay online, baku electronics debt pay, find out baku electronics debt, baku-electronics credit payment',
      950: 'kontakt home pay online, kontakt home debt, kontakt home pay credit, kontakt home pay debt',
    },
    transport: {
      767: 'taxi 189,taxi 189 pay online,taxi 189 service pay online',
    },
    government: {
      901: 'online pay university of standards debt, find out the debt of university of standards',
      826: 'online pay center for patents and trademarks debt, find out the debt of center for patents and trademarks',
      964: 'online pay university of state migration service debt, find out the debt of state migration service',
      965: 'online pay ministry of internal affairs debt, find out the debt of ministry of internal affairs',
    },
    tv: {
      173: 'online pay atv+ debt, find out the debt of atv+.',
      752: 'online pay ailetv iptv debt, find out the debt of ailetv iptv.',
      121: 'online pay ailetv debt, find out the debt of ailetv.',
      143: 'online pay bbtv debt, find out the debt of bbtv.',
      147: 'online pay connect tv debt, find out the debt of connect tv.',
      780: 'online pay nn tv debt, find out the debt of nn tv.',
      427: 'online pay nar home tv debt, find out the debt of nar home tv.',
      265: 'online pay ra tv debt, find out the debt of ra tv.',
      398: 'online pay rahat tv debt, find out the debt of rahat tv.',
      801: 'online pay rahat tv debt, find out the debt of rahat tv.',
      763: 'online pay sn tv debt, find out the debt of sn tv.',
      153: 'online pay katv debt, find out the debt of katv.',
      929: 'online pay katv debt, find out the debt of katv.',
      178: 'online pay ultel ip tv debt, find out the debt of ultel ip tv.',
      933: 'online pay ultel ip tv debt, find out the debt of ultel ip tv.',
    },
    internet: {
      content: (
        <>
          <h2>One-click online bill payment</h2>
          <p>
            How to pay online by a bank card without any significant loss? As
            usual, money on the account ends at the most inconvenient time. The
            best way to top up your account quickly is to use the E-PUL service.
            This service is available on any platform. This service is available
            on any gadgets: computers, tablets, smartphones. The service works
            on Android, iOS, via the website. Download the application or
            register on the website. It will always help you to top up your
            account at any time of the day and in all weathers.
          </p>
          <h2>How to top up the Internet from your phone in Baku?</h2>
          <ol>
            <li>
              Install the appropriate application. Register in it. The process
              of registration is easy and it will take you a few minutes.
            </li>
            <li>
              To complete the process, you can use an active bank card. It is
              also possible to open a virtual card. It is replenished from your
              card or e-wallets. The process is very fast.
            </li>
            <li>
              Select the appropriate service on the website. Find your provider.
              Enter the desired amount of money to be deducted in your
              credit/bank card. Your account will be credited instantly.
            </li>
            <li>Enjoy free access to the worldwide network!</li>
          </ol>
          <p>
            Are you tired of remembering a website to pay for services? Install
            the app! Paying for the Internet will be extremely easy. The desired
            result with a minimum expenditure of effort. All recurring payments
            can be collected in one place.
          </p>
          <h2>E-PUL benefits for customers</h2>
          <ul>
            <li role="presentation">
              Maximum convenience. You need to do just a couple of clicks. It is
              suitable even for beginners. You can pay for the services of any
              provider in Azerbaijan on one service. Open the appropriate
              section, follow the instructions.
            </li>
            <li role="presentation">
              Accessibility and simplicity of the interface. Information on
              topping up is presented clearly. It will be easy to pay for the
              Internet online even if you are using this service for the first
              time.
            </li>
            <li role="presentation">
              Service reliability. E-PUL is the first service to collect so many
              online opportunities. We cooperate with the largest Azerbaijani
              companies. The required amount will be credited extremely quickly.
            </li>
            <li role="presentation">
              The ability to pay for the services of any company. A search
              function is available for your convenience.
            </li>
          </ul>
          <p>
            Do not hesitate to contact our Customer Support Service. Our
            operators will advise you on all issues relating to payment and give
            you some additional information in a short period of time.
          </p>
          <p>
            Speed is important when adding funds to the account of a service
            company. The sooner the money is credited, the better. It is easy to
            save money and maintain a positive balance. Just use our service!
          </p>
        </>
      ),
      165: 'online pay internet activeol debt, find out the debt of internet activeol.',
      806: 'online pay internet baktelecom.net debt, find out the debt of internet baktelecom.net.',
      808: 'online pay internet baktelecom.net debt, find out the debt of internet baktelecom.net.',
      123: 'online pay internet aile net debt, find out the debt of internet aile net.',
      404: 'online pay internet azevrotel debt, find out the debt of internet azevrotel.',
      818: 'online pay internet azevrotel debt, find out the debt of internet azevrotel.',
      135: 'online pay internet azevrotel debt, find out the debt of internet azevrotel.',
      820: 'online pay internet azdatakom debt, find out the debt of internet azdatakom.',
      125: 'online pay internet avirtel debt, find out the debt of internet avirtel.',
      804: 'online pay internet aztelekom.net debt, find out the debt of internet aztelekom.net.',
      807: 'online pay internet aztelekom.net debt, find out the debt of internet aztelekom.net.',
      124: 'online pay internet aflanet debt, find out the debt of internet aflanet.',
      384: 'online pay internet aflanet debt, find out the debt of internet aflanet.',
      810: 'online pay internet azfibernet debt, find out the debt of internet azfibernet.',
      510: 'online pay internet azfibernet debt, find out the debt of internet azfibernet.',
      682: 'online pay internet azgoldnet debt, find out the debt of internet azgoldnet.',
      403: 'online pay internet alfanet optik debt, find out the debt of internet alfanet optik.',
      148: 'online pay internet connect debt, find out the debt of internet connect.',
      166: 'online pay internet connect fiber debt, find out the debt of internet connect fiber.',
      149: 'online pay internet teleport debt, find out the debt of internet teleport.',
      22: 'online pay internet birlink debt, find out the debt of internet birlink.',
      34: 'online pay internet birlink debt, find out the debt of internet birlink.',
      782: 'online pay internet fibernet debt, find out the debt of internet fibernet.',
      512: 'online pay internet fibernet telekom debt, find out the debt of internet fibernet telekom.',
      167: 'online pay internet izone debt, find out the debt of internet izone.',
      385: 'online pay internet citynet debt, find out the debt of internet citynet.',
      426: 'online pay internet nar home debt, find out the debt of internet nar home.',
      397: 'online pay internet naxinternet debt, find out the debt of internet naxinternet.',
      802: 'online pay internet sazz debt, find out the debt of internet sazz.',
      761: 'online pay internet gamma skynet debt, find out the debt of internet gamma skynet.',
      161: 'online pay internet smart online debt, find out the debt of internet smart online.',
      763: 'online pay internet sntv debt, find out the debt of internet sntv.',
      387: 'online pay internet uninet debt, find out the debt of internet uninet.',
      488: 'online pay internet uninet debt, find out the debt of internet uninet.',
      131: 'online pay internet azeronline debt, find out the debt of internet azeronline.',
      917: 'online pay internet azeronline debt, find out the debt of internet azeronline.',
      136: 'online pay internet azstarnet debt, find out the debt of internet azstarnet.',
      919: 'online pay internet azstarnet debt, find out the debt of internet azstarnet.',
      150: 'online pay internet dataplus debt, find out the debt of internet dataplus.',
      920: 'online pay internet dataplus debt, find out the debt of internet dataplus.',
      152: 'online pay internet eurosel debt, find out the debt of internet eurosel.',
      921: 'online pay internet eurosel debt, find out the debt of internet eurosel.',
      171: 'online pay internet globalnetworks debt, find out the debt of internet globalnetworks.',
      922: 'online pay internet globalnetworks debt, find out the debt of internet globalnetworks.',
      156: 'online pay internet metronet debt, find out the debt of internet  metronet.',
      923: 'online pay internet metronet debt, find out the debt of internet  metronet.',
      170: 'online pay internet pilot debt, find out the debt of internet  pilot.',
      925: 'online pay internet pilot debt, find out the debt of internet  pilot.',
      172: 'online pay internet telekomInvest debt, find out the debt of internet telekomInvest.',
      926: 'online pay internet telekomInvest debt, find out the debt of internet telekomInvest.',
      391: 'online pay internet super online debt, find out the debt of internet super online.',
      927: 'online pay internet super online debt, find out the debt of internet super online.',
      168: 'online pay internet transeurocom debt, find out the debt of internet transeurocom.',
      928: 'online pay internet transeurocom debt, find out the debt of internet transeurocom.',
      154: 'online pay internet katv debt, find out the debt of internet katv.',
      918: 'online pay internet katv debt, find out the debt of internet katv.',
      162: 'online pay internet ultel debt, find out the debt of internet ultel.',
      931: 'online pay internet ultel debt, find out the debt of internet ultel.',
    },
    education: {
      781: 'online payment abiturient, online pay for abiturient journal.',
      753: 'online payment dim, online pay for dim.',
      748: 'online payment eas, online pay for eas.',
      500: 'online payments edutech, edutech pay online.',
      754: 'online payments it step, it step pay online.',
      830: 'pay online a penalty for the car, pay online a penalty, check a penalty online, pay online a penalty ministry of Internal affairs.',
      831: 'pay online a penalty, check online a penalty, ministry of taxes pay online a penalty.',
      832: 'pay online a penalty for the customs, pay online a penalty, check online a penalty, the state customs committee pay online a penalty.',
      833: 'check online a penalty, the ministry of labor and social protection pay online a penalty.',
      834: 'check online a penalty, the ministry of environment and natural resources pay online a penalty.',
    },
    online: {
      629: 'online pay yandex money, online recharge balance yandex money, online put money yandex money.',
      765: 'online pay azcard, online recharge balance azcard, online put money azcard.',
      681: 'online pay facebook games, online recharge balance facebook games, online put money facebook games.',
      680: 'online pay google play, online recharge balance google play, online put money google play.',
      633: 'online pay moneta, online recharge balance moneta, online put money moneta.',
      624: 'online pay qiwi euro, online recharge balance qiwi euro, online put money qiwi euro.',
      625: 'online pay qiwi rub, online recharge balance qiwi rub, online put money qiwi rub.',
      623: 'online pay qiwi usd, online recharge balance qiwi usd, online put money qiwi usd.',
      578: 'online pay steam, online recharge balance steam, online put money steam.',
      632: 'online pay webmoney wme, online recharge balance webmoney wme, online put money webmoney wme.',
      630: 'online pay webmoney wmr, online recharge balance webmoney wmr, online put money webmoney wmr.',
      631: 'online pay webmoney wmz, online recharge balance webmoney wmz, online put money webmoney wmz.',
      683: 'online pay xbox lite, online recharge balance xbox lite, online put money xbox lite.',
    },
    banks: {
      184: 'online to pay credit for afb bank, pay a credit an afb bank by card, pay for afb bank credit via the Internet.',
      409: 'online to pay credit for albukart, pay a credit an albukart by card, pay for albukart credit via the Internet.',
      347: 'online to pay credit for access bank, pay a credit an access bank by card, pay for access bank credit via the Internet.',
      486: 'online to pay credit for amrahbank, pay a credit an amrahbank by card, pay for amrahbank credit via the Internet.',
      186: 'online to pay credit for atabank, pay a credit an atabank by card, pay for atabank credit via the Internet.',
      226: 'online to pay credit for azercredit, pay a credit an azercredit by card, pay for azercredit credit via the Internet.',
      834: 'online to pay credit for azer turk bank, pay a credit an azer turk bank by card, pay for azer turk bank credit via the Internet.',
      746: 'online to pay credit for bank avrasiya, pay a credit an bank avrasiya by card, pay for bank avrasiya credit via the Internet.',
      225: 'online to pay credit for bank vtb, pay a credit an bank vtb by card, pay for bank vtb credit via the Internet.',
      388: 'online to pay credit for bank vtb, pay a credit an bank vtb by card, pay for bank vtb credit via the Internet.',
      182: 'online to pay credit for bank of baku, pay a credit an bank of baku by card, pay for bank of baku credit via the Internet.',
      183: 'online to pay credit for bolkart, pay a credit an bolkart by card, pay for bolkart credit via the Internet.',
      706: 'online to pay credit for brandcredit, pay a credit an brandcredit by card, pay for brandcredit via the Internet.',
      349: 'online to pay credit for embafinans, pay a credit an embafinans by card, pay for embafinans via the Internet.',
      800: 'online to pay credit for fintrend, pay a credit an fintrend by card, pay for fintrend via the Internet.',
      191: 'online to pay credit for flexikredi, pay a credit an flexikredi by card, pay for flexikredi via the Internet.',
      193: 'online to pay credit for finca, pay a credit an finca by card, pay for finca via the Internet.',
      192: 'online to pay credit for kapital bank, pay a credit an kapital bank by card, pay for kapital bank via the Internet.',
      407: 'online to pay credit for kapital bank, pay a credit an kapital bank by card, pay for kapital bank via the Internet.',
      634: 'online to pay credit for molbulak, pay a credit an molbulak by card, pay for molbulak via the Internet.',
      485: 'online to pay credit for muganbank, pay a credit an muganbank by card, pay for muganbank via the Internet.',
      194: 'online to pay credit for nikoil bank, pay a credit an nikoil bank by card, pay for nikoil bank via the Internet.',
      195: 'online to pay credit for respublika bank, pay a credit an respublika bank by card, pay for respublika bank via the Internet.',
      346: 'online to pay credit for sebakredit bank, pay a credit an sebakredit bank by card, pay for sebakredit bank via the Internet.',
      506: 'online to pay credit for silkway bank, pay a credit an silkway bank by card, pay for silkway bank via the Internet.',
      196: 'online to pay credit for turan bank, pay a credit an turan bank by card, pay for turan bank via the Internet.',
      408: 'online to pay credit for unibank, pay a credit an unibank by card, pay for unibank via the Internet.',
      406: 'online to pay credit for worldcard, pay a credit an worldcard by card, pay for worldcard via the Internet.',
      405: 'online to pay credit for yapi kredi, pay a credit an yapi kredi by card, pay for yapi kredi via the Internet.',
    },
    other: {
      266: 'online pay ali nino debt, find out the debt of ali nino.',
      645: 'online pay avira debt, find out the debt of avira.',
      684: 'online pay bitdefender debt, find out the debt of bitdefender.',
      676: 'online pay eset nod 32 debt, find out the debt of eset nod 32.',
      679: 'online pay kaspersky debt, find out the debt of kaspersky.',
      305: 'online pay bonuslar debt, find out the debt of bonuslar.',
      675: 'online pay destek debt, find out the debt of destek.',
      179: 'online pay domofon debt, find out the debt of domofon.',
      181: 'online pay just smile debt, find out the debt of just smile.',
      726: 'online pay lalafo debt, find out the debt of lalafo.',
      677: 'online pay turbo az debt, find out the debt of turbo az.',
      904: 'online pay gilan debt, find out the debt of gilan.',
      902: 'online pay hayat management debt, find out the debt of hayat management.',
    },
    insurance: {
      content: (
        <>
          <h2>Pay for insurance online</h2>
          <p>
            It is possible to top up and pay for insurance through the Internet
            in Azerbaijan as quickly as possible. Do you value your time and
            effort? Do you want to be sure that the payment will be made
            quickly? Use the E-PUL service! We have collected insurance
            companies from Baku and Azerbaijan on one platform. Everything is
            simple and easy. It is available to everyone in just a few clicks.
          </p>
          <p>
            Just take advantage of the offer through the website or app.
            Registration will take a few minutes. Then you can add your bank
            card or open a virtual one. The service works on Android, iOS
            platforms. You can pay for insurance from your own phone.
          </p>
          <h2>How to pay for the insurance in Baku?</h2>
          <ol>
            <li>
              The first step is registration and selection of a bank card.
            </li>
            <li>
              Next, choose the type of insurance you want. For more convenience,
              you can use the search field. Attention! There are some types of
              insurance policies, where you don’t need to pay for service
              commission.
            </li>
            <li>Enter the required details and make the payment.</li>
            <li>
              Funds are transferred to the account of the insurance company as
              quickly as possible.
            </li>
          </ol>
          <p>
            The website works like clockwork: fast, accurate, safe, reliable.
            This is the easiest and most convenient way to pay for insurance.
            Regardless of the type of gadget, it is suitable for everyone with
            an Internet connection.
          </p>
          <h2>E-PUL platform security and reliability</h2>
          <ul>
            <li role="presentation">
              The legality and safety of the service are confirmed by state and
              international certificates. They are listed on the website.
            </li>
            <li role="presentation">
              The resource works with the largest insurance companies. You can
              enter the desired service in the search field. This function
              simplifies the payment process.
            </li>
            <li role="presentation">
              When you pay for insurance online, the commission rate will be
              lower. You can read the terms on the website or contact our
              operators online, by phone.
            </li>
          </ul>
          <p>
            The terms of service are simple and transparent. The information is
            clear and accessible to everyone. All payment instructions are
            detailed.
          </p>
          <p>
            It will take a couple of minutes to add funds to your insurance
            account. If you are making payments via this service for the first
            time, you can take advantage of our staff consultations. You will
            see that this service is the best solution! All types of insurance
            are just a few clicks away.
          </p>
        </>
      ),
      160: 'pay online a insurance for the car, pay online a insurance, check a insurance online, pay online a insurance ateshgah.',
      727: 'pay online a insurance for the car, pay online a insurance, check a insurance online, pay online a insurance baki sigorta.',
      715: 'pay online a insurance for the car, pay online a insurance, check a insurance online, pay online a insurance pasha.',
      783: 'pay online a insurance for the car, pay online a insurance, check a insurance online, pay online a insurance qala heyat.',
      900: 'pay online a insurance for the car, pay online a insurance, check a insurance online, pay online a insurance meqa.',
      930: 'pay online a insurance for the car, pay online a insurance, check a insurance online, pay online a insurance ata.',
    },
    notFoundDescription:
      'EPUL online payment system. Pay Online, Save your payment history, Pay your bills automatically, Transfer money from card to card and Gain BONUSES',
    notFoundKeywords:
      'online payment, pay online, transfer money from card to card, utilities online pay, mobile operators online pay, internet providers online pay, tv providers online pay, stationary phones online pay, entertainment services online pay, other services online pay, banking online pay, payment system online pay, insurance online pay, education online pay, penalties online pay.',
  },
  ru: {
    description:
      'в Баку ✅ безопасно 💳 с любой банковской карты Азербайджана  ⚡️ быстро ✨ надежно 👍 просто 📞 *1919 | EPUL',
    title: 'в Баку через интернет | EPUL',
    mobile: {
      content: (
        <>
          <h2>Выгодное и удобное пополнение мобильного телефона</h2>
          <p>
            Нужно пополнить мобильный телефон онлайн? Воспользоваться
            терминалом, отправиться за картой пополнения или выбрать другую
            альтернативу? Подобная услуга доступна в смартфоне и браузере
            компьютера. Сервис позволяет пополнить мобильный счет быстро и
            просто. Все операторы Азербайджана собраны на одной платформе.
            Минимальная комиссия, высокая скорость выполнения задачи и удобство
            для каждого пользователя.
          </p>
          <p>
            Платеж за пользование связью – это действительно легко. Сайт сервиса
            E-PUL поможет круглосуточно пополнять телефон без усилий. Для вас
            актуален самый легкий способ, включающий всех операторов
            одновременно.
          </p>
          <h2>Как внести оплату мобильной связи?</h2>
          <ol>
            <li>
              Выберите наиболее удобный вариант: регистрацию на сайте или в
              приложении. Платформа доступна на iOS и Android.
            </li>
            <li>
              “Привяжите” активную банковскую карту или откройте виртуальную в
              системе.
            </li>
            <li>Выберите оператора – и пополните баланс номера телефона.</li>
            <li>За считанные минуты средства поступят на счет.</li>
          </ol>
          <p>
            Все быстро, просто, легко! У сервиса понятный с первого взгляда
            интерфейс. Инструкции необходимых действий расписаны подробно,
            пошагово. Также здесь представлены ответы на самые распространенные
            вопросы. Нужна помощь? Получить ее можно на сайте в соответствующем
            разделе.
          </p>
          <h2>Чем хороша оплата мобильного телефона в E-PUL?</h2>
          <ul>
            <li>
              Все операторы Азербайджана, международные операторы собраны на
              сайте. Несколько кликов – и готов результат.
            </li>
            <li>
              Удобство и простота для каждого клиента. Внесение оплаты
              осуществляется посредством смартфона, браузера в компьютере.
              Средства поступают на счет предельно быстро, без заминок.
            </li>
            <li>Возможность внести средства в любой момент.</li>
            <li>
              Использование ресурса абсолютно безопасно. Работа сервиса
              подтверждена соответствующими сертификатами.
            </li>
            <li>
              “Виртуальная” карта, открытая в приложении, может использоваться
              только для данных платежей. Можно применять и уже имеющуюся
              банковскую карту.
            </li>
          </ul>
          <p>
            Пополнение в Баку доступно 24/7. Выбирайте удобный E-PUL в телефоне
            или компьютере. Экономьте время на проведении платежей. Максимальная
            простота и надежность предложена всем клиентам!
          </p>
        </>
      ),
      22: 'азерсел, азерсел пополнить баланс онлайн, азерсел узнать долг онлайн, азерсел оплатить долг онлайн, сим пополнить баланс онлайн, азерсель загрузить контуры онлайн, сим загрузить контуры.',
      21: 'азерсел, азерсел пополнить баланс онлайн, азерсел узнать долг онлайн, азерсел оплатить долг онлайн, сим пополнить баланс онлайн, азерсель загрузить контуры онлайн, сим загрузить контуры.',
      142: 'баксел, баксел пополнить баланс онлайн, джин пополнить баланс онлайн, баксел загрузить контуры онлайн, джин загрузить контуры.',
      790: 'баксел, баксел пополнить баланс онлайн, джин пополнить баланс онлайн, баксел загрузить контуры онлайн, джин загрузить контуры.',
      157: 'нар, нар пополнить баланс онлайн, нар  загрузить контуры онлайн.',
      402: 'нар, нар пополнить баланс онлайн, нар  загрузить контуры онлайн.',
      791: 'нар, нар пополнить баланс онлайн, нар  загрузить контуры онлайн.',
      614: 'билайн, билайн грузия пополнить баланс онлайн, beeline пополнить.',
      619: 'билайн, билайн казахстан пополнить баланс онлайн, beeline пополнить баланс онлайн.',
      617: 'билайн, билайн киргизия пополнить баланс онлайн, beeline пополнить.',
      615: 'билайн, билайн россия пополнить баланс онлайн, beeline пополнить.',
      618: 'билайн, билайн таджикистан пополнить баланс онлайн, beeline пополнить.',
      616: 'билайн, билайн украина пополнить баланс онлайн, beeline пополнить.',
      393: 'нахтел, нахтел пополнить баланс онлайн, нахтел пополнить.',
      710: 'фринет, фринет пополнить баланс онлайн, фринет пополнить.',
      620: 'госел, госел пополнить баланс онлайн, госел пополнить.',
      794: 'киевстар, киевстар пополнить баланс онлайн, киевстар пополнить.',
      793: 'лайфсел, лайфсел пополнить баланс онлайн, лайфсел пополнить.',
      792: 'магафон, магафон пополнить баланс онлайн, магафон пополнить.',
      621: 'mtc, mtc пополнить баланс онлайн, mtc пополнить.',
      622: 'mtc, mtc пополнить баланс онлайн, mtc пополнить.',
      568: 'skype, скайп пополнить баланс онлайн, скайп пополнить.',
      755: 'вайбер, виюер пополнить баланс онлайн, вайбер пополнить.',
      906: 'simtravel, симтравол пополнить баланс онлайн, симтравол пополнить.',
      907: 'tele2, теле2 пополнить баланс онлайн, теле2 пополнить.',
      908: 'yota, yota пополнить баланс онлайн, yota пополнить.',
    },
    utilities: {
      content: (
        <>
          <h2>Оплата коммунальных услуг: просто и быстро!</h2>
          <p>
            Оплата коммунальных услуг в Интернете – настоящая находка! Отличное
            предложение современности, позволяющее отправлять средства онлайн.
            Забудьте о стоянии в очередях – это прошлый век. Теперь внесение
            средств за коммунальные платежи на счет станет легким и приятным.
            Минимум усилий и временных затрат. Сохранение шаблонов и возможность
            оплатить ком услуги в пару кликов. Все это доступно уже сейчас!
            Самый простой способ “живет” в вашем смартфоне.
          </p>
          <p>
            E-PUL – электронная платежная система. Удобство – наше второе имя.
            Достаточно зарегистрироваться на платформе! Дальше платежный сервис
            предоставляет все виды жилищных оплат в одном “флаконе”! Без
            очередей. Без длительного ожидания. Без проблем! Потратьте время на
            более приятные занятия, чем внесение квартплаты!
          </p>
          <h2>Как работает система?</h2>
          <ul>
            <li>Регистрация, которая займет считанные минуты.</li>
            <li>
              Привязка карты банка или открытие виртуальной карточки. Если
              предпочитаете виртуальную карту, откройте ее в несколько кликов.
              Пополнение возможно посредством использования электронных
              кошельков.
            </li>
            <li>
              Совершайте разнообразные платежи через браузер или в приложении.
              Достаточно ввести нужную услугу – и заполнить реквизиты. На
              будущее сохраняются шаблоны для большего удобства.
            </li>
            <li>Экономьте свое время и силы! Оплачивайте из любой точки!</li>
          </ul>
          <h2>Преимущества E-PUL</h2>
          <ul>
            <li>
              Оплата коммунальных услуг в Баку в одном приложении. Вносите
              средства в платежном сервисе в любое время суток оперативно,
              просто.
            </li>
            <li>
              Возможность пользоваться виртуальной картой. Вместо основной
              карточки банка, откройте виртуальную. Она может применяться только
              для платы за “коммуналку”.
            </li>
            <li>
              Надежность работы сервиса в Баку и Азербайджане подтверждена
              сертификатами. Система абсолютно безопасна. Условия сотрудничества
              прозрачны и понятны для всех.
            </li>
            <li>
              Экономия времени, сил, средств. Оплата за электроэнергию, газ,
              воду и т.д. всегда находится под рукой!
            </li>
            <li>
              Приложение работает на всех смартфонах. Воспользоваться им могут
              поклонники Android и iOS.
            </li>
            <li>
              Для клиентов доступны бонусы. О специальных выгодах можно узнать
              больше на сайте.
            </li>
            <li>
              Интерфейс интуитивно понятен. Есть вопросы? Воспользуйтесь
              рекомендациями и советами.
            </li>
          </ul>
          <p>
            Внесение коммунальных платежей стало простой и приятной процедурой.
            В приложении или через браузер – никакой разницы! Экономьте время и
            усилия, забудьте об ожидании. Контролируйте платежи из смартфона или
            других гаджетов.
          </p>
        </>
      ),
      425: 'азерсу оплатить через смарт карт,узнать долг за воду, заплатить за воду онлайн смарт картой,пополнить баланс карты за воду.',
      127: 'азеригаз оплатить через смарт карт,узнать долг за газ, заплатить за газ онлайн смарт картой,пополнить баланс карты за газ.',
      130: 'азеригаз оплатить через смарт карт,узнать долг за газ, заплатить за газ онлайн смарт картой,пополнить баланс карты за газ.',
      144: 'азеригаз оплатить онлайн,узнать долг за газ, заплатить за газ онлайн.',
      515: 'азеришиг оплатить онлайн,узнать долг за свет, заплатить за свет онлайн.',
      132: 'азерсу оплатить через, узнать долг за воду, заплатить за воду онлайн.',
      516: 'азерсу оплатить через, узнать долг за воду, заплатить за воду онлайн.',
      389: 'азеристилик, азеристилик оплатить через, узнать долг за отопление , заплатить за отопление онлайн.',
      547: 'азеристилик, азеристилик оплатить через, узнать долг за отопление , заплатить за отопление онлайн.',
      394: 'нахсу оплатить через, узнать долг за воду нахчиван, заплатить за воду онлайн нахчиван.',
      396: 'нахишиг оплатить онлайн, узнать долг за свет нахчиван, заплатить за свет онлайн нахчиван.',
      395: 'нахгаз оплатить онлайн,узнать долг за газ нахчиван, заплатить за газ онлайн нахчиван.',
    },
    phone: {
      content: (
        <>
          <h2>
            Онлайн оплата телефона с EPUL – простота и удобство для каждого!{' '}
          </h2>
          <p>
            Онлайн оплата телефона существенно упрощает пользование подобной
            услугой. Сегодня стационарный телефон преимущественно нужен
            организациям. Ожидание в рамках корпоративной этики – это лишнее.
            Поэтому наш сервис предлагает возможность забыть об очередях. Данная
            услуга теперь доступна в интернете!{' '}
          </p>
          <p>
            Достаточно зайти на сайт EPUL – и совершить платеж. Минимум усилий и
            минимум затрат времени! Подобные платежи подходят даже новичкам в
            интернете. Сервис создан для вашего удобства. Все операции по
            внесению средств находятся под рукой.
          </p>
          <h2>Как проходит оплата телефона в Баку?</h2>
          <ul>
            <li>
              На страницах сервиса собраны все операторы Баку и Азербайджана.
              Выберите любой из списка или введите название в строку поиска.
            </li>
            <li>
              Осуществить процедуру можно после регистрации. Этот этап понятен
              интуитивно. Сервис также предлагает видео-инструкцию. За считанные
              минуты вы получите свой аккаунт. При необходимости будет
              предоставлена помощь онлайн-поддержки.
            </li>
            <li>
              Воспользуйтесь открытой “виртуальной” картой. Или любой картой
              банка Азербайджана. Пополнение виртуальной карты возможно через
              электронные кошельки. После регистрации система предложит разные
              варианты выполнения задачи.
            </li>
            <li>
              Введите номер и соответствующий регион из выпадающего списка – и
              деньги поступят на счет.
            </li>
          </ul>
          <p>
            Быстро, просто и результативно. Операция практически мгновенна.
            Перед вами оптимальный вариант, экономящий время. Оплата домашнего
            телефона через интернет стала предельно простой! Отметим, что для
            стационарных компьютеров возможен доступ через браузер. Пользователи
            мобильных гаджетов могут скачать приложение. Достаточно иметь
            мобильную сеть, чтобы легко положить средства на счет.
          </p>
          <p>
            С одного аккаунта отправляйте деньги на несколько номеров! Оплатите
            телефонную связь себе и близким в пару кликов!
          </p>
          <h2>Преимущества EPUL</h2>
          <ul>
            <li>
              Удобство и мобильность. Нужно быть в сети для отправки средств на
              домашний номер.
            </li>
            <li>
              Все операторы и регионы собраны в одном приложении. Вы можете
              оставаться дома и открыть всего одну вкладку!
            </li>
            <li>
              Есть возможность пользоваться картой банка Азербайджана или
              открыть виртуальную. Для пополнения последней подходят все
              онлайн-кошельки.
            </li>
            <li>
              Существует система бонусов, позволяющая экономить средства. Оплата
              домашнего телефона с EPUL – это выгодно! Убедиться в этом
              исключительно легко на собственном примере.
            </li>
          </ul>
          <p>
            Выбирайте EPUL – и будьте всегда на связи, контролируя все платежи.
          </p>
        </>
      ),
      386: 'азтелеком телефон онлайн оплатить долг, узнать долг азтелеком телефон, оплатить долг азтелеком телефона, узнать долг азтелеком телефона.',
      803: 'азтелеком телефон онлайн оплатить долг, узнать долг азтелеком телефон, оплатить долг азтелеком телефона, узнать долг азтелеком телефона.',
      816: 'азевротел телефон онлайн оплатить долг, узнать долг азевротел телефон, оплатить долг азевротел телефона, узнать долг азевротел телефона.',
      815: 'трансевроком телефон онлайн оплатить долг, узнать долг трансевроком телефон, оплатить долг трансевроком телефона, узнать долг трансевроком телефона.',
      163: 'ультел телефон онлайн оплатить долг, узнать долг ультел телефон, оплатить долг ультел телефона, узнать долг ультел телефона.',
      932: 'ультел телефон онлайн оплатить долг, узнать долг ультел телефон, оплатить долг ультел телефона, узнать долг ультел телефона.',
      146: 'катель телефон онлайн оплатить долг, узнать долг катель телефон, оплатить долг катель телефона, узнать долг катель телефона.',
      934: 'нахчиван телефон онлайн оплатить долг, узнать долг нахчиван телефон, оплатить долг нахчиван телефона, узнать долг нахчиван телефона.',
      399: 'нахчиван телефон онлайн оплатить долг, узнать долг нахчиван телефон, оплатить долг нахчиван телефона, узнать долг нахчиван телефона.',
    },
    tv: {
      content: (
        <>
          <h2>Как оплатить ТВ просто и легко?</h2>
          <p>
            Возможность оплатить ТВ онлайн всегда актуальна. Оставайтесь на
            связи и смотрите любимые каналы с EPUL. Наш сервис предлагает
            простую и нужную услугу. Вносите средства на счет провайдера в любое
            время. Для этого просто установите удобное приложение. В одном
            сервисе собрано все кабельное телевидение Азербайджана. Найдите
            своего поставщика услуг – и наслаждайтесь полюбившимися программами!
          </p>
          <p>
            Простота и легкость “подкупят” даже новичков. Ведь для пользования
            ресурсом нужно минимум усилий и времени.
          </p>
          <h2>Как осуществляется оплата интернет ТВ через EPUL?</h2>
          <ul>
            <li>
              Первый шаг – регистрация. Потребуется минимум данных и несколько
              минут. Процедура интуитивно понятна. Но, если есть необходимость,
              клиент может обратиться в поддержку. Помощь доступна через
              онлайн-чат или в телефонном режиме.
            </li>
            <li>
              Далее выберите провайдера из списка или посредством использования
              строки поиска.
            </li>
            <li>
              Введите номер счета, убедитесь, что он написан верно. После
              перечислите средства. На данном этапе есть несколько способов.
              Можно воспользоваться имеющейся банковской картой или открыть
              новую. Виртуальная карточка помогает качественнее отслеживать
              средства на оплату ТВ. Пополнение такой карты возможно через
              онлайн-кошельки.
            </li>
            <li>
              Деньги поступят предельно быстро. Даже в экстренном случае вы
              успеете подключиться до начала любимого шоу!
            </li>
          </ul>
          <p>
            Система максимально проста и удобна. Пользуетесь смартфоном? Тогда
            скачивайте приложение. Предпочитаете вносить средства через браузер?
            Просто сохраните логин и пароль во вкладке! Оплата TV провайдера
            через интернет в Баку пройдет предельно легко. Оставьте время для
            более приятных дел и развлечений.
          </p>
          <h2>Сервис EPUL: основные преимущества для пользователей</h2>
          <ul>
            <li>Все провайдеры на одной площадке.</li>
            <li>
              Простота и легкость внесения средств. Вариативность разных
              способов оплаты.
            </li>
            <li>
              Возможность выбирать сотрудничество с лучшими. Мы гарантируем
              надежность каждого проведенного платежа.
            </li>
            <li>Высокая скорость обработки заявок, исполнения операций.</li>
            <li>
              Прозрачность условий. Размер комиссии указывается непосредственно
              на сайте.
            </li>
            <li>
              Помощь профессионалов. Задайте интересующие вопросы – и получите
              исчерпывающую информацию! Разберется абсолютно любой пользователь
              без лишних усилий!
            </li>
          </ul>
          <p>
            Оплата телевидения онлайн в Баку посредством EPUL – это специальные
            бонусы. Выгодные предложения и система поощрений ожидают наших
            клиентов. Смотрите кабельное телевидение с удовольствием вместе с
            нашим ресурсом!
          </p>
        </>
      ),
      173: 'онлайн оплатить долг atv+, узнать долг atv+, оплатить долг атв плюс, узнать долг онлайн атв+, оплатить кабель тв атв+ картой',
      752: 'онлайн оплатить долг ailetv iptv, узнать долг ailetv iptv, узнать долг онлайн ailetv iptv, оплатить кабель ailetv iptv картой',
      121: 'онлайн оплатить долг ailetv, узнать долг ailetv, узнать долг онлайн ailetv, оплатить кабель ailetv картой',
      143: 'онлайн оплатить долг bbtv, узнать долг bbtv, узнать долг онлайн bbtv, оплатить кабель bbtv картой',
      147: 'онлайн оплатить долг connect tv, узнать долг connect tv, узнать долг онлайн connect tv, оплатить кабель connect tv картой',
      780: 'онлайн оплатить долг nn tv, узнать долг nn tv, узнать долг онлайн nn tv, оплатить кабель nn tv картой',
      427: 'онлайн оплатить долг nar home tv, узнать долг nar home tv, узнать долг онлайн nar home tv, оплатить кабель nar home tv картой',
      265: 'онлайн оплатить долг ra tv, узнать долг ra tv, узнать долг онлайн ra tv, оплатить кабель ra tv картой',
      398: 'онлайн оплатить долг rahat tv, узнать долг rahat tv, узнать долг онлайн rahat tv, оплатить кабель rahat tv картой',
      801: 'онлайн оплатить долг rahat tv, узнать долг rahat tv, узнать долг онлайн rahat tv, оплатить кабель rahat tv картой',
      763: 'онлайн оплатить долг sn tv, узнать долг sn tv, узнать долг онлайн sn tv, оплатить кабель sn tv картой',
      153: 'онлайн оплатить долг katv, узнать долг katv, узнать долг онлайн katv, оплатить кабель katv картой',
      929: 'онлайн оплатить долг katv, узнать долг katv, узнать долг онлайн katv, оплатить кабель katv картой',
      178: 'онлайн оплатить долг ultel ip tv, узнать долг ultel ip tv, узнать долг онлайн ultel ip tv, оплатить кабель ultel ip tv картой',
      933: 'онлайн оплатить долг ultel ip tv, узнать долг ultel ip tv, узнать долг онлайн ultel ip tv, оплатить кабель ultel ip tv картой',
    },
    internet: {
      content: (
        <>
          <h2>Оплата счетов за интернет в один клик</h2>
          <p>
            Как оплатить интернет банковской картой с минимальными потерями?
            Деньги на счету обычно заканчиваются в самое неудобное время.
            Оптимальный способ пополнить быстро – воспользоваться E-PUL. Перед
            вами ресурс, доступный на любой платформе. Для использования
            подойдет любой гаджет: компьютер, планшет, смартфон. Сервис работает
            на Android, iOS, через сайт. Скачайте приложение или пройдите
            регистрацию на сайте. Это поможет всегда иметь возможность пополнить
            счет. В любое время суток и в любую погоду.
          </p>
          <h2>Как пополнить интернет с телефона в Баку?</h2>
          <ol>
            <li>
              Установите соответствующее приложение. Зарегистрируйтесь в нем.
              Регистрация проста, понятна интуитивно, занимает считанные минуты.
            </li>
            <li>
              Для выполнения задачи можно воспользоваться активной банковской
              карточкой. Также есть возможность “открыть” виртуальную карту. Она
              пополняется из вашей карты или электронных кошельков. Процедура
              осуществляется очень быстро.
            </li>
            <li>
              Выбрать соответствующую услугу на сайте. Найти своего провайдера.
              Внести средства, воспользовавшись нужной картой. Деньги
              зачисляются моментально.
            </li>
            <li> Наслаждаться свободным доступом во всемирную сеть!</li>
          </ol>
          <p>
            Устали запоминать сайт для оплаты услуг? Установите приложение!
            Заплатить за интернет будет предельно легко. Минимум усилий – и
            готовый результат. Все регулярные платежи можно собрать в одном
            месте.
          </p>
          <h2>Плюсы E-PUL для пользователей</h2>
          <ul>
            <li>
              Максимальное удобство. Достаточно сделать всего пару кликов.
              Подходит даже для новичков. Оплатить услуги любого провайдера в
              Азербайджане можно на одном ресурсе. Откройте соответствующий
              раздел, следуйте инструкции.
            </li>
            <li>
              Доступность и простота интерфейса. Информация о внесении
              финансовых средств подана понятно. С первого знакомства легко
              оплатить интернет онлайн.
            </li>
            <li>
              Надежность сервиса. E-PUL – первый ресурс, собравший столько
              онлайн возможностей. Мы сотрудничаем с крупнейшими
              азербайджанскими компаниями. Необходимая сумма поступит “по
              адресу” исключительно быстро.
            </li>
            <li>
              Возможность оплатить услуги любой компании. Для вашего удобства
              доступна функция поиска.
            </li>
          </ul>
          <p>
            Есть вопросы? Свяжитесь со специалистом в чате. Дополнительную
            информацию и советы по оплате можно получить оперативно.
          </p>
          <p>
            При отправке средств на счет компании, предоставляющей услуги, важна
            скорость. Чем быстрее поступят деньги, тем лучше. Сэкономить и
            сохранить положительный баланс легко. Просто воспользуйтесь нашим
            ресурсом!
          </p>
        </>
      ),
      165: 'онлайн оплатить долг интернет activeol, узнать долг интернет activeol, оплатить долг интернет активол, узнать долг онлайн интернет активол, оплатить за интернет активол онлайн картой',
      806: 'онлайн оплатить долг интернет baktelecom.net, узнать долг интернет baktelecom.net, оплатить долг интернет baktelecom.net, узнать долг онлайн интернет baktelecom.net, оплатить за интернет baktelecom.net онлайн картой',
      808: 'онлайн оплатить долг интернет baktelecom.net, узнать долг интернет baktelecom.net, оплатить долг интернет baktelecom.net, узнать долг онлайн интернет baktelecom.net, оплатить за интернет baktelecom.net онлайн картой',
      123: 'онлайн оплатить долг интернет aile net, узнать долг интернет aile net, оплатить долг интернет aile net, узнать долг онлайн интернет aile net, оплатить за интернет aile net онлайн картой',
      404: 'онлайн оплатить долг интернет azevrotel, узнать долг интернет azevrotel, оплатить долг интернет azevrotel, узнать долг онлайн интернет azevrotel, оплатить за интернет azevrotel онлайн картой',
      818: 'онлайн оплатить долг интернет azevrotel, узнать долг интернет azevrotel, оплатить долг интернет azevrotel, узнать долг онлайн интернет azevrotel, оплатить за интернет azevrotel онлайн картой',
      135: 'онлайн оплатить долг интернет azevrotel, узнать долг интернет azevrotel, оплатить долг интернет azevrotel, узнать долг онлайн интернет azevrotel, оплатить за интернет azevrotel онлайн картой',
      820: 'онлайн оплатить долг интернет azdatakom, узнать долг интернет azdatakom, оплатить долг интернет azdatakom, узнать долг онлайн интернет azdatakom, оплатить за интернет azdatakom онлайн картой',
      125: 'онлайн оплатить долг интернет avirtel, узнать долг интернет avirtel, оплатить долг интернет avirtel, узнать долг онлайн интернет avirtel, оплатить за интернет avirtel онлайн картой',
      804: 'онлайн оплатить долг интернет aztelekom.net, узнать долг интернет aztelekom.net, оплатить долг интернет aztelekom.net, узнать долг онлайн интернет aztelekom.net, оплатить за интернет aztelekom.net онлайн картой',
      807: 'онлайн оплатить долг интернет aztelekom.net, узнать долг интернет aztelekom.net, оплатить долг интернет aztelekom.net, узнать долг онлайн интернет aztelekom.net, оплатить за интернет aztelekom.net онлайн картой',
      124: 'онлайн оплатить долг интернет aflanet, узнать долг интернет aflanet, оплатить долг интернет aflanet, узнать долг онлайн интернет aflanet, оплатить за интернет aflanet онлайн картой',
      384: 'онлайн оплатить долг интернет aflanet, узнать долг интернет aflanet, оплатить долг интернет aflanet, узнать долг онлайн интернет aflanet, оплатить за интернет aflanet онлайн картой',
      810: 'онлайн оплатить долг интернет azfibernet, узнать долг интернет azfibernet, оплатить долг интернет azfibernet, узнать долг онлайн интернет azfibernet, оплатить за интернет azfibernet онлайн картой',
      510: 'онлайн оплатить долг интернет azfibernet, узнать долг интернет azfibernet, оплатить долг интернет azfibernet, узнать долг онлайн интернет azfibernet, оплатить за интернет azfibernet онлайн картой',
      682: 'онлайн оплатить долг интернет azgoldnet, узнать долг интернет azgoldnet, оплатить долг интернет azgoldnet, узнать долг онлайн интернет azgoldnet, оплатить за интернет azgoldnet онлайн картой',
      403: 'онлайн оплатить долг интернет alfanet optik, узнать долг интернет alfanet optik, оплатить долг интернет alfanet optik, узнать долг онлайн интернет alfanet optik, оплатить за интернет alfanet optik онлайн картой',
      148: 'онлайн оплатить долг интернет connect, узнать долг интернет connect, оплатить долг интернет connect, узнать долг онлайн интернет connect, оплатить за интернет connect онлайн картой',
      166: 'онлайн оплатить долг интернет connect fiber, узнать долг интернет connect fiber, оплатить долг интернет connect fiber, узнать долг онлайн интернет connect fiber, оплатить за интернет connect fiber онлайн картой',
      149: 'онлайн оплатить долг интернет teleport, узнать долг интернет teleport, оплатить долг интернет teleport, узнать долг онлайн интернет teleport, оплатить за интернет teleport онлайн картой.',
      22: 'онлайн оплатить долг интернет birlink, узнать долг интернет birlink, оплатить долг интернет birlink, узнать долг онлайн интернет birlink, оплатить за интернет birlink онлайн картой.',
      34: 'онлайн оплатить долг интернет birlink, узнать долг интернет birlink, оплатить долг интернет birlink, узнать долг онлайн интернет birlink, оплатить за интернет birlink онлайн картой.',
      782: 'онлайн оплатить долг интернет fibernet, узнать долг интернет fibernet, оплатить долг интернет fibernet, узнать долг онлайн интернет fibernet, оплатить за интернет fibernet онлайн картой.',
      512: 'онлайн оплатить долг интернет fibernet telekom, узнать долг интернет fibernet telekom, оплатить долг интернет fibernet telekom, узнать долг онлайн интернет fibernet telekom, оплатить за интернет fibernet telekom онлайн картой.',
      167: 'онлайн оплатить долг интернет izone, узнать долг интернет izone, оплатить долг интернет izone, узнать долг онлайн интернет izone, оплатить за интернет izone онлайн картой.',
      385: 'онлайн оплатить долг интернет citynet, узнать долг интернет citynet, оплатить долг интернет citynet, узнать долг онлайн интернет citynet, оплатить за интернет citynet онлайн картой.',
      426: 'онлайн оплатить долг интернет nar home, узнать долг интернет nar home, оплатить долг интернет nar home, узнать долг онлайн интернет nar home, оплатить за интернет nar home онлайн картой.',
      397: 'онлайн оплатить долг интернет naxinternet, узнать долг интернет naxinternet, оплатить долг интернет naxinternet, узнать долг онлайн интернет naxinternet, оплатить за интернет naxinternet онлайн картой.',
      802: 'онлайн оплатить долг интернет sazz, узнать долг интернет sazz, оплатить долг интернет sazz, узнать долг онлайн интернет sazz, оплатить за интернет sazz онлайн картой.',
      761: 'онлайн оплатить долг интернет gamma skynet, узнать долг интернет gamma skynet, оплатить долг интернет gamma skynet, узнать долг онлайн интернет gamma skynet, оплатить за интернет gamma skynet онлайн картой.',
      161: 'онлайн оплатить долг интернет smart online, узнать долг интернет smart online, оплатить долг интернет smart online, узнать долг онлайн интернет smart online, оплатить за интернет smart online онлайн картой.',
      763: 'онлайн оплатить долг интернет sntv, узнать долг интернет sntv, оплатить долг интернет sntv, узнать долг онлайн интернет sntv, оплатить за интернет sntv онлайн картой',
      387: 'онлайн оплатить долг интернет uninet, узнать долг интернет uninet, оплатить долг интернет uninet, узнать долг онлайн интернет uninet, оплатить за интернет uninet онлайн картой',
      488: 'онлайн оплатить долг интернет uninet, узнать долг интернет uninet, оплатить долг интернет uninet, узнать долг онлайн интернет uninet, оплатить за интернет uninet онлайн картой',
      131: 'онлайн оплатить долг интернет azeronline, узнать долг интернет azeronline, оплатить долг интернет azeronline, узнать долг онлайн интернет azeronline, оплатить за интернет azeronline онлайн картой',
      917: 'онлайн оплатить долг интернет azeronline, узнать долг интернет azeronline, оплатить долг интернет azeronline, узнать долг онлайн интернет azeronline, оплатить за интернет azeronline онлайн картой',
      136: 'онлайн оплатить долг интернет azstarnet, узнать долг интернет azstarnet, оплатить долг интернет azstarnet, узнать долг онлайн интернет azstarnet, оплатить за интернет azstarnet онлайн картой',
      919: 'онлайн оплатить долг интернет azstarnet, узнать долг интернет azstarnet, оплатить долг интернет azstarnet, узнать долг онлайн интернет azstarnet, оплатить за интернет azstarnet онлайн картой',
      150: 'онлайн оплатить долг интернет dataplus, узнать долг интернет dataplus, оплатить долг интернет dataplus, узнать долг онлайн интернет dataplus, оплатить за интернет dataplus онлайн картой',
      920: 'онлайн оплатить долг интернет dataplus, узнать долг интернет dataplus, оплатить долг интернет dataplus, узнать долг онлайн интернет dataplus, оплатить за интернет dataplus онлайн картой',
      152: 'онлайн оплатить долг интернет eurosel, узнать долг интернет eurosel, оплатить долг интернет eurosel, узнать долг онлайн интернет eurosel, оплатить за интернет eurosel онлайн картой.',
      921: 'онлайн оплатить долг интернет eurosel, узнать долг интернет eurosel, оплатить долг интернет eurosel, узнать долг онлайн интернет eurosel, оплатить за интернет eurosel онлайн картой.',
      171: 'онлайн оплатить долг интернет globalnetworks, узнать долг интернет globalnetworks, оплатить долг интернет globalnetworks, узнать долг онлайн интернет globalnetworks, оплатить за интернет globalnetworks онлайн картой.',
      922: 'онлайн оплатить долг интернет globalnetworks, узнать долг интернет globalnetworks, оплатить долг интернет globalnetworks, узнать долг онлайн интернет globalnetworks, оплатить за интернет globalnetworks онлайн картой.',
      156: 'онлайн оплатить долг интернет metronet, узнать долг интернет metronet, оплатить долг интернет metronet, узнать долг онлайн интернет metronet, оплатить за интернет metronet онлайн картой.',
      923: 'онлайн оплатить долг интернет metronet, узнать долг интернет metronet, оплатить долг интернет metronet, узнать долг онлайн интернет metronet, оплатить за интернет metronet онлайн картой.',
      170: 'онлайн оплатить долг интернет pilot, узнать долг интернет pilot, оплатить долг интернет pilot, узнать долг онлайн интернет pilot, оплатить за интернет pilot онлайн картой.',
      925: 'онлайн оплатить долг интернет pilot, узнать долг интернет pilot, оплатить долг интернет pilot, узнать долг онлайн интернет pilot, оплатить за интернет pilot онлайн картой.',
      172: 'онлайн оплатить долг интернет telekomInvest, узнать долг интернет telekomInvest, оплатить долг интернет telekomInvest, узнать долг онлайн интернет telekomInvest, оплатить за интернет telekomInvest онлайн картой.',
      926: 'онлайн оплатить долг интернет telekomInvest, узнать долг интернет telekomInvest, оплатить долг интернет telekomInvest, узнать долг онлайн интернет telekomInvest, оплатить за интернет telekomInvest онлайн картой.',
      391: 'онлайн оплатить долг интернет super online, узнать долг интернет super online, оплатить долг интернет super online, узнать долг онлайн интернет super online, оплатить за интернет super online онлайн картой.',
      927: 'онлайн оплатить долг интернет super online, узнать долг интернет super online, оплатить долг интернет super online, узнать долг онлайн интернет super online, оплатить за интернет super online онлайн картой.',
      168: 'онлайн оплатить долг интернет transeurocom, узнать долг интернет transeurocom, оплатить долг интернет transeurocom, узнать долг онлайн интернет transeurocom, оплатить за интернет transeurocom онлайн картой.',
      928: 'онлайн оплатить долг интернет transeurocom, узнать долг интернет transeurocom, оплатить долг интернет transeurocom, узнать долг онлайн интернет transeurocom, оплатить за интернет transeurocom онлайн картой.',
      154: 'онлайн оплатить долг интернет katv, узнать долг интернет katv, оплатить долг интернет katv, узнать долг онлайн интернет katv, оплатить за интернет katv онлайн картой.',
      918: 'онлайн оплатить долг интернет katv, узнать долг интернет katv, оплатить долг интернет katv, узнать долг онлайн интернет katv, оплатить за интернет katv онлайн картой.',
      162: 'онлайн оплатить долг интернет ultel, узнать долг интернет ultel, оплатить долг интернет ultel, узнать долг онлайн интернет ultel, оплатить за интернет ultel онлайн картой.',
      931: 'онлайн оплатить долг интернет ultel, узнать долг интернет ultel, оплатить долг интернет ultel, узнать долг онлайн интернет ultel, оплатить за интернет ultel онлайн картой.',
    },
    education: {
      781: 'онлайн платежи абитуриент, купить абитуриент журнал онлайн.',
      753: 'онлайн платежи dim, dim оплатить онлайн.',
      748: 'онлайн платежи eas, eas оплатить онлайн.',
      500: 'онлайн платежи edutech, edutech оплатить онлайн.',
      754: 'онлайн платежи it step, it step оплатить онлайн.',
      905: 'онлайн оплатить долг coca cola, узнать долг coca cola, оплатить долг coca cola картой, узнать долг онлайн coca cola.',
      935: 'bakuelectronics оплатить онлайн, оплатить долг баку electronics, выяснить долг баку electronics, оплатить кредит bakuelectronics',
      950: 'kontakt home оплатить онлайн, контакт дома задолженности, контакт дома платить кредит, контакт дома платить долг',
    },
    transport: {
      767: 'такси 189, такси 189 оплата онлайн, сервис такси 189 оплата онлайн',
    },
    government: {
      901: 'онлайн оплатить университет по стандартам долга, узнать долг университета по стандартам',
      826: 'онлайн платежный центр по долгу патентов и товарных знаков, узнайте долг центра по патентам и товарным знакам',
      964: 'онлайн оплату госуслуг миграционной службы, выяснение долга государственной миграционной службы',
      965: 'online pay ministries of internal affairs debt, find out the debt of ministries of internal affairs',
    },
    online: {
      content: (
        <>
          <h2>Электронные деньги всегда с вами вместе с EPUL</h2>
          <p>
            Системы электронных денег подарили настоящую свободу и удобство.
            Покупки за границей и международные переводы стали реальностью.
            Электронные кошельки просты в эксплуатации и не требуют особых
            навыков. Однако для пользования ими нужен универсальный инструмент.
          </p>
          <p>
            Сегодня вам доступна платформа, позволяющая управлять средствами
            любой системы. В одном приложении собраны все электронные деньги.
            EPUL – уникальный сервис. Он помогает совершать операции с
            онлайн-деньгами. Кошельки находятся на расстоянии в пару кликов.
            Пользователю достаточно пройти регистрацию – и убедиться в
            практичности ресурса!
          </p>
          <h2>Как сделать оплату онлайн? Пошаговый алгоритм</h2>
          <ul>
            <li>
              Зарегистрируйтесь в системе. Предварительно выберите работу в
              браузере или приложении. Для смартфонов приложение – лучший
              вариант. Регистрация проходит за несколько минут.
            </li>
            <li>
              Следующий этап – “привязать” карту банка или открыть виртуальную.
              Виртуальная сделает платежи максимально безопасными. Карта
              открывается предельно быстро. Ею можно расплачиваться,
              использовать как реквизит для перевода денег. Средства с
              виртуальной карты выводятся на банковскую. Доступно и
              обналичивание.
            </li>
            <li>
              Выберите нужную платежную систему. EPUL работает со всеми крупными
              системами в Азербайджане.
            </li>
            <li>
              Далее можно пополнить электронный кошелек онлайн в Баку за
              считанные минуты.
            </li>
          </ul>
          <p>
            Пользователь получает контроль за своими средствами 24/7. И все это
            – благодаря одному сервису, простому и понятному в работе.
          </p>
          <h2>Три причины выбрать EPUL</h2>
          <ul>
            <li>
              Безопасность переводов и гарантия сохранности средств. Для вас
              предложены прозрачные условия. Размер комиссии указан сразу на
              сайте.
            </li>
            <li>
              Понятный интерфейс и легкость эксплуатации. Минимум усилий – и
              готов желаемый результат. Удобное предложение, экономящее ваше
              время.
            </li>
            <li>
              Высокая скорость выполнения операций. Совершить перевод
              электронных денег можно оперативно. Услуга доступна круглосуточно!
            </li>
          </ul>
          <p>
            Отметим и специальные предложения. Сервис дарит приятные бонусы
            клиентам! Благодаря им, переводы станут еще проще и выгоднее.
            Новичков ожидают видео-подсказки для большего удобства.
          </p>
          <p>
            Хотите уточнить детали? Обратитесь за консультацией онлайн.
            Специалисты быстро ответят на поставленные вопросы. Мы заботимся о
            вашем финансовом комфорте. Выбирайте надежный сервис для управления
            деньгами онлайн! Оцените комфорт и удобство EPUL!
          </p>
        </>
      ),
      629: 'онлайн оплатить яндекс деньги, онлайн пополнить баланс яндекс деньги, онлайн положить деньги яндекс деньги',
      765: 'онлайн оплатить azcard, онлайн пополнить баланс azcard, онлайн положить деньги azcard',
      681: 'онлайн оплатить facebook игры, онлайн пополнить баланс facebook игры, онлайн положить деньги facebook игры',
      680: 'онлайн оплатить google play, онлайн пополнить баланс google play, онлайн положить деньги google play',
      633: 'онлайн оплатить moneta, онлайн пополнить баланс moneta, онлайн положить деньги moneta',
      624: 'онлайн оплатить qiwi euro, онлайн пополнить баланс qiwi euro, онлайн положить деньги qiwi euro',
      625: 'онлайн оплатить qiwi rub, онлайн пополнить баланс qiwi rub, онлайн положить деньги qiwi rub',
      623: 'онлайн оплатить qiwi usd, онлайн пополнить баланс qiwi usd, онлайн положить деньги qiwi usd',
      578: 'онлайн оплатить steam, онлайн пополнить баланс steam, онлайн положить деньги steam',
      632: 'онлайн оплатить webmoney wme, онлайн пополнить баланс webmoney wme, онлайн положить деньги webmoney wme',
      630: 'онлайн оплатить webmoney wmr, онлайн пополнить баланс webmoney wmr, онлайн положить деньги webmoney wmr',
      631: 'онлайн оплатить webmoney wmz, онлайн пополнить баланс webmoney wmz, онлайн положить деньги webmoney wmz',
      683: 'онлайн оплатить xbox lite, онлайн пополнить баланс xbox lite, онлайн положить деньги xbox lite',
    },
    // insurance: {
    //     830: "оплатить онлайн штраф за машину, оплатить онлайн штраф дпс, узнать штраф онлайн, оплатить онлайн штраф министерство внутренних дел",
    //     831: "оплатить онлайн штраф за налог, оплатить онлайн штраф министерство налогов",
    //     832: "оплатить онлайн штраф за таможню, узнать онлайн штраф за таможню, оплатить онлайн штраф государственного таможенного комитета",
    //     833: "узнать штраф онлайн , оплатить онлайн штраф министерства труда и социальной защиты",
    //     834: "узнать онлайн штраф, оплатить онлайн штраф министерства экологии и природных ресурсов"
    // },
    insurance: {
      content: (
        <>
          <h2>Оплата страховки онлайн</h2>
          <p>
            Пополнить страховку через Интернет в Азербайджане можно максимально
            быстро. Цените свое время и силы? Хотите быть уверены в том, что
            платеж пройдет оперативно? Воспользуйтесь сервисом E-PUL! На одной
            платформе мы собрали страховые компании Баку и Азербайджана. Все
            просто и понятно. Доступно всем желающим буквально в несколько
            кликов.
          </p>
          <p>
            Просто воспользуйтесь предложением посредством сайта или приложения.
            Регистрация займет несколько минут. Далее можно “привязать”
            актуальную банковскую карту или открыть виртуальную. Сервис работает
            на платформах Android, iOS. Оплачивать страховку можно из
            собственного телефона.
          </p>
          <h2>Как проходит оплата онлайн страховки в Баку?</h2>
          <ol>
            <li>Первый шаг – регистрация и выбор банковской карты.</li>
            <li>
              Далее выбираете нужный вид страховки. Для удобства можно
              воспользоваться поиском. Внимание! Есть виды страховок, в которых
              комиссия сервиса отсутствует.
            </li>
            <li>Вводите необходимые реквизиты – и осуществляете оплату.</li>
            <li>
              Средства поступают на счет страховой компании предельно быстро.
            </li>
          </ol>
          <p>
            Сайт работает, как часы: быстро, точно, безопасно, надежно. Это
            самый простой и удобный способ оплатить страховку. Он подходит всем,
            у кого есть выход в интернет. Независимо от вида гаджета.
          </p>
          <h2>Безопасность и надежность платформы E-PUL</h2>
          <ul>
            <li>
              Законность и безопасность сервиса подтверждена государственными и
              международными сертификатами. Они указаны на сайте.
            </li>
            <li>
              Ресурс работает с крупнейшими страховыми компаниями. В строке
              поиска можно ввести нужную услугу. Такая возможность упрощает
              процесс оплаты.
            </li>
            <li>
              Услуга оплаты страховки онлайн проводится с минимальной комиссией.
              Ознакомиться с условиями можно на сайте. Либо задать интересующие
              вопросы сотрудникам ресурса онлайн, по телефону.
            </li>
          </ul>
          <p>
            Условия использования сервиса просты и прозрачны. Информация понятна
            и доступна каждому. Все инструкции по осуществлению оплаты подробно
            описаны.
          </p>
          <p>
            Внесение средств на страховой счет займет пару минут. Новички могут
            дополнительно воспользоваться консультациями. С первого раза вы
            убедитесь в оптимальности такого выбора! Все страхование теперь
            находится на расстоянии нескольких кликов.
          </p>
        </>
      ),
      160: 'оплатить онлайн страховку за машину, узнать долг страховку онлайн, оплатить онлайн страховку атешгях',
      727: 'оплатить онлайн страховку за машину, узнать долг страховку онлайн, оплатить онлайн страховку баки сигорта',
      715: 'оплатить онлайн страховку за машину, узнать долг страховку онлайн, оплатить онлайн страховку паша',
      783: 'оплатить онлайн страховку за машину, узнать долг страховку онлайн, оплатить онлайн страховку кала хеят',
      900: 'оплатить онлайн страховку за машину, узнать долг страховку онлайн, оплатить онлайн страховку мека',
      930: 'оплатить онлайн страховку за машину, узнать долг страховку онлайн, оплатить онлайн страховку ата',
    },
    banks: {
      content: (
        <>
          <h2>
            Банковские услуги EPUL: пополнить карту онлайн быстро и просто!
          </h2>
          <p>
            Нужно перевести деньги на карту онлайн? Сервис EPUL – безупречный
            вариант для интернет-банкинга. Все операции доступны в одном
            приложении или вкладке браузера. Банки Азербайджана собраны на
            данной платформе! Мы работаем с государственными и частными
            учреждениями. При этом гарантируем надежность, высокую скорость и
            удобство в любом случае.
          </p>
          <p>
            Сейчас пополнить счет с карты онлайн – проще, чем вы можете
            представить! Операция займет несколько минут. Что особенно приятно –
            новички разберутся с первого раза. При необходимости доступны
            онлайн-консультации. Пользователи отмечают удобный, понятный
            интерфейс. Целый банк в вашем телефоне! И работает он круглосуточно,
            без перерывов и выходных!
          </p>
          <h2>Мобильный банкинг – комфорт и удобство для вас!</h2>
          <p>Преимущества сервиса очевидны:</p>
          <ul>
            <li>
              Возможность пополнить карту онлайн, выбрав любой банк. Учреждения
              Баку и всего Азербайджана – к вашим услугам!
            </li>
            <li>
              Для пользования ресурсом достаточно пройти процесс регистрации.
              Этот способ исключительно прост.
            </li>
            <li>
              После регистрации выберите нужный банк или воспользуйтесь поиском.
              Далее нужно ввести номер карты. Для оплаты можно использовать уже
              существующую карточку. Доступен и другой вариант: зарегистрируйте
              виртуальную карту. Пополняйте ее с электронных кошельков. Это
              удобно, быстро.
            </li>
          </ul>
          <p>
            Для пользователей особенно актуален вопрос комиссии. Она зависит от
            условий пользования услугами банка. При осуществлении переводов
            будьте внимательны. Со своей стороны мы гарантируем самые выгодные
            предложения! С нами пополнить карту онлайн в Баку можно легко,
            быстро и доступно!
          </p>
          <p>
            Хотите получить больше информации? Интересует работа с конкретным
            банком? Воспользуйтесь профессиональными консультациями. Мы придем
            на помощь в телефонном режиме или онлайн-чате. Реагируем на запросы
            любой сложности оперативно и результативно. С EPUL банковские
            операции становятся понятными и доступными для каждого.
          </p>
          <h2>Мы работаем для вашего комфорта</h2>
          <p>
            Залог качественного банкинга в сети – надежность и высокая скорость.
            Пройдите через регистрацию – и убедитесь сами. Мы проводим операции
            молниеносно быстро. Клиенты получают гарантированную надежность
            любого платежа. Вы доверяете свои данные и средства лучшему сервису!
          </p>
          <p>
            Используйте реальную или виртуальную платежную карту. Вы сможете
            экономить время и деньги вместе с нами!
          </p>
        </>
      ),
      184: 'онлайн оплатить кредит afb bank, оплатить кредит через карту afb bank, оплатить кредит afb bank через интернет',
      409: 'онлайн оплатить кредит albukart, оплатить кредит через карту albukart, оплатить кредит albukart через интернет',
      347: 'онлайн оплатить кредит access bank, оплатить кредит через карту access bank, оплатить кредит access bank через интернет',
      486: 'онлайн оплатить кредит amrahbank, оплатить кредит через карту amrahbank, оплатить кредит amrahbank через интернет',
      186: 'онлайн оплатить кредит atabank, оплатить кредит через карту atabank, оплатить кредит atabank через интернет',
      226: 'онлайн оплатить кредит azercredit, оплатить кредит через карту azercredit, оплатить кредит azercredit через интернет',
      834: 'онлайн оплатить кредит azer turk bank, оплатить кредит через карту azer turk bank, оплатить кредит azer turk bank через интернет',
      746: 'онлайн оплатить кредит bank avrasiya, оплатить кредит через карту bank avrasiya, оплатить кредит bank avrasiya через интернет',
      225: 'онлайн оплатить кредит bank vtb, оплатить кредит через карту bank vtb, оплатить кредит bank vtb через интернет',
      388: 'онлайн оплатить кредит bank vtb, оплатить кредит через карту bank vtb, оплатить кредит bank vtb через интернет',
      182: 'онлайн оплатить кредит bank of baku, оплатить кредит через карту bank of baku, оплатить кредит bank of baku через интернет',
      183: 'онлайн оплатить кредит bolkart, оплатить кредит через карту bolkart, оплатить кредит bolkart через интернет',
      706: 'онлайн оплатить кредит brandcredit, оплатить кредит через карту brandcredit, оплатить кредит brandcredit через интернет',
      349: 'онлайн оплатить кредит embafinans, оплатить кредит через карту embafinans, оплатить кредит embafinans через интернет',
      800: 'онлайн оплатить кредит fintrend, оплатить кредит через карту fintrend, оплатить кредит fintrend через интернет',
      191: 'онлайн оплатить кредит flexikredi, оплатить кредит через карту flexikredi, оплатить кредит flexikredi через интернет',
      193: 'онлайн оплатить кредит finca, оплатить кредит через карту finca, оплатить кредит finca через интернет',
      192: 'онлайн оплатить кредит kapital bank, оплатить кредит через карту kapital bank, оплатить кредит kapital bank через интернет',
      407: 'онлайн оплатить кредит kapital bank, оплатить кредит через карту kapital bank, оплатить кредит kapital bank через интернет',
      634: 'онлайн оплатить кредит molbulak, оплатить кредит через карту molbulak, оплатить кредит molbulak через интернет',
      485: 'онлайн оплатить кредит muganbank, оплатить кредит через карту muganbank, оплатить кредит muganbank через интернет',
      194: 'онлайн оплатить кредит nikoil bank, оплатить кредит через карту nikoil bank, оплатить кредит nikoil bank через интернет',
      195: 'онлайн оплатить кредит respublika bank, оплатить кредит через карту respublika bank, оплатить кредит respublika bank через интернет',
      346: 'онлайн оплатить кредит sebakredit bank, оплатить кредит через карту sebakredit bank, оплатить кредит sebakredit bank через интернет',
      506: 'онлайн оплатить кредит silkway bank, оплатить кредит через карту silkway bank, оплатить кредит silkway bank через интернет',
      196: 'онлайн оплатить кредит turan bank, оплатить кредит через карту turan bank, оплатить кредит turan bank через интернет',
      408: 'онлайн оплатить кредит unibank, оплатить кредит через карту unibank, оплатить кредит unibank через интернет',
      406: 'онлайн оплатить кредит worldcard, оплатить кредит через карту worldcard, оплатить кредит worldcard через интернет',
      405: 'онлайн оплатить кредит yapi kredi, оплатить кредит через карту yapi kredi, оплатить кредит yapi kredi через интернет',
    },
    other: {
      266: 'онлайн оплатить долг ali nino, узнать долг ali nino, оплатить долг ali nino картой, узнать долг онлайн ali nino',
      645: 'онлайн оплатить долг avira, узнать долг avira, оплатить долг avira картой, узнать долг онлайн avira',
      684: 'онлайн оплатить долг bitdefender, узнать долг bitdefender, оплатить долг bitdefender картой, узнать долг онлайн bitdefender',
      676: 'онлайн оплатить долг eset nod 32, узнать долг eset nod 32, оплатить долг eset nod 32 картой, узнать долг онлайн eset nod 32',
      679: 'онлайн оплатить долг kaspersky, узнать долг kaspersky, оплатить долг kaspersky картой, узнать долг онлайн kaspersky',
      305: 'онлайн оплатить долг bonuslar, узнать долг bonuslar, оплатить долг bonuslar картой, узнать долг онлайн bonuslar',
      675: 'онлайн оплатить долг destek, узнать долг destek, оплатить долг destek картой, узнать долг онлайн destek',
      179: 'онлайн оплатить долг domofon, узнать долг domofon, оплатить долг domofon картой, узнать долг онлайн domofon',
      181: 'онлайн оплатить долг just smile, узнать долг just smile, оплатить долг just smile картой, узнать долг онлайн just smile',
      726: 'онлайн оплатить долг lalafo, узнать долг lalafo, оплатить долг lalafo картой, узнать долг онлайн lalafo',
      677: 'онлайн оплатить долг turbo az, узнать долг turbo az, оплатить долг turbo az картой, узнать долг онлайн turbo az',
      901: 'онлайн оплатить долг университет стандартов, узнать долг университет стандартов, оплатить долг университет стандартов картой, узнать долг онлайн университет стандартов',
      904: 'онлайн оплатить долг gilan, узнать долг gilan, оплатить долг gilan картой, узнать долг онлайн gilan',
      902: 'онлайн оплатить долг hayat management, узнать долг hayat management, оплатить долг hayat management картой, узнать долг онлайн hayat management',
    },
    notFoundDescription:
      'EPUL система онлайн оплат. Оплати Онлайн, Сохраняйте свою историю платежей, Автоматически оплачивайте свои счета, Переводите деньги с карты на карту и Набирайте БОНУСЫ.',
    notFoundKeywords:
      'эпул, e-pul, epul, оплати онлайн,  система онлайн оплат, онлайн оплатить, онлайн оплатить счета, онлайн оплаты баку, приём платежей онлайн, онлайн оплаты через интернет, оплата через интернет банковской карту, онлайн оплаты на сайте, переводы с карты на карту, коммунальные услуги онлайн оплатить, мобильный оператор онлайн оплатить, интернет провайдер онлайн оплатить, тв провайдер онлайн оплатить, стационарные телефоны онлайн оплатить, развлекательные сервисы онлайн оплатить, другие услуги онлайн оплатить, банковские услуги онлайн оплатить, платежные системы онлайн платить, страхование онлайн оплатить, образование онлайн оплатить, штрафы онлайн оплатить.',
  },
  az: {
    description:
      'Bakıda ✅ təhlükəsiz 💳 Azərbaycanda istənilən bank kartı ilə ⚡️ sürətli ✨ etibarlı 👍 tahat 📞 *1919 | EPUL',
    title: 'Bakıda internetlə  | EPUL',
    mobile: {
      content: (
        <>
          <h2>Mobil telefonun əlverişli rahat doldurulması</h2>
          <p>
            Mobil telefonu onlayn doldurmaq lazımdır? Terminaldan istifadə
            etmək, doldurma kartı üçün yollaтmaq və ya alternativ üsul seçmək?
            Bənzər xidmət smartfonda və kompüter brauzerində mövcuddur. Xidmət
            mobil hesabınızı tez və asanlıqla doldurmağa imkan verir.
            Azərbaycanın bütün operatorları bir platformada toplanıb. Minimum
            komissiya, tapşırıqların yerinə yetirilməsinin yüksək sürəti və hər
            bir istifadəçi üçün rahatlıq.
          </p>
          <p>
            Rabitə istifadəsi üçün ödəmə - həqiqətən asandır. E-PUL xidməti
            saytı, telefonunuzu gün ərzində səy göstərmədən doldurmağa kömək
            edəcəkdir. Bütün operatorları eyni zamanda cəmləşdirən ən asan üsul
            sizin üçün aktualdır.
          </p>
          <h2>Mobil rabitə ödənişini necə həyata keçirmək olar?</h2>
          <ol>
            <li>
              Ən münasib variantı seçin: veb saytında və ya tətbiqi proqramda
              qeydiyyat. Platforma iOS və Android-də mövcuddur.
            </li>
            <li>
              Aktiv bank kartını “qoşun” və ya sistemdə virtual kart açın.
            </li>
            <li>Operatoru seçin – və telefon nömrənizin balansını doldurun.</li>
            <li>Bir neçə dəqiqədən sonra vəsait hesaba daxil olacaq.</li>
          </ol>
          <p>
            Hər şey sürətli, sadə, asandır! Xidmət ilk baxışdan aydın interfeysə
            malikdir. Zəruri tədbirlərin təlimatları ətraflı şəkildə təsvir
            olunub. Bundan əlavə, burada ən çox soruşulan sualların cavabları
            təqdim olunub. Kömək lazımdır? Saytın müvafiq bölməsində kömək əldə
            edə bilərsiniz.
          </p>
          <h2>Mobil telefonun E-PUL ilə ödənişinin üstünlükləri nədədir?</h2>
          <ul>
            <li>
              Azərbaycanın bütün operatorları, beynəlxalq operatorlar saytda
              toplanmışdır. Bir neçə klik - və nəticə hazırdır.
            </li>
            <li>
              Hər bir müştəri üçün rahatlıq və sadəlik. Ödəniş smartfon,
              kompüterdəki brauzer vasitəsilə həyata keçirilir. Vəsaitlər hesaba
              sürətlə, əngəlsiz köçürülür.
            </li>
            <li>İstənilən vaxt vəsait yatırmaq imkanı.</li>
            <li>
              Resursdan istifadə tamamilə təhlükəsizdir. Xidmətin fəaliyyəti
              müvafiq sertifikatlarla təsdiqlənir.
            </li>
            <li>
              Tətbiqi proqramda açılan “virtual” kart yalnız müvafiq ödənişlər
              üçün istifadə edilə bilər. Mövcud bank kartından da istifadə edə
              bilərsiniz.
            </li>
          </ul>
          <p>
            Doldurma Bakıda 24/7 mümkündür. Telefonunuzda və ya kompüterinizdə
            rahat E-PUL seçin. Ödəniş zamanı vaxtınıza qənaət edin. Maksimum
            sadəlik və etibarlılıq bütün müştərilərə təklif olunur!
          </p>
        </>
      ),
      22: 'azercell, azercell balans artirmaq onlayn, azercell balans yoxlamaq onlayn, azercell borcu onlayn ödəmək, azercell onlayn kontur yuklemek, sim balans artirmaq onlayn, sim kontur yuklemek onlayn.',
      21: 'azercell, azercell balans artirmaq onlayn, azercell balans yoxlamaq onlayn, azercell borcu onlayn ödəmək, azercell onlayn kontur yuklemek, sim balans artirmaq onlayn, sim kontur yuklemek onlayn.',
      142: 'bakcell, bakcell balans artirmaq onlayn, bakcell onlayn kontur yuklemek, cin balans artirmaq onlayn, cin kontur yuklemek onlayn.',
      790: 'bakcell, bakcell balans artirmaq onlayn, bakcell onlayn kontur yuklemek, cin balans artirmaq onlayn, cin kontur yuklemek onlayn.',
      157: 'nar mobile, nar balans artirmaq onlayn, nar onlayn kontur yuklemek.',
      402: 'nar mobile, nar balans artirmaq onlayn, nar onlayn kontur yuklemek.',
      791: 'nar mobile, nar balans artirmaq onlayn, nar onlayn kontur yuklemek.',
      614: 'beeline gürcüstan, beeline gürcüstan balans artirmaq onlayn, beeline gürcüstan onlayn balansi artirmaq, bilayn balans artirmaq onlayn, bilayn balansa pul qoymaq onlayn.',
      619: 'beeline qazaxistan, beeline qazaxistan balans artirmaq onlayn, beeline qazaxistan onlayn balansi artirmaq, bilayn balans artirmaq onlayn, bilayn balansa pul qoymaq onlayn.',
      617: 'beeline qirgizidtan, beeline qirgizidtan balans artirmaq onlayn, beeline qirgizidtan onlayn balansi artirmaq, bilayn balans artirmaq onlayn, bilayn balansa pul qoymaq onlayn.',
      615: 'beeline rusiya, beeline rusiya balans artirmaq onlayn, beeline rusiya onlayn balansi artirmaq, bilayn balans artirmaq onlayn, bilayn balansa pul qoymaq onlayn.',
      618: 'beeline tacikistan, beeline tacikistan balans artirmaq onlayn, beeline tacikistan onlayn balansi artirmaq, bilayn balans artirmaq onlayn, bilayn balansa pul qoymaq onlayn.',
      616: 'beeline ukrayna, beeline ukrayna balans artirmaq onlayn, beeline ukrayna onlayn balansi artirmaq, bilayn balans artirmaq onlayn, bilayn balansa pul qoymaq onlayn.',
      393: 'naxtel, naxtel balans artirmaq onlayn, naxtel onlayn balansi artirmaq, naxtel artirmaq onlayn, naxtel pul qoymaq onlayn.',
      710: 'freenet, freenet balans artirmaq onlayn, freenet onlayn balansi artirmaq, freenet artirmaq onlayn, freenet pul qoymaq onlayn.',
      620: 'geocell, geocell balans artirmaq onlayn, geocell onlayn balansi artirmaq, geocell artirmaq onlayn, geocell pul qoymaq onlayn.',
      794: 'kievstar, kievstar balans artirmaq onlayn, kievstar onlayn balansi artirmaq, kievstar artirmaq onlayn, kievstar pul qoymaq onlayn.',
      793: 'lifecell, lifecell balans artirmaq onlayn, lifecell onlayn balansi artirmaq, lifecell artirmaq onlayn, lifecell pul qoymaq onlayn.',
      792: 'megafon, megafon balans artirmaq onlayn, megafon onlayn balansi artirmaq, megafon artirmaq onlayn, megafon pul qoymaq onlayn.',
      621: 'mtc, mtc balans artirmaq onlayn, mtc onlayn balansi artirmaq, mtc artirmaq onlayn, mtc pul qoymaq onlayn.',
      622: 'mtc, mtc balans artirmaq onlayn, mtc onlayn balansi artirmaq, mtc artirmaq onlayn, mtc pul qoymaq onlayn.',
      568: 'skayp, skayp balans artirmaq onlayn, skayp onlayn balansi artirmaq, skype artirmaq onlayn, skype pul qoymaq onlayn.',
      755: 'viber, viber balans artirmaq onlayn, viber onlayn balansi artirmaq, viber artirmaq onlayn, viber pul qoymaq onlayn.',
      906: 'simtravel, simtravel balans artirmaq onlayn, simtravel onlayn balansi artirmaq, simtravel artirmaq onlayn, simtravel pul qoymaq onlayn.',
      907: 'tele2, tele2 balans artirmaq onlayn, tele2 onlayn balansi artirmaq, tele2 artirmaq onlayn, tele2 pul qoymaq onlayn.',
      908: 'yota, yota balans artirmaq onlayn, yota onlayn balansi artirmaq, yota artirmaq onlayn, yota pul qoymaq onlayn.',
    },
    utilities: {
      content: (
        <>
          <h2>Kommunal xidmətlərin ödənişi: sadə və tez!</h2>
          <p>
            Kommunal xidmətlərin İnternetdə ödənişi - əsl tapıntıdır! Pul
            vəsaitlərini onlayn göndərməyə imkan verən zəmanənin əla təklifi.
            Növbədə dayanmağı unudun - bu ötən əsrdir. İndi kommunal ödənişlər
            üçün vəsaitləri hesaba daxil etmək asan və xoş olacaq. Minimal səy
            və vaxt sərfi. Şablonların saxlanması və kom. xidmətləri bir neçə
            klik ilə ödəmək imkanı. Bütün bunlar artıq mümkündür! Ən sadə üsul
            sizin smartfonunuzda “mövcuddur”!
          </p>
          <p>
            E-PUL – elektron ödəniş sistemi. Rahatlıq – bizim ikinci adımızdır.
            Platformada qeydiyyatdan keçmək kifayətdir! Bundan sonra ödəniş
            servisi bütün növ mənzil ödənişlərini vahid “şüşədə” təqdim edir!
            Növbəsiz. Uzunsürən gözləmə olmadan. Problemsiz! Vaxtınızı mənzil
            ödənişlərindən daha xoş məşğuliyyətə sərf edin!
          </p>
          <h2>Sistem necə işləyr?</h2>
          <ul>
            <li>Bir neçə dəqiqə çəkəcək qeydiyyat.</li>
            <li>
              Bank kartını qoşmaq və ya virtual kart açmaq.Virtual karta
              üstünlük verirsinizsə, onu bir neçə kliklə açın. Doldurma elektron
              cüzdanların köməyi ilə mümkündür.
            </li>
            <li>
              Brauzer vasitəsi ilə və ya tətbiqi proqramda müxtəlif ödənişlər
              edin. Lazımi xidməti daxil etmək – və rekvizitləri doldurmaq
              kifayətdir. Gələcəkdə rahatlıq üçün şablonlar saxlanılır.
            </li>
            <li>
              Vaxtınıza və səyinizə qənaət edin! İstənilən yerdən ödəniş edin!
            </li>
          </ul>
          <h2>E-PUL-un üstünlükləri</h2>
          <ul>
            <li>
              Bakıda kommunal xidmətlərin ödənişi vahid tətbiqi proqramda.
              Vəsaitləri ödəniş sisteminə günün istənilən vaxtında, operativ və
              asanlıqla daxil edin.
            </li>
            <li>
              Virtual kartdan istifadə imkanı. Əsas bank kartının əvəzinə
              virtual kart açın. Bu kar yalnız kommunal xərclər üçün istifadə
              edilə bilər.
            </li>
            <li>
              Bakıda və Azərbaycanda xidmətin etibarlılığı sertifikatlarla
              təsdiqlənir. Sistem tamamilə təhlükəsizdir. Əməkdaşlıq şərtləri
              hər kəs üçün şəffaf və başa düşüləndir.
            </li>
            <li>
              Vaxt, səy və pul qənaəti. Elektrik enerjisi, qaz, su və s.
              ödənişlər həmişə əlinizin altındadır!
            </li>
            <li>
              Tətbiqi proqram bütün smartfonlarda işləyir. Android və iOS
              azarkeşləri bu proqramdan istifadə edə bilərlər.
            </li>
            <li>
              Müştərilər üçün bonuslar mövcuddur. Xüsusi faydalar haqqında daha
              ətraflı məlumatı veb saytda əldə edə bilərsiniz.
            </li>
            <li>
              İnterfeys intuitiv olaraq aydındır. Sualınız var? Tövsiyə və
              məsləhətlərdən faydalanın.
            </li>
          </ul>
          <p>
            Kommunal ödənişlər etmək sadə və xoş bir prosedura çevrildi. Tətbiqi
            proqramda və ya brauzer vasitəsi ilə - fərq etməz! Vaxt və səyinizə
            qənaət edin, gözləməyi unudun. Smartfonunuzdan və ya digər
            qadjetlərdən ödənişləri idarə edin.
          </p>
        </>
      ),
      425: 'azersu smart kart onlayn odemek, azersu borcu onlayn oyrenmek, su pulu onlayn odemek smart kartla, su kartinin balansini artirmaq',
      127: 'azeriqaz smart kart, azeriqaz smart kartla odemek, azeriqaz borcu oyrenmek, az pulu onlayn odemek smart kartla, qaz kart\u0131n\u0131n balans\u0131n\u0131 art\u0131rmaq',
      130: 'azeriqaz, azeriqaz onlayn odemek, azeriqaz borcu oyrenmek, qaz pulu onlayn odemek, qaz borcunu oyrenmek',
      144: 'azeris\u0131q, azeris\u0131q onlayn odemek, azeris\u0131q borcu oyrenmek, isiq pulu onlayn odemek, isiq borcunu oyrenmek',
      515: 'azeris\u0131q, azeris\u0131q onlayn odemek, azeris\u0131q borcu oyrenmek, isiq pulu onlayn odemek,isiq borcunu oyrenmek',
      132: 'azersu, azersu onlayn odemek, azersu borcu oyrenmek, su pulu onlayn odemek',
      516: 'azersu, azersu onlayn odemek, azersu borcu oyrenmek, su pulu onlayn odemek',
      389: 'azeristilik, azeristilik onlayn odemek, azeristilik borcu oyrenmek, istilik batareyasinin pulunu onlayn odemek, otapleniya pulunu onlayn odemek',
      547: 'azeristilik, azeristilik onlayn odemek, azeristilik borcu oyrenmek, istilik batareyasinin pulunu onlayn odemek, otapleniya pulunu onlayn odemek',
      394: 'naxsu, naxsu onlayn odemek, naxsu borcu oyrenmek, naxcivan su pulu onlayn odemek',
      396: 'naxisiq, naxisiq onlayn odemek, naxisiq borcu oyrenmek, isiq pulu onlayn odemek naxcivan, isiq borcunu oyrenmek naxcivan',
      395: 'naxqaz, naxqaz onlayn odemek, naxqaz borcu oyrenmek, qaz pulu onlayn odemek naxcivan, qaz borcunu oyrenmek naxcivan',
    },
    phone: {
      386: 'aztelekom telefon borcunu onlayn odemek, aztelekom telefonun borcunu oyrenmek, aztelekom telefon pulunu odemek, aztelekom telefon borcunu oyrenmek',
      803: 'aztelekom telefon borcunu onlayn odemek, aztelekom telefonun borcunu oyrenmek, aztelekom telefon pulunu odemek, aztelekom telefon borcunu oyrenmek',
      816: 'azeurotel telefon borcunu onlayn odemek, azeurotel telefonun borcunu oyrenmek, azeurotel telefon pulunu odemek, azeurotel telefon borcunu oyrenmek',
      815: 'transeurocom telefon borcunu onlayn odemek, transeurocom telefonun borcunu oyrenmek, transeurocom telefon pulunu odemek, transeurocom telefon borcunu oyrenmek',
      163: 'ultel telefon borcunu onlayn odemek, ultel telefonun borcunu oyrenmek, ultel telefon pulunu odemek, ultel telefon borcunu oyrenmek.',
      932: 'ultel telefon borcunu onlayn odemek, ultel telefonun borcunu oyrenmek, ultel telefon pulunu odemek, ultel telefon borcunu oyrenmek.',
      146: 'catel telefon borcunu onlayn odemek, catel telefonun borcunu oyrenmek, catel telefon pulunu odemek, catel telefon borcunu oyrenmek.',
      934: 'catel telefon borcunu onlayn odemek, catel telefonun borcunu oyrenmek, catel telefon pulunu odemek, catel telefon borcunu oyrenmek.',
      399: 'naxcivan telefon borcunu onlayn odemek, naxcivan  telefonun borcunu oyrenmek, naxcivan telefon pulunu odemek, naxcivan telefon borcunu oyrenmek',
    },
    tv: {
      173: 'atv+ borcunu onlayn odemek, atv+ borcunu oyrenmek, atv+ pulunu odemek, kabel tv odemek onlayn, atv plus odemek onlayn',
      752: 'ailetv iptv borcunu onlayn odemek, ailetv iptv borcunu oyrenmek, ailetv iptv pulunu odemek, kabel ailetv iptv odemek onlayn, ailetv iptv odemek onlayn',
      121: 'ailetv borcunu onlayn odemek, ailetv borcunu oyrenmek, ailetv pulunu odemek, kabel ailetv odemek onlayn, ailetv odemek onlayn',
      143: 'bbtv borcunu onlayn odemek, bbtv borcunu oyrenmek, bbtv pulunu odemek, kabel bbtv odemek onlayn, bbtv odemek onlayn',
      147: 'connect tv borcunu onlayn odemek, connect tv borcunu oyrenmek, connect tv pulunu odemek, kabel connect tv odemek onlayn, connect tv odemek onlayn',
      780: 'nn tv borcunu onlayn odemek, nn tv borcunu oyrenmek, nn tv pulunu odemek, kabel nn tv odemek onlayn, nn tv odemek onlayn',
      427: 'nar home tv borcunu onlayn odemek, nar home tv borcunu oyrenmek, nar home tv pulunu odemek, kabel nar home tv odemek onlayn, nar home tv odemek onlayn',
      265: 'ra tv borcunu onlayn odemek, ra tv borcunu oyrenmek, ra tv pulunu odemek, kabel ra tv odemek onlayn, ra tv odemek onlayn',
      398: 'rahat tv borcunu onlayn odemek, rahat tv borcunu oyrenmek, rahat tv pulunu odemek, kabel rahat tv odemek onlayn, rahat tv odemek onlayn',
      801: 'rahat tv borcunu onlayn odemek, rahat tv borcunu oyrenmek, rahat tv pulunu odemek, kabel rahat tv odemek onlayn, rahat tv odemek onlayn',
      763: 'sn tv borcunu onlayn odemek, sn tv borcunu oyrenmek, sn tv pulunu odemek, kabel sn tv odemek onlayn, sn tv odemek onlayn',
      153: 'katv borcunu onlayn odemek, katv borcunu oyrenmek, katv pulunu odemek, kabel katv odemek onlayn, katv odemek onlayn',
      929: 'katv borcunu onlayn odemek, katv borcunu oyrenmek, katv pulunu odemek, kabel katv odemek onlayn, katv odemek onlayn',
      178: 'ultel ip tv borcunu onlayn odemek, ultel ip tv borcunu oyrenmek, ultel ip tv pulunu odemek, kabel ultel ip tv odemek onlayn, ultel ip tv odemek onlayn',
      933: 'ultel ip tv borcunu onlayn odemek, ultel ip tv borcunu oyrenmek, ultel ip tv pulunu odemek, kabel ultel ip tv odemek onlayn, ultel ip tv odemek onlayn',
    },
    internet: {
      content: (
        <>
          <h2>Bir kliklə internet hesabının ödənməsi</h2>
          <p>
            Minimum itkilərlə bank kartı vasitəsilə internet hesabını necə
            ödəmək olar? Hesabdakı pul adətən ən əlverişsiz vaxtda bitir. Hesabı
            sürətli doldurmağın ən yaxşı yolu - E-PUL-dan istifadə etməkdir.
            Qarşınızda, istənilən platformada fəaliyyət göstərə bilən resurs
            mövcuddur. İstifadə üçün istənilən qadjet uygundur: kompüter,
            planşet, smartfon. Xidmət Android, iOS operativ sistemlərində və veb
            sayt vasitəsilə işləyir. Tətbiqi proqramı yükləyin və ya saytda
            qeydiyyatdan keçin. Bu, hesabınızı istənilən zaman doldurmağınıza
            kömək edəcəkdir. Günün istənilən vaxtında və istənilən hava
            şəraitində.
          </p>
          <h2>Bakıda telefon vasitəsilə interneti necə doldurmaq olar?</h2>
          <ol>
            <li>
              Müvafiq tətbiqi proqramı quraşdırın. Həmin proqramda qeydiyyatdan
              keçin. Qeydiyyat sadədir, intuitivdir və bir neçə dəqiqə çəkir.
            </li>
            <li>
              Tapşırığı tamamlamaq üçün aktiv bank kartından istifadə edə
              bilərsiniz. Həmçinin virtual kart “açmaq” imkanı var. Virtual kart
              bank kartınızdan və ya elektron cüzdanınızdan doldurulur. Prosedur
              çox tez həyata keçirilir.
            </li>
            <li>
              Veb saytında müvafiq xidməti seçin. Provayderinizi tapın. Zəruri
              kartdan istifadə edərək vəsaiti yatırın. Pul dərhal hesaba
              köçürülür.
            </li>
            <li>Dünya şəbəkəsinə sərbəst girişdən zövq alın!</li>
          </ol>
          <p>
            Xidmətləri ödəmək üçün veb saytı xatırlamaqdan bezdiniz? Tətbiqi
            proqramı quraşdırın. İnternet üçün ödəniş son dərəcə asan olacaq.
            Minimum səy - və hazır nəticə. Bütün müntəzəm ödənişləri bir yerdə
            toplamaq mümkündür.
          </p>
          <h2>İstifadəçilər üçün E-PUL-un üstünlükləri</h2>
          <ul>
            <li>
              Maksimum rahatlıq. Bir neçə klik etmək kifayətdir. Hətta yeni
              istifadəçilər üçün uyğundur. Azərbaycandakı istənilən provayderin
              xidmətlərini vahid resursda ödəyə bilərsiniz. Müvafiq bölməni
              açın, təlimatlara əməl edin.
            </li>
            <li>
              Münasib və sadə interfeys. Maliyyə vəsaitlərinin yatırılması
              haqqında məlumat aydın şəkildə təqdim olunub. İlk istifadədən
              interneti onlayn ödəmək asandır.
            </li>
            <li>
              Xidmətin etibarlılığı. E-PUL - bu qədər onlayn imkan toplayan ilk
              resursdur. Biz Azərbaycanın ən iri şirkətləri ilə əməkdaşlıq
              edirik. Tələb olunan məbləğ "ünvana" çox tez çatacaq.
            </li>
            <li>
              İstənilən şirkətin xidmətlərini ödəmək imkanı. Rahatlığınız üçün
              axtarış funksiyası mövcuddur.
            </li>
          </ul>
          <p>
            Sualınız var? Çat vasitəsilə mütəxəssislə əlaqə saxlayın. Əlavə
            məlumatı və ödənişlə bağlı məsləhətləri operativ şəkildə ala
            bilərsiniz.
          </p>
          <p>
            Xidmət təqdim edən şirkətin hesabına vəsait yatırarkən sürət çox
            vacibdir. Pul nə qədər tez göndərilsə, bir o qədər yaxşıdır. Qənaət
            etmək və müsbət balansı qorumaq asandır. Sadəcə resursumuzdan
            istifadə edin!
          </p>
        </>
      ),
      165: 'activeol internet borcunu onlayn odemek, activeol internet borcunu oyrenmek, activeol internet pulunu odemek, internet odemek onlayn, aktivol internet odemek onlayn.',
      806: 'baktelecom.net internet borcunu onlayn odemek, baktelecom.net internet borcunu oyrenmek, baktelecom.net internet pulunu odemek, internet odemek onlayn, baktelecom.net internet odemek onlayn.',
      808: 'baktelecom.net internet borcunu onlayn odemek, baktelecom.net internet borcunu oyrenmek, baktelecom.net internet pulunu odemek, internet odemek onlayn, baktelecom.net internet odemek onlayn.',
      123: 'aile net internet borcunu onlayn odemek, aile net internet borcunu oyrenmek, aile net internet pulunu odemek, internet odemek onlayn, aile net internet odemek onlayn.',
      404: 'azevrotel internet borcunu onlayn odemek, azevrotel internet borcunu oyrenmek, azevrotel internet pulunu odemek, internet odemek onlayn, azevrotel internet odemek onlayn.',
      818: 'azevrotel internet borcunu onlayn odemek, azevrotel internet borcunu oyrenmek, azevrotel internet pulunu odemek, internet odemek onlayn, azevrotel internet odemek onlayn.',
      135: 'azevrotel internet borcunu onlayn odemek, azevrotel internet borcunu oyrenmek, azevrotel internet pulunu odemek, internet odemek onlayn, azevrotel internet odemek onlayn.',
      820: 'azdatakom internet borcunu onlayn odemek, azdatakom internet borcunu oyrenmek, azdatakom internet pulunu odemek, internet odemek onlayn, azdatakom internet odemek onlayn.',
      125: 'avirtel internet borcunu onlayn odemek, avirtel internet borcunu oyrenmek, avirtel internet pulunu odemek, internet odemek onlayn, avirtel internet odemek onlayn.',
      804: 'aztelekom.net internet borcunu onlayn odemek, aztelekom.net internet borcunu oyrenmek, aztelekom.net internet pulunu odemek, internet odemek onlayn, aztelekom.net internet odemek onlayn.',
      807: 'aztelekom.net internet borcunu onlayn odemek, aztelekom.net internet borcunu oyrenmek, aztelekom.net internet pulunu odemek, internet odemek onlayn, aztelekom.net internet odemek onlayn.',
      124: 'aflanet internet borcunu onlayn odemek, aflanet internet borcunu oyrenmek, aflanet internet pulunu odemek, internet odemek onlayn, aflanet internet odemek onlayn.',
      384: 'aflanet internet borcunu onlayn odemek, aflanet internet borcunu oyrenmek, aflanet internet pulunu odemek, internet odemek onlayn, aflanet internet odemek onlayn.',
      810: 'azfibernet internet borcunu onlayn odemek, azfibernet internet borcunu oyrenmek, azfibernet internet pulunu odemek, internet odemek onlayn, azfibernet internet odemek onlayn.',
      510: 'azfibernet internet borcunu onlayn odemek, azfibernet internet borcunu oyrenmek, azfibernet internet pulunu odemek, internet odemek onlayn, azfibernet internet odemek onlayn.',
      682: 'azgoldnet internet borcunu onlayn odemek, azgoldnet internet borcunu oyrenmek, azgoldnet internet pulunu odemek, internet odemek onlayn, azgoldnet internet odemek onlayn.',
      403: 'alfanet optik internet borcunu onlayn odemek, alfanet optik internet borcunu oyrenmek, alfanet optik internet pulunu odemek, internet odemek onlayn, alfanet optik internet odemek onlayn.',
      148: 'connect internet borcunu onlayn odemek, connect internet borcunu oyrenmek, connect internet pulunu odemek, internet odemek onlayn, connect internet odemek onlayn',
      166: 'connect fiber internet borcunu onlayn odemek, connect fiber internet borcunu oyrenmek, connect fiber internet pulunu odemek, internet odemek onlayn, connect fiber internet odemek onlayn.',
      149: 'teleport internet borcunu onlayn odemek, teleport internet borcunu oyrenmek, teleport internet pulunu odemek, internet odemek onlayn, teleport internet odemek onlayn',
      22: 'birlink internet borcunu onlayn odemek, birlink internet borcunu oyrenmek, birlink internet pulunu odemek, internet odemek onlayn, birlink internet odemek onlayn.',
      34: 'birlink internet borcunu onlayn odemek, birlink internet borcunu oyrenmek, birlink internet pulunu odemek, internet odemek onlayn, birlink internet odemek onlayn.',
      782: 'fibernet internet borcunu onlayn odemek, fibernet internet borcunu oyrenmek, fibernet internet pulunu odemek, internet odemek onlayn, fibernet internet odemek onlayn',
      512: 'fibernet telekom internet borcunu onlayn odemek, fibernet telekom internet borcunu oyrenmek, fibernet telekom internet pulunu odemek, internet odemek onlayn, fibernet telekom internet odemek onlayn',
      167: 'izone internet borcunu onlayn odemek, izone internet borcunu oyrenmek, izone internet pulunu odemek, internet odemek onlayn, izone internet odemek onlayn.',
      385: 'citynet internet borcunu onlayn odemek, citynet internet borcunu oyrenmek, citynet internet pulunu odemek, internet odemek onlayn, citynet internet odemek onlayn',
      426: 'nar home internet borcunu onlayn odemek, nar home internet borcunu oyrenmek, nar home internet pulunu odemek, internet odemek onlayn, nar home internet odemek onlayn.',
      397: 'naxinternet internet borcunu onlayn odemek, naxinternet internet borcunu oyrenmek, naxinternet internet pulunu odemek, internet odemek onlayn, naxinternet internet odemek onlayn',
      802: 'sazz internet borcunu onlayn odemek, sazz internet borcunu oyrenmek, sazz internet pulunu odemek, internet odemek onlayn, sazz internet odemek onlayn',
      761: 'gamma skynet internet borcunu onlayn odemek, gamma skynet internet borcunu oyrenmek, gamma skynet internet pulunu odemek, internet odemek onlayn, gamma skynet internet odemek onlayn',
      161: 'smart online internet borcunu onlayn odemek, smart online internet borcunu oyrenmek, smart online internet pulunu odemek, internet odemek onlayn, smart online internet odemek onlayn',
      763: 'sntv internet borcunu onlayn odemek, sntv  internet borcunu oyrenmek, sntv internet pulunu odemek, internet odemek onlayn, sntv internet odemek onlayn',
      387: 'uninet internet borcunu onlayn odemek, uninet internet borcunu oyrenmek, uninet internet pulunu odemek, internet odemek onlayn, uninet internet odemek onlayn',
      488: 'uninet internet borcunu onlayn odemek, uninet internet borcunu oyrenmek, uninet internet pulunu odemek, internet odemek onlayn, uninet internet odemek onlayn',
      131: 'azeronline internet borcunu onlayn odemek, azeronline internet borcunu oyrenmek, azeronline internet pulunu odemek, azeronline odemek onlayn, azeronline internet odemek onlayn',
      917: 'azeronline internet borcunu onlayn odemek, azeronline internet borcunu oyrenmek, azeronline internet pulunu odemek, azeronline odemek onlayn, azeronline internet odemek onlayn',
      136: 'azstarnet internet borcunu onlayn odemek, azstarnet internet borcunu oyrenmek, azstarnet internet pulunu odemek, azstarnet odemek onlayn, azstarnet internet odemek onlayn',
      919: 'azstarnet internet borcunu onlayn odemek, azstarnet internet borcunu oyrenmek, azstarnet internet pulunu odemek, azstarnet odemek onlayn, azstarnet internet odemek onlayn',
      150: 'dataplus internet borcunu onlayn odemek, dataplus internet borcunu oyrenmek, dataplus internet pulunu odemek, dataplus odemek onlayn, dataplus internet odemek onlayn',
      920: 'dataplus internet borcunu onlayn odemek, dataplus internet borcunu oyrenmek, dataplus internet pulunu odemek, dataplus odemek onlayn, dataplus internet odemek onlayn',
      152: 'eurosel internet borcunu onlayn odemek, eurosel internet borcunu oyrenmek, eurosel internet pulunu odemek, eurosel odemek onlayn, eurosel internet odemek onlayn',
      921: 'eurosel internet borcunu onlayn odemek, eurosel internet borcunu oyrenmek, eurosel internet pulunu odemek, eurosel odemek onlayn, eurosel internet odemek onlayn',
      171: 'globalnetworks internet borcunu onlayn odemek, globalnetworks internet borcunu oyrenmek, globalnetworks internet pulunu odemek, globalnetworks odemek onlayn, globalnetworks internet odemek onlayn',
      922: 'globalnetworks internet borcunu onlayn odemek, globalnetworks internet borcunu oyrenmek, globalnetworks internet pulunu odemek, globalnetworks odemek onlayn, globalnetworks internet odemek onlayn',
      156: 'metronet internet borcunu onlayn odemek, metronet internet borcunu oyrenmek, metronet internet pulunu odemek, metronet odemek onlayn, metronet internet odemek onlayn',
      923: 'metronet internet borcunu onlayn odemek, metronet internet borcunu oyrenmek, metronet internet pulunu odemek, metronet odemek onlayn, metronet internet odemek onlayn',
      170: 'pilot internet borcunu onlayn odemek, pilot internet borcunu oyrenmek, pilot internet pulunu odemek, pilot odemek onlayn, pilot internet odemek onlayn',
      925: 'pilot internet borcunu onlayn odemek, pilot internet borcunu oyrenmek, pilot internet pulunu odemek, pilot odemek onlayn, pilot internet odemek onlayn',
      172: 'telekomInvest internet borcunu onlayn odemek, telekomInvest internet borcunu oyrenmek, telekomInvest internet pulunu odemek, telekomInvest odemek onlayn, telekomInvest internet odemek onlayn',
      926: 'telekomInvest internet borcunu onlayn odemek, telekomInvest internet borcunu oyrenmek, telekomInvest internet pulunu odemek, telekomInvest odemek onlayn, telekomInvest internet odemek onlayn',
      391: 'super online internet borcunu onlayn odemek, super online internet borcunu oyrenmek, super online internet pulunu odemek, super online odemek onlayn, super online internet odemek onlayn',
      927: 'super online internet borcunu onlayn odemek, super online internet borcunu oyrenmek, super online internet pulunu odemek, super online odemek onlayn, super online internet odemek onlayn',
      168: 'transeurocom internet borcunu onlayn odemek, transeurocom online internet borcunu oyrenmek, transeurocom internet pulunu odemek, transeurocom odemek onlayn, transeurocom internet odemek onlayn',
      928: 'transeurocom internet borcunu onlayn odemek, transeurocom online internet borcunu oyrenmek, transeurocom internet pulunu odemek, transeurocom odemek onlayn, transeurocom internet odemek onlayn',
      154: 'katv internet borcunu onlayn odemek, katv online internet borcunu oyrenmek, katv internet pulunu odemek, katv odemek onlayn, katv internet odemek onlayn',
      918: 'katv internet borcunu onlayn odemek, katv online internet borcunu oyrenmek, katv internet pulunu odemek, katv odemek onlayn, katv internet odemek onlayn',
      162: 'ultel internet borcunu onlayn odemek, ultel online internet borcunu oyrenmek, ultel internet pulunu odemek, ultel odemek onlayn, ultel internet odemek onlayn',
      931: 'ultel internet borcunu onlayn odemek, ultel online internet borcunu oyrenmek, ultel internet pulunu odemek, ultel odemek onlayn, ultel internet odemek onlayn',
    },
    education: {
      781: 'abiturient onlayn ödənişləri, abiturient jurnali onlayn almaq',
      753: 'dim onlayn ödənişləri, dim onlayn odemek',
      748: 'eas onlayn ödənişləri, eas onlayn odemek',
      500: 'edutech onlayn ödənişləri, edutech onlayn odemek',
      754: 'it step onlayn ödənişləri, it step onlayn odemek',
    },
    online: {
      629: 'yandex dengi onlayn ödə, yandex dengi onlayn pul qoymaq, yandex dengi onlayn balansı artırmaq',
      765: 'azcard onlayn ödə, azcard onlayn pul qoymaq, azcard onlayn balansı artırmaq',
      681: 'facebook oyunlar onlayn ödə, facebook oyunlar onlayn pul qoymaq, facebook oyunlar onlayn balansı artırmaq',
      680: 'google play onlayn ödə, google play onlayn pul qoymaq, google play onlayn balansı artırmaq',
      633: 'moneta onlayn ödə, moneta onlayn pul qoymaq, moneta onlayn balansı artırmaq',
      624: 'qiwi euro onlayn ödə, qiwi euro onlayn pul qoymaq, qiwi euro onlayn balansı artırmaq',
      625: 'qiwi rub onlayn ödə, qiwi rub onlayn pul qoymaq, qiwi rub onlayn balansı artırmaq',
      623: 'qiwi usd onlayn ödə, qiwi usd onlayn pul qoymaq, qiwi usd onlayn balansı artırmaq',
      578: 'steam onlayn ödə, steam onlayn pul qoymaq, steam onlayn balansı artırmaq',
      632: 'webmoney wme onlayn ödə, webmoney wme onlayn pul qoymaq, webmoney wme onlayn balansı artırmaq',
      630: 'webmoney wmr onlayn ödə, webmoney wmr onlayn pul qoymaq, webmoney wmr onlayn balansı artırmaq',
      631: 'webmoney wmz onlayn ödə, webmoney wmz onlayn pul qoymaq, webmoney wmz onlayn balansı artırmaq',
      683: 'xbox lite onlayn ödə, xbox lite onlayn pul qoymaq, xbox lite onlayn balansı artırmaq',
    },
    education: {
      629: 'yandex dengi onlayn ödə, yandex dengi onlayn pul qoymaq, yandex dengi onlayn balansı artırmaq',
      765: 'azcard onlayn ödə, azcard onlayn pul qoymaq, azcard onlayn balansı artırmaq',
      681: 'facebook oyunlar onlayn ödə, facebook oyunlar onlayn pul qoymaq, facebook oyunlar onlayn balansı artırmaq',
      680: 'google play onlayn ödə, google play onlayn pul qoymaq, google play onlayn balansı artırmaq',
      633: 'moneta onlayn ödə, moneta onlayn pul qoymaq, moneta onlayn balansı artırmaq',
      624: 'qiwi euro onlayn ödə, qiwi euro onlayn pul qoymaq, qiwi euro onlayn balansı artırmaq',
      625: 'qiwi rub onlayn ödə, qiwi rub onlayn pul qoymaq, qiwi rub onlayn balansı artırmaq',
      623: 'qiwi usd onlayn ödə, qiwi usd onlayn pul qoymaq, qiwi usd onlayn balansı artırmaq',
      578: 'steam onlayn ödə, steam onlayn pul qoymaq, steam onlayn balansı artırmaq',
      632: 'webmoney wme onlayn ödə, webmoney wme onlayn pul qoymaq, webmoney wme onlayn balansı artırmaq',
      630: 'webmoney wmr onlayn ödə, webmoney wmr onlayn pul qoymaq, webmoney wmr onlayn balansı artırmaq',
      631: 'webmoney wmz onlayn ödə, webmoney wmz onlayn pul qoymaq, webmoney wmz onlayn balansı artırmaq',
      683: 'xbox lite onlayn ödə, xbox lite onlayn pul qoymaq, xbox lite onlayn balansı artırmaq',
    },
    online_store: {
      629: 'yandex dengi onlayn ödə, yandex dengi onlayn pul qoymaq, yandex dengi onlayn balansı artırmaq',
      765: 'azcard onlayn ödə, azcard onlayn pul qoymaq, azcard onlayn balansı artırmaq',
      681: 'facebook oyunlar onlayn ödə, facebook oyunlar onlayn pul qoymaq, facebook oyunlar onlayn balansı artırmaq',
      680: 'google play onlayn ödə, google play onlayn pul qoymaq, google play onlayn balansı artırmaq',
      633: 'moneta onlayn ödə, moneta onlayn pul qoymaq, moneta onlayn balansı artırmaq',
      624: 'qiwi euro onlayn ödə, qiwi euro onlayn pul qoymaq, qiwi euro onlayn balansı artırmaq',
      625: 'qiwi rub onlayn ödə, qiwi rub onlayn pul qoymaq, qiwi rub onlayn balansı artırmaq',
      623: 'qiwi usd onlayn ödə, qiwi usd onlayn pul qoymaq, qiwi usd onlayn balansı artırmaq',
      578: 'steam onlayn ödə, steam onlayn pul qoymaq, steam onlayn balansı artırmaq',
      632: 'webmoney wme onlayn ödə, webmoney wme onlayn pul qoymaq, webmoney wme onlayn balansı artırmaq',
      630: 'webmoney wmr onlayn ödə, webmoney wmr onlayn pul qoymaq, webmoney wmr onlayn balansı artırmaq',
      631: 'webmoney wmz onlayn ödə, webmoney wmz onlayn pul qoymaq, webmoney wmz onlayn balansı artırmaq',
      683: 'xbox lite onlayn ödə, xbox lite onlayn pul qoymaq, xbox lite onlayn balansı artırmaq',
    },
    transport: {
      767: 'taksi 189,taksi 189 onlayn \u00f6de,taksi borcunu onlayn ode',
    },
    government: {
      901: 'standartlashdirma institutu online ode, standartlashdirma institutu borcu oyren',
      826: 'patent ve emtee nishanlari merkezi borcunu onlayn ode, patent ve emtee nishanlari merkezi borcunu oyren',
      964: 'dovlet miqrasiya xidmeti rusumunu ode, dovlet miqrasiya xidmeti borcunu ode',
      965: 'daxili ishler nazirliyi borcunu onlayn ode, daxili ishler nazirliyi borcunu oyren',
    },
    banks: {
      184: 'afb bank onlayn ode, afb bank onlayn pul qoymaq, afb bank onlayn kredit odemek.',
      409: 'albukart onlayn ode, albukart onlayn pul qoymaq, albukart onlayn kredit odemek.',
      347: 'access bank onlayn ode, access bank onlayn pul qoymaq, access bank onlayn kredit odemek.',
      486: 'amrahbank onlayn ode, amrahbank onlayn pul qoymaq, amrahbank onlayn kredit odemek.',
      186: 'atabank onlayn ode, atabank onlayn pul qoymaq, atabank onlayn kredit odemek.',
      226: 'azercredit onlayn ode, azercredit onlayn pul qoymaq, azercredit onlayn kredit odemek.',
      834: 'azer turk bank onlayn ode, azer turk bank onlayn pul qoymaq, azer turk bank onlayn kredit odemek.',
      746: 'bank avrasiya onlayn ode, bank avrasiya onlayn pul qoymaq, bank avrasiya onlayn kredit odemek.',
      225: 'bank vtb onlayn ode, bank vtb onlayn pul qoymaq, bank vtb onlayn kredit odemek.',
      388: 'bank vtb onlayn ode, bank vtb onlayn pul qoymaq, bank vtb onlayn kredit odemek.',
      182: 'bank of baku onlayn ode, bank of baku onlayn pul qoymaq, bank of baku onlayn kredit odemek.',
      183: 'bolkart onlayn ode, bolkart onlayn pul qoymaq, bolkart onlayn kredit odemek.',
      706: 'brandcredit onlayn ode, brandcredit onlayn pul qoymaq, brandcredit onlayn kredit odemek.',
      349: 'embafinans onlayn ode, embafinans onlayn pul qoymaq, embafinans onlayn kredit odemek.',
      800: 'fintrend onlayn ode, fintrend onlayn pul qoymaq, fintrend onlayn kredit odemek.',
      191: 'flexikredi onlayn ode, flexikredi onlayn pul qoymaq, flexikredi onlayn kredit odemek.',
      193: 'finca onlayn ode, finca onlayn pul qoymaq, finca onlayn kredit odemek.',
      192: 'kapital bank onlayn ode, kapital bank onlayn pul qoymaq, kapital bank onlayn kredit odemek.',
      407: 'kapital bank onlayn ode, kapital bank onlayn pul qoymaq, kapital bank onlayn kredit odemek.',
      634: 'molbulak onlayn ode, molbulak onlayn pul qoymaq, molbulak onlayn kredit odemek.',
      485: 'muganbank onlayn ode, muganbank onlayn pul qoymaq, muganbank onlayn kredit odemek.',
      194: 'nikoil bank onlayn ode, nikoil bank onlayn pul qoymaq, nikoil bank onlayn kredit odemek.',
      195: 'respublika bank onlayn ode, respublika bank onlayn pul qoymaq, respublika bank onlayn kredit odemek.',
      346: 'sebakredit bank onlayn ode, sebakredit bank onlayn pul qoymaq, sebakredit bank onlayn kredit odemek.',
      506: 'silkway bank onlayn ode, silkway bank onlayn pul qoymaq, silkway bank onlayn kredit odemek.',
      196: 'turan bank onlayn ode, turan bank onlayn pul qoymaq, turan bank onlayn kredit odemek.',
      408: 'unibank onlayn ode, unibank onlayn pul qoymaq, unibank onlayn kredit odemek.',
      406: 'worldcard onlayn ode, worldcard onlayn pul qoymaq, worldcard onlayn kredit odemek.',
      405: 'yapi kredi onlayn ode, yapi kredi onlayn pul qoymaq, yapi kredi onlayn kredit odemek.',
    },
    other: {
      266: 'ali nino borcunu onlayn odemek, ali nino borcunu oyrenmek, ali nino pulunu odemek.',
      645: 'avira borcunu onlayn odemek, avira borcunu oyrenmek, avira pulunu odemek.',
      684: 'bitdefender borcunu onlayn odemek, bitdefender borcunu oyrenmek, bitdefender pulunu odemek.',
      676: 'eset nod 32 borcunu onlayn odemek, eset nod32 borcunu oyrenmek, eset nod32 pulunu odemek.',
      679: 'kaspersky borcunu onlayn odemek, kaspersky borcunu oyrenmek, kaspersky pulunu odemek.',
      305: 'bonuslar borcunu onlayn odemek, bonuslar borcunu oyrenmek, bonuslar pulunu odemek.',
      675: 'destek borcunu onlayn odemek, destek borcunu oyrenmek, destek pulunu odemek.',
      179: 'domofon borcunu onlayn odemek, domofon borcunu oyrenmek, domofon pulunu odemek.',
      181: 'just smile borcunu onlayn odemek, just smile borcunu oyrenmek, just smile pulunu odemek.',
      726: 'lalafo borcunu onlayn odemek, lalafo borcunu oyrenmek, lalafo pulunu odemek.',
      677: 'turbo az borcunu onlayn odemek, turbo az borcunu oyrenmek, turbo az pulunu odemek.',
      901: 'standartlasdirma institutu borcunu onlayn odemek, standartlasdirma institutu borcunu oyrenmek, standartlasdirma institutu pulunu odemek.',
      904: 'gilan borcunu onlayn odemek, gilan borcunu oyrenmek, gilan institutu pulunu odemek.',
      902: 'hayat management borcunu onlayn odemek, hayat management borcunu oyrenmek, hayat management pulunu odemek.',
    },
    insurance: {
      content: (
        <>
          <h2>Sığortanın onlayn ödənişi</h2>
          <p>
            Azərbaycanda internet vasitəsilə sığortanı mümkün qədər tez bir
            zamanda doldurmaq mümkündür. Vaxtınıza və səyinizə dəyər verin.
            Ödənişin dərhal qəbul ediləcəyinə əmin olmaq istəyirsiniz? E-PUL
            xidmətindən istifadə edin. Biz, Bakı və Azərbaycanın sığorta
            şirkətlərini bir platformada topladıq. Hər şey sadə və aydındır. Bir
            neçə klik ilə hər kəs üçün mümkündür.
          </p>
          <p>
            Sadəcə olaraq veb sayt və ya tətbiqi proqram vasitəsilə təklifdən
            faydalanın. Qeydiyyat bir neçə dəqiqə çəkəcəkdir. Daha sonra aktual
            bank kartını “qoşa” və ya virtual kart aça bilərsiniz. Xidmət
            Android, iOS platformalarında işləyir. Sığortanı öz telefonunuzdan
            ödəyə bilərsiniz.
          </p>
          <h2>Bakıda onlayn sığortanın ödənişi necə keçir?</h2>
          <ol>
            <li>İlk addım – bank kartının qeydiyyatı və seçilməsi</li>
            <li>
              Daha sonra istədiyiniz sığorta növünü seçin. Rahatlıq üçün
              axtarışdan istifadə edə bilərsiniz. Diqqət! Xidmət komissiyası
              olmayan sığorta növləri mövcuddur.
            </li>
            <li>
              Tələb olunan rekvizitləri daxil edin - və ödənişi həyata keçirin.
            </li>
            <li>
              Vəsaitlər sığorta şirkətinin hesabına mümkün qədər tez köçürülür.
            </li>
          </ol>
          <p>
            Veb sayt saat kimi işləyir: tez, dəqiq, təhlükəsiz, etibarlı. Bu
            sığorta ödənişinin ən asan və ən əlverişli yoludur. İnternet
            bağlantısı olan hər kəsə uyğundur. Qadjetin növündən asılı
            olmayaraq.
          </p>
          <h2>E-PUL platformasının təhlükəsizliyi və etibarlılığı</h2>
          <ul>
            <li>
              Xidmətin qanuniliyi və təhlükəsizliyi dövlət və beynəlxalq
              sertifikatlarla təsdiqlənir. Bu sertifikatlar saytda
              yerləşdirilib.
            </li>
            <li>
              Resurs ən böyük sığorta şirkətləri ilə əməkdaşlıq edir. Axtarış
              sətrinə istədiyiniz xidməti daxil edə bilərsiniz. Bu cür imkan
              ödəniş prosesini asanlaşdırır.
            </li>
            <li>
              Sığortanın onlayn ödəniş xidməti minimum komissiya ilə həyata
              keçirilir.
            </li>
            <li>
              Şərtlərlə saytda tanış ola bilərsiniz. Və ya maraqlandıran
              suallarla resurs əməkdaşlarına onlayn və telefon vasitəsilə
              müraciət edə bilərsiniz.
            </li>
          </ul>
          <p>
            Xidmətdən istifadə şərtləri sadə və şəffafdır. Məlumat hər kəs üçün
            aydındır və əlçatandır. Ödəniş həyata keçirmək üçün bütün təlimatlar
            ətraflı şəkildə təsvir edilib.
          </p>
          <p>
            Vəsaitlərin sığorta hesabında köçürülməsi bir neçə dəqiqə
            çəkəcəkdir. Yeni istifadəçilər əlavə konsultasiyadan faydalana
            bilər. İlk istifadədən sonra seçiminizin optimallığından əmin
            olacaqsınız! Bütün sığorta artıq bir neçə klik məsafəsindədir.
          </p>
        </>
      ),
      160: 'avtomobil sigorta onlayn odemek, masin sigortasini onlayn odemek, sigorta onlayn oyrenmek, atesgah sigorta onlayn odemek.',
      727: 'avtomobil sigorta onlayn odemek, masin sigortasini onlayn odemek, sigorta onlayn oyrenmek, baki sigorta onlayn odemek.',
      715: 'avtomobil sigorta onlayn odemek, masin sigortasini onlayn odemek, sigorta onlayn oyrenmek, pasha sigorta onlayn odemek.',
      783: 'avtomobil sigorta onlayn odemek, masin sigortasini onlayn odemek, sigorta onlayn oyrenmek, qala heyat sigorta onlayn odemek.',
      900: 'avtomobil sigorta onlayn odemek, masin sigortasini onlayn odemek, sigorta onlayn oyrenmek, meqa sigorta onlayn odemek.',
      930: 'avtomobil sigorta onlayn odemek, masin sigortasini onlayn odemek, sigorta onlayn oyrenmek, ata sigorta onlayn odemek.',
    },
    notFoundDescription:
      'EPUL sistemi onlayn ödəmə. Onlayn ödəyin, ödəniş tarixçənizi qeyd edin, sənədlərinizi avtomatik olaraq ödəyin, kartdan kartdan karta köçürün və bonusları yığın.',
    notFoundKeywords:
      'onlayn ödəniş, onlayn ödəmə, kartdan karta pul köçürmə, kommunal xidmətlər onlayn ödəmə, mobil operatorlar onlayn ödəmə, internet provayderləri onlayn ödəmə, tv provayderləri onlayn ödəmə, stasionar telefonlar onlayn ödəmə, əyləncə xidmətləri onlayn ödəmə, digər xidmətlər onlayn ödəmə, onlayn bankçılıq ödəmə, ödəniş sistemi onlayn ödəmə, sığorta onlayn ödəmə, təhsil onlayn ödəmə, cərimələr onlayn ödəmə.',
  },
};
