import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IBonuses, IHistory } from "../../models/Bonuses";
import { IBonusGift, IBonusStore } from "../../models/BonusStore";
import { IBonusCategory } from "../../models/BonusCategory";
import client, { ADMIN_BACK_PUBLIC, BASE_URL } from "../../axios";
import moment from "moment";

const token = localStorage.getItem("token");

export const fetchBonuses = createAsyncThunk(
  "bonList/fetchBonuses",
  async (sid: string | null | undefined, thunkAPI) => {
    try {
      const today = moment().format('YYYY.MM.DD');
      const monthAgo = moment().subtract(1, 'months').format('YYYY.MM.DD');

      const res = await client.get<IBonuses, any>(`bonus/list/1?sessionId=${sid || localStorage.getItem("token")}&from=${monthAgo}&to=${today}`)

      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const fetchBonusGetDetail = createAsyncThunk(
  "bonList/fetchBonusGetDetail",
  async (
    { sid, id }: { sid?: string | undefined | null; id: number },
    thunkAPI
  ) => {
    try {
      // const resBonus = await axios.get<IBonusGift, any>(
      //     `${BASE_URL}bonus/gift_detail/sessionId=${localStorage.getItem("token")}/${id}`,
      //     {
      //         headers: {
      //             "Content-Type": "application/json",
      //             "Payment-Origin": "web",
      //         },
      //     }
      // );

      const response = await fetch(
        `${BASE_URL}bonus/gift_detail/sessionId=${localStorage.getItem("token")}/${id}`,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Payment-Origin": "web",
          },
        }
      )

      const json = await response.json();

      return json?.items.bonusGift[0];
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);

interface fetchBonusStoreParams {
  // categoryId: number,
  offset: number;
  selectedCategoryId: number;
  categoryProductId: number;
}
export const fetchBonusStore = createAsyncThunk(
  "bonusStore/fetchBonusStore",
  async (params: fetchBonusStoreParams, thunkAPI) => {
    try {
      const { selectedCategoryId, offset, categoryProductId } = params;
      const limit = 12;
      const resBonusStore = await client.get<IBonusStore>(
        "bonus/storeByLimit/" +
        localStorage.getItem("token") +
        `?categoryId=${categoryProductId}` +
        `&offset=${offset}` +
        `&limit=${limit}`
      );
      return resBonusStore.data.items.bonusGift;
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const fetchBonusCategory = createAsyncThunk(
  "bonusCategory/fetchBonusCategory",
  async (thunkAPI) => {
    try {
      const resBonusCategory = await axios.get<IBonusCategory, any>(
        // `${ADMIN_BACK_PUBLIC}list-bonus-gift-categories`
        "https://admin.e-pul.az/back/public/list-bonus-gift-categories"
      );
      return resBonusCategory.data;
    } catch (e) {
      return thunkAPI;
    }
  }
);

export const fetchPrintedHistory = createAsyncThunk(
  "bonList/getPrintInfo",
  async (data: any, thunkAPI) => {
    try {
      const res = await client.get<IHistory, any>(
        `history/receipt2?orderId=${data.orderId}&historyId=${data.historyId
        }&sessionId=${localStorage.getItem("token")}&afterPayment=${data.afterPayment
        }`
      );
      return res.data;
    } catch (e) {
      return thunkAPI;
    }
  }
);

export const fetchHistoryReceipt = createAsyncThunk(
  "bonList/receipt",
  async (data: any, thunkAPI) => {
    try {
      const res = await client.post<any>(
        `history/receipt2/send?orderId=${data.orderId}&historyId=${data.historyId
        }&email=${data.email}&sendType=${data.sendType
        }&sessionId=${localStorage.getItem("token")}&afterPayment=true`
      );
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);
