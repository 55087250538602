import React from 'react';
import "./index.scss"

interface HeaderTextProps {
    title: string
}

const HeaderText = ({title}: HeaderTextProps) => {
    return (
        <div className="header-text">
            <p className="header-text__title">{title}</p>
        </div>
    );
};

export default HeaderText;