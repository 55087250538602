import React from "react"
import ContentLoader from "react-content-loader"

const MediaLess410FavSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={75}
        viewBox="0 0 410 75"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="62" rx="0" ry="0" width="410" height="5" />
        <circle cx="30" cy="30" r="25" />
        <rect x="65" y="19" rx="0" ry="0" width="108" height="8" />
        <rect x="65" y="36" rx="0" ry="0" width="101" height="8" />
        <rect x="244" y="25" rx="5" ry="5" width="92" height="17" />
        <rect x="346" y="28" rx="0" ry="0" width="27" height="10" />
    </ContentLoader>
)

export default MediaLess410FavSkeleton

