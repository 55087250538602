import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { checkBonusServicesDigests, checkCategoriesDigests } from "../../redux/actions/CategoriesActions";
import { getVirtualCardInfo } from "../../redux/actions/cardsActions";
import { authSelector } from "../../redux/slices/auth";
import { getCart } from "../../redux/actions/basketActions";

export const useDataLoader = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(authSelector);
  //@ts-ignore
  const { basket } = useAppSelector((state) => state.basketSlice);
  const token = localStorage.getItem("token");
  
  useEffect(() => {
      dispatch(checkCategoriesDigests());
      dispatch(checkBonusServicesDigests());
      dispatch(getVirtualCardInfo());
      dispatch(getCart({cartId: basket?.cart?.id || localStorage.getItem('basketId'), isAuth, token}))
  }, [dispatch, isAuth, token]);
}