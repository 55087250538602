import React, {useEffect, useState} from 'react';
import {Category, LocaleName} from "../../models/Categories";
import {Link, useLocation, useParams} from "react-router-dom";
import {categoriesInfo} from "../../consts";
import {useAppSelector} from "../../hooks/redux";
import './index.scss'
import {useTranslation} from "react-i18next";

interface IOtherPayments {
    handleDataFromChild?: any,
}

const OtherPayments = ({handleDataFromChild}: IOtherPayments) => {
    const { t } = useTranslation()
    const params = useParams()
    const { language, langKey } = useAppSelector(state => state.changeLangSlice)
    const [allServices, setAllServices] = useState<Category[]>([]);
    const {categories, services} = useAppSelector((state) => state.categoriesReducer);
    const [showCategory, setShowCategory] = useState<boolean>(false)
    const auth = useAppSelector((state) => state.authReducer);
    const savedPath = auth.isAuth ? "new-payment" : "guest-payment"

    const data = categories;

    const sendDataToParent = () => {
        handleDataFromChild(showCategory);
    };

    useEffect(() => {
        const popularity = [1, 2, 3, 5, 9];

        const main = data?.filter((item: any) => popularity?.includes(item.id))
        const others = data?.filter((item: any) => !popularity?.includes(item.id))
        const all = main.concat(others)

        if (data) {
            setAllServices(all)
        }
    }, [data, allServices.length]);

    const handleClickMoreInfo = () => {
        setShowCategory(prev => !prev)
        sendDataToParent()
    }

    const resultOtherPayment = allServices.filter(item => item.name !== params.category)

    return (
        <div className="other-payment-panel">
            <div className="other-payment-panel__title">{t('otherPayments')}</div>
            <div className="other-payment-panel__category">
                {resultOtherPayment.slice(0,10).map((item: Category) => {
                    return <Link state={{ category: item, savedPath, processName: savedPath }}
                                 to={`/epay/${language}/category/${item.name}`} key={item.id}
                                 className="other-payment-panel__category-item">
                        <div className="other-payment-panel__category-icon"
                             style={{ background: categoriesInfo[item.id]?.backGround }}>
                            <div className="other-payment-panel__category-image">
                                <img src={categoriesInfo[item.id]?.img} alt="Logo" className="other-payment-panel__category-image-img" />
                            </div>
                        </div>
                        <div className="other-payment-panel__category-text">
                            {item.localName[langKey as keyof LocaleName]}
                        </div>
                    </Link>
                })}
                {showCategory && resultOtherPayment.slice(11).map((item: Category) => {
                    return <Link state={{ category: item, savedPath, processName: savedPath }}
                                 to={`/epay/${language}/category/${item.name}`} key={item.id}
                                 className="other-payment-panel__category-item">
                        <div className="other-payment-panel__category-icon"
                             style={{ background: categoriesInfo[item.id]?.backGround }}>
                            <div className="other-payment-panel__category-image">
                                <img src={categoriesInfo[item.id]?.img} alt="Logo" className="other-payment-panel__category-image-img" />
                            </div>
                        </div>
                        <div className="other-payment-panel__category-text">
                            {item.localName[langKey as keyof LocaleName]}
                        </div>
                    </Link>
                })}
                <div
                    className="other-payment-panel__category-more-info"
                    onClick={handleClickMoreInfo}
                >
                    {!showCategory ? t('showMore') : t('collapse')}
                </div>
            </div>
        </div>
    );
};

export default React.memo(OtherPayments);
