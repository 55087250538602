import React, { useState } from "react";
import {Box, Dialog, IconButton, InputAdornment, TextField, Typography,} from "@mui/material";
import Button from "../Button/Button";
import BootstrapDialogTitle from "../BootstrapDialogTitle";
import {useForm} from "react-hook-form";
import {
  fetchForgotPassword,
  fetchForgotPasswordChangePas,
  fetchForgotPasswordPasCheckOtp,
} from "../../redux/actions/authActions";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import CPhoneInput from "../CustomsComponents/PhoneInput";
import './index.scss'
import {notify} from "../../helper";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface ForgotPasswordModalProps {
  handleClosePasswordModal: () => void;
  showForgotPasswordModal: boolean;
}

// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '1px solid #000',
//     borderRadius: '15px',
//     p: 2,
// }

const ForgotPasswordModal = ({
  handleClosePasswordModal,
  showForgotPasswordModal,
}: ForgotPasswordModalProps) => {
  const dispatch = useAppDispatch();
  const { phone, otp } = useAppSelector((state) => state.authReducer);
  const {t} = useTranslation()

  const {
    register,
    handleSubmit,
    setError,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      phone: phone,
      otp: otp,
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickRepeatShowPassword = () => setShowRepeatPassword(!showRepeatPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);

  const onSubmit = (values: {
    phone: string;
    otp?: string;
    newPassword?: string;
    confirmPassword?: string;
  }) => {
    if (newPassword && confirmPassword) {
      if (getValues().newPassword !== getValues().confirmPassword) {
        setError("confirmPassword", {
          type: "manual",
          message: t("checkPasswordsMatch") || "",
        })
      } else {
      dispatch(fetchForgotPasswordChangePas(values)).then((r) =>
        handleClosePasswordModal()
      );
      }
    } else if (getValues("otp")) {
      values.otp && setValue("otp", values.otp);
      setValue("phone", values.phone);
      dispatch(fetchForgotPasswordPasCheckOtp(values));
    } else {
      setValue("phone", values.phone);
      dispatch(fetchForgotPassword(values))
    }
  };

  return (
    <Dialog
      open={showForgotPasswordModal}
      onClose={handleClosePasswordModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BootstrapDialogTitle
          id="restore-password"
          children={
            <Typography sx={{fontWeight: "600"}} variant="h5">
              {t("restorePassword")}
            </Typography>
          }
          // id={"1"}
          onClose={handleClosePasswordModal}
      />
      <Box sx={{px: 2.5}}>
        <Typography>
          {t("passwordRecoveryLink")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/*<TextField*/}
          {/*  color="warning"*/}
          {/*  variant="standard"*/}
          {/*  margin="normal"*/}
          {/*  disabled={!!phone}*/}
          {/*  InputProps={{*/}
          {/*    startAdornment: (*/}
          {/*      <InputAdornment position="start">+994</InputAdornment>*/}
          {/*    ),*/}
          {/*  }}*/}
          {/*  label={t("phoneNumber")}*/}
          {/*  // defaultValue={}*/}
          {/*  fullWidth*/}
          {/*  // helperText={errors.phone?.message}*/}
          {/*  error={Boolean(errors.phone?.message)}*/}
          {/*  {...register("phone", { required: `${t("enterPhoneNumber")}` })}*/}
          {/*/>*/}
          <CPhoneInput control={control}/>


          {/*<input onChange={onChangeNumber}/>*/}
          {phone && (
            <TextField
              disabled={!!otp}
              type="string"
              color="warning"
              variant="standard"
              margin="normal"
              label={t("code")}
              fullWidth
              // helperText={errors.code?.message}
              error={Boolean(errors.otp?.message)}
              {...register("otp", {
                required: `${t("enterCodeSent")}`,
              })}
            />
          )}
          {otp && (
            <>
              <TextField
                label={t("password")}
                color="warning"
                margin="normal"
                type={showPassword ? "text" : "password"}
                helperText={errors.newPassword?.message}
                error={Boolean(errors.newPassword?.message)}
                variant="standard"
                fullWidth
                {...register("newPassword", {
                  required: `${t("enterPassword")}`,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                color="warning"
                variant="standard"
                margin="normal"
                type={showRepeatPassword ? "text" : "password"}
                label={t("confirmThePassword")}
                helperText={errors.confirmPassword?.message}
                error={Boolean(errors.confirmPassword?.message)}
                fullWidth
                {...register("confirmPassword", {
                  required: `${t("passwordsDontMatch")}`,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickRepeatShowPassword}
                          onMouseDown={handleMouseDownRepeatPassword}
                      >
                        {showRepeatPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </>
          )}
          <Button
            type="submit"
            width="100%"
            buttonText={`${t("send")}`}
            background={"rgba(239, 92, 39, 1)"}
            padding="14px 0"
            margin="20px 0 14px 0"
            borderRadius={15}
            color="white"
          />
        </form>
      </Box>
    </Dialog>
  );
};

export default ForgotPasswordModal;
