import React, {FC, useEffect} from "react";
import RegistrationLeftMain from "../RegistrationLeftMain/RegistrationLeftMain";
import RelaxRightInfo from "../RelaxRightInfo/RelaxRightInfo";
import RegistrationModal from "../RegistrationModal/RegistrationModal";
import LastOperation from "../LastOperation/LastOperation";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import RightMenuSkeleton from "../Sceletons/RightMenuSkeleton/RightMenuSkeleton";
import RightMenuSkeleton1030 from "../Sceletons/RightMenuSkeleton/RightMenuSkeleton1030";
import "./index.scss"
import OtherPayments from "../OtherPayments/OtherPayments";
import OtherPaymentSwiper from "../OtherPayments/OtherPaymentSwiper";
import OtherPaymentSwiperSkeleton from "../Sceletons/OtherPaymentSwiperSkeleton/OtherPaymentSwiperSkeleton";
import {fetchHistoryList} from "../../redux/actions/historyAction";

interface IProps {
    withHistory?: boolean;
}

const RightComponents: FC<IProps> = (props) => {
    const {isAuth, isLoading} = useAppSelector((state) => state.authReducer);
    const walletListLoading = useAppSelector(
        (state) => state.walletList.isLoading
    );
    const debtListLoading = useAppSelector((state) => state.debtsList.isLoading);
    const {withHistory} = props;

    const url = window.location.pathname;
    const hasCategory = url.includes("category");

    return (
        <div className='right-component' style={{width: "100%"}}>
            {isAuth ? (
                <>
                    {isLoading || walletListLoading || debtListLoading ? (
                        <>
                            <div className="skeleton">
                                <div className="right-skeleton"><RightMenuSkeleton/></div>
                                <div className="right-skeleton1030"><RightMenuSkeleton1030/></div>
                            </div>
                            <div className="skeleton-swiper">
                                <OtherPaymentSwiperSkeleton/>
                            </div>
                        </>
                    ) : (
                        <>
                            {hasCategory ?
                                <>
                                    <div className="right-component-panel">
                                        <OtherPayments/>
                                    </div>
                                    <div className="layout__app-otherWrap">
                                        <div className='layout__app-otherWrap-border'></div>
                                        <div className="layout__app-otherSwiper">
                                            <OtherPaymentSwiper/>
                                        </div>
                                    </div>
                                </>
                                :
                                <RegistrationModal/>
                            }
                            {withHistory ? <LastOperation/> : null}


                        </>
                    )}
                    {/*<RegistrationModal />*/}
                    {/*<LastOperation/>*/}
                </>
            ) : (
                <>
                    <div className="right-component-other">
                        <RegistrationLeftMain/>
                    </div>
                    <div className="layout__app-otherWrap">
                        <div className='layout__app-otherWrap-border'></div>
                        <div className="layout__app-otherSwiper">
                            <OtherPaymentSwiper/>
                        </div>
                    </div>
                    {/*<RelaxRightInfo />*/}
                </>
            )}
        </div>
    );
};

export default RightComponents;
