import React from "react"
import ContentLoader from "react-content-loader"

const LineHeaderBonusSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={50}
        viewBox="0 0 590 60"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="20" rx="0" ry="0" width="500" height="50" />
    </ContentLoader>
)

export default LineHeaderBonusSkeleton