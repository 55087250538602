import React, { FC } from 'react';

import { useForm } from "react-hook-form";

import './index.scss'


interface IProps {
    style?: any;
    getValue?: () => void;
    required: boolean;
    pattern: RegExp;
    name: string;
    errorMsg?: string;
    placeHolder?: string;
    value: string;
    setValue: (value: string) => void;
}
const Input: FC<IProps> = (props) => {
    const {name, required, pattern, errorMsg, placeHolder, style, value, setValue} = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const onSubmit = () => {
        reset();
    };

    const errorMessage: any = errors[name]?.message

    return(
        <div className="input-box">
            <form className="input-box__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-box__field">
                    {
                        name === 'phone' &&
                        <div className="input-box__phone-prefix">+994</div>
                    }
                    <input
                        value={value}
                        style={{paddingLeft: '0', ...style}}
                        {...register(name, {
                            required,
                            pattern: {
                                value: pattern,
                                message: errorMsg || '',
                            },
                        })}
                        onKeyUp={() => {
                            trigger(name);
                        }}
                        placeholder={placeHolder}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
                {/* {errors[name] &&
                    <p className="text-danger">
                        {errorMessage}
                    </p>
                } */}
            </form>
        </div>
        )
}

export default Input;
