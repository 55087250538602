import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/redux';
import Button from '../Button/Button';
import CustomModal from '../ModalComponent/CustomModal/CustomModal';

interface UnAuthModalProps {
  showUnAuthModal: boolean;
  language: string;
  setShowUnAuthModal: (value: boolean) => void;
}

export const UnAuthModal: React.FC<UnAuthModalProps> = ({ showUnAuthModal, language, setShowUnAuthModal }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const handleModal = () => {
    dispatch(setShowUnAuthModal(false));
    navigate(`/epay/${language}/sign/in`);
  };

  const closeModal = () => {
    dispatch(setShowUnAuthModal(false));
    navigate('/');
  };

  return (
    <div className='token-modal-invilid'>
      <CustomModal
        active={showUnAuthModal}
        //@ts-ignore
        setActive={(value) => dispatch(setShowUnAuthModal(value))}
      >
        <div className={'token-modal'}>
          <div className='token-modal__header'>
            <div className='token-modal__close-icon'>
              <img
                className='token-modal__close'
                onClick={closeModal}
                src={require('../../../src/img/close-icon.svg').default}
                alt='token-modal__close'
              />
            </div>
          </div>
          <div className='token-modal__content'>
            <div className='token-modal__text'>{t('sessionExpired')}</div>
            <div className='token-modal__text'>{t('loginAgain2')}</div>
          </div>
          <div className='button-wrap-token'>
            <Button
              minWidth={327}
              borderRadius={15}
              background='#EF5C27'
              color='#FFFFFF'
              padding='14px 133px'
              buttonText={t('logIn')}
              onClick={handleModal}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
