const ServiceActionTypes = {
  SET_SERVICE: 'SET_SERVICE',
  SET_ERROR: 'SET_ERROR',
  IS_HUB_SOLUTION_PAYMENT_METHOD: 'IS_HUB_SOLUTION_PAYMENT_METHOD',
};

export const ServiceLoadingTypes = {
  GET_ONE: 'GET_ONE',
};

export default ServiceActionTypes;
