import React from "react"
import ContentLoader from "react-content-loader"

const SettingsLess900Skeleton = () => (
    <ContentLoader
        speed={2}
        width='100%'
        height={1000}
        viewBox="0 0 900 1000"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle style={{display: 'flex'}} cx={'49%'} cy="100" r="54" />
        <rect x="0" y="0" rx="0" ry="0" width="250" height="32" />
        <rect style={{display: 'flex'}} x="0" y="173" rx="0" ry="0" width="100%" height="32" />
        <rect style={{display: 'flex'}} x="0" y="225" rx="0" ry="0" width="100%" height="32" />
        <rect style={{display: 'flex'}} x="0" y="277" rx="0" ry="0" width="100%" height="32" />
        <rect style={{display: 'flex'}} x="0" y="329" rx="0" ry="0" width="100%" height="38" />
        <rect style={{display: 'flex'}} x="0" y="387" rx="0" ry="0" width="100%" height="54" />
        <rect style={{display: 'flex'}} x="0" y="461" rx="0" ry="0" width="100%" height="32" />
        <rect style={{display: 'flex'}} x="0" y="513" rx="0" ry="0" width="100%" height="32" />

        <rect style={{display: 'flex'}} x="0" y="565" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="565" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="617" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="617" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="669" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="669" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="721" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="721" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="773" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="773" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="825" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="825" rx="0" ry="0" width="200" height="32" />

    </ContentLoader>
)

export default SettingsLess900Skeleton