import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../axios";
import { IUserPersonalLink } from "../../models/userPersonlanLink";

const token = localStorage.getItem("token");

export const getUserPersonalLinkFetch = createAsyncThunk(
    "personalLink/getUserPersonalLinkFetch",
    async (sid: string | undefined | null, thunkApi) => {
        try {
            const resUserPersonalLink = await client.post<IUserPersonalLink>(
                `user/get_link?sessionId=${localStorage.getItem("token")}`
            );
            return resUserPersonalLink.data;
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось загрузить");
        }
    }
);