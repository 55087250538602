import React, { useEffect } from 'react';
import './FAQ.scss'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
import LayoutWithSidebar from "../layouts/LayoutWithSidebar/LayoutWithSidebar";
import {useNavigate} from "react-router-dom";
import { useAppSelector } from '../../hooks/redux';
//@ts-ignore
import bonusPdf from '../../../src/pdf/bonus_epul.pdf';
//@ts-ignore
import { Helmet } from 'react-helmet';
import LayoutWithApp from "../layouts/LayoutWithApp/LayoutWithApp";

const Faq = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language } = useAppSelector((state) => state.changeLangSlice);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <LayoutWithApp
            goBack={() => navigate(`/epay/${language}/home`)}
            navTitle={t('home')}
        >
            <div className='faq-body'>
                <Helmet>
                    <title>{t('faqTitle')}</title>
                    <meta name="description" content={t('faqDescription') || ''}/>
                    <meta name="keywords" content={t('faqKeywords') || ''}/>
                </Helmet>
                <h1 className="faq-body__title">{t("questionsAnswers")}</h1>
                <div className='faq-body-accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='typography-margin'>{t('howRegister')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer1")}<br/>{t("warning")}<br/>{t("questionNum")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className='typography-margin'>{t("question2")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer2")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className='typography-margin'>{t("question3")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer3")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>{t("question4")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer4")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>{t("question5")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer5")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>{t("question6")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer6")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>{t("bonus")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t("answer7")}
                                <a target="_blank" href={bonusPdf} rel="noreferrer" style={{marginLeft: '5px'}}>{t("bonusCalculationMethod")}</a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography> {t("question8")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer8")}<br/>{t("answer82")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography> {t("question9")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t("answer9")}</Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </LayoutWithApp>
    );
};

export default Faq;

