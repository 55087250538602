import React, { FC, useEffect, useState } from "react";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PaymentFields from "../../PaymentFields";
import { useAppSelector } from "../../../hooks/redux";
import { ServiceActionCreators } from "../../../redux/reducers/service/action-creators";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { Languages } from "../../../redux/reducers/language/languageData";
import { toast } from "react-toastify";
import { fetchWithTimeout } from "../../../utils/query";
import client, { BASE_URL } from "../../../axios";
import Loading from "../../Loading/Loading";
import AdditionalFields from "../../AdditionalFields/AdditionalFields";
import "./index.scss";
import CardsBox from "./components/CardsBox";
import { useTranslation } from "react-i18next";

interface IAutoPaymentTransactionProps {}

const cardSelectorCategories = new Set([9, 5, 10, 16]);
const cardSelectorServices = new Set([1069]);
const fieldsForPicker = [
  { label: "Visa", key: "newcard_visa", value: "newcard_visa" },
  { label: "MasterCard", key: "newcard_mc", value: "newcard_mc" },
];

const AutoPaymentTransaction: FC<IAutoPaymentTransactionProps> = () => {
  const { t } = useTranslation();
  const service = useAppSelector((state) => state.service);
  const loading = useAppSelector((state) => state.loading);
  const language = useAppSelector((state) => state.language);

  const location = useLocation();

  const [subVal, setSubVal] = useState("");
  const [fields, setFields] = useState([]);
  const [groupId, setGroupId] = useState(0);
  const [msgType, setMsgType] = useState(0);
  const [subName, setSubName] = useState("");
  const [checkData, setCheckData] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [cardPanId, setCardPanId] = useState("newcard_visa");
  const [isLoading, setIsLoading] = useState(false);
  const [instrument, setInstrument] = useState<any>(null);
  const [amountValue, setAmountValue] = useState("");
  const [isClientExist, setIsClientExist] = useState(false);
  const [isAmountCorrect, setIsAmountCorrect] = useState(true);
  const [paramAmountValue, setParamAmountValue] = useState(
    location?.state?.amount || ""
  );
  const [showVerdictFields, setShowVerdictFields] = useState(false);
  const [allFieldsHaveData, setAllFieldsHaveData] = useState(false);
  const [loadingNotTransparent, setLoadingNotTransparent] = useState(false);
  const [autoRedirectToPayment, setAutoRedirectToPayment] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const params = useParams();

  const navigate = useNavigate();
  const { language: selectedLanguage } = language;
  const lang = Languages(selectedLanguage);

  console.log("params", params);
  console.log("location", location.state);

  const { state } = location;
  const changeUrl = () => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    console.log("changeUrl", fields);

    const { name } = fields[0];
    const { value } = fields[0];

    params.set(name, value);

    url.search = params.toString();

    window.history.pushState({}, "", url);
  };

  const isAuthToken = localStorage.getItem("token") || "";

  useEffect(() => {
    const getServiceById = async () => {
      const { serviceId } = params;
      await dispatch(
        ServiceActionCreators.getServiceById({
          selected: [serviceId && +serviceId],
          hash: serviceId && +serviceId,
        })
      );
    };

    getServiceById().catch(console.error);
  }, []);

  useEffect(() => {
    if (
      !state.isFieldEdit &&
      !params.isCreate &&
      (allFieldsHaveData || autoRedirectToPayment)
    ) {
      checkIfUserExist(
        isAuthToken,
        service.service.id,
        JSON.stringify({ fields }),
        groupId || 0
      );
    }
  }, [allFieldsHaveData, autoRedirectToPayment]);

  //add types
  const checkIfUserExist = async (
    sessionId: string,
    serviceId: any,
    fields: any,
    selectedGroupId: any
  ) => {
    setIsLoading(true);

    try {
      // const response = await fetchWithTimeout(
      //     `${BASE_URL}categories/client_info_with_group_id?fields=${encodeURIComponent(
      //         fields
      //     )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}&sessionId=${sessionId}`,
      //     {
      //         method: "GET",
      //         headers: {
      //             "Content-Type": "application/x-www-form-urlencoded",
      //             "Payment-Origin": "web",
      //         },
      //     }
      // );
      const response = await client.get(
        `categories/client_info_with_group_id?fields=${encodeURIComponent(
          fields
        )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}&sessionId=${sessionId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        // const json = await response.json();

        if (response.data.resultCodes === "OK") {
          if (params.process === "isEdit") {
            changeUrl();
          }
          setIsClientExist(true);
          setCheckData(response.data);
        } else if (
          response.data.resultCodes === "NOT_FOUND" ||
          response.data.resultCodes === "INVALID_PARAMETERS" ||
          response.data.resultCodes === "UNKNOWN_ERROR"
        ) {
          setMsgType(1);
          notify();
        } else if (
          response.data.resultCodes === "UNDECIDED_PROTOCOL" ||
          response.data.resultCodes === "PROTOCOL_CANT_DECIDED"
        ) {
          setShowVerdictFields(true);
        }
      } else {
        setMsgType(0);
        notify();
      }
    } catch (e) {
      setMsgType(0);
      notify();
    } finally {
      setIsLoading(false);
    }
  };

  const notify = () => {
    toast.error(msgType === 1 ? lang.noClientMsg : lang.someError, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const renderPicker = () => {
    return (
      <div className="service_fields-item">
        <p className="service_fields-item-title">{lang.selectCard}</p>
        <select
          aria-label="service_fields"
          onChange={(e) => {
            setCardPanId(e.target.value);
          }}
          value={cardPanId}
        >
          {fieldsForPicker.map((item, i) => {
            return (
              <option key={item.key} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  if (service.error) {
    return <div className="service_error">Ошибка при загрузке Сервиса</div>;
  }

  const loader = (
    <div
      className={`loader-wrapper ${
        loadingNotTransparent ? "loader-wrapper--not-transparent" : ""
      } `}
    >
      <Loading />
    </div>
  );

  if (Object.keys(service.service).length === 0) {
    return loader;
  }

  const onBtnClick = () => {
    if (isClientExist) {
      processToAutoPayment();
    } else {
      checkIfUserExist(
        isAuthToken,
        service.service.id,
        JSON.stringify({ fields }),
        groupId || 0
      );
    }
  };

  const processToAutoPayment = async () => {
    const path =
      params.process === "isEdit"
        ? `/epay/${language}/edit-payment/${state.id}`
        : `/epay/${language}/payment-page`;
    await navigate(path, {
      state: {
        service: service.service,
        checkData,
        cardPanId: cardPanId,
        subName: subName,
        subVal: subVal,
        groupId: groupId || 0,
        amount: Number(amountValue),
        fields,
        cardInfo: instrument?.card,
        isFavChecked: isChecked,
      },
    });
  };

  const btnValue =
    params.process === "isEdit" ? `${t("edit")}` : `${t("next")}`;

  const getBtnTitle = () => {
    return isClientExist ? btnValue : lang.check;
  };

  const onClickCancel = () => {
    navigate(`/epay/${language}/edit-payment/${params.serviceId}`, {
      state: {
        isEdit: true,
      },
    });
  };

  return (
    <div className="transaction">
      <NavigationComponent title={"Назад"} onClick={() => navigate(-1)} />
      <div className="transaction__content">
        <div className="transaction__title">
          {service.service.localName[language.langKey]}
        </div>
        <div className="transaction__form-box">
          <div className="transaction__form">
            <PaymentFields
              showVerdictFields={showVerdictFields}
              service={service?.service}
              onValueChange={(data: any) => {
                data.fields && setFields(data.fields);
                data.groupId && setGroupId(data.groupId);
                data.allFieldsHaveData &&
                  setAllFieldsHaveData(data.allFieldsHaveData);
                data.autoRedirectToPayment &&
                  setAutoRedirectToPayment(data.autoRedirectToPayment);
              }}
              isClientExist={isClientExist}
              azercell={service?.service?.id === 976}
              fieldsForPicker={fieldsForPicker}
              // defaultFields={fields}
            />
            {isAuthToken && isClientExist && (
              <>
                <AdditionalFields
                  onChange={(data: any) => {
                    data.amountValue && setAmountValue(data.amountValue);
                    data.isAmountCorrect &&
                      setIsAmountCorrect(data.isAmountCorrect);
                  }}
                  isAmountCorrect={isAmountCorrect}
                  service={service.service}
                  checkData={checkData}
                  defaultValue={amountValue || paramAmountValue}
                />
                {(cardSelectorCategories.has(service?.service?.categoryId) ||
                  cardSelectorServices.has(service?.service?.id)) &&
                  renderPicker()}
                <CardsBox
                  isChecked={isChecked}
                  setIsChecked={() => setIsChecked((prev) => !prev)}
                  setInstrument={setInstrument}
                />
              </>
            )}

            <button
              className="transaction__btn"
              disabled={!isAmountCorrect && isClientExist}
              onClick={() => onBtnClick()}
            >
              {getBtnTitle()}
            </button>
            {params.process === "isEdit" && (
              <button
                className="transaction__btn-cancel"
                onClick={() => onClickCancel()}
              >
                {lang.cancel}
              </button>
            )}
          </div>
          {(isLoading || loading.isLoading || loading.isPaymentLoading) &&
            loader}
        </div>
      </div>
    </div>
  );
};

export default AutoPaymentTransaction;
