import React, { useEffect, useState } from "react";
import "./index.scss";
import NavigationComponent from "../CustomsComponents/NavigationComponent";
import LeftComponents from "../LeftComponents";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {Category, LocaleName, Service} from "../../models/Categories";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux";
import { fetchFavoritesList } from "../../redux/actions/favoritesListAction";
import {useTranslation} from "react-i18next";

interface CategoryProps {
  category: Category;
}

const show = require("../../img/show.svg").default;
const imagePerRow = 9;

const PayCommunal = () => {
  const { t } = useTranslation();
  const [next, setNext] = useState(imagePerRow);
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  // const {category}: CategoryProps =location.state;
  // const {service} = category.services
  // const [category, setCategory] = useState<any>([])
  const [service, setService] = useState<Service[]>([]);
  const isAuth = useAppSelector((state) => state.authReducer.isAuth);
  const {language, langKey} = useAppSelector(state => state.changeLangSlice)

  useEffect(() => {
    dispatch(fetchFavoritesList());
    if (Array.isArray(location.state)) {
      setService([...location.state]);
    } else {
      const { category } = location.state;
      // setCategory(category)
      setService([...category.services.service]);
    }
  }, []);

  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => navigate(`/epay/${language}/home`);


  return (
    <div className="pay-communal">
      <div className="pay-communal__left">
        <NavigationComponent onClick={goBack} title={t("home")} />
        <div className="pay-communal__left-wrap">
          {service?.slice(0, next)?.map((service: any) => (
            <Link
              to={`${location?.state?.path || `/epay/${language}/payment`}/${service.id}`}
              state={{ service }}
              className="pay-communal__left-wrap-container"
            >
              <div className="pay-communal__left-wrap-container-image">
                <img
                  className="pay-communal__left-wrap-container-image-pic"
                  src={
                    "https://www.e-pul.az/epul_api/images/" +
                    service.friendlyName +
                    ".png"
                  }
                  onError={(event) => {
                    //@ts-ignore
                    event.target.src = 'https://placehold.co/60x60/EF5C27/FFF';
                  }}
                  alt=""
                />
              </div>
              <div className="pay-communal__left-wrap-container-title">
                {service.localName[langKey as keyof LocaleName]}
              </div>
            </Link>
          ))}
        </div>
        {
          // @ts-ignore
          next < service.length && (
            <div className="catalogue-blocks__button">
              <Button
                buttonText={`${t("uploadMore")}`}
                background={"none"}
                color="#EF5C27"
                padding="16.5px 27px 16.5px 27px"
                borderRadius={15}
                margin="0 10px 0 0"
                icon={show}
                onClick={handleMoreImage}
              />
            </div>
          )
        }

        <div className="pay-communal__left-wrap-line"></div>

        {/*<div className="pay-communal__left-add-templates">*/}
        {/*    <p className="pay-communal__left-add-headtext">{"Шаблоны"}</p>*/}
        {/*    <Link to="/add-favorites" className="pay-communal__left-add-icon">*/}
        {/*        <img src={require("../../img/plus-card-icon.svg").default} alt=""/>*/}
        {/*        <p className="pay-communal__left-add-icon-text">{"Добавить шаблон"}</p>*/}
        {/*    </Link>*/}
        {/*</div>*/}
        {/*<AddTemplateAccordion/>*/}
      </div>
      {isAuth ? (
        <div className="pay-communal__right">
          <LeftComponents />
        </div>
      ) : null}
    </div>
  );
};

export default PayCommunal;
