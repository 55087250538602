import { createAsyncThunk } from "@reduxjs/toolkit";
import { IWallet } from "../../models/Wallet";
import client from "../../axios";
import { IAuthState } from "../slices/auth";

const token = localStorage.getItem("token");

export const createWallet = createAsyncThunk(
  "wallet/fetchWallet",
  async (sid: string | undefined, thunkApi) => {
    try {
      const resWallet = await client.post<IWallet>(
        "wallet/create/" + (sid || token) + '?currency=AZN'
      );
      return resWallet.data;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const sendIdentificationByCourier = createAsyncThunk(
  "wallet/sendIdentificationByCourier",
  async (sendData: any, thunkApi) => {
    try {
      const res = await client.post<any>(
        `wallet/identify/delivery_base64/${sendData.sessionId}?deliveryAddress=${sendData.deliveryAddress}&livingAddress=${sendData.livingAddress}&contactType=${sendData.contactType}&contactAddress=${sendData.contactAddress}`,
        {
          first: sendData.first,
          second: sendData.second,
          third: sendData.third,
        }
      );

      return res;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const getSimaQrCode = createAsyncThunk('wallet/getSimaQrCode',
    async (_, thunkApi) => {
        try{
            const response = await client.post(`sima/identification?sid=${localStorage.getItem('token')}`)
            console.log('getSimaQrCode helloooo', response)

            return response;

        } catch (e) {
            console.log('getSimaQrCode catch error', e)
        }
    }
)

export const getQrCode = createAsyncThunk('wallet/getQrCode',
  async (queryParams: any, thunkApi) => {
    try{
      const response = await client.post(`/invoice/wallet_invoice/create/${localStorage.getItem('token')}?phone=${queryParams.phone}&amount=${queryParams.amount}&activeTill=${queryParams.activeTill}&wallet_currency=${queryParams.wallet_currency}&description=${queryParams.description}`)
      return response;
    } catch (e) {
      console.log('getQrCode error', e)
    }
  }
)

export const getInvoice = createAsyncThunk('wallet/getInvoice',
  async (invoiceUnique: any, thunkApi) => {
    try{
      const response = await client.get(`/invoice/get_invoice_guest?invoiceUnique=${invoiceUnique}`)
      return response;
    } catch (e) {
      console.log('getInvoice error', e)
    }
  }
)

export const payInvoice = createAsyncThunk('wallet/payInvoice',
  async (queryParams: any, thunkApi) => {
    try{
      const response = await client.post(`/guest/do_invoice_pay/${localStorage.getItem('token')}?tokenType=${queryParams.tokenType}&cardPanId=${queryParams.cardPanId}&amount=${queryParams.amount}&lng=${queryParams.lng}&historyId=${queryParams.historyId}&returnUrl=${queryParams.returnUrl}&invoiceUnique=${queryParams.invoiceUnique}`)
      return response;
    } catch (e) {
      console.log('payInvoice error', e)
    }
  }
)

export const getC2qrService = createAsyncThunk(
  "wallet/getC2qrService",
  async (
    {
      sid,
      phone,
    }: { sid?: string | undefined | null; phone?: string | undefined | null },
    thunkApi
  ) => {
    try {
      const state = (thunkApi.getState() as any).authReducer as IAuthState;

      phone = phone || state.user?.phone;

      // const response = await fetchWithTimeout(BASE_URL + 'categories/service/' + (phone?.startsWith('+79') ? 'pay_by_qr_tkb' : 'pay_by_mobile') + '/' + (sid || token), {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded',
      //         'Payment-Origin': 'Web'
      //     },
      // });
      const response = await client.get(
        "categories/service/" +
          (phone?.startsWith("+79") ? "pay_by_qr_tkb" : "pay_by_mobile") +
          "/" +
          (sid || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Payment-Origin": "Web",
          },
        }
      );
      if (response.status === 200) {
        // const json = await response.json();
        if (response.data.id) {
          return response.data;
        }
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const getC2wService = createAsyncThunk(
  "wallet/getC2wService",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      const response = await client.get(
        "wallet/get/service/fill_wallet/" + (sid || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      //  const response = await fetchWithTimeout(
      //   BASE_URL + "wallet/get/service/fill_wallet/" + (sid || token),
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "Web",
      //     },
      //   }
      // );
      // const json = await response.json();
      if (response.data.id) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const getW2wService = createAsyncThunk(
  "wallet/getW2wService",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      const response = await client.get(
        "wallet/get/service/wallet_transfer/" + (sid || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.id) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);
