import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPrivateMessage } from "../../models/MessagesRead";
import client from "../../axios";

const token = localStorage.getItem("token");

export const fetchMessagesRead = createAsyncThunk(
  "messagesRead/fetchMessagesRead",
  async ({ sid, id }: { sid?: string; id?: string }, thunkApi) => {
    try {
      const resMessagesRead = await client.post<IPrivateMessage>(
        `message/read/${id}` + "?sessionId=" + (sid || localStorage.getItem("token"))
      );
      return resMessagesRead.data;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);
