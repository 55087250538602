import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import CustomModal from "../../../Components/ModalComponent/CustomModal/CustomModal";
import LayoutEmpty from "../../../Components/layouts/LayoutEmpty/LayoutEmpty";
import {useNavigate} from "react-router-dom";

const img1 = require('../../../img/e-walletpull.jpg')
const img2 = require('../../../img/bonusIcon.jpg')
const img3 = require('../../../img/debtIcon.jpg')
const img4 = require('../../../img/recurringPayment.jpg')

const AdvantSecurity = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [activeModal, setActiveModal] = useState(false)
    const [modalInfo, setModalInfo] = useState<string>("")
    const [modalTitle, setModalTitle] = useState<string>("")
    const advantageArrSec = [
        {
            img: img1,
            title: t("compSecurity"),
            path: '#',
            info: t("info1")
        },
        {
            img: img2,
            title: t("verifiOfPayer"),
            path: '#',
            info: t("info2")

        },
        {
            img: img3,
            title: t("sendInformation"),
            path: '#',
            info: t("info3")

        },
        {
            img: img4,
            title: t("securityFilter"),
            path: '#',
            info: t("info4")

        },
    ]

    const goBack = () => navigate(-1);

    return (
        <LayoutEmpty goBack={goBack} navTitle={t("advantages")}>
            <div className="advantages">
                <div className="advantages__body">
                    {advantageArrSec.map((item, index) => (
                        <div onClick={() => {
                            setModalInfo(item.info)
                            setModalTitle(item.title)
                            setActiveModal(true)
                        }} className="advantages__body-item">
                            <div className="advantages__body-item-img">
                                <img src={item.img} alt="advantages" width="100%"/>
                            </div>
                            <div>
                                <p className="advantages__body-item-text">{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <CustomModal setActive={setActiveModal} active={activeModal}>
                    <div className="advantages__modal-wrap">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div className="advantages__modal-wrap-title">{modalTitle}</div>
                            <div>
                                <img
                                    style={{cursor: "pointer"}}
                                    src={require("../../../img/close-icon.svg").default}
                                    alt="close"
                                    onClick={() => setActiveModal(false)}
                                />
                            </div>
                        </div>
                        <hr style={{margin: "15px 0"}}/>
                        <div className="advantages__modal-wrap-text">
                            {modalInfo}
                        </div>
                    </div>
                </CustomModal>

            </div>
        </LayoutEmpty>
    );
};

export default AdvantSecurity;