import React from 'react';
import "./index.scss"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useAppSelector } from '../../hooks/redux';

const AddNewCard = () => {
    const {t} = useTranslation();
    const { language } = useAppSelector((state): any => state.changeLangSlice);

    return (
        <div className="replenishment-of-mobile__slider-wrap">
            <p className="replenishment-of-mobile__slider-wrap-text">{t("paymentMethod")}</p>
            <Link to={`/epay/${language}/add-my-card`} className="replenishment-of-mobile__slider-wrap-text-add">
                <img src={require("../../img/plus-card-icon.svg").default} alt=""/>
                <p className="replenishment-of-mobile__slider-add-new-card">{t("addNewCard")}</p>
            </Link>
        </div>
    );
};

export default AddNewCard;