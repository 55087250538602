const EditIcon = ({favorite, setFieldValue, openModal, fieldName, index}) => {
  return (
    <img
      onClick={() => {
        if(fieldName == 'comment') {
          setFieldValue({value: favorite.comment, name: 'comment'})
        } else {
          setFieldValue({value: favorite.fields.paymentListField[index].value, index: index, name: 'field'})
        }
        openModal();
    }}
    src={require("../../img/edit-icon.svg").default}
    alt="edit-icon"
  />)
}

export default EditIcon;