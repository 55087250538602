import React, { memo } from 'react';
import "./index.scss"
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

import appStoreAZ from '../../../img/appstore_az_b.svg';
import appStoreRu from '../../../img/appstore_ru_b.svg';
import appStoreEN from '../../../img/appstore_en_b.svg';
interface InstallAppAdsProps {
}

const InstallAppAds = memo((props: InstallAppAdsProps) => {
    const { t } = useTranslation();
    const langValue = i18next?.language;

    return (
        <div className="install-app-ads">
            <div className="install-app-ads__main">{t("installEpulApp")}</div>
            <div className="install-app-ads__ads-icon">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=az.epul.technopay.android" rel="noreferrer" className="install-app-ads__ads-icon-div">
                    {langValue === "az" ?
                            <img src={require('../../../img/gpAz.jpg')} style={{width: 157, height: 50}} alt="logo" loading={"lazy"} />
                        : langValue === "ru" ?
                                <img src={require('../../../img/gPRu.jpg')} style={{width: 157, height: 50}}  alt="logo" loading={"lazy"} />
                            : langValue === "en" ?
                                    <img src={require('../../../img/gPen.jpg')} style={{width: 157, height: 50}}  alt="logo" loading={"lazy"} />
                                :
                                    <img src={require('../../../img/gpAz.jpg')} style={{width: 157, height: 50}} alt="logo" loading={"lazy"} />
                    }
                </a>
                <a target="_blank" href="https://apps.apple.com/us/app/e-pul/id965770019?ign-mpt=uo%3D4" rel="noreferrer" className="install-app-ads__ads-icon-div">
                    {langValue === "az" ?
                            <img src={appStoreAZ} style={{ height: 50}} alt="logo" loading={"lazy"}/>
                        : langValue === "ru" ?
                                <img src={appStoreRu} style={{ height: 50}} alt="logo" loading={"lazy"}/>
                            : langValue === "en" ?
                                    <img src={appStoreEN} style={{ height: 50}} alt="logo" loading={"lazy"}/>
                                :
                                    <img src={appStoreAZ} style={{ height: 50}} alt="logo" width={182} height={58} loading={"lazy"}/>
                }
                </a>
            </div>
        </div>
    );
});

export default InstallAppAds;
