import React, {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import AsideSettings from "../AsideSettings/AsideSettings";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import "./Notifications.scss";
import AddCheckboxIn from "../../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {fetchProfileDetails} from "../../../redux/actions/authActions";
import {changeNotificationSetting, updateProfileData,} from "../../../redux/actions/profileActions";
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";

const message = require("../../../img/message.svg").default;

const Notifications = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const goBack = () => navigate(`/epay/${language}/settings`);
  const {profileDetails} = useAppSelector((state) => state.authReducer);
  const {profile} = useAppSelector((state) => state.authReducer);
  const [getNotifications, setGetNotifications] = useState(
    profileDetails?.mobileNotificationEnabled
  );

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, []);

  const onChangeNotificationType = (e: any) => {
    dispatch(
      updateProfileData({
        fullName: profile?.user?.firstName! + profile?.user?.lastName,
        otp: e.target.value,
      })
    );
  };

  const onChangeNotificationSetting = () => {
    setGetNotifications(!getNotifications);
    dispatch(
      changeNotificationSetting({
        params: {
          notificationEnabled: !getNotifications,
        },
      })
    );
  };

  return (
      <LayoutEmpty goBack={goBack} navTitle={t("settings")}>
        <div className="notifications">
          <div className="notifications-body">
            {/*<NavigationComponent onClick={goBack} title={t("settings")}/>*/}

            <h2 className="notifications-body__title">{t("notifications")}</h2>

            <div className="notifications-body__content">
              <div className="notifications__item">
                <AddCheckboxIn
                    onClick={onChangeNotificationSetting}
                    width={30}
                    height={30}
                    padding="10px 0"
                    title={`${t("receiveNotifications")}`}
                    isChecked={!!getNotifications}
                />
              </div>
              <div className="border"></div>
              {/*<form onSubmit={handleSubmit(onSubmit)} className="sms-notifications">*/}
              <div className="sms-notifications">
                <img src={message} alt="" />
                <span className="sms-notifications__title">{t("notifyBy")}</span>
                <select
                    aria-label="sms-notifications"
                    onChange={(e) => onChangeNotificationType(e)}
                    className="editable-form__dropdown"
                    value={profile?.user?.preferredOtp}
                    name=""
                    id=""
                >
                  <option value={"SMS"}>SMS</option>
                  <option value={"EMAIL"}>E-mail</option>
                </select>
              </div>

              {/*</form>*/}
            </div>
          </div>
          <div className="notifications-right">
            <AsideSettings/>
          </div>
        </div>
      </LayoutEmpty>
  );
};

export default Notifications;
