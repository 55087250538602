import { createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../axios'

export const fetchBrowserToken = createAsyncThunk(
  'browserToken/fetchBrowserToken',
  async (sid: string | undefined, thunkApi) => {
    try {
      const url = sid ? `user/browser_token?sid=${sid}` : 'user/browser_token'

      const res = await client.post(url)
      return { ...res, isAuthBrowserToken: sid ? true : false }
    } catch (e) {
      return thunkApi.rejectWithValue('Не удалось загрузить')
    }
  },
)
