import React, {FC, useEffect} from "react";
import "./index.scss";
import {Link, NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import {LocaleName} from "../../models/Categories";
import LastOperationSkeleton from "../Sceletons/RightMenuSkeleton/LastOperationSkeleton";
import {fetchHistoryList} from "../../redux/actions/historyAction";


const loaderImg = require('../../img/loading.gif');
const isDefault = require('../../img/close-icon.svg');

const LastOperation: FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const { historyList, isLoading } = useAppSelector((state) => state.historyList);
  const { services } = useAppSelector((state) => state.categoriesReducer);
  const {langKey, language} = useAppSelector(state => state.changeLangSlice)

  useEffect(() => {
    dispatch(fetchHistoryList());
  }, [dispatch]);

  const itemImg = (item: any) => {
    // const findService = services?.find((service: any) => service.id === item?.normalizedServiceId);
    const findService = services?.find((service: any) => service.id !== item?.id);
    if (!findService) {
      return loaderImg;
    }

    return `https://www.e-pul.az/epul_api/images/${item.friendlyName}.png`;
  }

  return (isLoading
          ?
      <div className="last-operation">
        <LastOperationSkeleton />
      </div>
          :
            <div className="last-operation">
              <div className="last-operation__header">
                <div className="last-operation__header-div">
                  <p className="last-operation__header-div-text">{t("recentOperations")}</p>
                </div>
                {historyList?.totalCount === 0 ?
                    <div className="last-operation__header-div-empty">{t("empty")}</div>
                     :
                    historyList?.items?.history?.slice(0, 4).map((item: any) => (
                        <NavLink to={`/epay/${language}/result/payment?isPayHistory=false&historyId=${item?.fields?.historyField[0]?.historyId}&orderId=${item.orderId}`} key={item.id} className="last-operation__information">
                          <div className="last-operation__information-main">
                            <div className="last-operation__information-main-div">
                              <div className="last-operation__information-image-wrap">
                                <img
                                    className="last-operation__information-image"
                                    src={itemImg(item)}
                                    alt="Service"
                                />
                              </div>
                            </div>
                            <div className="last-operation__information-title">
                              <p className="last-operation__information-title-street">
                                {item.fields.historyField[0]?.value}
                              </p>
                              {item.fields.historyField?.[1]?.value &&
                                  <p className="last-operation__information-title-street">
                                    {item?.fields?.historyField?.[1]?.value}
                                  </p>
                              }
                              <p className="last-operation__information-title-service">
                                {item.localName[langKey as keyof LocaleName]}
                              </p>
                            </div>
                            <div className="last-operation__information-count-wrap">
                              <p className="last-operation__information-price">
                                {item.amount / 100}
                              </p>
                              <p className="last-operation__information-currency">
                                {item.currency}
                              </p>
                            </div>
                          </div>
                        </NavLink>
                    ))}
                <div className="last-operation__footer">
                  <Link to={`/epay/${language}/history`} className="last-operation__footer-text">
                    {t("allOperations")}
                  </Link>
                </div>
              </div>
            </div>

  );
};

export default LastOperation;
