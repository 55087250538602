import { useTranslation } from 'react-i18next';
import Input from "../components/Inputs/Inputs";

interface AmountInputProps {
  amountValue: string;
  handleAmountChange: (value: string) => void;
}
const amountReg = new RegExp("^[0-9]+$");

const AmountInput: React.FC<AmountInputProps> = ({
  amountValue,
  handleAmountChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="top-up-balance__field-box">
      <div className="top-up-balance__field-title">{t('amount')}</div>
      <div className="top-up-balance__field">
        <Input
          style={{
            width: '367px',
            height: '49px',
            fontWeight: '500',
            fontSize: '30px',
            lineHeight: '49px',
          }}
          name={'amount'}
          pattern={amountReg}
          errorMsg={`${t('errorAmount')}`}
          required
          placeHolder="0"
          value={amountValue}
          setValue={handleAmountChange}
        />
      </div>
    </div>
  );
};

export default AmountInput;
