import React from 'react';
import '../index.scss'

const AdvantC2C = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Kartdan-Karta xidməti"}</div>
                <div
                    className="about__right-text">{"E-Pul-da mövcud olan və istifadəçilərin rahatlığına şərait yaradan xidmətlərdən biri – Kartdan-karta daha da genişləndi. Belə ki, pul köçürmələri artıq bütün Azərbaycan banklarının debet kartları ilə mümkün olacaq!"}
                </div>
                <div className="about__right-text">
                    {'E-Pul.az Azərbaycanda ilk dəfə, bank olmayan təşkilat olaraq, ölkə üzrə ani plastik kart köçürmələrini həyata keçirir. Kartdan-karta xidmətinin komissiyası 1%, minimum 1 AZN-ə qədər azaldı.'}
                </div>
                <div className="about__right-text">
                    {'Məlumat üçün bildirək ki, bu xidmətdən sayt və mobil tətbiq vasitəsi ilə yararlana bilərsiniz.'}
                </div>
                <div className="about__right-text">
                    <a target="_blank" href="/" style={{fontSize: "24px", color: "#F78C40"}}>www.EPUL.az</a>
                </div>
            </div>
        </div>
    );
};

export default AdvantC2C;