import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavigationComponent from "../../Components/CustomsComponents/NavigationComponent";
import RegistrationModal from "../../Components/RegistrationModal/RegistrationModal";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchMessagesRead } from "../../redux/actions/messagesReadAction";
import { IPrivateMessage } from "../../models/MessagesRead";
import "./index.scss"
import { useTranslation } from "react-i18next";
import LayoutEmpty from "../../Components/layouts/LayoutEmpty/LayoutEmpty";
import { authSelector } from '../../redux/slices/auth';


const MessageInfoComponent = () => {
    const { t } = useTranslation();
    const { isAuth } = useAppSelector(authSelector);
    const { id } = useParams()
    const location: any = useLocation()
    const messageRead: IPrivateMessage = location.state?.messages
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchMessagesRead({ id }))
    }, [])

    const goBack = () => navigate(-1)

    function createMarkup() {
        return { __html: messageRead?.text };
    }

    return (
        <LayoutEmpty goBack={goBack} navTitle={t("messages")}>
            <div className="messageInfo">
                <div className="message-info">
                    {/*<NavigationComponent onClick={goBack} title={t("messages")}/>*/}
                    <div className="message-info__banner">
                        <div className="message-info__banner-text">
                            <div className="message-info__banner-text-title">{t("installApp")}</div>
                            <div className="message-info__banner-text-bonus">{t("getBonuses")}</div>
                        </div>
                        <div></div>
                    </div>
                    <div className="message-info__header-information">
                        <div className="message-info__header-information-head">
                            <div className="message-info__header-information-text">{messageRead?.subject}</div>
                            <span
                                className="message-info__header-information-date">{new Date(messageRead?.insertDate).toDateString().slice(4, 15)}</span>
                        </div>
                        <div className="message-info__header-text">
                            <div dangerouslySetInnerHTML={createMarkup()} />
                        </div>
                    </div>
                </div>
                <div className="message-right-menu">
                    {isAuth ? <RegistrationModal /> : null}
                </div>
            </div>
        </LayoutEmpty>
    );
};

export default MessageInfoComponent;

