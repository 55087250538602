import React, { FC, useEffect, useState } from "react";
import "../../Components/FavoritesComponent/index.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  deleteFromFavoritesFetch,
  fetchFavoritesList,
} from "../../redux/actions/favoritesListAction";
import { IFavoritesList } from "../../models/FavoritesList";
import CustomModal from "../../Components/ModalComponent/CustomModal/CustomModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LayoutWithSidebar from "../../Components/layouts/LayoutWithSidebar/LayoutWithSidebar";
import FavoritesSkeleton from "../../Components/Sceletons/FavoritesSkeleton/FavoritesSkeleton";
import MediaLess900FavSkeleton from "../../Components/Sceletons/FavoritesSkeleton/MediaLess900FavSkeleton";
import MediaLess610FavSkeleton from "../../Components/Sceletons/FavoritesSkeleton/MediaLess610FavSkeleton";
import MediaLess410FavSkeleton from "../../Components/Sceletons/FavoritesSkeleton/MediaLess410FavSkeleton";
import FavoriteList from "./FavoritesList";
import ModalButtons from "../../Components/ModalButtons/ModalButtons";
import { languageSelector } from "../../redux/reducers/language";
import { favoriteListSelector } from "../../redux/slices/favoritesSlice";

interface FavoritesComponentProps {
}
const FavoritesComponent: FC<IFavoritesList> = (
  props: FavoritesComponentProps
) => {
  const favorMockArr = [1, 2, 3, 4, 5];
  const { t } = useTranslation();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [selectedFavItem, setSelectedFavItem] = useState<IFavoritesList | null>(
    null
  );
  const { language } = useAppSelector(languageSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { favList, isLoading } = useAppSelector(favoriteListSelector);

  useEffect(() => {
    dispatch(fetchFavoritesList());
  }, []);

  const hasUrl = `/epay/${language}/`

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setDeleteModalActive(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const openDeleteModal = () => {
    setDeleteModalActive(true);
  };

  const onDeleteFavorites = () => {
    dispatch(deleteFromFavoritesFetch(selectedFavItem?.id));
    toast(
      favList?.resultCodes === "OK"
        ? `${t("successfullyDeleted")}`
        : `${t("error")}`
    );
    setDeleteModalActive(false);
  };

  return (
    <LayoutWithSidebar goBack={() => navigate(`${hasUrl}home`)} navTitle={t("home")}>
      <div className="favorites__add-templates">
        <div className="favorites__add-templates-wrap">
          <div className="favorites__add-templates-wrap-text">
            {t("templates")}
          </div>
          <Link
            to={`/epay/${language}/category/add-favorite`}
            state={{ savedPath: "AddFavorite" }}
            className="auto-payment-modal__body-item-new">
            <div
              className="favorites__add-templates-wrap-plus"
            >
              <img
                src={require("../../img/plus-card-icon.svg").default}
                alt="plus-card-icon"
              />
              <span className="favorites__add-templates-wrap-add">
                {t("addTemplate")}
              </span>
            </div>
          </Link>
        </div>
      </div>
      {isLoading
        ? favorMockArr.map((item) => {
          return <>
            <div className="favorites-skeleton"><FavoritesSkeleton key={item} /></div>
            <div className="media-favorites-skeleton"><MediaLess900FavSkeleton key={item} /></div>
            <div className="media610-favorites-skeleton"><MediaLess610FavSkeleton key={item} /></div>
            <div className="media410-favorites-skeleton"><MediaLess410FavSkeleton key={item} /></div>
          </>
        })
        : ""}
      <div className="favorites-list">
        {favList?.totalCount === 0 ? (
          <div className="favorites__add-templates-wrap-empty">
            {t("empty")}
          </div>
        ) : (
          !isLoading &&
          favList?.items.paymentListItem?.map((itemFav: any) => {
            return (
              <FavoriteList favorite={itemFav} openDeleteModal={openDeleteModal} setSelectedFavItem={setSelectedFavItem} />
            );
          })
        )}
      </div>
      <CustomModal
        active={deleteModalActive}
        setActive={setDeleteModalActive}
      >
        <ModalButtons text={'deleteTemplate'} buttonText={'delete'} handleInstruction={() => onDeleteFavorites()} handleCancel={() => setDeleteModalActive(false)} />
      </CustomModal>
    </LayoutWithSidebar>
  );
};

export default FavoritesComponent;