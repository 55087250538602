import React from "react";
import "../../Components/FavoritesComponent/index.scss"
import {Link} from "react-router-dom";
import { useAppSelector } from '../../hooks/redux';
import { createFavoriteFields, createFavoriteLink } from './functions';
import { useTranslation } from 'react-i18next'
import Button from '../../Components/Button/Button';
import FavoriteImage from './FavoriteImage';

const FavoriteList = ({favorite, openDeleteModal, setSelectedFavItem}) => {
  const { language, langKey } = useAppSelector(state => state.changeLangSlice);
  const { services, categories } = useAppSelector((state) => state.categoriesReducer);
  const { t } = useTranslation()

  const returnMainField = (paymentListField, name) => {
    if (name === 'Azercell') {
      if (!paymentListField.length || paymentListField.length < 2) {
        return undefined;
      }
      return paymentListField[0].enumFieldValue[langKey] + (paymentListField.length !== 1 ? paymentListField[1].value : '')
    } else {
      const field = paymentListField.find((payment) => payment.isMainField === true);
      return field?.value;
    }
  };

  return (
    <Link to={`/epay/${language}/favorite-info/${favorite.id}`} className="favotite-link">
      <div className='favorites'>
        <div className='favorites__left'>
          <FavoriteImage favorite={favorite}/>
          <div className="favorites__info">
            <div className="favorites__info__block">
              <div className="favorites__info__block__name">
                  {favorite.localName[langKey]}
              </div>
              <div className="favorites__info__block__value">
                  {returnMainField(favorite?.fields?.paymentListField, favorite.name)}
              </div>
              <div className="accordion__wrap-text-mob">
                  {favorite.comment}
              </div>
            </div>
          </div>
        </div>
        <div className='favorites__right'>
          <div className="favorites__info__debt">
            {favorite.hasDebt && favorite.lastDebt / 100 + " " + favorite.currency}
          </div>
          <Link
            to={createFavoriteLink(favorite, categories, services, language)}
            state={{
              favorite: true,
              serviceId: favorite.serviceId,
              fields: createFavoriteFields(favorite),
              groupId: favorite.groupId,
            }}
            className="favorites__pay"
          >
          <Button
              buttonText={`${t("pay")}`}
              width='110px'
              padding="10px 25px"
              borderRadius={15}
              border="1px solid rgba(239, 92, 39, 1)"
              background={"#FFFFFF"}
              color={"black"}
          />
          </Link>
          <div
            onClick={(e) => {
              e.preventDefault();
              openDeleteModal();
              setSelectedFavItem(favorite);
            }}
            className="favorites__info__trash"
          >
            <img src={require("../../img/trash.png")} alt={"trash"} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FavoriteList;