import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import AsideSettings from "../../AsideSettings/AsideSettings";
import Button from "../../../Button/Button";
import "./Password.scss";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {useForm} from "react-hook-form";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {changePassword} from "../../../../redux/actions/profileActions";
import {useTranslation} from "react-i18next";
import {notify} from "../../../../helper";
import ForgotPasswordModal from "../../../ForgotPasswordModal";
import LayoutEmpty from "../../../layouts/LayoutEmpty/LayoutEmpty";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const ChangePassword = () => {
  const {t} = useTranslation();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const navigate = useNavigate();
  const goBack = () => navigate(`/epay/${language}/settings`);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickRepeatShowPassword = () => setShowRepeatPassword(!showRepeatPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);


  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
    mode: "onBlur",
  });

  const handleOpenPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };
  const handleClosePasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  const onSubmit = (values: {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
  }) => {
    if (getValues().repeatPassword !== getValues().newPassword) {
      setError("repeatPassword", {
        type: "manual",
        message: "Проверьте совпадение паролей",
      });
    } else {
      dispatch(
        changePassword({
          params: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
        })
      )
          .then((resUpdateName: any) => {
            if (resUpdateName.payload.resultCodes === "OK" && resUpdateName.payload.description === "OK") {
              notify(`${t("passwordChangeSuccessful")}`, true);
            } else if (resUpdateName.payload.resultCodes === "INVALID_USERNAME_OR_PASSWORD" && resUpdateName.payload.description === "Invalid username or password") {
              notify(`${t("errorWhenChangingPassword")}`, false)
            } else {
              notify(`${t("errorWhenChangingPassword")}`, false)
            }
        navigate("/settings");
      });
    }
  };
  return (
      <LayoutEmpty goBack={goBack} navTitle={t("settings")}>
        <div className="password">
          <div className="password-body">
            <h2 className="password-body__title">{t("changeProfilePassword")}</h2>
            <div style={{display: "flex"}}>
              <form className="password__form" onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    color="warning"
                    variant="standard"
                    margin="normal"
                    type={showOldPassword ? "text" : "password"}
                    label={`${t("oldPassword")}`}
                    fullWidth
                    helperText={errors.oldPassword?.message}
                    error={Boolean(errors.oldPassword?.message)}
                    {...register("oldPassword", {required: `${t("enterYourOldPassword")}`})}
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                            >
                              {showOldPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                          </InputAdornment>
                      )
                    }}
                />
                <TextField
                    color="warning"
                    variant="standard"
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    label={`${t("newPassword")}`}
                    helperText={errors.newPassword?.message}
                    error={Boolean(errors.newPassword?.message)}
                    fullWidth
                    {...register("newPassword", { required: `${t("enterYourPassword")}` })}
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                          </InputAdornment>
                      )
                    }}
                />
                <TextField
                    color="warning"
                    variant="standard"
                    margin="normal"
                    type={showRepeatPassword ? "text" : "password"}
                    label={`${t("repeatPassword")}`}
                    helperText={errors.repeatPassword?.message}
                    error={Boolean(errors.repeatPassword?.message)}
                    fullWidth
                    {...register("repeatPassword", {
                      required: `${t("reenterPassword")}`,
                    })}
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickRepeatShowPassword}
                                onMouseDown={handleMouseDownRepeatPassword}
                            >
                              {showRepeatPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                          </InputAdornment>
                      )
                    }}
                />
                <div className="edit-password-footer">
                  <div className="password__buttons" >
                    <Button
                        buttonText={`${t("save")}`}
                        color="white"
                        background="#EF5C27"
                        border="1px solid #fff"
                        padding="14px 25px 14px 25px"
                        borderRadius={15}
                        width="100%"
                        type="submit"
                        disabled={!isValid}
                    />

                  </div>
                  <div className="forgot-password-wrap">
                    <span onClick={handleOpenPasswordModal}>{`${t("forgotPassword")}`}</span>
                  </div>
                </div>
              </form>
            </div>
            <ForgotPasswordModal
                showForgotPasswordModal={showForgotPasswordModal}
                handleClosePasswordModal={handleClosePasswordModal}
            />
          </div>
          <div className="password-right">
            <AsideSettings/>
          </div>
        </div>
      </LayoutEmpty>
  );
};

export default ChangePassword;
