import {IMessagesRead} from "../../models/MessagesRead";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchMessagesRead} from "../actions/messagesReadAction";

interface InitialState {
    sid: string
    error: string
    messagesRead: IMessagesRead | null
    isLoading: boolean
}

const initialState: InitialState = {
    sid: "",
    error: "",
    messagesRead: null,
    isLoading: false
}

export const messageReadSlice = createSlice({
    name: "messageRead",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchMessagesRead.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchMessagesRead.fulfilled.type]: (state, action: PayloadAction<IMessagesRead>) => {
            state.isLoading = false
            state.error = ""
            state.messagesRead = action.payload
        },
        [fetchMessagesRead.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        }
    }
})

export default messageReadSlice.reducer