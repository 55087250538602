import axios from "axios";
import client, { BASE_URL } from "../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { cardsSliceActions } from "../slices/cardsSlice";
import thunk from "redux-thunk";
import moment from "moment/moment";
import { notify } from "../../helper";
import { t } from "i18next";
import {FREE_PAY_URL} from "../../utils/consts";

export const getDigitalCardThunk = createAsyncThunk('cards/getDigitalCard',
    async (_, thunkApi: any) => {
        try {
            const res = await client.get(`unibank/getUserCard?sessionId=${localStorage.getItem('token')}`)


            if (res.status === 200) {
                if (res.data.ResultCodes === '0' && res.data.description === 'OK') {
                    if (res.data.data?.length) {
                        thunkApi.dispatch(cardsSliceActions.setDigitalCard(res.data.data[0]))
                        thunkApi.dispatch(getDigitalCardInfoThunk(res.data.data[0]?.id))
                    } else {
                        thunkApi.dispatch(cardsSliceActions.setDigitalCard(undefined))
                    }

                }
            }
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось получить ")
        }

    }
)


export const makeCardDefaultThunk = createAsyncThunk('cards/makeCardDefault',
    async (data: any, thunkAPI: any) => {
        try {
            return await client.post<any>(`cards/make_default/${data}?sessionId=${localStorage.getItem("token")}`, {}, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Payment-Origin': 'Web'
                }
            });
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось сохранить")
        }
    }
)

export const editSimpleCardNameThunk = createAsyncThunk('cards/makeCardDefault',
    async (data: any, thunkAPI: any) => {
        try {

            console.log('editSimpleCardNameThunk', data);
            const res = await client.post<any>(`cards/save_pan/${data.bindingId}?sessionId=${localStorage.getItem("token")}&name=${data.name}`, {}, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // 'Payment-Origin': 'Web'
                }
            });

            console.log('response', res.data)

            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось сохранить")
        }
    }
)





export const getDigitalCardInfoThunk = createAsyncThunk('cards/getDigitalCardInfoThunk',
    async (id: number, thunkApi: any) => {
        try {
            const res = await client.get(`unibank/getCardInfo/${id}?sessionId=${localStorage.getItem('token')}`)


            if (res.status === 200) {
                if (res.data.ResultCodes === '0' && res.data.description === 'OK') {
                    thunkApi.dispatch(cardsSliceActions.setDigitalCardInfo(res.data.data))
                }
            }
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось получить ")
        }

    }
)

export const getDigitalCardRequisitesThunk = createAsyncThunk('cards/getDigitalCardRequisitesThunk',
    async (id: number, thunkApi: any) => {
        try {
            const res = await client.get(`unibank/getRequisites/${id}?sessionId=${localStorage.getItem('token')}`)


            if (res.status === 200) {
                if (res.data.ResultCodes === '0' && res.data.description === 'OK') {
                    thunkApi.dispatch(cardsSliceActions.setRequisites(res.data.data))
                }
            }
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось получить ")
        }

    }
)

export const blockDigitalCardThunk = createAsyncThunk('cards/blockDigitalCardThunk',
    async (data: any, thunkApi: any) => {
        try {
            const res = await client.put(`unibank/${data.value}/${data.id}?sessionId=${localStorage.getItem('token')}`)


            if (res.status === 200) {
                if (res.data.ResultCodes === '0' && res.data.description === 'OK') {
                    thunkApi.dispatch(getDigitalCardInfoThunk(data.id))
                    if (data.value === 'blockCard') {
                        notify(t('successfullyBlock'), true)
                    } else {
                        notify(t('successfullyUnblock'), true)
                    }
                }
            }
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось получить ")
        }

    }
)

export const getDigitalCardStatementsThunk = createAsyncThunk('cards/blockDigitalCardThunk',
    async ({ id, dateFrom, dateTo }: any, thunkApi: any) => {
        try {
            const language = localStorage.getItem("language");
            const res = await client.get(`unibank/statements/${id}?sessionId=${localStorage.getItem('token')}&dateFrom=${dateFrom}&dateTo=${dateTo}&lang=${language}`)

            if (res.status === 200) {
                if (res.data.ResultCodes === '0' && res.data.description === 'OK') {
                    thunkApi.dispatch(cardsSliceActions.setStatements(res.data?.data))
                }
            }
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось получить ")
        }

    }
)

export const getVirtualCardInfo = createAsyncThunk('cards/getVirtualCardInfo',
    async (_, thunkApi: any) => {
        try {
            thunkApi.dispatch(getDigitalCardThunk())
            thunkApi.dispatch(cardsSliceActions.setRequisites(undefined))
        } catch (e) {

        }
    }
)


export const fetchRemoveCard = createAsyncThunk(
    'cards/fetchRemoveCard',
    async (params: { body: any; browserToken: string }, thunkApi) => {
        try {
            const res = await axios.post(
                `${FREE_PAY_URL}/api/cardStorage/remove`,
                params.body,
                {
                    headers: {
                        BrowserToken: params.browserToken,
                    },
                },
            )

            thunkApi.dispatch(cardsSliceActions.removeCard(params.body))

            return res
        } catch (e) {
            return thunkApi.rejectWithValue('Не удалось загрузить')
        }
    },
)

export const fetchCardAdd = createAsyncThunk(
    'cards/fetchCardAdd',
    async (params: { body: any; browserToken: string }, thunkApi) => {
        try {
            const res = await axios.post(
                `${FREE_PAY_URL}/api/cardStorage/add`,
                params.body,
                {
                    headers: {
                        BrowserToken: params.browserToken,
                    },
                },
            )

            thunkApi.dispatch(cardsSliceActions.addCard(params.body))

            return res
        } catch (e) {
            return thunkApi.rejectWithValue('Не удалось загрузить')
        }
    },
)


export const fetchUpdateCard = createAsyncThunk(
    'cards/fetchUpdateCard',
    async (params: { body: any; browserToken: string }, thunkApi) => {
        try {
            const res = await axios.post(
                `${FREE_PAY_URL}/api/cardStorage/update`,
                params.body,
                {
                    headers: {
                        BrowserToken: params.browserToken,
                    },
                },
            )

            thunkApi.dispatch(cardsSliceActions.updateCard(params.body))


            return res
        } catch (e) {
            return thunkApi.rejectWithValue('Не удалось загрузить')
        }
    },
)