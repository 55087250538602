import React, {useEffect} from 'react';
import '../index.scss'

const AdvantWallet = () => {

    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])

    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require("../../../img/photo_2022-07-22_10-55-41.jpg").default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"E-Pul Pul Kisəsi (Wallet)"}</div>
                <div
                    className="about__right-text">{"E-PUL PUL KİSƏSİ (WALLET) – E-Pul portalı daha bir yeniliyini təqdim edir. Yeni xidmətimiz haqqında məlumatı diqqətinizə çatdırırıq.\n" +
                    "E-Pul.az-da qeydiyyatdan keçərək, Pul Kisəsi yaradırsınız. MilliÖn terminallarından Elektron pul kisəsi bölümünə daxil olaraq, E-Pul.az-ı seçib və qeydiyyatda olan mobil nömrəni daxil edirsiniz."}
                </div>
                <div className="about__right-text">
                    {'E-Pul Pul Kisəsi balansını artırmaq iki üsulla mümkündür.'}
                </div>
                <div className="about__right-text">
                    {"Kart vasitəsi ilə balans artırmaq üçün sayta daxil olaraq, kart məlumatlarını qeyd etmək lazımdır.  Azərbaycan banklarına məxsus kartlar üçün komissiya nəzərdə tutulmayıb."}
                </div>
                <div className="about__right-text">
                    {"Balansı MilliÖn terminalları vasitəsi ilə də artırmaq olar. Burada komissiya nəzərdə tutulmayıb."}
                </div>
                <div className="about__right-head">{"E-Pul Pul Kisəsi ilə aşağıdakı əməliyyatları yerinə yetirmək olar:"}</div>
                <div className="about__right-text">
                    {"Komissiya tətbiq edilməyən hər bir odənişi yerinə yetirə bilərsiniz – ödəniş növü kimi kart yox, Pul Kisəsindən istifadə edə bilərsiniz. Komissiyalı ödənişlərin icrası isə yalnız identifikasiyadan keçdikdən sonra mümkündür."}
                </div>
                <div className="about__right-text">
                    {"Kartdan-karta xidməti– pul köçürməsi edən zaman ödəniş növü kimi Pul Kisəsi seçib digər karta pul köçürə bilərsiniz. Məlumat üçün bildirək ki, bu funksiyadan yalnız identifikasiyadan keçən istifadəçilər yararlana bilər."}
                </div>
                <div className="about__right-text">
                    {"Pul köçürməsi – digər E-Pul istifadəçisinin elektron pul kisəsinə pul köçürmə mümkündür. Bu köçürmə zamanı heç bir komissiya ödənilmir."}
                </div>
                <div className="about__right-text">
                    {"Nağdlaşdırma-İdentifikasiyadan keçən istifadəçilər pul kisəsindən karta vəsait köçürərək əməliyyatı icra edə  bilərlər."}
                </div>
                <div className="about__right-head">{"Xidmət üzrə 3 tarif planı mövcuddur: Minimal, Gündəlik, Peşəkar"}</div>
                <div className="about__right-head">
                    {"Minimal tarif planı:"}
                </div>
                <div className="about__right-text">
                    {"Komissiya tətbiq edilməyən ödənişləri icra  etmək mümkündür"}
                </div>
                <div className="about__right-text">
                    {"Balansın maksimal həddi – 100AZN"}
                </div>
                <div className="about__right-text">
                    {"İllik limit – 1500AZN"}
                </div>
                <div className="about__right-head">
                    {"Gündəlik tarif planı:"}
                </div>
                <div className="about__right-text">
                    {"Balansın maksimal həddi – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"İllik limit – 15 000AZN"}
                </div>
                <div className="about__right-text">
                    {"Pul kisəsindən digər pul kisəsinə köçürmə imkanı – məhdudiyyət yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Pul kisəsindən digər Azərbaycan banklarına məxsus kartlara (VİSA/MC) köçürmə imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"BANKÇILIQ kateqoriyasında ödəmə imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"ÖDƏNİŞ SİSTEMLƏRİ kateqoriyasında ödəmə imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"MAĞAZALAR ŞƏBƏKƏSİ kateqoriyasında ödəmə imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Əlavə komissiya olduqda xidmətlərin ödənilməsi imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Nağdlaşdırma – məhdudiyyət  yoxdur"}
                </div>


                <div className="about__right-head">
                    {"Peşəkar tarif planı:"}
                </div>
                <div className="about__right-text">
                    {"Balansın maksimal həddi – məhdudiyyət yoxdur"}
                </div>
                <div className="about__right-text">
                    {"İllik limit – məhdudiyyət yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Pul kisəsindən digər pul kisəsinə köçürmə imkanı – məhdudiyyət yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Pul kisəsindən Azərbaycan banklarına məxsus kartlara (VİSA/MC) köçürmə imkanı – məhdudiyyət yoxdur"}
                </div>
                <div className="about__right-text">
                    {"BANKÇILIQ kateqoriyasında ödəmə imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"ÖDƏNİŞ SİSTEMLƏRİ kateqoriyasında ödəmə imkanı – məhdudiyyət yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Əlavə komissiya olduqda xidmətlərin ödənilməsi imkanı – məhdudiyyət  yoxdur"}
                </div>
                <div className="about__right-text">
                    {"Nağdlaşdırma – məhdudiyyət  yoxdur"}
                </div>



                <div className="about__right-head">
                    {"İDENTİFİKASSİYA üçün aşağıdakı metodlar mövcuddur:"}
                </div>
                <div className="about__right-text">
                    {"Tətbiq vasitəsi ilə"}
                </div>
                <div className="about__right-text">
                    {"Ofisə gəlmək"}
                </div>
                <div className="about__right-text">
                    {"Ünvana çatdırılma"}
                </div>
                <div className="about__right-text">
                    {"ASAN imza"}
                </div>


                <div className="about__right-head">
                    {"DİQQƏT!"}
                </div>
                <div className="about__right-text">
                    {"Pul kisəsi balansını 100 AZN-dən yuxarı artırmaq üçün identifikasiyadan keçməlisiniz!"}
                </div>
                <div className="about__right-text">
                    {"Bank kartına pul köçürmək üçün identifikasiyadan keçməlisiniz!"}
                </div>
                <div className="about__right-text">
                    {"Bank kartına pul köçürmək üçün gündəlik limit 1000 AZN"}
                </div>
                <div className="about__right-text">
                    {"Azərbaycan kartları üçün əməliyyat zamanı komissiya tutulmur! Xarici kartlar üçün komissiya 1% min 1AZN"}
                </div>

                <div className="about__right-head">
                    {"ВНИМАНИЕ!"}
                </div>
                <div className="about__right-text">
                    {"Для пополнения баланса кошелька более чем на 100 AZN необходимо пройти идентификацию!"}
                </div>
                <div className="about__right-text">
                    {"Для перевода средств на банковскую карту необходимо пройти идентификацию!"}
                </div>
                <div className="about__right-text">
                    {"Дневной лимит перевода средств на банковскую карту 1000 AZN"}
                </div>
                <div className="about__right-text">
                    {"Для карт Азербайджана комиссия не взимается! Для иностранных карт, комиссия 1% мин 1AZN"}
                </div>

                <div className="about__right-head">
                    {"ATTENTION!"}
                </div>
                <div className="about__right-text">
                    {"To replenish your wallet balance for more than 100 AZN, you need to go through identification!"}
                </div>
                <div className="about__right-text">
                    {"To transfer funds to a bank card, you must go through identification!"}
                </div>
                <div className="about__right-text">
                    {"Daily limit for transferring funds to a bank card 1000 AZN"}
                </div>
                <div className="about__right-text">
                    {"No commission is charged for Azerbaijan cards! For foreign cards, commission 1% min 1AZN"}
                </div>
            </div>
        </div>
    );
};

export default AdvantWallet;