import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdditionalFields from "../../Components/AdditionalFields/AdditionalFields";
import PaymentFields from "../../Components/PaymentFields/index";
import withRouter from "../../HOC/widthRouter";
import { GuestActionCreators } from "../../redux/reducers/guest/action-creators";
import { Languages } from "../../redux/reducers/language/languageData";

import { ServiceActionCreators } from "../../redux/reducers/service/action-creators";
import { fetchWithTimeout, getQueryParams } from "../../utils/query";
import Loading, { BtnLoading } from "../../Components/Loading/Loading";
import "./index.scss";
import CenterMode from "../../Components/MyCardComponent/CenterMode";
import AddCheckboxIn from "../../Components/CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import { addFavoritesActionCreators } from "../../redux/reducers/addFavorites/action-creators";
import client, { BASE_URL } from "../../axios";
import InputFieldComponent from "../../Components/MyCardComponent/InputField/InputField";
import { addToFavoritesFetch } from "../../redux/actions/favoritesListAction";
import { t } from "i18next";

const cardSelectorCategories = new Set([9, 5, 10, 16]);
const cardSelectorServices = new Set([1069]);

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousLang: undefined,
      fields: [],
      groupId: 0,
      isPayAnotherCard: false,
      newCardData: {
        cvc: "",
        cardName: "",
        cardNumber: "",
        expiryDate: "",
      },
      isSaveNewCardToMasPas: false,
      isClientExist: false,
      allFieldsHaveData: false,
      isLoading: false,
      isModalVisible: false,
      amountValue: "",
      msgType: 0,
      subVal: "",
      autoRedirectToPayment: false,
      subName: "",
      additionalDebtFields: [],
      isAmountCorrect: true,
      isAuth: false,
      fieldsForPicker: [
        { label: "Visa", key: "newcard_visa", value: "newcard_visa" },
        { label: "MasterCard", key: "newcard_mc", value: "newcard_mc" },
      ],
      cardPanId: "newcard_visa",
      loadingNotTransparent: false,
      isChecked: false,
    };
  }

  componentDidMount = async () => {
    const { params, getServiceById } = this.props;

    await getServiceById({
      selected: [+params?.id],
      hash: +params?.id,
    });
  };

  notify = () => {
    const { language: selectedLanguage } = this.props;
    const language = Languages(selectedLanguage);
    toast.error(
      this.state.msgType === 1 ? language.noClientMsg : language.someError,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  componentDidUpdate = async (_, prevS) => {
    if (prevS.allFieldsHaveData !== this.state.allFieldsHaveData) {
      if (this.state.allFieldsHaveData) {
        await this.onButtonPress();
      }
    }
    if (this.state.autoRedirectToPayment !== prevS.autoRedirectToPayment) {
      if (this.state.autoRedirectToPayment) {
        await this.onButtonPress();
      }
    }
  };

  async checkIfUserExist(serviceId, fields, selectedGroupId) {
    this.setState({ isLoading: true });
    try {
      // const response = await fetchWithTimeout(
      //   `${BASE_URL}categories/client_info_with_group_id?fields=${encodeURIComponent(
      //     fields
      //   )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "web",
      //     },
      //   }
      // );
      const response = await client.get(
        `categories/client_info_with_group_id?fields=${encodeURIComponent(
          fields
        )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        // const json = await response.json();

        if (response.data?.resultCodes === "OK") {
          this.setState({ isClientExist: true, checkData: response.data });
        } else if (
          response.data?.resultCodes === "NOT_FOUND" ||
          response.data?.resultCodes === "INVALID_PARAMETERS" ||
          response.data?.resultCodes === "UNKNOWN_ERROR"
        ) {
          this.setState({ msgType: 1 });
          this.notify();
        } else if (
          response.data?.resultCodes === "UNDECIDED_PROTOCOL" ||
          response.data?.resultCodes === "PROTOCOL_CANT_DECIDED"
        ) {
          this.setState({ showVerdictFields: true });
        }
      } else {
        this.setState({ msgType: 0 });
        this.notify();
      }
    } catch (e) {
      this.setState({ msgType: 0 });
      this.notify();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async onButtonPress(e) {
    if (this.state.isPayAnotherCard) {
      await this.payWithAnotherCard(e);
    } else {
      await this.payWithExistingCard(e);
    }
  }

  async payWithAnotherCard(e) {
    e?.preventDefault();
    const { service, language } = this.props;
    const { fields, groupId, subName, subVal } = this.state;
    const fieldsJSON = JSON.stringify({ fields });
    const { newCardData } = this.state;
    const { forms } = this.props;

    const cardData = {
      cardNumber: newCardData.cardNumber,
      year: newCardData.expiryDate.substring(4, 7).trim(),
      month: newCardData.expiryDate.substring(0, 3).trim(),
      cvv: newCardData.cvc,
    };

    try {
      if (newCardData.cardName) {
        cardData.cardName = newCardData.cardName;
      }

      await forms.payWithNewCard(
        cardData,
        {
          serviceId: service.id,
          amount: this.state.paramAmountValue
            ? Number(this.state.paramAmountValue)
            : Number(this.state.amountValue),
          fields: fieldsJSON,
          payAllDebt: false,
          subName: subName,
          subVal: subVal,
          desc: "", //TODO: add comments for favorites // comment,
          lng: language,
          selectedGroupId: groupId,
        },
        () => {
          console.log("success");
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async payWithExistingCard(e) {
    const { navigate, walletList } = this.props;
    e?.preventDefault();
    const { service, masterPass } = this.props;
    const { fields, groupId, subName, subVal, cardPanId, instrument } =
      this.state;
    const fieldsJSON = JSON.stringify({ fields });

    if (this.props.loading.isPaymentLoading) {
      return;
    }
    if (this.state.isClientExist) {
      if (this.state.isAmountCorrect || Number(this.state.paramAmountValue)) {
        let { language = "az" } = getQueryParams();

        this.setState({ isLoading: true });

        try {
          if (instrument) {
            if (instrument.card) {
              await masterPass.forms.payWithSavedCard(instrument.card.name, {
                serviceId: service.id,
                amount: this.state.paramAmountValue
                  ? Number(this.state.paramAmountValue)
                  : Number(this.state.amountValue),
                fields: fieldsJSON,
                payAllDebt: false,
                subName: subName,
                subVal: subVal,
                desc: "", //TODO: add comments for favorites // comment,
                lng: language,
                selectedGroupId: groupId,
              });
            } else if (instrument.wallet) {
              const wallet = walletList?.walletList?.wallet?.find(
                (wallet) => wallet.currency === "AZN"
              );
              const amountValue = this.state.paramAmountValue
                ? Number(this.state.paramAmountValue)
                : Number(this.state.amountValue);
              if (Number((wallet?.balance || 0) >= Number(amountValue) * 100)) {
                await masterPass.forms.doPayWithWallet(
                  service.id,
                  "AZN",
                  amountValue,
                  fieldsJSON,
                  false,
                  subName,
                  subVal,
                  "", //TODO: add comments for favorites // comment,
                  groupId,
                  (orderId, historyId) => {
                    navigate(
                      `/epay/${language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`
                    );
                  }
                );
              }
            } else if (instrument.bonus) {
              await masterPass.forms.doPayByBonuses(
                service.id,
                this.state.paramAmountValue
                  ? Number(this.state.paramAmountValue)
                  : Number(this.state.amountValue),
                fieldsJSON,
                false,
                subName,
                subVal,
                "", //TODO: add comments for favorites // comment,
                groupId,
                (orderId, historyId) => {
                  navigate(
                    `/epay/${language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`
                  );
                },
                () => {}
              );
            }
            return;
          }
          const url = await this.props.doGuestPay(
            service.id,
            this.state.paramAmountValue
              ? Number(this.state.paramAmountValue)
              : Number(this.state.amountValue),
            fieldsJSON,
            false,
            subName,
            subVal,
            groupId || 0,
            // `${window.location.origin}/frames/${language}/check`,
            `${window.location.origin}/epay/${language}/result/payment/check`,
            cardPanId
          );
          if (url) {
            window.location = url;
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.setState({ isLoading: false });
        }
      }
    } else {
      this.checkIfUserExist(service.id, fieldsJSON, groupId || 0);
    }
  }

  renderPicker() {
    const { language: selectedLanguage } = this.props;
    const language = Languages(selectedLanguage);

    return (
      <div className="service_fields-item">
        <p className="service_fields-item-title">{language.selectCard}</p>
        <select
          aria-label="service-fields"
          onChange={(e) => {
            this.setState({ cardPanId: e.target.value });
          }}
          value={this.state.cardPanId}
        >
          {this.state.fieldsForPicker.map((item, i) => {
            return (
              <option key={item.key} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  chooseAnotherCard() {
    this.setState({ isPayAnotherCard: !this.state.isPayAnotherCard });
    this.setState({ isSaveNewCardToMasPas: false });
  }

  render() {
    const { language: selectedLanguage, langKey } = this.props;
    const language = Languages(selectedLanguage);

    const { service, serviceError } = this.props;

    const { addFavGroupId } = this.props;

    const { loadingNotTransparent } = this.state;

    if (serviceError) {
      return <div className="service_error">{t("errorLoadingService")}</div>;
    }

    const loader = (
      <div
        className={`loader-wrapper ${
          loadingNotTransparent ? "loader-wrapper--not-transparent" : ""
        } `}
      >
        <Loading />
      </div>
    );

    if (Object.keys(service).length === 0) {
      return loader;
    }

    const {
      isClientExist,
      checkData,
      isAmountCorrect,
      showVerdictFields,
      fieldsForPicker,
    } = this.state;

    // const serviceDescription =
    //   `<div class="service_description-field-title">${language.information}!</div>` +
    //   service?.localDesc?.[langKey];

    const isRightColumn =
      service?.localDesc?.[langKey] !== "" ||
      this.state.additionalDebtFields?.length !== 0;

    const containerClassName = isRightColumn
      ? "container"
      : "container container--not-right-column";

    const isAuthToken = localStorage.getItem("token");

    return (
      <>
        <div className={containerClassName}>
          <div className="service-wrapper">
            <div className="service">
              <form
                className="service_form"
                onSubmit={(e) => {
                  this.onButtonPress(e);
                }}
              >
                <h1 className="service_title">{service.localName[langKey]}</h1>
                <div className="service_content">
                  <PaymentFields
                    showVerdictFields={showVerdictFields}
                    service={service}
                    onValueChange={(s) => {
                      this.setState(s);
                    }}
                    isClientExist={isClientExist}
                    azercell={service?.id === 976}
                    // defaultFields={this.state.fields}
                    fieldsForPicker={fieldsForPicker}
                  />
                  {isClientExist && (
                    <>
                      <AdditionalFields
                        onChange={(s) => {
                          this.setState(s);
                        }}
                        isAmountCorrect={isAmountCorrect}
                        service={service}
                        checkData={checkData}
                        defaultValue={
                          this.state.amountValue || this.state.paramAmountValue
                        }
                      />
                      {(cardSelectorCategories.has(service?.categoryId) ||
                        cardSelectorServices.has(service?.id)) &&
                        this.renderPicker()}
                    </>
                  )}
                </div>
                {isAuthToken && isClientExist ? (
                  <>
                    <>
                      <div className="service__pay-another-card">
                        <p className="service__card-box-title">
                          {t("paymentMethod")}
                        </p>
                        <div className="service__another-card-icon">
                          {this.state.isPayAnotherCard ? "–" : "+"}
                        </div>
                        <p
                          className="service__another-card-text"
                          onClick={() => this.chooseAnotherCard()}
                        >
                          {this.state.isPayAnotherCard
                            ? `${t("cancel")}`
                            : `${t("payWithAnotherCard")}`}
                        </p>
                      </div>
                      {this.state.isPayAnotherCard ? (
                        <div className="service__another-card-box">
                          <InputFieldComponent
                            infoImage
                            cardDataCb={(data) =>
                              this.setState({ newCardData: data })
                            }
                            isCardSaved={this.state.isSaveNewCardToMasPas}
                            // hasDefaultNameField={true}
                          />
                          <AddCheckboxIn
                            height={30}
                            width={30}
                            onClick={() =>
                              this.setState({
                                isSaveNewCardToMasPas:
                                  !this.state.isSaveNewCardToMasPas,
                              })
                            }
                            isChecked={this.state.isSaveNewCardToMasPas}
                            fontSize={14}
                            title={`${t("wantToAdd")}`}
                          />
                        </div>
                      ) : (
                        <CenterMode
                          canWallet={true}
                          canBonus={true}
                          onSelect={(data) =>
                            this.setState({ instrument: data })
                          }
                        />
                      )}
                    </>
                    <>
                      <AddCheckboxIn
                        onClick={() =>
                          this.setState({ isChecked: !this.state.isChecked })
                        }
                        isChecked={this.state.isChecked}
                        title={t("addPaymentFavorites")}
                        padding="10px 0 20px 0"
                      />
                    </>
                  </>
                ) : undefined}

                <button
                  className="service-wrapper__btn"
                  disabled={
                    (!isAmountCorrect && isClientExist) ||
                    this.state.isLoading ||
                    this.props.loading.isLoading ||
                    this.props.loading.isPaymentLoading
                  }
                  onClick={() => {
                    if (this.state.isChecked) {
                      const fieldsJson = JSON.stringify({
                        fields: this.state.fields,
                      });
                      addFavGroupId({
                        comment: "",
                        serviceId: this.props.service.id,
                        selectedGroupId: this.state.groupId,
                        fields: fieldsJson,
                      });
                    }
                  }}
                >
                  {(this.state.isLoading ||
                    this.props.loading.isLoading ||
                    this.props.loading.isPaymentLoading) && (
                    <div style={{ position: "relative" }}>
                      <div className="spin"></div>
                    </div>
                  )}
                  {isClientExist ? language.pay : language.check}
                </button>

                {/*{*/}
                {/*  (autoPaymentsState.isCreate || autoPaymentsState.isEdit) && isClientExist*/}
                {/*        ?*/}
                {/*      <>*/}
                {/*        <Link to={autoPaymentsState.path} state={*/}
                {/*          {service,*/}
                {/*            checkData,*/}
                {/*            cardPanId: this.state.cardPanId,*/}
                {/*            subName: this.state.subName,*/}
                {/*            subVal: this.state.subVal,*/}
                {/*            groupId: this.state.groupId || 0,*/}
                {/*            amount:  Number(this.state.amountValue),*/}
                {/*            fields: this.state.fields,*/}
                {/*            cardInfo: this.state.instrument?.card,*/}
                {/*          }*/}
                {/*        }>*/}
                {/*          <button*/}
                {/*              className="service-wrapper__btn"*/}
                {/*              disabled={!isAmountCorrect && isClientExist}*/}
                {/*          >*/}
                {/*            {autoPaymentsState.btnValue}*/}
                {/*          </button>*/}
                {/*        </Link>*/}
                {/*        {*/}
                {/*          autoPaymentsState.isEdit &&*/}
                {/*            <Link to={`/edit-payment/${autoPaymentsState.id}`} state={{isEdit: true}}>*/}
                {/*              <button*/}
                {/*                  className="service-wrapper__btn service-wrapper__btn--cancel"*/}
                {/*              >*/}
                {/*                Отмена*/}
                {/*              </button>*/}
                {/*            </Link>*/}

                {/*        }*/}
                {/*      </>*/}

                {/*        : <button*/}
                {/*            className="service-wrapper__btn"*/}
                {/*            disabled={!isAmountCorrect && isClientExist}*/}
                {/*            onClick={() => {*/}
                {/*              if(this.state.isChecked) {*/}
                {/*                const fieldsJson = JSON.stringify({fields: this.state.fields})*/}
                {/*                addFavGroupId({*/}
                {/*                  comment: "",*/}
                {/*                  serviceId: this.props.service.id,*/}
                {/*                  selectedGroupId: this.state.groupId,*/}
                {/*                  fields: fieldsJson*/}
                {/*                })*/}
                {/*              }*/}
                {/*            }}*/}
                {/*        >*/}
                {/*          {isClientExist ? language.pay : language.check}*/}
                {/*        </button>*/}
                {/*}*/}
                {/*<button*/}
                {/*    className="service-wrapper__btn"*/}
                {/*    disabled={!isAmountCorrect && isClientExist}*/}
                {/*    onClick={() => {*/}
                {/*      if(this.state.isChecked) {*/}
                {/*        addFavGroupId({*/}
                {/*          comment: "",*/}
                {/*          serviceId: this.props.service.id,*/}
                {/*          selectedGroupId: this.state.groupId,*/}
                {/*          fields: this.state.fields*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*>*/}
                {/*  {isClientExist ? language.pay : language.check}*/}
                {/*</button>*/}
              </form>
              {/*{(this.state.isLoading ||*/}
              {/*  this.props.loading.isLoading ||*/}
              {/*  this.props.loading.isPaymentLoading) &&*/}
              {/*  loader}*/}
            </div>
            {/*{(service.localDesc[langKey] ||*/}
            {/*  this.state.additionalDebtFields?.length !== 0) && (*/}
            {/*  <aside className="right-column">*/}
            {/*    {service.localDesc[langKey] && (*/}
            {/*      <div*/}
            {/*        className="service_fields-item service_description-field"*/}
            {/*        dangerouslySetInnerHTML={{*/}
            {/*          __html: serviceDescription,*/}
            {/*        }}*/}
            {/*      ></div>*/}
            {/*    )}*/}
            {/*    {this.state.additionalDebtFields?.length !== 0 && (*/}
            {/*      <div className="service_fields-item service_debt-additional-field-items">*/}
            {/*        {this.state.additionalDebtFields?.map((item, index) => {*/}
            {/*          return (*/}
            {/*            <div*/}
            {/*              className="service_debt-additional-field-item"*/}
            {/*              key={index}*/}
            {/*            >*/}
            {/*              <div className="service_debt-additional-field-item-title">*/}
            {/*                {item.mutliLangValueString[langKey].replace(*/}
            {/*                  /<[^>]+>/g,*/}
            {/*                  " "*/}
            {/*                )}*/}
            {/*              </div>*/}
            {/*              <div className="service_debt-additional-field-item-body">*/}
            {/*                {item.value}*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </aside>*/}
            {/*)}*/}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    service: { service, error: serviceError },
    cardsReducer,
    walletList,
    loading,
    masterPassReducer,
    changeLangSlice,
  } = state;
  const { language, langKey } = state.changeLangSlice;

  return {
    service,
    loading,
    serviceError,
    language,
    langKey,
    masterPass: masterPassReducer,
    forms: masterPassReducer.forms,
    cards: cardsReducer.cards,
    walletList,
    changeLangSlice,
  };
};

const mapDispatchToProps = {
  getServiceById: ServiceActionCreators.getServiceById,
  doGuestPay: GuestActionCreators.doGuestPay,
  addFavorites: addFavoritesActionCreators.addFavorites,
  addFavGroupId: addToFavoritesFetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Service));
