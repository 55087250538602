import React from "react";
import "./Button.scss";

interface ButtonProps {
  buttonText?: any;
  background?: string;
  color?: string;
  border?: string;
  borderRadius?: number;
  minWidth?: number;
  padding?: string;
  onClick?: (e?: any) => void;
  margin?: string;
  icon?: any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  width?: string;
  height?: number;
}

const Button = ({
  type,
  icon,
  width,
  color,
  border,
  margin,
  padding,
  onClick,
  minWidth,
  disabled,
  background,
  buttonText,
  borderRadius,
  height
}: ButtonProps) => {

    return (
    <>
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className="custom-button"
        style={{
          color,
          background,
          border,
          minWidth,
          padding,
          borderRadius,
          margin,
          width,
          height
        }}
      >
        {icon && <img
            className="custom-button__img"
            src={icon}
            alt="custom-button__img"
        />}
        {buttonText}
      </button>
    </>
  );
};
export default Button;
