import './index.scss';
import { useState } from 'react';
import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Button from "../Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useForm } from "react-hook-form";
import { fetchAuth } from "../../redux/actions/authActions";
import ForgotPasswordModal from "../ForgotPasswordModal";
import ForgotPasswordMobileCode from "../ForgotPasswordMobileCode";
import { Navigate, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";
import CPhoneInput from "../CustomsComponents/PhoneInput";
import { notify } from "../../helper";

const AuthorizationForm = () => {
    const { t } = useTranslation()
    const { isAuth, isLoading } = useAppSelector(state => state.authReducer)
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false)
    const [showForgotPasswordMobileCode, setShowForgotPasswordMobileCode] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const navigate = useNavigate()
    const { language } = useAppSelector((state) => state.changeLangSlice);

    const dispatch = useAppDispatch()
    const { register, handleSubmit, control, setError, setValue, watch, formState: { errors, isValid } } = useForm({
        defaultValues: {
            phone: '',
            password: ''
        },
        mode: 'onChange'
    })

    const sendLoginEvent = () => {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
            event: 'login',
            method: 'phone',
        });
    };

    const onSubmit = async (values: any) => {
        await dispatch(fetchAuth({ ...values, navigate })).then((response: any) => {
            if (response.payload.status === 200) {
            } else {
                notify(`${t("incorrectDataEntered")}`, false)
            }
        })

        await sendLoginEvent()
    }

    const handleOpenPasswordModal = () => {
        setShowForgotPasswordModal(true)
    }
    const handleClosePasswordModal = () => {
        setShowForgotPasswordModal(false)

    }
    const handleClosePasswordMobileCode = () => {
        setShowForgotPasswordMobileCode(false)
    }

    if (isAuth) {
        const link = `/epay/${language}/home`
        return <Navigate to={link} />
    }

    return (
        <>
            {isLoading ? <Loading /> :
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CPhoneInput control={control} />
                        <TextField color="warning"
                            variant="standard"
                            margin="normal"
                            type={showPassword ? "text" : "password"}
                            label={`${t("password")}`}
                            helperText={errors.password?.message}
                            error={Boolean(errors.password?.message)}
                            fullWidth {...register('password', { required: `${t("enterYourPassword")}` })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: 'red',
                                    fontSize: '12px',
                                },
                                '& .MuiFormHelperText-root': {
                                    color: 'red',
                                    fontSize: '12px',
                                },
                            }}
                        />
                        <div className="no-registration-main__register-forgot-btn">
                            <Typography onClick={handleOpenPasswordModal} className='forgot-auth-password'>{t("forgotPassword")}</Typography>
                        </div>

                        <div className="no-registration-main__register-button">
                            <Button
                                type='submit'
                                buttonText={`${t("signIn")}`}
                                background="#EF5C27"
                                padding="14px 65px"
                                borderRadius={15}
                                color="#FFFFFF"
                                margin="0 auto"
                            />
                        </div>
                    </form>
                    <ForgotPasswordModal showForgotPasswordModal={showForgotPasswordModal}
                        handleClosePasswordModal={handleClosePasswordModal} />
                    <ForgotPasswordMobileCode showForgotPasswordMobileCode={showForgotPasswordMobileCode}
                        handleClosePasswordMobileCode={handleClosePasswordMobileCode} />
                </>
            }
        </>

    )
};

export default AuthorizationForm;
