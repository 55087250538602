import React from "react"
import ContentLoader from "react-content-loader"

const BonusesFavSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={500}
        viewBox="0 0 420 500"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="504" y="36" rx="0" ry="0" width="7" height="496" />
        <rect x="20" y="22" rx="0" ry="0" width="4" height="380" />
        <rect x="20" y="18" rx="0" ry="0" width="360" height="4" />
        <rect x="376" y="22" rx="0" ry="0" width="4" height="380" />
        <rect x="21" y="399" rx="0" ry="0" width="360" height="4" />
        <rect x="40" y="40" rx="0" ry="0" width="323" height="263" />
        <rect x="77" y="332" rx="0" ry="0" width="242" height="32" />
    </ContentLoader>
)

export default BonusesFavSkeleton