import { createSlice } from '@reduxjs/toolkit'

interface InitialState {
  cards: any
  isLoading: boolean
  error: string
}

const initialState: InitialState = {
  cards: null,
  isLoading: false,
  error: '',
}

export const habStorageSlice = createSlice({
  name: 'habStorageSlice',
  initialState,
  reducers: {},
  extraReducers: {},
})

export default habStorageSlice.reducer
