import './index.scss';
import { FC, useState } from 'react';
import HeaderText from '../../CustomsComponents/HeaderText';
import SideMenu from '../SideMenu/SideMenu';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { WalletInfo } from './WalletInfo';
import LayoutEmpty from '../../layouts/LayoutEmpty/LayoutEmpty';
import useLoadData from './useLoadData';
import useTransfer from './useTransfer';
import CreateInvoiceModalProps from './CreateInvoiceModal';
import AmountInput from './AmountInput';
import WalletBanner from './WalletBanner';
import ActionButtons from './ActionButtons';
import CardsSection from './CardsBox';

const TopUpBalance: FC = () => {
  const { c2w } = useAppSelector((state: { wallet: any }) => state.wallet);
  const [instrument, setInstrument] = useState<any>(null);
  const [amountValue, setAmountValue] = useState<string>('');
  const [isValidAmount, setIsValidAmount] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { isDataLoaded, isHubSolutionPaymentMethod } = useLoadData(
    c2w,
    language,
  );
  const transfer = useTransfer(
    amountValue,
    instrument,
    setBtnLoading,
    language,
  );
  const navigate = useNavigate();
  const [modalActive, setModalActive] = useState(false);
  const openQR = () => {
    setModalActive(true);
  };

  const goBack = () => navigate(`/epay/${language}/e-wallet`);

  const handleAmountChange = (value: any) => {
    setAmountValue(value);
    const minAmount = (c2w?.minAmount ?? 0) / 100;
    const maxAmount = (c2w?.maxAmount ?? 0) / 100;
    const isValid = value && value >= minAmount && value <= maxAmount;
    //@ts-ignore
    setIsValidAmount(isValid);
  };

  return (
    <LayoutEmpty goBack={goBack} navTitle={t('back')}>
      <CreateInvoiceModalProps
        active={modalActive}
        setActive={setModalActive}
        amountValue={amountValue}
      />
      <div className="top-up-balance">
        <div className="top-up-balance__wrap">
          <div className="top-up-balance__left-wrap">
            <HeaderText title={t('balanceReplenishment')} />
            <WalletBanner language={language} />
            <WalletInfo />
            <AmountInput
              amountValue={amountValue}
              handleAmountChange={handleAmountChange}
            />    
            <p className={isValidAmount ? "valid-input" : "error-input"}>{t("min") + ': ' + (c2w?.minAmount ?? 0)/100 + " AZN   " + t("max") + ': ' + (c2w?.maxAmount ?? 0)/100 + " AZN"}</p>
            <p className="top-up-balance__left-wrap-summa-textP">
              {t('commission')}
            </p>
            <CardsSection
              isDataLoaded={isDataLoaded}
              setInstrument={setInstrument}
              isHubSolutionPaymentMethod={isHubSolutionPaymentMethod}
            />
            <ActionButtons
              amountValue={amountValue}
              transfer={transfer}
              openQR={openQR}
              btnLoading={btnLoading}
              disable={!isValidAmount || !amountValue}
            />
          </div>
          <div className="top-up-balance__right-wrap">
            <SideMenu />
          </div>
        </div>
      </div>
    </LayoutEmpty>
  );
};

export default TopUpBalance;
