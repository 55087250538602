import { PROCESS_STAGE } from "../actions/authActions";
import { AuthActionTypes } from '../actions/authActions';
// import { IAuthState } from "../states/authState";

// export interface IAuthState {
//     user: IUser | undefined;
//     processStage: number | 0;
//     device: IDevice | undefined;
//     sessionId: string;
//     asanLogin?: IAsanLogin | undefined;
//     sid?: string;
// };

const initialState: any = {
    user: undefined,
    processStage: 0,
    device: undefined,
    sessionId: '',
    asanLogin: undefined
};
export default function authAsanReducer(
    state = initialState,
    action: AuthActionTypes
): any {
    switch (action.type) {
        // case SAVE_USER:
        //     return { ...state, user: action.user };
        case PROCESS_STAGE:
            console.log('PROCESS_STAGE', action);
            return { ...state, processStage: action.processStage, sid: action.processStage === 1 || action.processStage === 2 ? action.sid : state.sid};
        // case SAVE_DEVICE_INFO:
        //     return { ...state, device: action.device };
        // case SAVE_SESSION_ID:
        //     return { ...state, sessionId: action.sessionId };
        // case ASAN_LOGIN:
        //     return { ...state, asanLogin: action.asanLogin };
        // case SIGN_OUT:
        //     return {
        //         user: undefined,
        //         processStage: 0,
        //         device: undefined,
        //         sessionId: ''
        //     };
        default:
            return state;
    };
};