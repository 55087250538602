import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAutoPayments } from "../../models/AutoPayments";
import { fetchWithTimeout, toQueryParams } from "../../utils/query";
import client, { BASE_URL } from "../../axios";

const token = localStorage.getItem("token");

export const fetchAutoPayments = createAsyncThunk<any>('autoPaymentList/fetchAutoPayments',
    async (thunkAPI: any) => {
        try {
            const res = await client.get<IAutoPayments | any>(`recurring_payment/list/${localStorage.getItem("token")}`);

            return res.data;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось загрузить")
        }
    }
)

export const getSingleAutoPayment = createAsyncThunk('autoPaymentList/getSingleAutoPayment',
    async (id: string, thunkAPI: any) => {
        try {
            const res = await client.get<IAutoPayments | any>(`recurring_payment/get/${id}?sessionId=${localStorage.getItem('token')}`);

            return res.data;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось получить")
        }
    }
)

export const saveAutoPayments = createAsyncThunk('autoPaymentList/saveAutoPayments',
    async (data: any, thunkAPI: any) => {
        try {
            const url = toQueryParams(data);
            const res = await client.post<IAutoPayments | any>(`recurring_payment/cache_create_with_group_id?${url}`);

            return res.data;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось сохранить")
        }
    }
)

export const cacheAutoPayments = createAsyncThunk('autoPaymentList/saveAutoPayments',
    async (data: any, thunkAPI: any) => {
        try {
            const url = toQueryParams(data);
            const res = await client.post<IAutoPayments | any>(`recurring_payment/cache_create_with_group_id?${url}`);

            return res.data;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось сохранить")
        }
    }
)

export const commitRecurring = createAsyncThunk('autoPaymentList/commitRecurring',
    async (data: any, thunkAPI: any) => {
        try {
            //TODO:FIX KAMRAN & NURLAN
            const res = await client.post<IAutoPayments | any>(
                `recurring_payment/commit/${localStorage.getItem(
                    'token',
                )}?mirrorId=${data}&return=${window.location.host}&responseCode=0000&redirect=false`,
            )

            console.log(res)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось сохранить')
        }
    }
)

export const editAutoPayments = createAsyncThunk('autoPaymentList/editAutoPayments',
    async (data: any, thunkAPI: any) => {
        try {

            const url = toQueryParams(data?.data);
            const res = await client.post<IAutoPayments | any>(`recurring_payment/cache_edit_with_group_id/${data.id}?${url}`);


            console.log('res', res.data)
            return res.data;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось сохранить")
        }
    }
)

export const changeAutoPaymentStatus = createAsyncThunk('autoPaymentList/changeAutoPaymentStatus',
    async (data: { id: number | undefined, status: number }, thunkAPI: any) => {
        try {
            const res = await client.post<IAutoPayments | any>(`recurring_payment/change/activated_status/${data.id}/${data.status}?sessionId=${localStorage.getItem("token")}`);


            return res;
            // thunkAPI.dispatch(fetchAutoPayments());
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось сохранить")
        }
    }
)

export const deleteAutoPayment = createAsyncThunk('autoPaymentList/deleteAutoPayment',
    async (data: any, thunkAPI: any) => {
        try {
            return await client.delete<IAutoPayments | any>(`recurring_payment/delete/${data.id}?sessionId=${data.token}`);
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось удалить")
        }
    }
)
