import React from "react"
import ContentLoader from "react-content-loader"

const Media340AutoPaymentSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={50}
        viewBox="0 0 850 70"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <circle cx="28" cy="42" r="28" />
        <rect x="110" y="27" rx="0" ry="0" width="210" height="35" />
        <rect x="660" y="26" rx="0" ry="0" width="100" height="50" />
        <rect x="780" y="26" rx="0" ry="0" width="50" height="50" />
    </ContentLoader>
)

export default Media340AutoPaymentSkeleton

