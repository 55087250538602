import React, { useEffect, useState } from "react";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import CustomCalendar from "../../Calendar/Calendar";
import Button from "../../Button/Button";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  fetchEditProfileDetails,
  fetchProfileDetails,
} from "../../../redux/actions/authActions";
import moment from "moment";
import { useTranslation } from "react-i18next";

const cake = require("../../../img/cake.svg").default;
const calendar = require("../../../img/calendar-icon.svg").default;
const genderIcon = require("../../../img/gender.svg").default;

const ProfileDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const { profileDetails } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, [dispatch]);

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      birthDate: profileDetails?.birthDate,
      gender: profileDetails?.gender,
    },
    mode: "onChange",
  });
  const gender = watch("gender");
  const birthDate = watch("birthDate");

  useEffect(() => {
    if (
      (gender !== null && gender !== undefined && gender !== profileDetails?.gender) ||
      (birthDate !== null && birthDate !== undefined && birthDate !== profileDetails?.birthDate)
    ) {
      dispatch(fetchEditProfileDetails({ gender, birthDate }));
    }
  }, [gender, birthDate]);

  useEffect(() => {
    setValue("gender", profileDetails?.gender);
    setValue("birthDate", profileDetails?.birthDate);
  }, [profileDetails?.birthDate, profileDetails?.gender, setValue]);

  const birthdayFormated = moment(
    new Date(Number(profileDetails?.birthDate))
  ).format("YYYY-MM-DD");

  const emptyDate =
    birthdayFormated === "0000-12-31" ||
    birthdayFormated === "0001-01-01" ||
    birthdayFormated === "Invalid date";

  const finalDate = emptyDate
    ? new Date()
    : moment(new Date(Number(profileDetails?.birthDate))).toDate();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault(); }}
      className={"profile-details__form"}
    >
      <div
        className="editable-form__content"
        // style={{ paddingBottom: "14px", marginBottom: "20px" }}
      >
        <div className="editable-form__inner">
          <span className="editable-form__label">{t("birthday")}</span>
        </div>
        <div onClick={() => setShowCalendar(true)} className={"calendar__wrap"}>
          <img className={"editable-form__icon"} src={cake} alt="" />

          <input
            style={{ padding: 0, width: "100%" }}
            disabled
            className="editable-form__input"
            type="text"
            name="email"
            value={`${new Date(finalDate)?.toLocaleDateString("az-Cyrl-AZ")}`}
          />
        </div>

        <Button
          buttonText={""}
          background={"none"}
          color={"#EF5C27"}
          icon={calendar}
          onClick={(e) => {
            e.preventDefault();
            setShowCalendar(true);
          }}
        />

        <CustomModal active={showCalendar} setActive={setShowCalendar}>
          <CustomCalendar
            title={"birthday"}
            // defaultBirthday={new Date(profileDetails?.birthDate!)}
            setModalActive={setShowCalendar}
            value={finalDate}
            onChange={(e) => {
              setValue("birthDate", e.getTime());
            }}
          />
        </CustomModal>
      </div>

      <div className="profile-details__gender__wrap">
        <div className={"profile-details__gender"}>
          <label className="editable-form__label" htmlFor="">
            {t("gender")}
          </label>
          <img className={"editable-form__icon"} src={genderIcon} alt="" />
        </div>
        <div style={{ width: "20%" }}>
          <select
            aria-label="gender"
            name="gender"
            value={gender}
            onChange={(e) => {
              setValue("gender", +e.target.value);
              // onSubmit({ gender: +e.target.value  });
            }}
            // style={{ textAlign: "left" }}
            className="editable-form__dropdown"
          >
            <option value={-1}>{t("notSelected")}</option>
            <option value={0}>{t("unknown")}</option>
            <option value={1}>{t("male")}</option>
            <option value={2}>{t("female")}</option>
          </select>
        </div>
        <div></div>
      </div>

      {/*  </div>*/}
      {/*</div>*/}
    </form>
  );
};

export default ProfileDetails;
