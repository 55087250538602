import loading from './loading';
import service from './service';
import bonusServices from './bonusServices';
import msgToUser from './msgToUser';
import guestPayment from './guestPayment';
import language from './language';
import authReducer from '../../redux/slices/auth';
import product from '../../redux/slices/productCatalogueSlice';
import call from '../../redux/slices/callWithSlice';
import categoriesReducer from '../../redux/slices/categoriesSlice';
import messages from '../../redux/slices/messageSlice';
import favList from '../../redux/slices/favoritesSlice';
import messagesRead from '../../redux/slices/messagesReadSlice';
import wallet from '../../redux/slices/walletSlice';
import walletList from '../../redux/slices/walletListSlice';
import bonList from '../../redux/slices/bonusesSlice';
import bannerList from '../../redux/slices/bannerSlice';
import debtsList from '../slices/debtsSlice';
import autoPaymentList from '../../redux/slices/autoPaymentsSlice';
import bonusStore from '../../redux/slices/bonusStoreSlice';
import popularProduct from '../../redux/slices/popularProductSlice';
import bonusCategory from '../../redux/slices/bonusCategorySlice';
import settingProfile from '../../redux/slices/profileSlice';
import masterPassReducer from '../../redux/slices/masterpassSlice';
import card2cardReducer from '../../redux/slices/card2cardSlice';
import cardsReducer from '../../redux/slices/cardsSlice';
import generateInvitation from '../../redux/slices/generateInvitationLink';
import getUserPersonalLink from '../../redux/slices/getUserPersonalLinkSlice';
import historyList from '../slices/historyListSlice';
import authAsanReducer from './authAsanReducer';
import changeLangSlice from './changeLangSlice';
import browserToken from '../slices/browserTokenSlice';
import tokenize from '../slices/tokenizeSlice';
import habStorageSlice from '../../redux/slices/habStorageSlice';
import basketSlice  from '../slices/backetSlice'

const reducers = {
  loading,
  service,
  msgToUser,
  guestPayment,
  language,
  authReducer,
  product,
  call,
  categoriesReducer,
  messages,
  autoPaymentList,
  favList,
  messagesRead,
  wallet,
  walletList,
  bonList,
  bannerList,
  debtsList,
  bonusStore,
  bonusCategory,
  popularProduct,
  settingProfile,
  masterPassReducer,
  card2cardReducer,
  cardsReducer,
  generateInvitation,
  historyList,
  authAsanReducer,
  changeLangSlice,
  getUserPersonalLink,
  bonusServices,
  browserToken,
  tokenize,
  habStorageSlice,
  basketSlice
};

export default reducers;
