export function changeHref(afterLang: string) {
    const alternateLinkAZ = document.getElementById('alternateLinkAZ')! as HTMLAnchorElement | null;
    const alternateLinkEN = document.getElementById('alternateLinkEN')! as HTMLAnchorElement | null;
    const alternateLinkRU = document.getElementById('alternateLinkRU')! as HTMLAnchorElement | null;
    const alternateLinkX = document.getElementById('alternateLinkX')! as HTMLAnchorElement | null;
    
    if (alternateLinkAZ) {
        alternateLinkAZ.href = `${window.location.origin}/epay/az/${afterLang}`;
    }
    if (alternateLinkEN) {
        alternateLinkEN.href = `${window.location.origin}/epay/en/${afterLang}`;
    }
    if (alternateLinkRU) {
        alternateLinkRU.href = `${window.location.origin}/epay/ru/${afterLang}`;
    }
    if (alternateLinkX) {
        alternateLinkX.href = `${window.location.origin}/epay/en/${afterLang}`;
    }
  }
