import * as React from "react";
import Button from "../../Button/Button";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import SideMenu from "../SideMenu/SideMenu";
import "./AsanImza.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import { useAppSelector } from "../../../hooks/redux";

const AsanImza = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const goBack = () => navigate(`/epay/${language}/identification`)
  return (
      <>
        <LayoutEmpty goBack={goBack} navTitle={t("goThrough")}>
          <div className="asan-imza-body">
            <h2 className="asan-imza__title">ASAN İMZA</h2>

            <div className="asan-imza__content">
              <div>
                <span className="office-body__content-text">
              {t("logIdentified")}
            </span>
              </div>
            </div>

            <div className="replenishment-of-mobile__telephone-number">
              <div className="replenishment-of-mobile__telephone-wrap">
                <label className="asan-imza__label" htmlFor="">
                  {t("phoneNumber")}
                </label>
                <input
                    type="text"
                    className="no-outline"
                    placeholder="050 111 22 33"
                />
              </div>
            </div>
            <div className="replenishment-of-mobile__sum-main">
              <div className="replenishment-of-mobile__input-div">
                <label className="asan-imza__label" htmlFor="">
                  {t("userID")}
                </label>
                <input placeholder="55555" />
              </div>
            </div>

            <div className="asan-imza__button">
              <Button
                  buttonText={`${t("continueTo")}`}
                  color="white"
                  background="#EF5C27"
                  border="1px solid #fff"
                  padding="14px 160px 14px 160px"
                  borderRadius={15}
                  margin="0 10px 0 0"
              />
            </div>
          </div>
          <div className="asan-imza-left">
            <SideMenu/>
          </div>
        </LayoutEmpty>
      </>

  );
};

export default AsanImza;
