import React from "react"
import ContentLoader from "react-content-loader"

const HistoryBonusesSkeleton = () => (
    <ContentLoader
        speed={2}
        width={345}
        height={100}
        viewBox="0 0 345 100"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="5" y="2" rx="0" ry="0" width="75" height="10" />
        <circle cx="30" cy="48" r="25" />
        <rect x="85" y="22" rx="0" ry="0" width="120" height="15" />
        <rect x="85" y="47" rx="0" ry="0" width="100" height="15" />
        <rect x="239" y="22" rx="0" ry="0" width="90" height="15" />
        <rect x="256" y="47" rx="0" ry="0" width="70" height="10" />
    </ContentLoader>
)

export default HistoryBonusesSkeleton