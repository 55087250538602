import React, {useEffect, useState} from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import LayoutWithApp from "../../Components/layouts/LayoutWithApp/LayoutWithApp";
import {updateCartItem} from "../../redux/actions/basketActions";
import {notify} from "../../helper";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {useAppSelector} from "../../hooks/redux";
import {basketSliceActions} from "../../redux/slices/backetSlice";

const EditBasketPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { id } = useParams();
    const [value, setValue] = useState('');
    const [amount, setAmount] = useState(location.state?.itemData?.amount);
    const serviceInfo = location.state?.itemData;
    const { isAuth } = useAppSelector((state) => state.authReducer)
    const { langKey } = useAppSelector(state => state.changeLangSlice);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const itemData = location.state?.itemData;
        if (itemData) {
            setValue(itemData.fields?.slice(0, 2).map((field: any) => field?.value).join(''));
            setAmount((itemData.amount / 100).toFixed(2) || '');
        }
    }, [id, location.state]);

    const minAmount = location.state?.itemData?.minAmount / 100;
    const maxAmount = location.state?.itemData?.maxAmount / 100;

    const handleCancel = () => {
        navigate(-1)
    };
    const isShow = location.state?.itemData?.serviceId === 976 ||
        location.state?.itemData?.serviceId === 142 ||
        location.state?.itemData?.serviceId === 157

    const handleSave =  async () => {
        const updatedFields = serviceInfo?.fields.map((field: any) => ({
            id: Number(field.fieldId),
            name: field.name,
            value: field.value
        }));
        const data = {
            value,
            amount: Number(amount) * 100,
            isAuth,
            id: serviceInfo?.id,
            cartId: serviceInfo?.cartId,
            serviceId: serviceInfo.serviceId,
            fields: JSON.stringify(updatedFields),
            subName: serviceInfo.paymentInfoServiceItem.name,
            subValue: serviceInfo.paymentInfoServiceItem.value,
            selectedGroup: serviceInfo.selectedGroup,
            toPay: serviceInfo.toPay,
            token
        };
        try {
            const response = await dispatch(updateCartItem(data));
            if (response?.payload.ResultCodes === "ok") {
                dispatch(basketSliceActions.updateBasketItem({id: data.id, value: data.value, amount: data.amount}));
                notify(`${t("successfullyChanged")}`, true);
                navigate(-1);
            } else {
                notify(`${t("notSuccessful")}`, false);
            }
        } catch (error) {
            console.error("Error deleting item from cart:", error);
        }
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setAmount(input);
    };

    const isSaveDisabled = amount === '';

    return (
        <LayoutWithApp goBack={() => navigate(-1)} navTitle={t("basket")}>
            <div className="edit-page-wrap">
                <div className="edit-page-wrap__name">{location.state?.itemData?.serviceName?.[langKey]}</div>
                <div className="edit-page-wrap__input-wrapper">
                    <div className="edit-page-wrap__input-wrapper-value">
                        <div>{serviceInfo?.fields[0]?.localShortName?.[langKey]}</div>
                        <div className="service_phone-field">
                            {isShow ? <span className="service_phone-placeholder">994</span> : <></>}
                            <input
                                type="text"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="edit-page-wrap__input-wrapper-amount">
                        <div>{t('amount_')}</div>
                        <input
                            style={{color: amount > maxAmount ? "red" : ""}}
                            type="text"
                            value={amount}
                            placeholder={"Сумма AZN"}
                            onChange={handleAmountChange}
                        />
                    </div>
                    <span>{t('min')} {minAmount} Azn</span>{" "}
                    <span style={{color: amount > maxAmount ? "red" : ""}}>{t('max')}</span> <span>{maxAmount} Azn</span>
                </div>

                <div className="edit-page-wrap__wrapper-btns">
                <div className="edit-page-wrap__wrapper-btns-btn">
                        <button
                            className="edit-page-wrap__wrapper-btns-btn-save"
                            onClick={handleSave}
                            disabled={isSaveDisabled}
                        >{"Сохранить"}</button>
                    </div>
                    <div className="edit-page-wrap__wrapper-btns-btn">
                        <button
                            className="edit-page-wrap__wrapper-btns-btn-save"
                            onClick={handleCancel}
                        >{"Отмена"}</button>
                    </div>
                </div>
            </div>
        </LayoutWithApp>

    );
};

export default EditBasketPage;
