import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {Controller, useForm} from "react-hook-form";
import { fetchAuth, fetchRegister } from "../../redux/actions/authActions";
import "./index.scss";
import ForgotPasswordModal from "../ForgotPasswordModal";
import ForgotPasswordMobileCode from "../ForgotPasswordMobileCode";
import { Navigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-number-input";
import CPhoneInput from "../CustomsComponents/PhoneInput";
import {notify} from "../../helper";
import "./index.scss"

const RegisterForm = () => {
  const {t} = useTranslation()
  const { isAuth } = useAppSelector((state) => state.authReducer);
  const [showForgotPasswordModal, setShowForgotPasswordModal] =
    useState<boolean>(false);
  const [showForgotPasswordMobileCode, setShowForgotPasswordMobileCode] =
    useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const [isChecked, setIsChecked] = useState(false);
  const [showPasswordConditions, setShowPasswordConditions] = useState(false);
  const [validations, setValidations] = useState({
    minLength: false,
    containsLetter: false,
    containsNumber: false
  });

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      phone: "",
      password: "",
      confirmPassword: "",
      agree: false,
    },
    mode: "onChange",
  });

  const sendRegisterEvent = () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'sign_up',
          method: 'phone',
      });
  };

  const onSubmit = async (values: any) => {
    dispatch(fetchRegister(values)).then(res => {
        if (res.payload?.description === 'Invalid field: PHONE_STILL_IN_USE') {
            notify(t('invalidUsernamePassword'))
        } else if (res.payload?.resultCodes === 'INVALID_USERNAME_OR_PASSWORD') {
            notify(t('invalidUsernamePassword'))
        }
    });
     await sendRegisterEvent()
  };
  const handleOpenPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };
  const handleClosePasswordModal = () => {
    setShowForgotPasswordModal(false);
  };
  // const handleClosePasswordMobileCode = () => {
  //   setShowForgotPasswordMobileCode(false);
  // };
  if (isAuth) {
    const link = `/epay/${language}/home`
    return <Navigate to={link}/>
  }

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const handleFocus = () => {
    setShowPasswordConditions(true);
  };

  const handleBlur = () => {
    setShowPasswordConditions(false);
  };

  const handlePasswordChange = (value: any) => {
    setValidations({
      minLength: value.length >= 8,
      containsLetter: /[A-Za-z]/.test(value),
      containsNumber: /\d/.test(value)
    });
    setValue("password", value)
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CPhoneInput control={control} aria-label={"Phone"}/>
        <div className="password-input-container">
        <TextField
          label={`${t("password")}`}
          color="warning"
          margin="normal"
          type={showPassword ? "text" : "password"}
          helperText={errors.password?.message}
          error={Boolean(errors.password?.message)}
          variant="standard"
          fullWidth
          {...register("password", 
            { required: `${t("enterYourPassword")}`,
              validate: (value) => /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value) || `${t("invalidPassword")}`
          })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) => {
            handlePasswordChange(e.target.value);
            trigger("password"); 
            if (getValues("confirmPassword")) {
              trigger("confirmPassword"); 
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showPasswordConditions && (
        <div className="password-conditions">
           <div className="password-triangle"></div>
          <ul>
            <li className={validations.minLength ? "valid" : "invalid"}>{validations.minLength ? "✓" : "X"} {t("passwordLength")}</li>
            <li className={validations.containsLetter  ? "valid" : "invalid"}>{validations.containsLetter ? "✓" : "X"} {t("passwordContainsLetter")}</li>
            <li className={validations.containsNumber  ? "valid" : "invalid"}>{validations.containsNumber ? "✓" : "X"} {t("passwordContainsNumber")}</li>
          </ul>
        </div>
        )}
        </div>
        <TextField
          color="warning"
          variant="standard"
          margin="normal"
          type={showPassword ? "text" : "password"}
          label={`${t("confirmThePassword")}`}
          helperText={errors.confirmPassword?.message}
          error={Boolean(errors.confirmPassword?.message)}
          fullWidth
          {...register("confirmPassword", { 
            required: `${t("enterYourPassword")}`,
            validate: (value) => value === watch("password") || `${t("passwordsDontMatch")}`
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box my={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="warning"
                {...register("agree", {required: `${t("confirmAgreement")}`})}
              />
            }
            label={
              <>
                <Typography style={{color: "warning", fontSize: "14px"}}>
                  {t("acceptConditions")}
                  <a
                    style={{marginLeft: '5px'}}
                    target="_blank"
                    className="terms-link"
                    href="https://www.e-pul.az/epay/ru/resources/pdf/e33898de-6302-4756-8f0c-5f6c5218e02e_terms_ru.pdf"
                    rel="noreferrer"
                  >
                    {t("agreements")}
                  </a>
                </Typography>
              </>
            }
          />
        </Box>
        <Box mt={2.5}>
          <Typography
            onClick={handleOpenPasswordModal}
            className="forgot-auth-password"
          >
              {t("forgotPassword")}
          </Typography>
        </Box>

        <div className="no-registration-main__register-button">
          <Button
            disabled={!isValid}
            type="submit"
            buttonText={`${t("signUp")}`}
            background="#EF5C27"
            padding="14px 65px"
            borderRadius={15}
            color="#FFFFFF"
            margin="0 auto"
          />
        </div>
      </form>
      <ForgotPasswordModal
        showForgotPasswordModal={showForgotPasswordModal}
        handleClosePasswordModal={handleClosePasswordModal}
      />
      {/*<ForgotPasswordMobileCode*/}
      {/*  showForgotPasswordMobileCode={showForgotPasswordMobileCode}*/}
      {/*  handleClosePasswordMobileCode={handleClosePasswordMobileCode}*/}
      {/*/>*/}
    </>
  );
};

export default RegisterForm;
