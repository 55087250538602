
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchAutoPayments, getSingleAutoPayment} from "../actions/autoPayments";
import {IAutoPayments, IItems} from "../../models/AutoPayments";

interface InitialState {
    sid: string,
    error: string,
    autoPaymentsList: IAutoPayments | null,
    isLoading: boolean,
    isAutoPayments: boolean,
    autoPaymentsState: {
        id?: number,
        path: string,
        isEdit: boolean,
        btnValue: string,
        isCreate:  boolean,
        isFieldEdit: boolean,
    },
     editItemData: {
        id: number,
        repeatingType: string,
        periods: string[]
    }
    selectedItem: IItems,
}

const initialState: InitialState = {
    sid: "",
    error: "",
    autoPaymentsList: null,
    isLoading: false,
    isAutoPayments: false,
    autoPaymentsState: {
        id: 0,
        path: '',
        isEdit: false,
        btnValue: '',
        isCreate: false,
        isFieldEdit: false,
    },
    editItemData: {
        id: 0,
        repeatingType: '',
        periods: []
    },
    selectedItem: {
        id: 0,
        userId: 0,
        amount: 0,
        fields: {
            autoOrderField: []
        },
        periods: {
            autoOrderPeriods: []
        },
        groupId: 0,
        mirrorId: '',
        isActive: 0,
        historyId: '',
        serviceId: 0,
        localName: {
            valueEn: '',
            valueRu: '',
            valueAz: '',
        },
        maskedPan: '',
        billMethod: 0,
        insertDate: 0,
        updateDate: 0,
        categoryId: 0,
        expiryDate: 0,
        paymentMode: 0,
        firstRunDate: 0,
        categoryName: '',
        repeatingType: 0,
        deactivateDate: 0,
        subServiceName: '',
        maxAllowAmount: 0,
        payCurrentDebt: false,
        subServiceValue: '',
        showNotification: 0,
    }



}

export const autoPaymentsListSlice = createSlice({
    name: "autoPaymentsList",
    initialState,
    reducers: {
        setIsAutoPayments(state, action) {
            state.isAutoPayments = action.payload;
        },
        setAutoPaymentsState(state, action) {
            state.autoPaymentsState = action.payload;
        },
        setEditItemData(state, action) {
            state.editItemData = action.payload;
        },
        setSelectedItem(state, action) {
            state.selectedItem = action.payload;
        },
        resetSelectedItemData(state) {
            state.selectedItem = initialState.selectedItem;
            state.editItemData = initialState.editItemData
        },
        resetSelectedItem(state) {
            state.selectedItem = initialState.selectedItem;
        }
    },
    extraReducers: {
        [fetchAutoPayments.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchAutoPayments.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false
            state.error = ""
            state.autoPaymentsList = action.payload
        },
        [fetchAutoPayments.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
        [getSingleAutoPayment.pending.type]: (state) => {
            state.isLoading = true
        },
        [getSingleAutoPayment.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false
            state.error = ""
            state.selectedItem = action.payload?.items?.autoOrder[0];
        },
        [getSingleAutoPayment.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})
export const autoPaymentsListSliceActions = {
    ...autoPaymentsListSlice.actions
};
export default autoPaymentsListSlice.reducer;