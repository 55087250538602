import {t} from "i18next";
import Loading from "../../Components/Loading/Loading";

export const ShowCheckInfo = ({savedCheckData}) => {
    if(savedCheckData === undefined){
        return (
        <div className="loader-wrapper loader-wrapper--check">
            <Loading />
        </div>
        )
    }
    else if(savedCheckData){
        return (
        <div className="check-head__left">
            <img src={require("../../img/check_doneicon.svg").default}/>
            <span>{t("paymentCompleted")}</span>
        </div>
        )
    }
    else {
        <div className="check-head__left">
            <img src={require("../../img/check-dont.svg").default}/>
            <span>{t("paymentNotCompleted")}</span>
        </div>
    }
}