import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {LocaleName} from "../../models/Categories";

interface ChangeLangState {
    language: string | 'ru';
    langKey:  LocaleName["valueRu"] | LocaleName["valueAz"] | LocaleName["valueEn"];
}

const initialState: ChangeLangState = {
    language: localStorage.getItem("language") || 'ru',
    langKey: localStorage.getItem("langKey") ||'valueRu' ,

}

export const changeLangSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeLang: (state, action: PayloadAction<{langKey:string, language:string}>) => {
            state.langKey = action.payload.langKey
            state.language = action.payload.language
            localStorage.setItem('language', action.payload.language)
            localStorage.setItem('langKey', action.payload.langKey)

        }
    }
})
export default changeLangSlice.reducer
