import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import moment from "moment/moment";
import {addRealMonth, getPrice, getOrderFields} from "../../../helper";
import { Category } from "../../../models/Categories";
import { AppDispatch } from "../../../redux/store";
import { ServiceActionCreators } from "../../../redux/reducers/service/action-creators";
import { IAutoOrderField, IItems } from "../../../models/AutoPayments";
import { week, itemIcon, azercellId } from '../consts';
import { changeAutoPaymentStatus, fetchAutoPayments } from "../../../redux/actions/autoPayments";
import './index.scss';
import {useAppSelector} from "../../../hooks/redux";
import {ICard} from "../../../models/Card";

interface IProps {
    item: IItems;
    categories?: Category[];
    isModalOpen?: boolean;
    setIsModalOpen?: (value: boolean) => void;
}

const RecurringPaymentItem: FC<IProps> = ({item, setIsModalOpen, isModalOpen}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [nearDate, setNearDate] = useState('');
    const { services } = useAppSelector(state => state.categoriesReducer);
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const { cards } = useAppSelector((state) => state.cardsReducer);

    //TODO: Sevinj review this function
    const getNearDay = useCallback(() => {
        let nearDay: any = '';
        let currentClock = moment().format('HH:MM')
        let currentWeekDay = moment().isoWeekday();
        const today = new Date();
        let selectedDays = item?.periods?.autoOrderPeriods?.map((period) => week[period?.day]);
        const todayDay = today.getDate();
        const selectedDay = item?.periods?.autoOrderPeriods[0]?.day;

        if(item?.repeatingType === 1) {
            if(currentClock < item?.periods?.autoOrderPeriods[0]?.atClock) {
                nearDay = moment().format('DD.MM.YYYY')
            } else {
                nearDay = moment().add(1, 'days').format('DD.MM.YYYY')
            }
            return nearDay;
        } else if(item?.repeatingType === 2) {

            const min = Math.min(...selectedDays)

            if(min < currentWeekDay ) {
                currentWeekDay = min + 7;
            } else {
                currentWeekDay = min
            }

            nearDay = moment()?.isoWeekday(currentWeekDay).format('DD.MM.YYYY')
            return nearDay;
        } else if(item?.repeatingType === 3) {
            const date = addRealMonth(moment().set('date', Number(selectedDay)));
            nearDay = moment(date).format('DD.MM.YYYY');
            return nearDay;
        } else {
            return nearDay;
        }
    }, [item?.periods?.autoOrderPeriods, item?.repeatingType])

    useEffect(() => {
        const date = getNearDay()
        setNearDate(date)
    }, [getNearDay])

    const getItemInfo = item?.fields?.autoOrderField?.map((field: IAutoOrderField, index: number, arr) => {
        if(field?.serviceId === azercellId) {
            if(index === 1) {
                return arr[index -1]?.value + field?.value;
            }
        } else {
            return field?.value;
        }
    });

    const changeItemStatus = () => {
        if(item?.isActive === 1) {
            dispatch(changeAutoPaymentStatus({id: item?.id, status: 0})).then((res) => {
                if(res?.payload?.status === 200) {
                    dispatch(fetchAutoPayments());
                }
            })
        } else {
            dispatch(changeAutoPaymentStatus({id: item?.id, status: 1})).then((res) => {
                if(res?.payload?.status === 200) {
                    dispatch(fetchAutoPayments());
                }
            })
        }
    }

    const valueOrderField = getOrderFields(item?.fields?.autoOrderField);

    const getItemImage = (): string => {

        const findService = services?.find((service: any) => service?.id === item?.serviceId);

        if(findService) {
            return `https://www.e-pul.az/epul_api/images/${findService?.friendlyName}.png`
        } else {
            return require('../../../img/loading (1).gif');
        }
    }

    const onClickToItem = async() => {
        await dispatch(ServiceActionCreators.getServiceById({
            selected: [item?.serviceId],
            hash: item?.serviceId,
        }));
        navigate(
            `/epay/${language}/recurring-edit/${item?.id}`,
            {
                state: {
                    recurring: item,
                }
            }
        )
    }

    const checkRecurring = () => {
        const findCard = cards?.find((card: ICard) => card.pan === item.maskedPan);

        if (findCard) {
            changeItemStatus();
        } else {
            setIsModalOpen?.(true);
        }
    }

    return (
        <div className="recurring-item">
            <div className="recurring-item__image-wrapper">
                <img className="recurring-item__image" src={getItemImage()} alt=""/>
            </div>
            <div className="recurring-item__payment-info">
                <div className="recurring-item__payment-title" title={valueOrderField || ''}>
                    {valueOrderField}
                </div>
                <div className="recurring-item__payment-subtitle" title={item?.localName?.valueRu}>
                    {item?.localName?.valueRu}
                </div>
            </div>
            <div className="recurring-item__payment-price" title={`${getPrice(item?.amount)} AZN`}>
                <span className="recurring-item__price-value" >
                    {getPrice(item?.amount)}
                </span>
                {' AZN'}
            </div>
            <div className="recurring-item__payment-date">
                <div className="recurring-item__date-title">{t("nearestPayment")}</div>
                <div className="recurring-item__date-subtitle" title={nearDate}>
                    {nearDate}
                </div>
            </div>
            <div className="recurring-item__payment-switch">
                <Switch
                    checked={item?.isActive === 1}
                    color="warning"
                    onChange={checkRecurring}
                />
            </div>
            <img
                className="recurring-item__icon"
                src={itemIcon} alt="icon"
                onClick={() => onClickToItem()}
            />
        </div>
    )
}

export default RecurringPaymentItem;
