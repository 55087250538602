import {createAsyncThunk} from "@reduxjs/toolkit";
import client from "../../axios";
import {fetchWithTimeout} from "../../utils/query";

export const fetchBanner = createAsyncThunk(
    "bannerList/fetchBanner",
    async (sid: string | null | undefined, thunkAPI) => {

        try {
            // const res = await client.get<any>('https://admin.e-pul.az/back/public/list-banners?types=1,2,3,4,5');

            const res = await client.get("https://admin.e-pul.az/back/public/list-banners?types=1,2,3,4,5" , {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            console.log("ress banner", res)


            return res.data;
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось загрузить");
        }

    }
);
