import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchFilterCard } from "../../redux/actions/habStorageAction";
import { authSelector } from "../../redux/slices/auth";
import { browserTokenSelector } from "../../redux/slices/browserTokenSlice";

export const useFilterCard = () => {
    const dispatch = useAppDispatch();
    const { isAuth } = useAppSelector(authSelector);
    const { browserToken, isAuthBrowserToken } =
        useAppSelector(browserTokenSelector);

    useEffect(() => {
        if (!isAuth || !isAuthBrowserToken) return;

        dispatch(fetchFilterCard({ body: {}, browserToken: browserToken }));
    }, [dispatch, isAuth, browserToken, isAuthBrowserToken]);
}