import React, {FC} from 'react';
import {infoModal} from "../../mockFile/mockFile.js"
import "./index.scss"
import {useTranslation} from "react-i18next";

interface RelaxRightInfoProps{}

const RelaxRightInfo: FC = (props: RelaxRightInfoProps) => {
    const { t } = useTranslation();

    const infoModal = [
        {
            title: t("conveniently"),
            text: t("instantTransfers"),
        },
        {
            title: t("reliably"),
            text: t("directAcceptancePayments"),
        },
        {
            title: t("safely"),
            text: t("certificates"),
        },
    ]
    return (
        <div className="relax-right-info">
            {
                infoModal.map((item, id) => (
                    <div key={id} className="relax-right-info__main">
                        <div className="relax-right-info__main-div">
                            <p className="relax-right-info__main-title">{item.title}</p>
                            <p className="relax-right-info__main-text">{item.text}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default RelaxRightInfo;