import { Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/min";
import 'react-phone-number-input/style.css'
import { useTranslation } from "react-i18next";
import './index.scss'

const CPhoneInput = ({ control }: any) => {
    const { t } = useTranslation();


    return (
        <Controller
            name="phone"
            control={control}
            rules={{
                validate: (value) => {
                    return isValidPhoneNumber(String(value))
                }
            }}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }
            ) => (
                <>
                    <PhoneInput
                        aria-label={"phone"}
                        className={'phone-input'}
                        international
                        defaultCountry="AZ"
                        value={value}
                        onChange={onChange}
                        id="phone-input"
                        name="phone"
                    />
                    {error
                        ?
                        <p className='errorInvalid'>
                            {t('invalidPhone')}</p>
                        : null}
                </>

            )}
        />
    );
};

export default CPhoneInput;