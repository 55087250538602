import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {ICard} from "../../models/Card";
import {fetchWalletList} from "../../redux/actions/walletListAction";
import { BonusToCash } from "../../helper";
import { Wallet } from "../../models/WalletList";
import {useTranslation} from "react-i18next";
import {LoadingLocal} from "../Loading/Loading";
import {IS_MASTER_PASS_MEMBER} from "../../utils/consts";

const CenterMode = ({onSelect, noCards, canWallet, canBonus, ruRate, canPulpal, canAnonymous, currentHubSolutionPaymentMethodHidden, onlyBonus, onlyWallet}: { onSelect?: (data: { card?: ICard, wallet?: Wallet, bonus?: boolean, ruCard?: boolean, anonymous?: boolean, pulpal?: boolean}) => void, canWallet?: boolean, canBonus?: boolean, canAnonymous?: boolean, canPulpal?: boolean, noCards?: boolean, ruRate?: number, currentHubSolutionPaymentMethodHidden?:boolean, onlyBonus?:boolean, onlyWallet?:boolean }) => {
    const {t} = useTranslation()
    const [slideCount, setSlideCount] = useState(0);
    const [selectedId, setSelectedId] = useState<any>({
        type: '',
        value: '',
    });
    let {cards, isLoading} = useAppSelector(state => state.cardsReducer)
    const {isHubSolutionPaymentMethod, allHubSolutionPaymentMethodHidden} = useAppSelector((state) => state.service);
    const { profile, isAuth } = useAppSelector((state) => state.authReducer);
    const {walletList} = useAppSelector(state => state.walletList)
    const { forms } = useAppSelector(state => state.masterPassReducer)
    const dispatch = useAppDispatch()
    const token: any = localStorage.getItem('token')
    const pulPalId = 'pulPal_id';
    const ruRateId = 'ruRate_id';
    const bonusCardId = 'bonus_id';
    const anonymousId = 'anonymous_id';
    useEffect(() => {
        if(isAuth) {
            dispatch(fetchWalletList())
            forms?.getToken(token, true)
        }
    }, [dispatch, forms, isAuth, token])

    const [cardsList, setCadsList] = useState(noCards || onlyBonus || onlyWallet ? [] : cards);

    useEffect(()=>{
        if(cards.length && !onlyBonus && !onlyWallet) {
            setCadsList(cards)
        }
    }, [cards, setCadsList, onlyBonus, onlyWallet])

    const wallets = canWallet ? walletList?.wallet || [] : [];

    useEffect(()=>{
        if(
            (!isHubSolutionPaymentMethod && allHubSolutionPaymentMethodHidden) || (currentHubSolutionPaymentMethodHidden && !isHubSolutionPaymentMethod)
        ) {

            const cardsListMod:any = cardsList.filter((card)=>{
                return card?.isMasterPassMember === IS_MASTER_PASS_MEMBER
            })

            setCadsList(cardsListMod)
            return
        }
    }, [cardsList.length, isHubSolutionPaymentMethod])

    useEffect(() => {
    if (onlyBonus || onlyWallet) {
        setSlideCount(1)
    } else { 
        if(canBonus) {
            setSlideCount((c) => c + 1)
        }
        if(cardsList.length) {
            setSlideCount((c) => c + cards?.length)
        }
        if(wallets.length) {
            setSlideCount((c) => c + wallets?.length)
        }
        if(canAnonymous) {
            setSlideCount((c) => c + 1)
        }
        if(canPulpal) {
            setSlideCount((c) => c + 1)
        }
        if(!!ruRate) {
            setSlideCount((c) => c + 1)
        }
    }
        return () => {
            setSlideCount(0)
        }
    }, [cardsList.length, wallets.length])

    useEffect(() => {
        if(selectedId.type) {
            return;
        }
        if(cardsList.length) {
            setSelectedId({
                type: 'card',
                value: cardsList[0],
            })
        } else if(wallets.length) {
            setSelectedId({
                type: 'wallet',
                value: wallets[0],
            })
        } else {
            if(canBonus) {
                setSelectedId({
                    type: 'bonus',
                    value: bonusCardId,
                })
                return
            }
            if(canAnonymous) {
                setSelectedId({
                    type: 'anonymous',
                    value: anonymousId,
                })
                return
            }
            if(canPulpal) {
                setSelectedId({
                    type: 'pulPal',
                    value: pulPalId,
                })
                return
            }
            if(!!ruRate) {
                setSelectedId({
                    type: 'ruRate',
                    value: ruRateId,
                })
                return
            }
        }
    }, [  canBonus, canAnonymous, canPulpal, ruRate, cardsList?.length, wallets?.length])

    useEffect(() => {
        if(selectedId.type === 'bonus') {
            onSelect && onSelect({bonus: true});
        }
        if(selectedId.type === 'wallet') {
            onSelect && onSelect({wallet: selectedId.value});
        }
        if(selectedId.type === 'anonymous') {
            onSelect && onSelect({anonymous: true});
        }
        if(selectedId.type === 'pulPal') {
            onSelect && onSelect({pulpal: true});
        }
        if(selectedId.type === 'ruRate') {
            // onSelect && onSelect({ruRate: true});
        }
        if(selectedId.type === 'card') {
            onSelect && onSelect({card: selectedId.value});
        }
    }, [selectedId.type, selectedId.value])

    useEffect(() => {
        return () => {
            setSlideCount(0)
            setSelectedId({
                type: '',
                value: '',
            })
        }
    }, [])

    return slideCount <= 3
            ? (
                <>
                    <div className='card-container'>
                        {
                            isLoading && !noCards && <div className="card-loading"><LoadingLocal /></div>
                        }
                    {cardsList?.map((card: any) => (

                            <div
                                key={card?.id}
                                className={selectedId.type === 'card' && selectedId.value.id === card?.id  ? 'card card--active' : 'card'}
                                onClick={() => setSelectedId({
                                    type: 'card',
                                    value: card,
                                })}
                            >
                                <div className="card__header">
                                    <div className="card__header-title">{card?.name}</div>
                                    {
                                        selectedId.type === 'card' && selectedId.value.id === card?.id ?
                                            <div className="card__header-icon">
                                                <img className="card__img" src={require('../../img/check.svg').default} alt="" />
                                            </div> : null
                                    }
                                </div>
                                <div className="card__body">
                                    {card?.pan}
                                </div>
                                <div className="card__footer">
                                    <div className="card__footer-title">•• / ••</div>
                                    <div className="card__footer-icon">
                                        <img
                                            className="card__footer-img"
                                            src={card.pan.startsWith('2') || card.pan.startsWith('5')
                                                ? require("../../img/master-silver.svg").default
                                                : require("../../img/visa-silver.svg").default}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                    ))}
                    {wallets?.map((wallet: any) => (
                            <div
                                key={wallet?.id}
                                className={selectedId.type === 'wallet' && selectedId.value.id === wallet?.id  ? 'card card--active' : 'card'}
                                onClick={() => setSelectedId({
                                    type: 'wallet',
                                    value: wallet,
                                })}
                            >
                                <div className="card__header">
                                    <div className="card__header-title">E-кошелек</div>
                                    {
                                        selectedId.type === 'wallet' && selectedId.value.id === wallet?.id?
                                            <div className="card__header-icon">
                                                <img className="card__img" src={require('../../img/check.svg').default} alt="" />
                                            </div> : null
                                    }
                                </div>
                                <div className="card__body">
                                    {wallet.balance / 100} {wallet.currency}
                                </div>
                                <div className="card__footer">
                                    <div className="card__footer-icon">
                                        <img
                                            className="card__img"
                                            src={require("../../img/Vector-silver.svg").default}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                    ))}
                    {
                        canBonus && (
                                <div
                                    className={selectedId.type === 'bonus' && selectedId.value === bonusCardId  ? 'card card--active' : 'card'}
                                    onClick={() => setSelectedId( {
                                        type: 'bonus',
                                        value: bonusCardId,
                                    })}
                                >
                                    <div className="card__header">
                                        <div className="card__header-title">{t("bonus")}</div>
                                        {
                                            selectedId.type === 'bonus' ?
                                                <div className="card__header-icon">
                                                    <img className="card__img" src={require('../../img/check.svg').default} alt="" />
                                                </div> : null
                                        }
                                    </div>
                                    <div className="card__body">
                                        {profile?.bonusValue} / {BonusToCash(profile?.bonusValue)} AZN
                                    </div>
                                    <div className="card__footer">
                                        <div className="card__footer-icon">
                                            <img
                                                className="card__footer-img"
                                                src={require("../../img/bonus-white.svg").default}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                    {
                        canAnonymous && (
                            <div
                                className={selectedId.type === 'anonymous' && selectedId.value === anonymousId  ? 'card card--active' : 'card'}
                                onClick={() => setSelectedId({
                                    type: 'anonymous',
                                    value: anonymousId,
                                })}
                            >
                                <div className="card__header">
                                    <div className="card__header-title">Новая карта</div>
                                    {
                                        selectedId.type === 'anonymous' ?
                                            <div className="card__header-icon">
                                                <img className="card__img" src={require('../../img/check.svg').default} alt="" />
                                            </div> : null
                                    }
                                </div>
                                <div className="card__body">
                                    {/*{profile?.bonusValue} / {BonusToCash(profile?.bonusValue)} AZN*/}
                                </div>
                                <div className="card__footer">
                                    {/*<div className="card__footer-icon">*/}
                                    {/*    <img*/}
                                    {/*        className="card__footer-img"*/}
                                    {/*        src={require("../../img/bonus-white.svg").default}*/}
                                    {/*        alt=""*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        )
                    }
                    {
                        !!ruRate && (
                            <div
                                className={selectedId.type === 'ruRate' && selectedId.value === ruRateId ? 'card card--active' : 'card'}
                                onClick={() => setSelectedId({
                                    type: 'ruRate',
                                    value: ruRateId,
                                })}
                            >
                                <div className="card__header">
                                    <div className="card__header-title">Карта российского банка</div>
                                    {
                                        selectedId.type === 'ruRate' ?
                                            <div className="card__header-icon">
                                                <img className="card__img" src={require('../../img/check.svg').default} alt="" />
                                            </div> : null
                                    }
                                </div>
                                <div className="card__body">
                                    1AZN={ruRate?.toFixed(2)}RUB
                                </div>
                                <div className="card__footer">
                                    <div className="card__footer-icon">
                                        <img
                                            className="card__footer-img"
                                            src={require("../../img/p-icon.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        canPulpal && (
                            <div
                                className={selectedId.type === 'pulPal' && selectedId.value === pulPalId ? 'card card--active' : 'card'}
                                onClick={() => setSelectedId({
                                    type: 'pulPal',
                                    value: pulPalId,
                                })}
                            >
                                <div className="card__header">
                                    <div className="card__header-title">Оплата наличными или в рассрочку</div>
                                    {
                                        selectedId.type === 'pulPal' ?
                                            <div className="card__header-icon">
                                                <img className="card__img" src={require('../../img/check.svg').default} alt="" />
                                            </div> : null
                                    }
                                </div>
                                <div className="card__body">
                                    {/*1AZN={ruRate?.toFixed(2)}RUB*/}
                                </div>
                                <div className="card__footer">
                                    {/*<div className="card__footer-icon">*/}
                                    {/*    <img*/}
                                    {/*        className="card__footer-img"*/}
                                    {/*        src={require("../../img/p-icon.png")}*/}
                                    {/*        alt=""*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        )
                    }
                    </div>
                </>
            )
            : (
                <div className='swiper-container'>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={1}
                        slidesPerView={1}
                        centeredSlides={true}
                        roundLengths={true}
                        loop={true}
                        loopAdditionalSlides={30}
                        navigation
                        breakpoints={{
                            400:{
                                slidesPerView:1,
                            },
                            500:{
                                slidesPerView:2,
                            },
                            639: {
                                slidesPerView: 2,
                            },
                            865:{
                                slidesPerView:3,
                            },
                            1000:{
                                slidesPerView:3
                            },
                        }}
                        onSlideChange = {(params) => {
                            if (params.realIndex < cardsList.length) {
                                onSelect && onSelect({card: cardsList[params.realIndex]})
                            } else if (params.realIndex < cardsList.length + wallets.length) {
                                onSelect && onSelect({wallet: wallets[params.realIndex - cardsList.length]})
                            } else if (params.realIndex < cardsList.length + wallets.length + (canBonus ? 1 : 0)) {
                                onSelect && onSelect({bonus: true});
                            } else if (params.realIndex < cardsList.length + wallets.length + (canBonus ? 1 : 0) + (canAnonymous ? 1 : 0)) {
                                onSelect && onSelect({anonymous: true});
                            } else if (params.realIndex < cardsList.length + wallets.length + (canBonus ? 1 : 0) + (canAnonymous ? 1 : 0) + (canPulpal ? 1 : 0)) {
                                onSelect && onSelect({pulpal: true});
                            }
                        }}
                    >
                        {cardsList.map((card) => (
                            <SwiperSlide key={`${card?.id}`}>
                                <div  className="swiper-slide__content">
                                    <div className="swiper-slide__content-wap" style={{ display: "flex", marginTop: 10, textAlign: "start"}}>
                                        <div translate="no" className="swiper-slide__content-name-cart">{card.name}</div>
                                    </div>
                                    <div className="swiper-slide__content-body">{card.pan}</div>
                                    <div className="swiper-slide__content-footer">
                                        <div>{"•• / ••"}</div>
                                        <img
                                            src={card.pan.startsWith('2') || card.pan.startsWith('4')
                                                ? require("../../img/master-silver.svg").default
                                                : require("../../img/visa-silver.svg").default}
                                            alt=""/>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        {wallets.map((wallet: any)=>{
                            return (
                                <SwiperSlide key={wallet.id}>
                                    <div className="swiper-slide__content">
                                        <div className="swiper-slide__content-name-cart">{"E-кошелек"}</div>
                                        <div className="swiper-slide__content-body">{wallet.balance / 100} {wallet.currency}</div>
                                        <div className="swiper-slide__content-footer">
                                            <div>{""}</div>
                                            <img src={require("../../img/Vector-silver.svg").default} alt=""/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                        {canBonus && <SwiperSlide >
                            <div className="swiper-slide__content">
                                <div className="swiper-slide__content-name-cart">{t("bonus")}</div>
                                <div className="swiper-slide__content-body">{profile?.bonusValue} / {BonusToCash(profile?.bonusValue)} AZN
                                </div>
                                <div className="swiper-slide__content-footer">
                                    <div>{""}</div>
                                    <img src={require("../../img/bonus-white.svg").default} alt=""/>
                                </div>
                            </div>
                        </SwiperSlide>}
                        {!!ruRate && <SwiperSlide >
                            <div className="swiper-slide__content">
                                <div className="swiper-slide__content-name-cart">{"Карта российского банка"}</div>
                                <div className="swiper-slide__content-body">1AZN={ruRate.toFixed(2)}RUB</div>
                                <div className="swiper-slide__content-footer">
                                    <div>{""}</div>

                                    <img src={require("../../img/p-icon.png")} alt=""/>
                                </div>
                            </div>
                        </SwiperSlide>}
                        {canAnonymous && <SwiperSlide >
                            <div className="swiper-slide__content">
                                <div className="swiper-slide__content-name-cart">{"Новая карта"}</div>
                                <div className="swiper-slide__content-body"></div>
                                <div className="swiper-slide__content-footer">
                                    <div>{""}</div>
                                </div>
                            </div>
                        </SwiperSlide>}
                        {canPulpal && <SwiperSlide >
                            <div className="swiper-slide__content">
                                <div className="swiper-slide__content-name-cart">{"Оплата наличными или в рассрочку"}</div>
                                <div className="swiper-slide__content-body"></div>
                                <div className="swiper-slide__content-footer">
                                    <div>{""}</div>
                                </div>
                            </div>
                        </SwiperSlide>}
                    </Swiper>
                </div>
            )
}

export default CenterMode;
