import React from "react"
import ContentLoader from "react-content-loader"

const AutoPaymentSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={100}
        viewBox="0 0 750 100"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <circle cx="28" cy="42" r="28" />
        <rect x="63" y="27" rx="0" ry="0" width="210" height="30" />
        <rect x="340" y="32" rx="0" ry="0" width="80" height="17" />
        <rect x="440" y="19" rx="0" ry="0" width="130" height="40" />
        <rect x="640" y="26" rx="0" ry="0" width="35" height="24" />
        <rect x="705" y="26" rx="0" ry="0" width="30" height="24" />
    </ContentLoader>
)

export default AutoPaymentSkeleton

