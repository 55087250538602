import {createSlice} from "@reduxjs/toolkit";
import { MasterPassWebviewType } from "../../Components/MasterPass/MasterPassForms";
import { IMasterPassData } from "../../models/MasterPassData";
import { IMpCardData } from "../../models/MpCardData";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IMasterPassState {
    sid: string,
    error: string,
    masterPassData?: IMasterPassData,
    masterPassCards: IMpCardData[],
    forms?: MasterPassWebviewType,
    isLoading: boolean,
}

const initialState: IMasterPassState = {
    sid: "",
    error: "",
    masterPassCards: [],
    isLoading: false,
}

export const masterPassSlice = createSlice({
    name: "masterPass",
    initialState,
    reducers: {
        setMasterPassForms(state, action) {
            state.forms = action.payload
        },
        setMasterPassLoading(state, action) {
            state.isLoading = action.payload
        },
        setMasterPassData(state, action) {
            state.masterPassData = action.payload
        },
        setMasterPassCards(state, action) {
            state.masterPassCards = action.payload
        },
    },
})

const selectMasterPassState = (state: RootState) => state.masterPassReducer;

export const masterPassSelector = createSelector(
    [selectMasterPassState],
    (masterPassReducer) => masterPassReducer
  );


export default masterPassSlice.reducer
export const masterPassSliceActions = {
    ...masterPassSlice.actions
};
