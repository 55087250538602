import React from "react"
import ContentLoader from "react-content-loader"

const CatalogueSliderSkeletons = () => (
    <ContentLoader
        speed={2}
        width={500}
        height={100}
        viewBox="0 0 500 100"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="11" y="6" rx="15" ry="15" width="490" height="80" />
    </ContentLoader>
)

export default CatalogueSliderSkeletons