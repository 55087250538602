import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/Button";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import SideMenu from "../SideMenu/SideMenu";
import "./Online.scss";
import { setCallWithYouId } from "../../../redux/slices/callWithSlice";
import { sendIdentificationByCourier } from "../../../redux/actions/walletAction";
import { AppDispatch } from "../../../redux/store";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import { useAppSelector } from "../../../hooks/redux";

const plus = require("../../../img/plus.svg").default;
const frontSideImg = require("../../../img/vesiqe2.png");
const flipSideImg = require("../../../img/vesiqe.png");
const selfieImg = require("../../../img/selfieWithId.png");

type contactsType = {
  type: string;
  value: string;
  fieldValue: string;
  fieldPlaceholder: string;
};

const contacts: contactsType[] = [
  {
    type: "skype",
    value: "Skype",
    fieldValue: "Skype",
    fieldPlaceholder: "pleaseEnterSkype",
  },
  {
    type: "whatsapp",
    value: "Whatsapp",
    fieldValue: "Whatsapp",
    fieldPlaceholder: "pleaseEnterWp",
  },
  {
    type: "viber",
    value: "Viber",
    fieldValue: "Viber",
    fieldPlaceholder: "pleaseEnterViber",
  },
];

const Online = () => {
  const { t } = useTranslation();
  const [firstImg, setFirstImg] = useState(null);
  const [secondImg, setSecondImage] = useState(null);
  const [thirdImg, setThirdImg] = useState(null);
  const [encodedFirstImg, setEncodedFirstImg] = useState("");
  const [encodedSecondImg, setEncodedSecondImg] = useState("");
  const [encodedThirdImg, setEncodedThirdImg] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [contactValue, setContactValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [selectTab, setSelectTab] = useState<contactsType>({
    type: "skype",
    value: "skype",
    fieldValue: "skype",
    fieldPlaceholder: "pleaseEnterSkype",
  });
  const [localeLoading, setLocaleLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useAppSelector((state) => state.changeLangSlice);

  const checkAllFieldsFilled = () => {
    if (
      firstImg &&
      secondImg &&
      thirdImg
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  useEffect(() => {
    setContactValue("");
  }, [selectTab.value]);

  useEffect(() => {
    checkAllFieldsFilled();
  }, [deliveryAddress, contactValue, firstImg, secondImg, thirdImg]);

  const getDeliveryAddressValue = (value: string) => {
    setDeliveryAddress(value?.trim());
  };
  const getContactValueValue = (value: string) => {
    setContactValue(value?.trim());
  };

  const navigate = useNavigate();

  const goBack = () => navigate(`/epay/${language}/identification`);

  const encodeImageFileAsURL = (file: any, cb: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      cb(reader.result);
    };
  };

  const notify = (msg: string, success?: boolean) => {
    if (success) {
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onBtnPress = () => {
    setLocaleLoading(true);
    const token = localStorage.getItem("token");

    const data: any = {
      sessionId: token,
      deliveryAddress: "",
      livingAddress: "",
      contactType: "",
      contactAddress: "",
      first: encodedFirstImg?.split("base64,")[1],
      second: encodedSecondImg?.split("base64,")[1],
      third: encodedThirdImg?.split("base64,")[1],
    };

    dispatch(sendIdentificationByCourier(data)).then((res: any) => {
      if (
        // res?.payload?.status === 200 &&
        res?.payload.data?.resultCodes === "OK"
      ) {
        notify(`${t("identificationRequest")}`, true);
      } else {
        notify(`${t("failedSendRequest")}`);
      }
      setLocaleLoading(false);
      // navigate("/e-wallet");
    });
  };

  const imageUpload = (
    event: any,
    setState: (data: any) => void,
    cb: (value: ((prevState: string) => string) | string) => void
  ) => {
    const imgSizeLimit = 50000;

    if (event.target.files[0].size > imgSizeLimit) {
      notify(`${t("largeSizeImage")}`);
    } else {
      setState(event.target.files[0]);
      encodeImageFileAsURL(event.target.files[0], cb);
    }
  };

  return (
      <>
        <LayoutEmpty navTitle={t("goThrough")} goBack={goBack}>
          <div className="wallet-online__content">
            <div className="wallet-online__content-body">
              <div className="wallet-online__content-header">{t("online")}</div>
              <div className="wallet-online__content-title">
                {t("identifyYourself")}
              </div>
              <div className="wallet-online__content-text">
                {t("downloadCopies")}
              </div>
              <div className="wallet-online__image-box">
                <div className="wallet-online__image">
                  <div className="wallet-online__image-title">{t("frontSide")}</div>
                  <div className="wallet-online__select-image-box">
                    <img
                        className="wallet-online__select-image"
                        src={firstImg ? URL.createObjectURL(firstImg) : frontSideImg}
                        alt="image"
                    />
                  </div>
                  <div className="wallet-online__upload">
                    <label
                        className="wallet-online__upload-label"
                        htmlFor="firstImage"
                    >
                      <img
                          className="wallet-online__upload-icon"
                          src={plus}
                          alt="icon"
                      />
                      {t("download")}
                      <input
                          className="wallet-online__upload-input"
                          id="firstImage"
                          type="file"
                          accept="image/png, image/jpeg"
                          name="myImage"
                          onChange={(event: any) => {
                            imageUpload(event, setFirstImg, setEncodedFirstImg);
                          }}
                      />
                    </label>
                  </div>
                </div>
                <div className="wallet-online__image">
                  <div className="wallet-online__image-title">{t("flipSide")}</div>
                  <div className="wallet-online__select-image-box">
                    <img
                        className="wallet-online__select-image"
                        src={secondImg ? URL.createObjectURL(secondImg) : flipSideImg}
                        alt="image"
                    />
                  </div>
                  <div className="wallet-online__upload">
                    <label
                        className="wallet-online__upload-label"
                        htmlFor="secondImage"
                    >
                      <img
                          className="wallet-online__upload-icon"
                          src={plus}
                          alt="icon"
                      />
                      {t("download")}
                      <input
                          className="wallet-online__upload-input"
                          id="secondImage"
                          type="file"
                          accept="image/png, image/jpeg"
                          name="myImage"
                          onChange={(event: any) => {
                            imageUpload(event, setSecondImage, setEncodedSecondImg);
                          }}
                      />
                    </label>
                  </div>
                </div>
                <div className="wallet-online__image">
                  <div className="wallet-online__image-title">
                    {t("selfiesCard")}
                  </div>
                  <div className="wallet-online__select-image-box">
                    <img
                        className="wallet-online__select-image"
                        src={thirdImg ? URL.createObjectURL(thirdImg) : selfieImg}
                        alt="image"
                    />
                  </div>
                  <div className="wallet-online__upload">
                    <label
                        className="wallet-online__upload-label"
                        htmlFor="thirdImage"
                    >
                      <img
                          className="wallet-online__upload-icon"
                          src={plus}
                          alt="icon"
                      />
                      {t("download")}
                      <input
                          className="wallet-online__upload-input"
                          id="thirdImage"
                          type="file"
                          accept="image/png, image/jpeg"
                          name="myImage"
                          onChange={(event: any) => {
                            imageUpload(event, setThirdImg, setEncodedThirdImg);
                          }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="wallet-online__submit-btn">
                <Button
                    disabled={!isChecked || localeLoading}
                    buttonText={localeLoading ? <div style={{position: 'relative', top: '-7px'}} className="spin"></div> : `${t("goThrough")}`}
                    background={"#EF5C27"}
                    color={"white"}
                    minWidth={367}
                    height={44}
                    borderRadius={15}
                    padding={"0px 66px"}
                    margin={"auto"}
                    onClick={onBtnPress}
                />
              </div>
            </div>
            <div className="wallet-online__menu">
              <SideMenu />
            </div>
          </div>
        </LayoutEmpty>
      </>
  );
};

export default Online;
