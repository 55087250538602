import React from "react"
import ContentLoader from "react-content-loader"

const LastOperationSkeleton = () => (
    <ContentLoader
        speed={2}
        width={337}
        height={343}
        viewBox="0 0 337 343"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="20" y="20" rx="0" ry="0" width="206" height="17" />

        <circle cx="39" cy="90" r="24" />
        <rect x="85" y="77" rx="0" ry="0" width="206" height="17" />
        <circle cx="39" cy="150" r="24" />
        <rect x="85" y="137" rx="0" ry="0" width="206" height="17" />
        <circle cx="39" cy="210" r="24" />
        <rect x="85" y="197" rx="0" ry="0" width="206" height="17" />
        <circle cx="39" cy="270" r="24" />
        <rect x="85" y="257" rx="0" ry="0" width="206" height="17" />

        <rect x="115" y="300" rx="0" ry="0" width="150" height="17" />

    </ContentLoader>
)

export default LastOperationSkeleton