import "./SideMenu.scss"
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";

const activeStyle = {
    textDecoration: "none",
    backgroundColor: "#FFEAE9",
    borderLeft: "5px solid red",
};

const SideMenu = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation()
    const { language } = useAppSelector(state => state.changeLangSlice)

    const getStyles = (path) => {
        return path === pathname ? activeStyle : undefined;
    }

    return (
        <div className="aside-menu">
            <div className="aside-menu__wrap">
                <NavLink
                    to={`/epay/${language}/top-up-balance`}
                    style={() => getStyles("/top-up-balance")}
                    className="aside-menu__wrap-item"
                >
                    <div className="aside-menu__wrap-item-pic">
                        <img src={require("../../../img/convert-icon.svg").default} alt="convert-icon" />
                    </div>
                    <div className="aside-menu__wrap-item-text">
                        <div>{t("replenish")}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={`/epay/${language}/transaction`}
                    style={() => getStyles("/transaction")}
                    className="aside-menu__wrap-item">
                    <div>
                        <img src={require("../../../img/transactions.svg").default} alt="transaction-icon" />
                    </div>
                    <div className="aside-menu__wrap-item-textN">
                        <div>{t("transferAnother")}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={`/epay/${language}/transfer-wallet`}
                    state={{fromSideMenu: true}}
                    style={() => getStyles("/transfer-wallet")}
                    className="aside-menu__wrap-item"
                >
                    <div>
                        <img src={require("../../../img/wallet-to-card.svg").default} alt="ewallet-icon" />
                    </div>
                    <div className="aside-menu__wrap-item-textN">
                        <div>{t("transferW2C")}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={`/epay/${language}/identification`}
                    style={() => getStyles("/identification")}
                    className="aside-menu__wrap-item">
                    <div>
                        <img src={require("../../../img/secure.svg").default} alt="secure-icon" />
                    </div>
                    <div className="aside-menu__wrap-item-textN">
                        <div>{t("goThrough")}</div>
                    </div>
                </NavLink>
            </div>
        </div>
    );
};

export default SideMenu;
