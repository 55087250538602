import React from "react";
import ContentLoader from "react-content-loader";

const BillsSkeleton = () => (
  <ContentLoader
    speed={2}
    width={800}
    height={75}
    viewBox="0 0 800 75"
    backgroundColor="#f5f5f4"
    foregroundColor="#efebeb"
  >
    <circle cx="28" cy="37" r="25" />
    <rect x="60" y="25" rx="0" ry="0" width="110" height="10" />
    <rect x="60" y="45" rx="0" ry="0" width="190" height="10" />
    <rect x="370" y="27" rx="0" ry="0" width="90" height="20" />
    <rect x="600" y="24" rx="13" ry="13" width="100" height="30" />
    <rect x="730" y="37" rx="0" ry="0" width="30" height="5" />
  </ContentLoader>
);

export default BillsSkeleton;
