import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {changeLangAction} from "../../redux/reducers/changeLangAction";
import {signOut} from "../../redux/actions/authActions";
import "./Header.scss";
import ModalButtons from '../ModalButtons/ModalButtons';
import BasketButton from './BasketButton';
import useLastPathSegment from "../App/useLastPathSegment";

const lang = require("../../img/lang.svg").default;
const card = require("../../img/card 2.svg").default;
const w2c = require("../../img/wallet-to-card.svg").default;
const legal = require("../../img/legal.svg").default;
const quest = require("../../img/quest.svg").default;
const cart = require("../../img/cart.svg").default;
const exitIcon = require("../../img/exit-icon.svg").default;
const registerIcon = require("../../img/auth-icon.svg").default;
const logoEpul = require("../../img/logo-EPUL.svg").default;

const HeaderCustom = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const [showBurger, setShowBurger] = useState(false);
    const [openExitModal, setOpenExitModal] = useState(false);
    const [dynamicLink, setDynamicLink] = useState('');
    const burgerRef = React.useRef(null);
    const {profile, isAuth} = useAppSelector((state) => state.authReducer);
    const {language} = useAppSelector((state) => state.changeLangSlice);

    useEffect(() => {

        function hand(event: any) {
            if (
                burgerRef.current &&
                !(burgerRef.current as HTMLElement).contains(event.target)
            ) {
                setShowBurger(false);
            }
        }

        document.body.addEventListener("click", hand);

        return () => {
            document.removeEventListener("click", hand);
        };
    }, []);

    const handleCloseLogout = () => setOpenExitModal(false);

    const handleSignOut = () => {
        dispatch(signOut());
        navigate(`/epay/${language}/home`);
        setOpenExitModal(false);
    };

    const setLanguage = (lang: any) => {
        const currentPath = location.pathname;
        const newPath = currentPath.replace(/^\/epay\/[a-zA-Z]+/, `/epay/${lang}`);
        navigate(newPath);
        changeLang(lang);
        i18n.changeLanguage(lang, (err, t) => {
            if (err) return console.log("something went wrong loading", err);
            t("key");
        });
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(event.target.value);
    };

    useEffect(() => {
        if (language) {
            handleLanguageChange()
        }
    }, [location, language]);

    const handleLanguageChange = () => {
        const currentURL = location.pathname;
        const startIndex = currentURL.indexOf(`/${language}`) + 3;
        const extractedPart = currentURL.slice(startIndex);
        setDynamicLink(extractedPart)
        document.documentElement.lang = language;
    }

    const changeLang = (language: string) => {
        let langKey: string;
        if (language === "ru") {
            langKey = "valueRu";
        } else if (language === "az") {
            langKey = "valueAz";
        } else langKey = "valueEn";
        dispatch(changeLangAction(language, langKey));
    };

    const handleC2CNavigation = () => {
        navigate('/epay/ru/c2c');
        setShowBurger(false)
    }
    const morePage = useLastPathSegment('/more-page');
    return (
        <div className="header-wrapper-head">
            <div className="header-buttons">
                <div ref={burgerRef} className="hamburger-menu" style={{marginTop: morePage === '/more-page' ? "15px" : "0"}}>
                    <div
                        onClick={() => setShowBurger(!showBurger)}
                        className="menu__btn"
                    >
                        <span></span>
                    </div>
                    {<ul className={`menu__box ${showBurger ? "menu__box--open" : ""}`}>
                        <li>
                            <NavLink className="menu__item" to="#">
                                <img src={lang} alt="menu__item" loading={"lazy"} width={38} height={38}/>
                                {t("changinglang")}
                            </NavLink>
                        </li>
                        <li>
                            <div className="lang-select">
                                <a href={`/epay/ru${dynamicLink}`}
                                   className={`option ${language === 'ru' ? 'selected' : ''}`}>Ru</a>
                                <a href={`/epay/az${dynamicLink}`}
                                   className={`option ${language === 'az' ? 'selected' : ''}`}>Az</a>
                                <a href={`/epay/en${dynamicLink}`}
                                   className={`option ${language === 'en' ? 'selected' : ''}`}>En</a>
                            </div>
                        </li>
                        <li className="dividing-line">
                            <div
                                className="menu__item"
                                onClick={handleC2CNavigation}
                            >
                                <img src={card} alt="menu" loading={"lazy"} width={38} height={38}/>
                                {t("transferfromc2c")}
                            </div>
                        </li>
                        {isAuth ? <li className="dividing-line">
                            <Link
                                to={`epay/${language}/transfer-wallet`}
                                className="menu__item"
                                onClick={() => setShowBurger(false)}
                            >
                                <img src={w2c} alt="menu" loading={"lazy"} width={38} height={38}/>
                                {t("transferfromW2C")}
                            </Link>
                        </li> : null}
                        <li>
                            <Link
                                to={`epay/${language}/faq`}
                                className="menu__item"
                                onClick={() => setShowBurger(false)}
                            >
                                <img src={quest} alt="menu" loading={"lazy"} width={38} height={38}/>
                                {t("questionsAnswers")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`epay/${language}/catalogue`}
                                className="menu__item"
                                onClick={() => setShowBurger(false)}
                            >
                                <img src={cart} alt="menu" loading={"lazy"} width={38} height={38}/>
                                {t("marketplace")}
                            </Link>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.e-pul.az/epay/resources/pdf/terms_android.pdf"
                                className="menu__item"
                                onClick={() => setShowBurger(false)}
                                rel="noreferrer"
                            >
                                <img src={legal} alt="userAgreement" loading={"lazy"} width={38} height={38}/>
                                {t("userAgreement")}
                            </a>
                        </li>
                        {isAuth ? (
                            <>
                                <li>
                                    <div
                                        className="menu__item"
                                        onClick={() => {
                                            setOpenExitModal(true)
                                            setShowBurger(false)
                                        }}
                                    >
                                        <img src={exitIcon} alt="logout" loading={"lazy"} width={38} height={38}/>
                                        <div style={{fontSize: "14px", cursor: "pointer"}}>
                                            {t("logout")}
                                        </div>
                                    </div>
                                </li>
                            </>
                        ) : (
                            <li className="dividing-line">
                                <Link
                                    to={`epay/${language}/sign/in`}
                                    className="menu__item"
                                    onClick={() => {
                                        setShowBurger(false);
                                    }}
                                >
                                    <img
                                        src={registerIcon}
                                        alt="registerIcon"
                                        loading={"lazy"}
                                        width={26} height={28}
                                        style={{paddingRight: 0, paddingLeft: 10, cursor: "pointer"}}
                                    />
                                    <span className="login-register">
                            {" "}
                                        {t("logIn")} / {t("registration")}{" "}
                        </span>
                                </Link>
                            </li>
                        )}
                    </ul>
                    }
                </div>
                <div className='basket-mobile'>
                    <BasketButton/>
                </div>
                {isAuth &&
                    <div className='bonuses-mobile'>
                        <Link to={`/epay/${language}/bonuses`}>
                            <span>{profile?.bonusValue}</span>
                            <img
                                src={require("../../img/present.svg").default}
                                alt="Bonus Icon"
                            />
                        </Link>
                    </div>
                }
            </div>
            <div style={{margin: "0 auto"}}>
                <div className="header-logo">
                    <NavLink to={`/epay/${language}/home`} className="logoWrap-image">
                        <img className="logo" src={logoEpul} alt="Logo" loading={"lazy"}  width={122} height={60}/>
                        <div className="online-text">{t("onlinePaySistem")}</div>
                    </NavLink>
                    <NavLink to={`/epay/${language}/home`} className="logoWrapCenter">
                        <img src={logoEpul} alt="Logo" className="logoCenter" loading={"lazy"}  width={122} height={60}/>
                        <div className="online-text-center">{t("onlinePaySistem")}</div>
                    </NavLink>
                </div>
            </div>
            {
                <div>
                    <CustomModal active={openExitModal} setActive={setOpenExitModal}>
                        <ModalButtons text={'quitProfile'} buttonText={'logOut'} handleInstruction={handleSignOut} handleCancel={handleCloseLogout}/>
                    </CustomModal>
                </div>
            }

        </div>
    );
};

export default HeaderCustom;
