import React, {FC, useEffect} from 'react';
import "./index.scss"

import {useDispatch} from "react-redux";
import { AppDispatch } from '../../../redux/store';
import {useAppSelector} from "../../../hooks/redux";
import {IAutoOrderField, IItems} from '../../../models/AutoPayments';
import { autoPaymentsListSliceActions } from "../../../redux/slices/autoPaymentsSlice";
import {Link, useNavigate} from 'react-router-dom';
import {getPrice} from "../../../helper";
import moment from "moment";
import Switch from '@mui/material/Switch';
import {changeAutoPaymentStatus, fetchAutoPayments} from "../../../redux/actions/autoPayments";
import {Category, ICategories, Service} from "../../../models/Categories";
import {BASE_URL} from "../../../axios";
import {useTranslation} from "react-i18next";

type AutoPaymentItemProps = {
    item?: IItems;
    categories?: Category[];
}
const week: any = {
    'MON': 1,
    'TUES': 2,
    'WED': 3,
    'THRUS': 4,
    'FRI': 5,
    'SAT': 6,
    'SUN': 7,
}
const itemImage = require("../../../img/pic.svg").default;
const itemIcon = require("../../../img/info-color-orange.svg").default;
const azercellId = 976;

const AutoPaymentItem: FC<AutoPaymentItemProps> = ({ item, categories }) => {
    const {t} = useTranslation()
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { language } = useAppSelector((state) => state.changeLangSlice);

    const getNearDay = () => {
        let nearDay: any = '';
        let currentClock = moment().format('HH:MM')

        switch (item?.repeatingType) {
            case 1: {
                if(currentClock < item?.periods?.autoOrderPeriods[0]?.atClock) {
                    nearDay = moment().format('DD.MM.YYYY')
                } else {
                    nearDay = moment().add(1, 'days').format('DD.MM.YYYY')
                }
                return nearDay;
            }
            case 2: {
                let currentWeekDay = moment().isoWeekday();
                let temp = currentWeekDay;

                item?.periods?.autoOrderPeriods?.forEach((period) => {
                    if(currentWeekDay < week[period?.day]) {
                        currentWeekDay = week[period?.day]
                    }
                })

                nearDay = moment().isoWeekday(currentWeekDay).format('DD.MM.YYYY')
                return nearDay;
            }
            case 3: {
                const today = new Date();
                const todayDay = today.getDate();
                const selectedDay = item?.periods?.autoOrderPeriods[0]?.day;

                if (todayDay <= Number(selectedDay)) {
                    nearDay = moment(today.setDate(Number(selectedDay))).format('DD.MM.YYYY');
                } else if(todayDay > Number(selectedDay)) {
                    nearDay = moment(today.setFullYear(
                        today.getFullYear(),
                        today.getMonth() + 1,
                        Number(selectedDay)
                    )).format('DD.MM.YYYY');
                }
                return nearDay;
            }
            default:
                return nearDay;
        }
    }

    const getItemInfo = item?.fields?.autoOrderField?.map((field: IAutoOrderField, index: number, arr) => {
            if(field?.serviceId === azercellId) {
                if(index === 1) {
                    return arr[index -1]?.value + field?.value;
                }
            } else {
                return field?.value;
            }
    });

    const changeItemStatus = () => {
        if(item?.isActive === 1) {
            dispatch(changeAutoPaymentStatus({id: item?.id, status: 0})).then((res) => {
                if(res?.payload?.status === 200) {
                    dispatch(fetchAutoPayments());
                }
            })
        } else {
            dispatch(changeAutoPaymentStatus({id: item?.id, status: 1})).then((res) => {
                if(res?.payload?.status === 200) {
                    dispatch(fetchAutoPayments());
                }
            })
        }
    }

    const onClickToItem = async() => {
        // await dispatch(autoPaymentsListSliceActions.setSelectedItem(item));
        // dispatch(autoPaymentsListSliceActions.setEditItemData({
        //     id: item?.id,
        //     repeatingType: item?.repeatingType.toString(),
        //     periods: item?.periods?.autoOrderPeriods?.map((item: any) => item.day)
        // }))

        await navigate(
            `/epay/${language}/edit-payment/${item?.id}`,
            {
                state: {isEdit: true, id: item?.id}
            }
        )
    }

    const getItemImage = () => {
        let friendlyName = '';
        let iconHash = '';
        const  findCategory = categories?.find((category: Category) => category?.id === item?.categoryId)

        findCategory?.services?.service?.forEach((service: any) => {
            if(service.id === item?.serviceId ) {
                friendlyName = service.friendlyName;
                iconHash = service.iconHash;
            }
        })

        return `https://www.e-pul.az/epul_api/images/${friendlyName}.png`

    }


    return (
        <div className="auto-payment">
            <div className="auto-payment__image-wrapper">
                <img className="auto-payment__image" src={getItemImage()} alt="image" />
            </div>
            <div className="auto-payment__payment-info">
                <div className="auto-payment__payment-title">
                    {getItemInfo}
                </div>
                <div className="auto-payment__payment-subtitle">
                    {item?.localName?.valueRu}
                </div>
            </div>
            <div className="auto-payment__payment-price">
                <span className="auto-payment__price-value">
                    {getPrice(item?.amount)}
                </span>
                {' AZN'}
            </div>
            <div className="auto-payment__payment-date">

                <div className="auto-payment__date-title">{t("nearestPayment")}</div>
                <div className="auto-payment__date-subtitle">
                    {getNearDay()}
                </div>
            </div>
            <div className="auto-payment__payment-switch">
                <Switch checked={item?.isActive === 1} color="warning" onChange={changeItemStatus}/>
            </div>
            <img className="auto-payment__icon" src={itemIcon} alt="icon" onClick={() => onClickToItem()}/>
        </div>
    )
};

export default AutoPaymentItem;
