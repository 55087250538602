import React, {useEffect} from 'react';
import "./index.scss"
import {useTranslation} from "react-i18next";

const Blog = () => {
    const {t} = useTranslation()
    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])
    return (
        <div className="blog">
            {t("blog")}
        </div>
    );
};

export default Blog;