import React from "react"
import ContentLoader from "react-content-loader"

const OtherPaymentSwiperSkeleton = () => (
    <ContentLoader
        speed={2}
        width={130}
        height={500}
        viewBox="0 0 130 500"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="20" y="18" rx="0" ry="0" width="93" height="26"/>
        <circle cx="66" cy="84" r="23"/>
        <rect x="33" y="123" rx="0" ry="0" width="66" height="6"/>
        <circle cx="65" cy="177" r="23"/>
        <rect x="32" y="216" rx="0" ry="0" width="66" height="6"/>
        <circle cx="66" cy="271" r="23"/>
        <rect x="33" y="310" rx="0" ry="0" width="66" height="6"/>
        <circle cx="65" cy="364" r="23"/>
        <rect x="32" y="403" rx="0" ry="0" width="66" height="6"/>
        <circle cx="66" cy="458" r="23"/>
        <rect x="33" y="497" rx="0" ry="0" width="66" height="6"/>
        <circle cx="65" cy="551" r="23"/>
        <rect x="32" y="590" rx="0" ry="0" width="66" height="6"/>
        <rect x="4" y="19" rx="0" ry="0" width="3" height="500"/>
    </ContentLoader>
)

export default OtherPaymentSwiperSkeleton
