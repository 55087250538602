import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getC2qrService } from "../../redux/actions/walletAction";
import { masterPassSelector } from "../../redux/slices/masterpassSlice";
import { authSelector } from "../../redux/slices/auth";

export const useC2qrService = () => {
    const { forms } = useAppSelector(masterPassSelector);
    const { profile, isAuth } = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (profile?.user?.phone) {
          forms?.init(profile?.user?.phone);
          dispatch(getC2qrService({ sid: token, phone: profile?.user?.phone }));
        }
      }, [dispatch, forms, isAuth, profile?.user?.phone, token]);
}