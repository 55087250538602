import React from "react"
import ContentLoader from "react-content-loader"

const MediaLessHistorySkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={80}
        viewBox="0 0 640 80"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <rect x="6" y="70" rx="0" ry="0" width="100%" height="4" />
        <circle cx="40" cy="34" r="29" />
        <rect x="80" y="18" rx="0" ry="0" width="145" height="8" />
        <rect x="80" y="38" rx="0" ry="0" width="140" height="8" />
        <rect x="500" y="24" rx="0" ry="0" width="89" height="18" />
        <rect x="600" y="29" rx="0" ry="0" width="22" height="7" />
    </ContentLoader>
)

export default MediaLessHistorySkeleton