import React, {FC, useEffect, useState, useRef, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import SideMenu from "../SideMenu/SideMenu";
import './index.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getSimaQrCode} from "../../../redux/actions/walletAction";
import QRCode from "react-qr-code";
import {LoadingLocal} from "../../Loading/Loading";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";

interface IProps {

}

const SimaIdentification: FC<IProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const goBack = () => navigate(`/epay/${language}/identification`)
    const [url, setUrl] = useState('')
    const {isLoading} = useAppSelector(state => state.wallet);
    const [qrOpen, setQrOpen] = useState(false);

    useEffect(() => {
        dispatch(getSimaQrCode()).then((res: any) => {
            console.log(res.payload);
            if(res.payload.status === 200) {
                if(res.payload.data.message === 'Success') {

                    console.log('res.payload.data.url', res.payload.data.url);
                    setUrl(res.payload.data.url)
                }
            }
        })
    }, [dispatch])


    return (
        <LayoutEmpty goBack={goBack} navTitle={`${t("goThrough")}`}>
            <div className="sima__body">
                <div className="sima__content">
                    <div className="sima__title">SIMA</div>
                    <div className="sima__subtitle">{t('passIdentificationSIMA')}</div>
                    <div className="sima__docs">
                        <div className="sima__docs-box">
                            <div className="sima__docs-icon">
                                <img className="sima__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />
                            </div>
                            <div className="sima__docs-text">
                                {t('openSimaApp')}
                            </div>
                        </div>
                        <div className="sima__docs-box">
                            <div className="sima__docs-icon">
                                <img className="sima__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />
                            </div>
                            <div className="sima__docs-text">
                                {t('clickOnScan')}
                            </div>
                        </div>
                        <div className="sima__docs-box">
                            <div className="sima__docs-icon">
                                <img className="sima__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />
                            </div>
                            <div className="sima__docs-text">
                                {t('scanQr')}
                            </div>
                        </div>
                        <div className="sima__docs-box">
                            <div className="sima__docs-icon">
                                <img className="sima__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />
                            </div>
                            <div className="sima__docs-text">
                                {t('loginAgain')}
                            </div>
                        </div>
                    </div>
                    <div className="sima__btn" onClick={() => setQrOpen((pr) => !pr)}>
                        {qrOpen ? t('close') : t('passIdentification')}
                    </div>
                    <div className="sima__qr">
                        {isLoading ? <LoadingLocal /> : <QRCode size={357} value={url}/>}
                    </div>
                    {qrOpen ?  <div className="sima__qr-small">
                        {isLoading ? <LoadingLocal /> : <QRCode size={280} value={url}/>}
                    </div> : null}


                </div>
                <div className="sima__left">
                    <SideMenu/>
                </div>
            </div>
        </LayoutEmpty>

    )
}

export default SimaIdentification;