import * as React from 'react';
import { FC } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import "./index.scss"
import { useAppSelector } from '../../hooks/redux';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 120,
    borderRadius: 2,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2.75,
};

type Props = {
    handleOpen?: () => void,
    open?: any,
    handleClose?: () => void,
    setOpen?: any,
    title?: string,
    newTitle?: string,
    historyTitle?: string,
    path?: string
}

const AutoPaymentModal: FC<Props> = ({ path, handleClose, open, title, newTitle, historyTitle }) => {
    const { language } = useAppSelector((state): any => state.changeLangSlice)

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className="modal-auto-pay"
                // sx={style}
                >
                    <div className="auto-payment-modal">
                        <div className="auto-payment-modal__title">{title}</div>
                        <div
                            onClick={handleClose}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                        >
                            <img className="auto-payment-modal__title-img" src={require("../../img/close-icon.svg").default} />
                        </div>
                    </div>
                    <div className="auto-payment-modal__body">
                        <Link to={`/epay/${language}/category/add-favorite`} state={{
                            savedPath: "AddFavorite"
                        }} className="auto-payment-modal__body-item-new">{newTitle}</Link>
                        {/*<div className="auto-payment-modal__body-line"></div>*/}
                        {/*<Link to='/history' className="auto-payment-modal__body-item">{historyTitle}</Link>*/}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default AutoPaymentModal;
