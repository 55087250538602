import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentFields from "../../Components/PaymentFields";
import { addToFavoritesFetch } from "../../redux/actions/favoritesListAction";
import { AppDispatch } from "../../redux/store";

import Button from "../../Components/Button/Button";
import LayoutEmpty from "../../Components/layouts/LayoutEmpty/LayoutEmpty";
import { fetchWithTimeout } from "../../utils/query";
import { baseURL } from "../../http";
import { notify } from "../../helper";

import "./AddFavorite.scss";
import { useTranslation } from "react-i18next";
import client from "../../axios";
import { useAppSelector } from "../../hooks/redux";
//TODO: fix style to BEM

const AddFavorite = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [fields, setFields] = useState<any>([]);
  const [groupId, setGroupId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  const navigate = useNavigate();

  const { state } = location;

  const { service } = state;

  const dispatch = useDispatch<AppDispatch>();

  const isAuthToken = localStorage.getItem("token") || "";
  const { language } = useAppSelector((state) => state.changeLangSlice);

  const checkIfUserExist = async (
    sessionId: string,
    serviceId: number,
    fields: string,
    selectedGroupId: number
  ) => {
    setIsLoading(true);
    try {
      // const response = await fetchWithTimeout(
      //   `${baseURL}categories/client_info_with_group_id?fields=${encodeURIComponent(
      //     fields
      //   )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}&sessionId=${sessionId}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "web",
      //     },
      //   }
      // );
      const response = await client.get(
        `categories/client_info_with_group_id?fields=${encodeURIComponent(
          fields
        )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}&sessionId=${sessionId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.warn("response/", response);
      if (response.status === 200) {
        // const json = await response.json();
        // console.warn("json", json);
        if (response.data?.resultCodes === "OK") {
          await dispatch(
            addToFavoritesFetch({
              comment,
              serviceId: service?.id,
              selectedGroupId: groupId,
              fields,
            })
          );
          navigate(`/epay/${language}/favorites`);
        } else if (
          response.data?.resultCodes === "NOT_FOUND" ||
          response.data?.resultCodes === "INVALID_PARAMETERS"
        ) {
          notify(t("somethingWentWrong"), false);
        }
      } else {
        console.warn("error");
        notify(t("somethingWentWrong"), false);
      }
    } catch (e) {
      console.warn("error111", e);
      notify(t("somethingWentWrong"), false);
    } finally {
      setIsLoading(false);
    }
  };

  const addToFavoriteBtnClickHandler = () => {
    const fieldsJSON = JSON.stringify({ fields });

    checkIfUserExist(isAuthToken, service.id, fieldsJSON, groupId || 0);
  };

  // if (isLoading) return <>...</>

  return (
    <LayoutEmpty goBack={() => navigate(-1)}>
      <div className="add-favorite">
        <div className="add-favorite__content">
          <PaymentFields
            service={service && service}
            onValueChange={(data: any) => {
              data.hasOwnProperty("fields") && setFields(data.fields);
              data.hasOwnProperty("groupId") && setGroupId(data.groupId);
            }}
            azercell={service?.id === 976}
          />
          <label className="add-favorite__comment-label">{t('comment')}</label>
          <input className="add-favorite__comment" value={comment} onChange={(event => setComment(event.target.value))}/>

          <div className="add-favorite__btn" style={{ width: "100%" }}>
            {isLoading && (
              <div style={{ position: "relative", top: 15, right: 60 }}>
                <div className="spin"></div>
              </div>
            )}

            <Button
              disabled={isLoading}
              buttonText={t("addToFavorites")}
              background={"#EF5C27"}
              color={"white"}
              // minWidth={330}
              width={"100%"}
              border={"white 1px solid"}
              borderRadius={15}
              padding={"14px 66px"}
              margin="0 0 50px 0"
              onClick={() => {
                addToFavoriteBtnClickHandler();
              }}
            />
          </div>
        </div>
      </div>
    </LayoutEmpty>
  );
};

export default AddFavorite;
