import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";

const BasketButton = () => {
    const loaderGif = require('../../img/loading.svg').default;
    const [basketSize, setBasketSize] = useState(0);
    const {language} = useAppSelector((state) => state.changeLangSlice);
    const isAuth = useAppSelector((state) => state.authReducer.isAuth);
    //@ts-ignore
    const { basket, isLoading } = useAppSelector((state) => state.basketSlice);

    useEffect(() => {
        setBasketSize(basket?.cart?.cartItems?.length)
    }, [basket]);

    return (
        <div className="basket-button">
            <div className={`header-right-count ${isAuth ? 'auth-count' : 'notauth-count'}`}>
                {isLoading ? 
                <img
                    className="loader"
                    src={loaderGif}
                    alt="loader"
                />
                : (basketSize || 0)
                }
            </div>
            <Link to={`/epay/${language}/basket`}
                className="header-right-basket"
                style={{marginRight: isAuth ? 10 : 20}}>
                <img
                    className="header-right-basket-image"
                    src={require("../../img/basket-icon.svg").default}
                    alt="Basket"
                />
            </Link>
        </div>
    )
}

export default BasketButton;