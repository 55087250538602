import {IBonuses} from "../../models/Bonuses";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchBonuses, fetchBonusGetDetail} from "../actions/bonusesAction";
import { IBonusGift } from "../../models/BonusStore";

interface InitialState {
    sid: string,
    error: string,
    bonList: IBonuses | null,
    isLoading: boolean
    bonus:IBonusGift | null
}

const initialState: InitialState = {
    sid: "",
    error: "",
    bonList: null,
    isLoading: false,
    bonus:null,
}

export const bonListSlice = createSlice({
    name: "bonList",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchBonuses.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBonuses.fulfilled.type]: (state, action: PayloadAction<IBonuses>) => {
            state.isLoading = false
            state.error = ""
            state.bonList = action.payload
        },
        [fetchBonuses.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
        [fetchBonusGetDetail.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBonusGetDetail.fulfilled.type]: (state, action: PayloadAction<IBonusGift>) => {
        console.log(`fetchBonusGetDetail`, action)
            state.isLoading = false
            state.error = ""
            state.bonus = action.payload
        },
        [fetchBonusGetDetail.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export default bonListSlice.reducer