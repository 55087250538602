import React, { FC, useEffect, useState } from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import Button from "../Button/Button";
import {useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import HeaderCustom from "./HeaderCustom";
import "./Header.scss";
import BasketButton from "./BasketButton";

const loading = require("../../img/loading.mp4");
const setting = require("../../img/setting.svg").default;
const authIcon = require("../../img/authIcon.svg").default;

const color = '#EF5C27'

type Props = {
    isToken?: any;
    children?: JSX.Element;
};

const Header: FC<Props> = ({children, isToken}) => {
    const isAuth = useAppSelector((state) => state.authReducer.isAuth);
    const {userAvatarSource} = useAppSelector((state) => state.authReducer);
    const {language} = useAppSelector((state) => state.changeLangSlice);
    const {t} = useTranslation();
    const { profile, userAvatar } = useAppSelector((state) => state.authReducer);

    return (
        <header className="header-wrapper">
            <div className="header">
                <HeaderCustom/>
                <div className="header-right">
                    <BasketButton/>
                    {isAuth ? (
                        <div className="header__auth-wrapper">
                            <NavLink to="#" className="auth-icon">
                                {
                                    userAvatar ?
                                        <video className="auth-icon-image" autoPlay={true} loop={true}>
                                            <source src={loading} type="video/mp4"/>
                                        </video>
                                        : <img
                                            src={userAvatarSource || authIcon}
                                            alt="avatar"
                                            className="auth-icon-image"
                                        />
                                }
                            </NavLink>
                            <div className="header__auth">
                                <div className="header__auth-name">
                                    {(profile?.user.firstName && `${profile?.user.firstName}`) ||
                                        ""}
                                </div>
                                {/*<div className='header__auth-name'>{"Daşdemir Cəfərov"}</div>*/}
                                <div className="header__auth-setting">
                                    <Link to={`/epay/${language}/settings`} className="header__auth-setting__title">
                                        <img
                                            style={{marginRight: '2px'}}
                                            src={setting} alt="Setting"/>
                                        <span>{t("settings")}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <NavLink to={`/epay/${language}/sign/in`} style={{textDecoration: "none"}}>
                                <Button
                                    buttonText={`${t("logIn")}`}
                                    color="white"
                                    background={color}
                                    border="1px solid #fff"
                                    padding="17px 30px"
                                    borderRadius={15}
                                    margin="0 10px 0 0"
                                />
                            </NavLink>
                            <NavLink to={`/epay/${language}/sign/up`} style={{textDecoration: "none"}}>
                                <Button
                                    buttonText={`${t("registration")}`}
                                    color="#EF5C27"
                                    background="white"
                                    border="1px solid #fff"
                                    padding="17px 30px"
                                    borderRadius={15}
                                />
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
            <div className="header-foot">{children && children}</div>
        </header>
    );
};

export default Header;
