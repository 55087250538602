import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './index.scss'
import Button from "../../Components/Button/Button";
import InputFieldComponent from "../../Components/MyCardComponent/InputField/InputField";
import { useDispatch } from "react-redux";
import { GuestActionCreators } from "../../redux/reducers/guest/action-creators";
import { getInvoice } from "../../redux/actions/walletAction";
import { useLocation } from "react-router-dom";
import { formatDate, getTime } from "../../utils/functions";
import { LoadingLocal } from "../../Components/Loading/Loading";
import { useAppSelector } from "../../hooks/redux";
import { fetchTokenize } from "../../redux/actions/tokenize";

const InvoiceTopUp = () => {
	const {t} = useTranslation()
	const [instrument, setInstrument] = useState(null);
	const [invoiceInfo, setInvoiceInfo] = useState(null);
	const dispatch = useDispatch();
	const location = useLocation();
	const { isLoading } = useAppSelector((state) => state.wallet);
	const { language } = useAppSelector(state => state.changeLangSlice)
	const { browserToken } = useAppSelector(state => state.browserToken)

	useEffect(() => {
		const path = location.pathname;
		const invoiceUnique = path.substring(path.lastIndexOf("/") + 1);
		dispatch(getInvoice(invoiceUnique)).then((res) => {
			const invoice = res.payload.data.items.invoice[0];
			console.log('test1', invoice);
			setInvoiceInfo(invoice);
		})
	}, [])

  const payment = async () => {
		const cardDataSpited = instrument.newCardData.expiryDate.split('/')
		const cardData = {
			CardHolder: 'e-pul',
			Cvv2: instrument.newCardData.cvc,
			ExpiryMonth: +cardDataSpited[0].trim(),
			ExpiryYear: +cardDataSpited[1].trim(),
			Pan: instrument.newCardData.cardNumber,
		}

		const { payload: cardToken } = await dispatch(
			fetchTokenize({
				body: {
					CardData: cardData,
				},
				browserToken: browserToken,
			}),
		)
		
		const url = await dispatch(GuestActionCreators.doInvoicePay(
			4,
			cardToken,
			invoiceInfo.amount,
			"az",
			invoiceInfo.historyId,
			`${window.location.origin}/epay/${language}/result/payment/check`,
			invoiceInfo.invoiceUnique
		))
		if (url) {
			window.location = url;
		}
  }
  
  return (
    <div className="invoice-container">
      {isLoading ? 
        <div className="card-loading"><LoadingLocal /></div>
        :
        <>
          {invoiceInfo ?
            <div className="">
              <p className="invoice-container__title">{t("invoice")}</p>
              <div className="invoice-container__check">
								<div className="invoice-container__check__row">
									<div className="invoice-container__check__row__left">{t("invoiceId")}:</div>
									<div className="invoice-container__check__row__right">{invoiceInfo.invoiceUnique}</div>
								</div>
								<div className="invoice-container__check__row">
									<div className="invoice-container__check__row__left">{t("recipient_")}:</div>
									<div className="invoice-container__check__row__right">{invoiceInfo.senderFullName}</div>
								</div>
								<div className="invoice-container__check__row">
									<div className="invoice-container__check__row__left">{t("amount_")}:</div>
									<div className="invoice-container__check__row__right">{invoiceInfo.amount/100 + " " + invoiceInfo.currency}</div>
								</div>
								<div className="invoice-container__check__row">
									<div className="invoice-container__check__row__left">{t("description")}:</div>
									<div className="invoice-container__check__row__right">{invoiceInfo.description}</div>
								</div>
								<div className="invoice-container__check__row">
									<div className="invoice-container__check__row__left">{t("activeTill")}:</div>
									<div className="invoice-container__check__row__right">{formatDate(invoiceInfo.activeTill) + " " + getTime(invoiceInfo.activeTill)}</div>
								</div>
            	</div>
							<div className="invoice-container__card">
								<InputFieldComponent 
									infoImage
									cardDataCb={(data) =>
										setInstrument({
											newCardData: data,
											isNewCard: true,
											isGuestCard: true,
										})
									}
								/>
							</div>
							<Button
									padding="14px 40px"
									width="100%"
									height={50}
									background="#EF5C27"
									color="#FFFFFF"
									buttonText={`${t("replenish")}`}
									borderRadius={15}
									onClick={() => payment()}
							/>
          	</div>
          : <></>
					}
        </>
      }
    </div>
  )
};

export default InvoiceTopUp;