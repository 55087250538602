import axios from 'axios';
import { BASE_URL } from '../axios';

export const baseURL = BASE_URL;

export const $public = axios.create({
  baseURL,
});

$public.interceptors.request.use((config) => {
  config.headers['Payment-Origin'] = 'web';
  return config;
});
