import {createAsyncThunk} from "@reduxjs/toolkit";
import {IBonusStore} from "../../models/BonusStore";
import client from "../../axios";

interface fetchPopularProductType {
    offset: number,
    selectedCategoryId: number,
}

export const fetchPopularProduct = createAsyncThunk('popularProduct/fetchPopularProduct',
    async (params: fetchPopularProductType, thunkAPI) => {
        try {
            const {selectedCategoryId, offset} = params;
            const limit = 6;
            const resPopularProduct = await client.get<IBonusStore>(
                `bonus/storeByLimit/${localStorage.getItem('token')}?categoryId=${selectedCategoryId}&offset=${offset}&limit=${limit}`
            )
            return resPopularProduct.data.items.bonusGift
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось загрузить")
        }
    }
)