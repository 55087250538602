import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changePassword, updateProfileEmail } from "../actions/profileActions";

interface InitialState {
  data: any;
  error: string;
  isLoading: boolean;
}

const initialState: InitialState = {
  data: null,
  error: "",
  isLoading: false,
};

export const updateProfileEmailSlice = createSlice({
  name: "updateProfileEmail",
  initialState,
  reducers: {},
  extraReducers: {
    [updateProfileEmail.pending.type]: (state) => {
      state.isLoading = true;
      state.data = null;
    },
    [updateProfileEmail.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [updateProfileEmail.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.data = null;
    },
    [changePassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [changePassword.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = "";
    },
    [changePassword.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

export default updateProfileEmailSlice.reducer;
