import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {updateProfilePhone} from "../actions/profileActions";

interface InitialState {
    data: any,
    error: string,
    isLoading: boolean
}

const initialState: InitialState = {
    data: null,
    error: "",
    isLoading: false
}

export const updateProfilePhoneSlice = createSlice({
    name: "updateProfilePhone",
    initialState,
    reducers: {},
    extraReducers: {
        [updateProfilePhone.pending.type]: (state) => {
            state.isLoading = true
            state.data = null
        },
        [updateProfilePhone.fulfilled.type]: (state, action) => {
            state.isLoading = false
            state.error = ""
            state.data = action.payload
        },
        [updateProfilePhone.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.data = null
        }
    }
})

export default updateProfilePhoneSlice.reducer