import React, {FC, useEffect, useState} from 'react';
import "./index.scss"
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import HeaderText from "../../CustomsComponents/HeaderText";
import InstallApp from "../../CustomsComponents/InstallApp";
import PaymentPeriod from "../PaymentPeriod/PaymentPeriod";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {AppDispatch} from "../../../redux/store";
import { useDispatch } from 'react-redux';
import {useAppSelector} from "../../../hooks/redux";
// import {fetchAllCategories} from "../../../redux/actions/CategoriesActions";
import moment from "moment";
import {commitRecurring, editAutoPayments, getSingleAutoPayment} from "../../../redux/actions/autoPayments";
import {toQueryParams} from "../../../utils/query";
import {autoPaymentsListSliceActions} from "../../../redux/slices/autoPaymentsSlice";
import {addFavoritesActionCreators} from "../../../redux/reducers/addFavorites/action-creators";
import {useTranslation} from "react-i18next";

interface EditPaymentPageProps {
}

const EditPaymentPage: FC = (props: EditPaymentPageProps) => {
    const {t} = useTranslation()
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { state } = location;

    const {data} = useAppSelector(state => state.categoriesReducer);
    const { cards } = useAppSelector(state => state.cardsReducer);
    const {selectedItem, editItemData} = useAppSelector(state => state.autoPaymentList);
    const { language } = useAppSelector((state) => state.changeLangSlice);

    const azercellId = 976;
    const fields = state?.fields || selectedItem?.fields?.autoOrderField;
    const serviceId = state?.service?.id || selectedItem?.serviceId;
    const findedCard = cards?.find((card: any) => card.pan === selectedItem?.maskedPan);


    //add types
    const getFieldValue = () => {
        return fields?.map((field: any, index: number, fieldsArr: any) => {
            if(azercellId === serviceId) {
                if(index === 1) {
                    return fieldsArr[index - 1].value + field.value;
                }
            } else {
                return field?.value
            }
        })
    }


    const payment ={
        paymentPan: location?.state?.cardInfo?.pan || selectedItem?.maskedPan,
        amount: location?.state?.amount || (selectedItem?.amount / 100).toFixed(),
    };
    const fieldValue = getFieldValue();

    const goBack = () => {
        navigate(`/epay/${language}/auto-payments`);
        dispatch(autoPaymentsListSliceActions.resetSelectedItemData());
    };

    const category = data?.categories?.category?.find((category: any) => selectedItem?.categoryId === category?.id);
    const mainField = selectedItem?.fields?.autoOrderField?.find((field: any) => field?.isMainField);
    const {forms} = useAppSelector(state => state.masterPassReducer)


    useEffect(() => {
        // dispatch(fetchAllCategories());
        if(params?.id) {
            dispatch(getSingleAutoPayment(params?.id))
        }

    }, [dispatch]);


    const isSaveButtonDisabled = () => {
        if(payment.amount !== (selectedItem?.amount / 100)?.toFixed() || fieldValue !== getFieldValue()?.toString()) {
            return false;
        } else {
            return true;
        }
    }



    const getPeriodData = async (periodData: any) => {
        const sessionId = localStorage.getItem('token');
        const correctFields = fields?.map((field: any) => (
            {
                id: field.id || field.fieldId.toString(),
                name: field.name,
                value: field.value,
            }
        ))
        const fieldsJSON = JSON.stringify({fields: correctFields});
        const atClock = moment(new Date()).format('HH:mm');
        const mirrorId = selectedItem?.mirrorId;
        const data = {
            amount: payment.amount,
            fields: fieldsJSON,
            atClock,
            mirrorId,
            periods: periodData?.periods,
            sessionId,
            serviceId: selectedItem?.serviceId,
            bindingId: location?.state?.cardPanId || selectedItem?.historyId,
            maskedPan: payment.paymentPan,
            paymentMode: 1,
            repeatingType: periodData?.repeatingType,
            subServiceName: selectedItem?.subServiceName,
            payCurrentDebt: false,
            maxAllowAmount: selectedItem?.maxAllowAmount,
            selectedGroupId: selectedItem?.groupId,
            subServiceValue: selectedItem?.subServiceValue,
        }

        if(location.state.isFavChecked) {
            await addFavoritesActionCreators.addFavorites({
                comment: "",
                serviceId: location.state?.service.id,
                selectedGroupId: location.state.groupId,
                fields: fields
            })
        }
        await dispatch(editAutoPayments({data, id: selectedItem?.id}));

        await forms?.editRecurring(
            selectedItem?.serviceId,
            location?.state?.cardInfo?.name || findedCard?.name,
            mirrorId,
            payment.amount,
            () => {
                dispatch(commitRecurring(mirrorId));
                navigate(`/epay/${language}/auto-payments`);
            }
        )
        await dispatch(autoPaymentsListSliceActions.resetSelectedItemData())

    }



    const onEditPayment = (isFieldEdit: boolean) => {
        const paymentUrl: any = {
            groupId: selectedItem.groupId,
            amount: (selectedItem.amount / 100).toFixed()
        };

        selectedItem?.fields?.autoOrderField?.forEach((field: any, index:  number, fieldArr: any) => {
            if(azercellId === selectedItem.serviceId) {
                if(index === 1) {
                    paymentUrl[field.name] = fieldArr[index-1].value + field.value;
                }
            } else {
                paymentUrl[field.name] = field.value;
            }
        });

        const url = toQueryParams(paymentUrl);

        // dispatch(autoPaymentsListSliceActions.setAutoPaymentsState(
        //     {
        //         id: selectedItem.id,
        //         isCreate: false,
        //         isEdit: true,
        //         btnValue: 'Редактировать',
        //         path: `/edit-payment/${selectedItem.id}`,
        //         isFieldEdit: isFieldEdit,
        //     }
        // ));
        navigate(
            `/epay/${language}/auto-payment-transaction/isEdit/${selectedItem.serviceId}?${url}`,
            {
                state: {
                    ...paymentUrl,
                    fields: selectedItem?.fields,
                    isFieldEdit,
                    id: selectedItem.id,
                }
            }
        )
    }

    return (
        <div className="edit-payment">
            <NavigationComponent title={`${t("back")}`} onClick={goBack}/>
            <div className="edit-payment__body">
                <div className="edit-payment__content">
                    <div className="edit-payment__content-title">
                        <HeaderText title={selectedItem?.localName?.valueAz}/>
                    </div>
                    <div className="edit-payment__content-box">
                        <div className="edit-payment__content-field">
                            <div className="edit-payment__content-field-title">{t("service")}</div>
                            <div className="edit-payment__content-field-value">{selectedItem?.localName?.valueAz}</div>
                        </div>
                        <div className="edit-payment__content-field">
                            <div className="edit-payment__content-field-title">{mainField?.localName?.valueRu}</div>
                            <div className="edit-payment__content-field-value">
                                    { fieldValue }
                                <img
                                    className='edit-payment__icon'
                                    src={require("../../../img/edit-icon.svg").default}
                                    onClick={() => onEditPayment(true)}
                                />
                            </div>
                        </div>
                        <div className="edit-payment__content-field">
                            <div className="edit-payment__content-field-title">{t("amountWithCommission")}</div>
                            <div className="edit-payment__content-field-value">
                                {
                                    payment.amount
                                }
                                <img
                                    className='edit-payment__icon'
                                    src={require("../../../img/edit-icon.svg").default}
                                    onClick={() => onEditPayment(false)}
                                />
                            </div>
                        </div>
                        <div className="edit-payment__content-field">
                            <div className="edit-payment__content-field-title">{t("paymentMethod")}</div>
                            <div className="edit-payment__content-field-value">
                                {
                                    payment.paymentPan
                                }
                                <img
                                    className='edit-payment__icon'
                                    src={require("../../../img/edit-icon.svg").default}
                                    onClick={() => onEditPayment(false)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="edit-payment__period-box">
                        <PaymentPeriod
                            isEdit={location.state.isEdit}
                            item={selectedItem}
                            isDisabled={isSaveButtonDisabled()}
                            getPeriodData={getPeriodData}
                            editItemData={editItemData}
                            cb={(data: any) => {
                                dispatch(autoPaymentsListSliceActions.setEditItemData(data))
                            }}
                        />
                    </div>
                </div>
                <div className="edit-payment__install-app">
                    <InstallApp/>
                </div>
            </div>
        </div>
    );
};

export default EditPaymentPage;
