import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICard } from "../../models/Card";
import {
    blockDigitalCardThunk,
    fetchCardAdd,
    fetchRemoveCard,
    fetchUpdateCard,
    getDigitalCardRequisitesThunk,
    getDigitalCardStatementsThunk,
    getDigitalCardThunk
} from "../actions/cardsActions";

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ICardsState {
    cards: ICard[],
    isLoading: boolean,
    defaultPan: string,
    digitalCard: {
        isProcessLoading: boolean,
        primaryId: any,
        card: any,
        info: any,
        requisites: any,
        customerData: any,
        statements: any
    },
    isDigitalCardLoading: boolean,
}

const initialState: ICardsState = {
    cards: [],
    isLoading: false,
    defaultPan: "",
    digitalCard: {
        isProcessLoading: false,
        primaryId: undefined,
        card: undefined,
        info: undefined,
        requisites: undefined,
        customerData: undefined,
        statements: undefined
    },
    isDigitalCardLoading: false,
}

export const cardsSlice = createSlice({
    name: "cards",
    initialState,
    reducers: {
        setDefaultPan(state, action) {
            state.defaultPan = action.payload
        },
        setCards(state, action) {
            //TODO:refactoring
            var statesC = state.cards.map((obj: any) => obj.id)

            const payload = action.payload.map((item: any) => {
                if (!item?.maskedPan) return item
                item = { ...item, pan: item.maskedPan }
                return item
            })

            payload.forEach((itemA: any) => {
                if (!(statesC.indexOf(itemA.id) > -1)) {
                    state.cards.push(itemA)
                }
            })
        },
        setResetCards(state) {
            state.cards = []
        },
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        setDigitalCard(state, action) {
            state.digitalCard.card = action.payload
        },
        setDigitalCardInfo(state, action) {
            state.digitalCard.info = action.payload
        },
        setRequisites(state, action) {
            state.digitalCard.requisites = action.payload
        },
        setStatements(state, action) {
            state.digitalCard.statements = action.payload;
        },
        removeCard(state, action) {
            const idx = state.cards.findIndex((card: any) => card.id === action.payload.CardId)
            state.cards.splice(idx, 1)
        },

        addCard(state, action) {
            state.cards.push(action.payload)
        },

        updateCard(state, action) {
            const idx = state.cards.findIndex((card: any) => card.id === action.payload.CardId)
            state.cards[idx].name = action.payload.Name
        }
    },
    extraReducers: {
        [getDigitalCardThunk.pending.type]: (state) => {
            state.isDigitalCardLoading = true
        },
        [getDigitalCardThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isDigitalCardLoading = false
        },
        [getDigitalCardThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isDigitalCardLoading = false;
        },

        [blockDigitalCardThunk.pending.type]: (state) => {
            state.digitalCard.isProcessLoading = true
        },
        [blockDigitalCardThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.digitalCard.isProcessLoading = false
        },
        [blockDigitalCardThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.digitalCard.isProcessLoading = false;
        },

        [getDigitalCardRequisitesThunk.pending.type]: (state) => {
            state.digitalCard.isProcessLoading = true
        },
        [getDigitalCardRequisitesThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.digitalCard.isProcessLoading = false
        },
        [getDigitalCardRequisitesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.digitalCard.isProcessLoading = false;
        },

        [getDigitalCardStatementsThunk.pending.type]: (state) => {
            state.digitalCard.isProcessLoading = true
        },
        [getDigitalCardStatementsThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.digitalCard.isProcessLoading = false
        },
        [getDigitalCardStatementsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.digitalCard.isProcessLoading = false;
        },

        [fetchRemoveCard.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchRemoveCard.fulfilled.type]: (state) => {
            state.isLoading = false
        },
        [fetchRemoveCard.rejected.type]: (state) => {
            state.isLoading = false;
        },


        [fetchUpdateCard.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchUpdateCard.fulfilled.type]: (state) => {
            state.isLoading = false
        },
        [fetchUpdateCard.rejected.type]: (state) => {
            state.isLoading = false;
        },


        [fetchCardAdd.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCardAdd.fulfilled.type]: (state) => {
            state.isLoading = false
        },
        [fetchCardAdd.rejected.type]: (state) => {
            state.isLoading = false;
        },

    }
})

const selectCardsState = (state: RootState) => state.cardsReducer;

export const cardsSelector = createSelector(
    [selectCardsState],
    (cardsReducer) => cardsReducer

  );

export default cardsSlice.reducer
export const cardsSliceActions = {
    ...cardsSlice.actions
};
