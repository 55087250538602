import GuestPaymentActionTypes from './types';

export const GuestPaymentActionCreators = {
  saveUrlOrder: (formUrl, historyId, orderId) => ({
    type: GuestPaymentActionTypes.SAVE_URL_ORDER,
    formUrl,
    historyId,
    orderId,
  }),
};
