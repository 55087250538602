import React, {FC, useEffect, useState} from "react";
import "./index.scss";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import Button from "../Button/Button";
import {State} from "../CustomsComponents/Notification/Notification";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {signOut} from "../../redux/actions/authActions";
import {BonusToCash} from "../../helper";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ModalButtons from "../ModalButtons/ModalButtons";

interface RegistrationModalProps {}

export const activeClassName =
  "sidebar__content-item sidebar__content-item--active sidebar__content-item--cards";

const RegistrationModal: FC = (props: RegistrationModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { walletList, isLoading } = useAppSelector((state) => state.walletList);
  const { profile, isAuth } = useAppSelector((state) => state.authReducer);
  const price =
    (walletList?.wallet.find((item) => item.currency === "AZN")?.balance || 0) /
    100;
  const currency = "AZN";
  const [selectedCurrency, setSelectedCurrency] = useState<string>("AZN");
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const { data } = useAppSelector((state) => state.generateInvitation);
  const { usdTotalDebts, aznTotalDebts } = useAppSelector(
    (state) => state.debtsList
  );
  const [openExitModal, setOpenExitModal] = useState(false);
  const [stateOpen, setStateOpen] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setOpenExitModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const handleCloseLogout = () => setOpenExitModal(false);
  const handleSignOut = () => {
    dispatch(signOut());
    navigate(`/epay/${language}/home`);
  };


  // @ts-ignore
  const messagesCount = profile?.mailBox > 99 ? "99+" : profile?.mailBox;

  return (
    <>
      <div className="sidebar">
        <div className="sidebar__header">
          <Link to={`/epay/${language}/bonuses`} className="sidebar__header-item sidebar__header-item--green">
              <div className="sidebar__header-image">
                <img
                  className="sidebar__icon"
                  src={require("../../img/bonusIcon.svg").default}
                  alt="Bonus Icon"
                />
              </div>
              <div>
                <div className="sidebar__header-title">{t("bonus")}</div>
                <div className="sidebar__header-subtitle">
                  {BonusToCash(profile?.bonusValue)}
                  <span>{isAuth ? "AZN" : currency}</span>
                </div>
              </div>
          </Link>
          <Link to={`/epay/${language}/e-wallet`} className="sidebar__header-item sidebar__header-item--purple">
              <div className="sidebar__header-image">
                <img
                  className="sidebar__icon"
                  src={require("../../img/e-payment-icon.svg").default}
                  alt="Payment Icon"
                />
              </div>
              <div>
                <div className="sidebar__header-title">{t("e-wallet")}</div>
                <div className="sidebar__header-subtitle">
                  {price}
                  <span>{isAuth ? "AZN" : currency}</span>
                </div>
              </div>
          </Link>
          <Link to={`/epay/${language}/debt`} className="sidebar__header-item sidebar__header-item--pink">
              <div className="sidebar__header-dept">
                <div className="sidebar__header-image">
                  <img
                    className="sidebar__icon"
                    src={require("../../img/credit-icon.svg").default}
                    alt="Credit Icon"
                  />
                </div>
                <div className="sidebar__header-currency-box">
                  <div
                    className="sidebar__header-currency"
                    onClick={(e) => setSelectedCurrency("USD")}
                    style={{
                      color: selectedCurrency === "USD" ? "#ff7373" : "#f5c0c0",
                    }}
                  >
                    USD
                  </div>
                  <div
                    className="sidebar__header-currency"
                    onClick={(e) => setSelectedCurrency("AZN")}
                    style={{
                      color: selectedCurrency === "AZN" ? "#ff7373" : "#f5c0c0",
                    }}
                  >
                    AZN
                  </div>
                </div>
              </div>
              <div>
                <div className="sidebar__header-title">{t("debts")}</div>
                <div className="sidebar__header-subtitle">
                  {(selectedCurrency === "AZN"
                    ? aznTotalDebts
                    : usdTotalDebts) / 100}
                  <span>{isAuth ? "AZN" : currency}</span>
                </div>
              </div>
          </Link>
        </div>
        <div className="sidebar__content">
          <Link
            to={`/epay/${language}/history`}
            className={
              pathname === `/epay/${language}/history`
                ? activeClassName
                : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/history-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("history")}</div>
          </Link>
          <Link
            to={`/epay/${language}/favorites`}
            className={
              pathname === `/epay/${language}/favorites`
                ? activeClassName
                : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/star-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("favorites")}</div>
          </Link>
          <Link
            to={`/epay/${language}/message`}
            className={
              pathname === `/epay/${language}/message`
                ? activeClassName
                : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/message-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("messages")}</div>
            {profile?.mailBox ? (
              <div className="sidebar__content-count">
                <span>{messagesCount}</span>
              </div>
            ) : null}
          </Link>
          <Link
            to={`/epay/${language}/recurring-payments`}
            className={
              pathname === `/epay/${language}/recurring-payments`
                ? activeClassName
                : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/auto-pay-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("autopayments")}</div>
          </Link>
          <Link
            to={`/epay/${language}/my-card`}
            className={
              pathname === `/epay/${language}/my-card`
                ? activeClassName
                : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/myCard-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("mycards")}</div>
          </Link>
          <Link
            to={`/epay/${language}/message`}
            className={
              pathname === `/epay/${language}/my-card`
                ? activeClassName
                : "sidebar__content-item sidebar__content-item--cards"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/message-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("messages")}</div>
          </Link>
          <Link
            to={`/epay/${language}/invite-friend`}
            className={
              pathname === `/epay/${language}/invite-friend`
                ? activeClassName
                : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/add-friend-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("inviteFriend")}</div>
          </Link>
          <Link
            to={`/epay/${language}/faq`}
            className={
              pathname === `/epay/${language}/faq` ? activeClassName : "sidebar__content-item"
            }
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/help-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("help")}</div>
          </Link>
          <div
            className={
              openExitModal ? activeClassName : "sidebar__content-item"
            }
            onClick={() => setOpenExitModal(true)}
          >
            <div className="sidebar__content-image">
              <img
                className="sidebar__icon"
                src={require("../../img/exit-icon.svg").default}
                alt=""
              />
            </div>
            <div className="sidebar__content-title">{t("logout")}</div>
          </div>
        </div>
      </div>
      <CustomModal active={openExitModal} setActive={setOpenExitModal}>
        <ModalButtons text={'quitProfile'} buttonText={'logOut'} handleInstruction={handleSignOut} handleCancel={handleCloseLogout}/>
      </CustomModal>
    </>
  );
};

export default React.memo(RegistrationModal);
