import React from 'react';
import OtherPayments from "../OtherPayments/OtherPayments";
import OtherPaymentSwiper from "../OtherPayments/OtherPaymentSwiper";

const AdaptiveRightOtherPayment = () => {
    return (
        <>
            <div className="right-component-panel">
                <OtherPayments/>
            </div>
            <div className="layout__app-otherWrap">
                <div className='layout__app-otherWrap-border'></div>
                <div className="layout__app-otherSwiper">
                    <OtherPaymentSwiper/>
                </div>
            </div>
        </>
    );
};

export default AdaptiveRightOtherPayment;
