import React, {FC, useEffect} from 'react';
import {Link, useNavigate } from 'react-router-dom'
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import "./index.scss"
import InstallApp from "../../CustomsComponents/InstallApp";
import HeaderText from "../../CustomsComponents/HeaderText";

import {useDispatch} from "react-redux";
import { AppDispatch } from '../../../redux/store';
import {fetchAutoPayments} from "../../../redux/actions/autoPayments";
import {useAppSelector} from "../../../hooks/redux";
import Loading from '../../Loading/Loading';
import { IItems } from '../../../models/AutoPayments';
import AutoPaymentItem from '../AutoPaymentItem/AutoPaymentItem';
// import {fetchAllCategories} from "../../../redux/actions/CategoriesActions";
import {useTranslation} from "react-i18next";

type Props = {
    onClick?: (event: MouseEvent) => void,
}

const AutoPayments: FC<Props> = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const goBack = () => navigate(`/epay/${language}/home`);
    const { isLoading, autoPaymentsList }  = useAppSelector(state => state.autoPaymentList);
    const { data }  = useAppSelector(state => state.categoriesReducer);
    const { language, langKey } = useAppSelector(state => state.changeLangSlice);

    const autoPayments = autoPaymentsList?.items?.autoOrder;



    const category = data?.categories?.category;

    useEffect(() => {
        dispatch(fetchAutoPayments());
        // dispatch(fetchAllCategories());
    }, []);


    return (
        <div className="auto-payments">
            <NavigationComponent onClick={goBack} title={t("home")}/>
            <div className="auto-payments__wrapper">
                <div className="auto-payments__content">
                    <div className="auto-payments__content-header">
                        <HeaderText title={t("autopayments")}/>
                        {
                            autoPayments?.length ?
                            <Link to={`/epay/${language}/create-payments`} style={{textDecoration: 'none'}}>
                                <button className='auto-payments__btn-small'>{t("createAutopay")}</button>
                            </Link> : null

                        }
                    </div>
                    <div className="auto-payments__content-body">
                        {
                            autoPayments?.length
                                ? <div className="auto-payments__items">
                                     {
                                         isLoading
                                             ? <Loading />
                                             : autoPayments?.map((item: IItems) => (
                                                 <AutoPaymentItem
                                                     key={item.id}
                                                     item={item}
                                                     categories={category}
                                                 />
                                             ))
                                     }
                                </div>
                                : <Link to={`/epay/${language}/create-payments`} style={{textDecoration: 'none'}}>
                                    <div className="auto-payments__btn">
                                        <div className="auto-payments__btn-icon">
                                            <img className="auto-payments__icon-img"
                                                 src={require("../../../img/plus-favorites-icon.svg").default} alt=""/>
                                        </div>
                                        <span className="auto-payments__btn-text">
                                             {t("createAutopay")}
                                        </span>
                                    </div>
                                </Link>

                        }
                    </div>
                </div>
                <div className="auto-payments__app-container">
                    <InstallApp/>
                </div>
            </div>
        </div>
    )
};

export default AutoPayments;
