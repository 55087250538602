import React, { FC, memo } from 'react'
import { Link } from "react-router-dom";
import { Category, LocaleName } from "../../models/Categories";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/redux";
import { categoriesInfo } from "../../consts";
import "./index.scss"

interface IServiceMain {
    [key: number | string]: IService
}

interface IService {
    name: string,
    img: any,
    backGround: string,
}



interface ServiceMainProps {
    mainServices: Category[],
}

const ServiceMain: FC<ServiceMainProps> = memo(({ mainServices }) => {
    const { t } = useTranslation()
    const { language, langKey } = useAppSelector(state => state.changeLangSlice)
    const auth = useAppSelector((state) => state.authReducer);
    const savedPath = auth.isAuth ? "new-payment" : "guest-payment"

    return (
        <div className='home'>
            {/*<div className="service-main">*/}
            <Link to={`/epay/${language}/c2c`} className="service-main__item">
                <div className="service-main__icon"
                    style={{ background: categoriesInfo[99]?.backGround }}>
                    <div className="service-main__image">
                        <img src={categoriesInfo[99].img} alt="Logo" className="service-main__img" width={43} height={38}/>
                    </div>
                </div>
                <div className="service-main__text">
                    {t(categoriesInfo[99]?.name)}
                </div>
            </Link>
            {mainServices?.map((item: Category) => {
                return <Link state={{ category: item, savedPath, processName: savedPath }}
                    to={`/epay/${language}/category/${item.name}`} key={item.id}
                    className="service-main__item">
                    <div className="service-main__icon"
                        style={{ background: categoriesInfo[item.id]?.backGround }}>
                        <div className="service-main__image">
                            <img src={categoriesInfo[item.id]?.img} alt="Logo" className="service-main__img" />
                        </div>
                    </div>
                    <div className="service-main__text">
                        {item.localName[langKey as keyof LocaleName]}
                        {/*{item.localName.valueRu}*/}
                    </div>
                </Link>
            })}
            {/*</div>*/}
        </div>
    )
})

export default ServiceMain;
