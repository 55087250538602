import React from 'react';
import ServicesSkeletonsSmall from "../Sceletons/ServicesSkeletons/ServicesSkeletonsSmall";
import ServiceSearch from "../ServiceMain/ServiceSearch";
import ServiceMain from "../ServiceMain/ServiceMain";
import {useTranslation} from "react-i18next";

interface IHomeServiceProps {
    inputString?: string,
    setInputString?: any,
    onClickClear?: any,
    hint?: any,
    isLoading?: boolean,
    filteredList?: any,
    allServices?: any
}

const HomeService: React.FC<IHomeServiceProps> = ({
                                                      hint,
                                                      isLoading,
                                                      allServices,
                                                      inputString,
                                                      onClickClear,
                                                      filteredList,
                                                      setInputString,
}) => {
    const { t } = useTranslation();

    return (
        <div className="home-page__container-wrap">
            <div className="home-page__header">
                <div className="home-page__search">
                    <button
                        className="home-page__search-icon"
                        type="submit"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   // if (inputString) {
                        //   //   navigate("/pay-communal", { state: filteredList });
                        //   // }
                        // }}
                    >
                        <img
                            width={23}
                            height={22}
                            className="home-page__icon-img"
                            src={require("../../img/search-icon.svg").default}
                            alt="search icon"
                        />
                    </button>
                    <input
                        className="home-page__input"
                        name="search"
                        placeholder={`${t("nameOfService")}`}
                        type="text"
                        value={inputString}
                        onChange={(e) => {
                            setInputString(e.target.value)
                        }}
                    />
                    {inputString?.trim() && (
                        <button
                            className="home-page__close-icon"
                            onClick={onClickClear}
                        >
                            <img
                                width={20}
                                height={20}
                                className="home-page__icon-img"
                                src={require("../../img/close-icon.svg").default}
                                alt="cross icon"
                            />
                        </button>
                    )}
                </div>
                <div className="home-page__header-example">
                    <p className="home-page__header-text">{t("forExample")}</p>
                    {hint?.map((item: any) => (
                        <span
                            className="home-page__header-infoText"
                            onClick={(e) => {
                                setInputString(item);
                            }}
                            key={item}
                        >
                    {item}
                  </span>
                    ))}
                </div>
            </div>
            {isLoading
                ? (
                    <div style={{display: "flex"}}>
                        <ServicesSkeletonsSmall/>
                        <ServicesSkeletonsSmall/>
                        <ServicesSkeletonsSmall/>
                    </div>
                ) : (
                    <>
                        <div className="home-wrapper">
                            {filteredList?.length
                                ?
                                <ServiceSearch filteredList={filteredList}/>
                                :
                                <ServiceMain mainServices={allServices}/>
                            }
                        </div>
                    </>
                )}
        </div>
    );
};

export default HomeService;
