import React from "react"
import ContentLoader from "react-content-loader"

const SettingsSkeleton = () => (
    <ContentLoader
        speed={2}
        width='100%'
        height={500}
        viewBox="0 0 1024 500"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle cx="54" cy="100" r="54" />
        <rect x="0" y="0" rx="0" ry="0" width="250" height="32" />
        <rect style={{display: 'flex'}} x="128" y="47" rx="0" ry="0" width="50%" height="32" />
        <rect style={{display: 'flex'}} x="128" y="100" rx="0" ry="0" width="50%" height="32" />
        <rect style={{display: 'flex'}} x="128" y="159" rx="0" ry="0" width="50%" height="32" />
        <rect style={{display: 'flex'}} x="128" y="232" rx="0" ry="0" width="50%" height="56" />
        <rect style={{display: 'flex'}} x="128" y="303" rx="0" ry="0" width="50%" height="56" />
        <rect style={{display: 'flex'}} x="128" y="381" rx="0" ry="0" width="50%" height="32" />
        <rect style={{display: 'flex'}} x="128" y="431" rx="0" ry="0" width="50%" height="32" />
        <rect style={{display: 'flex'}} x="657" y="47" rx="0" ry="0" width="31%" height="312" />
    </ContentLoader>
)

export default SettingsSkeleton