import React, { FC, useEffect } from "react";
import RegistrationLeftMain from "./RegistrationLeftMain/RegistrationLeftMain";
import RelaxRightInfo from "./RelaxRightInfo/RelaxRightInfo";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import LastOperation from "./LastOperation/LastOperation";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import RightMenuSkeleton from "./Sceletons/RightMenuSkeleton/RightMenuSkeleton";
import { fetchWalletList } from "../redux/actions/walletListAction";

interface LeftComponentsProps {
  withHistory?: boolean;
}

const skeletonStyles = {
  height: "max-content",
  width: "367px",
  margin: "20px 0",
  borderRadius: "15px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  padding: "0px 0 28px 5px",
};

const LeftComponents: FC<LeftComponentsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { isAuth, isLoading } = useAppSelector((state) => state.authReducer);
  const walletListLoading = useAppSelector(
    (state) => state.walletList.isLoading
  );
  const debtListLoading = useAppSelector((state) => state.debtsList.isLoading);
  const { withHistory } = props;

  // useEffect(() => {
  //   if (isAuth) {
  //     dispatch(fetchWalletList());
  //   }
  // }, [dispatch, isAuth]);
  return (
    <div className="right-component" style={{ width: "100%" }}>
      {isAuth ? (
        <>
          {isLoading || debtListLoading ? (
            <div style={skeletonStyles}>
              <RightMenuSkeleton />
            </div>
          ) : (
            <>
              <RegistrationModal />
              {withHistory ? <LastOperation /> : null}
            </>
          )}
          {/*<RegistrationModal />*/}
          {/*<LastOperation/>*/}
        </>
      ) : (
        <>
          {/*<RegistrationLeftMain />*/}
          <RelaxRightInfo />
        </>
      )}
    </div>
  );
};

export default LeftComponents;
