import React, { FC } from 'react';
import "./index.scss"
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";
import { LocaleName } from "../../../models/Categories";


interface SelectedCategoryProps {
    categories?: any;
    pathState?: {
        isAutoPayments: boolean,
    };
    path?: string;
}

export const imagesForCategories: any = {
    1: {
        id: 1,
        name: 'mobile',
        image: require('../../../img/categories/mobile-icon.svg').default,
        bg: '#FFEAE9',
    },
    2: {
        id: 2,
        name: 'utilities',
        image: require('../../../img/categories/communal-icon.svg').default,
        bg: '#F0FFD9',
    },
    3: {
        id: 3,
        name: 'internet',
        image: require('../../../img/categories/network-icon.svg').default,
        bg: '#E3EEFF',
    },
    4: {
        id: 4,
        name: 'tv',
        image: require('../../../img/categories/tv.svg').default,
        bg: '#E3EEFF',
    },
    5: {
        id: 5,
        name: 'online',
        image: require('../../../img/categories/paymend-icon.svg').default,
        bg: '#E8FFF1',
    },
    6: {
        id: 6,
        name: 'phone',
        image: require('../../../img/categories/station-mob-icon.svg').default,
        bg: '#F0FFD9',
    },
    7: {
        id: 7,
        name: 'entertainment',
        image: require('../../../img/categories/inter-icon.svg').default,
        bg: '#E8FFF1',
    },
    8: {
        id: 8,
        name: 'other',
        image: require('../../../img/categories/other-icon.svg').default,
        bg: '#E8FFF1',
    },
    9: {
        id: 9,
        name: 'banking',
        image: require('../../../img/categories/service-icon.svg').default,
        bg: '#FFF6E4',
    },
    10: {
        id: 10,
        name: 'insurance',
        image: require('../../../img/categories/insurance-icon.svg').default,
        bg: '#FFEAE9',
    },
    11: {
        id: 11,
        name: 'education',
        image: require('../../../img/categories//education-icon.svg').default,
        bg: '#E3EEFF',
    },
    12: {
        id: 12,
        name: 'penalty',
        image: require('../../../img/categories/penalty.svg').default,
        bg: '#F3EFFF',
    },
    13: {
        id: 13,
        name: 'software',
        image: require('../../../img/categories/program-icon.svg').default,
        bg: '#F3EFFF',
    },
    14: {
        id: 14,
        name: 'transport',
        image: require('../../../img/categories/transport-icon.svg').default,
        bg: '#FFF6E4',
    },
    15: {
        id: 15,
        name: 'government',
        image: require('../../../img/categories/department-service-icon.svg').default,
        bg: '#F0FFD9',
    },
    16: {
        id: 16,
        name: 'online_store',
        image: require('../../../img/categories/magazineNet-icon.svg').default,
        bg: '#FFEAE9',
    },
    17: {
        id: 17,
        name: 'parking',
        image: require('../../../img/categories/parking.svg').default,
        bg: '#F3EFFF',
    },
    18: {
        id: 18,
        name: 'hotels_and_tourism',
        image: require('../../../img/categories/hotel-icon.svg').default,
        bg: '#FFF6E4',
    },
    19: {
        id: 19,
        name: 'charity',
        image: require('../../../img/categories/charity.svg').default,
        bg: '#F3EFFF',
    },
    20: {
        id: 20,
        name: 'credit_application',
        image: require('../../../img/categories/union.svg').default,
        bg: '#E8FFF1',
    },
    41: {
        id: 41,
        name: 'gas_station',
        image: require('../../../img/categories/gas_station.svg').default,
        bg: '#F3EFFF',
    },
}


const SelectedCategory: FC<SelectedCategoryProps> = (props) => {
    const { langKey } = useAppSelector(state => state.changeLangSlice)
    const { language } = useAppSelector(state => state.changeLangSlice)

    return (
        <div className="selected-category__catalog">
            {
                props.categories?.map((item: any) => (
                    <div key={item?.id} className="selected-category__catalog-item">
                        <NavLink to={`/epay/${language}/pay-communal`} className="selected-category__catalog-item-div" state={{ category: item, path: props.path }}>
                            <div className="selected-category__catalog-item-img" style={{ backgroundColor: imagesForCategories[item?.id].bg }}>
                                <img className="selected-category__catalog-item-image" src={imagesForCategories[item?.id].image} alt="" />
                            </div>
                            <div className="selected-category__catalog-item-name">
                                <span className="selected-category__catalog-item-text">{item?.localName[langKey as keyof LocaleName]}</span>
                            </div>
                        </NavLink>
                    </div>
                ))
            }
        </div>
    );
};

export default SelectedCategory;