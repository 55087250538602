import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISelectedFavList } from "../../models/FavoritesList";
import client from "../../axios";
import {fetchWithTimeout, toQueryParams} from "../../utils/query";
import {baseURL} from "../../http";

const token = localStorage.getItem("token");

export const fetchFavoritesList = createAsyncThunk(
  "favList/fetchFavoritesList",
  async (sid: string | null | undefined, thunkAPI) => {
    try {
      const resFavList = await client.get<ISelectedFavList, any>(
        "favorites/list/" + (sid || localStorage.getItem("token"))
      );
      return resFavList.data;
    } catch (e) {
      console.log("fetchFavoritesList catch error", e);
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const addToFavoritesFetch = createAsyncThunk(
  "favList/addToFavorites",

  async (data: any, thunkAPI) => {
    try {
      const res = await client.post<any>(
        `favorites/add_with_group_id?sessionId=${localStorage.getItem("token")}&comment=${data.comment}&serviceId=${data.serviceId}&selectedGroupId=${data.selectedGroupId}&fields=${data.fields}`,

        toQueryParams(data),

        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (res.data.resultCodes === "OK") {
        thunkAPI.dispatch(fetchFavoritesList());
      }

      return res
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const editFavoritesFetch = createAsyncThunk(
    "favList/editFavorites",

    async (
        {
            id,
            comment,
            fields,
            serviceId,
            selectedGroupId
        }: {
            id: number;
            comment?: string,
            fields: string,
            serviceId: any;
            selectedGroupId: number;
        },
        thunkAPI
    ) => {
        try {
            const res = await client.post<any>(
                `favorites/edit_with_group_id/${id}?sessionId=${localStorage.getItem("token")}&${toQueryParams({
                    comment: comment || "",
                    serviceId: serviceId,
                    fields: fields,
                    selectedGroupId,
                })}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );

            if (res.data.resultCodes === "OK") {
                thunkAPI.dispatch(fetchFavoritesList());
            }
            return res
        } catch (e) {
            return thunkAPI.rejectWithValue("Не удалось загрузить");
        }
    }
);

export const deleteFromFavoritesFetch = createAsyncThunk(
  "favList/deleteFromFavorites",

  async (data: any, thunkAPI) => {
    try {
      const res = await client.delete<any>(
        `favorites/delete/${data}?sessionId=${localStorage.getItem("token")}`
      );

      if (res.data.resultCodes === "OK") {
        thunkAPI.dispatch(fetchFavoritesList());
      }
      return res
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);
