import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import LayoutWithApp from "../../layouts/LayoutWithApp/LayoutWithApp";
import HeaderText from "../../CustomsComponents/HeaderText";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {useAppSelector} from "../../../hooks/redux";
import RecurringPaymentItem from "../RecurringPaymentItem/RecurringPaymentItem";
import {IItems} from "../../../models/AutoPayments";
import {changeAutoPaymentStatus, fetchAutoPayments} from "../../../redux/actions/autoPayments";
import "./index.scss";
import AutoPaymentSkeleton from "../../Sceletons/AutopaymentServiceSkeleton/AutoPaymentSkeleton";
import Media900AutoPaymentSkeleton from "../../Sceletons/AutopaymentServiceSkeleton/Media900AutoPaymentSkeleton";
import Media530AutoPaymentSkeleton from "../../Sceletons/AutopaymentServiceSkeleton/Media530AutoPaymentSkeleton";
import Media455AutoPaymentSkeleton from "../../Sceletons/AutopaymentServiceSkeleton/Media455AutoPaymentSkeleton";
import Media340AutoPaymentSkeleton from "../../Sceletons/AutopaymentServiceSkeleton/Media340AutoPaymentSkeleton";
import {ICard} from "../../../models/Card";
import RecurringWarningModal from "../RecurringModals/RecurringWarningModal";

interface IProps {
}

const mockArr = [1, 2, 3, 4, 5, 6, 7]

const RecurringPaymentsList: FC<IProps> = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {isLoading, autoPaymentsList} = useAppSelector(
      (state) => state.autoPaymentList
  );
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const cards = useAppSelector((state) => state.cardsReducer.cards);
  const goBack = () => navigate(`/epay/${language}/home`);
  const autoPayments = autoPaymentsList?.items?.autoOrder;
  const modalMessage = t("activateAutoPayment");

  useEffect(() => {
    dispatch(fetchAutoPayments());
    // dispatch(fetchAllCategories());
  }, [dispatch]);

  useEffect(() => {
    checkRecurringAvailableForActivating()
  }, [autoPayments]);

  const checkRecurringAvailableForActivating = () => {
    autoPayments?.forEach((item) => {
      if (!item.isActive) {
        return;
      }
      const foundCard = cards?.find((card: ICard) => card.pan === item.maskedPan);

      if (!foundCard) {
        dispatch(changeAutoPaymentStatus({ id: item.id, status: item.isActive === 1 ? 0 : 1 }));
      }
    })
  }

  const createRecurring = () => {
    navigate(`/epay/${language}/category/add-recurring`, {
      state: {
        savedPath: "AddRecurring",
      },
    });
  };

  const renderItems = () => {
    return autoPayments?.length ? (
      <div className="recurring-payments__items">
        {autoPayments?.map((item: IItems) => (
          <RecurringPaymentItem
            key={item.id}
            item={item}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        ))}
      </div>
    ) : (
      <div
        className="recurring-payments__btn"
        onClick={() => createRecurring()}
      >
        <div className="recurring-payments__btn-icon">
          <img
            className="recurring-payments__icon-img"
            src={require("../../../img/plus-favorites-icon.svg").default}
            alt=""
          />
        </div>
        <span className="recurring-payments__btn-text">
          {t("createAutopay")}
        </span>
      </div>
    );
  };

  return (
    <LayoutWithApp navTitle={t("home")} goBack={goBack}>
      <div className="recurring-payments">
        <div className="recurring-payments__header">
          <HeaderText title={t("autopayments")} />
          {
            autoPayments?.length ? (
              // <Link to='/create-payments' style={{textDecoration: 'none'}}>
              <button
                  className="recurring-payments__btn-small"
                  onClick={() => createRecurring()}
              >
                {t("createAutopay")}
              </button>
            ) : null
            // </Link> : null
          }
        </div>
        <div className="recurring-payments__content">
          {isLoading ? (mockArr.map((item: any) => {
            return <>
              <div className="auto-payment-skeleton"><AutoPaymentSkeleton/></div>
              <div className="media900-auto-payment-skeleton"><Media900AutoPaymentSkeleton/></div>
              <div className="media530-auto-payment-skeleton"><Media530AutoPaymentSkeleton/></div>
              <div className="media455-auto-payment-skeleton"><Media455AutoPaymentSkeleton/></div>
              <div className="media340-auto-payment-skeleton"><Media340AutoPaymentSkeleton/></div>
            </>
          })) : renderItems()}
        </div>
      </div>
      <RecurringWarningModal
          message={modalMessage}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
      />
    </LayoutWithApp>
  );
};

export default RecurringPaymentsList;
