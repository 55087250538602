import * as React from "react";
import Button from "../../Button/Button";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import SideMenu from "../SideMenu/SideMenu";
import "./Success.scss";

const Success = () => {
  return (
    <div className="success">
      <div className="success-body">
        <div>
          <NavigationComponent title={"E-кошелек"} />
        </div>

        <div className="success-body__content">
          <img src={require("../../../img/ic-suc.svg").default} alt="" />

          <h1>Индефикация пройдена</h1>
          <span>Ваш Epul-аккаунт работает без ограничений</span>
        </div>
      </div>
      <SideMenu />
    </div>
  );
};

export default Success;
