import React from "react";

export const PaymentPage = () => {
  const [payment] = React.useState(false);
    return (
        <>
        {payment ? (
            <div className='app-header'>
              <img
                className='app-header__image'
                src={require('./../../img/check-succes.svg').default}
                alt='app-header__image'
              />
              <div className='app-header__payment'>{'Платеж совершен'}</div>
              <div className='app-header__recept'>{'Получить квитанцию'}</div>
            </div>
          ) : (
            <></>
          )}
          </>
    )
}