import React from "react"
import ContentLoader from "react-content-loader"

const MediaLess900FavSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={89}
        viewBox="0 0 1000 100"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="15" y="10" rx="0" ry="0" width="1000" height="5" />
        <circle cx="38" cy="57" r="30" />
        <rect x="75" y="44" rx="0" ry="0" width="140" height="10" />
        <rect x="75" y="65" rx="0" ry="0" width="125" height="8" />
        <rect x="730" y="44" rx="0" ry="0" width="140" height="29" />
        <rect x="895" y="44" rx="0" ry="0" width="32" height="29" />
        <rect x="960" y="54" rx="0" ry="0" width="25" height="5" />
        <rect x="15" y="96" rx="0" ry="0" width="1000" height="5" />
    </ContentLoader>
)

export default MediaLess900FavSkeleton