import {createAsyncThunk} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import client, {BASE_URL} from "../../axios";
import {fetchProfile, fetchProfileDetails, signOut} from "./authActions";
import {baseURL} from "../../http";
import {ThunkAction} from "redux-thunk";
import {Action} from "redux";
import {fetchWithTimeout, toQueryParams} from "../../utils/query";
import {notify} from "../../helper";
import i18n from "../../i18n";
// import  LoadingActionCreators from '../reducers/loading/action-creators'

const token = localStorage.getItem("token");

export const _userAvatar = (payload: boolean) => ({
    type: "auth/_userAvatar",
    payload,
});
export const _userAvatarSource = (payload: string) => ({
    type: "auth/_userAvatarSource",
    payload,
});

export const updateProfileData = createAsyncThunk(
    "updateName/updateProfileData",
    async (
        {
            sid,
            fullName,
            otp,
            wantDebt = false,
        }: {
            sid?: string | undefined;
            fullName?: string;
            otp?: string;
            wantDebt?: boolean;
        },
        thunkAPI
    ) => {
        try {
            if (otp === "SMS") {
                otp = "Sms";
            } else if (otp === "EMAIL") {
                otp = "Email";
            }
            const name = fullName?.split(" ")[0];
            const lastName = fullName?.split(" ")[1];
            console.log("fullName updateProfileData", fullName)
            const resUpdateName = await client.post(
                `user/update_user_info_web?sessionId=${localStorage.getItem(
                    "token"
                )}&firstName=${name}&lastName=${lastName || ''}&prefOtp=${otp}&wantDebt=${wantDebt}`,
                {},
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        // "Payment-Origin": "web",
                    },
                }
            );

            // const resUpdateName = await client.post<any>( `profile/update?sessionId=${localStorage.getItem("token")}`,
            //
            //     toQueryParams({
            //         name: name,
            //         preferredOtp: otp
            //     }),
            //
            //     {
            //         headers: {
            //             "Content-Type": "application/x-www-form-urlencoded",
            //             'Payment-Origin': 'web',
            //         },
            //     }
            // );

            if (resUpdateName.data.resultCodes === "OK") {
                thunkAPI.dispatch(fetchProfile());
            }
            return resUpdateName;
        } catch (e) {
            thunkAPI.rejectWithValue("");
        }
    }
);
export const setAvatar = createAsyncThunk(
    "auth/setAvatar",
    async (data: any, thunkApi) => {
        try {
            const response = await fetchWithTimeout(
                BASE_URL + "profile/set_avatar_base64/?sid=" + data.sid,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Payment-Origin": "web",
                    },
                    body: data.photo,
                }
            );

            // const response = await client.post(
            //     `profile/set_avatar_base64/?sid=${sendData.sid}`,
            //     photo,
            // );

            if (response.status === 200) {
                const json = await response.json();

                if (json?.resultCodes === "OK") {
                    const imageSource = "data:image/png;base64," + data.photo;
                    thunkApi.dispatch(_userAvatarSource(imageSource));
                    notify(i18n.t("successfullyModified"), true);
                }
            } else {
                notify(i18n.t("errorAvatarChanged"));
            }
        } catch (e: any) {
            console.log(e, "error");
            thunkApi.rejectWithValue(e.message());
        }
    }
);
export const removeAvatar = createAsyncThunk(
    "auth/removeAvatar",
    async (sid: any, thunkApi) => {
        const res = await client.post(
            `profile/remove_avatar/${sid}`,
            {},
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );

        if (res.status === 200) {
            if (res.data.resultCodes === "OK" && res.data.description === "OK") {
                thunkApi.dispatch(_userAvatarSource(""));
                notify(i18n.t("successfullyDeleted"), true);
            } else {
                notify(i18n.t("errorAvatarRemove"), true);
            }
        }
    }
);

export const getUserAvatar = createAsyncThunk(
    "auth/getUserAvatar",
    async (sid: string | undefined | null, thunkApi) => {
        try {

            console.log('getUserAvatar', getUserAvatar);

            const res = await client.get("profile/get_avatar/" + sid, {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            if (res.status === 200) {
                if (res.data.size > 0) {
                    return URL.createObjectURL(res.data)
                } else {
                    return ''
                }
            }

        } catch (e) {
            console.log("getUserAvatar catch error", e);
            thunkApi.rejectWithValue("");
        }
    }
);

//Change Phone
export const updateProfilePhone = createAsyncThunk(
    "updateName/updateProfilePhone",
    async (
        {sid, params}: { sid?: string | undefined; params: { newPhone: string } },
        thunkAPI
    ) => {
        const resUpdateName = await client.post(
            `profile/request_change_phone?sessionId=${
                sid || localStorage.getItem("token")
            }&newPhone=${params.newPhone}&lng=Aze`
        );
        return resUpdateName;
    }
);

export const requestChangePhone = createAsyncThunk(
    "profile/request_change_phone",
    async (
    params: { sid: string; language: string; newPhone: string },
    thunkAPI
    ) => {
    try {
        const response = await fetchWithTimeout(BASE_URL + 'profile/request_change_phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Payment-Origin': 'web'
            },
            body: toQueryParams({
                sessionId: params.sid,
                lng: 'az',
                newPhone: params.newPhone
            }),
        });
        if (response.status === 200) {
            const json = await response.json();
            if (json.resultCodes === 'OK') {
            } else if (json.confirmationSended == false) {
                notify(i18n.t("confirmationNotSended"));
                return false
            } else if(json.resultCodes === 'PHONE_STILL_IN_USE'){
                notify(i18n.t("phoneInUse"));
                return false
            } else {
                notify(i18n.t("error"));
                return false
            }
        }
    } catch (e) {
        console.error('error', e);
        notify(i18n.t("error"));
    }
});

export const changePhoneSendCode = (
    sessionId: string,
    phoneNumber: string,
    code: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    try {
        const response = await fetchWithTimeout(BASE_URL + 'profile/change_phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Payment-Origin': 'web'
            },
            body: toQueryParams({
                sessionId: sessionId,
                newPhone: phoneNumber,
                code: code,
            }),
        });
        if (response.status === 200) {
            const json = await response.json();
            if (json.resultCodes === 'OK') {
                notify(i18n.t("numberChangedSuccessfully"), true);
            } else if (json.resultCodes === "INVALID_KEY") {
                notify(i18n.t("invalidCode"));
                return false
            }
        } else {
            notify(i18n.t("error"));
        }
    } catch (e) {
        console.error('error', e);
        notify(i18n.t("error"));
    } finally {
    }
};

//Change BirthDate
export const updateProfileBirthDate = createAsyncThunk(
    "updateBirthDate/updateProfileBirthDate",
    async (
        {
            sid,
            params,
        }: {
            sid?: string | undefined;
            params: { birthDate: string; gender: string };
        },
        thunkAPI
    ) => {
        const resUpdateBirthDate = await client.post(
            `profile/details/update?sessionId=${
                sid || localStorage.getItem("token")
            }&birthDate=${params.birthDate}&gender=${params.gender}`
        );
        return resUpdateBirthDate.data;
    }
);

// Change Email
export const updateProfileEmail = createAsyncThunk(
    "updateEmail/updateProfileEmail",
    async (
        {sid, params}: { sid?: string | undefined; params: { email: string } },
        thunkAPI
    ) => {
        const resUpdateName = await client.post(
            `profile/request_change_email?sessionId=${
                sid || localStorage.getItem("token")
            }&email=${params.email}&lng=Aze`
        );
        return resUpdateName;
    }
);
// Change Password
export const changePassword = createAsyncThunk(
    "updateEmail/changePassword",
    async (
        {
            sid,
            params,
        }: {
            sid?: string | undefined;
            params: { oldPassword: string; newPassword: string };
        },
        thunkAPI
    ) => {
        const {oldPassword, newPassword} = params;
        try {
            const resUpdateName = await client.post(
                `user/change_password?sessionId=${
                    sid || localStorage.getItem("token")
                }&oldPassword=${oldPassword}&newPassword=${newPassword}`
            );

            console.log("resUpdateName change", resUpdateName);
            return resUpdateName.data;
        } catch (e) {
            console.log(e, "change password error");
        }
    }
);

// Change CVV Payment Type
export const changeCvvType = createAsyncThunk(
    "updateEmail/changeCvvType",
    async (
        {
            sid,
            params,
        }: {
            sid?: string | undefined;
            params: { paymentWithoutCvv: boolean };
        },
        thunkAPI
    ) => {
        try {
            const resUpdateName = await client.post(
                `user/change_cvv_setting?sessionId=${
                    sid || localStorage.getItem("token")
                }&payWithoutCVV=${params.paymentWithoutCvv}`
            );
            alert("Смена CVV оплаты прошла успешно");

            return resUpdateName.data;
        } catch (e) {
            console.log(e, "change CVV payment error");
        }
    }
);
export const changeNotificationSetting = createAsyncThunk(
    "profile/changeNotificationType",
    async (
        {
            params,
            sid,
        }: {
            sid?: string | undefined;
            params: { notificationEnabled?: boolean };
        },
        thunkAPI
    ) => {
        try {
            const response = await client.post(
                `user/change_notification_setting?sessionId=${
                    sid || localStorage.getItem("token")
                }&notificationEnabled=${params.notificationEnabled}`
            );
            console.log(response, "response22222");
            fetchProfileDetails();
        } catch (e) {
            console.log(e, "change CVV payment error");
        }
    }
);
