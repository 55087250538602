import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {FREE_PAY_URL} from "../../utils/consts";

export const fetchTokenize = createAsyncThunk(
  'tokenize/fetchTokenize',
  async (params: { body: any; browserToken: string }, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${FREE_PAY_URL}/api/tokenization/tokenize`,
        params.body,
        {
          headers: {
            BrowserToken: params.browserToken,
          },
        },
      )
      return data.cardToken
    } catch (e) {
      return thunkApi.rejectWithValue('Не удалось загрузить')
    }
  },
)
