import React from "react"
import ContentLoader from "react-content-loader"

const RightMenuSkeleton1030 = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={480}
        viewBox="0 0 367 520"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="11" y="0" rx="15" ry="15" width="102" height="103" />
        <rect x="123" y="0" rx="15" ry="15" width="102" height="103" />
        <rect x="236" y="0" rx="15" ry="15" width="102" height="103" />
        <rect x="80" y="194" rx="0" ry="0" width="210" height="17" />
        <circle cx="34" cy="201" r="24" />
        <rect x="81" y="251" rx="0" ry="0" width="210" height="17" />
        <circle cx="34" cy="259" r="24" />
        <rect x="80" y="311" rx="0" ry="0" width="210" height="17" />
        <circle cx="34" cy="318" r="24" />
        <rect x="79" y="368" rx="0" ry="0" width="210" height="17" />
        <circle cx="34" cy="375" r="24" />
        <rect x="81" y="431" rx="0" ry="0" width="210" height="17" />
        <circle cx="34" cy="437" r="24" />
        <rect x="78" y="488" rx="0" ry="0" width="210" height="17" />
        <circle cx="35" cy="494" r="24" />
        <rect x="21" y="156" rx="0" ry="0" width="311" height="2" />
    </ContentLoader>
)

export default RightMenuSkeleton1030