import './index.scss';
import { FC, useState } from 'react';
import { CardDataType } from '../MyCardComponent/InputField/InputField';
import CenterMode from '../MyCardComponent/CenterMode';
import { ICard } from '../../models/Card';
import { Wallet } from '../../models/WalletList';
import { useTranslation } from 'react-i18next';
import AddNewCardButton from './AddNewCardButton';
import NewCardInput from './NewCardInput';

export interface IInstrument {
  card?: ICard;
  wallet?: Wallet;
  bonus?: boolean;
  ruCard?: boolean;
  anonymous?: boolean;
  pulpal?: boolean;
  digitalCard?: any;
  newCardData?: CardDataType;
  isNewCard: boolean;
}

interface IProps {
  setInstrument: (data: IInstrument) => void;
  ruRate?: number;
  canBonus?: boolean;
  canWallet?: boolean;
  canAnonymous?: boolean;
  canPulpal?: boolean;
  noCards?: boolean;
  noNewCard?: boolean;
  currentHubSolutionPaymentMethodHidden?: boolean;
  onlyBonus?: boolean;
  onlyWallet?: boolean;
}

const CardsBox: FC<IProps> = ({
  setInstrument,
  noNewCard,
  noCards,
  canBonus,
  canWallet,
  canAnonymous,
  canPulpal,
  ruRate,
  currentHubSolutionPaymentMethodHidden,
  onlyBonus,
  onlyWallet,
}) => {
  const { t } = useTranslation();
  const [isNewCard, setIsNewCard] = useState(false);

  const toggleNewCard = () => setIsNewCard((prevState) => !prevState);

  return (
    <div className="cards-box">
      <div className="cards-box__title">
        <div className="cards-box__text">{t('paymentMethod')}</div>
        {!noNewCard && (
          <AddNewCardButton
            isNewCard={isNewCard}
            toggleNewCard={toggleNewCard}
            noNewCard={noNewCard}
          />
        )}
      </div>
      {isNewCard && !noCards ? (
        <NewCardInput setInstrument={setInstrument} />
      ) : (
        <CenterMode
          canBonus={canBonus}
          canWallet={canWallet}
          canAnonymous={canAnonymous}
          canPulpal={canPulpal}
          noCards={noCards}
          ruRate={ruRate}
          onSelect={(data) => setInstrument({ ...data, isNewCard: false })}
          currentHubSolutionPaymentMethodHidden={
            currentHubSolutionPaymentMethodHidden
          }
          onlyBonus={onlyBonus}
          onlyWallet={onlyWallet}
        />
      )}
    </div>
  );
};

export default CardsBox;
