import React, {useEffect, useState} from 'react';
import "./index.scss"
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import AddCheckboxIn from "../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import {getOptionValues} from "../../helper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";
import RightComponents from "../RightComponents/RightComponents";
import RegistrationLeftMain from "../RegistrationLeftMain/RegistrationLeftMain";
import OtherPaymentSwiper from "../OtherPayments/OtherPaymentSwiper";
import OtherPayments from "../OtherPayments/OtherPayments";

const RightBasketComponents = () => {
    const {t} = useTranslation();
    const optionValues = getOptionValues(t);
    const [isSelectOPen, setIsSelectOpen] = useState(false);
    const [optionValue, setOptionValue] = useState<any>({
        type: '3',
        value: `${t("monthly")}`
    });
    const [amountToPay, setAmountToPay] = useState(0);
    const [productsToPay, setProductsToPay] = useState(0);
    const {language} = useAppSelector(state => state.changeLangSlice)
    //@ts-ignore
    const { basket } = useAppSelector((state) => state.basketSlice);
    const routeRight = window.location.pathname;
    const isLink = routeRight === `/epay/${language}/select-payment-method`
    const cartItems = basket?.cart?.cartItems;
    const isFilterToPay = cartItems?.filter((f: any) => f.toPay )

    useEffect(() => {
        setAmountToPay(basket?.cart?.cartItems?.filter((item: any) => item.toPay).reduce((sum: number, item: any) => sum + item.amount, 0));
        setProductsToPay(basket?.cart?.cartItems?.filter((item: any) => item.toPay).length)
    }, [basket]);

    return (
        <>
            {productsToPay > 0 ?

                <div className="right-basket" style={{height: !isLink ? '150px' : ''}}>
                    <div className="right-basket-sum">
                        <div className="right-basket-sum__item">
                            <div className="right-basket-sum__item-head">
                                <span>{t('numberOfProducts')}</span>{productsToPay}</div>
                            <div className="right-basket-sum__item-sum">
                                <span>{(amountToPay / 100).toFixed(2)}</span> AZN
                            </div>
                        </div>
                    </div>
                    <div className="right-basket-wrapBtn" style={{marginTop: !isLink ? '25px' : ''}}>
                        {!isLink &&
                            <Link to={`/epay/${language}/select-payment-method`} className="right-basket-btn"
                                  state={{isFilterToPay}}>
                                <Button
                                    borderRadius={15}
                                    background="#EF5C27"
                                    color="#FFFFFF"
                                    padding="14px 119px"
                                    buttonText={`${t("pay")}`}
                                />
                            </Link>
                        }
                    </div>
                </div> :
                <div className="right-basket__right-other-payment">
                    <OtherPayments/>
                </div>}
        </>
    );
};

export default RightBasketComponents;
