import React, {FC, useState} from "react";
import "./index.scss";
import HeaderText from "../../CustomsComponents/HeaderText";
import SideMenu from "../SideMenu/SideMenu";
import CardsBox, {IInstrument} from "../../CardsBox/CardsBox";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {GuestActionCreators} from "../../../redux/reducers/guest/action-creators";
import {useTranslation} from "react-i18next";
import Button from "../../Button/Button";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import {fetchWalletList} from "../../../redux/actions/walletListAction";
import CPhoneInput from "../../CustomsComponents/PhoneInput";
import {useForm} from "react-hook-form";

interface Transaction {
}

interface IProps {
  setInstrument: (data: IInstrument) => void;
  ruRate?: number;
  canBonus?: boolean;
  canWallet?: boolean;
  canAnonymous?: boolean;
  canPulpal?: boolean;
  noCards?: boolean;
  noNewCard?: boolean;
}


interface FieldType {
  id: string,
  name: string,
  value: string,
}

// const phoneReg =
//     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{2})[-. ]*(\d{1})(?: *x(\d+))?\s*$/;
// const amountReg = new RegExp("^[0-9]+$");

const Transaction: FC<IProps> = ({noNewCard, noCards, canBonus, canWallet, canAnonymous, canPulpal, ruRate}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  // const {walletList} = useAppSelector(state => state.walletList);
  // const {user} = useAppSelector(state => state.authReducer);
  const {w2w, c2qr} = useAppSelector((state) => state.wallet);
  const [phone, setPhone] = useState<any>("");
  const [instrument, setInstrument] = useState(
      undefined as IInstrument | undefined
  );
  const { language} = useAppSelector(state => state.changeLangSlice)
  // const {language} = useAppSelector((state) => state.language);
  // const [currency, setCurrency] = useState(walletList?.wallet?.find(wallet => wallet.currency === 'AZN')?.currency || 'AZN');
  const { forms } = useAppSelector((state) => state.masterPassReducer);
  const { isPaymentLoading } = useAppSelector((state) => state.loading);
  const navigate = useNavigate();

  const goBack = () => navigate(`/epay/${language}/e-wallet`);

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    watch
  } = useForm({
    defaultValues: {
      amount: "",
      phone: "",
      comment: "",
    },
    mode: "onChange",
  });

  const amountValue = watch("amount");

  const getPayByQrServiceData = (data: any) => {
    const {amount, phone} = data;
    if (!c2qr) {
      return;
    }
    const serviceFields = c2qr.serviceFields.serviceField;
    let fieldsService = [
      {
        name: serviceFields[0].name,
        value: phone,
        id: serviceFields[0].id.toString(),
      },
      // {
      //   name: 'comment',
      //   id: "2825",
      //   value: comment,
      // }
    ];

    return {
      serviceId: c2qr.id,
      amount: Number(amount),
      fields: JSON.stringify({ fields: fieldsService }),
      payAllDebt: c2qr.hasDebt,
      subName: c2qr.subName,
      subVal: "",
      desc: "",
      lng: language,
      selectedGroupId: 0,
    };
  };

  const checkNewCard = () => {
    return true;
  };


  const transfer = async (data: any) => {

    const { comment, phone, amount } = data;

    let pickerCardName = instrument?.card?.name || "newCard";
    if (instrument?.wallet) {
      // pickerCardName = "wallet";
      forms?.transferToWallet(
          instrument.wallet.currency,
          Number(amount),
          phone,
          'AZN',
          comment,
          ({ historyId, orderId }) => {
            navigate(
                `/epay/${language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`
            );
            dispatch(fetchWalletList())
          },
      )
      return;
    }

    const serviceData = getPayByQrServiceData(data);
    if (!serviceData) {
      return;
    }

    if (instrument?.ruCard) {
      const url = await dispatch(
        GuestActionCreators.doGuestPay(
          serviceData?.serviceId,
          Number(amount),
          serviceData?.fields,
          false,
          serviceData?.subName,
          serviceData?.subVal,
          0,
          // `${window.location.origin}/frames/${language}/check`,
          `${window.location.origin}/epay/${language}/result/payment/check`,
          "",
          "RUB"
        )
      );
      if (url) {
        window.location = url;
      }
      return;
    }

    if (instrument?.isNewCard) {
      if (!checkNewCard() || !instrument.newCardData) {
        return;
      }
      const splt = instrument.newCardData.expiryDate.split("/");
      forms?.payWithNewCard(
        {
          year: splt[1].trim(),
          month: splt[0].trim(),
          cardNumber: instrument.newCardData.cardNumber,
          cvv: instrument.newCardData.cvc,
          cardName: instrument.newCardData.cardName,
        },
        serviceData!
      );
    } else {
      forms?.payWithSavedCard(pickerCardName, serviceData!);
    }

  };

  console.log("isPaymentLoading",isPaymentLoading)


  return (
      <LayoutEmpty goBack={goBack} navTitle={t("back")}>
        <div className={"transfer"}>
          {/*<NavigationComponent onClick={goBack} title={t("back")}/>*/}
          <div className="transfer__body">
            <div className="transfer__content">
              <div className="transfer__content-title">
                <HeaderText title={t("corrected")}/>
              </div>
              <div className="transfer__content-body">
                <form onSubmit={handleSubmit(transfer)}>
                  <div className="transfer__field-box">
                    <div className="transfer__field-title">{t("recipient")}</div>
                    <div className="transfer__field transfer__field--phone">
                      <CPhoneInput
                          value={phone}
                          onChange={setPhone}
                          control={control}
                      />
                    </div>
                  </div>
                  <div className="transfer__field-box">
                    <div className="transfer__field-title">{t("amount")}</div>
                    <div className="transfer__field">
                      <input
                          {...register('amount', {
                            validate: value => {
                            const amount = parseFloat(value);
                            return value === '' || (amount >= (w2w?.minAmount ?? 0) / 100 && amount <= (w2w?.maxAmount ?? 0) / 100);
                            }
                          })}
                          className='transfer__comment-input'
                          type="number"
                          style={{
                            fontSize: "30px",
                            lineHeight: "49px",
                          }}
                          name={"amount"}
                          required
                          placeholder="0"
                      />
                      <p className={amountValue === "" || !errors.amount ? "valid-input" : "error-input"}>{t("min") + ': ' + (w2w?.minAmount ?? 0)/100 + " AZN   " + t("max") + ': ' + (w2w?.maxAmount ?? 0)/100 + " AZN"}</p>
                      </div>
                  </div>
                  <div className="transfer__field-hint">{t("commission")}</div>
                  <div className="transfer__field-box">
                    <div className="transfer__field-title">{t("comment")}</div>
                    <div className="transfer__field">
                      <input
                          {...register('comment')}
                          className='transfer__comment-input'
                          name={"comment"}
                          placeholder={`${t("comment")}`}
                          style={{
                            marginBottom: '30px'
                          }}
                      />
                    </div>
                  </div>

                  <div className="transfer__payment-box">
                    <CardsBox
                        setInstrument={(i) => {
                          setInstrument(i);
                        }}
                        canWallet
                        noCards={true}
                        noNewCard={true}
                        canBonus={false}
                        canPulpal={false}
                        canAnonymous={false}
                    />

                  </div>
                  <div className="transfer__payment-btn">
                    {isPaymentLoading && (
                        <div style={{ position: "relative", top: 15, right: 180 }}>
                          <div className="spin"></div>
                        </div>
                    )}
                    <Button
                        disabled={isPaymentLoading || errors.amount || !amountValue}
                        buttonText={`${t("next")}`}
                        background={"#EF5C27"}
                        color={"white"}
                        width={"390px"}
                        borderRadius={15}
                        padding={"14px 0"}
                        // onClick={() => transfer()}
                    />
                  </div>
                </form>

              </div>
            </div>
            <div className="transaction__side-menu">
              <SideMenu/>
            </div>
          </div>
        </div>
      </LayoutEmpty>
  );
};

export default Transaction;
