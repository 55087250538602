import ServiceActionTypes from './types';
import ServiceService from '../../../services/service';
import { LoadingActionCreators } from '../loading/action-creators';

export const ServiceActionCreators = {
  setService: (service) => ({
    type: ServiceActionTypes.SET_SERVICE,
    payload: service,
  }),

  setError: (payload) => ({
    type: ServiceActionTypes.SET_ERROR,
    payload,
  }),

  setServiceIsAvailablePaymentSystem: (payload) => ({
    type: ServiceActionTypes.IS_HUB_SOLUTION_PAYMENT_METHOD,
    payload,
  }),

  getServiceById: (data) => async (dispatch) => {
    ServiceActionCreators.setService({});
    try {
      dispatch(LoadingActionCreators.loadingState(true));

      const {
        data: {
          list: { service },
        },
      } = await ServiceService.getServiceById(data);

      if (!service.length) throw new Error('Load service');

      dispatch(ServiceActionCreators.setService(service?.[0]));
      dispatch(ServiceActionCreators.setError(''));
    } catch (e) {
      dispatch(ServiceActionCreators.setError(e));
    } finally {
      dispatch(LoadingActionCreators.loadingState(false));
    }
  },
};
