import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import "./index.scss";
import {replenishmentOfMobile} from "../../../mockFile/mockFile";
import {useTranslation} from "react-i18next";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{color: "black"}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    tabs: {
        "& .MuiTabs-flexContainer ": {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
        },
    },
    tabTwo: {
        "&.MuiButtonBase-root.MuiTab-root": {
            width: "50%",
            display: "flex",
            flexDirection: "row",

            "&.MuiButtonBase-root.MuiTab-root svg": {
                marginRight: "10px",
            },

            "&.MuiTab-root:nth-child(1) path": {
                fill: "rgba(136, 136, 136, 1)",
            },
            "&.MuiTab-root:nth-child(2) path": {
                stroke: "rgba(136, 136, 136, 1)",
            },

            "&.Mui-selected": {
                color: "black",
                display: "flex",
                flexDirection: "row",
            },

            "&.Mui-selected:nth-child(1) path": {
                fill: "#EF5C27",
            },
            "&.Mui-selected:nth-child(2) path": {
                stroke: "#EF5C27",
            }
        }
    },
    tabsLine: {
        "&.MuiTabs-flexContainer": {
            backgroundColor: "#EF5C27"
        }
    },
    padTab: {
        "&.MuiBox-root": {
            padding: 0
        }
    }
}));

export default function BasicTabs() {
    const {t} = useTranslation()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: "100%", borderRadius: "15px", boxShadow: "rgba(0, 0, 0, 0.24) 0 3px 8px"}}
             className={classes.padTab}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#EF5C27",
                            height: "2px"
                        }
                    }}

                >
                    <Tab className={classes.tabTwo} label={<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path
                                d="M6.58329 12.076C6.58329 12.6283 6.13558 13.076 5.58329 13.076C5.03101 13.076 4.58329 12.6283 4.58329 12.076H6.58329ZM7.29082 18.5426C6.89734 18.1551 6.89253 17.5219 7.28008 17.1284C7.66762 16.7349 8.30077 16.7301 8.69425 17.1177L7.29082 18.5426ZM5.33727 13.8638L6.04657 14.5687C5.8266 14.79 5.51591 14.896 5.20654 14.8552C4.89716 14.8144 4.62457 14.6315 4.46949 14.3607L5.33727 13.8638ZM6.90024 10.8725C7.28955 10.4807 7.92272 10.4788 8.31445 10.8681C8.70618 11.2574 8.70815 11.8906 8.31884 12.2823L6.90024 10.8725ZM2.86757 11.5635C2.59311 11.0842 2.75914 10.4732 3.2384 10.1987C3.71766 9.92427 4.32867 10.0903 4.60313 10.5696L2.86757 11.5635ZM13.6593 12.076V13.076C13.1088 13.076 12.6618 12.631 12.6593 12.0805L13.6593 12.076ZM17.2354 11.076C17.7876 11.076 18.2354 11.5237 18.2354 12.076C18.2354 12.6283 17.7876 13.076 17.2354 13.076V11.076ZM12.6394 7.60446C12.6369 7.05218 13.0826 6.60247 13.6349 6.60001C14.1872 6.59755 14.6369 7.04326 14.6394 7.59554L12.6394 7.60446ZM13.6593 19.1521C17.5673 19.1521 20.7354 15.984 20.7354 12.076H22.7354C22.7354 17.0886 18.6719 21.1521 13.6593 21.1521V19.1521ZM20.7354 12.076C20.7354 8.16805 17.5673 5 13.6593 5V3C18.6719 3 22.7354 7.06348 22.7354 12.076H20.7354ZM13.6593 5C9.75134 5 6.58329 8.16805 6.58329 12.076H4.58329C4.58329 7.06348 8.64677 3 13.6593 3V5ZM8.69425 17.1177C9.97265 18.3768 11.7245 19.1521 13.6593 19.1521V21.1521C11.1783 21.1521 8.92818 20.1553 7.29082 18.5426L8.69425 17.1177ZM4.62797 13.1589L6.90024 10.8725L8.31884 12.2823L6.04657 14.5687L4.62797 13.1589ZM4.46949 14.3607L2.86757 11.5635L4.60313 10.5696L6.20505 13.3668L4.46949 14.3607ZM13.6593 11.076H17.2354V13.076H13.6593V11.076ZM14.6394 7.59554L14.6593 12.0716L12.6593 12.0805L12.6394 7.60446L14.6394 7.59554Z"
                                fill="#EF5C27"/>
                        </svg>
                        {t("latest")}</>} {...a11yProps(0)} />
                    <Tab className={classes.tabTwo} label={<>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path
                                d="M20.3937 14.1424C20.03 14.4927 19.7586 14.9275 19.6038 15.4081C19.4489 15.8887 19.4154 16.4002 19.5061 16.8969L20.3588 21.8816C20.4984 22.6978 19.6421 23.3204 18.9087 22.9358L14.4256 20.5849C13.9835 20.3463 13.4895 20.2201 12.9871 20.2176C12.4537 20.2046 11.9262 20.3312 11.4568 20.5849L6.97856 22.9333C6.24427 23.3183 5.38729 22.6939 5.52883 21.8769L6.39159 16.8969C6.48045 16.3989 6.4445 15.8867 6.28694 15.406C6.12938 14.9253 5.85513 14.4912 5.48873 14.1424L1.92353 10.61C1.33764 10.0295 1.6668 9.02926 2.48293 8.91013L7.524 8.17429C8.02678 8.10854 8.50535 7.91889 8.9167 7.6224C9.32804 7.32591 9.65928 6.93187 9.88064 6.47568L12.1101 1.96248C12.479 1.21563 13.5458 1.22082 13.9075 1.97123L16.0783 6.47568C16.2997 6.93187 16.6309 7.32591 17.0422 7.6224C17.4536 7.91889 17.9321 8.10854 18.4349 8.17429L23.4464 8.9074C24.2651 9.02717 24.5924 10.0327 24.0011 10.6115L20.3937 14.1424Z"
                                stroke="#EF5C27" stroke-width="2"/>
                        </svg>
                        {t("templates")}</>} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div style={{padding: "20px 0 25px"}}>
                    {replenishmentOfMobile.map((item) => (
                        <div className="tabs-title__information">
                            <div className="tabs-title__information-main">
                                <div className="tabs-title__information-main-div">
                                    <div style={{backgroundColor: item.backGround}}
                                         className="tabs-title__information-image-wrap"
                                    >
                                        <img className="tabs-title__information-image" src={item.img}/>
                                    </div>
                                </div>
                                <div className="tabs-title__information-title">
                                    <p className="tabs-title__information-title-street">{item.titleStreet}</p>
                                    <p className="tabs-title__information-title-service">{item.titleService}</p>
                                </div>
                                <div className="tabs-title__information-count-wrap">
                                    <p className="tabs-title__information-price">{item.price}</p>
                                    <p className="tabs-title__information-currency">{item.currency}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <div style={{padding: "20px 0 25px"}}>
                    <div style={{display: "flex", textAlign: "center", justifyContent: "center"}}>
                        OOPS
                    </div>
                    {/*{replenishmentOfMobile.map((item) => (*/}
                    {/*    <div className="tabs-title__information">*/}
                    {/*        <div className="tabs-title__information-main">*/}
                    {/*            <div className="tabs-title__information-main-div">*/}
                    {/*                <div style={{backgroundColor: item.backGround}}*/}
                    {/*                     className="tabs-title__information-image-wrap"*/}
                    {/*                >*/}
                    {/*                    <img className="tabs-title__information-image" src={item.img}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="tabs-title__information-title">*/}
                    {/*                <p className="tabs-title__information-title-street">{item.titleStreet}</p>*/}
                    {/*                <p className="tabs-title__information-title-service">{item.titleService}</p>*/}
                    {/*            </div>*/}
                    {/*            <div className="tabs-title__information-count-wrap">*/}
                    {/*                <p className="tabs-title__information-price">{item.price}</p>*/}
                    {/*                <p className="tabs-title__information-currency">{item.currency}</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </div>
            </TabPanel>
        </Box>
    );
}
