import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import NavigationComponent from "../../Components/CustomsComponents/NavigationComponent";
import InstallApp from "../../Components/CustomsComponents/InstallApp";
import { usePaymentInputs } from "react-payment-inputs";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputFieldComponent from "../../Components/MyCardComponent/InputField/InputField";
import CardsBox, { IInstrument } from "../../Components/CardsBox/CardsBox";
import { GuestActionCreators } from "../../redux/reducers/guest/action-creators";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslation } from "react-i18next";
import {getResultPaymentUrl, notify} from "../../helper";
import client from "../../axios";
import ReCAPTCHA from 'react-google-recaptcha'
import ContentLoader from "react-content-loader";
import { fetchGetCardToken, fetchHabSolutionC2CService } from "../../redux/actions/habStorageAction";
import { fetchTokenize } from "../../redux/actions/tokenize";
import Loading from "../../Components/Loading/Loading";
import OtherPayments from "../../Components/OtherPayments/OtherPayments";
import { toQueryParams } from "../../utils/query";
import OtherPaymentSwiper from "../../Components/OtherPayments/OtherPaymentSwiper";
import { createHubCardData } from "../../utils/hubFunctions";
import { getC2cService } from "../../redux/actions/card2cardAction";

interface IProps {
}

const currencyValues: any = [
  {
    type: "1",
    value: "AZN",
  },
  {
    type: "2",
    value: "USD",
  },
  {
    type: "3",
    value: "EUR",
  },
];
const ERROR_MESSAGES = {
  emptyCardNumber: "do111111111",
  invalidCardNumber: "12o",
  emptyExpiryDate: "La fecha de expiración es inválida",
  monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
  yearOutOfRange: "El año de expiración no puede estar en el pasado",
  dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
  invalidExpiryDate: "La fecha de expiración es inválida",
  emptyCVC: "El código de seguridad es inválido",
  invalidCVC: "El código de seguridad es inválido",
};

const TransferCard1: FC<IProps> = () => {
  const { t } = useTranslation();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [amountValue, setAmountValue] = useState("");
  const [phone, setPhone] = useState("");
  const [amountValueConverted, setAmountValueConverted] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [exp, setExp] = useState("");
  const [instrument, setInstrument] = useState(
    undefined as (IInstrument & { isGuestCard?: boolean }) | undefined
  );
  const [currencyValue, setCurrencyValue] = useState({
    type: "1",
    value: "AZN",
  });
  const [reCaptcha, setReCaptcha] = useState(null);
  const [reCaptchaLoad, setReCaptchaLoad] = useState(true)
  const [isValidAmount, setIsValidAmount] = useState(true);

  // console.log(
  //   'states',
  //   {
  //     isSelectOpen,
  //     amountValue,
  //     phone,
  //     amountValueConverted,
  //     cardNumber,
  //     exp,
  //     instrument,
  //     currencyValue,
  //     reCaptcha,
  //     reCaptchaLoad,
  //   }
  // )

  const { browserToken } = useAppSelector(state => state.browserToken)

  const { isAuth, user, profile } = useAppSelector(
    (state) => state.authReducer
  );
  const { forms } = useAppSelector((state) => state.masterPassReducer);
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const { w2c, c2c, currencyConverter, refillCard } = useAppSelector(
    (state) => state.card2cardReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const getFullPan = async () => {
    const response = await client.get(
      "payment/c2c_recipient_pan/" +
      location?.state?.fields?.[0]?.historyId +
      "/" +
      localStorage.getItem("token"),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    if (response.data?.resultCodes === "OK") {
      //add space between card number
      const cardNumberWithSpace = response.data?.value
        .replace(/(.{4})/g, "$1 ")
        .trim();
      // handleChangeCardNumber({ target: { value: response.data?.value } });
      setCardNumber(cardNumberWithSpace); //4098584450890692 I checked this card number and try 4098 5844 5089 0692 , Google show me error that dont allow autocomplete
      // setCardNumber(response.data?.value);
    }
  };

  useEffect(() => {
    if (location?.state?.serviceId === 245) {
      getFullPan();
    }
  }, [location]);

  const onRecaptchaChange = (value: any) => {
    setReCaptcha(value)
  }

  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });

  const handleChangeCardNumber = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setCardNumber(value);
  };

  const handleChangeExpiryDate = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setExp(value);
  };

  //TODO: add types
  const setOptionValue = (option: any) => {
    setCurrencyValue(option);
    setIsSelectOpen(false);
  };

  const checkInstrumentAndAmount = () => {
    return true;
  };

  const checkConvertedAmount = () => {
    return true;
  };

  const checkNewCard = () => {
    return true;
  };


  const [loading, setLoading] = useState(false);


  const transfer = async () => {
    if (isAuth) {
      if (!cardNumber || !amountValue) {
        notify(t("fillAllInputs"));
        return;
      }
    }
    else if (!cardNumber || !phone || !amountValue || !instrument?.newCardData?.cardNumber || !instrument?.newCardData?.expiryDate || !instrument?.newCardData?.cvc) {
      notify(t("fillAllInputs"));
      return;
    }

    setLoading(true);

    if (!checkInstrumentAndAmount()) {
      return;
    }

    if (!cardNumber || cardNumber.length < 19) {
      notify(t("invalidCardNumber"));
      return;
    }
    // if (await this.props.isPaymentLoading()) {
    //     return;
    // }

    let amountNum = Number(amountValue);

    if (!amountNum) {
      notify(t("emptyAmountValue"));
      return;
    }
    if (amountNum < 1) {
      notify(t("invalidAmountValue"));
      return;
    }

    if (!forms) {
      return;
    }

    if (!isAuth) {
      if (!instrument?.newCardData || !instrument?.isGuestCard) {
        return;
      }
      // const splt = instrument.newCardData.expiryDate.split("/");
      console.log(`instrument:`, instrument)
      const cardDataSpited = instrument.newCardData.expiryDate.split(' / ')

      const cardData: Record<string, any> = {
        CardHolder: 'e-pul',
        Cvv2: instrument.newCardData.cvc,
        ExpiryMonth: +cardDataSpited[0]?.trim(),
        ExpiryYear: +cardDataSpited[1]?.trim(),
        Pan: instrument.newCardData.cardNumber,
      }


      const { payload: cardToken } = await dispatch(
        fetchTokenize({
          body: {
            ...createHubCardData(instrument.newCardData),
          },
          browserToken: browserToken,
        }),
      )
      const { payload } = await dispatch(fetchHabSolutionC2CService('GUEST'))

      console.log(`payload:`, payload)

      const { data } = payload as any
      const createFields = (groupId: any) => {
        const {
          serviceFields: { serviceField },
          groups,
        } = data
        let fields = [{
          id: '2204',
          name: 'phone',
          value: phone, //TODO: place phone
        }]
        if (groups.length >= 2) {
          const group = groups.find((group: any) => group.id === groupId)
          if (group) {
            for (const i in group?.fields) {
              const item = group?.fields[i]
              let field = {
                id: item.id.toString(),
                name: item.name,
                value: item.defaultValue,
                qrfield: item.type === 'barcode',
                barfield: item.type === 'qrcode',
              }
              fields.push(field)
            }
          }
        } else {
          for (const item of groups.length === 1
            ? groups[0].fields
            : serviceField) {
            let field = {
              id: item.id.toString(),
              name: item.name,
              value: item.defaultValue,
              qrfield: item.type === 'qrcode',
              barfield: item.type === 'barcode',
            }
            fields.push(field)
          }
        }
        return fields
      }

      let fields = createFields(3)
      console.log(`fields:`, fields)

      fields = fields.map(field =>
        field.id === "2960" ? { ...field, value: cardNumber.replace(/\s/g, "") } : field,
      )

      const fieldJson = JSON.stringify({ fields })
      console.log(`fieldJson:`, fieldJson)

      const url = await dispatch(
        GuestActionCreators.doGuestPay(
          data?.id,
          amountNum,
          fieldJson,
          false,
          "",
          "",
          3,
            getResultPaymentUrl(language),
          cardToken,
          currencyValue.value,
          4,
        ),
      )

      if (url) {
        window.location = url
      }

      return;
    }

    if (instrument?.wallet || instrument?.ruCard) {
      if (!w2c) {
        return;
      }
      if (!exp || exp.length < 7) {
        notify(t("invalidExpDate"));
        // TODO: higlight exp fields
        return;
      }
      if (instrument?.wallet && !profile?.user?.walletIdentified) {
        notify(t("onlyIdentifyUserCanTransfer"));
        //this.props.MsgToUserState(MessageType.IdentificationRequired);
        return;
      }
    }

    if (instrument?.digitalCard) {
      // const inMasterPass = this.props.cards?.find(c => c.name === 'u_' + data.id);
      // exp = undefined;
      // if (inMasterPass) {
      //     pickerCardName = inMasterPass.name;
      // } else {
      //     pickerCardName = 'newcard';
      //     if (requisites) {
      //         const splitExpDate = requisites.exp_date.split('/');
      //         newCard = {
      //             cardName:'',
      //             cardNumber: requisites?.full_pan.replace(/ /g, ''),
      //             // cvv: requisites?.cvv,
      //             month: splitExpDate[0],
      //             year: splitExpDate[1]
      //         }
      //     } else {
      //         return;
      //     };
      // };
    }

    if (instrument?.ruCard) {
      if (!refillCard) {
        return;
      }
      if (!checkConvertedAmount()) {
        return;
      }

      const fields = JSON.stringify({
        fields: [
          { id: "2770", name: "pan", value: cardNumber },
          { id: "2771", name: "exp", value: exp },
        ],
      });
      const url = await dispatch(
        GuestActionCreators.doGuestPay(
          refillCard.id,
          Number(amountValueConverted),
          fields,
          false,
          "",
          "",
          0,
            getResultPaymentUrl(language),
          "",
          "RUB"
        )
      );

      if (url) {
        window.location = url;
      }

      return;
    }

    let pickerCardName = instrument?.card?.name || "newCard";
    if (instrument?.wallet) {
      pickerCardName = "wallet";
    }

    const serviceData = await forms.doPayMasterPassC2c(
      pickerCardName,
      amountNum,
      cardNumber.replace(/\s/g, ""),
      isAuth && !instrument?.ruCard && !instrument?.wallet
        ? currencyValue.value
        : "AZN",
      exp.replace(/\s/g, "")
    );


    if (!serviceData) {
      return;
    }

    if (instrument?.isNewCard) {
      if (
        !checkNewCard() ||
        !instrument.newCardData ||
        instrument.isGuestCard
      ) {
        return;
      }
      console.log(`click instrument:`, instrument)


      const cardDataSpited = instrument.newCardData.expiryDate.split(' / ')



      const cardData:Record<string, any> = {
        CardHolder: 'e-pul',
        Cvv2: instrument.newCardData.cvc,
        ExpiryMonth: +cardDataSpited[0].trim(),
        ExpiryYear: +cardDataSpited[1].trim(),
        Pan: instrument.newCardData.cardNumber,
      }


      if(instrument.newCardData?.cardName) {
        cardData['Name'] =  instrument.newCardData?.cardName
      }

      const { payload: cardToken } = await dispatch(
        fetchTokenize({
          body: {
            ...createHubCardData(instrument.newCardData),
          },
          browserToken: browserToken,
        }),
      )

              const {payload} =  await dispatch(fetchHabSolutionC2CService())

            console.log(`payload:`, payload)

            const {data} = payload as any

            const createFields = (groupId:any)=> {



                const {
                        serviceFields: { serviceField },
                        groups,
                } = data

                let fields = []
                if (groups.length >= 2) {
                    const group = groups.find((group:any) => group.id === groupId)
                    if (group) {
                        for (const i in group?.fields) {
                            const item = group?.fields[i]
                            let field = {
                                id: item.id.toString(),
                                name: item.name,
                                value: item.defaultValue,
                                qrfield: item.type === 'barcode',
                                barfield: item.type === 'qrcode',
                            }
                            fields.push(field)
                        }
                    }
                } else {
                    for (const item of groups.length === 1
                        ? groups[0].fields
                        : serviceField) {
                        let field = {
                            id: item.id.toString(),
                            name: item.name,
                            value: item.defaultValue,
                            qrfield: item.type === 'qrcode',
                            barfield: item.type === 'barcode',
                        }
                        fields.push(field)
                    }
                }

                return fields
            }

            let fields = createFields(3)
            console.log(`fields:`, fields)

            fields = fields.map(field =>
                field.id === "2960" ? { ...field, value: cardNumber.replace(/\s/g, "") } : field,
            )









            let body: any = {
                sessionId: localStorage.getItem('token'),
                serviceId: data?.id,
                amount: amountNum,
                fields: JSON.stringify({fields}),
                selectedGroupId: 27,
                bindingId:cardToken,
                subName: "",
                subVal: "",
                desc: "",
                lng: "az",
                tokenType:4,
              }

            console.log(`body:`, body)

            const res = await client.post(
                `payment/do_pay_with_group_id/${localStorage.getItem('token')}?${toQueryParams(
                  body,
                )}`,
                {},
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                },
              )

            console.log(`res:`, res)

            if(res.data.formUrl) {
              window.location = res.data.formUrl
            }


      // forms?.payWithNewCard2Card(
      //   {
      //     year: splt[1].trim(),
      //     month: splt[0].trim(),
      //     cardNumber: instrument.newCardData.cardNumber,
      //     cvv: instrument.newCardData.cvc,
      //     cardName: instrument.newCardData.cardName,
      //   },
      //   { ...serviceData, masterCardCanFail: false }
      // );
    } else if (instrument?.wallet) {
      navigate(
        `/epay/${language}/result/payment?isPayHistory=false&historyId=${serviceData.historyId}&orderId=wallet`
      );
    } else {
      if(instrument?.card?.isMasterPassMember){
        forms?.payWithSavedCard2Card(pickerCardName, {
          ...serviceData,
          masterCardCanFail: false,
        });
      } else {
        const {payload} =  await dispatch(fetchHabSolutionC2CService())

            console.log(`payload:`, payload)

            const {data} = payload as any

            const createFields = (groupId:any)=> {



                const {
                        serviceFields: { serviceField },
                        groups,
                } = data

                let fields = []
                if (groups.length >= 2) {
                    const group = groups.find((group:any) => group.id === groupId)
                    if (group) {
                        for (const i in group?.fields) {
                            const item = group?.fields[i]
                            let field = {
                                id: item.id.toString(),
                                name: item.name,
                                value: item.defaultValue,
                                qrfield: item.type === 'barcode',
                                barfield: item.type === 'qrcode',
                            }
                            fields.push(field)
                        }
                    }
                } else {
                    for (const item of groups.length === 1
                        ? groups[0].fields
                        : serviceField) {
                        let field = {
                            id: item.id.toString(),
                            name: item.name,
                            value: item.defaultValue,
                            qrfield: item.type === 'qrcode',
                            barfield: item.type === 'barcode',
                        }
                        fields.push(field)
                    }
                }

                return fields
            }

            let fields = createFields(3)
            console.log(`fields:`, fields)

            fields = fields.map(field =>
                field.id === "2960" ? { ...field, value: cardNumber.replace(/\s/g, "") } : field,
            )




            const {payload:cardToken} = await dispatch(
              fetchGetCardToken({
                body: {
                  CardId: instrument?.card?.id,
                },
                browserToken: browserToken,
              }),
            )





            let body: any = {
                sessionId: localStorage.getItem('token'),
                serviceId: data?.id,
                amount: amountNum,
                fields: JSON.stringify({fields}),
                selectedGroupId: 3,
                bindingId:cardToken,
                subName: "",
                subVal: "",
                desc: "",
                lng: "az",
                tokenType:4,
              }

            console.log(`body:`, body)

            const res = await client.post(
                `payment/do_pay_with_group_id/${localStorage.getItem('token')}?${toQueryParams(
                  body,
                )}`,
                {},
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                },
              )

            console.log(`res:`, res)
            if(res.data.formUrl) {
              window.location = res.data.formUrl
            }
      }
    }
    setLoading(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleAmountChange = (event: any) => {
    const value = event.target.value;
    setAmountValue(value);
    const minAmount = (c2c?.minAmount ?? 0) / 100;
    const maxAmount = (c2c?.maxAmount ?? 0) / 100;
    const isValid = value && value >= minAmount && value <= maxAmount;
    setIsValidAmount(isValid);
  };

    useEffect(() => {
      if (!isAuth) {
        dispatch(getC2cService("GUEST"));
      }
  }, [dispatch, isAuth]);

  return (
    <div className="transfer-card">
      <div style={{ padding: "15px 0" }}>
        <NavigationComponent title={t("back")} onClick={goBack} />
      </div>
      <h1 className="transfer-card-text">{t('transferfromc2c')}</h1>
      <div className="transfer-card__body">
        <div className="transfer-card__content">
          <div className="transfer-card__cards-box">
            {isAuth ? (
                <CardsBox
                    canWallet={false}
                    setInstrument={(data) => setInstrument(data)}
                />
            ) : (
                <>
                  <div className="transfer-card__field-title">
                    {t("fromTheCard")}
                  </div>
                  <InputFieldComponent
                      infoImage
                      cardDataCb={(data) =>
                          setInstrument({
                            newCardData: data,
                            isNewCard: true,
                            isGuestCard: true,
                          })
                      }
                      // isCardSaved={true}
                  />
                </>
            )}
          </div>
          {!isAuth && (
              <div className="transfer-card__field">
                <div className="transfer-card__field-title">
                  {t("yourPhoneNumber")}
                </div>
                <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    className="transfer-card__field-input"
                    placeholder="0501231234"
                />
              </div>
          )}
          <div className="transfer-card__form">
            <div className="transfer-card__field">
              <div className="transfer-card__field-title">{t("ontheCard")}</div>
              <input
                  {...getCardNumberProps({onChange: handleChangeCardNumber})}
                  value={cardNumber}
                  className="transfer-card__field-input"
                  placeholder="••••  ••••  ••••  ••••"
              />
            </div>
            {!!instrument?.wallet && (
                <div className="transfer-card__card-wrap">
                  <div className="transfer-card__field">
                    <div className="transfer-card__field-title">
                      {t("endDate")}
                    </div>
                    <input
                        {...getExpiryDateProps({
                          onChange: handleChangeExpiryDate,
                        })}
                        className="transfer-card__field-input transfer-card__field-input--short"
                    />
                  </div>
                </div>
            )}
            <div className="transfer-card__field">
              <div className="transfer-card__field-title">{t("currency")}</div>
              <ClickAwayListener onClickAway={() => setIsSelectOpen(false)}>
                <div className="transfer-card__custom-select">
                  <div
                      className="transfer-card__select-header"
                      onClick={() => setIsSelectOpen((prev) => !prev)}
                  >
                    <div className="transfer-card__select-title">
                      {isAuth && !instrument?.ruCard && !instrument?.wallet
                          ? currencyValue.value
                          : "AZN"}
                    </div>
                    <div className="transfer-card__select-img">
                      <img
                        className="transfer-card__icon"
                        src={require("../../img/drop.svg").default}
                        alt="icon"
                      />
                    </div>
                  </div>
                  {isSelectOpen && (
                    <div className="transfer-card__option-box">
                      <div className="transfer-card__option-content">
                        {currencyValues
                          ?.filter(
                            (option: any) =>
                              (isAuth &&
                                !instrument?.ruCard &&
                                !instrument?.wallet) ||
                              option.value === "AZN"
                          )
                          .map((option: any) => (
                            <div
                              key={option.value}
                              className="transfer-card__option"
                              onClick={() => setOptionValue(option)}
                              style={
                                currencyValue.value === option.value
                                  ? { color: "#EF5C27" }
                                  : {}
                              }
                            >
                              {option.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
            <div className="transfer-card__field">
              <div className="transfer-card__field-title">
                {t("transferAmount")}
              </div>
              <input
                className="transfer-card__field-input"
                onChange={handleAmountChange}
              />
              <p className={isValidAmount ? "valid-input" : "error-input"}>{t("min") + ': ' + (c2c?.minAmount ?? 0)/100 + " AZN   " + t("max") + ': ' + (c2c?.maxAmount ?? 0)/100 + " AZN"}</p>
            </div>
          </div>

          <div className="transfer-card__hint">{t("commissionsCharged")}</div>
          {isAuth ? <></> :
            <div className='payment-recaptch'>
              {reCaptchaLoad &&
                <ContentLoader
                  speed={2}
                  width={304}
                  height={78}
                  viewBox="0 0 304 78"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="6" y="11" rx="0" ry="0" width="398" height="137" />
                  <rect x="123" y="64" rx="0" ry="0" width="49" height="7" />
                </ContentLoader>}
              <ReCAPTCHA
                sitekey={'6LfhleMoAAAAAIKvywPzSxtTk9K50bnPVl82gncg'}
                onChange={onRecaptchaChange}
                hl={language}
                asyncScriptOnLoad={() => {
                  setReCaptchaLoad(false)
                }}
              />
            </div>
          }
          {loading && (<Loading />)}
          <input
            disabled={!isValidAmount || (!isAuth && !reCaptcha)}
            type="submit"
            value={`${t("pay")}`}
            onClick={() => transfer()}
            className={`transfer-card__btn ${(isAuth || reCaptcha) && amountValue && isValidAmount ? "enabled-btn" : "disabled-btn"}`}
          />
        </div>
        <div className="transfer-card__app">
          <div className="transfer-card__app-other">
              <OtherPayments/>
          </div>
          <div className="transfer-card__app-install">
            <InstallApp/>
          </div>
        </div>
        <div className="transfer-card__app-swiper">
          <div className="layout__app-otherWrap">
            <div className='layout__app-otherWrap-border'></div>
            <div className="layout__app-otherSwiper">
              <OtherPaymentSwiper/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferCard1;
