import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  fetchAuth,
  fetchForgotPassword,
  fetchForgotPasswordChangePas,
  fetchForgotPasswordPasCheckOtp,
  fetchProfile,
  fetchProfileDetails,
  fetchRegister,
} from "../actions/authActions";
import {IForgotPasswordPayload} from "../../models/ForgotPassword";
import {IAuth, IUser} from "../../models/Auth";
import {ProfileServerResponse} from "../../models/Profile";
import {IProfileDetails, ProfileDetailsServerResponse,} from "../../models/ProfileDetails";
import {IAsanLogin} from "../../models/asanLogin";
import * as React from "react";
import {getUserAvatar, removeAvatar, setAvatar} from "../actions/profileActions";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface IAuthState {
  processStage: number,
  sessionId: string,
  asanUser: IAsanLogin | undefined,
  data: null;
  isLoading: boolean;
  resultCodes: string;
  description: string;
  isAuth: boolean;
  sid: string | null;
  user: IUser | null;
  profile: ProfileServerResponse | null;
  profileDetails: IProfileDetails | null;
  phone: string;
  otp: string;
  userAvatar: boolean,
  userAvatarSource:string
  showUnAuthModal: boolean,
}

const initialState: IAuthState = {
  processStage: 0,
  sessionId: '',
  asanUser: undefined,
  data: null,
  isLoading: false,
  resultCodes: "",
  description: "",
  user: null,
  isAuth: !!localStorage.getItem("token"),
  sid: "",
  profile: null,
  profileDetails: null,
  phone: "",
  otp: "",
  userAvatar: false,
  userAvatarSource: '',
  showUnAuthModal: false,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOut: (state) => {
      state.isAuth = false;
      state.sid = "";
      state.profile = null;
      state.userAvatar = false;
      state.userAvatarSource = '';
      localStorage.removeItem("token");
    },
    setShowUnAuthModal : (state, action:PayloadAction<boolean>) => {
        state.showUnAuthModal = action.payload;
    },
    unReadCount: (state, action) => {
      state.profile!.mailBox = action.payload
    },
    process_stage: (state,action) => {
        state.processStage = action.payload.processStage;
        state.sid = action.payload.processStage === 1 || action.payload.processStage === 2 ? action.payload.sid : state.sid;
    },
    asan_login: (state,action) => {
        state.asanUser = action.payload;
    },
    _userAvatar: (state, action: PayloadAction<boolean>) => {
      state.userAvatar = action.payload;
    },
    _userAvatarSource: (state, action: PayloadAction<string>) => {
      console.log('_userAvatarSource', action.payload);
      state.userAvatarSource = action.payload;
    },
    save_user: (state,action:PayloadAction<IAuth>) => {
        state.user = action.payload.user;
        state.sid = action.payload?.sid;
        localStorage.setItem("token", action.payload?.sid);
    },
    auth_user: (state, action) => {
      state.isAuth = true;
    },
    save_session_id: (state, action) => {
        state.sid = action.payload;
    },
    save_profile : (state, action) => {
        state.user = action.payload;
    }

  },
  extraReducers: {
    [fetchRegister.pending.type]: (
      state: IAuthState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = true;
    },
    [fetchRegister.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.resultCodes = action.payload?.resultCodes;
    },
    [fetchRegister.rejected.type]: (
      state: IAuthState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.data = null;

    },
    [getUserAvatar.pending.type]: (state: IAuthState,) => {state.userAvatar = true},
    [getUserAvatar.fulfilled.type]: (state, action) => {
      state.userAvatarSource = action.payload;
      state.userAvatar = false
    },
    [getUserAvatar.rejected.type]: (state: IAuthState) => {state.userAvatar = false},
    [removeAvatar.pending.type]: (state: IAuthState,) => {state.userAvatar = true},
    [removeAvatar.fulfilled.type]: (state) => {state.userAvatar = false},
    [removeAvatar.rejected.type]: (state: IAuthState) => {state.userAvatar = false},
    [setAvatar.pending.type]: (state: IAuthState,) => {state.userAvatar = true},
    [setAvatar.fulfilled.type]: (state) => {state.userAvatar = false},
    [setAvatar.rejected.type]: (state: IAuthState) => {state.userAvatar = false},

    [fetchAuth.pending.type]: (
      state: IAuthState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = true;
      // state.isAuth = false;
    },
    [fetchAuth.fulfilled.type]: (state, action: PayloadAction<IAuth>) => {
      state.sid = action.payload?.sid;
      state.isAuth = true;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    [fetchAuth.rejected.type]: (
      state: IAuthState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.data = null;
      state.isAuth = false;
    },
    [fetchForgotPassword.pending.type]: (state: IAuthState) => {
      state.isLoading = true;
    },
    [fetchForgotPassword.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.data = null;
      state.phone = action.payload?.phone;
      state.description = action.payload?.description;
      state.resultCodes = action.payload?.resultCodes;
    },
    [fetchForgotPassword.rejected.type]: (
      state: IAuthState,
      action: PayloadAction<IForgotPasswordPayload>
    ) => {
      state.isLoading = false;
      state.description = action.payload.description;
    },
    [fetchForgotPasswordPasCheckOtp.pending.type]: (state: IAuthState) => {
      state.isLoading = true;
    },
    [fetchForgotPasswordPasCheckOtp.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.data = null;
      state.phone = action.payload?.phone;
      state.otp = action.payload?.otp;
      state.description = action.payload?.description;
      state.resultCodes = action.payload?.resultCodes;
    },
    [fetchForgotPasswordPasCheckOtp.rejected.type]: (
      state: IAuthState,
      action: PayloadAction<IForgotPasswordPayload>
    ) => {
      state.isLoading = false;
      state.description = action.payload.description;
    },
    [fetchForgotPasswordChangePas.pending.type]: (state: IAuthState) => {
      state.isLoading = true;
    },
    [fetchForgotPasswordChangePas.fulfilled.type]: (
      state,
      action: PayloadAction<IForgotPasswordPayload>
    ) => {
      state.isLoading = false;
      state.data = null;
      state.description = action.payload.description;
      state.resultCodes = action.payload.resultCodes;
    },
    [fetchForgotPasswordChangePas.rejected.type]: (
      state: IAuthState,
      action: PayloadAction<IForgotPasswordPayload>
    ) => {
      state.isLoading = false;
      state.description = action.payload.description;
    },
    [fetchProfile.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileServerResponse>
    ) => {
      state.isLoading = false;
      state.profile = action.payload;
    },
    [fetchProfile.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchProfile.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      // state.error = action.payload
    },
    [fetchProfileDetails.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileDetailsServerResponse>
    ) => {
      state.isLoading = false;
      // state.error = ""
      // debugger
      state.profileDetails = action.payload.userDetails;
    },
    [fetchProfileDetails.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchProfileDetails.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      // state.error = action.payload
    },


  },
});

const selectAuthState = (state: RootState) => state.authReducer;

export const authSelector = createSelector(
    [selectAuthState],
    (authReducer) => authReducer
  );
  
export const selectIsAuth = (state: any) => Boolean(state.isAuth?.data);
export default authSlice.reducer;
