import {t} from "i18next";

export const weekDays = [
    {
        label: 'monday',
        value: 'MON',
    },
    {
        label: 'tuesday',
        value: 'TUE',
    },
    {
        label: 'wednesday',
        value: 'WED',
    },
    {
        label:  'thursday',
        value: 'THU',
        optional: 'THRUS',
    },
    {
        label: 'friday',
        value: 'FRI',
    },
    {
        label: 'saturday',
        value: 'SAT',
    },
    {
        label: 'sunday',
        value: 'SUN',
    },
]


export const monthDays: string[] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']

export const week: any = {
    'MON': 1,
    'TUE': 2,
    'WED': 3,
    'THU': 4,
    'FRI': 5,
    'SAT': 6,
    'SUN': 7,
    'THRUS': 4,
}

export const itemIcon = require("../../img/info-color-orange.svg").default;
export const azercellId = 976;

export const fieldsForPicker = [
    { label: "Visa", key: "newcard_visa", value: "newcard_visa" },
    { label: "MasterCard", key: "newcard_mc", value: "newcard_mc" },
];
export const cardSelectorCategories = new Set([9, 5, 10, 16]);
export const cardSelectorServices = new Set([1069]);
