import React, { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "../History.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IHistory } from "../../../models/Bonuses";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { useAppSelector } from "../../../hooks/redux";
import { fetchHistoryReceipt, fetchPrintedHistory, } from "../../../redux/actions/bonusesAction";
import ReactToPrint from "react-to-print";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import { addToFavoritesFetch, deleteFromFavoritesFetch, } from "../../../redux/actions/favoritesListAction";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { toQueryParams } from "../../../utils/query";
import { useTranslation } from "react-i18next";
import { Category, ICategories, LocaleName } from "../../../models/Categories";
import "moment/locale/ru";
import "moment/locale/en-gb";
import "moment/locale/az";
import { notify } from "../../../helper";

const share = require("../../../img/share.svg").default;
const bonus = require("../../../img/bonusSmall.svg").default;
const repeat = require("../../../img/repeat.svg").default;
const message = require("../../../img/message.svg").default;
const favorites = require("../../../img/favorites1.svg").default;
const closeIcon = require("../../../img/close-icon.svg").default;
const favoritesFilled = require("../../../img/favorites-fill.svg").default;

interface IHistoryItem {
  item: IHistory;
  historyItemLoading: boolean;
}

type PrintInfoType = {
  orderId: string;
  afterPayment: boolean;
  historyId: number;
};

type receiptDataType = {
  email: string;
  phone: string;
  orderId: string;
  sendType: string;
  historyId: number;
  afterPayment: boolean;
};

const HistoryItem: FC<IHistoryItem> = ({ item, historyItemLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { favList } = useAppSelector((state) => state.favList);
  const { profile } = useAppSelector((state) => state.authReducer);
  const { language } = useAppSelector((state) => state.changeLangSlice);

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const fieldsFromCheck = item?.fields?.historyField?.map((item: any) => ({
    id: item.fieldId.toString(),
    name: item.name,
    value: item.value,
  }));

  const [savedCheckData, setSavedCheckData] = useState<IHistory | null>(null);
  const [modalActive, setModalActive] = useState(false);
  const [receiptData, setReceiptData] = useState<receiptDataType>({
    email: "",
    phone: "",
    orderId: "",
    sendType: "email",
    historyId: 0,
    afterPayment: true,
  });

  const { langKey } = useAppSelector((state) => state.changeLangSlice);
  const { services, categories } = useAppSelector(state => state.categoriesReducer);
  const [favInfo, setFavInfo] = useState({ isFav: false, favId: 0 });
  const checkEl: any | null = useRef(null);
  const azercellId = 976;

  const isMainField = item?.fields?.historyField?.find(
    (field: any) => field?.isMainField
  );
  const langHistory = localStorage.getItem("language");

  const categoryName = categories?.find((category: Category) => category.id === item.categoryId)?.name;
  const serviceName = services?.find((service: any) => service.id === item.normalizedServiceId);

  useEffect(() => {
    if (modalActive) {
      setValue("email", profile?.user?.email);
      const data: PrintInfoType = {
        orderId: item.orderId,
        historyId: item.id,
        afterPayment: true,
      };
      dispatch(fetchPrintedHistory(data)).then((res) => {
        setSavedCheckData(res.payload.item);
      });
    }
  }, [modalActive]);

  useEffect(() => {
    checkFavoriteItem();
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const onSubmit = (data: any) => {
    dispatch(fetchHistoryReceipt({ ...receiptData, email: data.email })).then(
      (res: any) => {
        if (res.payload.status === 200) {
          notify(`${t("successfullySent")}`, true);
        } else {
          notify(`${t("sendingError")}`, false);
        }
      }
    );
    reset();
    setModalActive(false);
  };

  const openModal = () => {
    setReceiptData({
      ...receiptData,
      orderId: item?.orderId,
      historyId: item?.id,
    });
    setModalActive(true);
  };
  const toggleFav = () => {
    if (favInfo.isFav) {
      setFavInfo({ ...favInfo, isFav: false });
      deleteFromFavorites();
    } else {
      setFavInfo({ ...favInfo, isFav: true });
      addToFavorites();
    }
  };

  const deleteFromFavorites = () => {
    dispatch(deleteFromFavoritesFetch(favInfo.favId)).then((res: any) => {
      if (res.payload.status === 200) {
        notify(`${t("removedFromFavorites")}`, true);
      } else {
        notify(`${t("errorPaymentToFavorites")}`, false);
      }
    });
  };

  const getHistoryUrl = () => {
    const data: any = {
      groupId: item.groupId,
    };
    item?.fields?.historyField?.forEach((field, index, historyField) => {
      if (azercellId === item.serviceId) {
        if (index === 1) {
          data[field.name] = historyField[index - 1].value + field.value;
        }
      } else {
        data[field.name] = field.value;
      }
    });

    return toQueryParams(data);
  };

  const addToFavorites = () => {
    const fieldsJson = JSON.stringify({ fields: fieldsFromCheck });

    const data = {
      comment: "",
      fields: fieldsJson,
      serviceId: item.normalizedServiceId,
      selectedGroupId: item!.groupId,
    };
    dispatch(addToFavoritesFetch(data)).then((res: any) => {
      if (res.payload.status === 200) {
        notify(`${t("successfullyAddedToFavorites")}`, true);
      } else {
        notify(`${t("ErrorAddingPaymentToFavorites")}`, false);
      }
    });
  };

  const checkFavoriteItem = () => {
    const mainField = item.fields.historyField.find(
      (field) => field.isMainField
    );
    for (let i = 0; i < favList?.items?.paymentListItem?.length!; i++) {
      const currentFav = favList?.items?.paymentListItem[i];
      if (
        currentFav?.serviceId === item.normalizedServiceId &&
        currentFav?.groupId === item.groupId
      ) {
        setFavInfo({
          isFav: true,
          favId: currentFav?.id,
        });
      } else if (mainField) {
        if (mainField?.serviceId === currentFav?.serviceId) {
          setFavInfo({
            isFav: true,
            favId: currentFav.id,
          });
        }
      }
    }
  };
  const onPrintClick = () => {
    if (checkEl.current) {
      checkEl.current.style.display = "block";
    }
  };

  moment.lang("ru");

  const fields = item?.fields?.historyField?.map((field) => ({
    id: field?.serviceId?.toString(),
    name: field?.name,
    value: field?.value,
    qrfield: false,
    barfield: false,
  }));

  let newFields: any = [];
  if (item.serviceId === azercellId) {
    newFields = fields;
  } else {
    newFields = [fields[0]]
  }

  const clickToRepeat = () => {
    const path =
      item.serviceId === 245
        ? `/epay/${language}/transfer-card/${item.serviceId}?${getHistoryUrl()}`
        : `/epay/${language}/payment-add/category/${categoryName}/${serviceName?.friendlyName}`;
    navigate(path, {
      state: {
        serviceId: item.normalizedServiceId || item.serviceId,
        fields: newFields,
        groupId: item?.groupId,
      },
    });
  };

  const showFieldName = (field: any) => {
    if (langKey === 'valueAz') {
      return field?.localName?.valueAz
    }
    else if (langKey === 'valueRu') {
      return field?.localName?.valueRu
    }
    else if (langKey === 'valueEn') {
      return field?.localName?.valueEn
    }
  }

  return (
    <>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div key={item.id} className="history-left__body-item">
            <Typography>
              <div className="history-left__body-time gray-small-time">
                {moment(item.insertDate).locale(`${langHistory}`).format("ll")}
              </div>
            </Typography>
            <div style={{ minWidth: 60 }}>
              <img
                className="history-left__body-logo"
                src={
                  "https://www.e-pul.az/epul_api/images/" +
                  item.friendlyName +
                  ".png"
                }
                onError={(event) => {
                  //@ts-ignore
                  event.target.src = 'https://placehold.co/60x60/EF5C27/FFF';
                }}
                alt=""
              />
            </div>
            <div className="history-left__body-name__block">
              <div className="history-left__body-name">
                {item.serviceId === azercellId
                  ? item?.fields?.historyField?.map(
                    (field: any, index, historyArr) => {
                      if (index === 1) {
                        return historyArr[index - 1].value + field.value;
                      }
                    }
                  )
                  : isMainField?.value}
              </div>
              <div className="history-left__body-type gray-small">
                {item.localName[langKey as keyof LocaleName]}
              </div>
            </div>
            <div className="history-left__body-amount">
              {(item.amount / 100).toFixed(2)}
              <div className="history-left__body-amount__currency">
                {item.currency}
              </div>
            </div>
            <div className="history-left__body-result">
              {item.status === 3 ? (
                <div style={{ color: "#8AC12D" }}>{t("successfully")}</div>
              ) : item.status === 10 ? (
                <div style={{ color: "#FF4239" }}>{t("error")}</div>
              ) : item.status === 0 || item.status === 1 || item.status === 2 ? (
                <div style={{ color: "#FDB22A" }}>{t("inProcessing")}</div>
              ) : item.status === 20 ? (
                <div style={{ color: "#FDB22A" }}>{t("reversed")}</div>
              ) : item.status === 30 ? (
                <div style={{ color: "#FF4239" }}>{t("fraud")}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="history__details">
            <div className="history-accordion">
              <div className="history-accordion__left">
                <div className="history-accordion-col">
                  <div className="history-accordion-amount gray-small">
                    <div className="history-accordion__title">
                      {t("service")}
                    </div>
                    <div className="history-accordion__body">
                      {showFieldName(item)}
                    </div>
                  </div>
                </div>
                <div className="history-accordion-col">
                  <div className="history-accordion-amount gray-small">
                    <div className="history-accordion__title">
                      {t("amount")}
                    </div>
                    <div className="history-accordion__body">
                      {(item.amount / 100).toFixed(2)}
                      <div className="history-left__body-amount__currency">
                        {item.currency}
                      </div>
                    </div>
                  </div>
                </div>
                {item.pan && (
                  <div className="history-accordion-col">
                    <div className="history-accordion-typeOfPayment gray-small">
                      <div className="history-accordion__title ">
                        {t("paymentMethod")}
                      </div>
                      <div className="history-accordion__body">{item.pan}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="history-accordion__right">
                {item?.fields?.historyField?.map(
                  (field: any, index, historyArr) => {
                    if (item.serviceId === 976) {
                      if (index === 1) {
                        return (
                          <div className="history-accordion-col">
                            <div className="history-accordion__title">
                              {field?.localName[langKey as keyof LocaleName]}
                            </div>
                            <div className="history-accordion__body gray-small">
                              {historyArr[index - 1].value + field.value}
                            </div>
                          </div>
                        );
                      }
                    } else {
                      return (
                        <div className="history-accordion-col">
                          <div className="history-accordion__title ">
                            {showFieldName(field)}
                          </div>
                          <div className="history-accordion__body gray-small">
                            {field.value}
                          </div>
                        </div>
                      );
                    }
                  }
                )}
                <div className="history-accordion-col">
                  <div className="history-accordion-bonus">
                    <div className="history-accordion__title">
                      {t("bonusesReceived")}
                    </div>
                    <div className="history-accordion__body bonus">
                      {item.bonusAmount} <img src={bonus} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="history-control__buttons">
              <div className="secondStyles">
                <Button
                  padding="12px 20px"
                  margin="0 10px 0 0"
                  borderRadius={5}
                  buttonText={t("getReceipt")}
                  color="rgba(255, 66, 57, 1)"
                  background="#FFEAE9"
                  icon={share}
                  onClick={() => openModal()}
                />
              </div>
              {item?.serviceId === 326 ||
                item?.serviceId === 325 ||
                item?.serviceId === 327 ||
                item?.serviceId === 1447 ? null : (
              <>
                <div className="secondStyles">
                  <Button
                    padding="12px 20px"
                    margin="0 10px 0 0"
                    borderRadius={5}
                    buttonText={
                      favInfo.isFav
                        ? `${t("inTheTemplates")}`
                        : `${t("inTemplates")}`
                    }
                    color="#9673FF"
                    background="#F3EFFF"
                    icon={favInfo.isFav ? favoritesFilled : favorites}
                    onClick={() => toggleFav()}
                  />
                </div>
                <div className="secondStyles">
                  <Button
                    icon={repeat}
                    color="#8AC12D"
                    margin="0 10px 0 0"
                    padding="12px 20px"
                    onClick={() => clickToRepeat()}
                    buttonText={t("repeat")}
                    background="#F0FFD9"
                    borderRadius={5}
                  />
                </div>
              </>
              )}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className="modal-history">
        <CustomModal active={modalActive} setActive={setModalActive}>
          <div className="receipt">
            <div
              className="receipt__close-icon"
              onClick={() => setModalActive(false)}
            >
              <img
                src={closeIcon}
                alt="cross icon"
              />
            </div>
            <div className="receipt__content">
              <div className="receipt__field">
                <label
                  className="receipt__field-label"
                  htmlFor="receiptEmail"
                >
                  Email
                </label>
                <input
                  className="receipt__field-input"
                  {...register("email", {
                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    required: true,
                  })}
                  id="receiptEmail"
                  placeholder="Email"
                />
                <div
                  className="receipt__message-icon"
                  onClick={() => setModalActive(false)}
                >
                  <img
                    src={message}
                    alt="cross icon"
                  />
                </div>
                {errors?.email && (
                  <div className="receipt__error">
                    {t("errorMessagePlease")}
                  </div>
                )}
              </div>
              <div className="receipt__btns">
                <div
                  className="receipt__btn receipt__btn--send"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("sendToMail")}
                </div>
                <ReactToPrint
                  trigger={() => (
                    <div
                      className="receipt__btn receipt__btn--print"
                      onClick={() => onPrintClick()}>
                      {t("print")}
                    </div>
                  )}
                  content={() => checkEl?.current}
                  pageStyle="print"
                  documentTitle="Onlayn Ödə | Onlayn Ödəmə Sistemi - EPUL"
                />
              </div>
            </div>
          </div>
          {/*<div className="receipt-modal">*/}
          {/*  <div*/}
          {/*      className="receipt-modal__icon"*/}
          {/*      onClick={() => setModalActive(false)}*/}
          {/*  >*/}
          {/*    <img*/}
          {/*        className="receipt-modal__img"*/}
          {/*        src={closeIcon}*/}
          {/*        alt="cross icon"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <form*/}
          {/*      className="receipt-modal__form"*/}
          {/*      onSubmit={handleSubmit(onSubmit)}*/}
          {/*  >*/}
          {/*    <label*/}
          {/*        className="receipt-modal__input-label"*/}
          {/*        htmlFor="receiptEmail"*/}
          {/*    >*/}
          {/*      Email*/}
          {/*    </label>*/}
          {/*    <div className="receipt-modal__input-box">*/}
          {/*      <input*/}
          {/*          {...register("email", {*/}
          {/*            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,*/}
          {/*            required: true,*/}
          {/*          })}*/}
          {/*          className="receipt-modal__input"*/}
          {/*          type="text"*/}
          {/*          id="receiptEmail"*/}
          {/*          placeholder="Email"*/}
          {/*      />*/}
          {/*      <div className="receipt-modal__message-icon">*/}
          {/*        <img*/}
          {/*            className="receipt-modal__img"*/}
          {/*            src={message}*/}
          {/*            alt="email icon"*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      {errors?.email && (*/}
          {/*          <div className="receipt-modal__error">*/}
          {/*            {t("errorMessagePlease")}*/}
          {/*          </div>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*    <div className="receipt-modal__footer">*/}
          {/*      <input*/}
          {/*          className="receipt-modal__send-btn"*/}
          {/*          type="submit"*/}
          {/*          value={`${t("sendToMail")}`}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </form>*/}
          {/*  <ReactToPrint*/}
          {/*      trigger={() => (*/}
          {/*          <button*/}
          {/*              className="receipt-modal__print-btn"*/}
          {/*              onClick={() => onPrintClick()}*/}
          {/*          >*/}
          {/*            {t("print")}*/}
          {/*          </button>*/}
          {/*      )}*/}
          {/*      content={() => checkEl?.current}*/}
          {/*      pageStyle="print"*/}
          {/*      documentTitle="Onlayn Ödə | Onlayn Ödəmə Sistemi - EPUL"*/}
          {/*  />*/}
          {/*</div>*/}
        </CustomModal>
      </div>
      <div style={{ display: "none" }}>
        <div className="check_print" ref={checkEl}>
          <div className="check_print-items">
            <div className="check_print-item">
              <div className="check_print-title">
                {t("history").toUpperCase()}
              </div>
              <div className="check_print-body">
                {moment(savedCheckData?.insertDate).format("DD.MM.YYYY HH:mm")}
              </div>
            </div>

            <div className="check_print-item">
              <div className="check_print-title">
                {t("quantity").toUpperCase()}
              </div>
              <div className="check_print-body check_print-amount">
                {savedCheckData && (+savedCheckData?.amount / 100).toFixed(2)}
                <div className="check_print-amount-currency">
                  {savedCheckData?.currency === "AZN" ? "AZN" : "$"}
                </div>
              </div>
            </div>
            <div className="check_print-data">
              <div className="check_print-title">{t("checkDetails")}</div>
              <div className="check_print-data_items">
                <div className="check_print-data-item">
                  <div className="check_print-data-title">
                    {t("chequeNumber")}
                  </div>
                  <div className="check_print-data-body">
                    {savedCheckData?.id}
                  </div>
                </div>
                <div className="check_print-data-item">
                  <div className="check_print-data-item-title">
                    {t("serviceC")}
                  </div>
                  <div className="check_print-data-item-body">
                    {savedCheckData?.localName[langKey]}
                  </div>
                </div>
                <div className="check_print-data-item">
                  <div className="check_print-data-item-title">
                    {t("customer")}
                  </div>
                  <div className="check_print-data-item-body">
                    {savedCheckData?.person || savedCheckData?.firstName}
                  </div>
                </div>
                {item.fields.historyField.map((data) => {
                  return (
                    <div key={data.fieldId} className="check_print-data-item">
                      <div className="check_print-data-item-title">
                        {data.localName?.valueAz + ":"}
                      </div>
                      <div className="check_print-data-item-body">
                        {data.value === "A"
                          ? data?.enumFieldValue["valueAz"]
                          : data?.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="check_print-payment-method">
            {t("paymentMet")}
            <div className="check_print-payment-method-icon">
              <img
                src={require("../../../img/categories/payment-method.png")}
                alt="payment method"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryItem;
