import moment from 'moment'
import React from 'react'
import './index.scss'
import { connect } from 'react-redux'
import { NavigateFunction } from 'react-router-dom'
import withRouter from '../../HOC/widthRouter'
import { Languages } from '../../redux/reducers/language/languageData'
import { MessageType } from '../../redux/reducers/msgToUser/types'
import { IAuthState } from '../../redux/slices/auth'
import {
  IMasterPassState,
  masterPassSliceActions,
} from '../../redux/slices/masterpassSlice'
import { RootState } from '../../redux/store'
import { fetchWithTimeout, parseQuery, toQueryParams } from '../../utils/query'
import { LoadingActionCreators } from '../../redux/reducers/loading/action-creators'
import { IMasterPassData } from '../../models/MasterPassData'
import { IMpCardData } from '../../models/MpCardData'
import { cardsSliceActions } from '../../redux/slices/cardsSlice'
import { ICard } from '../../models/Card'
import CustomModal from '../../Components/ModalComponent/CustomModal/CustomModal'
import client, { BASE_URL } from '../../axios'
import { fetchWalletList } from '../../redux/actions/walletListAction'
import { notify } from '../../helper'
import { t } from 'i18next'
import { useAppSelector } from '../../hooks/redux'
import { toast } from 'react-toastify'

const document = (window as any).document
const MFS = (window as any).MFS
const $ = (window as any).$

type Props = {
  navigate: NavigateFunction
  masterPass: IMasterPassState
  auth: IAuthState
  language: string
  loading: any
  setMasterPassForms: (ref: MasterPassWebviewType) => void
  loadingPaymentState: (state: boolean) => void
  loadingState: (state: boolean) => void
  setMasterPassCards: (payload: IMpCardData[]) => void
  setMasterPassData: (payload: IMasterPassData) => void
  setDefaultPan: (payload: string) => void
  setCards: (payload: ICard[]) => void
  setLoading: (payload: boolean) => void
}

type State = {
  isModalVisible: boolean
  linkCards: boolean
  checkForLink: boolean
  isCorrect: boolean
  code: string
  pinType: string
  referenceNo?: string
}

enum Operation {
  AddCard,
  Payment,
  AddRecurring,
  DeleteRecurring,
  Registration,
  C2CGuest,
}

type GuestC2C = {
  cardNumber: string
  month: string
  year: string
  cvv: string
  phoneNumber: string
  recipientCardNumber: string
  amountValue: string
  currency: string
}

export interface NewCardData {
  cardNumber: string
  year: string
  month: string
  cvv: string
  cardName?: string
}

export interface ServiceData {
  serviceId: number
  amount: number
  fields: string
  payAllDebt: boolean
  subName: string
  subVal: string
  desc: string
  lng: string
  selectedGroupId: number
}

export interface IWalletPurchaseData {
  amount: number
  historyId: number
  masterCardCanFail: boolean
}

export interface MfsResponse {
  accountStatus: string
  token: string
  referenceNo: string
  responseCode: string
  responseDescription: string
  url3D: string
  url3DSuccess: string
  url3DError: string
  newMsisdn: string
  internalResponseCode: string
  internalResponseDescription: string
  transactionId: string
  cards: MfsCard[]
}

export interface MfsCard {
  BankIca: string
  CardStatus: string
  EftCode: string
  IsMasterPassMember: string
  LoyaltyCode: string
  Name: string
  ProductName: string
  PromtCpin: string
  UniqueId: string
  Value1: string
  Value2: string
}

export interface MasterPassWebviewType {
  addCard: (
    cardName: string,
    cardNumber: string,
    year: string,
    month: string,
    cvv: string,
    success: () => void,
  ) => void
  deleteCard: (cardName: string, success: () => void) => void
  payWithNewCard: (
    cardData: NewCardData,
    serviceData: ServiceData,
    success?: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => void
  completeRegistration: (cardName: string, lastToken: string) => void
  payWithSavedCard: (
    bindingId: string,
    serviceData: ServiceData,
    success?: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => void
  payWithNewCard2Card: (
    cardData: NewCardData,
    data: IWalletPurchaseData,
  ) => void
  payWithSavedCard2Card: (bindingId: string, data: IWalletPurchaseData) => void
  addRecurring: (
    serviceId: number,
    cardName: string,
    mirrorId: string,
    amount: number,
    success: () => void,
  ) => void
  editRecurring: (
    serviceId: number,
    cardName: string,
    mirrorId: string,
    amount: number,
    success: () => void,
  ) => void
  deleteRecurring: (
    serviceId: number,
    cardName: string,
    mirrorId: string,
    success: () => void,
  ) => void
  init: (phone?: string) => void
  guestPayCard2Card: (cardData: GuestC2C) => void
  getToken: (sessionId: string, loadCards?: boolean) => void

  doPayMasterPassC2c: (
    bindingId: string,
    amount: number,
    pan: string,
    currency: string,
    exp?: string,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => Promise<
    | {
        historyId: number
        amount: number
      }
    | undefined
  >

  doPayByBonuses: (
    serviceId: number,
    amount: number,
    fields: string,
    payAllDebt: boolean,
    subName: string,
    subVal: string,
    desc: string,
    selectedGroupId: number,
    success: (orderId: string, historyId: number) => void,
    fail?: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => void

  doPayWithWallet: (
    serviceId: number,
    bindingId: string,
    amount: number,
    fields: string,
    payAllDebt: boolean,
    subName: string,
    subVal: string,
    desc: string,
    selectedGroupId: number,
    success: (orderId: string, historyId: number) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => void

  payWithPulpal: (
    giftId: number,
    serviceData: ServiceData,
    openWebview: (url: string) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => void

  giftToUser: (
    giftId: number,
    phoneNumber: string | undefined,
    minutes: number | undefined,
    success: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => void

  transferToWallet: (
    sourceWalletCurrency: string,
    longAmount: number,
    recipientWalletPhone: string,
    recipientWalletCurrency: string,
    comment?: string,
    success?: (result: { orderId: string; historyId: number }) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => Promise<void>
}

class MasterPassWebview
  extends React.Component<Props, State>
  implements MasterPassWebviewType
{
  cardName?: string
  operation?: Operation
  amount?: number
  referenceNo?: string
  macroMerchantId?: string
  deleteRecurringCallback?: () => void
  addRecurringCallback?: () => void
  removeCardCallback?: () => void
  masterPassClientID?: string
  phone?: string
  checkDone: boolean = false
  paymentSucceed: (() => void) | undefined = undefined
  guestC2CCurrency: string = ''
  analyticsData: any = undefined
  appsFlyerData: any = undefined
  getTokenInProgress: boolean = false
  mirrorId?: string

  constructor(props: Props) {
    super(props)
    this.state = {
      isModalVisible: false,
      isCorrect: false,
      linkCards: false,
      checkForLink: false,
      code: '',
      pinType: 'otp',
    }
  }

  componentDidMount(): void {
    this.init(undefined, true)
    this.props.setMasterPassForms(this)
  }

  authToken() {
    const token = localStorage.getItem('token')
    return token || ''
  }

  init = async (phoneArg?: string, reinit?: boolean) => {
    var date = new Date()
    var offsetInHours = -date.getTimezoneOffset() / 60
    let timeZone
    if (offsetInHours == 0) {
      timeZone = '+00'
    } else if (offsetInHours > 9) {
      timeZone = '+' + offsetInHours
    } else if (offsetInHours > 0) {
      timeZone = '+0' + offsetInHours
    } else if (offsetInHours < -9) {
      timeZone = '-' + -offsetInHours
    } else {
      timeZone = '-0' + -offsetInHours
    }
    // TODO: set real ip

    const phone = (phoneArg || this.props.auth.profile?.user?.phone)?.replace(
      '+',
      '',
    )
    const phoneChanged = phone !== this.phone
    this.phone = phone

    await this.getToken(this.authToken(), phoneChanged)

    if (!this.props.masterPass.masterPassData?.token && !reinit) {
      // console.log('this.props.masterPass.masterPassData?.token', this.props.masterPass.masterPassData?.token)
      return
    }

    if (!phoneChanged && !reinit) {
      return
    }

    MFS.setClientId(this.props.masterPass.masterPassData?.masterPassClientID)
    MFS.setAddress('https://ui.masterpassturkiye.com/v2')
    MFS.setAdditionalParameters(
      {
          "card_holder_name":`${this.props.auth.profile?.user?.firstName + ' ' + this.props.auth.profile?.user?.lastName}`,
          "bill_email":`${this.props.auth.profile?.user?.email || 'support@e-pul.az'}`,
          "browser_width":`${window.screen.width}`,
          "browser_height":`${window.screen.height}`
      }
    );

    $('[name="msisdn"]').val(phone)
    $('[name="userId"]').val(phone)
    $('[name="token"]').val(this.props.masterPass.masterPassData?.token)
    $('[name="referenceNo"]').val(
      this.props.masterPass.masterPassData?.referenceNumber,
    )
    $('[name="sendSmsLanguage"]').val(
      this.props.language === 'en' ? 'eng' : 'aze',
    )
    $('[name="clientIp"]').val('0.0.0.0')
    $('[name="timeZone"]').val(timeZone)

    if (!phone) {
      return
    }

    this.startCheck()
  }

  startCheck = () => {
    if (this.checkDone) {
      return
    }
    this.checkInternal()
  }

  checkMasterPassAccount = (code: string) => {
    //successful found masterpass account and linked to e-pul
    if (code.substring(1).startsWith('11100')) {
      return 1
    }
    //successful found masterpass account but have not linked to e-pul
    else if (
      code.substring(1).startsWith('1100') ||
      code == '1000001100000000'
    ) {
      return 2
    }
    //masterpass accound have not found
    else {
      return 3
    }
  }

  checkResponse = (response: MfsResponse) => {
    this.checkDone = true
    if (response.responseCode == '0000' || response.responseCode == '') {
      const code = this.checkMasterPassAccount(response.accountStatus)
      if (code === 1) {
        this.fetchDefaultPan()
        MFS.listCards(
          this.phone,
          this.props.masterPass.masterPassData?.token,
          this.listCardsResponseHandler,
        )
      } else if (code === 2) {
        this.setState({ isModalVisible: true, linkCards: true })
      }
    }
  }

  deleteResponse = (response: MfsResponse) => {
    this.props.loadingPaymentState(false)
    if (response.responseCode === '0000' || response.responseCode === '') {
      MFS.listCards(
        this.phone,
        this.props.masterPass.masterPassData?.token,
        this.listCardsResponseHandler,
      )
    }
  }

  linkResponse = (response: MfsResponse) => {
    if (response.responseCode === '0000' || response.responseCode === '') {
      this.fetchDefaultPan()
      MFS.listCards(
        this.phone,
        this.props.masterPass.masterPassData?.token,
        this.listCardsResponseHandler,
      )
    } else {
      let pinType
      switch (response.responseCode) {
        case '5000': // RTA
          pinType = 'rta'
          break
        case '5015': // MPIN
          pinType = 'mpin'
          break
        case '5008': // Ask Masterpass OTP
        case '5001': // Ask OTP
          pinType = 'otp'
          break
        default:
          return
      }
      this.props.loadingPaymentState(false)
      this.setState({
        isModalVisible: !this.state.isModalVisible,
        pinType,
        referenceNo: response.referenceNo,
        checkForLink: true,
      })
    }
  }

  linkCards = () => {
    this.toggleModal()
    this.linkCardToClientInternal()
  }

  toggleModal = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      linkCards: false,
      checkForLink: false,
    })
  }

  modalWindow(language: any) {
    return (
      <CustomModal
        active={this.state.isModalVisible}
        setActive={this.toggleModal}
      >
        <div className='masterPass-modal'>
          <div className='masterPass-modal__header'>
            <div
              className='masterPass-modal__close-icon'
              onClick={() => this.toggleModal()}
            >
              <img
                  width={20}
                  height={20}
                className='masterPass-modal__close-img'
                src={require('../../img/close-icon.svg').default}
                alt='close icon'
              />
            </div>
          </div>
          {this.state.linkCards ? (
            <div className={'masterPass-modal__content-body'}>
              <p
                className={'masterPass-modal__content-text'}
                style={{ color: '#000000' }}
              >
                {/*{language.linkCards}*/}
                Sizin Masterpass hesabınıza bağlı kartınız var. Kartı E-pul-da
                istifadə etmək istəyirsiniz?
              </p>
              <button
                className={'masterPass-modal__content-btn'}
                onClick={() => this.linkCards()}
              >
                {/*{language.yes}*/}
                Bəli
              </button>
              <button
                className={
                  'masterPass-modal__content-btn' +
                  ' masterPass-modal__content-btn--cancel'
                }
                onClick={() => this.toggleModal()}
              >
                {/*{language.cancel}*/}
                İmtina
              </button>
            </div>
          ) : (
            <div className={'masterPass-modal__content-body'}>
              <p className={'masterPass-modal__content-text'}>
                {this.state.pinType === 'rta'
                  ? 'Xahiş edirik bank təsdiqləmə kodu daxil edəsiniz (bank sms vasitəsilə kodu göndərib)'
                  : 'Xahiş edirik OTP kodu daxil edəsiniz (bank sms vasitəsilə kodu' +
                    ' göndərib və ya kartın balansından təsaduf məbləğ bloklanıb)'}
              </p>
              <input
                className={'masterPass-modal__content-field'}
                type='text'
                onChange={e => {
                  e.target.value.trim() === ''
                    ? this.setState({ code: e.target.value, isCorrect: false })
                    : this.setState({ code: e.target.value, isCorrect: true })
                }}
                name="masterPass-modal__content-field"
                placeholder={'Birdəfəlik şifrə'}
                defaultValue={this.state.code}
              />
              <button
                className={'masterPass-modal__content-btn'}
                onClick={() => this.confirmOtp()}
              >
                {/*{language.confirm}*/}
                Təsdiq edin
              </button>
            </div>
          )}
        </div>
      </CustomModal>
      // <View>
      //     <Modal
      //         transparent
      //         animationType="fade"
      //         visible={this.state.isModalVisible}>
      //         <TouchableOpacity
      //             style={modalStyles.backgroundStyle}
      //             activeOpacity={1}
      //             onPressOut={() => {
      //                 this.toggleModal();
      //             }}>
      //             <View style={modalStyles.containerStyle}>
      //                 <View style={Platform.OS === 'ios' ? modalStyles.windowStyleIOS : modalStyles.windowStyleAndroid}>
      //                     <View style={{ justifyContent: 'center' }}>
      //                         {this.state.linkCards ? <>
      //                             <View style={{ paddingBottom: 10 }}>
      //                                 <Text style={textStyles.textBlack14M}>
      //                                     {language.linkCards}
      //                                 </Text>
      //                             </View>
      //                             <View style={{ marginTop: 5 }}>
      //                                 <TouchableOpacity
      //                                     style={buttonStyles.filledButton}
      //                                     onPress={() => {
      //                                         this.linkCards();
      //                                     }}>
      //                                     <Text style={buttonStyles.filledButtonText}>{language.yes}</Text>
      //                                 </TouchableOpacity>
      //                                 <TouchableOpacity style={modalStyles.cancelButton}
      //                                     onPress={() => this.toggleModal()}>
      //                                     <Text style={textStyles.textOrange14M}>
      //                                         {language.cancel}
      //                                     </Text>
      //                                 </TouchableOpacity>
      //                             </View>
      //                         </> : <>
      //                             <View style={{ paddingBottom: 10 }}>
      //                                 <Text style={textStyles.textBlack14M}>
      //                                     {this.state.pinType === 'rta' ? language.rtaInfo : language.otpInfo}
      //                                 </Text>
      //                             </View>
      //                             <TextInput
      //                                 onChangeText={(text) => {
      //                                     text.trim() === '' ?
      //                                         this.setState({ code: text, isCorrect: false }) : this.setState({ code: text, isCorrect: true })
      //                                 }}
      //                                 placeholder={language.code}
      //                                 placeholderTextColor={AppStyles.colors.placeholderColor}
      //                                 defaultValue={this.state.code}
      //                                 style={styles.inputStyle}
      //                                 keyboardType={'phone-pad'}
      //                             />
      //                             <View style={{ marginTop: 5 }}>
      //                                 <TouchableOpacity
      //                                     style={this.state.isCorrect ? buttonStyles.filledButton : [buttonStyles.filledButton, { backgroundColor: '#EF5C274D' }]}
      //                                     onPress={() => {
      //                                         this.confirmOtp();
      //                                     }}>
      //                                     <Text style={buttonStyles.filledButtonText}>{language.confirm}</Text>
      //                                 </TouchableOpacity>
      //                             </View>
      //                         </>}
      //                     </View>
      //                 </View>
      //             </View>
      //         </TouchableOpacity>
      //     </Modal>
      // </View>
    )
  }

  confirmOtp() {
    const checkForLink = this.state.checkForLink
    this.setState({ isModalVisible: false, checkForLink: false })
    this.props.loadingPaymentState(true)
    MFS.setClientId(
      this.masterPassClientID ||
        this.props.masterPass.masterPassData?.masterPassClientID,
    )
    this.validateTransactionInternal(
      this.state.pinType === 'rta'
        ? this.state.code.replace('.', '').replace(',', '')
        : this.state.code,
      this.state.referenceNo,
      this.state.pinType,
      checkForLink,
    )
  }

  async response(response: MfsResponse) {
    let pinType = 'otp'

    if (this.operation === Operation.Registration) {
      return
    }
    if (this.operation === Operation.Payment) {
      let token = response.token
      if (response.responseCode === '5010') {
        token = MFS.getLastToken()
      }
      await this.saveToken(Number(this.referenceNo), this.referenceNo!, token)
    } else if (this.operation === Operation.C2CGuest) {
      const arr = response.url3D.split('token=')
      const c2cGuestToken = arr.length === 2 ? arr[1] : ''

      if (c2cGuestToken) {
        try {
          const jsonObject = JSON.stringify(response)
          const params = {
            jsonObject,
            token: c2cGuestToken,
            referenceNumber: this.referenceNo,
          }
          const res = await client.post<any>(
            `open_c2c/saveToken`,
            { body: JSON.stringify(params) },
            {
              // method: "POST",
              headers: {
                'Content-Type': 'application/json',
                // "Payment-Origin": "Web",
              },
              // body: JSON.stringify(params),
            },
          )
          // if (res.status !== 200) {
          //   this.messageToUser(MessageType.SomeError);
          //   // console.log("saveTokenErrorResponse", res);
          //   // console.log("saveTokenErrorJson", await res);
          //   return;
          // } else {
          //   // console.log("saveTokenSuccessResponse", res);
          //   // console.log("saveTokenSuccessJson", await res);
          // }
        } catch (e) {
          this.messageToUser(MessageType.SomeError)
          console.log('saveTokenCatchError', e)
          return
        }
      } else {
        this.showError('Error!')
        return
      }
    }

    switch (response.responseCode) {
      case '':
      case '0000':
        this.props.loadingPaymentState(false)
        if (this.operation === Operation.Payment) {
          await this.commmit(
            response.token,
            Number(this.amount),
            this.macroMerchantId || '',
            (orderId, historyId) => {
              this.props.navigate(
                `/epay/${this.props.language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`,
              )
            },
          )
        } else if (this.operation === Operation.AddRecurring) {
          this.addRecurringCallback && this.addRecurringCallback()
        } else if (this.operation === Operation.DeleteRecurring) {
          this.deleteRecurringCallback && this.deleteRecurringCallback()
        } else if (this.operation === Operation.AddCard) {
          //firebase.analytics().logEvent('add_card', {});
          this.getToken(this.authToken(), true)
        } else if (this.operation === Operation.C2CGuest) {
          this.commitGuestC2C(
            response.token,
            Number(this.amount),
            this.macroMerchantId || '',
            this.referenceNo || '',
            'AZN',
            () => {
              this.props.navigate(
                `/epay/${this.props.language}/result/payment?isPayHistory=false&historyId=${this.referenceNo}&orderId=${response.token}`,
              )
            },
          )
        }
        if (this.cardName && this.operation === Operation.Payment) {
          console.log('this.cardName && this.operation === Operation.Payment')
          this.completeRegistration(this.cardName, response.token)
        }
        break
      case '5000': // RTA
      case '5015': // MPIN
      case '5008': // Ask Masterpass OTP
      case '5001': // Ask OTP
        this.props.loadingPaymentState(false)
        if (response.responseCode === '5015') {
          pinType = 'mpin'
        } else if (response.responseCode === '5000') {
          pinType = 'rta'
        }
        this.setState({
          isModalVisible: !this.state.isModalVisible,
          pinType,
          referenceNo: response.referenceNo,
        })
        break
      case '5010': // 3ds
        this.props.loadingPaymentState(false)
        if (this.operation === Operation.Payment) {
          let params = `refernceNo=${this.referenceNo}&amount=${this.amount}&macroMerchantId=${this.macroMerchantId}`
          if (this.cardName) {
            params += `&cardName=${encodeURIComponent(this.cardName)}`
          }
          params += `&return=${window.location.host}/epay/${this.props.language}`
          window.location = `${response.url3D}&returnUrl=${encodeURIComponent(
            `${BASE_URL}masterpass/commit-web/${this.authToken()}?${params}`,
          )}` as any
        } else if (this.operation === Operation.AddCard) {
          window.location =
            `${response.url3D}&returnUrl=${window.location.host}/my-card` as any
        } else if (this.operation === Operation.AddRecurring) {
          console.log('this.operation === Operation.AddRecurring')
          window.location = `${response.url3D}&returnUrl=${encodeURIComponent(
            `${BASE_URL}/recurring_payment/commit/${this.authToken()}?mirrorId=${
              this.mirrorId
            }&return=${window.location.host}`,
          )}` as any
          // this.addRecurringCallback && this.addRecurringCallback()
        } else if (this.operation === Operation.C2CGuest) {
          let params = `refernceNo=${this.referenceNo}&amount=${this.amount}&macroMerchantId=${this.macroMerchantId}`
          params += `&return=${window.location.host}/epay/${this.props.language}`
          window.location = `${response.url3D}&returnUrl=${encodeURIComponent(
            `${BASE_URL}open_c2c/commit-web?${params}`,
          )}` as any
        }
        break
      case '1419':
        // TODO: localize by code
        this.showError(response.responseDescription)
        break
      case '5192':
        // TODO: localize by code
        this.showError(response.responseDescription)
        break
      case '1999':
        // TODO: localize by code
        this.showError(response.responseDescription)
        break
      case '5460':
        // TODO: localize by code
        this.showError(response.responseDescription)
        break
      case '5195':
        // TODO: localize by code
        this.showError(response.responseDescription)
        break
      case '5196':
        // TODO: localize by code
        this.showError(response.responseDescription)
        break
      default:
        if (this.operation === Operation.DeleteRecurring) {
          // Delete on any error, ignore it
          this.deleteRecurringCallback && this.deleteRecurringCallback()
          break
        }
        // TODO: localize
        console.log('Unknown payment error', JSON.stringify(response))
        this.showError('Unknown payment error')
        break
    }
  }

  showError = (errorText: string) => {
    // TODO: design modal
    this.props.loadingPaymentState(false)
    window.alert(errorText)
  }

  waitInitalization = async () => {
    console.log('wait init')
    this.props.loadingPaymentState(true)

    return false
  }

  addRecurringHandler = (response: MfsResponse) => {
    let pinType = 'otp'
    this.props.loadingPaymentState(false)
    switch (response.responseCode) {
      case '':
      case '0000':
        this.addRecurringCallback && this.addRecurringCallback()
        break
      case '5000': // RTA
      case '5015': // MPIN
      case '5008': // Ask Masterpass OTP
      case '5001': // Ask OTP
        this.props.loadingPaymentState(false)
        if (response.responseCode === '5015') {
          pinType = 'mpin'
        } else if (response.responseCode === '5000') {
          pinType = 'rta'
        }
        this.setState({
          isModalVisible: !this.state.isModalVisible,
          pinType,
          referenceNo: response.referenceNo,
        })
        break
      case '5010': // 3ds
        window.location = `${response.url3D}&returnUrl=${encodeURIComponent(
          `${BASE_URL}/recurring_payment/commit/${this.authToken()}?mirrorId=${
            this.mirrorId
          }&return=${window.location.host}`,
        )}` as any
        //this.addRecurringCallback
        break
      default:
        this.showError('Xəta!')
        // TODO: show error
        break
    }
  }

  deleteRecurringHandler(response: MfsResponse) {
    //if (response.responseCode == "0000" || response.responseCode == "") {
    this.deleteRecurringCallback && this.deleteRecurringCallback()
    //} else {
    //    this.props.loadingPaymentState(false);
    //}
  }

  deleteRecurring = async (
    serviceId: number,
    cardName: string,
    mirrorId: string,
    success: () => void,
  ) => {
    if (await this.waitInitalization()) {
      return
    }
    this.deleteRecurringCallback = success
    this.operation = Operation.DeleteRecurring
    this.props.loadingPaymentState(true)
    try {
      const masterPassData = await this.getTokenWithServiceId(serviceId)
      if (!masterPassData) {
        console.log('cannot obtain mp token')
        this.props.loadingPaymentState(false)
        return
      }
      MFS.setClientId(masterPassData?.masterPassClientID)
      this.deleteRecurringInternal(
        this.phone,
        masterPassData?.referenceNumber,
        masterPassData?.token,
        cardName,
        mirrorId,
      )
    } catch (e) {
      console.log(e)
      this.props.loadingPaymentState(false)
    }
  }

  processRecurring = async (
    update: boolean,
    serviceId: number,
    cardName: string,
    mirrorId: string,
    amount: number,
    success: () => void,
  ) => {
    if (await this.waitInitalization()) {
      return
    }
    this.addRecurringCallback = success
    this.operation = Operation.AddRecurring
    this.mirrorId = mirrorId
    const endData = moment().add(2, 'years').format('YYYYMMDD')
    this.props.loadingPaymentState(true)
    try {
      const masterPassData = await this.getTokenWithServiceId(serviceId)
      if (!masterPassData) {
        this.props.loadingPaymentState(false)
        return
      }
      this.masterPassClientID = masterPassData.masterPassClientID
      MFS.setClientId(this.masterPassClientID)
      this.addRecurringInternal(
        this.phone,
        masterPassData?.referenceNumber,
        masterPassData?.token,
        cardName,
        mirrorId,
        amount,
        endData,
        update,
      )
    } catch (e) {
      console.log(e)
      this.props.loadingPaymentState(false)
    }
  }

  addRecurring = (
    serviceId: number,
    cardName: string,
    mirrorId: string,
    amount: number,
    success: () => void,
  ) => {
    this.processRecurring(false, serviceId, cardName, mirrorId, amount, success)
  }

  editRecurring = (
    serviceId: number,
    cardName: string,
    mirrorId: string,
    amount: number,
    success: () => void,
  ) => {
    this.processRecurring(true, serviceId, cardName, mirrorId, amount, success)
  }

  addCard = async (
    cardName: string,
    cardNumber: string,
    year: string,
    month: string,
    cvv: string,
  ) => {
    if (this.props.loading.isPaymentLoading) {
      return
    }
    if (await this.waitInitalization()) {
      return
    }
    // TODO: loading indications
    this.operation = Operation.AddCard
    this.props.loadingPaymentState(true)
    MFS.setClientId(this.props.masterPass.masterPassData?.masterPassClientID)
    this.registerInternal(
      cardName,
      cardNumber,
      `${year.trim()}${month.trim()}`,
      cvv,
      this.props.masterPass.masterPassData?.referenceNumber,
      this.props.masterPass.masterPassData?.token,
    )
  }

  deleteCard = async (cardName: string, success: () => void) => {
    if (this.props.loading.isPaymentLoading) {
      return
    }
    if (await this.waitInitalization()) {
      return
    }
    // TODO: loading indications
    this.removeCardCallback = success
    this.props.loadingPaymentState(true)
    this.props.setLoading(true)
    MFS.setClientId(this.props.masterPass.masterPassData?.masterPassClientID)
    this.removeInternal(
      cardName,
      this.props.masterPass.masterPassData?.referenceNumber,
      this.props.masterPass.masterPassData?.token,
    )
  }

  completeRegistration = async (cardName: string, lastToken: string) => {
    console.log('lastToken', lastToken)
    console.log('cardName', cardName)
    if (await this.waitInitalization()) {
      return
    }
    // TODO: loading indications
    this.operation = Operation.Registration
    console.log('completeRegistrationParams', {
      cardName,
      lastToken,
      masterPassData: {
        referenceNumber: this.props.masterPass.masterPassData?.referenceNumber,
        token: this.props.masterPass.masterPassData?.token,
        masterPassClientID:
          this.props.masterPass.masterPassData?.masterPassClientID,
      },
    })
    //this.props.loadingPaymentState(true);
    MFS.setClientId(this.props.masterPass.masterPassData?.masterPassClientID)
    this.completeRegistrationInternal(
      JSON.stringify(cardName),
      lastToken,
      this.props.masterPass.masterPassData?.referenceNumber,
      this.props.masterPass.masterPassData?.token,
    )
  }

  purchase = async (
    amount: number,
    cardNumber: string,
    year: string,
    month: string,
    cvv: string,
    referenceNo: string,
    macroMerchantId: string,
    masterPassClientID: string,
    token: string,
    cardName?: string,
    operation?: Operation,
  ) => {
    console.log(
      'pur',
      amount,
      cardNumber,
      year,
      month,
      cvv,
      referenceNo,
      macroMerchantId,
      masterPassClientID,
      token,
      cardName,
      operation,
    )

    if (await this.waitInitalization()) {
      return
    }
    console.log('start purchase')
    // TODO: loading indications
    this.cardName = cardName
    this.amount = amount
    this.referenceNo = referenceNo
    this.macroMerchantId = macroMerchantId
    this.operation = operation ? operation : Operation.Payment
    this.props.loadingPaymentState(true)
    this.masterPassClientID = masterPassClientID
    MFS.setClientId(masterPassClientID)
    this.purchaseInternal(
      amount,
      cardNumber,
      `${year}${month}`,
      cvv,
      referenceNo,
      macroMerchantId,
      masterPassClientID,
      token,
    )
  }

  purchase2 = async (
    amount: number,
    listAccountName: string,
    referenceNo: string,
    macroMerchantId: string,
    masterPassClientID: string,
    token: string,
    operation?: Operation,
  ) => {
    if (await this.waitInitalization()) {
      return
    }
    console.log('start purchase')
    // TODO: loading indications
    this.amount = amount
    this.referenceNo = referenceNo
    this.macroMerchantId = macroMerchantId
    this.operation = operation ? operation : Operation.Payment
    this.props.loadingPaymentState(true)
    this.masterPassClientID = masterPassClientID
    MFS.setClientId(masterPassClientID)
    this.purchase2Internal(
      amount,
      listAccountName,
      referenceNo,
      macroMerchantId,
      masterPassClientID,
      token,
    )
  }

  payWithNewCard = async (
    cardData: NewCardData,
    serviceData: ServiceData,
    success?: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => {
    this.paymentSucceed = success
    this.analyticsData = analyticsData
    this.appsFlyerData = appsFlyerData
    if (this.props.masterPass.isLoading) {
      return
    }
    if (await this.waitInitalization()) {
      return
    }
    const data = await this.doPayMasterPassWithGroupId('new_card', serviceData)
    if (!data) {
      this.props.loadingPaymentState(false)
      return
    }
    // if (data.masterCardCanFail) {
    //await this.purchaseWithPulPal(data.historyId, cardData.cardNumber, cardData.year, cardData.month, cardData.cvv);
    //return;
    // }
    const token = await this.getTokenWithHistoryId(
      data.historyId,
      data.amount,
      'new_card',
    )
    if (!token) {
      this.props.loadingPaymentState(false)
      return
    }
    this.purchase(
      data.amount,
      cardData.cardNumber,
      cardData.year,
      cardData.month,
      cardData.cvv,
      token.referenceNumber,
      token.masterPassMerchantId,
      token.masterPassClientID,
      token.token,
      cardData.cardName,
    )
  }

  notify = (msg: string) => {
    toast.error(
        msg,
        {
            position: toast.POSITION.TOP_CENTER,
        }
    );
  }

  payWithNewCard2Card = async (
    cardData: NewCardData,
    data: IWalletPurchaseData,
  ) => {
    if (await this.waitInitalization()) {
      return
    }
    const token = await this.getTokenWithHistoryId(
      data.historyId,
      data.amount,
      'new_card',
    )
    if (!token) {
      this.props.loadingPaymentState(false)
      return
    }
    this.purchase(
      data.amount,
      cardData.cardNumber,
      cardData.year,
      cardData.month,
      cardData.cvv,
      token.referenceNumber,
      token.masterPassMerchantId,
      token.masterPassClientID,
      token.token,
      cardData.cardName,
    )
  }

  guestPayCard2Card = async (cardData: GuestC2C) => {
    this.guestC2CCurrency = cardData.currency
    try {
      const amount = Number(cardData.amountValue) * 100
      const response = await client.post<any>(
        `open_c2c/register`,
        {
          body: JSON.stringify({
            pan: cardData.recipientCardNumber,
            amount: amount,
            id: undefined,
            phone: cardData.phoneNumber.slice(5).replace(/\s/g, ''),
          }),
        },
        {
          // method: "POST",
          headers: {
            'Content-Type': 'application/json',
            // "Payment-Origin": "Web",
          },
          // body: JSON.stringify({
          //   pan: cardData.recipientCardNumber,
          //   amount: amount,
          //   id: undefined,
          //   phone: cardData.phoneNumber.slice(5).replace(/\s/g, ""),
          // }),
        },
      )
      console.log('responseRegisterC2C', response)
      // if (response.status !== 200) {
      //   this.messageToUser(MessageType.SomeError);
      //   console.log("jsonRegisterC2CError", await response.json());
      //   return;
      // }
      // const json = await response.json();
      // console.log("jsonRegisterC2C", response);
      this.purchase(
        amount,
        cardData.cardNumber,
        cardData.year,
        cardData.month,
        cardData.cvv,
        response.data?.referenceNumber,
        response.data?.masterPassMerchantId,
        response.data?.masterPassClient,
        response.data?.token,
        '',
        Operation.C2CGuest,
      )
      // console.log("referenceNoRegister", json.referenceNumber);
    } catch (e) {
      console.log('errorRegisterC2C', e)
      return
    }
  }

  payWithSavedCard = async (
    bindingId: string,
    serviceData: ServiceData,
    success?: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => {
    this.paymentSucceed = success
    this.analyticsData = analyticsData
    this.appsFlyerData = appsFlyerData
    if (this.props.loading.isPaymentLoading) {
      return
    }
    if (await this.waitInitalization()) {
      return
    }
    const data = await this.doPayMasterPassWithGroupId(bindingId, serviceData)
    if (!data) {
      this.props.loadingPaymentState(false)
      return
    }
    // if (data.masterCardCanFail) {
    //TODO: tranlate
    //this.showError('Pay with saved card is not possible now. You can pay by new card (you can use any card, also save card) or try again later');
    //return;
    // }
    if (!data) {
      this.props.loadingPaymentState(false)
      return
    }
    const token = await this.getTokenWithHistoryId(
      data.historyId,
      data.amount,
      bindingId,
    )
    if (!token) {
      this.props.loadingPaymentState(false)
      return
    }
    this.purchase2(
      data.amount,
      bindingId,
      data.historyId.toString(),
      token.masterPassMerchantId,
      token.masterPassClientID,
      token.token,
    )
  }

  payWithSavedCard2Card = async (
    bindingId: string,
    data: IWalletPurchaseData,
  ) => {
    this.props.loadingPaymentState(true)
    if (await this.waitInitalization()) {
      return
    }
    const token = await this.getTokenWithHistoryId(
      data.historyId,
      data.amount,
      bindingId,
    )
    if (!token) {
      this.props.loadingPaymentState(false)
      return
    }
    this.purchase2(
      data.amount,
      bindingId,
      data.historyId.toString(),
      token.masterPassMerchantId,
      token.masterPassClientID,
      token.token,
    )
  }

  componentWillUnmount() {}

  listCardsResponseHandler = (statusCode: any, response: MfsResponse) => {
    // console.log('response', response);

    this.props.loadingState(false)
    this.props.setLoading(false)
    this.getTokenInProgress = false
    if (response.responseCode != '0000' && response.responseCode != '') {
      //TODO: notify masterpass error
      this.messageToUser(MessageType.CatchError)
      return
    }

    console.log('response.responseCode', response)

    this.props.setMasterPassCards(response.cards)
    this.props.setCards(
      response.cards.map(item => ({
        id: item.UniqueId,
        pan: item.Value1,
        name: item.Name,
        balance: 0,
        bankIca: item.BankIca,
        cardStatus: item.CardStatus,
        eftCode: item.EftCode,
        isMasterPassMember: item.IsMasterPassMember,
        loyaltyCode: item.LoyaltyCode,
        productName: item.ProductName,
        promtCpin: item.PromtCpin,
        value2: item.Value2,
        isDefault: false,
        exp: 0,
        currency: '',
        isWallet: false,
      })),
    )
  }

  async getToken(sessionId: string, loadCards?: boolean) {
    if (this.getTokenInProgress) {
      return
    }
    this.getTokenInProgress = true
    this.props.loadingState(true)
    // this.props.setLoading(true);
    try {
      const response = await client.get<any>(
        `masterpass/generate/${sessionId}`,
        {
          // method: "GET",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )
      console.warn('response', response)
      if (response.status === 200) {
        // const json = await response.json();
        console.warn('jsongetToken', response.data)
        if (response.data.resultCodes === 'OK') {
          const masterPassData = {
            masterPassClientID: response.data?.masterPassClient,
            masterPassMerchantId: response.data?.masterPassMerchantId,
            referenceNumber: response.data?.referenceNumber,
            timeZone: response.data?.timeZone,
            token: response.data?.token,
          }
          this.props.setMasterPassData(masterPassData)
          // console.log('masterPassCards start:', masterPassData);

          if (!loadCards) {
            return
          }

          this.fetchDefaultPan()
          this.props.setLoading(true)
          MFS.listCards(
            this.phone,
            response.data?.token,
            this.listCardsResponseHandler,
          )
        } else {
          this.props.loadingPaymentState(false)
          this.getTokenInProgress = false
        }
      } else {
        console.warn('error')
        this.props.loadingPaymentState(false)
        this.getTokenInProgress = false
        // this.messageToUser(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      this.props.loadingPaymentState(false)
      this.getTokenInProgress = false
      // TODO: Indicate error somewhere
      //this.messageToUser(MessageType.CatchError));
    } finally {
      this.getTokenInProgress = false
    }
  }

  async fetchDefaultPan() {
    try {
      if (!this.authToken()) {
        return
      }
      // const response = await fetchWithTimeout(
      //   BASE_URL + "cards/list_with_wallet/" + this.authToken(),
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "Web",
      //     },
      //   }
      // );
      const response = await client.get(
        'cards/list_with_wallet/' + this.authToken(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      if (response.data?.resultCodes === 'OK') {
        const def = response.data?.pans?.maskedPan?.find(
          (p: any) => p.isDefault,
        )?.id
        this.props.setDefaultPan(def || response.data?.defaultMasterPassPan)
      }
    } catch (e) {
      console.warn('error', e)
      this.messageToUser(MessageType.CatchError)
    } finally {
      // this.props.loadingPaymentState(false);
    }
  }

  async getTokenWithServiceId(serviceId: number) {
    let language = this.props.language
    if (!language) {
      language = 'az'
    }
    try {
      // const response = await fetch(
      const response = await client.get<any>(
        // `${BASE_URL}masterpass/generate/service/${this.authToken()}/${serviceId}`,
        `masterpass/generate/service/${this.authToken()}/${serviceId}`,
        {
          // method: "GET",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )
      if (response.status === 200) {
        // const json = await response.json();
        if (response.data?.resultCodes === 'OK') {
          const masterPassData = {
            masterPassClientID: response.data?.masterPassClient,
            masterPassMerchantId: response.data?.masterPassMerchantId,
            referenceNumber: response.data?.referenceNumber,
            timeZone: response.data?.timeZone,
            token: response.data?.token,
          }
          return masterPassData
        }
      } else {
        console.warn('error')
        // this.messageToUser(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      this.messageToUser(MessageType.CatchError)
    }
  }

  async getTokenWithHistoryId(historyID: number, amount: number, name: string) {
    let language = this.props.language
    if (!language) {
      language = 'az'
    }
    try {
      // const response = await fetch(
      const response = await client.get<any>(
        // `${BASE_URL}masterpass/generate/${this.authToken()}/${historyID}?amount=${amount}`,
        `masterpass/generate/${this.authToken()}/${historyID}?amount=${amount}`,
        {
          // method: "GET",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )
      console.warn('response', response)
      if (response.status === 200) {
        // const json = await response.json();
        console.warn('jsongetTokenWithHistoryId', response.data)
        if (response.data?.resultCodes === 'OK') {
          const masterPassData = {
            masterPassClientID: response.data?.masterPassClient,
            masterPassMerchantId: response.data?.masterPassMerchantId,
            referenceNumber: response.data?.referenceNumber,
            timeZone: response.data?.timeZone,
            token: response.data?.token,
          }
          this.props.setMasterPassData(masterPassData)
          return masterPassData
        } else this.props.loadingPaymentState(false)
      } else {
        console.warn('error')
        this.props.loadingPaymentState(false)
        // this.messageToUser(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      this.props.loadingPaymentState(false)
      this.messageToUser(MessageType.CatchError)
    }
  }

  async saveToken(
    historyId: number,
    referenceNo: string,
    token: string,
    cb?: (r: boolean) => void,
  ) {
    try {
      // const response = await fetch(`${BASE_URL}masterpass/save/${this.authToken()}/${historyId}`, {
      // const response = await fetch(
      const data = toQueryParams({
        referenceNo: referenceNo,
        token: token,
      })
      const response = await client.post<any>(
        `masterpass/save/${localStorage.getItem('token')}/${historyId}?${data}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )
      // const response = await client.post<any>(
      //   // `${BASE_URL}masterpass/save/${localStorage.getItem(
      //   `masterpass/save/${localStorage.getItem("token")}/${historyId}`,
      //   {
      //     body: toQueryParams({
      //       referenceNo: referenceNo,
      //       token: token,
      //     }),
      //   },
      //   {
      //     // method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       // "Payment-Origin": "Web",
      //     },
      //     // body: toQueryParams({
      //     //   referenceNo: referenceNo,
      //     //   token: token,
      //     // }),
      //   }
      // );
      console.warn('response', response)
      if (response.status === 200) {
        // const json = await response.json();
        console.warn('jsonSaveToken', response.data)
        if (response.data?.resultCodes === 'OK') {
          cb && cb(true)
          return true
        } else cb && this.props.loadingPaymentState(false)
      } else {
        console.warn('error')
        cb && this.props.loadingPaymentState(false)
        // cb && this.messageToUser(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      cb && this.props.loadingPaymentState(false)
      cb && this.messageToUser(MessageType.CatchError)
    }
    cb && cb(false)
    return false
  }

  async commmit(
    token: string,
    amount: number,
    macroMerchantId: string,
    success?: (order: string, historyId: number) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) {
    try {
      let bodyParams: any = {
        token: token,
        amount: amount,
        macroMerchantId: macroMerchantId,
      }
      if (analyticsData) {
        bodyParams = {
          ...bodyParams,
          analyticsData: JSON.stringify(analyticsData),
        }
      }
      if (appsFlyerData) {
        bodyParams = {
          ...bodyParams,
          appsFlyerData: JSON.stringify(appsFlyerData),
        }
      }
      this.props.loadingPaymentState(true)
      // const response = await fetch(
      const response = await client.post<any>(
        // `${BASE_URL}masterpass/commit-fixed/${this.authToken()}`,
        `masterpass/commit-fixed/${this.authToken()}`,
        { body: toQueryParams(bodyParams) },
        {
          // method: "POST",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
          // body: toQueryParams(bodyParams),
        },
      )
      let errorCode
      if (response.status === 200) {
        // const json = await response.json();
        if (response.data?.resultCodes === 'OK') {
          success &&
            success(response.data?.ecommOrderId, response.data?.historyId)
          return true
        }
        errorCode = response.data?.errorCode
      }

      if (errorCode === undefined) {
        this.messageToUser(MessageType.MasterPassError, errorCode)
      } else {
        this.messageToUser(
          MessageType.MasterPassError,
          errorCode,
          'PaymentScreen',
        )
      }
    } catch (e) {
      this.messageToUser(MessageType.CatchError)
    } finally {
      this.props.loadingPaymentState(false)
    }
    console.log('commit failed')
    return false
  }

  async commitGuestC2C(
    c2cGuestToken: string,
    amount: number,
    macroMerchantId: string,
    referenceNo: string,
    currency: string,
    success?: () => void,
  ) {
    try {
      const bodyParams = {
        token: c2cGuestToken,
        amount: amount,
        macroMerchantId: macroMerchantId,
        referenceNo: referenceNo,
        currency: currency,
        customFields: [],
      }
      this.props.loadingPaymentState(true)

      console.log('bodyParamsCommit', bodyParams)
      // const response = await fetch(`${BASE_URL}open_c2c/commit`, {
      const response = await client.post<any>(`open_c2c/commit`, {
        body: JSON.stringify(bodyParams),
      })

      if (response.status === 200) {
        // console.log("commitSuccessResponse", response);
        // console.log("commitSuccessJson", await response.json());
        success && success()
      } else {
        await this.messageToUser(MessageType.SomeError)
        // console.log("commitErrorResponse", response);
        // console.log("commitErrorJson", await response.json());
      }
    } catch (e) {
      await this.messageToUser(MessageType.SomeError)
      console.log('commitCatchError', e)
    } finally {
      this.props.loadingPaymentState(false)
    }
  }

  async messageToUser(
    messageType: number,
    errorCode?: string,
    route?: string,
  ) {}

  onMessage = (obj: {
    method: string
    response?: MfsResponse
    error?: string
    message?: string
  }) => {
    // console.log('onMessage', obj);
    if (obj.method === 'mfsError') {
      // TODO: localize
      this.showError('Unknown error')
    } else if (obj.method === 'mfsResponse') {
      this.response(obj.response!)
    } else if (obj.method === 'mfsDeleteResponse') {
      this.deleteResponse(obj.response!)
    } else if (obj.method === 'mfsCheckResponse') {
      this.checkResponse(obj.response!)
    } else if (obj.method === 'mfsLinkResponse') {
      this.linkResponse(obj.response!)
    } else if (obj.method === 'mfsAddRecurring') {
      console.log("obj.method === 'mfsAddRecurring'", obj)
      this.addRecurringHandler(obj.response!)
    } else if (obj.method === 'mfsDeleteRecurring') {
      this.deleteRecurringHandler(obj.response!)
    }
  }

  mfsLinkResponseHandler = (status: any, response: MfsResponse) => {
    this.onMessage({ method: 'mfsLinkResponse', response })
  }

  mfsDeleteResponseHandler = (status: any, response: MfsResponse) => {
    this.onMessage({ method: 'mfsDeleteResponse', response })
  }

  mfsCheckResponseHandler = (status: any, response: MfsResponse) => {
    this.onMessage({ method: 'mfsCheckResponse', response })
  }

  mfsResponseHandler = (status: any, response: MfsResponse) => {
    this.onMessage({ method: 'mfsResponse', response })
  }

  registerInternal(
    accountAliasName: string,
    rtaPan: string,
    expiryDate: string,
    cvc: string,
    referenceNumber: string | undefined,
    token: string | undefined,
  ) {
    document.addCardForm.accountAliasName.value = accountAliasName
    document.addCardForm.rtaPan.value = rtaPan
    document.addCardForm.expiryDate.value = expiryDate
    document.addCardForm.cvc.value = cvc
    document.addCardForm.referenceNo.value = referenceNumber
    document.addCardForm.token.value = token

    try {
      MFS.register($('#register-form'), this.mfsResponseHandler)
    } catch (error: any) {
      this.onMessage({ method: 'mfsError', error: error.stack })
    }
  }

  removeInternal(
    accountAliasName: string,
    referenceNumber: string | undefined,
    token: string | undefined,
  ) {
    document.deleteCardForm.accountAliasName.value = accountAliasName
    document.deleteCardForm.msisdn.value = this.phone
    document.deleteCardForm.referenceNo.value = referenceNumber
    document.deleteCardForm.token.value = token

    try {
      MFS.deleteCard($('#deleteCard-form'), this.mfsDeleteResponseHandler)
    } catch (error: any) {
      this.onMessage({ method: 'mfsError', error: error.stack })
    }
  }

  checkInternal() {
    try {
      MFS.checkMasterPass($('#checkMP-form'), this.mfsCheckResponseHandler)
    } catch (error: any) {
      this.onMessage({ method: 'mfsError', error: error.stack })
    }
  }

  linkCardToClientInternal() {
    try {
      MFS.linkCardToClient(
        $('#linkCardToClient-form'),
        this.mfsLinkResponseHandler,
      )
    } catch (error: any) {
      this.onMessage({ method: 'mfsError', error: error.stack })
    }
  }

  purchaseInternal(
    amount: number,
    rtaPan: string,
    expiryDate: string,
    cvc: string,
    referenceNumber: string,
    macroMerchantId: string,
    masterPassClientID: string,
    token: string,
  ) {
    console.log('purchaseInternal', arguments)

    MFS.setClientId(masterPassClientID)

    document.purchaseForm.amount.value = amount
    document.purchaseForm.rtaPan.value = rtaPan
    document.purchaseForm.expiryDate.value = expiryDate
    document.purchaseForm.cvc.value = cvc

    document.purchaseForm.referenceNo.value = referenceNumber
    document.purchaseForm.orderNo.value = referenceNumber
    document.purchaseForm.macroMerchantId.value =
      macroMerchantId || '02822177921207093527822'
    document.purchaseForm.token.value = token

    try {
      MFS.directPurchase($('#purchase-form'), this.mfsResponseHandler)
    } catch (error: any) {
      this.onMessage({
        method: 'mfsError',
        message: error.message,
        error: error.stack,
      })
    }
  }

  purchase2Internal(
    amount: number,
    listAccountName: string,
    referenceNumber: string,
    macroMerchantId: string,
    masterPassClientID: string,
    token: string,
  ) {
    MFS.setClientId(masterPassClientID)

    document.purchase2Form.amount.value = amount
    document.purchase2Form.listAccountName.value = listAccountName

    document.purchase2Form.referenceNo.value = referenceNumber
    document.purchase2Form.orderNo.value = referenceNumber
    document.purchase2Form.macroMerchantId.value =
      macroMerchantId || '02822177921207093527822'
    document.purchase2Form.token.value = token

    try {
      MFS.purchase($('#purchase2-form'), this.mfsResponseHandler)
    } catch (error: any) {
      this.onMessage({
        method: 'mfsError',
        message: error.message,
        error: error.stack,
      })
    }
  }

  validateTransactionInternal(
    validationCode: string,
    referenceNo: string | undefined,
    pinType: string,
    checkForLink?: boolean,
  ) {
    try {
      document.otpForm.validationCode.value = validationCode
      document.otpForm.referenceNo.value = referenceNo
      document.otpForm.pinType.value = pinType
      MFS.validateTransaction(
        $('#otp-form'),
        checkForLink ? this.mfsLinkResponseHandler : this.mfsResponseHandler,
      )
    } catch (error: any) {
      this.onMessage({ method: 'mfsError', error: error.stack })
    }
  }

  completeRegistrationInternal(
    cardAliasName: string,
    lastToken: string,
    referenceNo: string | undefined,
    token: string | undefined,
  ) {
    try {
      document.completeForm.cardAliasName.value = cardAliasName
      document.completeForm.referenceNo.value = referenceNo
      document.completeForm.token.value = token
      MFS.completeRegistration(
        $('#complete-form'),
        lastToken,
        this.mfsResponseHandler,
      )
    } catch (error: any) {
      this.onMessage({ method: 'mfsError', error: error.stack })
    }
  }

  deleteRecurringInternal(
    msisdn: string | undefined,
    referenceNumber: string,
    token: string,
    listAccountName: string,
    productId: string,
  ) {
    document.recurringDelete.msisdn.value = msisdn
    document.recurringDelete.referenceNo.value = referenceNumber
    document.recurringDelete.sendSmsLanguage.value = 'eng'
    document.recurringDelete.token.value = token

    document.recurringDelete.listAccountName.value = listAccountName
    document.recurringDelete.productId.value = productId

    MFS.initiateRecurringPayment(
      $('#recurringPayment-form-delete'),
      (status: any, response: MfsResponse) => {
        this.onMessage({ method: 'mfsDeleteRecurring', response })
      },
    )
  }

  addRecurringInternal(
    msisdn: string | undefined,
    referenceNumber: string,
    token: string,
    listAccountName: string,
    productId: string,
    amount: number,
    endDate: string,
    update: boolean,
  ) {
    document.recurringAdd.msisdn.value = msisdn
    document.recurringAdd.referenceNo.value = referenceNumber
    document.recurringAdd.sendSmsLanguage.value = 'eng'
    document.recurringAdd.token.value = token

    document.recurringAdd.listAccountName.value = listAccountName
    document.recurringAdd.productId.value = productId

    document.recurringAdd.amount.value = amount
    document.recurringAdd.endDate.value = endDate
    document.recurringAdd.actionType.value = update ? 'U' : 'A'

    console.log(
      'document.recurringAdd',
      (document.recurringAdd.actionType.value = update ? 'U' : 'A'),
    )

    MFS.initiateRecurringPayment(
      $('#recurringPayment-form-add'),
      (status: any, response: MfsResponse) => {
        console.log('MFS.initiateRecurringPayment', response)
        this.onMessage({ method: 'mfsAddRecurring', response })
      },
    )
  }

  doPayMasterPassC2c: (
    bindingId: string,
    amount: number,
    pan: string,
    currency: string,
    exp?: string,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => Promise<
    | {
        historyId: number
        amount: number
      }
    | undefined
  > = async (
    bindingId: string,
    amount: number,
    pan: string,
    currency: string,
    exp?: string,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => {
    this.props.loadingPaymentState(true)
    try {
      let body: any = {
        bindingId: bindingId === 'newcard' ? bindingId : 'MC_' + bindingId,
        amount,
        pan,
        lng: this.props.language,
        currency,
        exp,
      }
      if (analyticsData) {
        body = { ...body, analyticsData: JSON.stringify(analyticsData) }
      }
      if (appsFlyerData) {
        body = { ...body, appsFlyerData: JSON.stringify(appsFlyerData) }
      }
      // const response = await fetch(
      const response = await client.post<any>(
        `card2card/do_c2c/masterpass/${this.authToken()}?${toQueryParams(
          body,
        )}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )
      // const response = await client.post<any>(
      //   // BASE_URL + "card2card/do_c2c/masterpass/" + this.authToken(),
      //   "card2card/do_c2c/masterpass/" + this.authToken(),
      //   { body: toQueryParams(body) },
      //   {
      //     // method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       // "Payment-Origin": "Web",
      //     },
      //     // body: toQueryParams(body),
      //   }
      // );
      if (response.status === 200) {
        console.log('response', response)
        // const json = await response.json();
        // console.log("doPayMasterPassC2c", json);
        if (response.data?.resultCodes === 'OK') {
          const purchaseData = {
            historyId: response.data?.historyId as number,
            amount: amount * 100,
          }
          if (bindingId === 'wallet') {
            this.props.loadingPaymentState(false)
          }
          //dispatch(_savePurchaseData(purchaseData));
          return purchaseData
        } else {
          this.props.loadingPaymentState(false)
          notify(t('paymentError'))
          // dispatch(MsgToUserState(MessageType.SomeError));
          //ошибка при оплате
        }
        //"resultCodes": "INVALID_AMOUNT" если меньше 0, делать проверку
        //"resultCodes": "PAYMENT_INFO_SERVICE_ITEM_NOT_SELECTED"
      } else {
        this.props.loadingPaymentState(false)
        // dispatch(MsgToUserState(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      this.props.loadingPaymentState(false)
      //dispatch(MsgToUserState(MessageType.CatchError));
      this.messageToUser(MessageType.CatchError)
    }
  }

  async doPayMasterPassWithGroupId(
    bindingId: string,
    serviceData: ServiceData,
  ) {
    this.props.loadingPaymentState(true)

    const {
      serviceId,
      amount,
      fields,
      payAllDebt,
      subName,
      subVal,
      desc,
      lng,
      selectedGroupId,
    } = serviceData

    //const cardName = bindingId === 'newcard' ? bindingId : 'MC_' + bindingId;
    console.warn('cardName', bindingId)
    console.warn('serviceId', serviceId)
    console.warn('amount', amount)
    console.warn('fields', fields)
    console.warn('payAllDebt', payAllDebt)
    console.warn('subName', subName)
    console.warn('subVal', subVal)
    console.warn('desc', desc)
    console.warn('lng', lng)
    console.warn('selectedGroupId', selectedGroupId)
    try {
      // const response = await fetch(

      const queryData = toQueryParams({
        sessionId: this.authToken(),
        serviceId: serviceId,
        bindingId: bindingId === 'new_card' ? bindingId : 'MC_' + bindingId,
        amount: amount,
        fields: fields,
        payAllDebt: payAllDebt,
        subName: subName,
        subVal: subVal,
        desc: desc,
        lng: lng,
        selectedGroupId: selectedGroupId,
      })

      const res = await client.post<any>(
        `payment/do_masterpass_pay_with_group_id/${this.authToken()}?${queryData}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )

      if (res.status === 200) {
        // const json = await response.json();
        if (res.data?.resultCodes === 'OK') {
          const purchaseData = {
            historyId: res.data?.historyId as number,
            amount: res.data?.newAmount as number,
          }
          return purchaseData
        } else if (res.data?.resultCodes === 'RECEIVER_WALLET_LIMIT_EXCEEDED') {
          this.notify(t('limit'));
        } else {
          this.props.loadingPaymentState(false)
          this.messageToUser(1)
        }
        //"resultCodes": "INVALID_AMOUNT" если меньше 0, делать проверку
        //"resultCodes": "PAYMENT_INFO_SERVICE_ITEM_NOT_SELECTED"
      } else {
        this.props.loadingPaymentState(false)
        this.messageToUser(1)
      }
    } catch (e) {
      console.warn('doPayMasterPassWithGroupId catch error', e)
      this.props.loadingPaymentState(false)
      // dispatch(MsgToUserState(MessageType.ConnectionError));
      this.messageToUser(MessageType.CatchError)
    }
  }

  async doPayByBonuses(
    serviceId: number,
    amount: number,
    fields: string,
    payAllDebt: boolean,
    subName: string,
    subVal: string,
    desc: string,
    selectedGroupId: number,
    success: (orderId: string, historyId: number) => void,
    fail?: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) {
    if (this.props.loading.isPaymentLoading) {
      return
    }
    this.props.loadingPaymentState(true)
    try {
      let body: any = {
        sessionId: this.authToken(),
        serviceId: serviceId,
        amount: amount,
        fields: fields,
        payAllDebt: payAllDebt,
        subName: subName,
        subVal: subVal,
        desc: desc,
        lng: this.props.language,
        selectedGroupId: selectedGroupId,
      }
      if (analyticsData) {
        body = { ...body, analyticsData: JSON.stringify(analyticsData) }
      }
      if (appsFlyerData) {
        body = { ...body, appsFlyerData: JSON.stringify(appsFlyerData) }
      }

      const res = await client.post<any>(
        `payment/do_pay_by_bonus/${this.authToken()}?${toQueryParams(body)}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
        },
      )

      console.log(res.data)
      if (res.status === 200) {
        if (res.data?.resultCodes === 'OK') {
          if (res.data?.orderId) {
            success && success(res.data?.orderId, res.data?.historyId)
          } else {
            fail && fail()
            this.messageToUser(MessageType.SomeError, '', 'PaymentScreen')
          }
          //dispatch(profileGet(sessionId));
        } else {
          fail && fail()
          this.messageToUser(MessageType.SomeError, '', 'PaymentScreen')
        }
      } else {
        fail && fail()
        this.messageToUser(MessageType.SomeError, '', 'PaymentScreen')
      }
    } catch (e) {
      console.warn('doPayByBonuses catch error', e)
      fail && fail()
      this.messageToUser(MessageType.SomeError, '', 'PaymentScreen')
    } finally {
      this.props.loadingPaymentState(false)
    }
  }

  async doPayWithWallet(
    serviceId: number,
    bindingId: string,
    amount: number,
    fields: string,
    payAllDebt: boolean,
    subName: string,
    subVal: string,
    desc: string,
    selectedGroupId: number,
    success: (orderId: string, historyId: number) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) {
    if (this.props.loading.isPaymentLoading) {
      return
    }
    this.props.loadingPaymentState(true)

    let body: any = {
      sessionId: this.authToken(),
      serviceId: serviceId,
      bindingId: 'wallet_' + bindingId,
      amount: amount,
      fields: fields,
      payAllDebt: payAllDebt,
      subName: subName,
      subVal: subVal,
      desc: desc,
      lng: this.props.language,
      selectedGroupId: selectedGroupId,
    }
    if (analyticsData) {
      body = { ...body, analyticsData: JSON.stringify(analyticsData) }
    }
    if (appsFlyerData) {
      body = { ...body, appsFlyerData: JSON.stringify(appsFlyerData) }
    }
    try {
      const res = await client.post(
        `payment/do_pay_with_group_id/${this.authToken()}?${toQueryParams(
          body,
        )}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )

      console.log('doPayWithWallet', res.data)
      if (res.data?.resultCodes === 'OK') {
        success && success(res.data?.orderId, res.data?.historyId)
        // fetchWalletList(localStorage.getItem('token'))
      } else {
        this.messageToUser(MessageType.SomeError, '', 'PaymentScreen')
      }
    } catch (e) {
      console.warn('doPayWithWallet catch error----> ', e)
      this.messageToUser(MessageType.SomeError, '', 'PaymentScreen')
    } finally {
      this.props.loadingPaymentState(false)
    }
  }

  async payWithPulpal(
    giftId: number,
    serviceData: ServiceData,
    openWebview: (url: string) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) {
    if (this.props.loading.isPaymentLoading) {
      return
    }
    this.props.loadingPaymentState(true)
    try {
      const {
        serviceId,
        amount,
        fields,
        payAllDebt,
        subName,
        subVal,
        desc,
        lng,
        selectedGroupId,
      } = serviceData
      let body: any = {
        sessionId: this.authToken(),
        serviceId: serviceId,
        bindingId: 'newcard',
        amount: amount,
        fields: fields,
        payAllDebt: payAllDebt,
        subName: subName,
        subVal: subVal,
        desc: desc,
        lng: this.props.language,
        selectedGroupId: selectedGroupId,
      }
      if (analyticsData) {
        body = { ...body, analyticsData: JSON.stringify(analyticsData) }
      }
      if (appsFlyerData) {
        body = { ...body, appsFlyerData: JSON.stringify(appsFlyerData) }
      }
      // const response = await fetch(
      const response = await client.post<any>(
        'payment/do_masterpass_pay_with_group_id/' + this.authToken(),
        { body: toQueryParams(body) },
        {
          // method: "POST",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // "Payment-Origin": "Web",
          },
          // body: toQueryParams(body),
        },
      )
      console.log('response_payWithPulpal', response)
      if (response.status === 200) {
        // const json = await response.json();
        // console.log("doPayWithGroupId", json);
        if (response.data?.resultCodes === 'OK') {
          const pulpal = await client.get(
            'bonus/gift_detail/' +
              this.authToken() +
              '/' +
              giftId +
              '/' +
              response.data?.historyId,
            {
              responseType: 'text',
            },
          )

          // const url = await pulpal.text();
          if (pulpal.status === 200 || pulpal.data) {
            openWebview(pulpal.data)
            return
          }

          this.props.loadingPaymentState(false)
          // dispatch(MsgToUserState(MessageType.SomeError));
          this.messageToUser(1)
        } else {
          this.props.loadingPaymentState(false)
          // dispatch(MsgToUserState(MessageType.SomeError));
          this.messageToUser(1)
          //ошибка при оплате
        }
        //"resultCodes": "INVALID_AMOUNT" если меньше 0, делать проверку
        //"resultCodes": "PAYMENT_INFO_SERVICE_ITEM_NOT_SELECTED"
      } else {
        // console.warn("response_payWithPulpal_text", await response.text());

        this.props.loadingPaymentState(false)
        // dispatch(MsgToUserState(MessageType.SomeError));
        this.messageToUser(1)
      }
    } catch (e) {
      console.warn('error', e)
      this.props.loadingPaymentState(false)
      this.messageToUser(MessageType.CatchError)
    }
  }

  async giftToUser(
    giftId: number,
    phoneNumber: string | undefined,
    minutes: number | undefined,
    success: () => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) {
    if (this.props.loading.isPaymentLoading) {
      return
    }
    this.props.loadingPaymentState(true)
    try {
      let body: any = {
        gift_id: giftId,
        phoneNumber: phoneNumber,
        minutes: minutes,
      }
      if (analyticsData) {
        body = { ...body, analyticsData: JSON.stringify(analyticsData) }
      }
      if (appsFlyerData) {
        body = { ...body, appsFlyerData: JSON.stringify(appsFlyerData) }
      }
      const response = await fetchWithTimeout(
        BASE_URL + 'bonus/gift_to_user/' + this.authToken(),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Payment-Origin': 'Web',
          },
          body: toQueryParams(body),
        },
      )
      console.warn('response', response)
      if (response.status === 200) {
        const json = await response.json()
        console.warn('json', json)
        if (json.resultCodes === 'OK') {
          success && success()
          //dispatch(profileGet(sessionId));
        }
      } else {
        // dispatch(MsgToUserState(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      this.messageToUser(MessageType.CatchError)
    } finally {
      this.props.loadingPaymentState(false)
    }
  }

  transferToWallet = async (
    sourceWalletCurrency: string,
    longAmount: number,
    recipientWalletPhone: string,
    recipientWalletCurrency: string,
    comment?: string,
    success?: (result: { orderId: string; historyId: number }) => void,
    analyticsData?: any,
    appsFlyerData?: any,
  ) => {
    this.props.loadingPaymentState(true)
    try {
      let body: any = {
        sourceWalletCurrency,
        longAmount,
        recipientWalletPhone,
        recipientWalletCurrency,
      }
      if (comment !== '') {
        body.comment = comment
      }
      if (analyticsData) {
        body = { ...body, analyticsData: JSON.stringify(analyticsData) }
      }
      if (appsFlyerData) {
        body = { ...body, appsFlyerData: JSON.stringify(appsFlyerData) }
      }
      const response = await fetchWithTimeout(
        BASE_URL + 'wallet/transfer/' + this.authToken(),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Payment-Origin': 'web',
          },
          body: toQueryParams(body),
        },
      )

      if (response.status === 200) {
        const json = await response.json()

        if (json.resultCodes === 'OK') {
          //dispatch(fetchWallets(sessionId));
          success && success(json)
        } else if (json.resultCodes === 'UNKNOWN_ERROR') {
          this.messageToUser(MessageType.SomeError)
        } else if (json.resultCodes === 'CUSTOMER_NOT_FOUND') {
          this.messageToUser(MessageType.CustomerNotFound)
        } else if (json.resultCodes === 'INVALID_AMOUNT') {
          this.messageToUser(MessageType.InvalidAmount)
        } else if (json.resultCodes === 'RECEIVER_WALLET_LIMIT_EXCEEDED') {
          this.messageToUser(MessageType.WalletLimitExceeded)
        }
      } else {
        // dispatch(MsgToUserState(MessageType.SomeError));
      }
    } catch (e) {
      console.warn('error', e)
      this.messageToUser(MessageType.CatchError)
    } finally {
      this.props.loadingPaymentState(false)
    }
  }

  render() {
    const language = Languages(this.props.language)

    return (
      <div>
        <form action='' method='POST' id='checkMP-form' name='checkMP'>
          <input type='hidden' name='userId' id='userId' />
          <input type='hidden' name='token' id='token' />
          <input type='hidden' name='referenceNo' id='referenceNo' />
          <input type='hidden' name='sendSmsLanguage' value='eng' />
          <input type='hidden' name='sendSms' value='N' />
        </form>

        <form
          action=''
          method='POST'
          id='linkCardToClient-form'
          name='linkCardToClient'
        >
          <input type='hidden' name='msisdn' id='msisdn' />
          <input type='hidden' name='token' id='token' />
          <input type='hidden' name='referenceNo' id='referenceNo' />
          <input type='hidden' name='sendSmsLanguage' value='eng' />
          <input type='hidden' name='sendSms' value='N' />
        </form>

        <form
          action=''
          method='POST'
          id='recurringPayment-form-add'
          name='recurringAdd'
        >
          <input type='hidden' id='msisdn' name='msisdn' />
          <input type='hidden' id='token' name='token' />
          <input type='hidden' id='referenceNo' name='referenceNo' />
          <input type='hidden' id='sendSmsLanguage' name='sendSmsLanguage' />
          <input type='hidden' name='sendSms' value='N' />
          <input type='hidden' name='actionType' value='A' />

          <input type='hidden' name='listAccountName' id='listAccountName' />
          <input type='hidden' name='productId' id='productId' />

          <input type='hidden' name='amount' id='amount' />
          <input type='hidden' name='endDate' id='endDate' />
        </form>

        <form
          action=''
          method='POST'
          id='recurringPayment-form-delete'
          name='recurringDelete'
        >
          <input type='hidden' id='msisdn' name='msisdn' />
          <input type='hidden' id='token' name='token' />
          <input type='hidden' id='referenceNo' name='referenceNo' />
          <input type='hidden' id='sendSmsLanguage' name='sendSmsLanguage' />
          <input type='hidden' name='sendSms' value='N' />
          <input type='hidden' name='actionType' value='D' />

          <input type='hidden' name='listAccountName' id='listAccountName' />
          <input type='hidden' name='productId' id='productId' />
        </form>

        <form name='addCardForm' action='' method='POST' id='register-form'>
          <input type='hidden' id='cardHolderName' name='accountAliasName' />
          <input type='hidden' id='card_no' name='rtaPan' />
          <input type='hidden' id='expiryDate' name='expiryDate' />
          <input type='hidden' id='iCVC' name='cvc' />

          <input type='hidden' id='msisdn' name='msisdn' />
          <input type='hidden' id='token' name='token' />
          <input type='hidden' id='referenceNo' name='referenceNo' />
          <input type='hidden' id='sendSmsLanguage' name='sendSmsLanguage' />

          <input type='hidden' name='sendSms' value='N' />
          <input type='hidden' name='actionType' value='A' />
          <input type='hidden' id='clientIp' name='clientIp' />
          <input type='hidden' name='delinkReason' value='' />
          <input type='hidden' name='eActionType' value='A' />
          <input type='hidden' name='cardTypeFlag' value='05' />
          <input type='hidden' name='cpinFlag' value='Y' />
          <input type='hidden' name='defaultAccount' value='Y' />
          <input type='hidden' name='mmrpConfig' value='110010' />
          <input type='hidden' name='identityVerificationFlag' value='Y' />
          <input type='hidden' name='mobileAccountConfig' value='MWA' />
          <input type='hidden' id='timeZone' name='timeZone' />
          <input type='hidden' name='uiChannelType' value='6' />
        </form>

        <form action='' method='POST' id='otp-form' name='otpForm'>
          <input type='hidden' name='validationCode' id='otp-input' />

          <input type='hidden' id='referenceNo' name='referenceNo' />
          <input type='hidden' name='sendSms' value='N' />
          <input type='hidden' name='sendSmsLanguage' value='eng' />
          <input type='hidden' name='pinType' value='otp' />
        </form>

        <form action='' method='POST' id='purchase-form' name='purchaseForm'>
          <input type='hidden' name='amount' />

          <input type='hidden' id='card_no' name='rtaPan' />
          <input type='hidden' id='expiryDate' name='expiryDate' />
          <input type='hidden' id='iCVC' name='cvc' />

          <input type='hidden' id='msisdn' name='msisdn' />
          <input type='hidden' id='token' name='token' />
          <input type='hidden' id='referenceNo' name='referenceNo' />

          <input type='hidden' name='macroMerchantId' id='macroMerchantId' />
          <input type='hidden' name='orderNo' id='historyId' />

          <input type='hidden' id='sendSmsLanguage' name='sendSmsLanguage' />
          <input type='hidden' name='sendSms' value='N' />
          <input type='hidden' name='actionType' value='A' />
          <input type='hidden' id='clientIp' name='clientIp' />
          <input type='hidden' name='delinkReason' value='' />
          <input type='hidden' name='eActionType' value='A' />
          <input type='hidden' name='cardTypeFlag' value='05' />
          <input type='hidden' name='cpinFlag' value='Y' />
          <input type='hidden' name='defaultAccount' value='Y' />
          <input type='hidden' name='mmrpConfig' value='110010' />
          <input type='hidden' name='identityVerificationFlag' value='Y' />
          <input type='hidden' name='mobileAccountConfig' value='MWA' />
          <input type='hidden' id='timeZone' name='timeZone' />
          <input type='hidden' name='uiChannelType' value='6' />
        </form>

        <form action='' method='POST' id='purchase2-form' name='purchase2Form'>
          <input name='amount' type='hidden' id='amount' />

          <input type='hidden' name='listAccountName' id='listAccountName' />
          <input type='hidden' name='msisdn' id='msisdn' />
          <input type='hidden' name='token' id='token' />
          <input type='hidden' name='referenceNo' id='referenceNo' />
          <input type='hidden' name='macroMerchantId' id='macroMerchantId' />
          <input type='hidden' name='orderNo' id='historyId' />
          <input type='hidden' name='sendSmsLanguage' value='aze' />

          <input type='hidden' name='sendSms' value='N' />
          <input type='hidden' name='aav' value='aav' />
          <input type='hidden' name='clientIp' value='' />
          <input type='hidden' name='encCPin' value='0' />
          <input type='hidden' name='encPassword' value='' />
          <input type='hidden' name='sendSmsMerchant' value='Y' />
          <input type='hidden' name='password' value='' />

          <input
            type='hidden'
            name='cavv'
            value='jKUQfB68bPMgCBEAMLXWAgMAAAA='
          />
          <input type='hidden' name='eci' value='02' />
          <input
            type='hidden'
            name='xid'
            value='JrBdMlYykqhk7W1nrjL61wJj2Dc='
          />
          <input
            type='hidden'
            name='md'
            value='557113:B3E3DAFD8DB44AA9372AD1BCAC697306CD94BE2A01E97D504538B7B1243D032C:3986:##100100000'
          />
        </form>

        <form action='' method='POST' id='complete-form' name='completeForm'>
          <input
            type='hidden'
            name='cardAliasName'
            id='registeredCardAliasName'
          />

          <input type='hidden' id='msisdn' name='msisdn' />
          <input type='hidden' id='token' name='token' />
          <input type='hidden' id='referenceNo' name='referenceNo' />
          <input type='hidden' id='sendSmsLanguage' name='sendSmsLanguage' />

          <input type='hidden' name='sendSms' value='N' />
        </form>

        <form
          action=''
          method='POST'
          id='deleteCard-form'
          name='deleteCardForm'
        >
          <input type='hidden' name='accountAliasName' id='accountAliasName' />
          <input type='hidden' id='msisdn' name='msisdn' />
          <input type='hidden' id='token' name='token' />
          <input type='hidden' id='referenceNo' name='referenceNo' />
          <input type='hidden' id='sendSmsLanguage' name='sendSmsLanguage' />
          <input type='hidden' name='sendSms' value='N' />
        </form>
        {this.modalWindow(language)}
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: state.authReducer,
  masterPass: state.masterPassReducer,
  language: (state as any).language.language,
  loading: (state as any).loading,
})

const mapDispatchToProps = {
  setMasterPassCards: masterPassSliceActions.setMasterPassCards,
  setMasterPassData: masterPassSliceActions.setMasterPassData,
  setMasterPassForms: masterPassSliceActions.setMasterPassForms,
  setDefaultPan: cardsSliceActions.setDefaultPan,
  setCards: cardsSliceActions.setCards,
  setLoading: cardsSliceActions.setLoading,
  loadingPaymentState: LoadingActionCreators.loadingPaymentState,
  loadingState: LoadingActionCreators.loadingState,
  fetchWallets: fetchWalletList,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MasterPassWebview),
)
