import React from 'react';
import "./index.scss"

interface HeaderAdaptivProps{
    title?: string;
    bonus?: string;
    onClick?:()=>void;
}

const HeaderAdaptiv = ({title, bonus, onClick}: HeaderAdaptivProps) => {
    return (
        <div className="header-adaptiv">
            <div className="header-adaptiv__title" onClick={onClick}>
                <div className="header-adaptiv__title-img">
                    <img src={require("../../img/left-arrow.svg").default} alt="arrow"/>
                </div>
                <div className="header-adaptiv__title-text">{title}</div>
            </div>
            <div className="header-adaptiv__right">
                <div className="header-adaptiv__right-bonus">{bonus}</div>
                <div className="header-adaptiv__title-img">
                    <img src={require("../../img/bonus-header.svg").default} alt="bonus icon"/>
                </div>
            </div>
        </div>
    );
};

export default HeaderAdaptiv;