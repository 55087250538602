import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import CustomMyCard from "./CustomMyCard";
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useTranslation } from "react-i18next";
import LayoutEmpty from "../layouts/LayoutEmpty/LayoutEmpty";
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import { notify } from "../../helper";
import { cardsSliceActions } from '../../redux/slices/cardsSlice';
import {
    blockDigitalCardThunk,
    editSimpleCardNameThunk,
    fetchRemoveCard,
    fetchUpdateCard,
    getDigitalCardRequisitesThunk,
    makeCardDefaultThunk
} from "../../redux/actions/cardsActions";
import MyCardSkeleton from "../Sceletons/MyCardSkeleton/MyCardSkeleton";
import Media900MyCardSkeleton from "../Sceletons/MyCardSkeleton/Media900MyCardSkeleton";
import DigitalCard from "./DigitalCard";
import { LoadingLocal } from "../Loading/Loading";
import { historyListSliceActions } from "../../redux/slices/historyListSlice";
import "./index.scss"
import { browserTokenSelector } from '../../redux/slices/browserTokenSlice';
import { languageSelector } from '../../redux/reducers/language';
import { masterPassSelector } from '../../redux/slices/masterpassSlice';
import { cardsSelector } from '../../redux/slices/cardsSlice';
import { ICard } from '../../models/Card';


interface MyCardProps {
}

const myCardArr = [1, 2, 3, 4]

const MyCard: FC = (props: MyCardProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [card, setCard] = useState<any>(null);
    const [value, setValue] = useState('');
    const [isRequisites, setIsRequisites] = useState(false);
    const { browserToken } = useAppSelector(browserTokenSelector)
    const { cards: habCards } = useAppSelector(state => state.habStorageSlice)
    const { language } = useAppSelector(languageSelector);
    const { forms } = useAppSelector(masterPassSelector)
    const { cards, defaultPan, isLoading, digitalCard, isDigitalCardLoading } = useAppSelector(cardsSelector);

    const dispatch = useAppDispatch();
    const token: any = localStorage.getItem('token')
    const hasUrl = `/epay/${language}/`

    const goBack = () => navigate(`${hasUrl}home`)

    const simpleCardOptions = [
        t("delete"),
        t("setAsDefault"),
        t("rename"),
    ]

    const digitalCardOptions = [
        `${isRequisites ? t('hideRequisites') : t('showRequisites')}`,
        `${digitalCard?.info?.blocked ? t('unBlock') : t('block')}`,
        t('history'),
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        forms?.getToken(token, true);

    }, [forms, token])

    useEffect(() => {
        setValue(card?.name)
    }, [card?.name, isEditModalOpen])

    const getSimpleCardOption = (value: string, item: any) => {
        setCard(item)
        if (value === t("delete")) {
            setIsDeleteModalOpen(true);
        } else if (value === t("setAsDefault")) {
            makeCardDefault(item)
        } else if (value === t("rename")) {
            setIsEditModalOpen(true)
        }
    }

    const getDigitalCardOption = (value: string) => {
        if (value === t('showRequisites')) {
            setIsRequisites((pr) => !pr);
            getDigitalCardRequisites()
        } else if (value === t('block') || value === t('unBlock')) {
            blockDigitalCard();
        } else if (value === t('history')) {
            redirectToCardHistory()
        } else {
            setIsRequisites(false);
        }

    }

    const getDigitalCardRequisites = () => {
        dispatch(getDigitalCardRequisitesThunk(digitalCard.card?.id))
    }

    const blockDigitalCard = () => {
        dispatch(blockDigitalCardThunk({
            id: digitalCard.card?.id,
            value: digitalCard?.info?.blocked ? 'unblockCard' : 'blockCard'
        }))
        setIsRequisites(false)
    }

    const redirectToCardHistory = () => {
        dispatch(historyListSliceActions.setListType(t('virtualCard')))
        navigate(`/epay/${language}/history`)
    }

    const makeCardDefault = (item: any) => {
        const cardPan = `MS_${item?.id}`;
        dispatch(cardsSliceActions.setLoading(true));
        dispatch(makeCardDefaultThunk(cardPan)).then((res) => {
            if (res.payload.status === 200 && res.payload.data.resultCodes === 'OK') {
                dispatch(cardsSliceActions.setLoading(false))
                dispatch(cardsSliceActions.setDefaultPan(cardPan))
                notify(`${t("theMapAssignedDefault")}`, true)
            } else {
                dispatch(cardsSliceActions.setLoading(false))
                notify(`${t("itWorkChange")}`, false)
            }
        });
        setCard(null)
    }

    const deleteSimpleCard = () => {
        //notify will work after add logic in delete function in masterpass


        if (card?.isMasterPassMember) {
            forms?.deleteCard(card?.name, () =>
                notify(`${t('successfullyDeleted')}`, true),
            )
        } else {
            dispatch(
                fetchRemoveCard({
                    body: {
                        CardId: card?.id,
                        MerchantUserId: '28433',
                    },
                    browserToken: browserToken,
                }),
            )
        }

        toggleDeleteModal()
    }

    const toggleDeleteModal = () => {
        setIsDeleteModalOpen((pr) => !pr);
        setCard(null)
    }

    const toggleEditModal = () => {
        setIsEditModalOpen((pr) => !pr);
        setCard(null)
    }

    const editBtnDisabled = () => {
        return !!(value?.trim() && value !== card?.name)
    }

    const editSimpleCardName = () => {
        if (card?.isMasterPassMember) {
            const bindingId = `MS_${card?.pan}`

            dispatch(editSimpleCardNameThunk({ bindingId, name: value })).then(
                res => {
                    if (
                        res.payload.status === 200 &&
                        res.payload.data.resultCodes === 'OK'
                    ) {
                        notify(`${t('cardNameChanged')}`, true)
                        dispatch(forms?.getToken(token))
                    } else {
                        notify(`${t('itWorkChange')}`, false)
                    }
                },
            )
        } else {
            dispatch(
                fetchUpdateCard({
                    body: {
                        CardId: card?.id,
                        Name: value,
                        // MerchantUserId: '28433',
                    },
                    browserToken: browserToken,
                }),
            )
        }

        setValue('')
        toggleEditModal()
    }

    console.log(cards)

    return (
        <LayoutEmpty goBack={goBack} navTitle={t("home")}>
            <div className="my-cards">
                <div className="my-cards__cards-box my-cards__cards-box--top">
                    <div className="my-cards__box-title">{t("mycards")}</div>
                    {
                        isLoading ?
                            <div className="my-card-skeleton-wrap">
                                {myCardArr.map((item) => {
                                    return <>
                                        <div className="my-card-skeleton"><MyCardSkeleton /></div>
                                        <div className="media900-my-card-skeleton"><Media900MyCardSkeleton /></div>
                                        <div className="media550-my-card-skeleton"><Media900MyCardSkeleton /></div>
                                    </>
                                })}</div>
                            : (
                                <>
                                    <div className="my-cards__cards">
                                        {
                                            cards.map((item: ICard) => (
                                                <CustomMyCard
                                                    key={item.id}
                                                    item={item}
                                                    isDefault={`MS_${item?.id}` === defaultPan}
                                                    setCard={setCard}
                                                    getSelectOption={getSimpleCardOption}
                                                    options={simpleCardOptions}
                                                    cardName={item.name}
                                                    cardNumber={item.pan}
                                                />
                                            ))
                                        }
                                        <Link className="my-cards__add-card" to={`/epay/${language}/add-my-card`}
                                            state={{ hasDefaultNameField: true }}>
                                            <div className="my-cards__add-icon">
                                                <img className="my-cards__img"
                                                    src={require('../../img/add-card-icon.svg').default} alt="icon" />
                                            </div>
                                            <div className="my-cards__add-title">{t("addNewCard")}</div>
                                        </Link>
                                    </div>
                                </>
                            )
                    }
                </div>

                {digitalCard?.card !== undefined
                    ?
                    <div className="my-cards__cards-box">
                        <div className="my-cards__box-title">{t("virtualCard")}</div>
                        {
                            isDigitalCardLoading ?
                                <div className="my-card-skeleton-wrap">
                                    <div className="my-card-skeleton"><MyCardSkeleton /></div>
                                    <div className="media900-my-card-skeleton"><Media900MyCardSkeleton /></div>
                                    <div className="media550-my-card-skeleton"><Media900MyCardSkeleton /></div>
                                </div>
                                : (
                                    <>
                                        <div className="my-cards__cards my-cards__cards--digital">
                                            {
                                                digitalCard?.isProcessLoading &&
                                                <div className='my-cards__loading'>
                                                    <LoadingLocal />
                                                </div>
                                            }
                                            <DigitalCard
                                                currency={digitalCard?.card?.currency}
                                                isRequisites={isRequisites}
                                                cardPan={digitalCard?.card?.pseudo_pan}
                                                cardName={`${t("virtualCard")}`}
                                                options={digitalCardOptions}
                                                balance={digitalCard?.info?.available_balance}
                                                getSelectOption={getDigitalCardOption}
                                            />

                                            {/*ВРЕМЕННО ЗАКОММЕНТИРОВАНО, ЕСЛИ ОТКРЫТЬ, ТО НАДО ПРОПИСАТЬ УСЛОВИЕ*/}
                                            {/*<Link className="my-cards__add-card" to="/create-virtual-card"*/}
                                            {/*      state={{hasDefaultNameField: true}}>*/}
                                            {/*    <div className="my-cards__add-icon">*/}
                                            {/*        <img className="my-cards__img"*/}
                                            {/*             src={require('../../img/add-card-icon.svg').default} alt="icon"/>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="my-cards__add-title">{t("addNewCard")}</div>*/}
                                            {/*</Link>*/}
                                        </div>
                                    </>
                                )
                        }
                    </div>
                    : null
                }
            </div>
            <CustomModal active={isDeleteModalOpen} setActive={setIsDeleteModalOpen}>
                <div className="my-cards__modal">
                    <div className="my-cards__modal-header">
                        <div className="my-cards__modal-title">{t('deleteCard')}</div>
                        <div className="my-cards__close-icon" onClick={() => toggleDeleteModal()}>
                            <img className="my-cards__img" src={require('../../img/close-icon.svg').default} alt="icon" />
                        </div>
                    </div>
                    <div className="my-cards__card-info">
                        {card?.pan}
                    </div>
                    <div className="my-cards__btns">
                        <div className="my-cards__btn" onClick={() => deleteSimpleCard()}>{t('delete')}</div>
                        <div className="my-cards__btn my-cards__btn--cancel" onClick={() => toggleDeleteModal()}>
                            {t('cancel')}
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal active={isEditModalOpen} setActive={setIsEditModalOpen}>
                <div className="my-cards__modal">
                    <div className="my-cards__modal-header">
                        {/*<div className="my-cards__modal-title">Удаление карты</div>*/}
                        <div className="my-cards__close-icon" onClick={() => toggleEditModal()}>
                            <img className="my-cards__img" src={require('../../img/close-icon.svg').default} alt="icon" />
                        </div>
                    </div>
                    <div className="my-cards__card-info">
                        <label htmlFor="field" className="my-cards__card-label">{t('cardName')}</label>
                        <input
                            className="my-cards__card-input"
                            type="text"
                            id="field"
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                        />
                    </div>
                    <div className="my-cards__btns">
                        <div
                            // className="my-cards__btn"
                            className={editBtnDisabled() ? "my-cards__btn" : "my-cards__btn my-cards__btn--disabled"}
                            onClick={() => editSimpleCardName()}
                        >
                            {t('edit')}
                        </div>
                        <div className="my-cards__btn my-cards__btn--cancel" onClick={() => toggleEditModal()}>
                            {t('cancel')}
                        </div>
                    </div>
                </div>
            </CustomModal>
        </LayoutEmpty>
    )
};

export default MyCard;
