import React, { FC } from "react"
import ContentLoader from "react-content-loader"

interface Props {
    size?: any,
}


const SettingsSkeleton768: FC<Props> = ({size}) => (
    <ContentLoader
        speed={2}
        width='100%'
        height={1000}
        viewBox={`0 0 ${size} 1000`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle style={{display: 'flex'}} cx={'49%'} cy="54" r="54" />
        <rect style={{display: 'flex'}} x="0" y="173" rx="0" ry="0" width='100%' height="54" />
        <rect style={{display: 'flex'}} x="0" y="247" rx="0" ry="0" width='100%' height="54" />
        <rect style={{display: 'flex'}} x="0" y="321" rx="0" ry="0" width='100%' height="54" />
        <rect style={{display: 'flex'}} x="0" y="395" rx="0" ry="0" width='100%' height="38" />
        <rect style={{display: 'flex'}} x="0" y="453" rx="0" ry="0" width='100%' height="54" />
        <rect style={{display: 'flex'}} x="0" y="527" rx="0" ry="0" width='100%' height="38" />
        <rect style={{display: 'flex'}} x="0" y="585" rx="0" ry="0" width='100%' height="38" />

        <rect style={{display: 'flex'}} x="0" y="643" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="643" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="695" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="695" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="747" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="747" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="799" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="799" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="851" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="851" rx="0" ry="0" width="200" height="32" />

        <rect style={{display: 'flex'}} x="0" y="903" rx="0" ry="0" width="32" height="32" />
        <rect style={{display: 'flex'}} x="50" y="903" rx="0" ry="0" width="200" height="32" />
    </ContentLoader>
)

export default SettingsSkeleton768