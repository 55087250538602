import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";

export const WalletInfo = () => {
  const { t } = useTranslation();
  const { walletList } = useAppSelector((state: { walletList: any; }) => state.walletList);

  return (
    <div className="top-up-balance__left-wrap-wallet">
      <span className="top-up-balance__left-wrap-wallet-head">
        {t('wallet')}
      </span>
      <div className="top-up-balance__left-wrap-wallet-box">
        {walletList?.wallet?.map((wallet: { currency: any; balance: number; }) => (
          <div className="top-up-balance__left-wrap-wallet-box-item">
            <div className="top-up-balance__left-wrap-wallet-box-item-wal">{`${t('wallet')} ${wallet.currency}`}</div>
            <div className="top-up-balance__left-wrap-wallet-box-item-price">
              {wallet.balance / 100}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
