import React, {useEffect} from 'react';
import "./index.scss"
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../Components/layouts/LayoutEmpty/LayoutEmpty";
import {useNavigate} from "react-router-dom";
import { useAppSelector } from '../../hooks/redux';

const img1 = require('../../img/e-walletpull.jpg')
const img2 = require('../../img/bonusIcon.jpg')
const img3 = require('../../img/debtIcon.jpg')
const img4 = require('../../img/recurringPayment.jpg')
const img5 = require('../../img/cardRegistration.jpg')
const img6 = require('../../img/historyPayment.jpg')
const img7 = require('../../img/card2Card.jpg')
const img8 = require('../../img/security.jpg')
const img9 = require('../../img/mobileApp.jpg')
const img10 = require('../../img/invoice.jpg')

const Advantages = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { language } = useAppSelector((state) => state.changeLangSlice);

    const advantageArr = [
        {
            img:img1 ,
            title: t("eWallet"),
            // path: '/advantages-wallet',
            path: 'https://blog.e-pul.az/?p=384',
        },
        {
            img: img2,
            title: t("bonus"),
            // path: '/advantages-bonus',
            path: 'https://blog.e-pul.az/?p=94',
        },
        {
            img: img3,
            title: t("debts"),
            // path: '/advantages-debts',
            path: 'https://blog.e-pul.az/?p=32',
        },
        {
            img: img4,
            title: t("automaticPayments"),
            // path: '/advantages-auto-payment',
            path: 'https://blog.e-pul.az/?p=78',
        },
        {
            img: img5,
            title: t("cardRegistration"),
            // path: '/advantages-cart-register',
            path: 'https://blog.e-pul.az/?p=38',
        },
        {
            img: img6,
            title: t("paymentHistory"),
            // path: '/advantages-history',
            path: 'https://blog.e-pul.az/?p=70',
        },
        {
            img: img7,
            title: t("fromCard2Card"),
            // path: '/advantages-c2c',
            path: 'https://blog.e-pul.az/?p=26',
        },
        {
            img: img8,
            title: t("security"),
            path: '/advantages-security',
        },
        {
            img: img9,
            title: t("mobileApp"),
            path: 'https://blog.e-pul.az/?p=108',
        },
        {
            img: img10,
            title: t("invoice"),
            // path: '/advantages-faktura',
            path: 'https://blog.e-pul.az/?p=472',
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const goBack = () => navigate(`/epay/${language}/home`);

    return (
        <LayoutEmpty goBack={goBack} navTitle={t("home")}>
            <div className="advantages">
                <div className="advantages__body">
                    {
                        advantageArr.map((item, index) => (
                            <a href={`${item.path}`} className="advantages__body-item">
                                <div className="advantages__body-item-img">
                                    <img
                                        src={item.img} alt="advantages"
                                        // width="100%"
                                    />
                                </div>
                                <div>
                                    <p className="advantages__body-item-text">{item.title}</p>
                                </div>
                            </a>
                        ))
                    }
                </div>

            </div>
        </LayoutEmpty>
    );
};

export default Advantages;