import React, { useState, useEffect } from 'react'
import './index.scss'
import { usePaymentInputs } from 'react-payment-inputs'
// import { Button } from './UI'

import { useNavigate } from 'react-router-dom';
import { masterPassSliceActions } from '../../../redux/slices/masterpassSlice';

import images from 'react-payment-inputs/images'
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import Button from "../../Button/Button";
import { VoiceChatRounded } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';

export type CardDataType = {
    cvc: string,
    cardName?: string,
    cardNumber: string,
    expiryDate: string,
}

interface IInputFieldComponentProps {
    infoImage?: boolean;
    hasDefaultNameField?: boolean;
    isCardSaved?: boolean;
    cardDataCb?: (data: CardDataType) => void;
}
const InputFieldComponent = (props: IInputFieldComponentProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'all'
    })

    const { infoImage, hasDefaultNameField, cardDataCb, isCardSaved } = props;
    const [activeModal, setActiveModal] = React.useState(false);

    const ERROR_MESSAGES = {
        emptyCardNumber: 'do111111111',
        invalidCardNumber: '12o',
        emptyExpiryDate: 'La fecha de expiración es inválida',
        monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
        yearOutOfRange: 'El año de expiración no puede estar en el pasado',
        dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
        invalidExpiryDate: 'La fecha de expiración es inválida',
        emptyCVC: 'El código de seguridad es inválido',
        invalidCVC: 'El código de seguridad es inválido',
    }

    const {
        meta: { erroredInputs, touchedInputs },
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getCardImageProps,
    } = usePaymentInputs({
        errorMessages: ERROR_MESSAGES,
    })

    const [cardNumber, setCardNumber] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [cardName, setCardName] = useState('')
    const [cvc, setCvc] = useState('')

    // const handleChangeCardNumber = ({ target: { value } }: { target: { value: string } }) => {
    //     setCardNumber(value)
    // }

    const handleChangeCardNumber = (event: any) => {
        const value = event.target.value;
        const cardFirstTwoSymbols = value.slice(0, 2);
        if ((cardFirstTwoSymbols == '34' || cardFirstTwoSymbols == '37')) {
            setCardNumber(value);
        }
        else {
            const formattedValue = value.replace(/[^\d]/g, '');
            let formattedCardNumber = '';
            for (let i = 0; i < formattedValue.length; i += 4) {
                formattedCardNumber += formattedValue.slice(i, i + 4) + ' ';
            }
            setCardNumber(formattedCardNumber.trim());
        }
    };

    const handleChangeCardName = ({ target: { value } }: { target: { value: string } }) => {
        setCardName(value)
    }

    const handleChangeExpiryDate = ({ target: { value } }: { target: { value: string } }) => {
        setExpiryDate(value)
    }

    const handleChangeCVC = ({ target: { value } }: { target: { value: string } }) => {
        setCvc(value)
    }

    useEffect(() => {
        const data: CardDataType = {
            cardNumber: cardNumber.replaceAll(' ', ''),
            expiryDate,
            cvc,
        }
        // if(!isCardSaved) {
        //     setCardName('')
        // }

        if (isCardSaved || hasDefaultNameField) {
            data.cardName = cardName;
        }

        cardDataCb && cardDataCb(data);
    }, [cardName, cardNumber, expiryDate, cvc, isCardSaved])

    const goToCardsPage = () => {
        return navigate('/cards', { replace: true })
    }

    const onSubmit = () => {
        console.log('submit')
    }

    const notifyParentOfErrors = (errors: any) => {
        //@ts-ignore
    if (props.onErrorsUpdate) { // Проверка наличия функции onErrorsUpdate
        //@ts-ignore
        props.onErrorsUpdate(errors);
    }
};

    const showCardNumberErrors = () => {
        notifyParentOfErrors(erroredInputs);
        if (erroredInputs.cardNumber && touchedInputs.cardNumber && cardNumber.length === 0) {
            return <p className="errorMsg">{t("RequiredField")}</p>
        }
        else if (erroredInputs.cardNumber && touchedInputs.cardNumber) {
            return <p className="errorMsg">{t(`InvalidCardNumber`)}</p>
        }
        else { return }
    }

    const showExpiryDateErrors = () => {
        notifyParentOfErrors(erroredInputs);
        if (erroredInputs.expiryDate && touchedInputs.expiryDate && expiryDate.length === 0) {
            return <p className="errorMsg">{t("RequiredField")}</p>
        }
        else if (erroredInputs.expiryDate && touchedInputs.expiryDate) {
            return <p className="errorMsg">{t("InvalidExpiryDate")}</p>
        }
        else { return }
    }

    const showCVCErrors = () => {
        notifyParentOfErrors(erroredInputs);
        if (erroredInputs.cvc && touchedInputs.cvc && cvc.length === 0) {
            return <p className="errorMsg">{t("RequiredField")}</p>
        }
        else if (erroredInputs.cvc && touchedInputs.cvc) {
            return <p className="errorMsg">{t("InvalidCVC")}</p>
        }
        else { return }
    }

    return (
        <div className='new-card '>
            <form onSubmit={handleSubmit(onSubmit)} className='new-card__form'>
                <div className='new-card__form-card-items border-radius'>
                    {
                        (hasDefaultNameField || isCardSaved) && <div>
                            <label className='new-card__form-item new-card__form-top'>
                                <span>{t("cardName")}</span>
                                <input
                                    className='border-radius'
                                    value={cardName}
                                    placeholder={`${t("cardName")}`}
                                    {...register('cardName', {
                                        required: true,
                                        minLength: 1,
                                    })}
                                    onChange={handleChangeCardName}
                                />
                                {errors.cardName && errors.cardName.type === "required" && (<p className="errorMsg">{t("cardNameIsRequired")}</p>)}
                            </label>
                        </div>
                    }
                    <label className='new-card__form-item new-card__form-top'>
                        <span style={{marginTop: '10px'}}>{t("cardNumber")}</span>
                        <div className="new-card__form-item-card-icon">
                            <svg
                                {...getCardImageProps({ images: images as any })}
                            />
                        </div>

                        <input
                            className='border-radius'
                            value={cardNumber}
                            maxLength={19}
                            {...getCardNumberProps({ onChange: handleChangeCardNumber })}
                            placeholder='••••  ••••  ••••  ••••'
                        />
                        {showCardNumberErrors()}
                    </label>

                    <div className='new-card__form-item new-card__form-bottom'>
                        <label style={{ display: 'flex', flexDirection: 'column' }} className='new-card__form-bottom-left'>
                            <span>{t("expirationDate")}</span>
                            <input
                                className='border-radius'
                                value={expiryDate}
                                {...getExpiryDateProps({ onChange: handleChangeExpiryDate })}
                                placeholder='•• / ••'
                            />
                            {/* {erroredInputs.expiryDate && touchedInputs.expiryDate && <p className="errorMsg">{t("cardNameIsRequired")}</p>} */}
                            {showExpiryDateErrors()}
                        </label>

                        <label style={{ display: 'flex', flexDirection: 'column' }} className='new-card__form-bottom-right'>
                            <span>CVV / CVC</span>
                            <input
                                style={{ position: 'relative' }}
                                className='border-radius'
                                {...getCVCProps({ onChange: handleChangeCVC })}
                                value={cvc}
                                placeholder='•••'
                            />
                            {showCVCErrors()}
                        </label>

                        {infoImage &&
                            <>
                                <div className="add-my-card__card-footer-image">
                                    <img onClick={() => setActiveModal(true)}
                                        className="add-my-card__card-footer-info-icon"
                                        src={require("../../../img/info-icon1.svg").default}
                                    />
                                </div>
                                <CustomModal active={activeModal} setActive={setActiveModal}>
                                    <div className="modal-cvv">
                                        <div className="modall-cvv__image">
                                            <img src={require("../../../img/CVVcard.png")} alt="" />
                                        </div>

                                        <Button
                                            buttonText={"Ok"}
                                            onClick={() => setActiveModal(false)}
                                            minWidth={200}
                                            margin="0 auto"
                                            padding="14px 130px"
                                            borderRadius={15}
                                            background="#EF5C27"
                                            color="#FFFFFF"
                                        />
                                    </div>
                                </CustomModal>
                            </>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default InputFieldComponent
