import React, {FC} from 'react';
import "../../Components/FavoritesComponent/index.scss"
import HeaderText from "../../Components/CustomsComponents/HeaderText";
import SelectedCategory from "../../Components/CustomsComponents/SelectedCategory";
import {useNavigate} from "react-router-dom";
import NavigationComponent from "../../Components/CustomsComponents/NavigationComponent";
import {useTranslation} from "react-i18next";

interface NewFavoritesProps {
}

const NewFavorites: FC = (props: NewFavoritesProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <div className="add-favorites">
            <NavigationComponent onClick={goBack} title={t("selectedServices")}/>
            <HeaderText title={t("newTemplate")}/>
            <div className="add-favorites__select-category">{t("chooseCategory")}</div>
            <SelectedCategory/>
        </div>
    );
};

export default NewFavorites;
