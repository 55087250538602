import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchBonusStore} from "../actions/bonusesAction";
import {IBonusGift} from "../../models/BonusStore";

interface InitialState {
    sid: string,
    error: string,
    bonusStore: IBonusGift[] ,
    isLoading: boolean
}

const initialState: InitialState = {
    sid: "",
    error: "",
    bonusStore: [],
    isLoading: false
}

export const bonusStoreSlice = createSlice({
    name: "bonusStore",
    initialState,
    reducers: {
        clearBonusStore(state) {
            state.bonusStore = []
        }
    },
    extraReducers: {
        [fetchBonusStore.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBonusStore.fulfilled.type]: (state, action: PayloadAction<IBonusGift[]>) => {
            state.isLoading = false
            state.error = ""
            state.bonusStore = [...state.bonusStore, ...action.payload]
        },
        [fetchBonusStore.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export default bonusStoreSlice.reducer
export const clearBonusStoreAction = () => ({
    type: "bonusStore/clearBonusStore",

})