import React from 'react';
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type Props = {
    options?: any,
    getSelectOption?: (value: string) => void;
}

const options = [
    "Удалить",
    "Получить реквизиты",
    "Пополнить",
    "Преименовать",
];

const ITEM_HEIGHT = 48;

const VerticalIcon: React.FC<Props> = ({options, getSelectOption}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const getMenuOption = (option: string) => {
        if (getSelectOption) {
            getSelectOption(option);
        }
        handleClose();
    }


    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button"
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="kamran"
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "25ch",
                        borderRadius: "15px"
                    }
                }}
            >
                {options.map((option: any) => (
                    <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={() => getMenuOption(option)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default VerticalIcon;
