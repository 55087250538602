import React from "react";
import {t} from "i18next";
import Button from "../Button/Button";

const ModalButtons = ({text, buttonText, handleInstruction, handleCancel}) => {

    return (
        <div className="registration-block__modal">
            <div className="registration-block__modal-head">
                <div className="registration-block__modal-head-text">
                    {t(text)}
                </div>
                <div style={{cursor: "pointer", marginLeft: "10px"}} onClick={handleCancel}>
                    <img
                    src={require("../../img/close-icon.svg").default}
                    alt="Close"
                    width={20}
                    height={20}
                    loading={"lazy"}
                    />
                </div>
            </div>
            <div className="registration-block__modal-body">
                <div className="registration-block__modal-body-logout">
                    <Button
                        buttonText={t(buttonText)}
                        background="#EF5C27"
                        color="white"
                        border="1px solid #EF5C27"
                        onClick={handleInstruction}
                        padding={"14px 30px"}
                        borderRadius={15}
                    />
                    <Button
                        buttonText={`${t("canceling")}`}
                        background="white"
                        color="#EF5C27"
                        border="1px solid #EF5C27"
                        onClick={handleCancel}
                        padding={"14px 30px"}
                        borderRadius={15}
                    />
                </div>
            </div>
        </div>
    );
};

export default ModalButtons;