import React from "react"
import ContentLoader from "react-content-loader"

const AutoPaymentServiceSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={60}
        viewBox="0 0 300 70"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <circle cx="28" cy="40" r="27" />
        <rect x="70" y="26" rx="0" ry="0" width="200" height="27" />
    </ContentLoader>
)

export default AutoPaymentServiceSkeleton