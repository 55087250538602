import React, {FC} from 'react';
import AddCheckboxIn from "../../../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import CenterMode from "../../../MyCardComponent/CenterMode";
import {useTranslation} from "react-i18next";

interface ICardsBoxProps {
    isChecked: boolean;
    setIsChecked: (data: boolean) => void;
    setInstrument: (data: any) => void; // add types
}

const CardsBox: FC<ICardsBoxProps> = (props) => {
    const {t} = useTranslation()
    return(
        <>
            <div className="service__pay-another-card">
                <p className="service__card-box-title">{t("paymentMethod")}</p>
            </div>
            <CenterMode onSelect={data => props.setInstrument(data)}/>
            <AddCheckboxIn
                title={`${t("addPaymentFavorites")}`}
                padding="10px 0 20px 0"
                onClick={props.setIsChecked}
                isChecked={props.isChecked}
            />
        </>)
}


export default CardsBox