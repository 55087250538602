import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {makeStyles} from "@material-ui/core/styles";
import {addZero} from "../../../utils/functions";
import "./index.scss";
import Button from "../../Button/Button";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../../hooks/redux";
import {useTranslation} from "react-i18next";
import BillsSkeleton from "../../Sceletons/BillsSkeleton/BillsSkeleton";
import {Category} from "../../../models/Categories";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    "&.MuiTabs-flexContainer ": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  tabTwo: {
    "&.MuiButtonBase-root.MuiTab-root": {
      width: "50%",

      "&.Mui-selected": {
        color: "#EF5C27",
        display: "flex",
        flexDirection: "row",
      },
    },
  },
  colorSelected: {
    "&.MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#EF5C27",
    },
  },
  tabsLine: {
    "&.MuiTabs-flexContainer": {
      backgroundColor: "#EF5C27",
    },
  },
  padTab: {
    "&.MuiBox-root": {
      padding: 0,
    },
  },
}));

const bilsMockSkeleton = [1, 2];

export default function BasicTabs() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedId, setSelectedId] = useState<any>([]);
  const { debtsList, isLoading } = useAppSelector((state) => state.debtsList);
  const { categories, services } = useAppSelector((state) => state.categoriesReducer);
  const debtItems = debtsList?.items?.paymentListItem;
  const { language } = useAppSelector((state) => state.changeLangSlice);

  // const toggleAcordion = () => {
  //     setExpand((prev) => !prev);
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getDebtDate = (value: any): any => {
    const date = new Date(value);

    return `${addZero(date.getDate())}.${addZero(
      date.getMonth() + 1
    )}.${addZero(date.getFullYear())}`;
  };

  const createFields = (item: any) => {
    return item?.fields?.paymentListField.map((item: any) => ({
      id: item.fieldId.toString(),
      value: item.value,
      name: item.name,
    }));
  };

  const clickToItem = (id: number) => {
    if (selectedId?.includes(id)) {
      setSelectedId(selectedId.filter((item: number) => item !== id));
    } else {
      setSelectedId([...selectedId, id]);
    }
  };

  const createLink = (debt: any) => {
    const categoryName = categories?.find((category: Category) => category.id === debt.categoryId)?.name;
    const serviceName = services?.find((service: any) => service.id === debt.serviceId);

    return `/epay/${language}/payment-add/category/${categoryName}/${serviceName?.friendlyName}`
  }

  // @ts-ignore
  return (
    <>
      {isLoading ? (
        bilsMockSkeleton.map((item: number) => {
          return <BillsSkeleton key={item} />;
        })
      ) : debtItems?.length ? (
        debtItems?.map((item: any) => (
          <div className="tab-panel" key={item.id}>
            <Accordion
              elevation={0}
              style={{ width: "100%", padding: "10px" }}
              expanded={selectedId?.includes(item.id) ? true : false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => clickToItem(item.id)}
              >
                <div className="tab-panel-accordion">
                  <div style={{display: "flex", alignItems: "center", width: "52%"}}>
                    <div className="tab-panel__img">
                      <img
                          className="tab-panel__img-pic"
                          src={require("../../../img/pic.svg").default}
                          alt={"tab-panel__img-pic"}
                      />
                    </div>
                    <div className="tab-panel__adress">
                      <div className="tab-panel__adress-street" title={item?.fields?.paymentListField[0].value}>
                        {item?.fields?.paymentListField[0].value}
                      </div>
                      <div className="tab-panel__adress-info">
                        <div
                            className="tab-panel__adress-info-item"
                            title={item?.localName?.valueRu}
                        >
                          {item?.localName?.valueRu}
                        </div>
                        <div className="tab-panel__adress-info-item-dot">{"•"}</div>
                        <div className="tab-panel__adress-info-item-data">
                          {getDebtDate(item?.insertDate)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", alignItems: "center", width: "48%"}}>
                    <div className="tab-panel__price">
                      <div className="tab-panel__price-item">{`${
                          item?.lastDebt / 100
                      } ${item?.currency}`}</div>
                    </div>
                    <Link
                        to={createLink(item)}
                        state={{
                          // favorite:true,
                          serviceId: item.serviceId,
                          fields: createFields(item),
                          groupId: item.groupId,
                        }}
                        className="tab-panel__btnPay"
                    >
                      <Button
                          buttonText={`${t("pay")}`}
                          padding="10px 25px"
                          borderRadius={15}
                          border="1px solid #F1F1F1"
                          // background={"#FFFFFF"}
                          background={
                            selectedId?.includes(item.id) ? "#EF5C27" : "#FFFFFF"
                          }
                          color={
                            selectedId?.includes(item.id) ? "#FFFFFF" : "black"
                          }
                      />
                    </Link>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="tab-panel-accordion__footer">
                  <div className="tab-panel-accordion__footer-wrapl">
                    <div className="tab-panel-accordion__footer-wrapl-item">
                      <div className="tab-panel-accordion__footer-wrapl-item-text">
                        {t("service")}
                      </div>
                      <div className="tab-panel-accordion__footer-wrapl-item-com">
                        {item?.localName?.valueRu}
                      </div>
                    </div>
                    {item?.fields?.paymentListField?.map((data: any) => (
                      <div
                        className="tab-panel-accordion__footer-wrapl-item"
                        key={item.id}
                      >
                        <div className="tab-panel-accordion__footer-wrapl-item-text">
                          {data?.localName.valueRu}
                        </div>
                        <div className="tab-panel-accordion__footer-wrapl-item-com">
                          {data?.value}
                        </div>
                      </div>
                    ))}

                    {item?.comment && (
                      <div className="tab-panel-accordion__footer-wrapl-item">
                        <div className="tab-panel-accordion__footer-wrapl-item-text">
                          {t("comment")}
                        </div>
                        <div className="tab-panel-accordion__footer-wrapl-item-com">
                          {item?.comment}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="tab-panel-accordion__footer-wrapr">
                    <div className="tab-panel-accordion__footer-wrapr-item">
                      <div className="tab-panel-accordion__footer-wrapl-item-text">
                        {t("amountWithCommission")}
                      </div>
                      <div className="tab-panel-accordion__footer-wrapl-item-com">{`${
                        item?.lastDebt / 100
                      }`}</div>
                    </div>
                    <div className="tab-panel-accordion__footer-wrapr-item">
                      <div className="tab-panel-accordion__footer-wrapl-item-text">
                        {t("lastUpdateDate")}
                      </div>
                      <div className="tab-panel-accordion__footer-wrapl-item-com">
                        {getDebtDate(item?.lastRequestDate)}
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      ) : (
        <div className="tab-panel__not-found"> {t("debtsEmpty")} </div>
      )}
    </>
  );
}
