import React from "react"
import ContentLoader from "react-content-loader"

const InvitationSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={80}
        viewBox="0 0 100% 80"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        {/*<rect x="504" y="36" rx="0" ry="0" width="7" height="496" />*/}
        <rect x="0" y="20" rx="9" ry="9" width="465" height="35" />
    </ContentLoader>
)

export default InvitationSkeleton