import React from 'react'
import { connect } from 'react-redux'
import { Languages } from '../../redux/reducers/language/languageData'
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import withRouter from '../../HOC/widthRouter'
import { addZero } from '../../utils/functions'
import { getQueryParams } from '../../utils/query'
import { ServiceActionCreators } from '../../redux/reducers/service/action-creators'
import "./index.scss";

export const verdictFields = new Set()
verdictFields.add('dlNumber')
verdictFields.add('dlSeries')

class PaymentFields extends React.Component {
    constructor(props) {
        super(props)

        const groupId = props.defaultGroupId || props.service.groups[0]?.id
        const fields = props.defaultFields || this.createFields(groupId)
        const emptyQr = fields.filter(f => !f.value && f.qrfield)
        const emptyBar = fields.filter(f => !f.value && f.barfield)

        this.state = {
            groupId,
            fields: fields,
            qrModal: emptyQr.length === 1 ? emptyQr[0].id : undefined,
            barModal: emptyBar.length === 1 ? emptyBar[0].id : undefined,
            phoneNumber: '',
            phoneNumberValid: false,
            isModalVisible: false,
            startDate: null,
        }
        props.onValueChange(this.state)
    }

    componentDidMount() {
        this.setValueQueryParams()

        this.isHubSolutionPaymentMethod()
    }

    componentDidUpdate(prevP, prevS) {


        if (prevS.groupId !== this.state.groupId) {
            this.setValueQueryParams(this.state.groupId)
        }

        if (
            prevP.isClientExist !== this.props.isClientExist &&
            this.props.isClientExist
        ) {
            this.setValueQueryParams(this.state.groupId)
        }
    }

    setValueQueryParams(groupId) {
        let queryValueFields = [],
            fields = [],
            amount = null,
            cardType = null

        const cardTypeParamNames = ['card_type', 'cardType']

        if (this.props.azercell) {
            if (getQueryParams()?.number) {
                this.setState({ phoneNumber: getQueryParams()?.number })
                queryValueFields.push(
                    ...[
                        {
                            ...this.state.fields?.[0],
                            value: getQueryParams()?.number?.substring(0, 2),
                        },
                        {
                            ...this.state.fields?.[1],
                            value: getQueryParams()?.number?.substring(2),
                        },
                    ],
                )
            }
        }

        if (!groupId) {
            groupId = +getQueryParams()?.groupId ? +getQueryParams()?.groupId : null
        }

        Object.keys(getQueryParams()).forEach(key => {
            if (key === 'amount' && getQueryParams()['amount']) {
                amount = getQueryParams()['amount']
            }

            if (cardTypeParamNames.includes(key)) {
                const keyValue = getQueryParams()[key]

                if (keyValue) {
                    cardType = this.props.fieldsForPicker.find(
                        field => field.value === keyValue,
                    )
                }
            }

            if (groupId) {
                fields = this.onPickerGroupValueChange(groupId)

            }

            if (!this.props.azercell) {
                if (fields.length !== 0) {
                    fields.forEach(field => {
                        if (field.name === key) {
                            queryValueFields.push({
                                ...field,
                                value: getQueryParams()?.[key],
                            })
                        }
                    })

                }
                else {
                    this.state.fields.forEach(field => {
                        if (field.name === key) {
                            queryValueFields.push({
                                ...field,
                                value: getQueryParams()?.[key],
                            })
                        }
                    })
                }
            }
        })

        const allFieldsHaveData =
            queryValueFields.length === this.state.fields.length

        let autoRedirectToPayment = false

        if (allFieldsHaveData && amount && cardType?.value) {
            autoRedirectToPayment = true
        }

        if (allFieldsHaveData) {
            this.setState({ fields: queryValueFields, paramAmountValue: amount })
            this.props.onValueChange({
                fields: queryValueFields,
                allFieldsHaveData: allFieldsHaveData,
                paramAmountValue: amount,
                cardPanId: cardType?.value,
                loadingNotTransparent: autoRedirectToPayment,
            })
        }

        if (this.props.isClientExist) {
            this.props.onValueChange({
                autoRedirectToPayment: autoRedirectToPayment,
            })
        }
    }

    saveValue(id, value, commit) {
        const fields = this.state.fields.map(field =>
            field.id === id ? { ...field, value: value } : field,
        )
        this.setState({ fields })
        this.props.onValueChange({ fields, groupId: this.state.groupId }, commit)
    }

    phoneNumberSaveValue(id, value, commit) {
        let prefix = ''
        let number = ''
        this.setState({ phoneNumber: value })
            prefix = value.substring(0, 2)
            number = value.substring(2)
            let fields = [...this.state.fields]
            fields[0] = { ...fields[0], value: prefix }
            fields[1] = { ...fields[1], value: number }
            this.setState({ fields })
            this.props.onValueChange({ fields, groupId: this.state.groupId }, commit)
    }
    returnValue(id) {
        const field = this.state.fields.find(field => field.id === id)
        return field?.value
    }

    renderPicker(id, localName, pickerItems, example) {
        const { isClientExist } = this.props
        const value = this.returnValue(id)
        const options = pickerItems.map(item => ({
            value: item.value,
            label: item.label,
            key: item.key,
        }));
        return (
            <div className='service_fields-item'>
                <p className='service_fields-item-title'>{localName}</p>
                <Select
                    aria-label="service_fields"
                    className={!value ? 'select-not-value' : null}
                    classNamePrefix="custom-select"
                    options={options}
                    onChange={selectedOption => {
                        this.saveValue(id, selectedOption.value);
                    }}
                    value={options.find(option => option.value === value) || null}
                    isDisabled={isClientExist}
                    placeholder={localName}
                />
            </div>
        )
    }

    renderPhoneNumber() {
        const { langKey } = this.props
        const {
            service: {
                serviceFields: { serviceField },
            },
        } = this.props

        return (
            <div className='service_fields-item'>
                {serviceField
                    .filter(v => v.type !== 'hidden')
                    .map(item => {
                        if (item.type === 'number') {
                            return (
                                <div key={item.id}>
                                    {this.renderTextFieldForPhone(
                                        item.id.toString(),
                                        item.type,
                                        item.localName[langKey],
                                        item.localPlaceHolder[langKey],
                                        item.regExp,
                                    )}
                                </div>
                            )
                        }
                        return null
                    })}
            </div>
        )
    }

    arrayForPicker(pickerItems) {
        const { langKey } = this.props

        let fields = []
        for (const i in pickerItems) {
            const item = pickerItems[i]
            let field = { label: '', key: '', value: '' }
            field.label = item.localeName[langKey]
            field.key = item.value
            field.value = item.value
            fields.push(field)
        }
        return fields
    }

    createGroupPickerArray() {
        const { langKey } = this.props

        const { service } = this.props
        let fields = []
        for (const group of service.groups) {
            let field = { label: '', key: 0, value: 0 }
            field.label = group.name[langKey]
            field.key = group.id
            field.value = group.id
            fields.push(field)
        }
        return fields
    }

    onPickerGroupValueChange(value) {
        const state = {
            groupId: value,
            fields: this.createFields(value),
        }
        this.setState(state)
        this.props.onValueChange(state)
        return state.fields
    }

    createFields(groupId) {
        const {
            service: {
                serviceFields: { serviceField },
                groups,
            },
        } = this.props

        let fields = []
        if (groups.length >= 2) {
            const group = groups.find(group => group.id === groupId)
            if (group) {
                for (const i in group?.fields) {
                    const item = group?.fields[i]
                    let field = {
                        id: item.id.toString(),
                        name: item.name,
                        value: item.defaultValue,
                        qrfield: item.type === 'barcode',
                        barfield: item.type === 'qrcode',
                    }
                    fields.push(field)
                }
            }
        } else {
            for (const item of groups.length === 1
                ? groups[0].fields
                : serviceField) {
                let field = {
                    id: item.id.toString(),
                    name: item.name,
                    value: item.defaultValue,
                    qrfield: item.type === 'qrcode',
                    barfield: item.type === 'barcode',
                }
                fields.push(field)
            }
        }

        return fields
    }

    renderPickerForGroup() {
        const { isClientExist } = this.props
        const items = this.createGroupPickerArray()
        if (items.length === 1) {
            return
        }
        const options = items.map(item => ({
            value: item.value,
            label: item.label,
            key: item.key,
        }));
        return (
            <div className='service_fields-item'>
                <Select
                    aria-label="service_fields"
                    classNamePrefix="custom-select"
                    options={options}
                    onChange={selectedOption => {
                        this.onPickerGroupValueChange(selectedOption.value);
                    }}
                    value={options.find(option => option.value === this.state.groupId) || null}
                    isDisabled={isClientExist}
                />
            </div>
        )
    }

    renderFields(groups, fields) {
        const { langKey } = this.props
        if (!fields) {
            return undefined
        }

        return (
            <>
                {groups && this.renderPickerForGroup()}

                {fields
                    .filter(
                        v =>
                            v.type !== 'hidden' &&
                            (!verdictFields.has(v.name) || this.props.showVerdictFields),
                    )
                    .map((item, index) => {
                        if (item.type === 'select') {
                            return (
                                <div className='service_fields-item' key={item.id}>
                                    {this.renderPicker(
                                        item.id.toString(),
                                        item.localName[langKey],
                                        this.arrayForPicker(item.listEnums.serviceFieldEnum),
                                        item.localPlaceHolder[langKey],
                                        item.regExp,
                                    )}
                                </div>
                            )
                        }
                        if (item.type === 'date') {
                            return (
                                <div className='service_fields-item' key={item.id}>
                                    {this.renderDateField(
                                        item.id.toString(),
                                        item.type,
                                        item.localName[langKey],
                                        item.localPlaceHolder[langKey],
                                        item.regExp,
                                    )}
                                </div>
                            )
                        }
                        return (
                            <div className='service_fields-item' key={item.id}>
                                {this.renderTextField(
                                    item.id.toString(),
                                    item.type,
                                    item.localName[langKey],
                                    item.localPlaceHolder[langKey],
                                    item.regExp,
                                )}
                            </div>
                        )
                    })}
            </>
        )
    }

    renderTextField(id, kind, localName, example, regExp) {
        const { language: selectedLanguage } = this.props
        const language = Languages(selectedLanguage)
        const { isClientExist, onFieldsValidationChange} = this.props
        const value = this.returnValue(id)
        let inputErrorClassNames = ''
        if (onFieldsValidationChange) {
            if (!new RegExp(regExp).test(value)) {
                if (value?.length > 0) {
                    inputErrorClassNames += 'input-error'
                }
                this.props.onFieldsValidationChange(false);
            } else {
                this.props.onFieldsValidationChange(true);
            }
        }
        return (
            <div className='service_fields-item'>
                <div
                    className={
                        inputErrorClassNames
                            ? 'service_fields-item-title service_fields-item-title--error'
                            : 'service_fields-item-title'
                    }
                >
                    {localName}
                </div>
                <input
                    className={inputErrorClassNames}
                    placeholder={example}
                    onChange={e => {
                        if (!isClientExist) {
                            this.saveValue(id, e.target.value)
                        }
                    }}
                    value={this.returnValue(id)}
                    readOnly={isClientExist}
                />
                {inputErrorClassNames && (
                    <div
                        style={{ color: 'red', textAlign: 'center' }}
                        className='service_fields-item-error'
                    >
                        {language.dataIsNotCorrect}
                    </div>
                )}
            </div>
        )
    }

    renderDateField(id, kind, localName, example) {
        const { isClientExist } = this.props
        const { startDate } = this.state

        return (
            <div className='service_fields-item'>
                <p className='service_fields-item-title'>{localName}</p>
                <DatePicker
                    className='service_fields-item--date'
                    disabled={isClientExist}
                    dateFormat='dd.MM.yyyy'
                    selected={startDate}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    onChange={date => {
                        if (!isClientExist) {
                            const customDate = date
                                ? `${addZero(date.getDate())}.${addZero(
                                    date.getMonth() + 1,
                                )}.${addZero(date.getFullYear())}`
                                : null
                            this.setState({ startDate: date })
                            this.saveValue(id, customDate)
                        }
                    }}
                />
            </div>
        )
    }

    renderTextFieldForPhone(id, kind, localName, example, regExp) {
        const { language: selectedLanguage } = this.props
        const language = Languages(selectedLanguage)
        const { isClientExist, azercell } = this.props
        let value = ''
        if (azercell) {
            value = `${this.state.fields[0].value}${this.state.fields[1].value}`
        } else {
            value = this.returnValue(id) || ''
            if (value?.length > 9) {
                value = value?.substring(value?.length - 9)
            }
        }
        let inputErrorClassNames = ''
        console.log('test1', regExp)
        if (
            azercell &&
            (!new RegExp('^(50|51|10|70|77|55|99)').test(value?.substring(0, 2)) ||
                !new RegExp(regExp).test(value?.substring(2))) &&
            value?.length > 0
        ) {
            inputErrorClassNames += 'input-error'
        }
        if (!azercell) {

            if (!new RegExp(regExp).test(value) && value?.length > 0) {
                inputErrorClassNames += 'input-error'
            }
        }
        return (
            <>
                <div className='service_fields-item'>
                    <div
                        className={
                            inputErrorClassNames
                                ? 'service_fields-item-title service_fields-item-title--error'
                                : 'service_fields-item-title'
                        }
                    >
                        {localName}
                    </div>
                    <div className='service_phone-field'>
                        <div className='service_phone-placeholder'>994</div>
                        <input
                            className={inputErrorClassNames}
                            placeholder={example}
                            onChange={e => {
                                azercell
                                    ? this.phoneNumberSaveValue(+id, e.target.value)
                                    : this.saveValue(id, e.target.value)
                            }}
                            readOnly={isClientExist}
                            maxLength={9}
                            value={value}
                        />
                    </div>
                    {inputErrorClassNames && (
                        <div
                            style={{ color: 'red', textAlign: 'center' }}
                            className='service_fields-item-error'
                        >
                            {language.dataIsNotCorrect}
                        </div>
                    )}
                </div>
            </>
        )
    }

    isHubSolutionPaymentMethod = ()=> {

        const { availablePaymentSystem } = this.props.service

        let isHubSolutionPaymentMethod = false

        availablePaymentSystem.forEach((item)=>{
          if(item.paymentSystem==="HUB_SOLUTION" && item.paymentProcessor === "HUB_SOLUTION") {
            isHubSolutionPaymentMethod  = true
            return
          }
        })


        if(isHubSolutionPaymentMethod) {
            this.props.setServiceIsAvailablePaymentSystem(isHubSolutionPaymentMethod)
            return
        }

        this.props.setServiceIsAvailablePaymentSystem(isHubSolutionPaymentMethod)


    }

    render() {
        const { language: selectedLanguage, langKey } = this.props
        const language = Languages(selectedLanguage)


        const {
            service,
            service: {
                serviceFields: { serviceField },
            },
        } = this.props

        const serviceDescription =
            `<div class="service_description-field-title">${language.information}!</div>` +
            service.localDesc[langKey]

        return (
            <div className='service_fields'>
                {/*{service.localDesc[langKey] && (*/}
                {/*    <p*/}
                {/*        className='service_fields-item service_description-field'*/}
                {/*        dangerouslySetInnerHTML={{*/}
                {/*            __html: serviceDescription,*/}
                {/*        }}*/}
                {/*    ></p>*/}
                {/*)}*/}
                {service.categoryId === 1 &&
                (service.id === 976 || service.id === 157 || service.id === 142)
                    ? this.renderPhoneNumber()
                    : service.groups.length >= 2
                        ? this.renderFields(
                            true,
                            service.groups.find(group => group.id === this.state.groupId)
                                ?.fields,
                        )
                        : this.renderFields(false, serviceField)}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { language,langKey  } = state.changeLangSlice
    return {
        language, langKey
    }
}

const mapDispatchToProps = {
    setServiceIsAvailablePaymentSystem:ServiceActionCreators.setServiceIsAvailablePaymentSystem
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PaymentFields))
