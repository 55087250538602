export const addZero = (num) => {
  if (num >= 0 && num <= 9) {
    return '0' + num;
  } else {
    return num;
  }
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return day + '.' + month + '.' + year;
};

export const getTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export const getIdLang = (langValue) => (langValue === 'az' ? 'lang_az' : '');

export const getClassLang = (app, langValue) => {
  if (app === 'google') {
    return langValue === 'az' ? 'google_az' : '';
  } else {
    return langValue === 'ru' || langValue === 'en'
      ? 'appstore_en'
      : 'appstore_az';
  }
};

export const logoSrc = (storeName, langValue) => {
  const isAvailableLang =
    langValue === 'az' || langValue === 'en' || langValue === 'ru';

  const getLogo = () => {
    if (isAvailableLang) {
      return require(`../img/${storeName}_${langValue}.svg`);
    } else {
      return require(`../img/${storeName}_az.svg`);
    }
  };

  return getLogo();
};
