import {createAsyncThunk} from "@reduxjs/toolkit";
import {IBasket} from "../../models/basketList";
import {basketSliceActions} from "../slices/backetSlice";
import axios from "axios";
import { MOBILE_URL } from "../../axios";

const token = localStorage.getItem("token");

export const addToCart = createAsyncThunk('addToCart',
    async(data: any, thunkAPI: any) => {
        try {
            let response;
            if (data.isAuth) {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/upsertItemToCart/${data.token}?cartItemId=${data.сartItemId}&amount=${data.amount}&serviceId=${data.serviceId}&toPay=true&subName=${data.subName}&subValue=${data.subValue}&selectedGroup=${data.selectedGroup}&fields=${data.fields}`);
            } else {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/upsertItemToCartGuest?cartItemId=${data.сartItemId}&cartId=${data.cartId}&amount=${data.amount}&serviceId=${data.serviceId}&toPay=true&subName=${data.subName}&subValue=${data.subValue}&selectedGroup=${data.selectedGroup}&fields=${data.fields}`);
                localStorage.setItem("basketId", response.data.cartId)
            }
            if (response?.data.ResultCodes === 'ok') {
                await thunkAPI.dispatch(getCart({cartId: response?.data.cartId, isAuth: data.isAuth, token: data?.token}));
                return response.data;
            }
        }catch (e) {
            return thunkAPI.rejectWithValue('Не удалось сохранить')
        }
    }
)

export const updateCartItem = createAsyncThunk('updateCartItem',
    async(data: any, thunkAPI: any) => {
        try {
            let response;
            if (data.isAuth) {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/upsertItemToCart/${data.token}?cartItemId=${data.id}&amount=${data.amount}&serviceId=${data.serviceId}&toPay=${data.toPay}&subName=${data.subName}&subValue=${data.subValue}&selectedGroup=${data.selectedGroup}&fields=${data.fields}`);
            } else {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/upsertItemToCartGuest?cartItemId=${data.id}&cartId=${data.cartId}&amount=${data.amount}&serviceId=${data.serviceId}&toPay=${data.toPay}&subName=${data.subName}&subValue=${data.subValue}&selectedGroup=${data.selectedGroup}&fields=${data.fields}`);
            }
            return response?.data;
        }catch (e) {
            return thunkAPI.rejectWithValue('Не удалось сохранить')
        }
    }
)

export const deleteFromCart = createAsyncThunk('deleteFromCart',
    async(data: any, thunkAPI: any) => {
        try {
            let response;
            if (data.isAuth) {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/removeItemFromCart/${token}?&CartItemId=${data.cartItemId}`);
            } else {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/removeItemFromCartGuest?CartId=${data.cartId}&CartItemId=${data.cartItemId}`);
            }
            return  response?.data;
        }catch (e) {
            return thunkAPI.rejectWithValue('Не удалось сохранить')
        }
    }
)

export const getCart = createAsyncThunk('getCart',
    async(data: any, thunkAPI: any) => {
        try {
            let response;
            if (data.isAuth) {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/getCart/${data.token}`);
            }
            else {
                response = await axios.post<IBasket | any>(`${MOBILE_URL}cart/getCartGuest?cartId=${data.cartId}`);
            }
            if (response?.data.ResultCodes == 'ok') {
                thunkAPI.dispatch(basketSliceActions.setBasket(response?.data.cart));
            }
            return  response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось сохранить')
        }
    }
)