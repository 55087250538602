import {createAsyncThunk} from "@reduxjs/toolkit";
import {ThunkAction} from "redux-thunk";
import {Action} from "redux";
import {ProfileServerResponse} from "../../models/Profile";
import {IProfileDetails} from "../../models/ProfileDetails";
import {RootState} from "../store";
import client from "../../axios";
import {fetchPopularProduct} from "./popularProductAction";
import moment from "moment";
import {fetchWithTimeout, toQueryParams} from "../../utils/query";
import {IAsanLogin} from "../../models/asanLogin";
import {IUserAsanImza} from "../../models/userAsanImza";
import {getUserAvatar} from "./profileActions";
import {IAuth, IUser} from "../../models/Auth";
import {fetchDebts} from "./debtsAction";
import {fetchWalletList} from "./walletListAction";
import {baseURL} from "../../http";
import {notify} from "../../helper";
import i18n from "../../i18n";
import { cardsSliceActions } from "../slices/cardsSlice";
import { basketSliceActions } from "../slices/backetSlice";

const token = localStorage.getItem("token");

export const signOutFunc = () => ({ type: "auth/signOut" });

export const signOut = () => async (dispatch: any) => {
  dispatch(basketSliceActions.clearBasket());
  dispatch(signOutFunc())
};

export const setShowUnAuthModal = (payload: boolean) => ({
  type: "auth/setShowUnAuthModal",
  payload,
});

export const save_profile = (profile: IUser) => ({
  type: "auth/save_profile",
  payload: profile,
});

export const fetchRegister = createAsyncThunk(
  "auth/fetchRegister",
  async (
    params: { password: string; phone: string; confirmPassword: string },
    thunkAPI
  ) => {
    try {
      // const dispatch = useAppDispatch()
      const phone = encodeURIComponent(params.phone);
      //%2B994
      const response = await client({
        method: "post",
        url: `user/web_register?phone=${phone}&password=${params.password}&confirmPassword=${params.confirmPassword}`,
        data: {},
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const { data } = response;
      if (response.status === 200) {
        if (data.resultCodes === "OK") {
          thunkAPI.dispatch(fetchAuth(params));
          // dispatch()
          return data;
        } else {
          return data;
        }
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);

export const fetchRegisterWithoutConfirm = createAsyncThunk(
  "auth/fetchRegister",
  async (params: { password: string; phone: string }, thunkAPI) => {
    try {
      // const dispatch = useAppDispatch()
      const phone = encodeURIComponent(params.phone);
      //%2B994
      const response = await client({
        method: "post",
        url: `user/web_register?phone=${phone}&password=${params.password}&confirmPassword=${params.password}`,
        data: {},
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const { data } = response;
      if (response.status === 200) {
        if (data.resultCodes === "OK") {
          thunkAPI.dispatch(fetchAuth(params));
          // dispatch()
          return data;
        } else {
          thunkAPI.rejectWithValue(data.resultCodes);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchHomePageInfo = createAsyncThunk(
    "auth/fetchHomePageInfo",
    async(sid: any, thunkAPI) => {
      try {
        thunkAPI.dispatch(fetchPopularProduct({ selectedCategoryId: -1, offset: 0 }));
      } catch (e) {
        console.log('fetchInfo catch error --->', e);
      } finally {
        //some logic
      }
    }
)

export const fetchAuth = createAsyncThunk(
  "auth/fetchAuth",
  async (
    params: { password: string; phone: string; navigate?: any },
    thunkAPI
  ) => {
    try {
      const phone = encodeURIComponent(params.phone);
      const response = await client({
        method: "post",
        url: `user/web_login/${phone}?password=${params.password}`,
        data: {},
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        if (response.data.resultCodes === "OK") {
          const { sid } = response.data;
          await localStorage.setItem("token", sid);

          // await thunkAPI.dispatch(fetchProfile(sid))
          // await thunkAPI.dispatch(fetchProfileDetails(sid))
          // thunkAPI.dispatch(fetchMessages(sid));
          // thunkAPI.dispatch(fetchFavoritesList(sid));
          // thunkAPI.dispatch(fetchBonuses(sid));
          // thunkAPI.dispatch(getUserAvatar(sid));
          //
          // thunkAPI.dispatch(
          //   fetchPopularProduct({ selectedCategoryId: -1, offset: 0 })
          // );
          return response;
        } else return thunkAPI.rejectWithValue("Error");
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchForgotPassword = createAsyncThunk(
  "auth/fetchForgotPassword",
  async (params: { phone: string }, thunkAPI) => {
    // const navigate = useNavigate()
    try {
      const { phone } = params;
      const response = await client({
        method: "post",
        url: `user/forget_password/${phone}`,
        data: {},
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        if (response.data.resultCodes === "OK") {
          return { ...response, phone };
        } else if (response.data.resultCodes === "OTP_SEND_LIMIT_EXCEEDED") {
        notify(i18n.t('otpLimitExceeded'))
        }
      }
      if (response.data.description === "Invalid username or password" && response.data.resultCodes === "INVALID_USERNAME_OR_PASSWORD") {
        notify(i18n.t("userIsNotFound"), false);
      }
      // if (data.resultCodes !== 'OK') return false
    } catch (e) {
      return thunkAPI.rejectWithValue("");
    }
  }
);

export const fetchForgotPasswordPasCheckOtp = createAsyncThunk(
  "auth/fetchForgotPasswordPasCheckOtp",
  async (params: { phone: string; otp?: string }, thunkAPI) => {
    try {
      const { phone, otp } = params;
      const response = await client({
        method: "post",
        url: `user/forget_password/check_otp/${phone}?otp=${otp}`,
        data: {},
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        if (response.data.resultCodes === "OK") {
          return { ...response, phone, otp };
        }
      }
      // if (data.resultCodes !== 'OK') return false
    } catch (e) {
      return thunkAPI.rejectWithValue("");
    }
  }
);

export const fetchForgotPasswordChangePas = createAsyncThunk(
  "auth/fetchForgotPasswordChangePas",
  async (
    params: { phone: string; otp?: string; newPassword?: string },
    thunkAPI
  ) => {
    try {
      const { phone, otp, newPassword } = params;
      const response = await client({
        method: "post",
        url: `user/forget_password/change_password/${phone}?otp=${otp}&newPassword=${newPassword}`,
        data: {},
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        if (response.data.resultCodes === "OK") {
          return (response.data = { ...response.data, phone, otp });
        }
      }
      // if (data.resultCodes !== 'OK') return false
    } catch (e) {
      return thunkAPI.rejectWithValue("");
    }
  }
);

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      sid = sid || localStorage.getItem("token");
      const resProfile = await client.get<ProfileServerResponse>(
        `profile/get/` + sid
      );
      const data = resProfile.data;
      if (data.resultCodes === "INVALID_SESSION") {
        await thunkApi.dispatch(signOut());
        return null;
      }

      return data;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);


export const fetchEveryPage = createAsyncThunk(
    'auth/fetchEveryPage',
    async (token: any, thunkAPI) => {
      try {
        thunkAPI.dispatch(getUserAvatar(token));
        thunkAPI.dispatch(fetchProfile(token));
        thunkAPI.dispatch(fetchDebts());
        thunkAPI.dispatch(fetchWalletList());
        thunkAPI.dispatch(cardsSliceActions.setResetCards())
        thunkAPI.dispatch(basketSliceActions.clearBasket());
      } catch (e) {
        console.log('fetchEveryPage catch error------->', e)
      }
    }
)

export const checkToken = createAsyncThunk(
    'auth/checkToken',
    async ( _) => {
      try {
        await client.post(`user/check_session?sessionId=${token}`)

      } catch (e) {
        console.log('checkToken catch error------->', e)
      }
    }
)

export const fetchProfileDetails = createAsyncThunk(
  "profile/fetchProfileDetails",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      // debugger;
      const response = await client.get<IProfileDetails>(
        `profile/get_details/` + (sid || localStorage.getItem("token")),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);
export const fetchEditProfileDetails = createAsyncThunk(
  "profile/fetchEditProfileDetails",
  async (
    params: { birthDate: any; sid?: string; gender?: number },
    thunkApi
  ) => {
    try {
      const response = await client.post<IProfileDetails>(
        `profile/details/update?sessionId=${
          params.sid || localStorage.getItem("token")
        }&gender=${params.gender}&birthDate=${moment(params.birthDate).format(
          "DD.MM.YYYY"
        )}`
      );
      thunkApi.dispatch(fetchProfileDetails());
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

// export const fetchMessages = createAsyncThunk(
//     'messages/fetchMessages',
//     async (sid: string | undefined, thunkApi) => {
//         try {
//             const resMessages = await axios.get<IPrivateMessage>(baseUrl + `message/list/` + (sid || token))
//
//             debugger
//
//             return resMessages.data
//         } catch (e) {
//             return thunkApi.rejectWithValue("Не удалось загрузить")
//         }
//     }
// )

// export const setToken = (token: string) => {
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//     localStorage.setItem('token', JSON.stringify(token));
// }

// export const fetchAsanAuth = createAsyncThunk(
//   "auth/fetchAsanAuth",
//   async (params: { asanUserId: string; phone: string }, thunkAPI) => {
//     try {
//       const { phone, asanUserId } = params;
//       // const phone = encodeURIComponent(params.phone)
//       const { data } = await client({
//         method: "POST",
//         url: `user/asan_login/phoneNumber=%2B994${phone}?asanUserId=${asanUserId}`,
//         // url: `user/asan_login/+994${phone}?asanUserId=${asanUserId}`,
//         data: {},
//         headers: {"Content-Type": "multipart/form-data"},
//         // headers: {
//         //   'Content-Type': 'application/x-www-form-urlencoded'
//         // },
//       });
//       // if (data.resultCodes !== 'OK') return false
//       return data;
//     } catch (e) {
//       return thunkAPI.rejectWithValue("");
//     }
//   }
// );

// export const fetchAsanLoginCheck = createAsyncThunk(
//     "auth/fetchAsanLoginCheck",
//     async (_, thunkAPI) => {
//       try {
//         const {data} = await client({
//               method: "GET",
//               // url: `user/asan_login_check/transactionId=${transactionId}`,
//               data: {},
//               headers: {"Content-Type": "multipart/form-data"},
//             }
//         )
//         return data;
//
//       } catch (e) {
//         return thunkAPI.rejectWithValue("");
//       }
//     }
// );

export const PROCESS_STAGE = "PROCESS_STAGE";

interface ProcessStageAction {
  type: typeof PROCESS_STAGE;
  processStage: number;
  sid?: string;
}

export function processStageChange(processStage: number, sid?: string) {
  return {
    type: PROCESS_STAGE,
    processStage,
    sid,
  };
}

export const process_stage = (payload: {
  processStage: number;
  sid?: string;
}) => ({ type: "auth/process_stage", payload });
export const _asanLogin = (payload?: IAsanLogin) => ({
  type: "auth/asan_login",
  payload,
});

export const save_session_id = (payload?: string) => ({
  type: "auth/save_session_id",
  payload,
});
export const save_user = (payload: IAuth) => ({
  type: "auth/save_user",
  payload,
});
export const auth_user = () => ({ type: "auth/auth_user" });

export const asanLogin =
    (
        user: { phone?: string; asanImzaId?: string },
        type: "register" | "bind" | "login",
        callback?: () => void
    ): ThunkAction<Promise<boolean>, RootState, unknown, Action<string>> =>
        async (dispatch, getState) => {
          // dispatch(loadingState(true));
          console.log("asanlogin");
          try {
            const params = {
              //for bind without +994
              phoneNumber: type === "bind" ? user.phone : user.phone,
              asanUserId: user.asanImzaId,
              // sid: getState().auth.sessionId,
              sid: token,
            };
            const res = await fetchWithTimeout(baseURL + "user/asan_" + type + "/", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Payment-Origin": "web",
              },
              body: toQueryParams(params),
            });
            // const response = await client.post(
            //   "user/asan_" + type + "/",
            //   {
            //     body: toQueryParams(params),
            //   },
            //   {
            //     headers: {
            //       "Content-Type": "application/x-www-form-urlencoded",
            //     },
            //   }
            // );
            const response = await res.json();
            if (response) {
              if (response.status === "PHONE_ALREADY_EXIST") {
                // bunlarda hamisindada datani sozu yigisdirsan
                // dispatch(_asanLogin(json));
                console.log("PHONE_ALREADY_EXIST");
              } else if (response.status === "PHONE_NOT_FOUND") {
                // dispatch(_asanLogin(json));
                console.log("PHONE_ALREADY_EXIST");
              } else if (
                  response.status === "ASAN_LOGIN_FAIL" ||
                  response.status === "PIN_CODE_NOT_FOUND"
              ) {
                // dispatch(_asanLogin(json));
                console.log("PHONE_ALREADY_EXIST");
              }
              if (response.status === "NEED_CONFIRMATION") {
                dispatch(_asanLogin(response));
                await dispatch(
                    asanLoginCheck(response.asanUser.transactionId, type, callback)
                );
                return true;
              } else {
                if (type === "bind") {
                }
              }
            }
          } catch (e) {
            // dispatch(MsgToUserState(MessageType.CatchError));
          } finally {
            // dispatch(loadingState(false));
          }
          return false;
        };

// to original token by asan token
export const asanSession =
  (
    phone: string,
    asanImzaToken: string
  ): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      // const response = await fetchWithTimeout(
      //   baseURL + `user/asan_session/${phone}?sid=${asanImzaToken}`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "Web",
      //     },
      //   }
      // );
      const response = await client.post(
        `user/asan_session/${phone}?sid=${asanImzaToken}`,
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data?.resultCodes === "OK") {
        dispatch(save_user(response.data));
        dispatch(auth_user());
      }
    } catch (e) {
      console.log("errorAsanSession", e);
    }
  };
export const registerDevice =
  (
    user: IUserAsanImza
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const params: any = {
        otp: user.code,
        token,
        pinCode: user.securityCode,
      };
      // const response = await fetchWithTimeout(
      //   baseURL +
      //     (token ? "/user/register_device_asan/" : "/user/register_device/") +
      //     user.phone,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     },
      //     body: toQueryParams(params),
      //   }
      // );
      const response = await client.post(
        (token ? "user/register_device_asan/" : "user/register_device/") +
          user.phone,
        {
          body: toQueryParams(params),
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data?.resultCodes === "OK") {
        await dispatch(fetchProfileDetails());
        await dispatch(save_session_id(response.data?.sid));
        // digital cards
        // await dispatch(getUserCard());
        // dispatch(getCardInfo());
        dispatch(save_profile(response.data?.user));

        dispatch(processStageChange(3));
        //TODO refactor this and create a new thunk for this
        // await afterSignIn2(json.sid, json.user, getState, dispatch);

        const token = await localStorage.getItem("token");
        if (token) {
          dispatch(save_session_id(token));
          localStorage.setItem("token", token);
        }
      } else if (
        response.data?.resultCodes === "LOGIN_FAILED" ||
        response.data?.resultCodes === "INVALID_NUMBER"
      ) {
        // dispatch(MsgToUserState(2));
        console.log("errorRegisterDevice", response.data);
      } else {
        console.log("errorRegisterDevice", response.data);
        // dispatch(MsgToUserState(1));
      }
    } catch (e) {
      console.log(e, "errorRegisterDevice");
      // dispatch(MsgToUserState(MessageType.CatchError));
    } finally {
      // dispatch(loadingState(false));
    }
  };

export const asanLoginCheck =
  (
    transactionId: string,
    type: "register" | "bind" | "login",
    callback?: () => void
  ): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      // const asanUser = getState().authAsanReducer?.asanLogin?.asanUser;
      const asanUser = getState().authReducer?.asanUser;
      if (asanUser?.asanUser?.transactionId !== transactionId) {
        return;
      }
      console.log("json iddd", asanUser);
      // const response = await fetchWithTimeout(
      //   baseURL +
      //     "user/asan_" +
      //     type +
      //     "_check/?transactionId=" +
      //     transactionId,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "web",
      //     },
      //   }
      // );
      const response = await client.get(
        "user/asan_" + type + "_check/?transactionId=" + transactionId,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log("responseAsanLoginCheck", response);

      if (response && response.data?.status === "TRANSACTION_NOT_FOUND") {
        dispatch(_asanLogin());
        callback && callback();
        return;
      } else if (response.data && response.data?.status !== "SUCCESS") {
        setTimeout(
          () => dispatch(asanLoginCheck(transactionId, type, callback)),
          5000
        );
        return;
      } else {
        console.log("ASAN_CHECK_SUCCESSFULL", response.data);
        if (type !== "bind") {
          const sid = response.data?.user.sessionId;
          dispatch(asanSession(asanUser?.asanUser.phone, sid));
        } else {
          // reload profile
          const profile = getState().authReducer.profile;
          if (profile) {
            // dispatch(saveProfile({ ...profile, pincode: '*******' }));
            // dispatch(fetchProfile());
          }
        }
      }
    } catch (e) {
      console.warn("errorAsanLoginCheck", e);
      // dispatch(MsgToUserState(MessageType.CatchError));
    }
    dispatch(_asanLogin());
    callback && callback();
  };

export type AuthActionTypes = ProcessStageAction;
