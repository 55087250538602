import React from "react"
import ContentLoader from "react-content-loader"

const BonusCatalogueSkeleton = () => (
    <ContentLoader
        speed={2}
        width={270}
        height={380}
        viewBox="0 0 270 380"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="5" y="17" rx="0" ry="0" width="5" height="354" />
        <rect x="10" y="17" rx="0" ry="0" width="235" height="5" />
        <rect x="244" y="17" rx="0" ry="0" width="5" height="354" />
        <rect x="10" y="366" rx="0" ry="0" width="235" height="5" />
        <rect x="15" y="30" rx="0" ry="0" width="222" height="225" />
        <rect x="37" y="266" rx="0" ry="0" width="188" height="34" />
        <rect x="83" y="309" rx="0" ry="0" width="91" height="32" />
    </ContentLoader>
)

export default BonusCatalogueSkeleton