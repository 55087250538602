import React from "react";

import {NavLink, useNavigate} from "react-router-dom";
import AsideSettings from "../../AsideSettings/AsideSettings";
import NavigationComponent from "../../../CustomsComponents/NavigationComponent";
import "./Security.scss";
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../../layouts/LayoutEmpty/LayoutEmpty";
import { useAppSelector } from "../../../../hooks/redux";
//@ts-ignore
import { Helmet } from 'react-helmet';

const Security = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const goBack = () => navigate(`/epay/${language}/settings`)

  return (
      <LayoutEmpty goBack={goBack} navTitle={t("settings")}>
          <div className="security">
            <Helmet>
                <title>{t('securityTitle')}</title>
                <meta name="description" content={t('securityDescription') || ''}/>
                <meta name="keywords" content={t('securityKeywords') || ''}/>
            </Helmet>
              <div className="security-body">
                  {/*<NavigationComponent onClick={goBack} title={t("settings")}/>*/}

                  <h2 className="security-body__title">{t("security")}</h2>

                  <ul className="security__content">
                      {/*<li className="security__content-item">*/}
                      {/*  <NavLink className="menu__item" to={"/change-safety-code"}>*/}
                      {/*    {t("changeSecurityCode")}*/}
                      {/*  </NavLink>*/}
                      {/*</li>*/}
                      {/*<div className="border"></div>*/}
                      <li className="security__content-item">
                          <NavLink className="menu__item" to={`/epay/${language}/change-password`}>
                              {t("changeProfilePassword")}
                          </NavLink>
                      </li>
                      {/*<div className="border"></div>*/}
                      {/*<li className="security__content-item">*/}
                      {/*  <NavLink className="menu__item" to={"/check-cvv"}>*/}
                      {/*    {t("CVVCheck")}{" "}*/}
                      {/*  </NavLink>*/}
                      {/*</li>*/}
                      <div className="border"></div>
                  </ul>
              </div>
              <div className="security-right">
                  <AsideSettings/>
              </div>
          </div>
      </LayoutEmpty>
  );
};

export default Security;
