import React, {FC} from "react";
import './index.scss';
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import {useTranslation} from "react-i18next";

interface IProps {
    message?: string;
    isModalOpen: boolean;
    setIsModalOpen: (value: (val: boolean) => boolean) => void;
}

const RecurringWarningModal: FC<IProps> = (props) => {
    const { isModalOpen, setIsModalOpen, message } = props;
    const {t} = useTranslation();

    return(
        <CustomModal active={isModalOpen} setActive={setIsModalOpen}>
            <div className="recurring-modal__content">
                <div className="recurring-modal__message">
                    {message}
                </div>
                <div
                    onClick={() => setIsModalOpen((val: boolean) => !val)}
                    className="recurring-modal__btn">
                    OK
                </div>
            </div>
        </CustomModal>
    )
}

export default RecurringWarningModal;