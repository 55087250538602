import React from 'react';
import "./index.scss"

const Loading = () => {
    return (
        <div className="loading">
            <div className="lds-roller lds-roller-orange">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};


export const LoadingLocal = () => {
    return (
        <div className="loading-local">
            <div className="lds-roller lds-roller-orange">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export const LoadingLocalWhite = () => {
    return (
        <div className="loading-local">
            <div className="lds-roller lds-roller-white">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};


export default Loading;