import React from "react";

import {NavLink, useNavigate} from "react-router-dom";
import AsideSettings from "../../AsideSettings/AsideSettings";
import Button from "../../../Button/Button";
import NavigationComponent from "../../../CustomsComponents/NavigationComponent";
import "./SafetyCode.scss";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "../../../../hooks/redux";

const SafetyCode = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const goBack = () => navigate(`/epay/${language}/settings`)
  return (
    <div className="safety-code">
      <div className="safety-code-body">
        <NavigationComponent onClick={goBack} title={t("settings")} />

        <h2 className="safety-code-body__title">{t("changeProfilePassword")}</h2>

        <form className="safety-code__form" action="">
          <input
            className="safety-code__form-input"
            placeholder={`${t("oldCode")}`}
            type="text"
          />
          <input
            className="safety-code__form-input"
            placeholder={`${t("newCode")}`}
            type="text"
          />
        </form>

        <div className="safety-code__buttons">
          <Button
            buttonText={`${t("save")}`}
            color="white"
            background="#EF5C27"
            border="1px solid #fff"
            padding="14px 25px 14px 25px"
            borderRadius={15}
            margin="0 10px 0 0"
          />
        </div>
      </div>

      <AsideSettings />
    </div>
  );
};

export default SafetyCode;
