import * as React from "react";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import SideMenu from "../SideMenu/SideMenu";
import "./Office.scss";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import { useAppSelector } from "../../../hooks/redux";

const Office = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isMapOpen, setIsMapOpen] = useState<boolean>(false);
    const { language } = useAppSelector((state) => state.changeLangSlice);

    const toggleMap = () => {
        setIsMapOpen(!isMapOpen);
    }

    const goBack = () => navigate(`/epay/${language}/identification`)
    return (
        <>
            <LayoutEmpty navTitle={`${t("goThrough")}`} goBack={goBack}>
                <div className="wallet-office__content">
                    <div className="wallet-office__content-text">
                        <div className="wallet-office__content-header">
                            {t("comeOffice")}
                        </div>
                        <div className="wallet-office__content-body">
                            <div className="wallet-office__docs">
                                <div className="wallet-office__docs-title">
                                    {t("bringCopy")}
                                </div>
                                <div className="wallet-office__docs-box">
                                    <div className="wallet-office__docs-icon">
                                        <img className="wallet-office__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />
                                    </div>
                                    <div className="wallet-office__docs-text">
                                        {t("passport")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wallet-office__office-info">
                            <div className="wallet-office__info-title">{t("phone")}</div>
                            <div className="wallet-office__info-text">
                                <a className="wallet-office__info-link" href="tel:+994105051919" >+994 10 505 19 19 </a>
                            </div>
                        </div>
                        <div className="wallet-office__office-info">
                            <div className="wallet-office__info-title">{t("scheduleWork")}</div>
                            <div className="wallet-office__info-text">
                                {t('workingTime')}
                            </div>
                        </div>
                        <div className="wallet-office__office-info">
                            <div className="wallet-office__info-title">{t("officeAddress")}</div>
                            <div className="wallet-office__info-text">
                                “PaySis” LLC , CityPoint Business Center AZ1022,Azerbaijan,Baku Jalil Mammadguluzadeh, 102A
                            </div>
                            <div className="wallet-office__map">
                          <span
                              className="wallet-office__map-text"
                              onClick={() => toggleMap()}
                          >
                              {isMapOpen ? `${t("closeMap")}` : `${t("showMap")}`}
                          </span>
                                {
                                    isMapOpen &&
                                    <div className="wallet-office__map-content">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7021.7897777018425!2d49.825618448585594!3d40.39094792051734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d82b48f0477%3A0x15d1ff0012c91ace!2zMTAyYSBDyZlsaWwgTcmZbW3JmWRxdWx1emFkyZkga8O8w6fJmXNpLCBCYWvEsSAxMDIyLCDQkNC30LXRgNCx0LDQudC00LbQsNC9!5e0!3m2!1sru!2s!4v1670922901443!5m2!1sru!2s"
                                            width="100%" height="400" style={{border:0}} allowFullScreen={true} loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                }
                            </div>

                        </div>

                    </div>
                    <div className="wallet-office__content-menu">
                        <SideMenu />
                    </div>
                </div>
            </LayoutEmpty>
            {/*<div className="wallet-office">*/}
            {/*    <NavigationComponent title={`${t("goThrough")}`} onClick={goBack}/>*/}
            {/*    <div className="wallet-office__content">*/}
            {/*        <div className="wallet-office__content-text">*/}
            {/*            <div className="wallet-office__content-header">*/}
            {/*                {t("comeOffice")}*/}
            {/*            </div>*/}
            {/*            <div className="wallet-office__content-body">*/}
            {/*                <div className="wallet-office__docs">*/}
            {/*                    <div className="wallet-office__docs-title">*/}
            {/*                        {t("bringCopy")}*/}
            {/*                    </div>*/}
            {/*                    <div className="wallet-office__docs-box">*/}
            {/*                        <div className="wallet-office__docs-icon">*/}
            {/*                            <img className="wallet-office__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />*/}
            {/*                        </div>*/}
            {/*                        <div className="wallet-office__docs-text">*/}
            {/*                            {t("passport")}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="wallet-office__docs-box">*/}
            {/*                        <div className="wallet-office__docs-icon">*/}
            {/*                            <img className="wallet-office__img" src={require('../../../img/dots-icon.svg').default} alt="icon" />*/}
            {/*                        </div>*/}
            {/*                        <div className="wallet-office__docs-text">*/}
            {/*                            ИНН*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="wallet-office__office-info">*/}
            {/*                <div className="wallet-office__info-title">{t("phone")}</div>*/}
            {/*                <div className="wallet-office__info-text">*/}
            {/*                    <a className="wallet-office__info-link" href="tel:+994123105363" >(+994 12) 310 53 63 </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="wallet-office__office-info">*/}
            {/*                <div className="wallet-office__info-title">{t("scheduleWork")}</div>*/}
            {/*                <div className="wallet-office__info-text">*/}
            {/*                    Пн-Пт: с 09:00 до 18:00*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="wallet-office__office-info">*/}
            {/*                <div className="wallet-office__info-title">{t("officeAddress")}</div>*/}
            {/*                <div className="wallet-office__info-text">*/}
            {/*                    “PaySis” LLC , CityPoint Business Center AZ1022,Azerbaijan,Baku Jalil Mammadguluzadeh, 102A*/}
            {/*                </div>*/}
            {/*                <div className="wallet-office__map">*/}
            {/*              <span*/}
            {/*                  className="wallet-office__map-text"*/}
            {/*                  onClick={() => toggleMap()}*/}
            {/*              >*/}
            {/*                  {isMapOpen ? `${t("closeMap")}` : `${t("showMap")}`}*/}
            {/*              </span>*/}
            {/*                    {*/}
            {/*                        isMapOpen &&*/}
            {/*                        <div className="wallet-office__map-content">*/}
            {/*                            <iframe*/}
            {/*                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7021.7897777018425!2d49.825618448585594!3d40.39094792051734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d82b48f0477%3A0x15d1ff0012c91ace!2zMTAyYSBDyZlsaWwgTcmZbW3JmWRxdWx1emFkyZkga8O8w6fJmXNpLCBCYWvEsSAxMDIyLCDQkNC30LXRgNCx0LDQudC00LbQsNC9!5e0!3m2!1sru!2s!4v1670922901443!5m2!1sru!2s"*/}
            {/*                                width="753px" height="400" style={{border:0}} allowFullScreen={true} loading="lazy"*/}
            {/*                                referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
            {/*                        </div>*/}
            {/*                    }*/}
            {/*                </div>*/}

            {/*            </div>*/}

            {/*        </div>*/}
            {/*        <div className="wallet-office__content-menu">*/}
            {/*            <SideMenu />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>

      )
};

export default Office;
