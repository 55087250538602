import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createWallet, getC2qrService, getC2wService, getSimaQrCode, getQrCode, getInvoice, getW2wService} from "../actions/walletAction";
import {IWallet} from "../../models/Wallet"
import { Service } from "../../models/Categories";

interface InitialState {
    sid: string,
    error: string,
    wallet: IWallet | null,
    c2w?: Service;
    w2w?: Service;
    c2qr?: Service;
    isLoading: boolean
}

const initialState: InitialState = {
    sid: "",
    error: "",
    wallet: null,
    isLoading: false
}

export const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {},
    extraReducers: {
        [createWallet.pending.type]: (state) => {
            state.isLoading = true
        },
        [createWallet.fulfilled.type]: (state, action: PayloadAction<IWallet>) => {
            state.isLoading = false
            state.error = ""
            state.wallet = action.payload
        },
        [createWallet.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [getSimaQrCode.pending.type]: (state) => {
            state.isLoading = true
        },
        [getSimaQrCode.fulfilled.type]: (state, action: PayloadAction<IWallet>) => {
            state.isLoading = false
            state.error = ""
        },
        [getSimaQrCode.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [getQrCode.pending.type]: (state) => {
            state.isLoading = true
        },
        [getQrCode.fulfilled.type]: (state, action: PayloadAction<IWallet>) => {
            state.isLoading = false
            state.error = ""
        },
        [getQrCode.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [getInvoice.pending.type]: (state) => {
            state.isLoading = true
        },
        [getInvoice.fulfilled.type]: (state, action: PayloadAction<IWallet>) => {
            state.isLoading = false
            state.error = ""
        },
        [getInvoice.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [getC2qrService.fulfilled.type]: (state, action) => {
            state.c2qr = action.payload
        },
        [getC2wService.fulfilled.type]: (state, action) => {
            state.c2w = action.payload
        },
        [getW2wService.fulfilled.type]: (state, action) => {
            state.w2w = action.payload
        },
    }
})

export default walletSlice.reducer