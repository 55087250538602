const { default: dotsImage } = require('../img/dots-icon.svg');

export const infoModal = [
  {
    title: 'Удобно',
    text: 'Мгновенные переводы, пополнения и оплаты более 100 услуг.',
  },
  {
    title: 'Надежно',
    text: 'Прямой прием платежей через центр «Bank of Baku»',
  },
  {
    title: 'Безопасно',
    text: 'Сертификаты Thawte SSL, Verified by Visa и MasterCard SecureCode',
  },
];

export const replenishmentOfMobile = [
  {
    img: require('../img/categories/mobile-icon.svg').default,
    titleStreet: '(+994) 050 111 22 33',
    titleService: 'Пополнение мобильного',
    price: '30',
    currency: 'azn',
    backGround: '#FFEAE9',
  },
  {
    img: require('../img/categories/mobile-icon.svg').default,
    titleStreet: '(+994) 050 111 22 33',
    titleService: 'Пополнение мобильного',
    price: '30',
    currency: 'azn',
    backGround: '#FFEAE9',
  },
  {
    img: require('../img/categories/mobile-icon.svg').default,
    titleStreet: '(+994) 050 111 22 33',
    titleService: 'Пополнение мобильного',
    price: '30',
    currency: 'azn',
    backGround: '#FFEAE9',
  },
  {
    img: require('../img/categories/mobile-icon.svg').default,
    titleStreet: '(+994) 050 111 22 33',
    titleService: 'Пополнение мобильного',
    price: '30',
    currency: 'azn',
    backGround: '#FFEAE9',
  },
  {
    img: require('../img/categories/mobile-icon.svg').default,
    titleStreet: '(+994) 050 111 22 33',
    titleService: 'Пополнение мобильного',
    price: '30',
    currency: 'azn',
    backGround: '#FFEAE9',
  },
];

export const virtualCard = [
  {
    cardName: 'Моя карта 1',
    cardIcon: require('../img/visa-icon.svg').default,
    cardNumber: '2335 •••• •••• 4533',
    cardPrice: '735 AZN',
  },
];

export const myVirtualCard = [
  {
    img: dotsImage,
    title:
      'Делайте безопасные онлайн-платежи без регистрации своей основной карты в различных интернет-магазинах и сайтах оплаты',
  },
  {
    img: dotsImage,
    title:
      'Используя функцию “Cash by Code”, обналичивайте сумму, имеющуюся на балансе карты через терминалы Bank of Baku',
  },
  {
    img: dotsImage,
    title:
      'Пополняйте баланс виртуальной карты при помощи других карт или E-Кошелка',
  },
  {
    img: dotsImage,
    title: 'Оплачивайте через Виртуальную Карту EPUL и получайте бонусы',
  },
];
