import React from 'react';
import '../index.scss'

const AdvantDebts = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Borcların ödənilməsi"}</div>
                <div
                    className="about__right-text">{"E-Pul.az portalında borclarınıza nəzarət edə bilərsiniz!"}
                </div>
                <div className="about__right-text">
                    {'Borcları öyrənmək və ödəmək üçün lazım olan ödənişi seçilənlərə əlavə edin. Bütün borclarınız müvafiq bölmədə görünəcək və vaxt itirmədən onları rahatlıqla ödəyə biləcəksiniz.'}
                </div>
                <div className="about__right-text">
                    <a target="_blank" href="/" style={{fontSize: "24px", color: "#F78C40"}}>www.EPUL.az</a>
                </div>
            </div>
        </div>
    );
};

export default AdvantDebts;