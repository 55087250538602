import {ICurrencyRate, IWalletList} from "../../models/WalletList";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchWalletList} from "../actions/walletListAction";
import { Service } from "../../models/Categories";

interface InitialState {
    sid: string,
    error: string,
    walletList: IWalletList | null,
    isLoading: boolean,
    azn: string | undefined,
    usd: string | undefined,
    currencyRates: ICurrencyRate[] | undefined,
    formUrl: string | undefined,
    orderId: string | undefined,
    defaultMasterPassPan: string | undefined,
}

const initialState: InitialState = {
    sid: "",
    error: "",
    walletList: null,
    isLoading: false,
    azn: undefined,
    usd: undefined,
    currencyRates: undefined,
    formUrl: undefined,
    orderId: undefined,
    defaultMasterPassPan: undefined,
}

export const walletListSlice = createSlice({
    name: "walletList",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchWalletList.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchWalletList.fulfilled.type]: (state, action: PayloadAction<IWalletList>) => {
            state.isLoading = false
            state.error = ""
            state.walletList = action.payload
        },
        [fetchWalletList.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
    }
})

export default walletListSlice.reducer