import { useEffect } from "react";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { setShowUnAuthModal, signOut } from "../redux/actions/authActions";
import { useNavigate } from "react-router-dom";

export const BASE_URL = process.env.REACT_APP_CLIENT_URL;
export const MOBILE_URL = process.env.REACT_APP_MOBILE_URL;
export const C2C_URL = process.env.REACT_APP_C2C;
export const ADMIN_BACK_PUBLIC = process.env.REACT_APP_ADMIN_BACK_PUBLIC;

const client = axios.create({
  // withCredentials: true,
  baseURL: BASE_URL,
});

client.interceptors.request.use((config: any) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers["Payment-Origin"] = "web";
  config.headers["Content-Type"] = "application/json";
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  // config.headers("Access-Control-Allow-Origin", "*");
  return config;
});

export const useAxiosInterceptor = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token')
  useEffect(() => {
    client.interceptors.request.use((config: any) => {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Payment-Origin"] = "web";
      config.headers["Content-Type"] = "application/json";
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      // config.headers("Access-Control-Allow-Origin", "*");
      return config;
    });
    client.interceptors.response.use((response) => {
      if (response?.data?.resultCodes === "INVALID_SESSION") {
        dispatch(signOut());
        dispatch(setShowUnAuthModal(true));
        return Promise.reject("Unauthorized");
      }
      else {
        return response;
      }

      // if (response?.data?.resultCodes === "INVALID_SESSION") {
      //   dispatch(signOut());
      //   dispatch(setShowUnAuthModal(true));
      //   return Promise.reject("Unauthorized");
      // } else {
      //   return response;
      // }
    });
  }, [dispatch]);
};
export default client;