import LoadingActionTypes from './types';

const initialState = {
  isLoading: false,
  isLoadingNext: false,
  isPaymentLoading: false,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoadingActionTypes.LOADING: {
      return { ...state, isLoading: action.isLoading };
    }
    case LoadingActionTypes.LOADING_NEXT: {
      return { ...state, isLoadingNext: action.isLoading };
    }
    case LoadingActionTypes.PAYMENT_LOADING: {
      console.log(' LoadingActionTypes.PAYMENT_LOADING:', action.isLoading);
      return { ...state, isPaymentLoading: action.isPaymentLoading };
    }

    default:
      return state;
  }
};

export default loadingReducer;
