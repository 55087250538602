import React, {useEffect} from "react";
import { useAppSelector } from "../../hooks/redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./index.scss";
import RegistrationModal from "../../Components/RegistrationModal/RegistrationModal";
import HeaderCustom from "../../Components/Header/HeaderCustom";
import {HeadBanner} from "../../Components/App/HeadBanner";
const loading = require("../../img/loading.mp4");
const defaultAvatar = require("../../img/authIcon.svg").default;
const setting = require("../../img/setting.svg").default;

const MorePage = () => {
  const { t } = useTranslation();
  const { profile, isLoading } = useAppSelector((state) => state.authReducer);
  const { language } = useAppSelector(state => state.changeLangSlice)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="more-details">
      <HeaderCustom/>
      <div className="more-details__header">
        {/*<p className="more-details__title">Система онлайн оплат</p>*/}
        <ProfilePhotoUploader />
        <div>
          <p className="more-details__name">
            {(profile?.user.firstName && `${profile?.user.firstName}`) || ""}
          </p>
          <div className="header__auth-setting">
            <Link to={`/epay/${language}/settings`} className="header__auth-setting__title">
            <img style={{marginRight: '2px'}} src={setting} alt="Setting" />
            <span>{t("settings")}</span>
            </Link>
          </div>
        </div>
      </div>
        <HeadBanner />
      <div className="more-details__content">
        <RegistrationModal />
      </div>
    </div>
  );
};

const ProfilePhotoUploader = () => {
  const { userAvatarSource, isLoading } = useAppSelector(
    (state) => state.authReducer
  );
  const userAva = userAvatarSource || defaultAvatar;
  return (
    <div className="settings__avatar-box">
      <div className="settings__image">
        {isLoading ? <video className="settings__img" autoPlay={true} loop={true}>
          <source src={loading} type="video/mp4"/>
        </video> : <img
            className="settings__img"
            src={userAva}
            alt="settings"
        />}

      </div>
    </div>
  );
};

export default MorePage;
