import React from 'react';
import {useAppDispatch} from "../../hooks/redux";
import {useForm} from "react-hook-form";
import {fetchForgotPassword} from "../../redux/actions/authActions";
import {Box, Dialog, InputAdornment, TextField, Typography} from "@mui/material";
import BootstrapDialogTitle from "../BootstrapDialogTitle";
import Button from "../Button/Button";

interface ForgotPasswordMobileCodeProps {
    showForgotPasswordMobileCode: boolean,
    handleClosePasswordMobileCode: () => void
}

const ForgotPasswordMobileCode = ({
                                      showForgotPasswordMobileCode,
                                      handleClosePasswordMobileCode
                                  }: ForgotPasswordMobileCodeProps) => {
    const dispatch = useAppDispatch()
    const {register, handleSubmit, setError, formState: {errors, isValid}} = useForm({
        defaultValues: {
            phone: '',
        },
        mode: 'onChange'
    })

    const onSubmit = (values: { phone: string }) => {
        dispatch(fetchForgotPassword(values))
    }
    return (
        <Dialog
            open={showForgotPasswordMobileCode}
            onClose={handleClosePasswordMobileCode}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
         123
        </Dialog>
    );
};

export default ForgotPasswordMobileCode;