import React from "react";
import i18next, {t} from "i18next";
import moment from "moment";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import ReactToPrint from "react-to-print";
import "react-toastify/dist/ReactToastify.css";

import client from "../../axios";
import withRouter from "../../HOC/widthRouter";
import {getQueryParams} from "../../utils/query";
import Button from "../../Components/Button/Button";
import errorPayment from "../../img/error-payment.svg";
import Loading from "../../Components/Loading/Loading";
import {default as message} from "../../img/message.svg";
import successPayment from "../../img/success-payment.svg";
import {default as closeIcon} from "../../img/close-icon.svg";
import {MessageType} from "../../redux/reducers/msgToUser/types";
import {Languages} from "../../redux/reducers/language/languageData";
import {fetchHistoryReceipt} from "../../redux/actions/bonusesAction";
import OtherPayments from "../../Components/OtherPayments/OtherPayments";
import CustomModal from "../../Components/ModalComponent/CustomModal/CustomModal";
import {MsgToUserActionCreators} from "../../redux/reducers/msgToUser/action-creators";
import NavigationComponent from "../../Components/CustomsComponents/NavigationComponent";
import OtherPaymentSwiper from "../../Components/OtherPayments/OtherPaymentSwiper";
import './index.scss'
import language from "../../redux/reducers/language";
import { ShowCheckInfo } from "./ShowCheckInfo";


const selectedLanguage = "az";

class Check extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.checkEl = React.createRef();
        this.state = {
            isModalVisible: false,
            isLoading: false,
            inputMail: "",
            savedCheckData: undefined,
            localName: "",
            historyField: [],
            isCorrectEmail: true,
            msgType: 0,
            inputPhone: "",
            isCorrectPhone: "false",
            isModalOpen: false,
            service: null,
            commission: null,
        };
        // this.getUserMail();

        this.setModalActive = this.setModalActive.bind(this);
        // this.goBack = this.goBack.bind(this)
    }

    async componentDidMount() {
        await this.getCheckData();
        // await this.getPromoData()
    };

    getUserMail = () => {
        const {profile} = this.props;
        this.setState({inputMail: profile?.profile?.user.email})

    }

    async calculateCommission(serviceId, amountValue) {
        try {
            const res = await client.post(`digest/guestListPaymentServicesSelected/${serviceId}`, {
                selected: [serviceId && +serviceId]
            })
            if (res.status === 200) {
                const {minimalComission, percent} = res.data?.list?.service[0] || {};
                if (res.data.list.service.length === 0) {
                    return 0
                } else if (percent === 0) {
                    console.log('minimalComission', minimalComission)
                    return minimalComission
                } else {
                    const commission = Math.round(Number(amountValue) * (percent / 10000)) / 100
                    if (commission < minimalComission) {
                        return minimalComission
                    } else return commission
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    notify = (value) => {
        const {language} = this.props.language;
        const languageHash = Languages(language);

        let message;
        switch (value) {
            case 1:
                message = languageHash.sendCheckMsgSuccess;
                break;
            case 2:
                message = languageHash.sendCheckLimitError;
                break;
            case 3:
                message = languageHash.sendSmsMsgSuccess;
                break;
            case 4:
                message = languageHash.smsLimitError;
                break;
            default:
                message = languageHash.someError;
                break;
        }

        toast(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    };

    async getCheckData() {
        const {orderId, historyId} = getQueryParams();
        let response;

        this.setState({isLoading: true});
        try {
            await client.get(`guest/rurl_payment_guest?orderId=${orderId}`);
            const sessionId = localStorage.getItem("token");
            if (orderId == 'wallet' || sessionId) {
                response = await client.get(`history/receipt2/${orderId}/${historyId}?sessionId=${sessionId}`);
            } else {
                response = await client.get(`guest/receipt2?orderId=${orderId}`);
            }
            if (
                response.data?.resultCodes === "OK" ||
                response.data?.resultCodes === "REVERSED" ||
                response.data?.resultCodes === "REVERSAL"
            ) {
                if (response.data?.resultCodes === "REVERSAL") {
                    response.data.item = {...response.data?.item, status: 20};
                }
                this.setState({
                    savedCheckData: response.data?.item,
                    localName: response.data?.item.localName["az"],
                });
                const commission = await this.calculateCommission(response?.data?.item?.serviceId, response?.data?.item?.amount)
                this.setState({commission: commission})

                this.saveMainField(response.data?.item.fields.historyField);
                const {categories} = this.props;
                const categoryName = categories?.categories?.find((category) => category?.id === response.data?.item?.categoryId)?.name;
                const data = {
                    event: "purchase",
                    ecommerce: {
                        transaction_id: response.data?.item.orderId,
                        value: ((response.data?.item.amount / 100) + commission).toFixed(2),
                        currency: response.data?.item.currency,
                        items: [{
                            item_name: response.data?.item?.localName.valueEn,
                            item_id: response.data?.item.id,
                            price: (response.data?.item.amount / 100).toFixed(2),
                            affiliation: response.data?.item.asseblyId,
                            item_category: categoryName,
                            quantity: 1
                        }]
                    }
                }
                console.log(data)
                window.dataLayer.push({ecommerce: null});
                window.dataLayer.push(data);

            }
        } catch (e) {
            console.warn("error", e);
            this.props.MsgToUserState(MessageType.CatchError);
        } finally {
            this.setState({isLoading: false});
        }
    }

    async getPromoData() {
        const {orderId} = getQueryParams();

        this.setState({isLoading: true});
        try {
            const response = await client.get(
                `guest/receipt/promocode?orderId=${orderId}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );

            if (response.data?.history.resultCodes === "OK") {
                this.setState({promocode: response.data?.promocode});
            }
        } catch (e) {
            console.warn("error", e);
            this.props.MsgToUserState(MessageType.CatchError);
        } finally {
            this.setState({isLoading: false});
        }
    }

    async sendCheckToMail() {
        this.setState({isLoading: true});
        this.setModalActive(false);
        const {orderId, historyId} = getQueryParams();

        try {
            const response = await client.post(
                `history/receipt2/send?orderId=${orderId}&historyId=${historyId}&email=${this.state.inputMail}&sendType=email&sessionId=${localStorage.getItem("token")}&afterPayment=true`
            );
            if (response.status === 200) {
                if (response.data?.resultCodes === "OK") {
                    toast(t('checkToEmail'), {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else if (response.data?.resultCodes === "EMAIL_SEND_LIMIT_EXCEEDED") {
                    this.notify(2);
                }
            } else {
                console.warn("error");
                this.notify(0);
            }
        } catch (e) {
            console.warn("error", e);
            this.notify(0);
        } finally {
            this.setState({isLoading: false});
        }
    }

    async sendLinkToSms() {
        const {inputPhone, isCorrectPhone} = this.state;
        if (!isCorrectPhone) {
            return;
        }

        this.setState({isLoading: true});

        try {
            const response = await client.post(
                `guest/send-sms-with-link?phoneNumber=${inputPhone}`
            );
            if (response.status === 200) {
                if (response.data?.resultCodes === "SMS_SEND") {
                    this.setState({msgType: 3});
                } else {
                    this.setState({msgType: 4});
                }
            } else {
                console.warn("error");
                this.setState({msgType: 0});
            }
        } catch (e) {
            console.warn("error", e);
            this.setState({msgType: 0});
        } finally {
            this.setState({isLoading: false});
        }
    }

    saveMainField(historyField) {
        let fields = [];
        for (const i in historyField) {
            const item = historyField[i];
            let field = {enumFieldValue: {}, localName: {}, value: ""};
            field.enumFieldValue = item.enumFieldValue;
            field.localName = item.localName;
            field.value = item.value;
            fields.push(field);
        }
        this.setState({historyField: fields});
    }

    renderErrorMsgEmail(language) {
        if (!this.state.isCorrectEmail) {
            if (this.state.inputMail.length === 0) {
                return language.requiredError;
            }
            return language.emptyError;
        }
    }

    renderErrorMsgPhone(language) {
        if (!this.state.isCorrectPhone) {
            if (this.state.inputPhone?.length === 0) {
                return language.requiredError;
            }
            return language.emptyError;
        }
    }

    historyFieldsValues() {
        // const { history, isPayHistory } = this.props.route?.params;
        const {history, isPayHistory} = getQueryParams();
        const {langKey} = this.props.language;

        let {savedCheckData, historyField} = this.state;
        let name = "";
        let value = "";


        if (isPayHistory && history) {
            historyField.map((item) => {
                name = item.localName[langKey];
                if (item.value === "A") {
                    value = item.enumFieldValue[langKey];
                }
                    // else if (history.categoryId === 1 && history.serviceId != 245) {
                    //     value = value + item.value
                // }
                else {
                    value = item.value;
                }
            });

            //value = history?.categoryId === 1  && history?.serviceId != 245 ? '+994' + value : value;
        } else if ((!isPayHistory || !JSON.parse(isPayHistory)) && savedCheckData) {
            historyField.forEach((item) => {
                name = item.localName[langKey];
                if (item.value === "A") {
                    value = item.enumFieldValue[langKey];
                }
                else {
                    value = item.value;
                }
            });

        }


        const showRecipient = () => {
            if (langKey === 'valueAz') {
                return 'Alıcı Pulqabı'
            } else if (langKey === 'valueRu') {
                return 'Кошелек получателя'
            } else if (langKey === 'valueEn') {
                return name
            }
        }
        return (
            <div className="check_data-item">
                <div
                    className="check_data-item-title check-data-content check_data-item-title--title">{name === "Recipient Wallet" ? showRecipient() : name}</div>
                <div className="check_data-item-title check-data-content">{value}</div>
            </div>
        );
    }

    setModalActive(value) {
        this.setState({
            isModalOpen: value,
            inputMail: this.props.profile?.profile?.user.email,
            isCorrectEmail: true,
        })
    }

    onPrintClick() {
        if (this.checkEl.current) {
            this.checkEl.current.style.display = "block";
        }
    };

    getInputValue = (e) => {
        const regEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

        if (!e.target.value.trim() || !regEmail.test(e.target.value)) {
            this.setState({isCorrectEmail: false})
        } else {
            this.setState({isCorrectEmail: true})
        }
        this.setState({inputMail: e.target.value})
    }

    render() {
        const routess = this.props.route;
        const {match, language: {langKey, language}} = this.props;
        let {savedCheckData, promocode, commission} = this.state;
        const regEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const regPhone = /^\+[1-9][0-9]*$/;
        const isAuthToken = Boolean(localStorage.getItem("token")) || "";

        const setErrorBgc = () => {
            if (savedCheckData == undefined) {
                return ""
            } else if (savedCheckData) {
                return "check_result check_result--success"
            } else if (savedCheckData == false) {
                return "check_result check_result--error"
            }
        }

        const setErrorIcon = () => {
            if (savedCheckData == undefined) {
                return ""
            } else if (savedCheckData) {
                return <img src={successPayment} alt="successPayment"/>
            } else if (savedCheckData == false) {
                return <img src={errorPayment} alt="errorPayment"/>
            }
        }

        const setErrorMsg = () => {
            if (savedCheckData == undefined) {
                return ""
            } else if (savedCheckData) {
                return t('paymentDone')
            } else if (savedCheckData == false) {
                return t('paymentError')
            }
        }

        const paymentResultRender = () => {
            return (
                <div
                    // className={
                    //   savedCheckData
                    //     ? "check_result check_result--success"
                    //     : "check_result check_result--error"
                    // }
                    // className={savedCheckData === true
                    //   ? "check_result check_result--success" : savedCheckData === false
                    //   ? "check_result check_result--error" : null}
                    className={setErrorBgc()}
                >
                    <div className="container container--check">
                        <div className="check_result-icon">
                            {/* {savedCheckData ? (
                <img src={successPayment} alt="" />
              ) : (
                <img src={errorPayment} alt="" />
              )} */}
                            {setErrorIcon()}
                        </div>
                        <div className="check_result-desc">
                            {/* {savedCheckData ? t('paymentDone') : t('paymentError')} */}

                            {setErrorMsg()}
                        </div>
                    </div>
                </div>
            );
        };

        const showServiceTranslate = () => {
            if (langKey === 'valueRu') {
                return savedCheckData?.localName?.valueRu
            } else if (langKey === 'valueAz') {
                return savedCheckData?.localName?.valueAz
            } else if (langKey === 'valueEn') {
                return savedCheckData?.localName?.valueEn
            }
        }

        this.goBack = () => this.props.navigate(`/epay/${language}/home`);

        return (
            <>
                <div className='chech-wrapper'>
                    <div className="check-wrapper-head">
                        <NavigationComponent onClick={this.goBack} title={t("home")}/>
                        <div className="check container container--check">
                            {this.state.isLoading && (
                                <div className="loader-wrapper loader-wrapper--check">
                                    <Loading/>
                                </div>
                            )}

                            {savedCheckData ? (
                                <>
                                    <div className="check-head">
                                        <ShowCheckInfo savedCheckData={savedCheckData} />
                                        <div className="check-head__right">
                                            <ReactToPrint
                                                trigger={() => (
                                                    <img
                                                        onClick={() => this.onPrintClick()}
                                                        style={{ width: 25, height: 25, cursor: 'pointer'}}
                                                        src={require("../../img/printer-svgrepo-com.svg").default}
                                                    />
                                                )}
                                                content={() => this.checkEl?.current}
                                                pageStyle="print"
                                                documentTitle={`${t('onlinePaySistem')} - EPUL`}
                                            />

                                            <>
                                                <img
                                                    style={{width: 25, height: 25, marginLeft: 10, cursor: 'pointer'}}
                                                    onClick={() => this.setModalActive(true)}
                                                    src={require("../../img/share.svg").default}
                                                />
                                            </>
                                        </div>
                                    </div>
                                    <div className="check-head__bonus">
                                        {
                                            (!isAuthToken &&(savedCheckData?.status === 1 || savedCheckData?.status === 3 || savedCheckData?.status === 40)) ?
                                                <div className="check-head__bonus" style={{padding: "15px 10px"}}>
                                                    <div style={{color: 'rgba(71, 71, 71, 1)'}}
                                                         className="check_data-item-title check-data-content check_data-item-title--title">
                                                        <div className="bonus-text">{t('text1')}{" "}
                                                            <div className="spaces">{savedCheckData?.bonusAmount ?
                                                                <div className="bonus-amount">{`${savedCheckData.bonusAmount} ${t('text2')}`}</div> : null}
                                                            </div>
                                                            {" "}
                                                            {t('text3')}{" "}
                                                            <div className="spaces">
                                                                <a href={`https://www.e-pul.az/epay/sign/up?paymentPromoCode=${promocode?.value}`}>
                                                                    {t('text4')}
                                                                </a>
                                                            </div>
                                                            {" "}
                                                            {language === "az" &&
                                                                <div className="spaces">{t('text5')}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="bonus-auth">
                                                    <div style={{color: 'rgba(71, 71, 71, 1)'}}
                                                         className="check_data-item-title check-data-content check_data-item-title--title">
                                                        {t("bonusesReceived")}
                                                    </div>

                                                    <div className="check_data-item-title check-data-content ">
                                                        <div style={{display: "flex"}}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignSelf: "center",
                                                                    color: "rgba(239, 92, 39, 1)"
                                                                }}
                                                            >
                                                                {savedCheckData?.bonusAmount}
                                                            </div>
                                                            <img
                                                                src={require("../../img/bonus-icon.svg").default}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    width: '22px',
                                                                    height: '22px',
                                                                    objectFit: 'contain',
                                                                }}
                                                                alt="bonus-icon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className="check_data-items solid box zig zag-out shadow">
                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {t("chequeNumber")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {savedCheckData?.id}
                                            </div>
                                        </div>
                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {t("date")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {moment(savedCheckData?.insertDate).format("DD.MM.YYYY HH:mm")}
                                            </div>
                                        </div>

                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {t("serviceC")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {showServiceTranslate()}
                                            </div>
                                        </div>
                                        {this.historyFieldsValues()}
                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {savedCheckData?.operation === 'WALLET_TO_WALLET' ?
                                                t("senderWallet") : t("cardNumber")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {savedCheckData?.pan}
                                            </div>
                                        </div>
                                        {savedCheckData?.fields?.historyField?.map(item => { return (
                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {item.localName[langKey]}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {item.value}
                                            </div>
                                        </div>)})}
                                        <div className="check_data-item-sum"></div>
                                        <div className="check_data-item">
                                            <div style={{color: 'rgba(71, 71, 71, 1)'}}
                                                 className="check_data-item-title check-data-content check_data-item-title--title">
                                                {`${t("amount_")}` +
                                                    ", " +
                                                    savedCheckData?.currency }
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {(savedCheckData?.amount / 100).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className="check_data-item">
                                            <div style={{color: 'rgba(71, 71, 71, 1)'}}
                                                 className="check_data-item-title check-data-content check_data-item-title--title">
                                                {`${t("commission_")}` +
                                                    ", " +
                                                    savedCheckData?.currency }
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {commission?.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div className="check-head" style={{background: "#FF4239"}}>
                                        <ShowCheckInfo savedCheckData={savedCheckData} />
                                    </div>
                                    <div className="check_data-items solid box zig zag-out shadow">
                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {t("date")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {moment(savedCheckData?.insertDate).format("DD.MM.YYYY HH:mm")}
                                            </div>
                                        </div>

                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {t("serviceC")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {/* {savedCheckData.localName?.valueAz} */}
                                                {showServiceTranslate()}
                                            </div>
                                        </div>
                                        {this.historyFieldsValues()}
                                        <div className="check_data-item">
                                            <div
                                                className="check_data-item-title check-data-content check_data-item-title--title">
                                                {t("cardNumber")}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {savedCheckData?.pan}
                                            </div>
                                        </div>

                                        <div className="check_data-item-sum"></div>
                                        <div className="check_data-item">
                                            <div style={{color: 'rgba(71, 71, 71, 1)'}}
                                                 className="check_data-item-title check-data-content check_data-item-title--title">
                                                {`${t("amountWithCommissions")}, ${savedCheckData?.currency || 0}`}
                                            </div>
                                            <div className="check_data-item-title check-data-content ">
                                                {((Number(savedCheckData?.amount || 0) / 100) + Number(commission || 0)).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            }
                            {
                                !this.state.isLoading ? (
                                    <>
                                        {savedCheckData ? (
                                            <div className={'check__btns'}>
                                                <NavLink className={'check__link'} to={`/epay/${language}/home`}>
                                                    <Button
                                                        buttonText={`${t("newPayment")}`}
                                                        color="#FFFFFF"
                                                        background="#EF5C27"
                                                        borderRadius={15}
                                                    />
                                                </NavLink>
                                                <NavLink
                                                    to={`/epay/${language}/favorites`}
                                                    className={'check__link'}
                                                >
                                                    <Button
                                                        buttonText={`${t("selectedPayments")}`}
                                                        padding="14px 36px"
                                                        color="#FFFFFF"
                                                        background="#9473D6"
                                                        borderRadius={15}
                                                    />
                                                </NavLink>
                                            </div>
                                        ) : (
                                            <div className={'check__btns-home'}>
                                                <NavLink className={'check__link'} to={`/epay/${language}/home`}>
                                                    <Button
                                                        buttonText={`${t("goHome")}`}
                                                        color="#FFFFFF"
                                                        padding="14px 223px"
                                                        background="#EF5C27"
                                                        borderRadius={15}
                                                        width="100%"
                                                        margin="40px 0 0 0"
                                                    />
                                                </NavLink>
                                            </div>
                                        )}
                                    </>
                                ) : null
                            }

                            <CustomModal active={this.state.isModalOpen} setActive={this.setModalActive}>
                                <div className="receipt">
                                    <div
                                        className="receipt__close-icon"
                                        onClick={() => this.setModalActive(false)}
                                    >
                                        <img
                                            src={closeIcon}
                                            alt="cross icon"
                                        />
                                    </div>
                                    <div className="receipt__content">
                                        <div className="receipt__field">
                                            <label
                                                className="receipt__field-label"
                                                htmlFor="receiptEmail"
                                            >
                                                Email
                                            </label>

                                            <input
                                                className="receipt__field-input"
                                                id="receiptEmail"
                                                placeholder="Email"
                                                value={this.state.inputMail}
                                                onChange={e => this.getInputValue(e)}
                                            />
                                            <div
                                                className="receipt__message-icon"
                                                onClick={() => this.setModalActive(false)}
                                            >
                                                <img
                                                    src={message}
                                                    alt="cross icon"
                                                />
                                            </div>
                                            {!this.state.isCorrectEmail && (
                                                <div className="receipt__error">
                                                    {t("errorMessagePlease")}
                                                </div>
                                            )}
                                        </div>
                                        <div className="receipt__btns">
                                            <div
                                                className="receipt__btn receipt__btn--send"
                                                onClick={() => this.sendCheckToMail()}
                                            >
                                                {t("send")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomModal>
                        </div>
                    </div>
                    <>
                        <div className='chech-wrapper-other-panel'>
                            <OtherPayments/>
                        </div>
                        <div className="layout__app-otherWrap">
                            <div className='layout__app-otherWrap-border'></div>
                            <div className="layout__app-otherSwiper">
                                <OtherPaymentSwiper/>
                            </div>
                        </div>
                    </>

                </div>
                <div style={{display: "none"}}>
                    <div className="check_print" ref={this.checkEl}>
                        <div className="check_print-items">
                            <div className="check_print-item">
                                <div className="check_print-title">
                                    {t("history").toUpperCase()}
                                </div>
                                <div className="check_print-body">
                                    {moment(savedCheckData?.insertDate).format("DD.MM.YYYY HH:mm")}
                                </div>
                            </div>

                            <div className="check_print-item">
                                <div className="check_print-title">
                                    {t("quantity").toUpperCase()}
                                </div>
                                <div className="check_print-body check_print-amount">
                                    {savedCheckData && ((savedCheckData?.amount / 100) + this.state.commission).toFixed(2)}
                                    <div className="check_print-amount-currency">
                                        {savedCheckData?.currency === "AZN" ? "AZN" : "$"}
                                    </div>
                                </div>
                            </div>
                            <div className="check_print-data">
                                <div className="check_print-title">{t("checkDetails")}</div>
                                <div className="check_print-data_items">
                                    <div className="check_print-data-item">
                                        <div className="check_print-data-title">
                                            {t("chequeNumber")}
                                        </div>
                                        <div className="check_print-data-body">
                                            {savedCheckData?.id}
                                        </div>
                                    </div>
                                    <div className="check_print-data-item">
                                        <div className="check_print-data-item-title">
                                            {t("serviceC")}
                                        </div>
                                        <div className="check_print-data-item-body">
                                            {savedCheckData?.localName[langKey]}
                                        </div>
                                    </div>
                                    <div className="check_print-data-item">
                                        <div className="check_print-data-item-title">
                                            {t("customer")}
                                        </div>
                                        <div className="check_print-data-item-body">
                                            {savedCheckData?.person || savedCheckData?.firstName}
                                        </div>
                                    </div>
                                    {savedCheckData?.fields?.historyField?.map((data) => {
                                        return (
                                            <div key={data?.fieldId} className="check_print-data-item">
                                                <div className="check_print-data-item-title">
                                                    {data?.localName[langKey] + ":"}
                                                </div>
                                                <div className="check_print-data-item-body">
                                                    {data?.value === "A"
                                                        ? data?.enumFieldValue[langKey]
                                                        : data?.value}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="check_print-payment-method">
                            {t("paymentMet")}
                            <div className="check_print-payment-method-icon">
                                <img
                                    src={require("../../img/categories/payment-method.png")}
                                    alt="payment method"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    guestPayment: state.guestPayment,
    language: state.changeLangSlice,
    profile: state.authReducer,
    categories: state.categoriesReducer,
});
const mapDispatchToProps = {
    MsgToUserState: MsgToUserActionCreators.msgToUserState,
    fetchHistoryReceipt: fetchHistoryReceipt,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Check));
