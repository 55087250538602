import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IDebts } from "../../models/Debts";
import client, { BASE_URL } from "../../axios";
// import {IBonusStore} from "../../models/BonusStore";
// import {IBonusCategory} from "../../models/BonusCategory";


export const fetchDebts = createAsyncThunk(
  "debtsList/fetchDebts",
  async (sid: string | undefined, thunkAPI) => {
    try {
      const debtsList = await client.get<IDebts, any>(
        `bills/list/${sid || localStorage.getItem("token")}`,
        {
          headers: {
            "Payment-Origin":"web",
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        }
      );

        return debtsList.data;
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);
