import GuestPaymentActionTypes from './types';

const initialState = {
  formUrl: undefined,
  orderId: undefined,
  historyId: undefined,
  routeName: 'Payment',
};

const guestPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GuestPaymentActionTypes.SAVE_URL_ORDER: {
      return {
        ...state,
        formUrl: action.formUrl,
        historyId: action.historyId,
        orderId: action.orderId,
      };
    }
    case GuestPaymentActionTypes.SAVE_ROUTE_NAME: {
      return { ...state, routeName: action.routeName };
    }

    default:
      return state;
  }
};

export default guestPaymentReducer;
