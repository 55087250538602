import client, { BASE_URL } from '../../../axios';
import { fetchWithTimeout, toQueryParams } from '../../../utils/query';
import { LoadingActionCreators } from '../loading/action-creators';
import { MsgToUserActionCreators } from '../msgToUser/action-creators';
import { MessageType } from '../msgToUser/types';

export const addFavoritesActionCreators = {
  addFavorites: (comment, serviceId, fields) => async (dispatch) => {
    dispatch(LoadingActionCreators.loadingPaymentState(true));
    try {
      // const response = await fetchWithTimeout(
      //     BASE_URL + '/favorites/add',
      //     {
      //         method: "POST",
      //         headers: {
      //             'Content-Type': 'application/x-www-form-urlencoded',
      //             'Payment-Origin': 'web',
      //         },
      //         body: toQueryParams({
      //             comment: comment,
      //             serviceId: serviceId,
      //             fields: fields
      //         }),
      //     },
      // )
      const response = await client.post(
        '/favorites/add',
        {
          body: toQueryParams({
            comment: comment,
            serviceId: serviceId,
            fields: fields,
          }),
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      if (response.status === 200) {
        // const json = await response.json();
      } else {
      }
    } catch (e) {
      console.warn('error', e);
      dispatch(MsgToUserActionCreators.msgToUserState(MessageType.CatchError));
    } finally {
      dispatch(LoadingActionCreators.loadingPaymentState(false));
    }
  },
};
