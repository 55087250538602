import React from "react";
import Button from "../../Button/Button";
import MiniSpinner from "../../MiniSpinner/MiniSpinner";
import { useTranslation } from "react-i18next";

interface ButtonGroupProps {
  amountValue: string;
  transfer: () => void;
  openQR: () => void;
  btnLoading: boolean;
  disable: boolean;
}

const ActionButtons: React.FC<ButtonGroupProps> = ({ amountValue, transfer, openQR, btnLoading, disable }) => {
  const { t } = useTranslation();

  return (
    <div className="top-up-balance__left-wrap-btn">
      <Button
        disabled={disable}
        height={50}
        background="#EF5C27"
        color="#FFFFFF"
        buttonText={btnLoading ? <MiniSpinner /> : `${t("replenish")}`}
        borderRadius={15}
        onClick={transfer}
      />
      <Button
        height={50}
        disabled={disable}
        padding="14px 40px"
        background="#EF5C27"
        color="#FFFFFF"
        buttonText={`${t("issueInvoice")}`}
        borderRadius={15}
        onClick={openQR}
      />
    </div>
  );
};

export default ActionButtons;
