import React, {FC} from 'react';
import "./index.scss"
import {useTranslation} from "react-i18next";
import { getClassLang, getIdLang, logoSrc } from '../../../utils/functions';
import { appleStore, googleStore } from '../../../utils/consts';
import { PLATFORMS } from '../../../utils/enum';

interface InstallAppProps {

}

const InstallApp: FC = (props: InstallAppProps) => {
    const {t} = useTranslation()
    const langValue = localStorage.getItem("language");

    return (
        <div>
            <div className="install-app__app-modal">
                <div className="install-app__app-modal-wrap">
                    <div className="install-app__app-modal-wrap-text">{t("installEpulApp")}</div>
                    <div className="install-app__app-modal-wrap-texB">{t("instantTransfers")}</div>
                    <div id={getIdLang(langValue)} className="install-app__app-modal-wrap-foot">
                        <div className="app-wrap">
                            <a 
                                target="_blank"
                                href={googleStore}
                                rel="noreferrer" className="install-app__app-modal-wrap-foot-mod">
                                <img
                                    alt='Android OS'
                                    title='Android OS'
                                    className={getClassLang(PLATFORMS.GOOGLE, langValue)}
                                    src={logoSrc(PLATFORMS.GOOGLE, langValue)}
                                />
                            </a>
                        </div>
                        <div className="app-wrap-app">
                            <a 
                                target="_blank"
                                href={appleStore}
                                rel="noreferrer">
                                <img
                                    alt='Apple IOS'
                                    title='Apple IOS'
                                    className={getClassLang(PLATFORMS.APPLE, langValue)}
                                    src={logoSrc(PLATFORMS.APPLE, langValue)}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default InstallApp;

