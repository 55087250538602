import React, { FC, useEffect, useState } from "react";
import LayoutEmpty from "../layouts/LayoutEmpty/LayoutEmpty";
import { useAppSelector } from "../../hooks/redux";
import {Link, NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import "./index.scss";
import { ServiceActionCreators } from "../../redux/reducers/service/action-creators";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import client from "../../axios";
import {getResultPaymentUrl, notify} from "../../helper";
import { fieldsForPicker } from "../RecurringPayments/consts";
import PaymentFields from "../PaymentFields";
import AdditionalFields from "../AdditionalFields/AdditionalFields";
import { LoadingLocal } from "../Loading/Loading";
import { GuestActionCreators } from "../../redux/reducers/guest/action-creators";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from 'react-google-recaptcha'
import ContentLoader from "react-content-loader";
//@ts-ignore
import { Helmet } from 'react-helmet';
import { serviceMeta } from "../../metaTags/metaTags";
import { fetchTokenize } from "../../redux/actions/tokenize";
import InputFieldComponent from "../MyCardComponent/InputField/InputField";
import {LocaleName} from "../../models/Categories";
import OtherPayments from "../OtherPayments/OtherPayments";
import OtherPaymentSwiper from "../OtherPayments/OtherPaymentSwiper";
import { createHubCardData } from "../../utils/hubFunctions";
import { addToCart } from "../../redux/actions/basketActions";

interface IProps { }

const PaymentAdd: FC<IProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { forms } = useAppSelector((state) => state.masterPassReducer);
  const { language, langKey } = useAppSelector(state => state.changeLangSlice)
  const serviceInState = useAppSelector((state) => state.service);
  const { walletList } = useAppSelector((state) => state.walletList);
  const {isPaymentLoading} = useAppSelector((state) => state.loading);
  const [service, setService] = useState<any>(null);
  const [fields, setFields] = useState<any>([]);
  const [groupId, setGroupId] = useState(null);
  const [checkData, setCheckData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const [instrument, setInstrument] = useState<any>(null);
  const [amountValue, setAmountValue] = useState("");
  const [isClientExist, setIsClientExist] = useState(false);
  const [isAmountCorrect, setIsAmountCorrect] = useState(false);
  const [subVal, setSubVal] = useState("");
  const [subName, setSubName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [addToFavoriteIsChecked, setAddToFavoriteIsChecked] = useState(false);
  const [comment, setComment] = useState("");
  const [payLoading, setPayLoading] = useState(false);
  const [reCaptcha, setReCaptcha] = useState(null);
  const [reCaptchaLoad, setReCaptchaLoad] = useState(true)
  const [debtInfoClient, setDebtInfoClient] = useState(null);
  const [isHubSolutionPaymentMethod, setIsHubSolutionPaymentMethod] = useState(false)
  // const [activeModal, setActiveModal] = useState(false);
  const isAuthToken = localStorage.getItem("token") || "";
  const { browserToken } = useAppSelector(state => state.browserToken)
  //@ts-ignore
  const { basket } = useAppSelector((state) => state.basketSlice);
  const { state } = location;
  const cardPanId = "newcard_visa";
  const fieldsJSON = JSON.stringify({ fields });
  const [regExp, setRegExp] = useState<any>(null);
  const [isFieldsValid, setIsFieldsValid] = useState<boolean | null>(null);
  // useEffect(() => {
  //   const hasToken = localStorage.getItem('token');
  //
  //   if (service && service.id === 715 && !hasToken) {
  //     setActiveModal(true);
  //   }
  //
  //   const intervalId = setInterval(() => {
  //     const hasToken = localStorage.getItem('token');
  //     if (service && service.id === 715 && !hasToken) {
  //       setActiveModal(true);
  //     }
  //   }, 24 * 60 * 60 * 1000);
  //
  //   return () => clearInterval(intervalId);
  // }, [service, params?.categoryName, params?.serviceName]);


  useEffect(() => {
    if (params?.categoryName && params?.serviceName) {
      const categoriesLocale = localStorage.getItem('categories');
      const servicesLocale = localStorage.getItem('services');
      if (servicesLocale !== null && categoriesLocale !== null) {
        const findCategory = JSON.parse(categoriesLocale)?.find((category: any) => category?.name === params?.categoryName);
        const findService = JSON.parse(servicesLocale)?.find((service: any) => findCategory.id === service.categoryId && service.friendlyName === params?.serviceName);
        setService(findService)
      } else {
        setIsLoading(true)
        client.post<any>(`digest/getPaymentServiceByFriendlyName`, {
          name: params?.serviceName
        }).then((res: any) => {
          if (res.status === 200) {
            const serviceId = res.data?.id;
            client.post<any>(`digest/guestListPaymentServicesSelected/${serviceId}`, {
              selected: [serviceId && +serviceId]
            }).then((res: any) => {
              if (res.status === 200) {
                setService(res.data.list.service[0])
                setIsLoading(false)
              }
            }).catch((error: any) => {
              console.error("Error guestListPaymentServicesSelected", error);
              setIsLoading(false);
            });
          } else {
            setIsLoading(false);
          }
        }).catch((error: any) => {
          console.error("Error getPaymentServiceByFriendlyName", error);
          setIsLoading(false);
        });
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (service && fields) {
      const id = fields[0];
      const reg = service?.serviceFields?.serviceField?.find(
        (field: any) => field.id === Number(id?.id)
      );
      setRegExp(reg?.regExp);
    }
  }, [fields, service]);

  useEffect(() => {
    if (state?.fields) {
      checkIfUserExist(
        state?.serviceId,

        JSON.stringify({ fields: state?.fields }),
        state?.groupId || 0
      );
      setFields(state?.fields);
    }
    if (state?.groupId) {
      setGroupId(state?.groupId);
    }
  }, [state, state?.fields, state?.groupId, state?.serviceId, isAuthToken]);

  useEffect(() => {
    if (isFieldsValid === false) {
      setIsDisabled(true);
    } else if (state?.fields) {
      if (isClientExist && isAmountCorrect) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (isClientExist) {
        if (new RegExp(regExp).test(fields[0]?.value) && isAmountCorrect) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        if (fields.length && (fields?.[0]?.name == "prefix" ? (new RegExp(regExp).test(fields[0]?.value) && fields[1]?.value.length == 7) : new RegExp(regExp).test(fields[0]?.value))) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    }
  }, [isAmountCorrect, isClientExist, state?.fields, fields, reCaptcha, isFieldsValid]);

  useEffect(() => {
    return () => {
      dispatch(ServiceActionCreators.setService({}));
    };
  }, [dispatch]);

  const checkIfUserExist = async (
    serviceId: any,
    fields: any,
    selectedGroupId: any
  ) => {
    setIsLoading(true);

    try {
      // const response = await fetchWithTimeout(
      //   `${BASE_URL}guest/categories/client_info_with_group_id?fields=${encodeURIComponent(
      //     fields,
      //   )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}`,
      //   {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/x-www-form-urlencoded',
      //       'Payment-Origin': 'web',
      //     },
      //   },
      // )

      const requestHeaders: Record<string, string> = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }

      if (service?.captcha > 0) {
        requestHeaders['Captcha'] = reCaptcha || ''
      }

      const response = await client.get(
        `guest/categories/client_info_with_group_id?fields=${encodeURIComponent(
          fields
        )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}`,
        {
          headers: requestHeaders,
        }
      );
      setDebtInfoClient(response.data)
      if (response.status === 200) {
        // const json = await response.json()

        if (response.data.resultCodes === "OK") {
          setIsClientExist(true);
          setCheckData(response.data);
        } else if (
          response.data.resultCodes === "NOT_FOUND" ||
          response.data.resultCodes === "INVALID_PARAMETERS" ||
          response.data.resultCodes === "UNKNOWN_ERROR"
        ) {
          // setMsgType(1);
          // notify();
          notify(`${t("notFound")}`, false);
        } else if (
          response.data.resultCodes === "UNDECIDED_PROTOCOL" ||
          response.data.resultCodes === "PROTOCOL_CANT_DECIDED"
        ) {
          // setShowVerdictFields(true)
        } else {
          notify(`${t("unknownError")}`, false);
        }
      } else {
        // setMsgType(0);
        notify(`${t("unknownError")}`, false);
      }
    } catch (e) {
      // setMsgType(0);
      notify(`${t("unknownError")}`, false);
    } finally {
      setIsLoading(false);
    }
  };

  const onRecaptchaChange = (value: any) => {
    setReCaptcha(value)
  }

  const onBtnClick = async () => {
    try {
      if (isClientExist) {
        if (isAmountCorrect) {
          await payment();
        }
      } else {
        if (!reCaptcha && service?.captcha > 0) {
          notify(`${t("reCaptchaError")}`, false);
          return
        }
        await checkIfUserExist(service?.id, fieldsJSON, groupId || 0);
      }
    } catch (error) {
      notify(`${t("error")}`, false);
    } finally {
    }
  };

  const onBtnClickBasket = async() => {
    const amountValueNumber = Number(amountValue)
    //@ts-ignore
    const updatedFields = fields.map(({ qrfield, barfield, id, ...rest }) => {
      return { id: Number(id), ...rest };
    });
    const data = {
        isAuth: false,
        сartItemId: 0,
        cartId: basket?.cart?.id || "",
        amount: amountValueNumber*100,
        serviceId: service.id,
        fields: JSON.stringify(updatedFields),
        subName: subName,
        subValue: subVal,
        selectedGroup: groupId,
    }
    try {
      const response = await dispatch(addToCart(data))
      if (response.payload?.ResultCodes === "ok") {
        notify(`${t("addedToCart")}`, true);
      } else {
        notify(`${t("notAddedToCart")}`, false);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    };
  }

  useEffect(()=>{
    if(!service) return

    const { availablePaymentSystem } = service

    let isHubSolutionPaymentMethod = false

    availablePaymentSystem.forEach((item:any)=>{
      if(item.paymentSystem==="HUB_SOLUTION" && item.paymentProcessor === "HUB_SOLUTION") {
        isHubSolutionPaymentMethod  = true
        return
      }
    })
    setIsHubSolutionPaymentMethod(isHubSolutionPaymentMethod)

  },[service])

  const payment = async () => {

    if(isHubSolutionPaymentMethod) {
      const cardDataSpited = instrument.newCardData.expiryDate.split('/')

      const cardData = {
        CardHolder: 'e-pul',
        Cvv2: instrument.newCardData.cvc,
        ExpiryMonth: +cardDataSpited[0].trim(),
        ExpiryYear: +cardDataSpited[1].trim(),
        Pan: instrument.newCardData.cardNumber,
      }

      const { payload: cardToken } = await dispatch(
        fetchTokenize({
          body: {
            ...createHubCardData(instrument.newCardData),
          },
          browserToken: browserToken,
        }),
      )

      const url = await dispatch(
        GuestActionCreators.doGuestPay(
          service.id,
          Number(amountValue),
          fieldsJSON,
          false,
          subName,
          subVal,
          groupId || 0,
            getResultPaymentUrl(language),
          cardToken,
          '',
          4,
        ),
      )

      if (url) {
        window.location = url;
      }

    } else {
      const url = await dispatch(
        GuestActionCreators.doGuestPay(
          service.id,
          Number(amountValue),
          fieldsJSON,
          false,
          subName,
          subVal,
          groupId || 0,
            getResultPaymentUrl(language),
          cardPanId
        )
      );
      if (url) {
        window.location = url;
      }
    }

  }

  if (isServiceLoading || service === null || service === undefined) {
    return <LoadingLocal />;
  }


  // @ts-ignore
  const infoDebtClient = debtInfoClient?.info?.values?.serviceInfoItem[0]?.additionalInfoBasedOnServiceInfoItem;
  // const closeInsuranceModal  = () => {
  //   setActiveModal(false)
  // }
  // const handleLinkClick = () => {
  //   setActiveModal(false);
  //   window.location.href = '/epay/az/category/insurance/icbari_insurance'
  // };

  return (
    <LayoutEmpty goBack={() => navigate(-1)}>
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        {/*{activeModal && service && service.id === 715 && (*/}
        {/*    <CustomModal active={activeModal} setActive={setActiveModal}>*/}
        {/*      <div className="insurance-modal">*/}
        {/*        <div className="insurance-modal-body">*/}
        {/*          {"İcbari Həyat Sığortası ödənişlərini icra etmək üçün digər bölməyə - "}*/}
        {/*          <span*/}
        {/*              onClick={handleLinkClick}*/}
        {/*              className="insurance-modal-body-link"*/}
        {/*          >{"İcbari Sığorta Bürosuna "}</span>*/}
        {/*          <span>{"daxil olmalısınız"}</span>*/}
        {/*        </div>*/}
        {/*        <div className="insu§rance-modal-close">*/}
        {/*          <img*/}
        {/*              src={require("../../img/close-icon.svg").default}*/}
        {/*              alt="Close"*/}
        {/*              width={15}*/}
        {/*              height={15}*/}
        {/*              loading={"lazy"}*/}
        {/*              onClick={closeInsuranceModal}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </CustomModal>*/}
        {/*)}*/}
        <div className="payment-add">
          <Helmet>
            {/* @ts-ignore */}
            <title>{service.localName[langKey] + ' ' + serviceMeta[language!].title}</title>
            {/* @ts-ignore */}
            <meta name="description" content={service.localName[langKey] + ' ' + serviceMeta[language!].description} />
            {/* @ts-ignore */}
            <meta name="keywords" content={serviceMeta[language][params.categoryName!]?.[service!.id!] || serviceMeta[language]['notFoundKeywords']} />
          </Helmet>
          {(isLoading || isPaymentLoading) && (
            <div className="payment-add__loader">
              <LoadingLocal />
            </div>
          )}
          {
            <>
              <h1 className="payment-add__service-title">
                {service?.localName
                  ? service?.localName[langKey]
                  : ""}
              </h1>
              {Object.values(service.localDesc as Record<string, string>).every((value: any) => !value.trim()) ? null : (
                  <div className="payment-add-alert">
                    <div className="payment-add-alert-info">{`${t('information')}`}</div>
                    <div
                        dangerouslySetInnerHTML={{__html: service.localDesc[langKey as keyof LocaleName]}}/>
                  </div>
              )}
              <div className="payment-wrap">
                <div className="payment-add__content">
                  <PaymentFields
                      service={service && service}
                      onValueChange={(data: any) => {
                        data.hasOwnProperty("fields") && setFields(data.fields);
                        data.hasOwnProperty("groupId") && setGroupId(data.groupId);
                      }}
                      isClientExist={isClientExist}
                      azercell={service?.id === 976}
                      fieldsForPicker={fieldsForPicker}
                      defaultFields={state?.fields}
                      defaultGroupId={groupId}
                      onFieldsValidationChange={(isValid: boolean | null) => setIsFieldsValid(isValid)}
                  />
                  {isClientExist ? (
                      <>
                        <AdditionalFields
                            service={service}
                            checkData={checkData}
                            defaultValue={amountValue}
                            isAmountCorrect={isAmountCorrect}
                            onChange={(data: any) => {
                              data.hasOwnProperty("subVal") && setSubVal(data.subVal);
                              data.hasOwnProperty("subName") &&
                              setSubName(data.subName);
                              data.hasOwnProperty("amountValue") &&
                              setAmountValue(data?.amountValue);
                              data.hasOwnProperty("isAmountCorrect") &&
                              setIsAmountCorrect(data.isAmountCorrect);
                            }}
                        />
                      </>
                  ) : null}
                </div>

                {infoDebtClient?.length > 0 &&
                    <div className='paymnet-add__right-block'>
                      <div className="paymnet-add__right-block-info">
                        {infoDebtClient.map(({
                                                                 key,
                                                                 value,
                                                                 mutliLangValueString
                                                               }: any, index: number) => (
                            <div key={key} className="debt-item">
                              <div className='multilangValue'>{mutliLangValueString[langKey as keyof LocaleName]}</div>
                              <div className='value-debt'>{value}</div>
                            </div>
                        ))}
                      </div>
                    </div>
                }
              </div>

              {isHubSolutionPaymentMethod && isClientExist && <InputFieldComponent
                infoImage
                cardDataCb={data =>{
                  setInstrument({ newCardData: data, isNewCard: true })
                }}
              />}

              {!isClientExist && service?.captcha > 0 && (
                  <div className='payment-recaptch'>
                    {reCaptchaLoad &&
                        <ContentLoader
                      speed={2}
                      width={304}
                      height={78}
                      viewBox="0 0 304 78"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="6" y="11" rx="0" ry="0" width="398" height="137" />
                      <rect x="123" y="64" rx="0" ry="0" width="49" height="7" />
                    </ContentLoader>}
                  <ReCAPTCHA
                    sitekey={'6LfhleMoAAAAAIKvywPzSxtTk9K50bnPVl82gncg'}
                    onChange={onRecaptchaChange}
                    hl={language}
                    asyncScriptOnLoad={() => {
                      setReCaptchaLoad(false)
                    }}
                  />
                </div>
              )}

              <div className="payment-add__guest-wrap-btn">
                <div className="payment-add__btns" style={{width: !isClientExist ? '100%' : "49%"}}>
                  <button
                      onClick={() => onBtnClick()}
                      className={
                        isDisabled || isPaymentLoading
                            ? "payment-add__btn payment-add__btn--disabled"
                            : "payment-add__btn"
                      }
                  >
                    {" "}
                    {isClientExist ? t("pay") : t("check")}
                  </button>
                </div>
                {isClientExist &&
                    <div className="payment-add__btns" style={{width: !isClientExist ? '100%' : "49%"}}>
                      <button
                          onClick={() => onBtnClickBasket()}
                          className={
                            isDisabled || isPaymentLoading
                                ? "payment-add__btn payment-add__btn--disabled-guest"
                                : "payment-add__btn-guest"
                          }
                      >
                        {" "}
                        {t("addToBasket")}
                      </button>
                    </div>
                }
              </div>

            </>
          }
        </div>
        {/*{infoDebtClient && infoDebtClient?.length > 0 &&*/}
        {/*    <div className='paymnet-add__right-block'>*/}
        {/*      <div className="paymnet-add__right-block-info">*/}
        {/*        {infoDebtClient && infoDebtClient.map(({*/}
        {/*                                                 key,*/}
        {/*                                                 value,*/}
        {/*                                                 mutliLangValueString*/}
        {/*                                               }: any, index: number) => (*/}
        {/*            <div key={index} className="debt-item">*/}
        {/*              <div className='multilangValue'>{mutliLangValueString[langKey as keyof LocaleName]}</div>*/}
        {/*              <div className='value-debt'>{value}</div>*/}
        {/*            </div>*/}
        {/*        ))}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*}*/}
        <>
          <div className="payment-add__other">
            <OtherPayments/>
          </div>
          <div className="payment-add__swiper">
            <div className="layout__app-otherWrap">
              <div className='layout__app-otherWrap-border'></div>
              <div className="layout__app-otherSwiper">
                <OtherPaymentSwiper/>
              </div>
            </div>
          </div>
        </>
      </div>
    </LayoutEmpty>
  );
};

export default PaymentAdd;
