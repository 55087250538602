import React from "react";
import Calendar from "react-calendar";
import "./index.scss";
import "react-calendar/dist/Calendar.css";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";

import moment from 'moment';
import 'moment/locale/az';

type Props = {
  children?: React.ReactNode;
  onChange?: (e: any) => void;
  value?: Date | any;
  setDate?: (i: string) => void;
  setModalActive?: any;
  date?: string;
  title?: string;
  locale?: any
};

const CustomCalendar: React.FC<Props> = ({
  setModalActive,
  value,
  onChange,
  setDate,title,
}) => {
    const {t} = useTranslation()

    const lang = localStorage.getItem("language");

    return (
        <div className="custom-calendar__calendar">
            <div className="custom-calendar__calendar-head">
                <p style={{}}>{t(`${title}`)}</p>
                <img
                    onClick={() => setModalActive(false)}
                    src={require("../../img/close-icon.svg").default}
                />
            </div>
            <div className="custom-calendar__calendar-selected-date">
                {/*{new Date(value).toString()}*/}
                {/*{new Date(value)?.toLocaleDateString("az-Cyrl-AZ")}*/}
            </div>
            {/*<Calendar onChange={onChange} value={value!} locale={`${lang}`}/>*/}
            <Calendar onChange={onChange} value={value!} locale={"az"}/>
            <div className="custom-calendar__calendar-button">
                <Button
                    minWidth={290}
                    borderRadius={15}
                    buttonText={`${t("save")}`}
                    padding="14px 131px"
                    color=" #FFFFFF"
                    background="#EF5C27"
                    onClick={() => setModalActive(false)}
                />
      </div>
    </div>
  );
};

export default CustomCalendar;
