import React from "react";
import {connect} from "react-redux";

import moment from "moment";
import {getQueryParams} from "../../utils/query";
import ReactToPrint from "react-to-print";

import {MsgToUserActionCreators} from "../../redux/reducers/msgToUser/action-creators";
import {MessageType} from "../../redux/reducers/msgToUser/types";

import errorPayment from "../../img/error-payment.svg";
import successPayment from "../../img/success-payment.svg";

import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Languages} from "../../redux/reducers/language/languageData";

import paymentMethod from "../../img/payment-method.png";
import Loading from "../../Components/Loading/Loading";
import client from "../../axios";
import { t } from "i18next";
import i18next from "i18next";

class GuestCheckScreen extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isModalVisible: false,
      isLoading: false,
      inputMail: "",
      savedCheckData: undefined,
      localName: "",
      historyField: [],
      isCorrectEmail: true,
      msgType: 0,
      inputPhone: "",
      isCorrectPhone: "false",
      commission: null,
    };
  }

  async componentDidMount() {
    await this.getCheckData();
    await this.getPromoData();
  }

  notify = () => {
    const { language: selectedLanguage } = this.props.language;
    const language = Languages(selectedLanguage);

    let message;
    switch (this.state.msgType) {
      case 1:
        message = language.sendCheckMsgSuccess;
        break;
      case 2:
        message = language.sendCheckLimitError;
        break;
      case 3:
        message = language.sendSmsMsgSuccess;
        break;
      case 4:
        message = language.smsLimitError;
        break;
      default:
        message = language.someError;
        break;
    }

    toast(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  async calculateCommission(serviceId, amountValue) {
    try {
      const res = await client.post(`digest/guestListPaymentServicesSelected/${serviceId}`, {
        selected: [serviceId && +serviceId]
      })
      if (res.status === 200) {
        const { minimalComission, percent } = res.data?.list?.service[0];
        if (percent === 0) {
          return minimalComission
        } else {
          const commission = Math.round(Number(amountValue) * (percent / 10000)) / 100
          if (commission < minimalComission) {
            return minimalComission
          } else return commission
        }
      }
    } catch(e) {
      console.log(e)
    }

  }

  async getCheckData() {
    const { orderId } = getQueryParams();

    this.setState({ isLoading: true });
    try {
      await client.get(`guest/rurl_payment_guest?orderId=${orderId}`);
      const response = await client.get(`guest/receipt2?orderId=${orderId}`);

      if (response.status === 200) {
        if (response.data?.resultCodes === "OK") {
          this.setState({ savedCheckData: response.data?.item });
          this.setState({
            localName: response.data?.item.localName["valueAz"],
          });
          const commission = await this.calculateCommission(response?.data?.item?.fields?.historyField[0]?.serviceId, response?.data?.item?.amount)
          console.log(`commission:`, commission)
          this.setState({commission: commission})
          this.saveMainField(response.data?.item.fields.historyField);
          const { categories } = this.props;
          const categoryName = categories?.categories?.find((category) => category?.id === response.data?.item?.categoryId)?.name;
          const data = {
            event: "purchase",
            ecommerce: {
              transaction_id: response.data?.item.orderId,
              value: ((response.data?.item.amount / 100) + commission).toFixed(2),
              currency: response.data?.item.currency,
              items: [{
                item_name: response.data?.item?.localName.valueEn,
                item_id: response.data?.item.id,
                price: (response.data?.item.amount / 100).toFixed(2),
                affiliation: response.data?.item.asseblyId,
                item_category: categoryName,
                quantity: 1
              }]
            }
          }
          console.log(data)
          window.dataLayer.push({ecommerce: null});
          window.dataLayer.push(data);
        }
      } else {
        // this.props.MsgToUserState(MessageType.SomeError);
      }
    } catch (e) {
      console.warn("error", e);
      this.props.MsgToUserState(MessageType.CatchError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getPromoData() {
    const { orderId } = getQueryParams();

    this.setState({ isLoading: true });
    try {
      const response = await client.get(
          `guest/receipt/promocode?orderId=${orderId}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
      );
      if (response.data?.history.resultCodes === "OK") {
        this.setState({ promocode: response.data?.promocode });
      }
    } catch (e) {
      console.warn("error", e);
      this.props.MsgToUserState(MessageType.CatchError);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  async sendCheckToMail() {
    this.setState({ isLoading: true });
    const { orderId } = getQueryParams();

    try {
      const response = await client.post(
          `guest/receipt/send?orderId=${orderId}&email=${this.state.inputMail}&sendType=email`
      );
      if (response.status === 200) {
        if (response.data?.resultCodes === "OK") {
          toast(t('checkToEmail'), {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (response.data?.resultCodes === "EMAIL_SEND_LIMIT_EXCEEDED") {
          this.setState({ msgType: 2 });
        }
      } else {
        console.warn("error");
        this.setState({ msgType: 0 });
      }
    } catch (e) {
      console.warn("error", e);
      this.setState({ msgType: 0 });
    } finally {
      this.setState({ isLoading: false });
    }
  }
  async sendLinkToSms() {
    const { inputPhone, isCorrectPhone } = this.state;
    if (!isCorrectPhone) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const response = await client.post(
          `guest/send-sms-with-link?phoneNumber=${inputPhone}`
      );
      console.warn("responseGuestSendLink", response);
      if (response.data?.status === 200) {
        if (response.data?.resultCodes === "SMS_SEND") {
          this.setState({ msgType: 3 });
        } else {
          this.setState({ msgType: 4 });
        }
      } else {
        console.warn("error");
        this.setState({ msgType: 0 });
      }
    } catch (e) {
      console.warn("error", e);
      this.setState({ msgType: 0 });
    } finally {
      this.setState({ isLoading: false });
    }
  }
  saveMainField(historyField) {
    let fields = [];
    for (const i in historyField) {
      const item = historyField[i];
      let field = { enumFieldValue: {}, localName: {}, value: "" };
      field.enumFieldValue = item.enumFieldValue;
      field.localName = item.localName;
      field.value = item.value;
      fields.push(field);
    }
    this.setState({ historyField: fields });
  }
  renderErrorMsgEmail(language) {
    if (!this.state.isCorrectEmail) {
      if (this.state.inputMail.length === 0) {
        return t('fieldEmpty');
      }
    }
  }
  renderErrorMsgPhone(language) {
    if (!this.state.isCorrectPhone) {
      if (this.state.inputPhone?.length === 0) {
        return t('fieldEmpty');
      }
    }
  }

  render() {
    const { language: selectedLanguage, langKey } = this.props.language;
    const language = Languages(selectedLanguage);
    const langs = {
      en: "valueEn",
      az: "valueAz",
      ru: "valueRu"
    };
    const currentLang = i18next.language;

    let { savedCheckData, promocode, commission } = this.state;

    const isPayHistory = false;
    const regEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const regPhone = /^\+[1-9][0-9]*$/;

    const savedCheckDataLocalName = savedCheckData?.localName?.[langs[currentLang]]

    const paymentResultRender = () => {
      const checkResultLinkRender = () => {
        if(savedCheckData?.status === 1 ||
          savedCheckData?.status === 3 ||
          savedCheckData?.status === 40){
           return (
            <div className="check_result-link">
              {currentLang === "az" ? (
                  <>
                    Təbriklər! Bu ödəniş ilə siz{" "}
                    {promocode?.bonusGiven ? promocode?.bonusGiven : null} bonus
                    qazandınız. Əldə etmək üçün elə indi{" "}
                    <a
                        href={`https://www.e-pul.az/epay/sign/up?paymentPromoCode=${promocode?.value}`}
                    >
                      buradan
                    </a>{" "}
                    qeydiyyatdan keçin!
                  </>
              ) : currentLang === "en" ? (
                  <>
                    Congratulations! You have earned{" "}
                    {promocode?.bonusGiven ? promocode?.bonusGiven : null} bonuses
                    with this payment. To get them, register{" "}
                    <a
                        href={`https://www.e-pul.az/epay/sign/up?paymentPromoCode=${promocode?.value}`}
                    >
                      here
                    </a>{" "}
                  </>
              ) : (
                  <>
                    Поздравляем! С этой оплаты вы заработали{" "}
                    {promocode?.bonusGiven ? promocode?.bonusGiven : null} бонусы.
                    Чтобы получить их, зарегистрируйтесь{" "}
                    <a
                        href={`https://www.e-pul.az/epay/sign/up?paymentPromoCode=${promocode?.value}`}
                    >
                      здесь
                    </a>{" "}
                  </>
              )}
            </div>

      )}
      };

      return (
        this.state.isLoading ? <></> :
          <>
            <div
                className={
                  savedCheckData
                      ? "check_result check_result--success"
                      : "check_result check_result--error"
                }
            >
            <div className="container container--check">
              <div className="check_result-icon">
                  {savedCheckData?.status === 1 || savedCheckData?.status === 3 || savedCheckData?.status === 40 || savedCheckData?.status === 2 ? (
                    <img src={successPayment} alt="" />
                  ) : (
                    <img src={errorPayment} alt="" />
                  )}
              </div>
              <div className="check_result-desc">
                {(savedCheckData?.status === 1 || savedCheckData?.status === 3 || savedCheckData?.status === 40 || savedCheckData?.status === 2) ? t('paymentDone') : t('paymentError')}
              </div>
              {savedCheckData && checkResultLinkRender()}
            </div>
          </div>
        </>
      );
    };

    return (
        <>
          {paymentResultRender()}
          <div className="check container container--check">
            {(savedCheckData?.status === 1 || savedCheckData?.status === 3 || savedCheckData?.status === 40 || savedCheckData?.status === 2) && (
                <div className="check_data-items">
                  <div className="check_data-item">
                    <div className="check_data-item-title check-data-content">
                      {t("date").toUpperCase() + ":"}
                    </div>
                    <div className="check_data-item-body check-data-content">
                      {moment(savedCheckData?.insertDate).format("DD.MM.YYYY HH:mm")}
                    </div>
                  </div>
                  <div className="check_data-item">
                    <div className="check_data-item-title check-data-content">
                      {t("amount").toUpperCase() + ":"}
                    </div>
                    <div className="check_data-item-body check-data-content">
                    {((savedCheckData?.amount / 100) + (commission || 0)).toFixed(2)}{" "}
                      {savedCheckData?.currency === "AZN" ? "AZN" : "$"}
                    </div>
                  </div>
                  <div className="check_data-item">
                    <div className="check_data-item-title check-data-content">
                      {t("service").toUpperCase() + ":"}
                    </div>
                    <div className="check_data-item-body check-data-content">
                      {savedCheckDataLocalName}
                    </div>
                  </div>
                  {this.state.historyField.map((item, i) => {
                    return (
                        <div key={i} className="check_data-item">
                          <div className="check_data-item-title check-data-content">
                            {item.localName[langs[currentLang]].toUpperCase()+":"}
                          </div>
                          <div className="check_data-item-body check-data-content">
                            {item.value === "A"
                                ? item.enumFieldValue[langKey]
                                : item.value}
                          </div>
                        </div>
                    );
                  })}
                  {!savedCheckData?.operation === 'PAN_TO_WALLET' &&
                  <div className="check_data-item">
                    <div className="check_data-item-title check-data-content">
                      {t("cardNumber").toUpperCase() + ":"}
                    </div>
                    <div className="check_data-item-body check-data-content">
                      {savedCheckData?.pan}
                    </div>
                  </div>}
                  <div className="check_data-item">
                    <div className="check_data-item-title check-data-content">
                      {t("chequeNumber").toUpperCase()}
                    </div>
                    <div className="check_data-item-body check-data-content">
                      {savedCheckData?.id}
                    </div>
                  </div>

                  <div className="check_send-mail">
                    <input
                        className="check_send-mail-input"
                        type="email"
                        onChange={(e) => {
                          this.state.inputMail && regEmail.test(e.target.value)
                              ? this.setState({
                                inputMail: e.target.value,
                                isCorrectEmail: true,
                              })
                              : this.setState({
                                inputMail: e.target.value,
                                isCorrectEmail: false,
                              });
                        }}
                        placeholder={"e-mail"}
                        value={this.state.inputMail}
                    />
                    <div className="check_send-mail-validate-message">
                      {this.renderErrorMsgEmail(language)}
                    </div>
                    <button
                        className="check_send-mail-btn"
                        disabled={!this.state.isCorrectEmail || !this.state.inputMail}
                        onClick={() => {
                          this.sendCheckToMail();
                        }}
                    >
                      {t("sendToMail")}
                    </button>
                    <ReactToPrint
                        trigger={() => (
                            <button
                                className="check_send-mail-btn"
                                onClick={() => {
                                  this.contentRef.style.display = "block";
                                }}
                            >
                              {t("print")}
                            </button>
                        )}
                        content={() => this.contentRef}
                        pageStyle="print"
                        documentTitle={`${t('onlinePaySistem')} - EPUL`}
                    />
                  </div>
                </div>
            )
          }
            <div
                style={{
                  display: "none",
                }}
            >
              <div ref={(el) => (this.contentRef = el)} className="check_print">
                <div className="check_print-items">
                  <div className="check_print-item">
                    <div className="check_print-title">
                      {t('date').toUpperCase()}
                    </div>
                    <div className="check_print-body">
                      {moment(savedCheckData?.insertDate).format(
                          "DD.MM.YYYY HH:mm"
                      )}
                    </div>
                  </div>

                  <div className="check_print-item">
                    <div className="check_print-title">
                      {t('amount2').toUpperCase()}
                    </div>
                    <div className="check_print-body check_print-amount">
                      {((savedCheckData?.amount / 100) + (commission || 0)).toFixed(2)}{" "}
                      <div className="check_print-amount-currency">
                        {savedCheckData?.currency === "AZN" ? "AZN" : "$"}
                      </div>
                    </div>
                  </div>
                  <div className="check_print-data">
                    <div className="check_print-title">{t('checkInfo')}</div>
                    <div className="check_print-data_items">
                      <div className="check_print-data-item">
                        <div className="check_print-data-title">
                          {t('checkNumber') + ":"}
                        </div>
                        <div className="check_print-data-body">
                          {savedCheckData?.id}
                        </div>
                      </div>
                      <div className="check_print-data-item">
                        <div className="check_print-data-item-title">
                          {t('service') + ":"}
                        </div>
                        <div className="check_print-data-item-body">
                          {savedCheckDataLocalName}
                        </div>
                      </div>
                      <div className="check_print-data-item">
                        <div className="check_print-data-item-title">
                          {t('person') + ":"}
                        </div>
                        <div className="check_print-data-item-body">
                          {savedCheckData?.person}
                        </div>
                      </div>
                      {this.state.historyField.map((item, i) => {
                        return (
                            <div key={i} className="check_print-data-item">
                              <div className="check_print-data-item-title">
                                {item.localName[langs[currentLang]] + ":"}
                              </div>
                              <div className="check_print-data-item-body">
                                {item.value === "A"
                                    ? item.enumFieldValue[langKey]
                                    : item.value}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="check_print-payment-method">
                  {t("paymentMethod")}
                  <div className="check_print-payment-method-icon">
                    <img src={paymentMethod} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {this.state.isLoading && (
                <div className="loader-wrapper loader-wrapper--check">
                  <Loading />
                </div>
            )}
          </div>
          <ToastContainer />
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  guestPayment: state.guestPayment,
  language: state.language,
  categories: state.categoriesReducer,
});
const mapDispatchToProps = {
  MsgToUserState: MsgToUserActionCreators.msgToUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCheckScreen);
