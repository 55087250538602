import Header from '../Header/Header';
import { PaymentPage } from './PaymentPage';

export const HeaderContent = () => {

  return (
    <Header>
        <PaymentPage />
    </Header>
  );
};

export default HeaderContent;
