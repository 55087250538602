import React, {useState} from 'react';
import "./index.scss"
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import InstallApp from "../../CustomsComponents/InstallApp";
import HeaderText from "../../CustomsComponents/HeaderText";
import Button from "../../Button/Button";
import {useNavigate} from "react-router-dom";
import CustomCalendar from "../../Calendar/Calendar";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import moment from "moment";
import {useTranslation} from "react-i18next";

type Props = {
    onClick?: (event: MouseEvent) => void,
}

const DebtMobile: React.FC<Props> = () => {
    const {t} = useTranslation()

    const [modalActive, setModalActive] = useState(false)
    const [value, onChange] = useState(new Date());
    // const [date, setDate] = React.useState(moment().format('MM.DD.YYYY'))
    const navigate = useNavigate();

    const goBack = () => navigate(-1)


    return (
        <div className="debt-replenishment">
            <NavigationComponent onClick={goBack} title={t("debt")}/>
            <div className="debt-replenishment__container">
                <div className="debt-replenishment__container-left">
                    <HeaderText title={"Пополнение мобильного"}/>
                    <div className="debt-replenishment__telephone">
                        <p className="debt-replenishment__telephone-text">{t("phoneNumber")}</p>
                        <div className="debt-replenishment__telephone-number">
                            <div className="debt-replenishment__telephone-wrap">
                                <input type="text" className="no-outline" placeholder="050 111 22 33"/>
                            </div>
                        </div>
                        <div className="debt-replenishment__sum-main">
                            <div className="debt-replenishment__input-div">
                                <input placeholder="Сумма, AZN"/>
                            </div>
                            <div className="debt-replenishment__button-div">
                                <button className="debt-replenishment__sum-main-button">5</button>
                                <button className="debt-replenishment__sum-main-button">7</button>
                                <button className="debt-replenishment__sum-main-button">10</button>
                                <button className="debt-replenishment__sum-main-button">20</button>
                            </div>
                        </div>
                        <div className="debt-replenishment__min-max">{"Мин. 1 AZN, Макс. 200 AZN"}</div>
                    </div>
                    <div className="debt-replenishment__data-main">
                        <img
                            onClick={() => setModalActive(true)}
                            className="debt-replenishment__data-main-image"
                             src={require("../../../img/date-picker-icon.svg").default}
                            alt="debt-replenishment__data-main-image"
                        />
                        <p className="debt-replenishment__data-main-text">{"Дата истечения"}</p>
                        <p className="debt-replenishment__data-main-date">{value?.toLocaleDateString("az-Cyrl-AZ")}</p>
                    </div>

                    <CustomModal active={modalActive} setActive={setModalActive}>
                        <CustomCalendar setModalActive={setModalActive} value={value}
                                        onChange={onChange}/>
                    </CustomModal>

                    <div className="debt-replenishment__telephone-number">
                        <div className="debt-replenishment__telephone-wrap">
                            <input type="text" className="no-outline" placeholder="Комментарий"/>
                        </div>
                    </div>
                    <div className="debt-replenishment__footer">
                        <Button
                            buttonText={`${t("send")}`}
                            minWidth={368}
                            background="#EF5C27"
                            padding="14px 133px"
                            color="#fff"
                            borderRadius={15}
                        />
                    </div>
                </div>
                <div className="debt-replenishment__container-right">
                    <InstallApp/>
                </div>
            </div>
        </div>
    );
};

export default DebtMobile;
