import React from "react";
import {Link, useNavigate} from "react-router-dom";
import HeaderText from "../../CustomsComponents/HeaderText";
import "./Identification.scss";
import SideMenu from "../SideMenu/SideMenu";
import {useTranslation} from "react-i18next";
import { useAppSelector} from "../../../hooks/redux";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";

const { default: locationIcon } = require('../../../img/locationIcon.svg');
const onlineIcon = require('../../../img/onlineIcon.png')
const asanImzaIcon = require('../../../img/asan-imza.png')
const simaIcon = require('../../../img/sima.png')
const { default: dotsImage } = require('../../../img/dots-icon.svg')

const Identification = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { language } = useAppSelector(state => state.changeLangSlice);

    const {walletList} = useAppSelector(state => state.walletList);

    const goBack = () => navigate(-1);
    //
    // useEffect(() => {
    //     dispatch(checkIdentification())
    // }, [dispatch])

    const identification = [
        {
            img: locationIcon,
            title: `${t("comeOffice")}`,
            path: '/identification/office',
        },
        {
            img: onlineIcon,
            title: `${t("online")}`,
            path: '/identification/online',
        },
        {
            img: asanImzaIcon,
            title: 'ASAN iMZA',
            path: '/identification/asan-imza',
        },
        {
            img: simaIcon,
            title: 'SIMA',
            path: '/identification/sima',
        },
    ]

    const identificationWallet = [
        {
            img: dotsImage,
            title: `${t("paymentsAllCategories")}`,
            text: `${t("payCategories")}`,
        },
        {
            img: dotsImage,
            title: `${t("unlimitedTransfers")}`,
            text: `${t("moneyTransfers")}`,
        },
        {
            img: dotsImage,
            title: `${t("unlimitedBalance")}`,
            text: `${t("populateWallet")}`,
        },
    ]

  return (
      <LayoutEmpty goBack={goBack} navTitle={t("back")}>
          <div className="identification__wrap">
              <div className="identification__wrap-left">
                  {
                      walletList?.wallet[0]?.isIdentified
                          ?
                          <div className="identification__pass">
                              <div className="identification__pass-img">
                                  <img src={require('../../../img/pass.svg').default} alt=""/>
                              </div>
                              <div className="identification__pass-title">
                                  {t('identificationPassed')}
                              </div>
                              <div className="identification__pass-subtitle">
                                  {t('epulWorksWithoutRestrictions')}
                              </div>
                          </div>
                          :
                          <>
                              <HeaderText title={t("goThrough")}/>
                              <div className="identification__wrap-left-text">{t("chooseConvenient")}</div>
                              <div className="identification__wrap-left-contain">
                                  {identification.map((item, index) => (
                                      <Link key={index} to={`/epay/${language}${item.path}`} className="identification__item">
                                          <div className="identification__item-image">
                                              <img src={item.img} alt="" className="identification__icon" />
                                          </div>
                                          <div className="identification__item-title">
                                              {item.title}
                                          </div>
                                      </Link>
                                  ))}
                              </div>
                              <div className="identification__wrap-left-contain-wrapText">
                                  <div
                                      className="identification__wrap-left-contain-wrapText-textHead">{t("getIdentified")}</div>
                                  <div
                                      className="identification__wrap-left-contain-wrapText-textBody">{t("anonymousUsers")}</div>
                              </div>
                              <div className="identification__wrap-left-contain-idenWallet">
                                  <div
                                      className="identification__wrap-left-contain-idenWallet-head">
                                      {t("identifiedWallet") }
                                  </div>
                                  {identificationWallet.map((item, index) => (
                                      <div key={index} className="identification__wrap-left-contain-idenWallet-body">
                                          <div>
                                              <img src={item.img} alt=""/>
                                          </div>
                                          <div className="identification__wrap-left-contain-idenWallet-body-text">
                                              <div
                                                  className="identification__wrap-left-contain-idenWallet-body-text-head">{item.title}</div>
                                              <div
                                                  className="identification__wrap-left-contain-idenWallet-body-text-headN">{item.text}</div>
                                          </div>
                                      </div>
                                  ))}
                              </div>
                          </>
                  }

              </div>
              <div className="identification__wrap-right">
                  <SideMenu/>
              </div>
          </div>
      </LayoutEmpty>

  );
};

export default Identification;
