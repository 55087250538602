import React, { FC } from "react";
import "../History.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "moment/locale/ru";
import "moment/locale/en-gb";
import "moment/locale/az";


interface IHistoryItem {
    item: any;
    historyItemLoading: boolean;
}


const DigitalHistoryItem: FC<IHistoryItem> = ({ item, historyItemLoading }) => {
    const { t } = useTranslation();
    const langHistory = localStorage.getItem("language");

    let currency = item?.currency;

    if (currency === 944) {
        currency = 'AZN'
    } else if (currency === 840) {
        currency = 'USD';
    }

    return (
        <>
            <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div key={item.id} className="history-left__body-item">
                        <Typography>
                            <div className="history-left__body-time gray-small-time">
                                {moment(item.date).locale(`${langHistory}`).format("ll")}
                            </div>
                        </Typography>
                        <div style={{ minWidth: 60 }}>
                            <img
                                className="history-left__body-logo"
                                src={item.icon}
                                alt=""
                            />
                        </div>
                        <div className="history-left__body-name__block">
                            <div className="history-left__body-name">
                                {item.name}
                            </div>
                            <div className="history-left__body-type gray-small">
                                {item.description}
                            </div>
                        </div>
                        <div className="history-left__body-amount">
                            {item.amount.toFixed(2)}
                            <div className="history-left__body-amount__currency">
                                {currency}
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="history__details">
                        <div className="history-accordion">
                            <div className="history-accordion__left">
                                <div className="history-accordion-col">
                                    <div className="history-accordion-amount gray-small">
                                        <div className="history-accordion__title">
                                            {t("name")}
                                        </div>
                                        <div className="history-accordion__body">
                                            {item?.name}
                                        </div>
                                    </div>
                                </div>
                                <div className="history-accordion-col">
                                    <div className="history-accordion-amount gray-small">
                                        <div className="history-accordion__title">
                                            {t("service")}
                                        </div>
                                        <div className="history-accordion__body">
                                            {item?.description}
                                        </div>
                                    </div>
                                </div>
                                <div className="history-accordion-col">
                                    <div className="history-accordion-amount gray-small">
                                        <div className="history-accordion__title">
                                            {t("amount")}
                                        </div>
                                        <div className="history-accordion__body">
                                            {item.amount.toFixed(2)}
                                            <div className="history-left__body-amount__currency">
                                                {currency}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="history-accordion__right">
                                <div className="history-accordion-col">
                                    <div className="history-accordion-amount gray-small">
                                        <div className="history-accordion__title">
                                            {t("date")}
                                        </div>
                                        <div className="history-accordion__body">
                                            {moment(item.date).locale(`${langHistory}`).format("ll")}
                                        </div>
                                    </div>
                                </div>
                                <div className="history-accordion-col">
                                    <div className="history-accordion-amount gray-small">
                                        <div className="history-accordion__title">
                                            Operation ID
                                        </div>
                                        <div className="history-accordion__body">
                                            {item?.operation_id}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default DigitalHistoryItem;
