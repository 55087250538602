import React, {FC, useEffect, useState} from 'react';
import '../index.scss';
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import InstallAppAds from "../../CustomsComponents/InstallAppAds";
import RightComponents from "../../RightComponents/RightComponents";
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import {useLocation, useNavigate} from "react-router-dom";
import {fetchHistoryList} from "../../../redux/actions/historyAction";
import RightBasketComponents from "../../RightBasketComponents/RightBasketComponents";

interface IProps {
    navTitle?: string | null;
    children?: any;
    goBack?: () => void;
    isApps?: boolean;
    noBack?: boolean;
    withHistory?: boolean;
}

const LayoutWithSidebar: FC<IProps> = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {language} = useAppSelector(state => state.changeLangSlice)
    const {withHistory, noBack, children, navTitle, goBack, isApps } = props;
    const {t} = useTranslation();
    const isAuth = useAppSelector((state) => state.authReducer.isAuth);
    const condition1 = !window.location.pathname.includes('home') && !window.location.pathname.includes('category') || window.location.pathname.includes('add-favorite')
    const condition2 = window.location.pathname.includes('category') && !window.location.pathname.includes('add-favorite')
    const hasUrl = `/epay/${language}/`
    const isLoc = location.pathname === `${hasUrl}home` ||
        location.pathname === `${hasUrl}history` ||
        location.pathname === `${hasUrl}message` ||
        location.pathname === `${hasUrl}favorites`;


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    const isBasketPath = location.pathname === `${hasUrl}basket`;
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isWindowWidth = windowWidth < 900
    const condition3 = window.location.pathname.includes('basket') || window.location.pathname.includes('select-payment-method')

    return (
        <div className="layout">
            {condition1 ? <div className="layout__navigation">
                <NavigationComponent onClick={goBack} title={navTitle || `${t("back")}`}/>
            </div> : null}
            {condition2 ? <Breadcrumbs/> : <></>}
            <div className={`layout__body ${isBasketPath ? 'hide__layout' : ''}`}>
                <div className="layout__content">
                    {
                        children
                    }
                </div>
                <div
                    className={`layout__app ${isAuth ? 'hide-menu' : ''}`}
                    style={{display: isLoc && isWindowWidth ? 'none' : 'flex'}}
                >
                    {isBasketPath ?
                        <RightBasketComponents/>
                        :
                        <RightComponents withHistory={withHistory}/>
                    }

                </div>
            </div>
            {isApps ? <InstallAppAds/> : null}

        </div>
    )
}

export default LayoutWithSidebar;
