import React, { FC, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {useAppSelector} from "../../hooks/redux";
import {fetchAllCategoriesIsAuth} from "../../redux/actions/CategoriesActions";
import LayoutEmpty from '../layouts/LayoutEmpty/LayoutEmpty';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import HeaderText from "../CustomsComponents/HeaderText";
import {Category, LocaleName} from "../../models/Categories";
import './index.scss';
import AutoPaymentServiceSkeleton from "../Sceletons/AutopaymentServiceSkeleton/AutopaymentServiceSkeleton";

interface IProps {

}

const arrCategories = new Array(21).fill(0);


//TODO: Sevinj move this in const file
export const imagesForCategories: any = {
    1: {
        id: 1,
        name: 'mobile',
        image: require('../../img/categories/mobile-icon.svg').default,
        bg: '#FFEAE9',
    },
    2: {
        id: 2,
        name: 'utilities',
        image: require('../../img/categories/communal-icon.svg').default,
        bg: '#F0FFD9',
    },
    3: {
        id: 3,
        name: 'internet',
        image: require('../../img/categories/network-icon.svg').default,
        bg: '#E3EEFF',
    },
    4: {
        id: 4,
        name: 'tv',
        image: require('../../img/categories/tv.svg').default,
        bg: '#E3EEFF',
    },
    5: {
        id: 5,
        name: 'online',
        image: require('../../img/categories/paymend-icon.svg').default,
        bg: '#E8FFF1',
    },
    6: {
        id: 6,
        name: 'phone',
        image: require('../../img/categories/station-mob-icon.svg').default,
        bg: '#F0FFD9',
    },
    7: {
        id: 7,
        name: 'entertainment',
        image: require('../../img/categories/inter-icon.svg').default,
        bg: '#E8FFF1',
    },
    8: {
        id: 8,
        name: 'other',
        image: require('../../img/categories/other-icon.svg').default,
        bg: '#E8FFF1',
    },
    9: {
        id: 9,
        name: 'banking',
        image: require('../../img/categories/service-icon.svg').default,
        bg: '#FFF6E4',
    },
    10: {
        id: 10,
        name: 'insurance',
        image: require('../../img/categories/insurance-icon.svg').default,
        bg: '#FFEAE9',
    },
    11: {
        id: 11,
        name: 'education',
        image: require('../../img/categories//education-icon.svg').default,
        bg: '#E3EEFF',
    },
    12: {
        id: 12,
        name: 'penalty',
        image: require('../../img/categories/penalty.svg').default,
        bg: '#F3EFFF',
    },
    13: {
        id: 13,
        name: 'software',
        image: require('../../img/categories/program-icon.svg').default,
        bg: '#F3EFFF',
    },
    14: {
        id: 14,
        name: 'transport',
        image: require('../../img/categories/transport-icon.svg').default,
        bg: '#FFF6E4',
    },
    15: {
        id: 15,
        name: 'government',
        image: require('../../img/categories/department-service-icon.svg').default,
        bg: '#F0FFD9',
    },
    16: {
        id: 16,
        name: 'online_store',
        image: require('../../img/categories/magazineNet-icon.svg').default,
        bg: '#FFEAE9',
    },
    17: {
        id: 17,
        name: 'parking',
        image: require('../../img/categories/parking.svg').default,
        bg: '#F3EFFF',
    },
    18: {
        id: 18,
        name: 'hotels_and_tourism',
        image: require('../../img/categories/hotel-icon.svg').default,
        bg: '#FFF6E4',
    },
    19: {
        id: 19,
        name: 'charity',
        image: require('../../img/categories/charity.svg').default,
        bg: '#F3EFFF',
    },
    20: {
        id: 20,
        name: 'credit_application',
        image: require('../../img/categories/union.svg').default,
        bg: '#E8FFF1',
    },
    41: {
        id: 41,
        name: 'gas_station',
        image: require('../../img/categories/gas_station.svg').default,
        bg: '#F3EFFF',
    },
    42: {
        id: 42,
        name: "Gifts",
        image: require("../../img/bonus.svg").default,
        bg: "#E8FFF1",
    },
}

const Categories: FC<IProps> = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const {isLoading, categories} = useAppSelector(state => state.categoriesReducer);
    const {language, langKey} = useAppSelector(state => state.changeLangSlice)

    const clickToCategory = (category: Category) => {
        let path; 
        if (location.state?.savedPath == "AddFavorite") {
            path = `/epay/${language}/category/${category.name}/add-favorite`;
        } else if (location.state?.savedPath == "AddRecurring") {
            path = `/epay/${language}/category/${category.name}/add-recurring`;
        } else {
            path = `/epay/${language}/category/${category.name}`;
        }
        
        navigate(
            path,
            {
                state: {
                    savedPath: location?.state?.savedPath || '',
                    category,
                    processName: 'new-payment'
                }
            }
        )
    }

    const renderCategories = () => (
        categories?.map((category: Category) => (
            <div key={category.id} className="categories__item" onClick={() => clickToCategory(category)}>
                <div
                    className="categories__image-bg"
                    style={{backgroundColor: imagesForCategories[category?.id].bg}}
                >
                    <img
                        className="categories__img"
                        src={imagesForCategories[category?.id].image}
                        alt=""
                    />
                </div>
                <div className="categories__title">
                    {category?.localName[langKey as keyof LocaleName]}
                </div>
            </div>
        ))
    )

    return (
        <LayoutEmpty
            goBack={() => navigate(-1)}
            navTitle={t("back")}
        >
            <div className="categories">
                <HeaderText title={t("categories")}/>
                {
                    isLoading
                        ?
                        <div className="categories__loading">
                            {
                                arrCategories.map((_, i) => i + 1).map((item) => (
                                    <AutoPaymentServiceSkeleton key={item}/>))
                            }
                        </div>
                        :
                        <div className="categories__items">
                            {renderCategories()}
                        </div>

                }
            </div>
        </LayoutEmpty>
    )
}

export default Categories;

