import React, {useEffect} from 'react';
import './index.scss'
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../Components/layouts/LayoutEmpty/LayoutEmpty";
import { useNavigate } from 'react-router-dom';
//@ts-ignore
import { Helmet } from 'react-helmet';

const About = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])

    return (
        <LayoutEmpty goBack={goBack}>
            <div className="about">
            <Helmet>
                <title>{t('aboutTitle')}</title>
                <meta name="description" content={t('aboutDescription') || ''}/>
                <meta name="keywords" content={t('aboutKeywords') || ''}/>
            </Helmet>
                <div className="about__left">
                    <img
                        style={{width: "100%", marginTop: "130px"}}
                        src={require("../../img/about-pic.png")}
                        alt="about"/>
                </div>
                <div className="about__right">
                    <h1 className="about__right-head">{t("aboutUs")}</h1>
                    <div
                        className="about__right-text">{t("electronicPayment")}
                    </div>
                    <div className="about__right-text">
                        {t("internetUser")}
                    </div>
                    <div className="about__right-text">
                        {t("aimProject")}
                    </div>
                    <div className="about__right-text">
                        {t("firstPayment")}
                    </div>
                    <div className="about__right-text">
                        {t("projectDeveloped") +
                            t("allPayments")}
                    </div>
                    <div className="about__right-text">
                        {t("securityConfirmed")}
                    </div>
                    <div className="about__right-text">
                        {t("becomeLeader")}
                    </div>
                </div>
            </div>
        </LayoutEmpty>
    );
};

export default About;


