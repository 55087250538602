import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./index.scss";
import Search from "../../Search/Search";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {useAppSelector} from "../../../hooks/redux";
import {fetchBonuses} from "../../../redux/actions/bonusesAction";
import {useTranslation} from "react-i18next";
import {LocaleName} from "../../../models/Categories";
import moment from "moment";
import HistoryBonusesSkeleton from "../../Sceletons/HistoryBonusesSkeleton/HistoryBonusesSkeleton";

const historyBonusMock = [1,2,3,4,5]

const HistoryDebt = () => {
  const {t} = useTranslation()
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const {bonList, isLoading} = useAppSelector((state) => state.bonList);
  const {langKey, language} = useAppSelector(state => state.changeLangSlice)

  const langBonus = localStorage.getItem("language");

  useEffect(() => {
    dispatch(fetchBonuses())
  }, [dispatch]);

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const filteredList = bonList?.items?.history.filter((item) => {
    const fullHistory = (item.localName.valueAz + item.name).toLowerCase();
    if (fullHistory.includes(searchValue.toLowerCase())) {
      return true;
    }
  });

  console.log('filteredList', filteredList);

  return (
    <div className="debt__body-right">
      <div className="debt__body-right-search">
        <div className="debt__body-right-search-head">{t("bonusHistory")}</div>
        <div className="debt__body-right-search-wrap">
          <Search
              onChangeSearchValue={onChangeSearchValue}
              inputString={searchValue}
          />
        </div>
      </div>
      <div className="debt__body-right-container">
        {isLoading ?
            (historyBonusMock.map((item) => {return <div style={{margin: "0 10px"}}><HistoryBonusesSkeleton key={item}/></div> }))
            :
            (filteredList?.length ? filteredList?.map((item, index) => (
                    <div key={index} className="debt__body-right-wrap">
                      <div className="debt__body-right-wrap-title">
                        {moment(item.insertDate).locale(`${langBonus}`).format("ll")}
                      </div>
                      <Link to={`/epay/${language}/history`} className="debt__body-right-wrap-text">
                        <div className="debt__body-right-main">
                          <div className="debt__body-right-wrap-text-img">
                            <img
                                src={`https://www.e-pul.az/epul_api/images/${item.friendlyName}.png`}
                                alt="dept-img"
                            />
                          </div>
                          <div className="debt__body-right-wrap-text-title">
                            <div className="debt__body-right-wrap-text-title-item">
                              {item.localName[langKey as keyof LocaleName]}
                            </div>
                            <div className="debt__body-right-wrap-text-title-com">
                              {item.name}
                            </div>
                          </div>
                          <div className="debt__body-right-wrap-text-wrap">
                            <div
                                className="debt__body-right-wrap-text-title-price"
                            >
                              <div className="debt__body-right-wrap-text-title-price-item">
                                {item.bonusAmount}
                              </div>
                              <img
                                  src={require("../../../img/red-gift.svg").default}
                                  alt="gift"
                              />
                            </div>
                            <div className="debt__body-right-wrap-text-title-money">
                              {`${item?.amount / 100} AZN`}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                )) : (<div className="debt__body-not-found"> {t("infoIsNotFound")}</div>)
            )}
      </div>
    </div>
  );
};

export default HistoryDebt;
