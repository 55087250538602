import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInvitationLink } from "../../models/InvitationList";
import client from "../../axios";

const token = localStorage.getItem("token");

export const generateInvitationLinkFetch = createAsyncThunk(
  "invitationLink/generateInvitationLinkFetch",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      const resInvitationLink = await client.post<IInvitationLink>(
        "user/generate_invitation_link/" + (sid || localStorage.getItem("token"))
      );
      return resInvitationLink.data;
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);
