import { createBrowserHistory } from "history";
import {toast} from "react-toastify";
import moment from "moment";
import {OptionValue} from "./models/OptionValue";
import {IAutoOrderField} from "./models/AutoPayments";

export const BonusToCash = (val?: any) => {
  if (!val) {
      return 0;
  }

  let koef;
  let base;
  let start;

  if (0 <= val && val < 40000) {koef = 400; base = 0; start = 0;}
  else if (40000 <= val && val < 75000) {koef = 350; base = 100; start = 40000;}
  else if (75000 <= val && val < 145000) {koef = (233 + 1/3); base = 200; start = 75000;}
  else if (145000 <= val && val < 200000) {koef = (183 + 1/3); base = 500; start = 145000;}
  else if (200000 <= val && val < 400000) {koef = (166 + 2/3); base = 800; start = 200000;}
  else if (400000 <= val && val < 750000) {koef = 175; base = 2000; start = 400000;}
  else if (750000 <= val && val < 1500000) {koef = 125; base = 4000; start = 750000;}
  else if (1500000 <= val && val < 2900000) {koef = 140; base = 10000; start = 1500000;}
  else if (2900000 <= val) {koef = 145; base = 0; start = 0;}
  else
  {
      return 0;
  }
  return Math.round(((val - start) / koef) + base) / 100.0;
}

export const getAzercellValue = (data: any, dataArr: any, index: number) => {
  if (index === 1) {
    return dataArr[index - 1].value + data.value;
  }
};

export const getPrice = (amount: number | any): string => {
  return (amount / 100).toFixed(2);
};

export const notify = (msg: string, success?: boolean) => {
  if(success) {
    toast.success(
        msg,
        {
          position: toast.POSITION.TOP_CENTER,
        }
    );
  } else {
    toast.error(
        msg,
        {
          position: toast.POSITION.TOP_CENTER,
        }
    );
  }

}

export const history = createBrowserHistory();

export const addRealMonth = (d: any) => {
  const fm = moment(d).add(1, 'M');
  const fmEnd = moment(fm).endOf('month');
  return d.date() !== fm.date() && fm.isSame(fmEnd.format('DD.MM.YYYY')) ? fm.add(1, 'd') : fm;
}

export const getOptionValues = (t: (key: string) => string): OptionValue[] => [
  {
    type: '1',
    value: `${t('daily')}`,
  },
  {
    type: '2',
    value: `${t('weekly')}`,
  },
  {
    type: '3',
    value: `${t('monthly')}`,
  },
];

export const getOrderFields = (fields: IAutoOrderField[]): string => {
  return fields.map(field => field.value).join(' ');
};

export const getResultPaymentUrl = (language: string): string => {
  return `${window.location.origin}/epay/${language}/result/payment`;
};
