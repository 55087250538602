import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from "./slices/auth";
import categoriesReducer from "./slices/categoriesSlice";
import product from "./slices/productCatalogueSlice";
import call from "./slices/callWithSlice";
import messages from "./slices/messageSlice";
import language from "./reducers/language";
import favList from "./slices/favoritesSlice";
import messagesRead from "./slices/messagesReadSlice";
import wallet from "./slices/walletSlice";
import walletList from "./slices/walletListSlice";
import bonList from "./slices/bonusesSlice";
import bannerList from "./slices/bannerSlice";
import debtsList from "./slices/debtsSlice";
import service from "./reducers/service";
import bonusServices from "./reducers/bonusServices";
import loading from "./reducers/loading";
import autoPaymentList from "./slices/autoPaymentsSlice";
import bonusStore from "./slices/bonusStoreSlice";
import bonusCategory from "./slices/bonusCategorySlice";
import popularProduct from "./slices/popularProductSlice";
import settingProfile from "./slices/profileSlice";
import profilePhoneNumber from "./slices/profilePhoneSlice";
import profileEmail from "./slices/profileEmailSlice";
import masterPassReducer from "./slices/masterpassSlice";
import card2cardReducer from "./slices/card2cardSlice";
import cardsReducer from "./slices/cardsSlice";
import generateInvitation from "./slices/generateInvitationLink"
import getUserPersonalLink from "./slices/getUserPersonalLinkSlice"
import historyList from "./slices/historyListSlice"
import authAsanReducer from "./reducers/authAsanReducer";
import changeLangSlice from "./reducers/changeLangSlice";
import browserToken from './slices/browserTokenSlice'
import tokenize from './slices/tokenizeSlice';
import habStorageSlice from './slices/habStorageSlice';

const rootReducer = combineReducers({
    authReducer,
    product,
    call,
    categoriesReducer,
    masterPassReducer,
    card2cardReducer,
    cardsReducer,
    messages,
    favList,
    messagesRead,
    wallet,
    walletList,
    bonList,
    bannerList,
    debtsList,
    autoPaymentList,
    loading,
    language,
    bonusStore,
    service,
    bonusCategory,
    popularProduct,
    settingProfile,
    profilePhoneNumber,
    profileEmail,
    generateInvitation,
    historyList,
    getUserPersonalLink,
    changeLangSlice,
    authAsanReducer,
    bonusServices,
    browserToken,
    tokenize,
    habStorageSlice
})

export const setupStore = () => {
    return configureStore({ reducer: rootReducer })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
