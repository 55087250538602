import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { GuestActionCreators } from "../../../redux/reducers/guest/action-creators";
import { getResultPaymentUrl, notify } from "../../../helper";
import { fetchGetCardToken } from "../../../redux/actions/habStorageAction";
import { toQueryParams } from "../../../utils/query";
import client from "../../../axios";
import useWalletServiceData from "./useWalletServiceData";
import usePayByQrServiceData from "./usePayByQrServiceData";
import { fetchTokenize } from "../../../redux/actions/tokenize";
import { createHubCardData } from "../../../utils/hubFunctions";
import { t } from "i18next";
import { ServiceData } from "../../MasterPass/MasterPassForms";
import { IInstrument } from "../../CardsBox/CardsBox";
import useLoadData from "./useLoadData";

const useTransfer = (amountValue: string, instrument: any, setBtnLoading: (loading: boolean) => void, language: string) => {
  const dispatch = useAppDispatch();
  const { browserToken } = useAppSelector((state: { browserToken: any; }) => state.browserToken);
  const { user } = useAppSelector((state: { authReducer: any; }) => state.authReducer);
  const { c2qr, c2w } = useAppSelector((state: { wallet: any; }) => state.wallet);
  const { isHubSolutionPaymentMethod } = useLoadData(
    c2w,
    language,
  );
  const getPayByQrServiceData = usePayByQrServiceData(amountValue, user, c2qr, language);
  const getWalletServiceData = useWalletServiceData(amountValue, c2w, language);
  const { forms } = useAppSelector((state: { masterPassReducer: any; }) => state.masterPassReducer);

  const checkNewCard = () => {
    return true;
  };

  const payWithNewCard = async (serviceData: ServiceData, instrument: IInstrument) => {
    if(!isHubSolutionPaymentMethod) {
        const payWithNewCard = async () => {
            const {newCardData} = instrument;

            if (!newCardData) {
                return;
            }

            const cardData: any = {
                cvv: newCardData.cvc,
                year: newCardData.expiryDate.substring(4, 7).trim(),
                month: newCardData.expiryDate.substring(0, 3).trim(),
                cardNumber: newCardData.cardNumber,
            };
            
            try {
                forms?.payWithNewCard(
                    {
                        year: cardData.year, month: cardData.month, cardNumber: cardData.cardNumber,
                        cvv: cardData.cvv,
                        cardName: newCardData.cardName,
                    },
                    serviceData!
                );
            } catch (e) {
                notify(`${t("error")}`, false);
            }
        };

        payWithNewCard()

        return
    }

    const {newCardData} = instrument;

    try {
        const { payload: cardToken } = await dispatch(
            fetchTokenize({
                body: {
                    ...createHubCardData(newCardData),
                },
                browserToken: browserToken,
            }),
        )

        let body: any = {
            returnUrl: getResultPaymentUrl(language),
            sessionId: localStorage.getItem('token'),
            serviceId: serviceData.serviceId,
            amount: Number(amountValue),
            fields: serviceData.fields,
            selectedGroupId: serviceData.selectedGroupId || 0,
            bindingId: cardToken,
            subName: serviceData.subName,
            subVal: serviceData.subVal,
            desc: '',
            lng: language,
            tokenType: 4,
        }
        setBtnLoading(true)
        const res = await client.post(
            `payment/do_pay_with_group_id/${localStorage.getItem(
            'token',
            )}?${toQueryParams(body)}`,
            {},
            {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            },
        )
        setBtnLoading(false)

        if(res.data.formUrl) {
            window.location.href = res.data.formUrl
        }
    } catch (e) {
        notify(`${t("error")}`, false);
    }
};

  const transfer = async () => {
    setBtnLoading(true);
    try {
      if (instrument?.ruCard) {
        const serviceData = getPayByQrServiceData();
        if (!serviceData) {
          return;
        }
        const url = await dispatch(GuestActionCreators.doGuestPay(
          serviceData?.serviceId,
          Number(amountValue),
          serviceData?.fields,
          false,
          serviceData?.subName, serviceData?.subVal,
          0,
          getResultPaymentUrl(language),
          '',
          "RUB"
        ));
        if (url) {
          window.location = url;
        }
        return;
      }

      const serviceData = getWalletServiceData();

      let pickerCardName = instrument?.card?.name || 'newCard';
      if (instrument?.wallet) {
        pickerCardName = 'wallet';
      }
      if (instrument?.isNewCard) {
        if (!checkNewCard() || !instrument.newCardData) {
          return;
        }
        await payWithNewCard(serviceData!, instrument);
      } else {
        if (instrument?.card?.isMasterPassMember) {
          forms?.payWithSavedCard(pickerCardName, serviceData!)
        } else {
          const { payload: cardToken } = await dispatch(
            fetchGetCardToken({
              body: {
                CardId: instrument?.card?.id,
              },
              browserToken: browserToken,
            }),
          )

          let body: any = {
            returnUrl: getResultPaymentUrl(language),
            sessionId: localStorage.getItem('token'),
            amount: Number(amountValue),
            bindingId: cardToken,
            desc: '',
            lng: language,
            tokenType: 4,
            ...getWalletServiceData()
          }

          const res = await client.post(
            `payment/do_pay_with_group_id/${localStorage.getItem(
              'token',
            )}?${toQueryParams(body)}`,
            {},
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          )

          if(res.data.formUrl) {
            window.location.href = res.data.formUrl
          }
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setBtnLoading(false)
    }
  };

  return transfer;
};

export default useTransfer;
