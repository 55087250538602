import React, {FC, useEffect, useState} from 'react';
import "./index.scss"
import AddCheckboxIn from "../../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { IItems} from "../../../models/AutoPayments";
import {AppDispatch} from "../../../redux/store";
import { useDispatch } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useAppSelector} from "../../../hooks/redux";
import {useTranslation} from "react-i18next";

type Props = {
    onClick?: (event: MouseEvent) => void,
    getPeriodData?: any;
    isEdit?: boolean;
    item?: IItems;
    isDisabled?: boolean;
    cb?: (data: any) => void;
    editItemData?: {
        id: number,
        repeatingType: string,
        periods: string[],
    };
}

const weekDays = [
    {
        label: 'Понедельник',
        value: 'MON',
    },
    {
        label: 'Вторник',
        value: 'TUE',
    },
    {
        label: 'Среда',
        value: 'WED',
    },
    {
        label:  'Четверг',
        value: 'THRUS',
    },
    {
        label: 'Пятница',
        value: 'FRI',
    },
    {
        label: 'Суббота',
        value: 'SAT',
    },
    {
        label: 'Воскресенье',
        value: 'SUN',
    },
]


const monthDays: string[] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','25','26','27','28','29','30','31']


const optionValues: any = [
    {
        type: '1',
        value: 'Ежедневно',
    },
    {
        type: '2',
        value: 'Еженедельно',
    },
    {
        type: '3',
        value: 'Ежемесячно',
    },
]

const PaymentPeriod: FC<Props> = ({getPeriodData, isEdit, item, isDisabled, cb, editItemData}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch<AppDispatch>();

    const [optionValue, setOptionValue] = useState({
        type: '3',
        value: 'Ежемесячно'
    });


    useEffect(()=> {
        const findOption = optionValues?.find((option: any) => option.type === editItemData?.repeatingType) || {
            type: '3',
            value: 'Ежемесячно',
        }


        setOptionValue(findOption)

    },[])

    const itemWeekDays = editItemData?.repeatingType === '2' ? editItemData.periods : [];
    const editMonthDay = editItemData?.repeatingType === '3' ? editItemData.periods.toString() : String(new Date().getDate());

    const {cards} = useAppSelector(state => state.cardsReducer);

    const value = item ? editMonthDay: String(new Date().getDate());


    const [selectMonthDay, setSelectMonthDay] = useState(value)
    const [deleteModalActive, setDeleteModalActive] = useState(false);


    const [isSelectOPen, setIsSelectOpen] = useState(false);
    const [isMonthSelectOpen, setIsMonthSelectOpen] = useState(false);
    let [selectedWeekDays, setSelectedWeekDays] = useState<string[]>(itemWeekDays);

    const periodData = {
        repeatingType: optionValue.type,
        periods: optionValue.type === '1' ? ['0'] : optionValue.type === '2' ? selectedWeekDays : [selectMonthDay]
    }

    function addRealMonth(d: any) {
        const fm = moment(d).add(1, 'M');
        const fmEnd = moment(fm).endOf('month');
        return d.date() != fm.date() && fm.isSame(fmEnd.format('DD.MM.YYYY')) ? fm.add(1, 'd') : fm;
    }
    const nextMonth = addRealMonth(moment().set('date', Number(value)));


   useEffect(() => {
       if(cb) {
           cb(periodData);
       }
   }, [ selectedWeekDays, selectMonthDay, optionValue.type])

    const getCheckValue = (value: string) => {
        if(selectedWeekDays?.includes(value)) {
            setSelectedWeekDays(selectedWeekDays?.filter((item: string) => item !== value));
        } else {
            setSelectedWeekDays([...selectedWeekDays, value]);
        }
    }

    const toggleDeleteModal = () => {
        if(deleteModalActive) {
            setDeleteModalActive(false);
        } else{
            setDeleteModalActive(true);
        }
    }

    const toggleSelect = () => {
        if(isSelectOPen) {
            setIsSelectOpen(false)
        } else {
            setIsSelectOpen(true)
        }
    }

    const setSelectedValue = (data: any) => {
        setOptionValue(data);
        setIsSelectOpen(false);
    }

    const setSelectedMonthValue = (data: string) => {
        setSelectMonthDay(data);
        setIsMonthSelectOpen(false);
    }


    return (
        <div className="payment-period">
            <div className="payment-period__period-box">
                <div className="payment-period__period">
                    <div className="payment-period__img">
                        <img  className="payment-period__icon" src={require("../../../img/repeat-icon.svg").default} alt="icon"/>
                    </div>
                    <div className="payment-period__title">{t("frequency")}</div>
                    <ClickAwayListener onClickAway={() => setIsSelectOpen(false)}>
                        <div className="payment-period__custom-select">
                            <div className="payment-period__select-header" onClick={() => toggleSelect()}>
                                <div
                                    className="payment-period__select-title"
                                >
                                    {optionValue.value}
                                </div>
                                <div className="payment-period__select-img" >
                                    <img  className="payment-period__icon" src={require('../../../img/drop.svg').default} alt="icon"/>
                                </div>
                            </div>
                            {
                                isSelectOPen &&
                                <div className="payment-period__option-box">
                                    <div className="payment-period__option-content">
                                        {
                                            optionValues?.map((option: any) => (
                                                <div
                                                    className="payment-period__option"
                                                    onClick={() => setSelectedValue(option)}
                                                    style={optionValue.value === option.value ? {color: '#EF5C27'} : {}}
                                                >
                                                    {option.value}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </ClickAwayListener>
                </div>
                {
                    optionValue.type === '3' &&
                    <div className="payment-period__period">
                        <div className="payment-period__img">
                            <img  className="payment-period__icon" src={require("../../../img/date-picker-icon.svg").default} alt="icon"/>
                        </div>
                        <div className="payment-period__title">
                            Дата
                            <ClickAwayListener onClickAway={() => setIsMonthSelectOpen(false)}>
                                <div className="payment-period__custom-select">
                                    <div className="payment-period__select-header" onClick={() => setIsMonthSelectOpen(!isMonthSelectOpen)}>
                                        <div className="payment-period__select-title" >{selectMonthDay}</div>
                                        <div className="payment-period__select-img" >
                                            <img  className="payment-period__icon" src={require('../../../img/drop.svg').default} alt="icon"/>
                                        </div>
                                    </div>
                                    {
                                        isMonthSelectOpen &&
                                        <div className="payment-period__option-box">
                                            <div className="payment-period__option-content">
                                                {
                                                    monthDays?.map((day: string) => (
                                                        <div
                                                            className="payment-period__option"
                                                            onClick={() => {
                                                                setSelectedMonthValue(day)
                                                            }}
                                                            style={selectMonthDay === day ? {color: '#EF5C27'} : {}}
                                                        >
                                                            {day}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                }
                {
                    optionValue.type === '3' &&
                    <div className="payment-period__near-date">
                        {`${t("nearestPayment")} - ${moment(nextMonth).format('DD.MM.YYYY')}`}
                    </div>
                }
                {
                    optionValue.type === '2' &&
                    <div className='payment-period__week-days'>
                        {weekDays?.map((item: any) =>
                            <div key={item.value} style={{margin: '5px 0'}}>
                                <AddCheckboxIn
                                    title={item.label}
                                    onClick={() => getCheckValue(item.value)}
                                    isChecked={selectedWeekDays?.includes(item?.value)}
                                />
                            </div>
                        )}
                    </div>
                }

            </div>
        </div>);
};

export default PaymentPeriod;
