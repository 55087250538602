import React from "react";

import useAdScript from "../../../hooks/useAdScript";
//@ts-ignore
import { Helmet } from "react-helmet";

const vtbBanner = require("../../../img/vtbNew.jfif");
const vtbBanner768 = require("../../../img/vtb768.jfif");

const electroBanner = require("../../../img/electroshop.jfif");
const electroBanner768 = require("../../../img/electro768.jfif");

const rabiteBanner = require("../../../img/rabitaNew.jpg");
const rabiteBanner768 = require("../../../img/rabitaNewMob.jpg");

const kapitalBanner = require("../../../img/kapital-bank-28-10.png");
const kapitalBanner768 = require("../../../img/kapital-bank-mob-28-10.png");

const AdvertisingBanner = () => {
  return (
    <>
      <div className="banner-wrap">
        <div className="slideshow">
          <div className="slideshow-item">
            <a
              target="_blank"
              href="https://onlinekredit.vtb.az/cash-loan/?utm_source=Epul_Desktop_banner_landing_24%2F01%2F2024&utm_medium=Epul_Desktop_banner_landing_24%2F01%2F2024&utm_campaign=Epul_Desktop_banner_landing_24%2F01%2F2024"
              rel="noreferrer"
            >
              <img src={vtbBanner} alt="" />
            </a>
          </div>
          <div className="slideshow-item">
            <a
              target="_blank"
              href="https://www.electroshop.az/?utm_source=EPUL+APP&utm_medium=CPC&utm_campaign=APP+BANNER"
              rel="noreferrer"
            >
              <img src={electroBanner} alt="" />
            </a>
          </div>

          <div className="slideshow-item">
            <a
              target="_blank"
              href="https://www.rabitabank.com/ferdi/kredit-sifarisi-kreditler/nagd-pul-krediti#calc"
              rel="noopener noreferrer"
            >
              <img src={rabiteBanner} alt="" />
            </a>
          </div>
          <div className="slideshow-item">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ccl.kapitalbank.az/az/order/GTKR?utm_medium=banner-desktop&utm_source=e-pul&utm_campaign=0-CNV-gundelik-telebat-krediti-10.9-faiz-AZ&utm_content=banner-white"
            >
              <img src={kapitalBanner} alt="" />
            </a>
          </div>
          {/*<div className="slideshow-item">*/}
          {/*  <ins*/}
          {/*    data-revive-zoneid="85"*/}
          {/*    data-revive-id="144e1bb6fa796840b2323709bbb2f595"*/}
          {/*  ></ins>*/}

          {/*  <Helmet>*/}
          {/*    {useAdScript("https://ads2.imv.az/www/delivery/asyncjs.php")}*/}
          {/*  </Helmet>*/}
          {/*</div>*/}

          {/*<div className='slideshow-item'>*/}
          {/*    <a*/}
          {/*        target='_blank'*/}
          {/*        href='https://www.flypgs.com/en/stay-tuned-for-incredible-summer-sales?v=273&utm_source=e-pul.az&utm_medium=display&utm_campaign=pegasus&utm_content=tenancy-1140x120'*/}
          {/*        rel='noreferrer'*/}
          {/*    >*/}
          {/*        <img src={flypgsBanner} alt=''/>*/}
          {/*    </a>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className="banner-wrap768">
        <div className="slideshow">
          <div className="slideshow-item">
            <a
              target="_blank"
              href="https://onlinekredit.vtb.az/cash-loan/?utm_source=Epul_Desktop_banner_landing_24%2F01%2F2024&utm_medium=Epul_Desktop_banner_landing_24%2F01%2F2024&utm_campaign=Epul_Desktop_banner_landing_24%2F01%2F2024"
              rel="noreferrer"
            >
              <img src={vtbBanner768} alt="" />
            </a>
          </div>
          <div className="slideshow-item">
            <a
              target="_blank"
              href="https://www.electroshop.az/?utm_source=EPUL+APP&utm_medium=CPC&utm_campaign=APP+BANNER"
              rel="noreferrer"
            >
              <img src={electroBanner768} alt="" />
            </a>
          </div>
          <div className="slideshow-item">
            <a
              target="_blank"
              aria-label="rabita bank"
              href="https://www.rabitabank.com/ferdi/kredit-sifarisi-kreditler/nagd-pul-krediti#calc"
              rel="noopener noreferrer"
            >
              <img src={rabiteBanner768} alt="" />
            </a>
          </div>
          <div className="slideshow-item">
            <a
              target="_blank"
              href="https://ccl.kapitalbank.az/az/order/GTKR?utm_medium=banner-mobile&utm_source=e-pul&utm_campaign=0-CNV-gundelik-telebat-krediti-10.9-faiz-AZ&utm_content=banner-white"
              rel="noreferrer"
            >
              <img src={kapitalBanner768} alt="" />
            </a>
          </div>

          {/*<div className='slideshow-item'>*/}
          {/*    <a*/}
          {/*        target='_blank'*/}
          {/*        href='https://www.flypgs.com/en/stay-tuned-for-incredible-summer-sales?v=273&utm_source=e-pul.az&utm_medium=display&utm_campaign=pegasus&utm_content=tenancy-1140x120'*/}
          {/*        rel='noreferrer'*/}
          {/*    >*/}
          {/*        <img src={flypgsBanner768} alt=''/>*/}
          {/*    </a>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default AdvertisingBanner;
