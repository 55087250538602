import "./index.scss";

import { useForm } from "react-hook-form";
import {useTransition} from "react";
import {useTranslation} from "react-i18next";

const reg = new RegExp("^[0-9]+$");

const FormattedInputs = ({ inputPhone = false, inputNumber = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const {t} = useTranslation();

  const onSubmit = (data) => {
    reset();
  };

  return (
    <div className="container pt-5">
      <div className="row justify-content-sm-center pt-5">
        {inputPhone && (
          <div className="wrap-number">
            <div className="prefiks">
              <span>{"+994"}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  // style{{width}}
                  maxLength={9}
                  minLength={8}
                  placeholder="000000000"
                  type="text"
                  className={`form-control ${errors.phone && "invalid"}`}
                  {...register("phone", {
                    required: "",
                    pattern: {
                      value:
                        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{2})[-. ]*(\d{1})(?: *x(\d+))?\s*$/,
                      message: `${t('errorPhone')}`,
                    },
                  })}
                  onKeyUp={() => {
                    trigger("phone");
                  }}
                />
                {errors.phone && (
                  <small className="text-danger">{errors.phone.message}</small>
                )}
              </div>
            </form>
          </div>
        )}

        {inputNumber && (
          <div className="wrap-number">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  // style{{width}}
                  maxLength={9}
                  placeholder=""
                  type="text"
                  className={`form-control ${errors.phone && "invalid"}`}
                  {...register("phone", {
                    required: "",
                    pattern: {
                      value: reg,
                      message: `${t('errorAmount')}`,
                    },
                  })}
                  onKeyUp={() => {
                    trigger("phone");
                  }}
                />
                {errors.phone && (
                  <small className="text-danger">{errors.phone.message}</small>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormattedInputs;
