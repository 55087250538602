import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { changePhoneSendCode } from "../../../redux/actions/profileActions";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../../redux/actions/authActions";

const OtpCode = ({newPhone, closeOTPModal }) => {
  const {t} = useTranslation();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const sid = localStorage.getItem("token")
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOtpChange = (e, index) => {
    if (!/^\d*$/.test(e.target.value)) return;

    const newOtp = [...otp];
    newOtp[index] = e.target.value;

    setOtp(newOtp);

    if (index < 3 && e.target.value) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const isOtpFilled = otp.every((digit) => digit !== "");

  const confirmOTP = async () => {
    try {
      const result = await dispatch(changePhoneSendCode(sid, newPhone, otp.join("")))
      if(result !== false) {
        closeOTPModal();
        dispatch(signOut());
        navigate(`/epay/${language}/home`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="otp-container">
      <h1>{t("enterCode")}</h1>
      <div className="otp-inputs">
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            onChange={(e) => handleOtpChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={inputRefs[index]}
            maxLength="1"
          />
        ))}
      </div>
      <button 
        onClick={() => confirmOTP()} 
        disabled={!isOtpFilled}
        className={!isOtpFilled ? 'disabled' : ''}
      >
        {t("confirm")}
      </button>
    </div>
  );
}

export default OtpCode;