import React from "react"
import ContentLoader from "react-content-loader"

const AdsSkeletonFavorites = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 270 400"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="6" y="13" rx="0" ry="0" width="5" height="373" />
        <rect x="9" y="382" rx="0" ry="0" width="260" height="5" />
        <rect x="266" y="11" rx="0" ry="0" width="5" height="373" />
        <rect x="8" y="10" rx="0" ry="0" width="260" height="5" />
        <rect x="23" y="25" rx="0" ry="0" width="230" height="234" />
        <rect x="26" y="278" rx="0" ry="0" width="230" height="30" />
        <rect x="80" y="326" rx="0" ry="0" width="114" height="30" />
    </ContentLoader>
)

export default AdsSkeletonFavorites
