import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import './index.scss'
import { baseURL } from "../../http";
import {toast} from "react-toastify";
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    WhatsappShareButton,
  } from "react-share";

const smsIcon = require('../../img/message-icon.svg').default;
const facebookIcon = require('../../img/facebook.png');
const whatsappIcon = require('../../img/whatsapp.svg').default;
const telegramIcon = require('../../img/telegram.svg').default;
const emailIcon = require('../../img/email.svg').default;

const InvoiceQR = ({invoiceUnique}) => {
    const {t} = useTranslation()
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const link = `${baseURL.replace('/epul_api/', '')}/epay/${language}/invoice/`;
    const navigate = useNavigate();

    const goToLink = async () => {
        navigate(`/epay/${language}/invoice/${invoiceUnique}`)
    };

    const copy = async () => {
        await navigator.clipboard.writeText(`${link}${invoiceUnique}`);
        toast(`${t("successfullyCopied")}`);
    };

    return (
      <div className="qr-modal">
        <div className="qr-modal__content">
          <QRCode size={230} value={`${link}${invoiceUnique}`} />
        </div>
        <div className="qr-modal__footer">
          <div className="qr-modal__links-title">
            {t("yourLink")}
          </div>
          <div className="qr-modal__copy">
            <div className="qr-modal__copy-link">
                <Link to={`/epay/${language}/invoice/${invoiceUnique}`}>{t('replenish')}</Link>
                {/* {`${link}/${invoiceUnique}`} */}
            </div>
            <div className="qr-modal__copy-img" onClick={goToLink}>
              <img src={require("../../img/link-external.svg").default} alt=""/>
            </div>
            <div className="qr-modal__copy-img" onClick={copy}>
              <img src={require("../../img/copy.svg").default} alt=""/>
            </div>
          </div>
          <div className="qr-modal__links">
            <div className="qr-modal__links-title">
              {t("shareLink")}
            </div>
            <div className="qr-modal__links-box">
              <div className="qr-modal__link">
                <div className="qr-modal__link-img">
                  <img src={smsIcon} alt=""/>
                </div>
                <div className="qr-modal__link-title">SMS</div>
              </div>
              <div className="qr-modal__link">
                <div className="qr-modal__link-img">
                  <EmailShareButton
                      children={<img src={emailIcon} alt=""/>}
                      url={`${link}${invoiceUnique}`}
                  />
                </div>
                <div className="qr-modal__link-title">Email</div>
              </div>
              <div className="qr-modal__link">
                <div className="qr-modal__link-img">
                  <WhatsappShareButton
                      children={<img src={whatsappIcon} alt=""/>}
                      url={`${link}${invoiceUnique}`}
                    />
                </div>
                <div className="qr-modal__link-title">Whatsapp</div>
              </div>
              <div className="qr-modal__link">
                <div className="qr-modal__link-img">
                  <TelegramShareButton
                      children={ <img src={telegramIcon} alt=""/>}
                      url={`${link}${invoiceUnique}`}
                  />
                </div>
                <div className="qr-modal__link-title">Telegram</div>
              </div>
              <div className="qr-modal__link">
                <div className="qr-modal__link-img">
                  <FacebookShareButton
                      children={ <img src={facebookIcon} alt=""/>}
                      url={`${link}${invoiceUnique}`}
                  />
                </div>
                <div className="qr-modal__link-title">Facebook</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
};

export default InvoiceQR;