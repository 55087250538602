import React, { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment/moment";
import AddCheckboxIn from "../../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import './index.scss';
import {  monthDays, weekDays, } from '../consts';
import { IAutoOrderPeriods, IItems } from "../../../models/AutoPayments";
import {addRealMonth, getOptionValues} from "../../../helper";

interface IProps {
    cb: (data: any, periods: string[], time: any) => void;
    recurring?: IItems;
    clock?: any;
}

const RecurringPeriod: FC<IProps> = ({cb, recurring, clock}) => {
    const {t} = useTranslation();
    const optionValues = getOptionValues(t);
    const editMonthDay = useCallback(() => {
        if (recurring?.repeatingType === 3) {
            const day = new Date();
            day.setDate(Number(recurring?.periods?.autoOrderPeriods[0].day))
            return String(day.getDate())
        } else {
            String(new Date().getDate())
        }
    }, [recurring?.periods?.autoOrderPeriods, recurring?.repeatingType])

    const [optionValue, setOptionValue] = useState<any>({
        type: '3',
        value: `${t("monthly")}`
    });

    const [isSelectOPen, setIsSelectOpen] = useState(false);
    const [selectMonthDay, setSelectMonthDay] = useState<any>('')
    const [selectedWeekDays, setSelectedWeekDays] = useState<string[]>([]);
    const [isMonthSelectOpen, setIsMonthSelectOpen] = useState(false);
    const [nearDate, setNearDate] = useState('');
    const now = new Date();
    const currentTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const [time, setTime] = useState(clock || currentTime);
    const lang = localStorage.getItem('language')
    useEffect(() => {
        const value = (recurring && optionValue.type === recurring.repeatingType.toString()) ? editMonthDay() : String(new Date().getDate());
        setSelectMonthDay(value)
    }, [editMonthDay, optionValue.type, recurring])

    const test = useCallback(() => {
        const currentDay = moment().format('DD');
        let date = null;
        const numberCurrentDate = Number(currentDay);
        const numberSelectDay = Number(selectMonthDay)

        if(numberSelectDay > numberCurrentDate) {
            return moment().set('date', Number(selectMonthDay)).format('DD.MM.YYYY');
        }
        if (numberSelectDay < numberCurrentDate) {
            date = addRealMonth(moment().set('date', Number(selectMonthDay)));
            return moment(date).format('DD.MM.YYYY');
        } else {
            date = addRealMonth(moment().set('date', Number(selectMonthDay)));
            return moment(date).format('DD.MM.YYYY');
        }
    }, [selectMonthDay]);

    useEffect(() => {
        const date = test();
        setNearDate(date)
    }, [selectMonthDay, test])

    const getCheckValue = (value: string) => {
        if(selectedWeekDays?.includes(value)) {
            setSelectedWeekDays(selectedWeekDays?.filter((item: string) => item !== value));
        } else {
            setSelectedWeekDays([...selectedWeekDays, value]);
        }
    }

    const periods: any = useCallback(() => {
        return optionValue.type === '1' ? ['0'] : optionValue.type === '2' ? selectedWeekDays : [selectMonthDay]
    }, [optionValue.type, selectMonthDay, selectedWeekDays])
    const repeatingType = optionValue.type;

    useEffect(() => {
        if(cb) {
            cb(repeatingType, periods(), time)
        }
    }, [optionValue.type, periods, repeatingType, time])

    useEffect(() => {
        if(recurring?.repeatingType === 1) {
            setOptionValue({
                type: '1',
                value: `${t("daily")}`
            })
        } else if(recurring?.repeatingType === 2) {
            setOptionValue({
                type: '2',
                value: `${t("weekly")}`,
            })
            const weekDays = recurring?.periods?.autoOrderPeriods?.map((period:IAutoOrderPeriods) => period.day)
            const time = recurring?.periods?.autoOrderPeriods?.map((period:IAutoOrderPeriods) => period.atClock)
            setSelectedWeekDays(weekDays)
            setTime(time)
        } else {
            setOptionValue({
                type: '3',
                value: `${t("monthly")}`
            })
        }
    }, [recurring, lang])

    const handleTimeChange = (event: any) => {
        setTime(event.target.value);
    };    

    return(
            <div className="recurring-period">
                <div className="recurring-period__period">
                    <div className="recurring-period__img">
                        <img  className="recurring-period__icon" src={require("../../../img/repeat-icon.svg").default} alt="icon"/>
                    </div>
                    <div className="recurring-period__period-title">{t("frequency")}</div>
                    <ClickAwayListener onClickAway={() => setIsSelectOpen(false)}>
                        <div className="recurring-period__custom-select">
                            <div className="recurring-period__select-header" onClick={() => setIsSelectOpen((pr) => !pr)}>
                                <div
                                    className="recurring-period__select-title"
                                >
                                    {optionValue.value}
                                </div>
                                <div className="recurring-period__select-img" >
                                    <img  className="recurring-period__icon" src={require('../../../img/drop.svg').default} alt="icon"/>
                                </div>
                            </div>
                            {
                                isSelectOPen &&
                                <div className="recurring-period__option-box">
                                    <div className="recurring-period__option-content">
                                        {
                                            optionValues?.map((option: any) => (
                                                <div
                                                    className="recurring-period__option"
                                                    onClick={() => {
                                                        setOptionValue(option)
                                                        setIsSelectOpen(false)
                                                    }}
                                                    style={optionValue.value === option.value ? {color: '#EF5C27'} : {}}
                                                >
                                                    {option.value}
                                                </div>)
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </ClickAwayListener>
                </div>
                {
                    optionValue.type === '3' &&
                    <div className="recurring-period__period">
                        <div className="recurring-period__img">
                            <img className="recurring-period__icon"
                                 src={require("../../../img/date-picker-icon.svg").default} alt="icon"/>
                        </div>
                        <div className="recurring-period__period-title">
                            {t("date")}
                            <ClickAwayListener onClickAway={() => setIsMonthSelectOpen(false)}>
                                <div className="recurring-period__custom-select">
                                    <div className="recurring-period__select-header" onClick={() => setIsMonthSelectOpen(!isMonthSelectOpen)}>
                                        <div className="recurring-period__select-title" >{selectMonthDay}</div>
                                        <div className="recurring-period__select-img" >
                                            <img  className="recurring-period__icon" src={require('../../../img/drop.svg').default} alt="icon"/>
                                        </div>
                                    </div>
                                    {
                                        isMonthSelectOpen &&
                                        <div className="recurring-period__option-box">
                                            <div className="recurring-period__option-content">
                                                {
                                                    monthDays?.map((day: string) => (
                                                        <div
                                                            className="recurring-period__option"
                                                            onClick={() => {
                                                                setSelectMonthDay(day);
                                                                setIsMonthSelectOpen(false);
                                                            }}
                                                            style={selectMonthDay === day ? {color: '#EF5C27'} : {}}
                                                        >
                                                            {day}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                }
                <div>
                    <label htmlFor="timePicker">{t("time")+":"}</label>
                    <input
                        id="timePicker"
                        type="time"
                        value={time}
                        onChange={handleTimeChange}
                        style={{ color: '#EF5C27', width: "90px" }}
                    />
                </div>
                {
                    optionValue.type === '3' &&
                    <div className="recurring-period__near-date">
                        {`${t("nearestPayment")} - ${nearDate} ${time} `}
                    </div>
                }
                {
                    optionValue.type === '2' &&
                    <div className='recurring-period__week-days'>
                        {weekDays?.map((item: any) =>
                            <div key={item.value} style={{margin: '5px 0'}}>
                                <AddCheckboxIn
                                    title={t(item?.label)}
                                    onClick={() => getCheckValue(item.value)}
                                    isChecked={selectedWeekDays?.includes(item?.value)}
                                />
                            </div>
                        )}
                    </div>
                }
        </div>
    )
}

export default RecurringPeriod;
