import React, {FC} from 'react';
import '../index.scss';
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import {useTranslation} from "react-i18next";
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';


interface IProps {
    navTitle?: string | null;
    children?: any;
    goBack?: () => void;
}

const LayoutEmpty: FC<IProps> = ({children, navTitle, goBack}) => {
    const {t} = useTranslation();
    const condition = window.location.pathname.includes('category') && !window.location.pathname.includes('add-favorite') && !window.location.pathname.includes('add-recurring')
    return(
        <div className="layout">
            {!condition ? <NavigationComponent onClick={goBack} title={navTitle || `${t("back")}`} /> : <></>}
            {condition ? <Breadcrumbs/> : <></>}
            <div className="layout__body">
                {
                    children
                }
            </div>

        </div>
    )
}

export default LayoutEmpty;