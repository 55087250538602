import React from 'react';
import "./index.scss"

type Props = {
    title?: string | undefined | null,
    width?: number
    height?: number,
    fontSize?: number,
    isChecked?: boolean,
    onClick?: any,
    padding?: string,
    value?:any,
    disabled?: boolean,
}

const AddCheckboxIn: React.FC<Props> = ({
                                            title,
                                            width,
                                            height,
                                            onClick,
                                            fontSize,
                                            isChecked,
                                            padding,
                                            value,
                                            disabled
                                        }) => {
    return (
        <label className="add-checkbox" style={{padding}}>
            <input
                value={value} type="checkbox"
                // style={{height, width}}
                checked={isChecked}
                onChange={onClick}
                disabled={disabled}
            />
            <span className="add-checkbox__title" style={{fontSize}}>{title}</span>
        </label>
    );
};

export default AddCheckboxIn;

























