const usePayByQrServiceData = (amountValue: string, user: any, c2qr: any, language: string) => {
  return () => {
    if (!c2qr) {
      return;
    }

    const serviceFields = c2qr.serviceFields.serviceField;
    let fieldsService = [{
      name: serviceFields[0].name,
      value: user?.phone || '',
      id: serviceFields[0].id.toString(),
    }];

    return {
      serviceId: c2qr.id,
      amount: Number(amountValue),
      fields: JSON.stringify({ fields: fieldsService }),
      payAllDebt: c2qr.hasDebt,
      subName: c2qr.subName,
      subVal: '',
      desc: '',
      lng: language,
      selectedGroupId: 0,
    };
  };
};

export default usePayByQrServiceData;
