import LanguageActionTypes from './types';
import { createSelector } from '@reduxjs/toolkit';

export const allLanguages = ['az', 'ru', 'en'];

const initialState = {
  language: 'az',
  langKey: 'valueAz',
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LanguageActionTypes.CHANGE_LANGUAGE:
      return { ...state, language: action.language, langKey: action.langKey };

    default:
      return state;
  }
};

const selectLanguageState = (state) => state.changeLangSlice;

export const languageSelector = createSelector(
  [selectLanguageState],
  (changeLangSlice) => changeLangSlice
);

export default languageReducer;
