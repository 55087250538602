import React from "react"
import ContentLoader from "react-content-loader"

const MediaHistorySkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={85}
        viewBox="0 0 835 85"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="4" y="0" rx="0" ry="0" width="835" height="5" />
        <rect x="14" y="32" rx="0" ry="0" width="69" height="13" />
        <circle cx="150" cy="40" r="25" />
        <rect x="210" y="26" rx="0" ry="0" width="151" height="10" />
        <rect x="210" y="46" rx="0" ry="0" width="151" height="9" />
        {/*<rect x="410" y="32" rx="0" ry="0" width="60" height="10" />*/}
        <rect x="680" y="32" rx="0" ry="0" width="60" height="10" />
        <rect x="780" y="32" rx="0" ry="0" width="42" height="10" />
        <rect x="4" y="75" rx="0" ry="0" width="835" height="5" />
    </ContentLoader>
)

export default MediaHistorySkeleton