import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../redux/store";
import { useAppSelector } from "../../../hooks/redux";
import "./index.scss";
import { LoadingLocal } from "../../Loading/Loading";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import AdditionalFields from "../../AdditionalFields/AdditionalFields";
import CardsBox from "../../CardsBox/CardsBox";
import PaymentFields from "../../PaymentFields";
import { Languages } from "../../../redux/reducers/language/languageData";
import client from "../../../axios";
import {
  fieldsForPicker,
  cardSelectorCategories,
  cardSelectorServices, azercellId,
} from "../consts";
import {IS_MASTER_PASS_MEMBER} from "../../../utils/consts";
import AdaptiveRightOtherPayment from "../../AdaptivRightOtherPayment/AdaptivRightOtherPayment";
import RecurringWarningModal from "../RecurringModals/RecurringWarningModal"

interface IProps {}

const RecurringTransaction: FC<IProps> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const language = useAppSelector((state) => state.changeLangSlice);
  const cards = useAppSelector((state) => state?.cardsReducer);
  const {
    state: { service },
  } = location;
  const isAuthToken = localStorage.getItem("token") || "";
  const { language: selectedLanguage } = language;
  const lang = Languages(selectedLanguage);
  const [fields, setFields] = useState<any>(null);
  const [subVal, setSubVal] = useState("");
  const [subName, setSubName] = useState("");
  const [groupId, setGroupId] = useState(0);
  const [msgType, setMsgType] = useState(0);
  const [checkData, setCheckData] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [regExp, setRegExp] = useState<any>(null);
  const [cardPanId, setCardPanId] = useState("newcard_visa");
  const [isLoading, setIsLoading] = useState(false);
  const [instrument, setInstrument] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amountValue, setAmountValue] = useState("");
  const [isClientExist, setIsClientExist] = useState(false);
  const [isAmountCorrect, setIsAmountCorrect] = useState(false);
  const [showVerdictFields, setShowVerdictFields] = useState(false);
  const  message = params.process === "edit"
      ? t("isRecurringEditAvailable")
      : t("isRecurringCreateAvailable");
  const serviceName = service.localName[language.langKey]
  const isAuthAndClient = isAuthToken && isClientExist

  const {
    recurring,
    isEdit,
    service: {
      serviceFields: { serviceField },
      groups,
    },
  } = location?.state;

  const goBack = () => {
    if (location?.state?.recurring) {
      navigate(`/epay/${selectedLanguage}/recurring-edit/${location?.state?.recurring?.id}`, {
        state: {
          service,
          fields,
          recurring,
        },
      });
    } else {
      navigate(-1);
    }
  };

  const createFields = useCallback(() => {
    let fields: any[] = [];
    let groupId: number;
    if (recurring) {
      const { autoOrderField } = recurring.fields;
      for (const i in autoOrderField) {
        const item = autoOrderField[i];
        let field: any = { id: "", name: "", value: "" };
        field.id = item.fieldId.toString();
        field.name = item.name;
        field.value = item.value;
        fields.push(field);
      }
      groupId = recurring.groupId;
    } else {
      groupId = groups?.length ? groups[0].id : 0;
      for (const i in serviceField) {
        const item = serviceField[i];
        if (groupId && item.groupId !== groupId) {
          continue;
        }
        let field: any = { id: "", name: "", value: "" };
        field.id = item.id.toString();
        field.name = item.name;
        fields.push(field);
      }
    }
    setFields(fields);
    setGroupId(groupId);
    // setIsAmountCorrect(true)
  }, [groups, recurring, serviceField]);

  useEffect(() => {
    createFields();
  }, [createFields]);

  useEffect(() => {
    if (location.state.service && fields) {
      const id = fields[0];
      const reg = service?.serviceFields?.serviceField?.find(
        (field: any) => field.id === Number(id?.id)
      );
      setRegExp(reg?.regExp);
    }
  }, [fields, location.state.service, recurring, service]);

  useEffect(() => {
    if (isClientExist) {
      if (new RegExp(regExp).test(fields[0]?.value) && isAmountCorrect) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (fields?.length && new RegExp(regExp).test(fields[0]?.value)) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [isAmountCorrect, isClientExist, fields, amountValue, regExp, recurring]);

  //add types
  const checkIfUserExist = async (
    sessionId: string,
    serviceId: any,
    fields: any,
    selectedGroupId: any
  ) => {
    setIsLoading(true);

    try {
      const response = await client.get(
        `categories/client_info_with_group_id?fields=${encodeURIComponent(
          fields
        )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}&sessionId=${sessionId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        // const json = await response.json();

        if (response.data?.resultCodes === "OK") {
          // if(params.process === 'isEdit') {
          //     changeUrl()
          // }
          setIsClientExist(true);
          setCheckData(response.data);
        } else if (
          response.data?.resultCodes === "NOT_FOUND" ||
          response.data?.resultCodes === "INVALID_PARAMETERS" ||
          response.data?.resultCodes === "UNKNOWN_ERROR"
        ) {
          setMsgType(1);
          notify();
        } else if (
          response.data?.resultCodes === "UNDECIDED_PROTOCOL" ||
          response.data?.resultCodes === "PROTOCOL_CANT_DECIDED"
        ) {
          setShowVerdictFields(true);
        }
      } else {
        setMsgType(0);
        notify();
      }
    } catch (e) {
      setMsgType(0);
      notify();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit && fields) {
      checkIfUserExist(
        isAuthToken,
        service?.id,
        JSON.stringify({ fields }),
        recurring?.groupId
      );
    }
  }, [fields, params.process, recurring?.groupId, service?.id]);

  const notify = () => {
    toast.error(msgType === 1 ? lang.noClientMsg : lang.someError, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const renderPicker = () => {
    return (
      <div className="service_fields-item">
        <p className="service_fields-item-title">{lang.selectCard}</p>
        <select
            aria-label="service_fields"
          onChange={(e) => {
            setCardPanId(e.target.value);
          }}
          value={cardPanId}
        >
          {fieldsForPicker.map((item, i) => {
            return (
              <option key={item.key} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  if (service.error) {
    return <div className="service_error">Ошибка при загрузке Сервиса</div>;
  }

  const onBtnClick = () => {
    if (isClientExist) {
      if(instrument) {
        processToAutoPayment();
      } else {
        toast.error(t("youHaveNoCards"), {position: toast.POSITION.TOP_CENTER});
      }
    } else {
      checkIfUserExist(
        isAuthToken,
        service.id,
        JSON.stringify({ fields }),
        groupId || 0
      );
    }
  };

  const processToAutoPayment = async () => {
    const path =
      params.process === "edit"
        ? `/epay/${selectedLanguage}/recurring-edit/${recurring?.id}`
        : `/epay/${selectedLanguage}/recurring-create`;

    const selectCard = instrument?.card;
    const findCard = cards?.cards?.find((card) => card?.pan === selectCard?.pan);

    if(findCard?.isMasterPassMember === IS_MASTER_PASS_MEMBER) {
        setIsModalOpen(true)
    } else {
      await navigate(path, {
        state: {
          service: service,
          cardPanId: cardPanId,
          groupId: groupId || 0,
          amount: Number(amountValue) || (recurring?.amount / 100).toFixed(),
          fields,
          cardInfo: instrument?.card,
          isCreate: params.process !== "edit",
          recurring,
          subVal,
          subName,
        },
      });
    }


  };

  const btnValue =
    params.process === "isEdit" ? `${t("edit")}` : `${t("next")}`;

  const getBtnTitle = () => {
    return isClientExist ? btnValue : t("check");
  };

  const onClickCancel = () => {
    navigate(`/epay/${language}/recurring-edit/${recurring?.id}`, {
      state: {
        recurring,
      },
    });
  };

  return isLoading || Object.keys(service).length === 0 ? (
      <LoadingLocal/>
  ) : (
      <LayoutEmpty goBack={goBack}>
        <div className="recurring-transaction-wrap">
          <div className="recurring-transaction">
            <div className="recurring-transaction__content">
              <div className="recurring-transaction__title">
                {serviceName}
              </div>
              <div className="recurring-transaction__form-box">
              <div className="recurring-transaction__form">
                <PaymentFields
                    showVerdictFields={showVerdictFields}
                    service={service}
                    onValueChange={(data: any) => {
                      if (data.fields) {
                        setFields(data.fields);
                      }
                      if (data.groupId) {
                        setGroupId(data.groupId);
                      }
                    }}
                    isClientExist={isClientExist}
                    azercell={service?.id === azercellId}
                    fieldsForPicker={fieldsForPicker}
                    defaultFields={fields}
                    defaultGroupId={groupId}
                />
                {isAuthAndClient && (
                    <AdditionalFields
                        isAmountCorrect={isAmountCorrect}
                        service={service}
                        checkData={checkData}
                        defaultValue={
                          recurring?.amount
                              ? (recurring?.amount / 100).toFixed()
                              : amountValue
                        }
                        onChange={(data: any) => {
                          data.hasOwnProperty("subVal") && setSubVal(data.subVal);
                          data.hasOwnProperty("subName") &&
                          setSubName(data.subName);
                          data.hasOwnProperty("amountValue") &&
                          setAmountValue(data?.amountValue);
                          data.hasOwnProperty("isAmountCorrect") &&
                          setIsAmountCorrect(data.isAmountCorrect);
                        }}
                    />
                )}
                {isAuthAndClient &&
                    (cardSelectorCategories.has(service?.categoryId) ||
                        cardSelectorServices.has(service?.id)) &&
                    renderPicker()}
                {isAuthAndClient && (
                    <div className="recurring-transaction__card-box">
                      <CardsBox
                          canBonus={false}
                          canWallet={false}
                          noNewCard
                          setInstrument={setInstrument}
                      />
                    </div>
                )}
                <button
                    className="recurring-transaction__btn"
                    disabled={isDisabled}
                    onClick={onBtnClick}
                >
                  {getBtnTitle()}
                </button>
                {params.process === "edit" && (
                    <button
                        className="recurring-transaction__btn-cancel"
                        onClick={onClickCancel}
                    >
                      {t("cancel")}
                    </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="recurring-right-wrap">
          <AdaptiveRightOtherPayment/>
        </div>
      </div>
      <RecurringWarningModal
          message={message}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
      />

    </LayoutEmpty>
  );
};

export default RecurringTransaction;
