import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { baseURL } from "../../../http";
import Loading from '../../Loading/Loading'

import { fetchBonusGetDetail } from "../../../redux/actions/bonusesAction";


import './BonusesPaymentCheck.scss'
import {useTranslation} from "react-i18next";


const BonusesPaymentCheck = () => {
    const {t} = useTranslation()


  const dispatch = useAppDispatch()



  const {bonus, isLoading}  = useAppSelector((state) => state.bonList);


  const params = useParams()


    useEffect(()=>{
    dispatch(fetchBonusGetDetail({id:+params.id!}))
  },[dispatch, params.id])


  const paymentResultRender = () => {
    const checkResultLinkRender = () => {
        return (
            <div className='check_result-link-wrap'>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{fontWeight: 700, fontSize: "22px", color: "#FFFFFF"}}>{t("paymentMade")}</div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={
                    'check_result check_result--success'
            }
        >
            <div className='container container--check'>
                <div className='check_result-icon'>
                    <img src={require("../../../img/success-payment.svg").default} alt=''/>
                </div>
                {checkResultLinkRender()}
            </div>
        </div>
    )
}



  return (
    <>

        {
            isLoading ? <Loading /> : (
                <>
                    {paymentResultRender()}
                    <div className="check container container--check">
                        <div className='check_data-items' style={{marginBottom: "77px"}}>
                            <div className='check_data-item'>
                                <div className='check_data-item-title check-data-content'>{t("bonusesSpent")}</div>
                                <div className='check_data-item-body check-data-content'>{bonus?.bonusValue}</div>
                            </div>
                            <div className='check_data-item'>

                                <div className='check_data-item-title check-data-content'>{t("productPhoto")}</div>
                                <div className='check_data-item-body check-data-content bonus-pay-img'>
                                    <img src={`${baseURL}bonus/get_gift_img/${localStorage.getItem('token')}/${bonus?.id}`} alt=""/>
                                </div>
                            </div>
                            <div className='check_data-item'>

                                <div className='check_data-item-title check-data-content'>{t("productName")}</div>
                                <div className='check_data-item-body check-data-content'>{bonus?.name}</div>
                            </div>

                        </div>
                    </div>
                </>

            )
        }
   </>
  );
};

export default BonusesPaymentCheck;
