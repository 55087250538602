import React from "react"
import ContentLoader from "react-content-loader"

const Media900MyCardSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 450 110"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="10" y="5" rx="10" ry="10" width="430" height="101" />
    </ContentLoader>
)

export default Media900MyCardSkeleton