import './../../index';
import { ToastContainer } from 'react-toastify';
import { setShowUnAuthModal } from '../../redux/actions/authActions';
import Footer from '../Footer/Footer';
import MasterPassForms from '../MasterPass/MasterPassForms';
import { useAppSelector } from '../../hooks/redux';
import { useAxiosInterceptor } from '../../axios';
import { authSelector } from '../../redux/slices/auth';
import { languageSelector } from '../../redux/reducers/language';
import { useLanguageAndRouteHandler } from './useLanguageAndRouteHandler';
import { useDataLoader } from './useDataLoader';
import { useC2qrService } from './useC2qrService';
import { useFilterCard } from './useFilterCard';
import { AppRoutes } from './AppRoutes';
import HeaderContent from './ShowHeaderTernary';
import { HeadBanner } from './HeadBanner';
import { useBrowserTokenManager } from './useBrowserTokenManager';
import { useAuthDataLoader } from './useAuthDataLoader';
import { UnAuthModal } from './UnAuthModal';
import  useLastPathSegment  from './useLastPathSegment';

function App() {
  const { showUnAuthModal, isAuth } = useAppSelector(authSelector);
  const token = localStorage.getItem('token');
  const { language } = useAppSelector(languageSelector);
  useAxiosInterceptor();
  useDataLoader();
  useLanguageAndRouteHandler();
  useC2qrService();
  useFilterCard();
  useAuthDataLoader(isAuth, token);
  useBrowserTokenManager();
  const morePage = useLastPathSegment('/more-page');

  return (
    <div className="home-main">
        {morePage === "/more-page" ? null : <HeaderContent />}
        {morePage === "/more-page" ? null : <HeadBanner />}
      <ToastContainer position="top-center" />
      <MasterPassForms />
      <AppRoutes />
      <Footer />
      <UnAuthModal showUnAuthModal={showUnAuthModal} language={language} setShowUnAuthModal={setShowUnAuthModal} />
    </div>
  );
}

export default App;
