import React from 'react';
import Slider from "react-slick";
import {LocaleName} from "../../../models/Categories";
import {useAppSelector} from "../../../hooks/redux";
import CatalogueSliderSkeletons from "../../Sceletons/CatalogueSliderSkeletons/CatalogueSliderSkeletons";
import './Catalogue.scss';

type CategoriesProps = {
    activeIndex: any,
    resCategory?: any,
    chooseCategory: (id: number) => void
    setActiveIndex: any,
    isLoadingCategory: boolean,
};

const CategoriesComponent: React.FC<CategoriesProps> = (props) => {
    const {resCategory, activeIndex, chooseCategory, setActiveIndex, isLoadingCategory} = props;
    const {langKey} = useAppSelector(state => state.changeLangSlice)
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    const arrCat: any = new Array(5).fill(0);

    return (
        <div className="categories-slider">
            {isLoadingCategory ?
                <div style={{display: "flex"}}>
                    {
                    arrCat.map(({_, i}: any) => i + 1).map((item: any) => (
                        <CatalogueSliderSkeletons key={item}/>))
                    }
                </div>
                :
                <>
                    {
                        resCategory?.map((el: any, index: number) => (
                            <div
                                className={activeIndex === index ? 'categories-slider__item categories-slider__item--active' : 'categories-slider__item'}
                                key={el.id}
                                onClick={() => {
                                    chooseCategory(el.id);
                                    setActiveIndex(index)
                                }}
                            >
                                {el?.localName[langKey as keyof LocaleName]}
                            </div>
                        ))
                    }
                </>
            }
        </div>
    );
};

export default CategoriesComponent;
