import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { cardsSliceActions } from '../slices/cardsSlice'
import client from '../../axios'
import {FREE_PAY_URL} from "../../utils/consts";



export const fetchFilterCard = createAsyncThunk(
  'tokenize/fetchFilterCard',
  async (params: { body: any; browserToken: string }, thunkApi) => {
    try {
      const res = await axios.post(
        `${FREE_PAY_URL}/api/cardStorage/filter`,
        params.body,
        {
          headers: {
            BrowserToken: params.browserToken,
          },
        },
      )

      thunkApi.dispatch(cardsSliceActions.setCards(res.data))

      return res
    } catch (e) {
      return thunkApi.rejectWithValue('Не удалось загрузить')
    }
  },
)

export const fetchGetCardToken = createAsyncThunk(
  'tokenize/fetchGetCardToken',
  async (params: { body: any; browserToken: string }, thunkApi) => {
    try {
      const res = await axios.post(
        `${FREE_PAY_URL}/api/cardStorage/token`,
        params.body,
        {
          headers: {
            BrowserToken: params.browserToken,
          },
        },
      )

      return res.data.cardToken
    } catch (e) {
      return thunkApi.rejectWithValue('Не удалось загрузить')
    }
  },
)
///_____________________________

export const fetchHabSolutionW2CService = createAsyncThunk(
  'fetchHabSolutionW2CService/getW2CHab',
  async (sid: string | undefined | null, thunkApi) => {
    try {
      const response = await client.get(
        'categories/service/hubSolutionPayout/' +
        (sid || localStorage.getItem('token')),
      )
      return response
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)


export const fetchHabSolutionC2CService = createAsyncThunk(
  'fetchHabSolutionС2CService/getС2CHab',
  async (sid: string | undefined | null, thunkApi) => {
    try {
      const response = await client.get(
        'categories/service/freePayC2c/' +
        (sid || localStorage.getItem('token')),
      )
      return response
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)


///_____________________________

export const fetchRecurringPaymentAdd = createAsyncThunk(
  'tokenize/fetchRecurringPaymentAdd',
  async (params: { body: any; browserToken: string }, thunkApi) => {
    try {
      const res = await axios.post(
        `${FREE_PAY_URL}/api/recurringPayment/add`,
        params.body,
        {
          headers: {
            BrowserToken: params.browserToken,
          },
        },
      )

      return res.data
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const fetchRecurringPaymentEdit = createAsyncThunk(
  'tokenize/fetchRecurringPaymentEdit',
  async (params: { body: any; browserToken: string }, thunkApi) => {
    try {
      const res = await axios.post(
        `${FREE_PAY_URL}/api/recurringPayment/update`,
        params.body,
        {
          headers: {
            BrowserToken: params.browserToken,
          },
        },
      )

      return res.data
    } catch (e) {
      return thunkApi.rejectWithValue('Не удалось загрузить')
    }
  },
)

export const fetchRecurringPaymentRemove = createAsyncThunk(
  'tokenize/fetchRecurringPaymentRemove',
  async (params: { body: any; browserToken: string }, thunkApi) => {
    try {
      const res = await axios.post(
        `${FREE_PAY_URL}/api/recurringPayment/remove`,
        params.body,
        {
          headers: {
            BrowserToken: params.browserToken,
          },
        },
      )

      return res.data
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)
