import React, { FC, useEffect, useState } from "react";
import ServiceMain from "../ServiceMain/ServiceMain";
import Ads from "../Ads/Ads";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Category, Service } from "../../models/Categories";
import ServiceSearch from "../ServiceMain/ServiceSearch";
import { useTranslation } from "react-i18next";
import ServicesSkeletonsBig from "../Sceletons/ServicesSkeletons/ServicesSkeletonsBig";
import ServicesSkeletonsSmall from "../Sceletons/ServicesSkeletons/ServicesSkeletonsSmall";
import LayoutWithSidebar from "../layouts/LayoutWithSidebar/LayoutWithSidebar";
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import "./index.scss";
//@ts-ignore
import { Helmet } from 'react-helmet';
import { fetchHistoryList } from "../../redux/actions/historyAction";
import { categoriesSelector } from "../../redux/slices/categoriesSlice";
import { authSelector } from "../../redux/slices/auth";
import HomeService from "./HomeService";

const hint = ["azercell", "yelobank", "uninet"];

const HomePage: FC = () => {
  const { t } = useTranslation();
  const [inputString, setInputString] = React.useState("");
  const [allServices, setAllServices] = useState<Category[]>([]);
  const { categories, services } = useAppSelector(categoriesSelector);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const auth = useAppSelector(authSelector);
  const [modalActive, setModalActive] = React.useState(false)

  const data = categories;

  const isAuth = auth.isAuth;
  const isLoading = auth.isLoading || categories.isLoading;

  const filteredList = inputString?.trim() ? services?.filter((ser: Service) => {
    if (ser.localName.valueAz.toLowerCase().includes(inputString?.trim()?.toLowerCase()) || ser.localName.valueRu.toLowerCase().includes(inputString.toLowerCase()) || ser.localName.valueEn.toLowerCase().includes(inputString.toLowerCase())) {
      return ser;
    }
  }) : [];

  useEffect(() => {
    const popularity = [1, 2, 3, 5, 9];

    const main = data?.filter((item: any) => popularity?.includes(item.id))
    const others = data?.filter((item: any) => !popularity?.includes(item.id))
    const all = main.concat(others)

    if (data) {
      setAllServices(all)
    }
  }, [data, allServices.length]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onClickClear = () => {
    setInputString("");
    inputRef.current?.focus();
  };

  return (
    <div className='home-page'>
      <Helmet>
        <title>{t('homeTitle')}</title>
        <meta name="description" content={t('homeDescription') || ''} />
        <meta name="keywords" content={t('homeKeywords') || ''} />
      </Helmet>
      <h1 style={{ display: 'none' }}>{t('onlinePaySistem')}</h1>
      <LayoutWithSidebar isApps noBack={true} withHistory={true}>
        <div className="home-page__container">
          <HomeService
              inputString={inputString}
              setInputString={setInputString}
              onClickClear={onClickClear}
              hint={hint}
              isLoading={isLoading}
              filteredList={filteredList}
              allServices={allServices}
          />
        </div>
      </LayoutWithSidebar>
      <Ads isAuth={isAuth} />
    </div>
  );
};

export default HomePage;
