import BonusServicesActionTypes from './types';

const initialState = {
  bonusServicesDigests: [],
  bonusServicesDigestMap: {},
  bonusServices: [],
};

const bonusServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case BonusServicesActionTypes.SAVE_BONUS_SERVICES_DIGESTS:
      return {
        ...state,
        bonusServicesDigests: action?.payload.newServicesDigests,
        bonusServicesDigestMap: action?.payload.newServicesDigestMap,
        bonusServices: action?.payload.newServices,
      };
    default:
      return state;
  }
};

export default bonusServicesReducer;
