import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IBonusGift} from "../../models/BonusStore";
import {fetchPopularProduct} from "../actions/popularProductAction";

interface InitialState {
    sid: string,
    error: string,
    popularProduct: IBonusGift[] ,
    isLoadingPop: boolean
}

const initialState: InitialState = {
    sid: "",
    error: "",
    popularProduct: [],
    isLoadingPop: false
}

export const popularProductSlice = createSlice({
    name: "popularProduct",
    initialState,
    reducers: {
        clearBonusStore(state) {
            state.popularProduct = []
        }
    },
    extraReducers: {
        [fetchPopularProduct.pending.type]: (state) => {
            state.isLoadingPop = true
        },
        [fetchPopularProduct.fulfilled.type]: (state, action: PayloadAction<IBonusGift[]>) => {
            state.isLoadingPop = false
            state.error = ""
            state.popularProduct = [...state.popularProduct, ...action.payload]
        },
        [fetchPopularProduct.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoadingPop = false
            state.error = action.payload
        }
    }
})

export default popularProductSlice.reducer
export const clearBonusStoreAction = () => ({
    type: "bonusStore/clearBonusStore",

})