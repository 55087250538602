import React from "react"
import ContentLoader from "react-content-loader"

const BonusesSkeleton = () => (
    <ContentLoader
        speed={2}
        // width={240}
        width="100%"
        height={330}
        viewBox="0 0 240 330"
        backgroundColor="#f5f5f4"
        // backgroundColor="red"
        foregroundColor="#efebeb"
    >
        <rect x="5" y="17" rx="0" ry="0" width="5" height="295"/>
        <rect x="10" y="17" rx="0" ry="0" width="225" height="5"/>
        <rect x="233" y="22" rx="0" ry="0" width="5" height="295"/>
        <rect x="10" y="311" rx="0" ry="0" width="100%" height="5"/>
        <rect x="15" y="30" rx="0" ry="0" width="209" height="191"/>
        <rect x="41" y="229" rx="0" ry="0" width="160" height="24"/>
        <rect x="87" y="264" rx="0" ry="0" width="71" height="25"/>
    </ContentLoader>
)

export default BonusesSkeleton