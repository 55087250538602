import React from "react"
import ContentLoader from "react-content-loader"

const Media900AutoPaymentSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={100}
        viewBox="0 0 850 100"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <circle cx="28" cy="42" r="28" />
        <rect x="63" y="27" rx="0" ry="0" width="210" height="30" />
        <rect x="400" y="32" rx="0" ry="0" width="80" height="17" />
        <rect x="510" y="19" rx="0" ry="0" width="130" height="40" />
        <rect x="735" y="26" rx="0" ry="0" width="45" height="25" />
        <rect x="820" y="26" rx="0" ry="0" width="30" height="30" />
    </ContentLoader>
)

export default Media900AutoPaymentSkeleton

