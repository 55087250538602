import { useEffect } from 'react';
import { fetchEveryPage } from '../../redux/actions/authActions';
import { getC2cService } from '../../redux/actions/card2cardAction';
import { getC2wService, getW2wService } from '../../redux/actions/walletAction';
import { useAppDispatch } from '../../hooks/redux';

export const useAuthDataLoader = (isAuth: boolean, token: string | null) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadAuthData = async () => {
      if (isAuth) {
        dispatch(fetchEveryPage(token));
        dispatch(getC2cService(token));
        dispatch(getC2wService(token));
        dispatch(getW2wService(token));
      }
    };

    loadAuthData();
  }, [dispatch, isAuth, token]);
};