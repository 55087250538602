import React, {FC} from 'react';
import '../index.scss';
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import InstallApp from "../../CustomsComponents/InstallApp/InstallApp";
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import OtherPayments from "../../OtherPayments/OtherPayments";
import OtherPaymentSwiper from "../../OtherPayments/OtherPaymentSwiper";


interface IProps {
    navTitle?: string | null;
    children?: any;
    goBack?: () => void;
}

const LayoutWithApp: FC<IProps> = ({children, navTitle, goBack}) => {
    const {t} = useTranslation();
    const condition = window.location.pathname.includes('category') && !window.location.pathname.includes('add-favorite') && !window.location.pathname.includes('add-recurring')
    return(
        <div className="layout">
            {!condition ? <NavigationComponent onClick={goBack} title={navTitle || `${t("back")}`} /> : <></>}
            {condition? <Breadcrumbs/> : <></>}
            <div className="layout__body">
                <div className="layout__content">
                    {
                        children
                    }
                </div>
                <div className="layout__app">
                    <div className="layout__app-other">
                        <OtherPayments/>
                    </div>
                    <InstallApp/>
                </div>
                <div className="layout__app-otherWrap">
                    <div className='layout__app-otherWrap-border'></div>
                    <div className="layout__app-otherSwiper">
                        <OtherPaymentSwiper/>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LayoutWithApp;
