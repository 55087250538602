import React, {FC, useEffect} from 'react';
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import "./index.scss"
import HeaderText from "../../CustomsComponents/HeaderText";
import PaymentPeriod from "../PaymentPeriod/PaymentPeriod";
import InstallApp from "../../CustomsComponents/InstallApp";
import { useLocation, useNavigate} from 'react-router-dom';
import moment from "moment/moment";
import {commitRecurring, saveAutoPayments} from "../../../redux/actions/autoPayments";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {useAppSelector} from "../../../hooks/redux";
import {addFavoritesActionCreators} from "../../../redux/reducers/addFavorites/action-creators";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface PaymentPeriodPageProps {
}

const PaymentPeriodPage: FC = (props: PaymentPeriodPageProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const {service, amount, subVal, subName, cardPanId, groupId, fields, cardInfo } = location?.state;

    //TODO: add types
    const correctFields = fields?.map((field: any) => ({
        id: field.id,
        name: field.name,
        value: field.value
    }))

    const fieldsJSON = JSON.stringify({ fields: correctFields });
    const {forms} = useAppSelector(state => state.masterPassReducer)
    const { language } = useAppSelector((state) => state.changeLangSlice);

    const notify = () => {
        toast.error(
            'success or error',
            // msgType === 1 ? lang.noClientMsg : lang.someError,
            {
                position: toast.POSITION.TOP_CENTER,
            }
        );
    }

    const getPeriodData = async (periodData: any) => {

        const sessionId = localStorage.getItem('token');


        const atClock = moment(new Date()).format('HH:mm');
        const mirrorId = new Date().getTime().toString();
        const data = {
            sessionId,
            serviceId: service?.id,
            bindingId: cardPanId,
            amount: amount,
            maxAllowAmount: service?.maxAmount,
            atClock,
            fields: fieldsJSON,
            payCurrentDebt: false,
            mirrorId,
            selectedGroupId: groupId,
            paymentMode: 1,
            subServiceName: subName,
            subServiceValue: subVal,
            maskedPan: cardInfo?.pan,
            repeatingType: periodData?.repeatingType,
            periods: periodData?.periods,
        }
        if(location.state.isFavChecked) {
            await dispatch(addFavoritesActionCreators.addFavorites({
                comment: "",
                serviceId: location.state?.service.id,
                selectedGroupId: location.state.groupId,
                fields: JSON.stringify({fields})
            }))
        }
        await dispatch(saveAutoPayments(data));
        await forms?.addRecurring(
            service?.id,
            cardInfo?.name,
            mirrorId,
            amount,
            () => {
                dispatch(commitRecurring(mirrorId));
                navigate(`/epay/${language}/auto-payments`)
            }
        )

        // await navigate('/auto-payments')
    }


    return (
        <div className="payment-period-page">
            <div>
                <NavigationComponent title={`${t("back")}`} onClick={goBack}/>
            </div>
            <div className="payment-period-page__header">
                <div className="payment-period-page__header-left">
                    <HeaderText title={t("paymentPeriod")}/>
                    <div className="payment-period-page__header-left-body">
                        <PaymentPeriod
                            getPeriodData={getPeriodData}
                        />
                    </div>
                </div>
                <div className="payment-period-page__header-right">
                    <InstallApp/>
                </div>
            </div>
        </div>
    );
};

export default PaymentPeriodPage;
