import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface WalletBannerProps {
  language: string;
}

const WalletBanner: React.FC<WalletBannerProps> = ({ language }) => {
  const { t } = useTranslation();

  return (
    <div className="top-up-balance__left-wrap-banner">
      <p className="top-up-balance__left-wrap-banner-textFor">
        {t('limit100')}
        <Link
          to={`/epay/${language}/identification`}
          className="top-up-balance__left-wrap-banner-textColor"
        >
          {t('toBeIdentified')}
        </Link>
      </p>
      <p className="top-up-balance__left-wrap-banner-textFor">
        {t('UnibankLeoComission')}
      </p>
    </div>
  );
};

export default WalletBanner;
