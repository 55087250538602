import {ISelectedFavList} from "../../models/FavoritesList";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchFavoritesList} from "../actions/favoritesListAction";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitialState {
    sid: string,
    error: string,
    favList: ISelectedFavList | null,
    isLoading: boolean
}

const initialState: InitialState = {
    sid: "",
    error: "",
    favList: null,
    isLoading: false
}

export const favListSlice = createSlice({
    name: "favList",
    initialState,
    reducers: {
        changeFavoritesList(state, action) {
            state.favList = action.payload
        }
    },
    extraReducers: {
        [fetchFavoritesList.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchFavoritesList.fulfilled.type]: (state, action: PayloadAction<ISelectedFavList>) => {
            state.isLoading = false
            state.error = ""
            state.favList = action.payload
        },
        [fetchFavoritesList.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
    }
})

const selectFavoriteListState = (state: RootState) => state.favList;

export const favoriteListSelector = createSelector(
    [selectFavoriteListState],
    (favList) => favList
  );


export default favListSlice.reducer
export const { changeFavoritesList } = favListSlice.actions