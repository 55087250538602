import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import { Helmet } from 'react-helmet';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import { getServices } from "../../redux/actions/CategoriesActions";
import { LoadingLocal } from '../Loading/Loading'
import { categoryMeta } from '../../metaTags/metaTags'
import LayoutWithSidebar from '../layouts/LayoutWithSidebar/LayoutWithSidebar'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {Category, LocaleName, Service} from '../../models/Categories'
import FavoritesSkeleton from "../Sceletons/FavoritesSkeleton/FavoritesSkeleton";
import MediaLess900FavSkeleton from "../Sceletons/FavoritesSkeleton/MediaLess900FavSkeleton";
import MediaLess610FavSkeleton from "../Sceletons/FavoritesSkeleton/MediaLess610FavSkeleton";
import MediaLess410FavSkeleton from "../Sceletons/FavoritesSkeleton/MediaLess410FavSkeleton";
import {IFavoritesList} from "../../models/FavoritesList";
import {
  deleteFromFavoritesFetch,
  fetchFavoritesList
} from "../../redux/actions/favoritesListAction";
import {toast} from "react-toastify";
import './index.scss'
import FavoriteList from '../../pages/FavoritesPage/FavoritesList';
import ModalButtons from '../ModalButtons/ModalButtons';

interface IProps {
}

const Services: FC<IProps> = () => {
  const favorMockArr = [1, 2, 3, 4, 5];
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location: any = useLocation()
  const params = useParams();
  const dispatch = useAppDispatch()
  const [service, setService] = useState<Service[]>([])
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [category, setCategory] = useState({
    excludeFromRecurrentPayments: null,
    id: null,
    localName: null,
    multilangCategory: null,
    name: null,
    services: null
  });

  const { isAuth } = useAppSelector(state => state.authReducer);
  const { categories, services } = useAppSelector((state) => state.categoriesReducer);
  const { language, langKey } = useAppSelector(state => state.changeLangSlice);
  const { favList, isLoading, error } = useAppSelector(
      (state) => state.favList
  )
  const savedPathPN = isAuth ? "new-payment" : "guest-payment"
  const [selectedFavItem, setSelectedFavItem] = useState<IFavoritesList | null>(
      null
  );

  useEffect(() => {
    dispatch(fetchFavoritesList());
  }, []);

  useEffect(() => {
    if (params.category) {
      dispatch(getServices(params.category)).then((res: any) => {
        setService(res.payload)
      })
    }
    else {
      setService([])
    }
  }, [dispatch, params])

  useEffect(() => {
    if (service.length) {
      deleteSchema()
      generateSchema()
    }
  }, [service])

  const generateSchema = () => {
    const jsonLdData = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": service.map((serv: any, index: number) => ({
        "@type": "ListItem",
        position: index + 1,
        url: `https://www.e-pul.az'${location.pathname}/${serv.friendlyName}`,
        name: serv.localName[langKey],
        image: 'https://www.e-pul.az/epul_api/images/' + serv.friendlyName + '.png',
      })),
    };

    const jsonLdString = JSON.stringify(jsonLdData, null, 2);
    const scriptTag = document.createElement("script");
    scriptTag.type = "application/ld+json";
    scriptTag.text = jsonLdString;
    document.head.appendChild(scriptTag);
  }

  const deleteSchema = () => {
    const scriptTags = document.querySelectorAll('script[type="application/ld+json"]');
    scriptTags.forEach((scriptTag) => {
      try {//@ts-ignore
        const scriptData = JSON.parse(scriptTag.textContent);
        if (scriptData["@type"] === "ItemList") {
          scriptTag?.parentNode?.removeChild(scriptTag);
        }
      } catch (error) {
        console.error("Error parsing JSON in script tag:", error);
      }
    });
  }

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const categoryWord = pathSegments[pathSegments.indexOf('category') + 1];
    const foundCategory = categories.find((category: any) => category.name === categoryWord);
    if (foundCategory) {
      setCategory(foundCategory);
    }

  }, [location, categories, setCategory]);

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      deleteSchema()
    }
  }, [])

  const clickToService = (service: Service) => {
    if (service?.subName?.startsWith('https://')) {
      window.location.href = service.subName;
    } else if (savedPathPN === 'guest-payment' && !location.state) {
      navigate(`/epay/${language}/category/${category.name}/${service?.friendlyName}`, {
        state: {
          serviceId: service.id,
        },
      });
    } else if (location?.state?.savedPath === "AddRecurring") {
      navigate(`/epay/${language}/recurring-transaction/create`, {
        state: {
          service,
        },
      })
    } else if (savedPathPN === 'new-payment' && !(location.state?.savedPath === 'AddFavorite')) {
      navigate(`/epay/${language}/payment-add/category/${category.name}/${service?.friendlyName}`, {
        state: {
          serviceId: service.id,
        },
      })
    } else if (location?.state?.processName === 'guest-payment') {
      navigate(`/epay/${language}/category/${params.category}/${service?.friendlyName}`, {
        state: {
          serviceId: service.id,
        },
      })
    } else if (location?.state?.savedPath === 'AddFavorite') {
      navigate(`/epay/${language}/add-favorite`, {
        state: {
          service,
        },
      })
    }
  }

  const openDeleteModal = () => {
    setDeleteModalActive(true);
  };

  const onDeleteFavorites = () => {
    dispatch(deleteFromFavoritesFetch(selectedFavItem?.id));
    toast(
        favList?.resultCodes === "OK"
            ? `${t("successfullyDeleted")}`
            : `${t("error")}`
    );
    setDeleteModalActive(false);
  };

  return (
    <LayoutWithSidebar goBack={() => navigate(-1)}>
      <Helmet>
        {/* @ts-ignore */}
        <title>{categoryMeta[language!].title?.[category?.id!] || category?.localName?.[langKey]}</title>
        {/* @ts-ignore */}
        <meta name="description" content={categoryMeta[language!].description?.[category?.id] || ''} />
        {/* @ts-ignore */}
        <meta name="keywords" content={categoryMeta[language]?.keywords?.[category?.id] || categoryMeta[language]?.notFoundKeywords} />
      </Helmet>
      <h1 className='category__title' style={{ marginBottom: '30px' }}>{category?.localName && category.localName[langKey]}</h1>
      <div className='services'>
        {isLoading ? (<div className='services__loading'><LoadingLocal /></div>)
          :
            <div className='services__content'>
                {
                    service.length &&
                        service?.map((service: Service) => (
                            <a
                                key={service.id}
                                className='services__service'
                                onClick={() => clickToService(service)}
                            >
                                <div className='services__service-image'>
                                    <img
                                        className='services__icon'
                                        src={
                                            'https://www.e-pul.az/epul_api/images/' +
                                            service.friendlyName +
                                            '.png'
                                        }
                                        onError={(event) => {
                                            //@ts-ignore
                                            event.target.src = 'https://placehold.co/60x60/EF5C27/FFF';
                                        }}
                                        // src={itemImg(service)}
                                        alt={service.friendlyName}
                                    />
                                </div>
                                <div className='services__service-title'>
                                    {service.localName[langKey as keyof LocaleName].replace(/\u00A0/g, ' ')}
                                </div>
                            </a>
                        ))
                        // : <div className='services__empty-services'>{t('emptyServices')}</div>
                }
                {/* @ts-ignore */}
                {/*<div className='category-content'>{serviceMeta[language][category.name]?.content}</div>*/}

              {isAuth &&
                <div className="service-content__templates">
                  <div className="service-content__templates-body">
                    <div className="favorites__add-templates">
                      <div className="favorites__add-templates-wrap">
                        <div className="favorites__add-templates-wrap-text">
                          {t("templates")}
                        </div>
                        <Link to={`/epay/${language}/category/add-favorite`} state={{
                            savedPath: "AddFavorite"
                        }} className="auto-payment-modal__body-item-new">
                        <div
                            className="favorites__add-templates-wrap-plus"
                        >
                          <img
                              src={require("../../img/plus-card-icon.svg").default}
                              alt="plus-card-icon"
                          />
                          <span className="favorites__add-templates-wrap-add">
                            {t("addTemplate")}
                          </span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    {isLoading
                        ? favorMockArr.map((item) => {
                          return <>
                            <div className="favorites-skeleton"><FavoritesSkeleton key={item}/></div>
                            <div className="media-favorites-skeleton"><MediaLess900FavSkeleton key={item}/></div>
                            <div className="media610-favorites-skeleton"><MediaLess610FavSkeleton key={item}/></div>
                            <div className="media410-favorites-skeleton"><MediaLess410FavSkeleton key={item}/></div>
                          </>
                        })
                        : ""}

                    <div className="selected-favorites__accordion-body">
                      {favList?.totalCount === 0 ? (
                          <div className="favorites__add-templates-wrap-empty">
                            {t("empty")}
                          </div>
                      ) : (
                          !isLoading &&
                          favList?.items.paymentListItem?.map((itemFav: any) => {
                            return (
                              <FavoriteList favorite={itemFav} openDeleteModal={openDeleteModal} setSelectedFavItem={setSelectedFavItem}/>
                            );
                          })
                      )}
                    </div>
                    <CustomModal
                        active={deleteModalActive}
                        setActive={setDeleteModalActive}
                    >
                      <ModalButtons text={'deleteTemplate'} buttonText={'delete'} handleInstruction={() => onDeleteFavorites()} handleCancel={() => setDeleteModalActive(false)}/>
                    </CustomModal>
                  </div>
                </div>
              }
            </div>
        }
      </div>
    </LayoutWithSidebar>
  )
}

export default Services
