import React, { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchBrowserToken } from '../../redux/actions/browserToken';
import { authSelector } from '../../redux/slices/auth';

export const useBrowserTokenManager = () => {
  const dispatch = useAppDispatch();
  const [expired, setExpired] = useState(null);
  const ref = React.useRef(null);
  const { isAuth } = useAppSelector(authSelector);
  const token = localStorage.getItem('token');

  const generateBrowserToken = useCallback(async () => {
    try {
      if (isAuth) {
        const res = await dispatch(fetchBrowserToken(token));
        if (res.payload.status !== 200) {
          throw new Error();
        }

        const dateRefresh = Date.now() + 600000 - Date.now();

        if (ref.current) clearTimeout(ref.current);

        ref.current = setTimeout(() => {
          setExpired(true);
        }, dateRefresh);

        return;
      }
      const res = await dispatch(fetchBrowserToken());
      if (res.payload.status !== 200) {
        throw new Error();
      }
      const dateRefresh = Date.now() + 600000 - Date.now();

      if (ref.current) clearTimeout(ref.current);

      ref.current = setTimeout(() => {
        setExpired(true);
      }, dateRefresh);
    } catch (error) {}
  }, [dispatch, isAuth, token]);

  useEffect(() => {
    if (!expired) return;
    (async () => {
      if (expired) {
        setExpired(null);
        clearTimeout(ref.current);
        generateBrowserToken();
      }
    })();
  }, [generateBrowserToken, expired]);

  useEffect(() => {
    generateBrowserToken();
  }, [generateBrowserToken]);
};