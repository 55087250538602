import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import "./Ads.scss";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {fetchPopularProduct} from "../../redux/actions/popularProductAction";
import AdsSkeletonFavorites from "../Sceletons/AdsBlocksSkeleton/AdsSkeletonFavorites";
import LineHeaderBonusSkeleton from "../Sceletons/AdsBlocksSkeleton/LineHeaderBonusSkeleton";
import SliderSkeleton from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton";
import SliderSkeleton460 from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton460";
import SliderSkeleton420 from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton420";
import SliderSkeleton390 from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton390";
import SliderSkeleton350 from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton350";
import SliderSkeleton885 from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton885";
import SliderSkeleton620 from "../Sceletons/AdsBlocksSkeleton/SliderSkeleton620";

const gift = require("../../img/gift.svg").default;

interface AdsProps {
  isAuth?: boolean;
}

const adsBlockSkeletonArr = [1, 2, 3, 4]
const adsBlockSkeletonArr885 = [1, 2, 3]
const adsBlockSkeletonArr620 = [1, 2]

const Ads = ({isAuth}: AdsProps) => {
  const {t} = useTranslation();
  const { language, langKey } = useAppSelector(state => state.changeLangSlice);
  const dispatch = useAppDispatch();
  const {popularProduct, isLoadingPop} = useAppSelector(
      (state) => state.popularProduct
  );

  useEffect(() => {
    dispatch(fetchPopularProduct({selectedCategoryId: -1, offset: 0}));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 0,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 0,
          dots: false
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 0,
          dots: false
        }
      },
      {
        breakpoint: 710,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 0,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <>
      <div className="bonuses-list">
         {isLoadingPop ? (
             <div className="bonuses-list__content">
               <div className="line-header-skeleton"><LineHeaderBonusSkeleton/></div>
               <div className="media700-line-header-skeleton"><LineHeaderBonusSkeleton/></div>
               <div className="media500-line-header-skeleton"><LineHeaderBonusSkeleton/></div>
               <div className="media400-line-header-skeleton"><LineHeaderBonusSkeleton/></div>
               <div className="ads-blocks-skeleton">
                 {adsBlockSkeletonArr.map((item) => {
                   return <AdsSkeletonFavorites key={item}/>
                 })}
               </div>
               <div className="media885-ads-skeleton">
                   {adsBlockSkeletonArr885.map((item) => {
                       return <SliderSkeleton885 key={item}/>
                     })}
               </div>
               <div className="media620-ads-skeleton">
                 {adsBlockSkeletonArr620.map((item) => {
                   return <SliderSkeleton620 key={item}/>
                 })}
               </div>
               <div className="media530-ads-skeleton">
                 <SliderSkeleton/>
               </div>
               <div className="media470-ads-skeleton">
                 <SliderSkeleton460/>
               </div>
               <div className="media420-ads-skeleton">
                 <SliderSkeleton420/>
               </div>
               <div className="media390-ads-skeleton">
                 <SliderSkeleton390/>
               </div>
               <div className="media350-ads-skeleton">
                 <SliderSkeleton350/>
               </div>
             </div>
        ) : (
          <div className="bonuses-list__content">
            <div className="bonuses-list__header">
              <div className="bonuses-list__title">
                {t("accumulateRedeemBonuses")}
              </div>
            </div>
            <div className="bonuses-list__slider">
              <Slider {...settings}>
                {popularProduct?.slice(1, 5)?.map((item, index) => (
                  <Link
                    to={`/epay/${language}/product/${item.id}`}
                    key={item?.id}
                    // style={{ textDecoration: "none" }}
                    className="bonuses-slider__wrapper"
                  >
                    <div className="bonuses-list__bonus">
                      <div className="bonuses-list__bonus-image">
                        <img
                          className="bonuses-list__icon"
                          loading={"lazy"}
                          src={`data:image/png;base64, ${item.image}`}
                          alt="bonuses-list__icon"
                        />
                      </div>
                      <div
                        className="bonuses-list__bonus-title"
                        title={item.name}
                      >
                        {item.name}
                      </div>
                      <div className="bonuses-list__count-box">
                        <div className="bonuses-list__count">
                          {item.bonusValue}
                        </div>
                        <div className="bonuses-list__count-image">
                          <img
                            className="bonuses-list__icon"
                            src={gift}
                            loading={"lazy"}
                            alt="bonuses-list__icon"
                          />
                        </div>
                      </div>
                      {item.price ? (
                        <div className="bonuses-list__price">
                          {(item.price / 100).toFixed()}
                          <span>AZN</span>
                        </div>
                      ) : null}
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
            <div className="bonuses-list__list">
              {popularProduct?.slice(0, 4)?.map((item, index) => (
                <Link
                  to={`/epay/${language}/product/${item.id}`}
                  key={item?.id}
                  className="wrap-product"
                >
                  <div className="bonuses-list__bonus">
                    <div className="bonuses-list__bonus-image">
                      <img
                        className="bonuses-list__icon"
                        src={`data:image/png;base64, ${item.image}`}
                        alt="bonuses-list__icon"
                        loading={"lazy"}
                      />
                    </div>
                    <div
                      className="bonuses-list__bonus-title"
                      title={item.name}
                    >
                      {item.name}
                    </div>
                    <div className="bonuses-list__count-box">
                      <div className="bonuses-list__count">
                        {item.bonusValue}
                      </div>
                      <div className="bonuses-list__count-image">
                        <img
                            className="bonuses-list__icon"
                            src={gift}
                            alt="bonuses-list__icon"
                            loading={"lazy"}
                        />
                      </div>
                    </div>
                    {item.price ? (
                      <div className="bonuses-list__price">
                        {(item.price / 100).toFixed()}
                        <span>AZN</span>
                      </div>
                    ) : null}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
        <div className="bonuses-list__footer">
          <Link to={`/epay/${language}/catalogue`} style={{textDecoration: "none"}}>
            <Button
              buttonText={`${t("goToBonusStore")}`}
              background={"#EF5C27"}
              color={"white"}
              minWidth={330}
              border={"white 1px solid"}
              borderRadius={15}
              padding={"14px 66px"}
              margin="0 0 50px 0"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Ads;
