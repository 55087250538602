import React, {FC} from 'react';
import "./index.scss"
import {useTranslation} from "react-i18next";

interface SearchProps {
    onChangeSearchValue: (i: string) => void,
    searchValue?: string,
    inputString?: string,
}

const Search: FC<SearchProps> = ({onChangeSearchValue, inputString}) => {
    const {t} = useTranslation()
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onClickClear = () => {
        onChangeSearchValue('');
        inputRef.current?.focus();
    };

    return (
        <div className="search">
            <button className="search__search-icon">
                <img
                    className="search__icon-img"
                    src={require("../../img/search-icon.svg").default}
                    alt="search icon"/>
            </button>
            <input
                ref={inputRef}
                type="text"
                value={inputString}
                onChange={e => {

                    onChangeSearchValue(e.target.value)
                }}
                className="search__input"
                placeholder={`${t("nameOfService")}`}
            />
            {inputString?.trim() &&
                <button className="search__close-icon" onClick={onClickClear}>
                    <img className="search__icon-img" src={require("../../img/close-icon.svg").default} alt="cross icon" />
                </button>
            }
        </div>
    );
};

export default Search;
