import React from 'react';
import "./index.scss"

const MiniSpinner = () => {
    return (
        <div className="loader"></div>
    );
};

export default MiniSpinner;
