import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLangAction } from '../../redux/reducers/changeLangAction';
import { changeHref } from './changeHref';

export const useLanguageAndRouteHandler = (language: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const changeCanonical = () => {
    const canonicalLink = document.querySelector('link[rel="canonical"]') as HTMLAnchorElement | null;
    if(canonicalLink){
      canonicalLink.href = window.location.href;
    }
  };

  useEffect(() => {
    const pattern = /^\/epay\/(az|en|ru)/;
    let localLanguage = language || localStorage.getItem('language') || 'az';
    if (!pattern.test(location.pathname)) {
      if (!(localLanguage === 'az' || localLanguage === 'ru' || localLanguage === 'en')) {
        localLanguage = 'az';
      }
      navigate(`epay/${localLanguage}/home`);
    }

    const startIndex = location.pathname.indexOf('/epay/') + '/epay/'.length;
    const twoLetters = location.pathname.substring(startIndex, startIndex + 2);
    const capitalizedTwoLetters = twoLetters.charAt(0).toUpperCase() + twoLetters.slice(1);

    if (language !== twoLetters) {
      dispatch(changeLangAction(twoLetters, `value${capitalizedTwoLetters}`));
      i18n.changeLanguage(twoLetters, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        t('key');
      });
    }

    changeCanonical();
    const link = window.location.pathname.match(/\/(az|ru|en)\/(.*)/);
    if (link) {
      const pathAfterLanguage = link[2];
      changeHref(pathAfterLanguage);
    }

    window.scrollTo(0, 0);
  }, [location, language, navigate, dispatch, i18n]);
};