import React, {useEffect, useState} from 'react';
import CardsBox, {IInstrument} from "../../Components/CardsBox/CardsBox";
import {useAppSelector} from "../../hooks/redux";
import LayoutWithSidebar from "../../Components/layouts/LayoutWithSidebar/LayoutWithSidebar";
import HeaderText from "../../Components/CustomsComponents/HeaderText";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './index.scss'
import {addToFavoritesFetch} from "../../redux/actions/favoritesListAction";
import {getResultPaymentUrl, notify} from "../../helper";
import LayoutEmpty from "../../Components/layouts/LayoutEmpty/LayoutEmpty";
import RightBasketComponents from "../../Components/RightBasketComponents/RightBasketComponents";
import InputFieldComponent from "../../Components/MyCardComponent/InputField/InputField";
import {fetchTokenize} from "../../redux/actions/tokenize";
import {createHubCardData} from "../../utils/hubFunctions";
import {GuestActionCreators} from "../../redux/reducers/guest/action-creators";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import ContentLoader from "react-content-loader";
import ReCAPTCHA from "react-google-recaptcha";

const SelectPaymentMethodPage = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    const [canBonus, setCanBonus] = useState(false);
    const [instrument, setInstrument] = useState<any>(null);
    const [groupId, setGroupId] = useState(null);
    const [amountValue, setAmountValue] = useState("");
    const {bonusServices} = useAppSelector((state) => state.bonusServices);
    const [subVal, setSubVal] = useState("");
    const [subName, setSubName] = useState("");
    const [fields, setFields] = useState<any>([]);
    const [isClientExist, setIsClientExist] = useState(false);
    const { isAuth  } = useAppSelector(
        (state) => state.authReducer
    );
    // new
    const [reCaptcha, setReCaptcha] = useState(null);
    // const [isHubSolutionPaymentMethod, setIsHubSolutionPaymentMethod] = useState(false)
    const [isHubSolutionPaymentMethod, setIsHubSolutionPaymentMethod] = useState(true)
    const dispatch = useDispatch<AppDispatch>();
    const { browserToken } = useAppSelector(state => state.browserToken)
    const { language, langKey } = useAppSelector(state => state.changeLangSlice)
    const cardPanId = "newcard_visa";
    const [service, setService] = useState<any>(null);
    const [reCaptchaLoad, setReCaptchaLoad] = useState(true)
    const location = useLocation()
    const {state} = location;
    const isAmountCorrect = state?.isFilterToPay[0]?.amount > 0

    console.log("state payment", state)


    const isAuthToken = localStorage.getItem("token") || "";

    const fieldsJSON = JSON.stringify({fields});

    useEffect(() => {
        if (service && bonusServices?.length) {
            const hasBonus = bonusServices.find((bonusService: any) => bonusService?.id === service?.id);
            setCanBonus(hasBonus)
        }
    }, [bonusServices, service]);

    useEffect(()=>{
        if(!service) return

        const { availablePaymentSystem } = service

        let isHubSolutionPaymentMethod = false

        availablePaymentSystem.forEach((item:any)=>{
            if(item.paymentSystem==="HUB_SOLUTION" && item.paymentProcessor === "HUB_SOLUTION") {
                isHubSolutionPaymentMethod  = true
                return
            }
        })


        setIsHubSolutionPaymentMethod(isHubSolutionPaymentMethod)

    },[service])

    const payment = async () => {
        console.log("payment")

        if(isHubSolutionPaymentMethod) {
            const cardDataSpited = instrument.newCardData.expiryDate.split('/')

            const cardData = {
                CardHolder: 'e-pul',
                Cvv2: instrument.newCardData.cvc,
                ExpiryMonth: +cardDataSpited[0].trim(),
                ExpiryYear: +cardDataSpited[1].trim(),
                Pan: instrument.newCardData.cardNumber,
            }
            const { payload: cardToken } = await dispatch(
                fetchTokenize({
                    body: {
                        ...createHubCardData(instrument.newCardData),
                    },
                    browserToken: browserToken,
                }),
            )

            const url = await dispatch(
                GuestActionCreators.doGuestPay(
                    service.id, //2000
                    Number(amountValue), //0
                    fieldsJSON, // cartId, ExcludePayList
                    false,
                    subName,
                    subVal,
                    groupId || 0,
                    getResultPaymentUrl(language),
                    cardToken,
                    '',
                    4,
                ),
            )
            console.log(`url:`, url)

            if (url) {
                window.location = url;
            }

        } else {
            const url = await dispatch(
                GuestActionCreators.doGuestPay(
                    service.id,
                    Number(amountValue),
                    fieldsJSON,
                    false,
                    subName,
                    subVal,
                    groupId || 0,
                    getResultPaymentUrl(language),
                    cardPanId
                )
            );
            if (url) {
                window.location = url;
            }
        }

    }

    const onRecaptchaChange = (value: any) => {
        setReCaptcha(value)
    }

    const onBtnClick = async () => {
        console.log("onBtnClick")
        try {
            if (isAmountCorrect) {
                await payment();
            } else {
                if (!reCaptcha && service?.captcha > 0) {
                    notify(`${t("reCaptchaError")}`, false);
                    return
                }
                // await checkIfUserExist(service?.id, fieldsJSON, groupId || 0);
            }
        } catch (error) {
            notify(`${t("error")}`, false);
        } finally {
        }
    };

    return (
        <LayoutEmpty goBack={() => navigate(-1)} navTitle={t("basket")}>
            <div className="select-payment-method">
                {isAuth ?
                    <div className="select-payment-method-head">
                        <HeaderText title={t('selectPaymentMethod')}/>
                    </div>
                    :
                    <></>
                }

                <div className="select-payment-method__wrap">
                    <div className="select-payment-method__card-box">
                        {isAuth ? <CardsBox
                            canBonus={canBonus}
                            canWallet
                            setInstrument={(data: IInstrument) => {
                                setInstrument(data);
                            }}
                        /> :
                        <div style={{marginTop: '20px'}}>
                            {/*<InputFieldComponent/>*/}
                            {/*{isHubSolutionPaymentMethod && isAmountCorrect && <InputFieldComponent*/}
                            {isAmountCorrect && <InputFieldComponent
                                infoImage
                                cardDataCb={data =>{
                                    setInstrument({ newCardData: data, isNewCard: true })
                                }}
                            />}
                        </div>}
                        {!isAmountCorrect && service?.captcha > 0 && (
                            <div className='payment-recaptch'>
                                {reCaptchaLoad &&
                                    <ContentLoader
                                        speed={2}
                                        width={304}
                                        height={78}
                                        viewBox="0 0 304 78"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                    >
                                        <rect x="6" y="11" rx="0" ry="0" width="398" height="137" />
                                        <rect x="123" y="64" rx="0" ry="0" width="49" height="7" />
                                    </ContentLoader>}
                                <ReCAPTCHA
                                    sitekey={'6LfhleMoAAAAAIKvywPzSxtTk9K50bnPVl82gncg'}
                                    onChange={onRecaptchaChange}
                                    hl={language}
                                    asyncScriptOnLoad={() => {
                                        setReCaptchaLoad(false)
                                    }}
                                />
                            </div>
                        )}

                        <div className="select-payment-method-wrapBtn">
                            <button
                                disabled
                                // onClick={() => onBtnClick()}
                                className="payment-add__btn"
                            >
                                {t("pay")}
                            </button>
                        </div>
                    </div>
                    <div>
                        <RightBasketComponents/>
                    </div>
                </div>


            </div>

        </LayoutEmpty>
    );
};

export default SelectPaymentMethodPage;
