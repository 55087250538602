import React from 'react';
import '../index.scss'

const AdvantBonus = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Bonuslar nədir və necə istifadə olunur?"}</div>
                <div
                    className="about__right-text">{"E-Pul.az-ın unikal xidmətlərindən biri bonus hesablanmasıdır."}
                </div>
                <div className="about__right-text">
                    {'İstifadəçilərin çoxsaylı suallarını cavablandırmaq məqsədi ilə aşağıdakı məlumatları nəzərinizə çatdırırıq. Diqqətli olun! Mətndə linklər var.'}
                </div>
                <div className="about__right-text">
                    {"Bonusların unikallığı onların hesablanma və xərclənmə qaydalarıdır. Belə ki, Siz hər bir ödənişə görə xüsusi cədvəl üzrə bonus xallar əldə edirsiniz. Bu cədvəl vahid deyil və dəyişə bilər"}
                </div>
                <div className="about__right-text">
                    {"Bonus xallarınızın toplanmasını qrafik vasitəsilə sayt və ya mobil tətbiqdə izləyə bilərsiniz. Həmin bonuslar yığılır, müəyyən məbləğə çatanda Siz onları xərcləyə bilərsiniz."}
                </div>
                <div className="about__right-text">
                    {"Xərcləmək üçün Siz müvafiq bölümə daxil olursunuz və istədiyiniz ödənişi seçib bonusla icra edə bilərsiniz."}
                </div>
                <div className="about__right-text">
                    {"E-Pul.az-da bonuslar real ödəmələrə çevrilir. Bu hədiyyə və ya şans deyil. Sadəcə real ödəmələrdir."}
                </div>
                <div className="about__right-text">
                    {"Bu sistemdən maksimum faydalana bilərsiniz."}
                </div>
                <div className="about__right-text">
                    {"Məlumat üçün bildiririk ki, Bonus sistemi ancaq qeydiyyatdan keçmiş istifadəçilər üçün nəzərdə tutulub. Saytdan istifadə etdikdə, öz hesabınıza daxil olmağı unutmayın."}
                </div>
            </div>
        </div>
    );
};

export default AdvantBonus;