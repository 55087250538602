import React from "react";
import { useTranslation } from "react-i18next";
import './index.scss'
import { useForm, Controller } from "react-hook-form";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Button from "../Button/Button";
import CalendarIcon from "../../img/date-picker-icon.svg";

const InvoiceFields = ({getQr}) => {
    const {t} = useTranslation()
    const { control, formState, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
          date: null
        }
      });

    const { isValid } = formState;

    const onSubmit = data => {
        getQr(data.description, data.date)
    };

    return (
        <form className="invoice-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="invoice-form__field">
                <label>{t('description')}</label>
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <input {...field} />}
                />
            </div>
            <div className="invoice-form__field">
                <label>{t('activeTill')}</label>
                <Controller
                    name="date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <DatePicker
                            showIcon
                            selected={field.value}
                            onChange={date => field.onChange(date)}
                            minDate={new Date()}
                            dateFormat="dd.MM.yyyy"
                            icon={<img src={CalendarIcon}/>}
                        />
                    )}
                />
            </div>
            <Button
                height={50}
                width="100%"
                margin="20px 0 0"
                disabled={!isValid}
                padding="14px 40px"
                background="#EF5C27"
                color="#FFFFFF"
                buttonText={`${t("confirm")}`}
                borderRadius={15}
            />
        </form>
    )
};

export default InvoiceFields;