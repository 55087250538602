import ServiceActionTypes from './types';

const initialState = {
  service: {},
  isHubSolutionPaymentMethod: false,
  allHubSolutionPaymentMethodHidden: false,
  error: false,
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceActionTypes.SET_SERVICE: {
      return { ...state, service: action.payload };
    }

    case ServiceActionTypes.IS_HUB_SOLUTION_PAYMENT_METHOD: {
      return { ...state, isHubSolutionPaymentMethod: action.payload };
    }

    case ServiceActionTypes.SET_ERROR: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

export default serviceReducer;
