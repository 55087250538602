import React from 'react';
import '../index.scss'

const AdvantFaktura = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Faktura"}</div>
                <div
                    className="about__right-text">{"Dəyərli istifadəçilər, E-Pul.az növbəti yeniliyini ixtiyarınıza verir."}
                </div>
                <div className="about__right-text">
                    {'Bu gündən etibarən  Faktura xidmətindən istifadə etmə imkanınız var.'}
                </div>
                <div className="about__right-text">
                    {'Bu xidmət iki formada təqdim olunur:'}
                </div>
                <div className="about__right-text">
                    {'Pul Kisəsi üzrə-Pul kisəmə pul köçür;'}
                </div>
                <div className="about__right-text">
                    {'Kommunal və digər ödənişlər üzrə-Mənim borcumu ödə.'}
                </div>
                <div className="about__right-text">
                    {'Bu xidmətdən istifadə edərək, E-Pul.az-da təqdim olunan istənilən xidmət üzrə borcunuzun ödənilməsini digər istifadəçiyə və E-Pul istifadəçisi olmayan şəxsə Faktura göndərməklə reallaşdıra bilərsiniz.'}
                </div>
                <div className="about__right-text">
                    {'Fakturada xidmət, ödəniləcək məbləğ və Fakturanı göndərəcəyiniz istifadəçinin telefon nömrəsi qeyd olunmalıdır.'}
                </div>
                <div className="about__right-text">
                    {'Pul Kisəsinin balansının artırılması ilə bağlı Fakturada isə mövcud Pul Kisənizə vəsaitin yatırılmasına dair E-Pul.az-ın digər istifadəçisinə Faktura göndərə bilərsiniz.'}
                </div>
                <div className="about__right-text">
                    {'Qarşı tərəf Faktura əsasında olan ödənişləri komissiyasız yerinə yetirir və  Bonuslara sahib olur.'}
                </div>
                <div className="about__right-text">
                    {'Fakturanın qüvvədə olma müddəti maksimum 1 ay hesab olunur.'}
                </div>
                <div className="about__right-text">
                    {'Qarşı tərəf ödənişlərinizi yerinə yetirə və ya göndərilən Fakturadan imtina edə bilər. Həmçinin, göndərən şəxs Fakturanı dayandıra bilər.'}
                </div>
                <div className="about__right-text">
                    {'E-Pul.az hesabınıza daxil olaraq yeni Faktura xidmətindən rahatlıqla istifadə edin.'}
                </div>
            </div>
        </div>
    );
};

export default AdvantFaktura;