/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./index.scss";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 367,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

interface ExitModal {}

const ExitModal = (props: ExitModal) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal">
            <div className="modal__head"> {"Выйти из профиля?"} </div>
            <div onClick={handleClose}>
              <img
                className="modal__head-icon"
                src={require("../../img/close-icon.svg").default}
              />
            </div>
          </div>
          <div>
            <div className="modal__footer">
              <button className="modal__footer-button">{"Выйти"}</button>
            </div>
            <p className="modal__footer-text">{"Отмена"}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ExitModal;
