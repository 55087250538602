import React from 'react';
import RegistrationLeftMain from "../../Components/RegistrationLeftMain/RegistrationLeftMain";
import './index.scss'

const Authorization = () => {
    return (
        <div className='auth-page'>
            <RegistrationLeftMain onlyEntry/>
        </div>
    );
};

export default Authorization;