import React, {useEffect, useState} from 'react'
import { Link, useLocation } from "react-router-dom";
import { t } from 'i18next';
import { useAppSelector } from '../../hooks/redux';
import './index.scss';

export const Breadcrumbs = () => {
    const location = useLocation();
    const { language, langKey } = useAppSelector(state => state.changeLangSlice);
    const { categories, services } = useAppSelector((state) => state.categoriesReducer);
    const [categoryName, setCategoryName] = useState('')
    const [serviceName, setServiceName] = useState('')
    const [categoryNameFriendly, setCategoryNameFriendly] = useState('')
    let categoryFriendlyName, serviceFriendlyName;
    const parts = location.pathname.split('/');

    const generate = () => {
        deleteSchema()
        if (categories.length && !location.pathname.includes('add-recurring')) {
            if (location.pathname.includes('/payment-add')) {
                categoryFriendlyName = parts[5]
                serviceFriendlyName = parts[6]
            } else {
                categoryFriendlyName = parts[4]
                serviceFriendlyName = parts[5]
            }
            setCategoryNameFriendly(categoryFriendlyName)
            const category = categories.find(item => item.name == categoryFriendlyName)
            setCategoryName(category?.localName[langKey])
            let service
            if (serviceFriendlyName) {
                service = services.find(item => item.friendlyName == serviceFriendlyName)
                setServiceName(service?.localName[langKey])
            }
            generateSchema(category, service)
        }
    }

    useEffect(() => {
        generate()
    }, [categories])

    useEffect(() => {
      return () => {  
        deleteSchema()
      }
    }, [])

    const generateSchema = (category, service) => {
      const jsonLdData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            position: 1,
            name: `EPUL: ${t('onlinePayment')}`,
            item: `https://www.e-pul.az/epay/${language}/home`
          },
          {
            "@type": "ListItem",
            position: 2,
            name: category.localName[langKey],
          }
        ]
      };

      if (service) {
        jsonLdData["itemListElement"][1].item = `https://www.e-pul.az/epay/${language}/category/${category.name}`
        jsonLdData["itemListElement"].push({
          "@type": "ListItem",
          position: 3,
          name: service.localName[langKey]
        })
      }
    
      const jsonLdString = JSON.stringify(jsonLdData, null, 2);
      const scriptTag = document.createElement("script");
      scriptTag.type = "application/ld+json";
      scriptTag.text = jsonLdString;
      document.head.appendChild(scriptTag);
    }
    
    const deleteSchema = () => {
      const scriptTags = document.querySelectorAll('script[type="application/ld+json"]');
      scriptTags.forEach((scriptTag) => {
        try {
          const scriptData = JSON.parse(scriptTag.textContent);
          if (scriptData["@type"] === "BreadcrumbList") {
            scriptTag?.parentNode?.removeChild(scriptTag);
          }
        } catch (error) {
          console.error("Error parsing JSON in script tag:", error);
        }
      });
    }
  
    return (
        <div className='breadcrumb'>
            <Link to={`/epay/${language}/home`}>EPUL: {t('onlinePayment')}</Link><div className='divider'>{' > '}</div>
            {location.pathname.includes('/payment-add') ?
            <>
            {serviceName ?
                <Link to={`/epay/${language}/category/${categoryNameFriendly}`}>{categoryName}</Link>
                :
                <div>{categoryName}</div>
            }
                <div className='divider'>{serviceName ? ' > ' : ''}</div>
                <div>{serviceName}</div>
            </> :
            <>
            {serviceName ?
                <Link to={`/epay/${language}/category/${categoryNameFriendly}`}>{categoryName}</Link>
                :
                <div>{categoryName}</div>
            }
                <div className='divider'>{serviceName ? ' > ' : ''}</div>
                <div>{serviceName}</div>
            </>
            }
        </div>
    );
  }