import React, {useEffect, useState} from 'react';
import "../../Components/FavoritesComponent/index.scss"
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { editFavoritesFetch, fetchFavoritesList } from '../../redux/actions/favoritesListAction';
import Button from '../../Components/Button/Button';
import { notify } from '../../helper';
import CustomModal from '../../Components/ModalComponent/CustomModal/CustomModal';
import OtherPayments from '../../Components/OtherPayments/OtherPayments';
import OtherPaymentSwiper from '../../Components/OtherPayments/OtherPaymentSwiper';
import LayoutEmpty from '../../Components/layouts/LayoutEmpty/LayoutEmpty';
import { createFavoriteFields, createFavoriteLink } from './functions';
import EditIcon from './EditIcon';
import FavoriteImage from './FavoriteImage';

const FavoriteInfo = () => {
  const { id } = useParams();
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { favList } = useAppSelector((state) => state.favList)
  const { language, langKey } = useAppSelector(state => state.changeLangSlice);
  const { services, categories } = useAppSelector((state) => state.categoriesReducer);
  const [fieldValue, setFieldValue] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const favorite = favList ? favList?.items.paymentListItem?.find(item => item.id == id) : null;

  useEffect(() => {
    if (!favList) {
      dispatch(fetchFavoritesList());
    }
  }, [favList]);

  const openModal = () => {
    setModalActive(true);
  };

  const onSaveClick = async () => {
    const fields = createFavoriteFields(favorite);
    if (fieldValue.name !== 'comment') {
      fields[fieldValue.index].value = fieldValue.value;
    }
    const editFavoritePayload = {
      id: favorite?.id,
      comment: fieldValue.name === 'comment' ? fieldValue.value : favorite.comment,
      fields: JSON.stringify({ fields }),
      serviceId: favorite.serviceId,
      selectedGroupId: favorite?.groupId,
    };

    try {
      const result = await dispatch(editFavoritesFetch(editFavoritePayload));
      if (result?.payload.data.resultCodes === "OK") {
        notify(`${t("successfullyModified")}`, true);
      } else {
        notify(`${t("errorSavingChanges")}`, false);
      }
    } catch (error) {
      notify(`${t("errorSavingChanges")}`, false);
      console.error("Error saving changes:", error);
    }

    setModalActive(false);
  };

  return (
    <LayoutEmpty goBack={() => navigate(-1)}>
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        {favorite &&
          <div className="favorite-info">
            <div className="favorite-info__head">
              <p className='favorite-info__head__title'>{favorite?.localName[langKey]}</p>
              <FavoriteImage favorite={favorite}/>
            </div>
            <div className="favorite-info__body">
              {favorite?.fields.paymentListField?.map((item, index) => {
                return (
                  <div className="favorite-info__body__row">
                    <div className="favorite-info__body__row__value">
                      <p>{item.localName[langKey]}</p>
                      <p>{item.value}</p>
                    </div>
                    <EditIcon favorite={favorite} setFieldValue={setFieldValue} openModal={openModal} fieldName={'field'} index={index}/>
                  </div>
              )})}
              <div className="favorite-info__body__row">
                <div className="favorite-info__body__row__value">
                  <p>{t("comment")}</p>
                  <p>{favorite.comment}</p>
                </div>
                <EditIcon favorite={favorite} setFieldValue={setFieldValue} openModal={openModal} fieldName={'comment'}/>
            </div>
            </div>
            <Link
              to={createFavoriteLink(favorite, categories, services, language)}
              state={{
                favorite: true,
                serviceId: favorite.serviceId,
                fields: createFavoriteFields(favorite),
                groupId: favorite.groupId,
              }}
              className="favorite-info__pay"
            >
              <Button
                buttonText={`${t("pay")}`}
                height={50}
                width={"100%"}
                borderRadius={15}
                background={'#EF5C27'}
                color={'#FFFFFF'}
              />
            </Link>
          </div>
        }
        <>
          <div className="payment-add__other">
            <OtherPayments/>
          </div>
          <div className="payment-add__swiper">
            <div className="layout__app-otherWrap">
              <div className='layout__app-otherWrap-border'></div>
              <div className="layout__app-otherSwiper">
                <OtherPaymentSwiper/>
              </div>
            </div>
          </div>
        </>
        <CustomModal active={modalActive} setActive={setModalActive}>
          <div className="modal-service-code">
            <div className="modal-service-code__close">
              <img
                onClick={() => setModalActive(false)}
                src={require("../../img/close-icon.svg").default}
                alt="close"
                width={20}
                height={20}
                loading={"lazy"}
              />
            </div>
            <input
              value={fieldValue?.value}
              onChange={(e) => setFieldValue({value: e.target.value, name: fieldValue.name, index: fieldValue?.index})}          
            />
            <div className="modal-service-code__btn">
              <Button
                buttonText={`${t("save")}`}
                minWidth={300}
                background="#EF5C27"
                color="#FFFFFF"
                borderRadius={15}
                padding="14px 42px"
                onClick={onSaveClick}
              />
            </div>
          </div>
        </CustomModal>
      </div>
    </LayoutEmpty>
  );
};

export default FavoriteInfo;