import {createSlice} from "@reduxjs/toolkit";
import { Service } from "../../models/Categories";
import { getC2cService, getCcService, getRcService, getW2cService } from "../actions/card2cardAction";

export interface ICard2CardState {
    c2c?: Service;
    w2c?: Service;
    currencyConverter?: Service;
    refillCard?: Service;
    hash?: string;
}

const initialState: ICard2CardState = {
}

export const card2cardSlice = createSlice({
    name: "card2card",
    initialState,
    reducers: {
        setC2cService(state, action) {
            state.c2c = action.payload
        },
        setW2cService(state, action) {
            state.w2c = action.payload
        },
        setCurrencyConverterService(state, action) {
            state.currencyConverter = action.payload
        },
        setRefillCardService(state, action) {
            state.refillCard = action.payload
        },
        setHash(state, action) {
            state.hash = action.payload
        },
    },
    extraReducers: {
        [getW2cService.fulfilled.type]: (state, action) => {
            state.w2c = action.payload
        },
        [getCcService.fulfilled.type]: (state, action) => {
            state.currencyConverter = action.payload
        },
        [getRcService.fulfilled.type]: (state, action) => {
            state.refillCard = action.payload
        },
        [getC2cService.fulfilled.type]: (state, action) => {
            state.c2c = action.payload?.json
        }
    }
})

export default card2cardSlice.reducer
export const card2cardSliceActions = {
    ...card2cardSlice.actions
};
