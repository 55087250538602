import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../HomePage/HomePage';
import RecurringCreate from '../RecurringPayments/RecurringCreate/RecurringCreate';
import RecurringEdit from '../RecurringPayments/RecurringEdit/RecurringEdit';
import RecurringPaymentsList from '../RecurringPayments/RecurringPaymentsList/RecurringPaymentsList';
import RecurringPeriod from '../RecurringPayments/RecurringPeriod/RecurringPeriod';
import RecurringTransaction from '../RecurringPayments/RecurringTransaction/RecurringTransaction';
import MessageComponent from '../../pages/MessagePage/MessageComponent';
import MessageInfoComponent from '../../pages/MessageInfoPage/MessageInfoComponent';
import FavoritesComponent from '../../pages/FavoritesPage/FavoritesComponent';
import NewFavorites from '../../pages/FavoritesPage/CustomFavorites';
import MyCard from '../MyCardComponent/MyCard';
import AddMyCard from '../MyCardComponent/AddMyCard';
import ModalComponent from '../ModalComponent/ModalComponent';
import ModalDeleteCardComponent from '../ModalComponent/ModalDeleteCardComponent';
import ModalRenameCardComponent from '../ModalComponent/ModalRenameCardComponent';
import AutoPayments from '../AutoPayments/AutoPayments/AutoPayments';
import InstallApp from '../CustomsComponents/InstallApp/InstallApp';
import CreateAutoPayment from '../AutoPayments/CreateAutoPayment/CreateAutoPayment';
import SelectedCategory from '../CustomsComponents/SelectedCategory';
import TabsComponent from '../AutoPayments/Tabs/TabsComponent';
import EditPaymentPage from '../AutoPayments/EditPaymentPage/EditPaymentPage';
import PaymentPeriodPage from '../AutoPayments/PaymentPeriodPage/PaymentPeriodPage';
import AutoPaymentModal from '../ModalComponent/AutoPaymentModal';
import FAQ from '../FAQ/FAQ';
import History from '../History/History';
import InstallAppAds from '../CustomsComponents/InstallAppAds';
import DebtInvoice from '../Debts/DebtInvoice';
import DebtMobile from '../Debts/DebtMobile';
import Debt from '../Debts/Debt';
import Settings from '../Settings/Settings/Settings';
import Notifications from '../Settings/Notifications/Notifications';
import Security from '../Settings/Security/Security/Security';
import ChangePassword from '../Settings/Security/Password/ChangePassword';
import Cvv from '../Settings/Security/Cvv/Cvv';
import SafetyCode from '../Settings/Security/SafetyCode/SafetyCode';
import Bonuses from '../Bonuses/Bonuses/Bonuses';
import Catalogue from '../Bonuses/Catalogue/Catalogue';
import Product from '../Bonuses/Product/Product';
import PayCommunal from '../PayCommunal/PayCommunal';
import CenterMode from '../MyCardComponent/CenterMode';
import WalletDetails from '../Ewallet/WalletDetails/WalletDetails';
import Transaction from '../Ewallet/Transaction/Transaction';
import TopUpBalance from '../Ewallet/TopUpBalance/TopUpBalance';
import Identification from '../Ewallet/Identification/Identification';
import Office from '../Ewallet/Office/Office';
import AsanImza from '../Ewallet/AsanImza/AsanImza';
import Online from '../Ewallet/Online/Online';
import Success from '../Ewallet/Success/Success';
import ModalVirtualCard from '../MyCardComponent/ModalVirtualCard/ModalVirtualCard';
import ExitModal from '../ModalComponent/ExitModal';
import Service from '../Service/Service';
import InputFieldComponent from '../MyCardComponent/InputField/InputField';
import FormattedInputs from '../inputComponent/inputComponent';
import Authorization from '../../pages/Authorization';
import About from '../../pages/About/About';
import SimaIdentification from '../Ewallet/SimaIdentification/SimaIdentification';
import Blog from '../../pages/Blog/Blog';
import Advantages from '../../pages/Advantages/Advantages';
import Payment from '../../pages/Payment/Service';
import DontInformation from '../CustomsComponents/DontInformation/DontInformation';
import RegisterForm from '../../pages/Register';
import TransferCard from '../../pages/TransferCard/TransferCard1';
import AdvantWallet from '../../pages/Advantages/AdvantWallet/AdvantWallet';
import AdvantBonus from '../../pages/Advantages/AdvantWallet/AdvantBonus';
import AdvantDebts from '../../pages/Advantages/AdvantWallet/AdvantDebts';
import AdvantAutoPayment from '../../pages/Advantages/AdvantWallet/AdvantAutoPayment';
import AdvantCartRefister from '../../pages/Advantages/AdvantWallet/AdvantCartRefister';
import AdvantHistory from '../../pages/Advantages/AdvantWallet/AdvantHistory';
import AdvantC2C from '../../pages/Advantages/AdvantWallet/AdvantC2C';
import AdvantFaktura from '../../pages/Advantages/AdvantWallet/AdvantFaktura';
import GuestCheckScreen from '../../pages/Payment/GuestCheck';
import AutoPaymentTransaction from '../AutoPayments/AutoPaymentTransaction/AutoPaymentTransaction';
import CreateTemplates from '../AutoPayments/CreateAutoPayment/CreateTemplates';
import Check from '../../pages/Payment/Check';
import AsanImzaBind from '../Settings/AsanImza/AsanImza';
import BonusesPaymentCheck from '../Bonuses/BonusesPaymentCheck/BonusesPaymentCheck';
import AdvantSecurity from '../../pages/Advantages/AdvantWallet/AdvantSecurity';
import Categories from '../Categories/Categories';
import Services from '../Services/Services';
import PaymentAdd from '../PaymentAdd/PaymentAdd';
import GuestPayment from '../GuestPayment/GuestPayment';
import AddFavorite from '../../pages/FavoritesPage/AddFavorite';
import InviteFriend from '../../pages/InviteFriend/InviteFriend';
import MorePage from '../../pages/MorePage';
import HeaderAdaptiv from '../HeaderAdaptiv/HeaderAdaptiv';
import CategoriesComponent from '../Bonuses/Catalogue/CategoriesComponent';
import CreateDigitalCard from '../MyCardComponent/DigitalCatd/CreateDigitalCard';
import TransferWallet from '../../pages/TransferCard/TransferWallet';
import NotFound from '../NotFound/NotFound';
import './../../index';
import Loading from '../Loading/Loading';
import InvoiceTopUp from '../../pages/InvoiceTopUp/InvoiceTopUp';
import FavoriteInfo from '../../pages/FavoritesPage/FavoriteInfo';
import BasketPage from "../../pages/BasketPage/BasketPage";
import SelectPaymentMethodPage from "../../pages/SelectPaymentMethodPage/SelectPaymentMethodPage";
import EditBasketPage from "../../pages/BasketPage/EditBasketPage";

export const baseRoute = 'epay/:lang';
const PrivacyPolicy = lazy(() => import('../../pages/PrivacyPolicy/PrivacyPolicy'));

export const AppRoutes = () => {
  return (
    <Routes>
    <Route path={`${baseRoute}/home`} element={<HomePage />} />
    <Route path={`${baseRoute}/adaptiv`} element={<HeaderAdaptiv />} />
    <Route path={`${baseRoute}/sign/in`} element={<Authorization />} />
    <Route path={`${baseRoute}/sign/up`} element={<RegisterForm />} />
    <Route
      path={`${baseRoute}/message-info`}
      element={<MessageInfoComponent />}
    />
    <Route
      path={`${baseRoute}/message-info/:id`}
      element={<MessageInfoComponent />}
    />
    <Route path={`${baseRoute}/message`} element={<MessageComponent />} />
    <Route
      path={`${baseRoute}/favorites`}
      element={<FavoritesComponent />}
    />
    <Route path={`${baseRoute}/add-favorite`} element={<AddFavorite />} />
    <Route path={`${baseRoute}/new-favorites`} element={<NewFavorites />} />
    <Route
      path={`${baseRoute}/favorite-info/:id`}
      element={<FavoriteInfo />}
    />
    <Route path={`${baseRoute}/my-card`} element={<MyCard />} />
    <Route path={`${baseRoute}/add-my-card`} element={<AddMyCard />} />
    {/*<Route path={`${baseRoute}/create-virtual-card`} element={<CreatedVirtualCard />} />*/}
    <Route
      path={`${baseRoute}/create-virtual-card`}
      element={<CreateDigitalCard />}
    />
    {/*<Route path={`${baseRoute}/add-my-virtual-card`} element={<AddMyVirtualCard />} />*/}
    <Route
      path={`${baseRoute}/modal-custom`}
      element={<ModalComponent />}
    />
    <Route
      path={`${baseRoute}/modal-delete`}
      element={<ModalDeleteCardComponent />}
    />
    <Route
      path={`${baseRoute}/modal-rename`}
      element={<ModalRenameCardComponent />}
    />
    <Route path={`${baseRoute}/exit-modal`} element={<ExitModal />} />
    <Route path={`${baseRoute}/center-mode`} element={<CenterMode />} />
    <Route path={`${baseRoute}/auto-payments`} element={<AutoPayments />} />
    <Route path={`${baseRoute}/install-app`} element={<InstallApp />} />
    <Route
      path={`${baseRoute}/install-app-ads`}
      element={<InstallAppAds />}
    />
    <Route
      path={`${baseRoute}/create-payments`}
      element={<CreateAutoPayment />}
    />
    <Route
      path={`${baseRoute}/create-templates`}
      element={<CreateTemplates />}
    />
    <Route
      path={`${baseRoute}/selected-category`}
      element={<SelectedCategory />}
    />
    <Route path={`${baseRoute}/payment/:id`} element={<Payment />} />
    <Route path={`${baseRoute}/tabs`} element={<TabsComponent />} />
    <Route
      path={`${baseRoute}/edit-payment/:id`}
      element={<EditPaymentPage />}
    />
    <Route
      path={`${baseRoute}/payment-page`}
      element={<PaymentPeriodPage />}
    />
    <Route
      path={`${baseRoute}/auto-payment-modal`}
      element={<AutoPaymentModal />}
    />
    <Route path={`${baseRoute}/debts-invoice`} element={<DebtInvoice />} />
    <Route path={`${baseRoute}/debt-mobile`} element={<DebtMobile />} />
    <Route path={`${baseRoute}/debt`} element={<Debt />} />
    <Route path={`${baseRoute}/history`} element={<History />} />
    <Route path={`${baseRoute}/invite-friend`} element={<InviteFriend />} />
    <Route path={`${baseRoute}/faq`} element={<FAQ />} />
    <Route path={`${baseRoute}/settings`} element={<Settings />} />
    <Route path={`${baseRoute}/bonuses`} element={<Bonuses />} />
    <Route
      path={`${baseRoute}/bonuses/payment/:param/:id`}
      element={<BonusesPaymentCheck />}
    />
    <Route path={`${baseRoute}/catalogue`} element={<Catalogue />} />
    <Route path={`${baseRoute}/product`} element={<Product />} />
    <Route path={`${baseRoute}/product/:id`} element={<Product />} />
    <Route
      path={`${baseRoute}/notifications`}
      element={<Notifications />}
    />
    <Route path={`${baseRoute}/asan-imza`} element={<AsanImzaBind />} />
    <Route path={`${baseRoute}/security`} element={<Security />} />
    <Route
      path={`${baseRoute}/change-password`}
      element={<ChangePassword />}
    />
    <Route path={`${baseRoute}/check-cvv`} element={<Cvv />} />
    <Route
      path={`${baseRoute}/change-safety-code`}
      element={<SafetyCode />}
    />
    <Route path={`${baseRoute}/pay-communal`} element={<PayCommunal />} />
    <Route path={`${baseRoute}/e-wallet`} element={<WalletDetails />} />
    <Route path={`${baseRoute}/transaction`} element={<Transaction />} />
    <Route
      path={`${baseRoute}/top-up-balance`}
      element={<TopUpBalance />}
    />
    <Route
      path={`${baseRoute}/identification`}
      element={<Identification />}
    />
    <Route
      path={`${baseRoute}/identification/sima`}
      element={<SimaIdentification />}
    />
    <Route
      path={`${baseRoute}/identification/office`}
      element={<Office />}
    />
    <Route
      path={`${baseRoute}/identification/online`}
      element={<Online />}
    />
    <Route
      path={`${baseRoute}/identification/asan-imza`}
      element={<AsanImza />}
    />
    <Route
      path={`${baseRoute}/identification/success`}
      element={<Success />}
    />
    <Route
      path={`${baseRoute}/modal-virtual-card`}
      element={<ModalVirtualCard />}
    />
    <Route
      path={`${baseRoute}/input-field-component`}
      element={<InputFieldComponent />}
    />
    <Route path={`${baseRoute}/service`} element={<Service />} />
    {/* <Route
      path={`${baseRoute}/result/payment/check`}
      element={<GuestCheckScreen />}
    /> */}
    <Route
      path={`${baseRoute}/result/payment/:params?`}
      element={<Check />}
    />
    <Route
      path={`${baseRoute}/result/payment/check/:params?`}
      element={<Check />}
    />
    <Route path={`${baseRoute}/about_company`} element={<About />} />
    <Route
      path={`${baseRoute}/advantages-wallet`}
      element={<AdvantWallet />}
    />
    <Route
      path={`${baseRoute}/slick-slider`}
      element={<CategoriesComponent />}
    />
    <Route
      path={`${baseRoute}/advantages-bonus`}
      element={<AdvantBonus />}
    />
    <Route
      path={`${baseRoute}/advantages-debts`}
      element={<AdvantDebts />}
    />
    <Route
      path={`${baseRoute}/advantages-security`}
      element={<AdvantSecurity />}
    />
    <Route
      path={`${baseRoute}/advantages-auto-payment`}
      element={<AdvantAutoPayment />}
    />
    <Route
      path={`${baseRoute}/advantages-cart-register`}
      element={<AdvantCartRefister />}
    />
    <Route
      path={`${baseRoute}/advantages-history`}
      element={<AdvantHistory />}
    />
    <Route path={`${baseRoute}/advantages-c2c`} element={<AdvantC2C />} />
    <Route
      path={`${baseRoute}/advantages-faktura`}
      element={<AdvantFaktura />}
    />
    <Route path={`${baseRoute}/blog`} element={<Blog />} />
    <Route
      path={`${baseRoute}/privacy-policy`}
      element={
        <Suspense fallback={<Loading />}>
          {' '}
          <PrivacyPolicy />
        </Suspense>
      }
    />
    <Route
      path={`${baseRoute}/dont-information`}
      element={<DontInformation />}
    />
    <Route path={`${baseRoute}/advantages`} element={<Advantages />} />
    <Route
      path={`${baseRoute}/format-input`}
      element={<FormattedInputs />}
    />
    <Route
      path={`${baseRoute}/auto-payment-transaction/:process/:serviceId`}
      element={<AutoPaymentTransaction />}
    />
    <Route path={`${baseRoute}/c2c/:id?`} element={<TransferCard />} />
    <Route
      path={`${baseRoute}/transfer-wallet/:id?`}
      element={<TransferWallet />}
    />
    {/* /result/autoOrder/success?mirrorId=1715756905045 */}
    <Route
      path={`${baseRoute}/recurring-payments`}
      element={<RecurringPaymentsList />}
    />
    <Route
      path={`${baseRoute}/recurring-payments/epay/result/autoorder/:slug`}
      element={<RecurringPaymentsList />}
    />
    <Route
      path={`${baseRoute}/result/autoOrder/:slug/:slug?`}
      element={<RecurringPaymentsList />}
    />
    <Route
      path={`${baseRoute}/category/add-recurring`}
      element={<Categories />}
    />
    <Route
      path={`${baseRoute}/category/add-favorite`}
      element={<Categories />}
    />
    <Route
      path={`${baseRoute}/category/:category`}
      element={<Services />}
    />
    <Route
      path={`${baseRoute}/category/:category/add-recurring`}
      element={<Services />}
    />
    <Route
      path={`${baseRoute}/category/:category/add-favorite`}
      element={<Services />}
    />
    <Route
      path={`${baseRoute}/recurring-transaction/:process`}
      element={<RecurringTransaction />}
    />
    <Route
      path={`${baseRoute}/recurring-period`}
      element={<RecurringPeriod />}
    />
    <Route
      path={`${baseRoute}/recurring-edit/:id`}
      element={<RecurringEdit />}
    />
    <Route
      path={`${baseRoute}/recurring-create`}
      element={<RecurringCreate />}
    />
    <Route
      path={`${baseRoute}/payment-add/category/:categoryName/:serviceName`}
      element={<PaymentAdd />}
    />
    <Route
      path={`${baseRoute}/category/:categoryName/:serviceName`}
      element={<GuestPayment />}
    />
    <Route path={`${baseRoute}/more-page`} element={<MorePage />} />
    <Route path={`${baseRoute}/invoice/:id`} element={<InvoiceTopUp />} />
    <Route path={`${baseRoute}/basket`} element={<BasketPage/>}/>
    <Route path={`${baseRoute}/basket/edit-item/:id`} element={<EditBasketPage/>}/>
    <Route path={`${baseRoute}/select-payment-method`} element={<SelectPaymentMethodPage/>}/>
    <Route path={`${baseRoute}/*`} element={<NotFound />} />
  </Routes>
  )
}