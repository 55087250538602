import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import HeaderText from "../CustomsComponents/HeaderText/HeaderText";
import NavigationComponent from "../CustomsComponents/NavigationComponent";
import './index.scss'
import AddCheckboxIn from "../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import InputFieldComponent from "./InputField/InputField";
import { useAppSelector } from '../../hooks/redux';
import { useTranslation } from "react-i18next";
import { LoadingLocal } from "../Loading/Loading";
import LayoutEmpty from "../layouts/LayoutEmpty/LayoutEmpty";
import { fetchTokenize } from '../../redux/actions/tokenize'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { createHubCardData } from '../../utils/hubFunctions';
import { fetchCardAdd } from '../../redux/actions/cardsActions';
import {FREE_PAY_URL} from "../../utils/consts";


type cardDataType = {
    cvc: string,
    cardName?: string,
    cardNumber: string,
    expiryDate: string,
}
const AddMyCard = () => {
    const { t } = useTranslation();
    const { forms } = useAppSelector(state => state.masterPassReducer)
    const location = useLocation();
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setsDisabled] = useState(true);
    const { isPaymentLoading } = useAppSelector(state => state.loading)
    const hasName = !!(isChecked || location?.state?.hasDefaultNameField);
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const { browserToken } = useAppSelector(state => state.browserToken)

    const dispatch = useDispatch<AppDispatch>()

    const [inputFieldErrors, setInputFieldErrors] = useState({
        cardNumber: '',
        expiryDate: '',
        cvc: '',
        zip: '',
    });

    const navigate = useNavigate();
    const goBack = () => navigate(`/epay/${language}/home`)
    const goBackPage = () => navigate(-1)
    const [newCardData, setNewCardData] = useState<cardDataType>({
        cvc: '',
        cardName: '',
        cardNumber: '',
        expiryDate: '',
    })


    const addNewCard = async () => {
        // setIsLoading(true);
        // forms?.addCard(
        //     newCardData.cardName ? newCardData.cardName : '',
        //     newCardData.cardNumber,
        //     newCardData.expiryDate.substring(4, 7).trim(),
        //     newCardData.expiryDate.substring(0, 3).trim(),
        //     newCardData.cvc,
        //     () => {
        //         setIsLoading(false);
        //         navigate(`/epay/${language}my-card`)
        //     }
        // )

        const cardDataSpited = newCardData.expiryDate.split('/')

        const cardData = {
        CardHolder: 'e-pul',
        Cvv2: newCardData.cvc,
        ExpiryMonth: +cardDataSpited[0].trim(),
        ExpiryYear: +cardDataSpited[1].trim(),
        Pan: newCardData.cardNumber,
        }

        try {
            const { payload: cardToken } = await dispatch(
              fetchTokenize({
                body: {
                    ...createHubCardData(newCardData),
                },
                browserToken: browserToken,
              }),
            )

            const {payload: addCard} = await dispatch(
              fetchCardAdd({
                body: {
                  CardToken: cardToken,
                  Name: newCardData.cardName,
                  RedirectUrl: `${window.location.origin}/epay/${language}/my-card`,
                },
                browserToken: browserToken,
              }),
            )

            const redirectUrl = (addCard as any).data.redirectUrl

            if(redirectUrl) {
                window.location.href= `${FREE_PAY_URL}/${redirectUrl}`
            }



          } catch (error) {
            console.log('error')
          }

    }

    let checkFirstTwoNumbers = () => {
        const cardFirstTwoSymbols = newCardData?.cardNumber.slice(0, 2);
        if ((cardFirstTwoSymbols === '34' || cardFirstTwoSymbols === '37') && newCardData?.cardNumber?.length === 15) {
            return true;
        }
    }

    //@ts-ignore
    const handleInputFieldErrorsUpdate = (errors) => {
        setInputFieldErrors(errors)
    };

    useEffect(() => {
        if (inputFieldErrors.cardNumber === undefined &&
            inputFieldErrors.cvc === undefined &&
            inputFieldErrors.expiryDate === undefined &&
            inputFieldErrors.zip === undefined && newCardData?.cardName && (newCardData?.cardNumber.length === 16 || checkFirstTwoNumbers()) && newCardData?.cvc.length >= 3 && newCardData?.expiryDate.length === 7 && isChecked) {
            setsDisabled(false)
        } else {
            setsDisabled(true)
        }
    }, [ isChecked, newCardData?.cardName, newCardData?.cardNumber, newCardData?.cvc, newCardData?.expiryDate])



    return (
        <LayoutEmpty goBack={goBack} navTitle={t("home")}>
            <div className="add-my-card">
                {/*<NavigationComponent onClick={goBack} title={t("home")}/>*/}
                {
                    isLoading && <div className="add-my-card__loader"><LoadingLocal /></div>
                }
                <div className="add-my-card__header">
                    <div>
                        <HeaderText title={t("mycards")} />
                        <div className="add-my-card__add-card-wrap">
                            <p className="add-my-card__add-card-wrap-add">{t("addingCard")}</p>
                            <div className="add-my-card__add-card-wrap-image" onClick={goBackPage}>
                                <img className="add-my-card__add-card-wrap-img"
                                    src={require("../../img/cancel-icon.svg").default} />
                                <p className="add-my-card__add-card-wrap-back">{t("canceling")}</p>
                            </div>
                        </div>
                        <InputFieldComponent
                            infoImage
                            cardDataCb={(data) => setNewCardData(data)}
                            hasDefaultNameField={true}
                            //@ts-ignore
                            onErrorsUpdate={handleInputFieldErrorsUpdate}
                        />
                        <div className="add-my-card__footer">
                            <div className="add-my-card__footer-info" onClick={() => setIsChecked(!isChecked)}>
                                <AddCheckboxIn
                                    height={35}
                                    width={65}
                                    isChecked={isChecked}
                                    fontSize={12}
                                    title={`${t('wantToAdd')}`} />
                            </div>

                            <div>
                                {isPaymentLoading && (
                                    <div style={{ position: "relative", top: 15, right: 40 }}>
                                        <div className="spin"></div>
                                    </div>
                                )}
                                <Button
                                    disabled={isDisabled || isPaymentLoading}
                                    buttonText={t("save")}
                                    minWidth={367}
                                    padding="14px 133px"
                                    borderRadius={15}
                                    background="#EF5C27"
                                    onClick={addNewCard}
                                    color="#fff"
                                />
                            </div>
                        </div>
                    </div>

                    {/*<div style={{border: "1px solid #F1F1F1", margin: "40px 0"}}></div>*/}

                    {/*<div className="card-virtual">*/}
                    {/*    <div className="card-virtual__left">*/}
                    {/*        <span className="card-virtual__left-card">{"Виртуальная карта 1"}</span>*/}
                    {/*        <span className="card-virtual__left-cardNUmber">{"2335  ••••  ••••  4533"}</span>*/}
                    {/*        <span className="card-virtual__left-pan">{"PAN"}</span>*/}
                    {/*        <div className="card-virtual__left-info">*/}
                    {/*            <span className="card-virtual__left-info-dot">{"../.."}</span>*/}
                    {/*            <span className="card-virtual__left-info-dot">{"..."}</span>*/}
                    {/*        </div>*/}
                    {/*        <div className="card-virtual__left-info">*/}
                    {/*            <span className="card-virtual__left-info-dot">{"EX/DT"}</span>*/}
                    {/*            <span className="card-virtual__left-info-dot">{"CVV"}</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="card-virtual__right">*/}
                    {/*        <div className="card-virtual__right-price">*/}
                    {/*            <span>{"735 AZN"}</span>*/}
                    {/*            <img src={require("../../img/vertical-dots-icon.svg").default} alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className="card-virtual__right-visa">*/}
                    {/*            <img src={require("../../img/visa-icon.svg").default} alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </LayoutEmpty>
    );
};

export default AddMyCard;
