import React, {FC} from 'react';
import "./index.scss"
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import HeaderText from "../../CustomsComponents/HeaderText";
import SelectedCategory from "../../CustomsComponents/SelectedCategory";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

type Props = {
    onClick?: ()=>void
}

const DebtInvoice: FC<Props> = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

    return (
        <div className="debt-invoice">
            <NavigationComponent onClick={goBack} title={t("debt")}/>
            <HeaderText title={t("Invoice")}/>
            <div className="debt-invoice__select-category">{t("chooseCategory")}</div>
            <SelectedCategory/>
        </div>
    );
};

export default DebtInvoice;
