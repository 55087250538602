import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslation } from "react-i18next";
import HeaderText from "../../Components/CustomsComponents/HeaderText";
import { fetchMessages } from "../../redux/actions/messageActions";
import DontInformation from "../../Components/CustomsComponents/DontInformation/DontInformation";
import MessageSkeleton from "../../Components/Sceletons/MessageSkeleton/MessageSkeleton";
import LayoutWithSidebar from "../../Components/layouts/LayoutWithSidebar/LayoutWithSidebar";
import "./index.scss";
import { messagesSelector } from "../../redux/slices/messageSlice";
import { languageSelector } from "../../redux/reducers/language";

interface MessageComponentProps {
}

const arrMessageMock = [1, 2, 3, 4, 5]

const MessageComponent: FC = (props: MessageComponentProps) => {
  const [value, setValue] = useState<string>("");

  const { messages, isLoading } = useAppSelector(messagesSelector);
  const { language } = useAppSelector(languageSelector);
  const listMessages = messages?.list.privateMessage

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchMessages());
  }, [dispatch]);

  const filteredMessages = listMessages?.filter((messages) => {
    return messages?.subject.toLowerCase().includes(value.toLowerCase());
  })

  function formatDate(date: any) {
    interface Months {
      [key: string]: string[];
    }

    const months: Months = {
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      ru: ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
      az: ['Yan', 'Fev', 'Mart', 'Apr', 'May', 'İyun', 'İyul', 'Avq', 'Sent', 'Okt', 'Noy', 'Dek']
    };

    const lang = language;
    const month = months[lang][date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  return (
    <LayoutWithSidebar goBack={() => navigate(-1)} navTitle={t("home")}>
      <div className='main-mes'>
        <div className="message__header">
          <div className="message__header-text">
            <HeaderText title={t("messages")} />
          </div>
          <div className="message__header-input">
            <div className="message__header-input-wrap">
              <input
                type="search"
                placeholder="Поиск"
                className="message__header-search-input"
                onChange={(event) => setValue(event.target.value)}
              />
              <button type="submit" className="message__header-search-icon">
                Search
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          arrMessageMock.map((item) => <MessageSkeleton key={item} />)
        ) : (
          <>
            {filteredMessages?.length === 0 ? (
              <div className="wrap-dont-info">
                <DontInformation />
              </div>
            ) : (
              <div>
                {filteredMessages?.map((item, id) => (
                  <Link
                    key={item.id}
                    to={`/epay/${language}/message-info/${item.id}`}
                    state={{ messages: item }}
                    className="message__main"
                  >
                    {!item.isReaded ? (
                      <>
                        <div className="message__main-info">
                          <div className="message__main-title">
                            <div className="message__main-circle">{ }</div>
                            <span className="message__main-text">
                              {item.subject}
                            </span>
                          </div>
                          <div className="message__main-text-info" dangerouslySetInnerHTML={{
                            __html: item.text,
                          }} />
                        </div>
                        <div className="message__main-date-info">
                          <span className="message__main-date">
                            {formatDate(new Date(item.insertDate))}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="message__main-info">
                          <span className="message__main-title-read">
                            {item.subject}
                          </span>
                          <div className="message__main-text-info-read" dangerouslySetInnerHTML={{
                            __html: item.text,
                          }} />
                        </div>
                        <div className="message__main-date-info">
                          <span className="message__main-date-read">
                            {formatDate(new Date(item.insertDate))}
                          </span>
                        </div>
                      </>
                    )}
                  </Link>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </LayoutWithSidebar>

  );
};

export default React.memo(MessageComponent);
