import React from "react"
import ContentLoader from "react-content-loader"

const SliderSkeleton420 = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={210}
        viewBox="0 0 550 320"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="19" rx="10" ry="10" width="110" height="292" />
        <rect x="130" y="21" rx="10" ry="10" width="280" height="292" />
        <rect x="430" y="20" rx="10" ry="10" width="110" height="292" />
    </ContentLoader>
)

export default SliderSkeleton420