import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/translation.json';
import translationAz from './locales/az/translation.json';
import translationRu from './locales/ru/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem('language') || 'ru',
    lng: localStorage.getItem('language') || 'ru',
    nsSeparator: ':::',
    keySeparator: '::',
    debug: true,
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: translationEn,
      },
      az: {
        translation: translationAz,
      },
      ru: {
        translation: translationRu,
      },
    },
  });

export default i18n;
