import React from 'react';
import './Footer.scss'
import {NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {BottomBar} from "../BottomBar/BottomBar";
import {useAppSelector} from "../../hooks/redux";
import bonusPdf from '../../../src/pdf/bonus_epul.pdf';
import i18next from "i18next";
import { appleStore, googleStore } from '../../utils/consts';
import { getClassLang, logoSrc } from '../../utils/functions';
import { PLATFORMS } from '../../utils/enum';

const Footer = () => {
    const {t} = useTranslation();
    const {isAuth} = useAppSelector(state => state.authReducer);
    const {language} = useAppSelector(state => state.changeLangSlice)

    const langValue = i18next?.language;
    const {pathname} = useLocation()

    let activeStyle = {
        textDecoration: "none",
        color: "rgba(239, 92, 39, 1)",
    };

    const dateYear = moment().format('YYYY');
    const fromToDate = `2014 - ${dateYear}`;

    return (
        <footer className='footer'>
            <div className="footer__body">
                <div className='footer__body__item1'>
                    <p className='footer__body__item1__title'>{t("customerInformation")}</p>
                    <ul>
                        <li>
                            <NavLink to={`/epay/${language}/advantages`}
                                     style={() => "/advantages" === pathname ? activeStyle : undefined}>
                                {t("advantages")}
                            </NavLink>
                        </li>
                        {/*<li>Безопасность</li>*/}
                        <li>
                            <NavLink to={`/epay/${language}/faq`}
                                     style={() => "/faq" === pathname ? activeStyle : undefined}
                            >
                                {t("help")}
                            </NavLink></li>
                        {/* <li>
                            <NavLink to='/my-card' style={() => "/my-card" === pathname ? activeStyle : undefined}>{t("virtualMap")}</NavLink>
                        </li> */}
                        <li>
                            <a
                                target="_blank"
                                href="https://blog.e-pul.az/" rel="noopener noreferrer"
                                aria-label={t("blog")}
                                // style={() => "/blog" === pathname ? activeStyle : undefined}
                            >
                                {t("blog")}
                            </a></li>
                        <li>
                            <NavLink to={`/epay/${language}/about_company`}
                                     style={() => "/about_company" === pathname ? activeStyle : undefined}>
                                {t("aboutUs")}
                            </NavLink></li>
                        <li>
                            <NavLink to={`/epay/${language}/privacy-policy`}
                                     style={() => "/privacy-policy" === pathname ? activeStyle : undefined}>
                                {t("privacyPolicy")}
                            </NavLink>
                        </li>
                        {/* <li>
                            <a target="_blank" href="https://www.e-pul.az/epay/resources/pdf/terms_android.pdf" rel="noreferrer">{t("userAgreement")}</a>
                        </li> */}
                        <li>
                            <a
                                target="_blank"
                                href={bonusPdf}
                                rel="noopener noreferrer"
                                aria-label={t("bonusCalculationMethod")}
                            >{t("bonusCalculationMethod")}</a>
                        </li>
                    </ul>
                </div>
                <div className='footer__body__item2'>
                    <p className='footer__body__item1__title'>{t("help")}</p>
                    <ul>
                        <li>{t("phone")} <a className='footer__body__item2__phone' href="tel:*1919"
                                            aria-label="tel:*1919"> *1919</a></li>
                        <li className='footer__body__item2__messenger'>
                            <span>{t("chat")} </span>
                            <a target="_blank"
                               href="https://www.messenger.com/t/eeepulaz/"
                               rel="noopener noreferrer">
                                <img
                                    width={24}
                                    height={24}
                                    src={require("../../img/Vector.svg").default}
                                    alt="messenger"
                                />
                            </a>
                            <a target="_blank"
                               href="https://wa.me/994105051919"
                               rel="noopener noreferrer">
                                <img
                                    width={24}
                                    height={24}
                                    src={require("../../img/whatsapp.svg").default}
                                    alt="whatsapp"
                                />
                            </a>
                        </li>
                        <li>{t("email")} <a className='footer__body__item2__mail'
                                            href="mailto:support@e-pul.az">support@e-pul.az</a>
                        </li>
                    </ul>
                    <div className="footer-partners__head">
                        <p className='footer__body__item1__title'>{t("partners")}</p>
                    </div>
                    <ul className='footer-partners'>
                        <li>
                            <a target="_blank" href="https://www.bankofbaku.com/" rel="noopener noreferrer"
                               aria-label="bankofbaku.com"
                            >
                                <img
                                    width={28}
                                    height={28}
                                    src={require('../../img/bob.svg').default} alt="bankofbaku"/>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.goldenpay.az/#/" rel="noopener noreferrer"
                               aria-label="goldenpay.az"
                            >
                                <img
                                    width={58}
                                    height={34}
                                    src={require('../../img/goldenpay_logo.svg').default} alt="goldenpay"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.thawte.com/" rel="noopener noreferrer"
                               aria-label="thawte.com"
                            >
                                <img
                                    width={29}
                                    height={34}
                                    src={require('../../img/Thawte.svg').default} alt="thawte"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.million.az/" rel="noopener noreferrer"
                               aria-label="million.az"
                            >
                                <img
                                    width={75}
                                    height={22}
                                    src={require('../../img/million.svg').default} alt="million"/></a>
                        </li>
                    </ul>
                </div>
                <div className='footer__body__item3'>
                    <p className='footer__body__item1__title'>{t("appendices")} </p>
                    <div className="footer-app">
                        <ul className="footer-app-wrap">
                            <li className="footer-app-android">
                                <a target="_blank"
                                   href={googleStore}
                                   rel="noopener noreferrer"
                                   aria-label="play-google"
                                >
                                    <img
                                        alt='Android OS'
                                        title='Android OS'
                                        src={logoSrc(PLATFORMS.GOOGLE, language)}
                                    />
                                </a>
                            </li>
                            <li className="footer-app-apple">
                                <a target="_blank"
                                    href={appleStore}
                                    rel="noopener noreferrer"
                                    aria-label="apple"
                                >
                                    <img
                                        alt='Apple IOS'
                                        title='Apple IOS'
                                        className={getClassLang(PLATFORMS.APPLE, langValue)}
                                        src={logoSrc(PLATFORMS.APPLE, langValue)}
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer__body__item4'>
                    <div className='footer__body__item1__title'>
                        <ul style={{listStyle: "none"}}>
                            <li>
                                <div className='footer__body__item4__logo'>
                                    <a
                                        target="_blank"
                                        className='social-icon'
                                        href="https://www.facebook.com/eeepulaz/"
                                        rel="noopener noreferrer"
                                        aria-label="facebook"
                                    >
                                        <img
                                            width={35}
                                            height={35}
                                            src={require('../../img/fb.svg').default} alt="facebook"/>
                                    </a>
                                    <a target="_blank"
                                       className='social-icon'
                                       href="https://twitter.com/eeepulaz"
                                       rel="noopener noreferrer"
                                       aria-label="twitter"
                                    >
                                        <img
                                            width={35}
                                            height={35}
                                            src={require('../../img/tw.svg').default} alt="twitter"/>
                                    </a>
                                    <a target="_blank" className='social-icon'
                                       href="https://www.youtube.com/channel/UCBTJj629DGwdgn8Y8dop3wg"
                                       rel="noopener noreferrer"
                                       aria-label="youtube"
                                    >
                                        <img
                                            width={35}
                                            height={35}
                                            src={require('../../img/tw-1.svg').default} alt="youtube"/>
                                    </a>
                                    <a
                                        target="_blank"
                                        className='social-icon'
                                        href="https://www.instagram.com/epul.az/"
                                        rel="noopener noreferrer"
                                        aria-label="instagram"
                                    >
                                        <img
                                            width={35}
                                            height={35}
                                            src={require('../../img/tw-2.svg').default} alt="instagram"/>
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href="https://paysis.az/" rel="noopener noreferrer"
                                       aria-label="logo_paysis">
                                        <img
                                            width={139}
                                            height={40}
                                            src={require('../../img/logo_paysis 1.svg').default} alt="logo-paysis"
                                        />
                                    </a>
                                </div>
                            </li>
                        </ul>

                        <div style={{fontSize: 14, fontWeight: 500, color: "#ffffff"}}>{`© ${fromToDate}`}</div>
                        <div style={{
                            paddingBottom: 20,
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#ffffff"
                        }}>{t("addresStreet")}</div>
                    </div>
                </div>
            </div>
            {
                isAuth ? <BottomBar/> : null
            }
        </footer>
    );
};

export default Footer;
