export const MsgToUserActionTypes = {
  MSGTOUSER: 'MSGTOUSER',
};

export const MessageType = {
  ConnectionError: 0,
  SomeError: 1,
  OtpCheckFailed: 2,
  LimitError: 3,
  UserActivated: 4,
  LoginFailed: 5,
  ChangeCodeError: 6,
  WalletLimitExceeded: 7,
  PhoneStillInUse: 8,
  SessionExpiredMsg: 9,
  Failed3ds: 10,
  AddCardFailed: 11,
  MasterPassError: 12,
  CustomerNotFound: 13,
  InvalidAmount: 14,
  CatchError: 15,
  IdentificationRequired: 16,
  ExpFieldRequired: 17,
  SmsLimitError: 18,
};

export default MsgToUserActionTypes;
