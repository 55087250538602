import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import Button from "../../Button/Button";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import HistoryDebt from "../../Debts/HistoryDebt/HistoryDebt";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../hooks/redux";
import {BonusToCash} from "../../../helper";
import {AppDispatch} from "../../../redux/store";
import "./Bonuses.scss";
import {fetchPopularProduct} from "../../../redux/actions/popularProductAction";
import {useTranslation} from "react-i18next";
import BonusesSkeleton from "../../Sceletons/BonusCatalogueSkeleton/BonusesSkeleton";
import { bonusConvertTable } from "../../../consts";
import BonusesFavSkeleton from "../../Sceletons/BonusCatalogueSkeleton/BonusesFavSkeleton";

const mockArr = [1, 2, 3, 4, 5, 6];

const Bonuses = () => {
  const {t} = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  // const {  bonList, isLoading } = useAppSelector((state) => state.bonList);
  const {profile, isAuth} = useAppSelector((state) => state.authReducer);
  const {popularProduct, isLoadingPop} = useAppSelector((state) => state.popularProduct);
  // const isAuth = useAppSelector((state) => state.authReducer.isAuth);
  const { language } = useAppSelector(state => state.changeLangSlice);

  useEffect(() => {
    // dispatch(fetchBonuses());
    // dispatch(fetchBonusStore({selectedCategoryId: -1, offset: 0}))
    dispatch(fetchPopularProduct({ selectedCategoryId: -1, offset: 0 }));
    if (!profile?.user?.id) {
      // dispatch(fetchProfile());
    }
  }, [dispatch, profile?.user?.id]);

  useEffect(() => {
    const close = (e: any) => {
      if(e.keyCode === 27){
        setModalActive(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[])

  return (
    <div className="bonuses">
      <div className="bonuses__title">
        <div className="bonuses__title-text">{t("bonus")}</div>
        <img
          onClick={() => setModalActive(true)}
          src={require("../../../img/info3.svg").default}
          alt="bonuses-img"
        />
      </div>
      <CustomModal active={modalActive} setActive={setModalActive}>
        <div className="bonus-modal">
          <div className="bonus-modal__header">
            <div className="bonus-modal__header-text">{t("aboutBonusSystem")}</div>
            <div className="bonus-modal__header-icon" onClick={() => setModalActive(false)}>
              <img src={require("../../../img/close-icon.svg").default} alt=""/>
            </div>
          </div>
          <div className="bonus-modal__body">
            <div className="bonus-modal__body-title"> {t("howAccumulateBonuses")}</div>
            <div className="bonus-modal__body-content">
              <div className="bonus-modal__content-title">{t("getAnExtra")}</div>
              <div className="bonus-modal__content-text">{t("aboutBonus")}</div>
              <div className="bonus-modal__bonus-box">
                {bonusConvertTable?.map((item: any) => (
                    <div className="bonus-modal__bonus-item">
                      <div className="bonus-modal__bonus-price">{item.manat} AZN</div>
                      <div className="bonus-modal__bonus-convert">
                          <div className="bonus-modal__bonus-count">{item.bns}</div>
                        <div className="bonus-modal__bonus-icon">
                          <img src={require("../../../img/bonus-purple.svg").default} alt=""/>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bonus-modal__footer">
            <Link to={`/epay/${language}/catalogue`} className="bonus-modal__btn">
              <Button
                buttonText={`${t("goBonusStore")}`}
                padding="14px 30px"
                color="#FFFFFF"
                background="#EF5C27"
                borderRadius={15}
                minWidth={327}
              />
            </Link>
          </div>
        </div>
      </CustomModal>
      <div className="bonuses__wrap">
        <div className="bonuses__wrap-left">
          <div className="bonuses__wrap-left-banner">
            <div className="bonuses__wrap-left-banner-text">
              {t("accumulatedBonuses")}
            </div>
            <div className="bonuses__wrap-left-banner-price">
              <div className="bonuses__wrap-left-banner-price-picture">
                <img src={require("../../../img/giftBig.svg").default} alt="" />
              </div>
              <div className="bonuses__wrap-left-banner-price-total">
                <div className="bonuses__wrap-left-banner-price-total-text">
                  {profile?.bonusValue}
                </div>
                <div className="bonuses__wrap-left-banner-price-total-price">
                  <span className="bonuses__wrap-left-banner-price-total-price-txt">
                    {BonusToCash(profile?.bonusValue)}
                  </span>
                  <span className="bonuses__wrap-left-banner-price-total-price-azn">
                    {"AZN"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="bonuses__wrap-left-title">
            {t("bonusStore")}
          </div>
           {isLoadingPop ? (
              <div className="bonuses__wrap-left-card">
                {mockArr.map((item) => {
                  return <>
                    <div className="bonuses__wrap-left-card-skeleton"><div><BonusesSkeleton key={item}/></div></div>
                    <div className="bonuses__wrap-left-card-skeleton-big"><div><BonusesFavSkeleton key={item}/></div></div>
                  </>
                })}
              </div>
          ) : (
              <>
                <div className="bonuses__wrap-left-card">
                  {popularProduct?.map((item, id) => (
                      <NavLink
                          to={`/epay/${language}/product/${item.id}`}
                          // state={{ bonusStore: item }}
                    key={id}
                    className="bonuses__wrap-left-card-item"
                  >
                    <div className="bonuses__wrap-left-card-item-picture">
                      {/*<img src={require('../../../img/honor.svg').default} alt=""/>*/}
                      <img
                          className="bonuses__wrap-left-card-item-picture-img"
                        src={`data:image/png;base64, ${item.image}`}
                        width={211}
                        height={187}
                        alt=""
                      />
                    </div>
                    <div className="bonuses__wrap-left-card-item-title">
                      {item.name}
                    </div>
                    <div className="bonuses__wrap-left-card-item-price">
                      <div className="bonuses__wrap-left-card-item-price-count">
                        {item.bonusValue}
                      </div>
                      <img
                        src={require("../../../img/gift.svg").default}
                        alt=""
                      />
                    </div>
                    {!!item.price && <div className="bonuses__wrap-left-card-item-total">
                      <div className="bonuses__wrap-left-card-item-total-price">
                        {item.price}
                      </div>
                      <div className="bonuses__wrap-left-card-item-total-azn">
                        {"AZN"}
                      </div>
                    </div>}
                      </NavLink>
                  ))}
                </div>
                <div className="bonuses__btn">
                  <Link to={`/epay/${language}/catalogue`} className="bonuses__wrap-left-btn">
                    <Button
                        buttonText={`${t("BonusStore")}`}
                        padding="14px 75px"
                        background="#EF5C27"
                        color="#FFFFFF"
                        minWidth={330}
                        borderRadius={15}
                    />
                  </Link>
                </div>
              </>
          )}
        </div>
        {isAuth && (
          <div className="bonuses__wrap-right">
            <HistoryDebt />
          </div>
        )}
      </div>
    </div>
  );
};

export default Bonuses;
