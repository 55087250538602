import React, {useState} from 'react';
import {InputAdornment, TextField} from "@mui/material";
import Button from "../Button/Button";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Controller, useForm} from "react-hook-form";
import {asanLogin} from "../../redux/actions/authActions";
import "./index.scss"
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import {Navigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CPhoneInput from "../CustomsComponents/PhoneInput";

const AuthorizationForm = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const [openAsanInfoModal, setOpenAsanInfoModal] = useState(false);
    const dispatch = useAppDispatch()
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const link = `/epay/${language}/home`
    const asanUser = useAppSelector(state => state.authReducer.asanUser)
    const {user} = useAppSelector(state => state.authReducer)
    const {register, handleSubmit, setError,control, formState: {errors, isValid}} = useForm({
        defaultValues: {
            phone: '',
            asanImzaId: ''
        },
        mode: 'onChange'
    })
    const onSubmit = async (values: { phone: string, asanImzaId: string }) => {
        const callback = () => setOpenAsanInfoModal(true );
        if (await dispatch(asanLogin({phone: values.phone, asanImzaId: values.asanImzaId},  location.pathname === "/sign/in" ? 'login' : "register", callback))) {
            setOpenAsanInfoModal(true)
        }else{
            setOpenAsanInfoModal(true)
        }

    }
    return (
        <>
            {user?.id && <Navigate to={link}/> }
            <form onSubmit={handleSubmit(onSubmit)}>
                <CPhoneInput control={control}/>

                <TextField color="warning"
                           variant="standard"
                           margin="normal"
                           label={t("userIDAsan")}
                           helperText={errors.asanImzaId?.message}
                           error={Boolean(errors.asanImzaId?.message)}
                           fullWidth {...register('asanImzaId', {required: `${t("enterUserIDAsan")}`})}/>
                {/*<label className="no-registration-main__register-footer">*/}
                {/*    <AddCheckboxIn height={20} width={20}*/}
                {/*                   title={"Я ознакомлен и принимаю условия соглашения"}/>*/}
                {/*</label>*/}
                {/*<Typography className="forgot-asan-imza"> Забыли пароль</Typography>*/}
                <div className="no-registration-main__register-button">
                    <Button
                        type='submit'
                        buttonText={`${t("signIn")}`}
                        background="#EF5C27"
                        padding="14px 65px"
                        borderRadius={15}
                        color="#FFFFFF"
                        margin="0 auto"
                    />
                </div>
                {/*<div>*/}
                {/*    <div className="no-registration-main__register-main">Номер телефона</div>*/}
                {/*    <div className="no-registration-main__register-input">*/}
                {/*        <Input*/}
                {/*            defaultValue="+994 000 00 00"*/}
                {/*            inputProps={ariaLabel}*/}
                {/*            sx={{*/}
                {/*                color: "#888888",*/}
                {/*                width: "100%",*/}
                {/*                lineHeight: "22px",*/}
                {/*                paddingBottom: "12px",*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="no-registration-main__register-input">*/}
                {/*        <Input*/}
                {/*            defaultValue="ID пользоватлея Asan Imza"*/}
                {/*            inputProps={ariaLabel}*/}
                {/*            sx={{*/}
                {/*                color: "#474747",*/}
                {/*                width: "100%",*/}
                {/*                lineHeight: "22px",*/}
                {/*                paddingBottom: "12px"*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="no-registration-main__register-button">*/}
                {/*        <Button*/}
                {/*            buttonText="Войти"*/}
                {/*            background="#EF5C27"*/}
                {/*            padding="14px 0"*/}
                {/*            borderRadius={15}*/}
                {/*            color="#FFFFFF"*/}
                {/*            margin="0 auto"*/}
                {/*            minWidth={280}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <p className="no-registration-main__forgot-password">{"Забыли пароль?"}</p>*/}
                {/*</div>*/}
            </form>
            {asanUser?.asanUser?.surname && (
                <CustomModal active={openAsanInfoModal} setActive={setOpenAsanInfoModal}>
                    <div style={{ display: "flex", flexDirection: "column", width: 450, height: 300, padding: 10}}>
                        <div style={{ display: "flex", justifyContent: "end", marginBottom: "20px"}}>
                            <div style={{cursor: "pointer"}} onClick={() => setOpenAsanInfoModal(false)}>
                                <img src={require("../../img/close-icon.svg").default}/>
                            </div>
                        </div>
                        <div style={{display: "flex", textAlign: "center", fontSize: "18px"}}>
                            {t("verificationCodeAsan")}
                        </div>
                        <div style={{display: "flex", justifyContent: "center", paddingTop: 20, fontSize: 22, color: "blue"}}>
                            <div className="modal__text">{asanUser?.asanUser?.verificationCode}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", paddingTop: 20}}>
                            <Button
                                buttonText={t("rejection")}
                                color="#FFFFFF"
                                padding="14px 80px"
                                minWidth={200}
                                borderRadius={15}
                                background="rgba(239, 92, 39, 1)"
                                onClick={() => setOpenAsanInfoModal(false)}
                            />
                        </div>
                    </div>
                </CustomModal>
            )
            }
            {asanUser?.status === "PHONE_ALREADY_EXIST" && (
                <CustomModal active={openAsanInfoModal} setActive={setOpenAsanInfoModal}>
                    <div style={{ display: "flex", flexDirection: "column", width: 450, height: 200, padding: 10}}>
                        <div>
                            PHONE_ALREADY_EXIST
                        </div>
                    </div>
                </CustomModal>
            )}
        </>
    );
};

export default AuthorizationForm;
