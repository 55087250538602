import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import {categoriesInfo} from "../../consts";
import {LocaleName} from "../../models/Categories";
import {useAppSelector} from "../../hooks/redux";

const SliderPopularCategories = () => {
    const {language, langKey} = useAppSelector(state => state.changeLangSlice)
    const auth = useAppSelector((state) => state.authReducer);
    const {categories} = useAppSelector(state => state.categoriesReducer);
    const savedPath = auth.isAuth ? "new-payment" : "guest-payment"


    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4
    };
    return (
        <div className="slider-popular-categories">
            <Slider {...settings}>
                {categories.slice(0, 12).map((item: any, id: number) => {
                    return (
                        <Link
                            state={{ category: item, savedPath, processName: savedPath }}
                            to={`/epay/${language}/category/${item.name}`}
                            key={item.id}
                            className="basket-wrapper-slider-categories__item"
                        >
                            <div className="basket-wrapper-slider-categories__item-icon" style={{ background: categoriesInfo[item.id]?.backGround }}>
                                <div className="basket-wrapper-slider-categories__item-image">
                                    <img style={{width: "30px", height: "30px"}} src={categoriesInfo[item.id]?.img} alt="Logo" />
                                </div>
                            </div>
                            <div className="basket-wrapper-slider-categories__item-text">
                                {item.localName[langKey as keyof LocaleName]}
                            </div>
                        </Link>
                    );
                })}
            </Slider>
        </div>
    );
};

export default SliderPopularCategories;
