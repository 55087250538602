export const toQueryParams = (params: {[key: string]: any}) => {
    const formBody: string[] = [];
    for (const property in params) {
        const value = params[property];
        if (value === undefined || value === null) {
            continue;
        }
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(value);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    const result = formBody.join("&");
    // console.log('toQueryParams', result);
    return result;
}

export async function fetchWithTimeout(resource: RequestInfo, options?: (RequestInit & { timeout?: number })) {
    let date = new Date().getTime();
    const timeout = options?.timeout || 60000;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response:any = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);
    date = new Date().getTime() - date;
    if (date > 1000) {
        console.log('Request too slow, ' + date + ', resource: ' + resource + ', data: ' + JSON.stringify(options));
    }

    return response;
}

export function parseQuery(queryString: string) {
    var query: {[key:string]: string} = {};
    if (queryString.indexOf('?') < 0) {
        return query;
    }
    var pairs = queryString.substring(queryString.indexOf('?') + 1, queryString.length).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');


        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export function getQueryParams() {
    return parseQuery(window.location.search || '')
}
