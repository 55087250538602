export function Languages(language) {
  switch (language) {
    case 'ru':
      return {
        lang: 'ru',
        person: 'Лицо',
        selectDebt: 'Выбор долга',
        debtTotal: 'Оплатить весь долг',
        debt: 'Долг',
        sum: 'Сумма',
        min: 'Мин.',
        max: 'Макс.',
        commission: 'Комиссия',
        dataIsNotCorrect: 'Введенные данные не корректны',
        sendCheckMsgSuccess: 'Ваш чек успешно отправлен',
        sendCheckLimitError: 'Вы превысили лимит отправки чека',
        sendSmsMsgSuccess: 'Сообщение отправлено',
        smsLimitError: 'Вы превысили лимит sms.',
        requiredError: 'Поле является обязательным',
        emptyError: 'Поле не может быть пустым',
        paymentDone: 'Платеж совершен',
        paymentError: 'Ошибка при платеже',
        date: 'Дата',
        service: 'Услуга',
        cardNumber: 'Номер карты',
        checkNumber: 'Номер чека',
        sendToEmail: 'Отправить на почту',
        checked: 'Проверено',
        checkPrint: 'Распечатать чек',
        downloadEPul:
          'Скачайте мобильное приложение E-Пул, которое упростит ваши платежи',
        enterNumberDownload:
          'Введите номер своего мобильного телефона, и ссылка для скачивания будет отправлена ​​на ваш телефон',
        getLink: 'Получить ссылку',
        checkInfo: 'Информация по чеку',
        paymentMethod: 'Способ оплаты',
        noClientMsg: 'Нет такого клиента',
        someError:
          'Произошла неизвестная ошибка. Пожалуйста, повторите попытку позже.',
        selectCard: 'Выберите карту',
        information: 'Информация',
        pay: 'Оплатить',
        check: 'Проверить',
        cancel: 'Отмена',
        mobileNumber: 'Мобильный номер',
        reCaptchaError: 'Подтвердите, что вы не робот',
      };
    case 'en':
      return {
        lang: 'en',
        person: 'Person',
        selectDebt: 'Select debt',
        debtTotal: 'Pay all debt',
        debt: 'Debt',
        sum: 'Amount',
        min: 'Min.',
        max: 'Max.',
        commission: 'Commission',
        dataIsNotCorrect: 'The entered data is incorrect',
        sendCheckMsgSuccess: 'Your check has been sent successfully',
        sendCheckLimitError: 'You have exceeded the limit for sending check',
        sendSmsMsgSuccess: 'Sms sent',
        smsLimitError: 'You have exceeded the sms limit.',

        requiredError: 'Field is required',
        emptyError: 'Field must not be empty',
        paymentDone: 'Payment completed',
        paymentError: 'Payment error',
        date: 'Date',
        service: 'Service',
        cardNumber: 'Card number',
        checkNumber: 'Check number',
        sendToEmail: 'Send to mail',
        checked: 'Last checked',
        checkPrint: 'Check print',
        downloadEPul:
          'Download the E-пул mobile application that will make your payments easier',
        enterNumberDownload:
          'Enter your mobile number and the download link will be sent to your phone',
        getLink: 'Get the link',
        checkInfo: 'Check Information',
        paymentMethod: 'Payment method',
        noClientMsg: 'No such client',
        someError: 'Unknown error. Please, try again later',
        selectCard: 'Choose a card',
        information: 'Information',
        pay: 'Pay',
        check: 'Check',
        cancel: 'Cancel',
        mobileNumber: 'Mobile number',
        reCaptchaError: 'Confirm that you are not a robot',
      };
    case 'az':
      return defaultObject;
    default:
      return defaultObject;
  }
}

const defaultObject = {
  lang: 'az',
  person: 'Müştəri',
  selectDebt: 'Borc seçmək',
  debtTotal: 'Bütün borcu ödə',
  debt: 'Borc',
  sum: 'Məbləğ',
  min: 'Min.',
  max: 'Max.',
  commission: 'Kommissiya',
  dataIsNotCorrect: 'Daxil edilmiş məlumat düzgün deyil',
  sendCheckMsgSuccess: 'Çekiniz uğurla göndərildi',
  sendCheckLimitError: 'Çek göndərmə limitinizi keçmisiz',
  sendSmsMsgSuccess: 'Sms göndərildi',
  smsLimitError: 'Siz sms göndərmə limitini keçmisiniz.',
  requiredError: 'Məlumat yazılmayıb',
  emptyError: 'Mütləq yazılmalıdır',
  paymentDone: 'Ödəniş tamamlandı',
  paymentError: 'Ödəniş xətası',
  date: 'Tarix',
  service: 'Xidmət',
  cardNumber: 'Kartın nömrəsi',
  checkNumber: 'Çekin nömrəsi',
  sendToEmail: 'Poçta göndərin',
  checked: 'Yoxlanılıb',
  checkPrint: 'Çeki çap edtmək',
  downloadEPul: 'Ödənişlərinizi rahatlaşdıracaq E-pul mobil tətbiqini yükləyin',
  enterNumberDownload:
    'Mobil nömrənizi daxil edin, yükləmə linki telefonunuza gəlsin',
  getLink: 'Linki elde et',
  checkInfo: 'ÇEK MƏLUMATLARI',
  paymentMethod: 'Ödəniş üsulu',
  noClientMsg: 'Müştəri mövcud deyil',
  someError:
    'Naməlum xəta baş verdi. Zəhmət olmasa, bir müddət sonra təkrarlayın.',
  selectCard: 'Kartı seçin',
  information: 'Məlumat',
  pay: 'Ödəyin',
  check: 'Yoxlayın',
  cancel: 'Imtina',
  mobileNumber: 'Mobil nömrə',
  reCaptchaError: 'Robot olmadığınızı təsdiq edin',
};
