import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import LayoutEmpty from "../layouts/LayoutEmpty/LayoutEmpty";
import {useAppSelector} from "../../hooks/redux";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import "./index.scss";
import {ServiceActionCreators} from "../../redux/reducers/service/action-creators";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {fetchWithTimeout, getQueryParams, toQueryParams} from "../../utils/query";
import client, {BASE_URL} from "../../axios";
import {getResultPaymentUrl, notify} from "../../helper";
import {fieldsForPicker} from "../RecurringPayments/consts";
import PaymentFields from "../PaymentFields";
import AdditionalFields from "../AdditionalFields/AdditionalFields";
import {Languages} from "../../redux/reducers/language/languageData";
import CardsBox, {IInstrument} from "../CardsBox/CardsBox";
import {LoadingLocal} from "../Loading/Loading";
import {GuestActionCreators} from "../../redux/reducers/guest/action-creators";
import AddCheckboxIn from "../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import {addToFavoritesFetch} from "../../redux/actions/favoritesListAction";
import {useTranslation} from "react-i18next";
import {LocaleName} from "../../models/Categories";
import {fetchPrintedHistory} from "../../redux/actions/bonusesAction";
import {MessageType} from "../../redux/reducers/msgToUser/types";
import OtherPayments from "../OtherPayments/OtherPayments";
import { fetchTokenize } from '../../redux/actions/tokenize'
import { fetchGetCardToken } from '../../redux/actions/habStorageAction'
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import OtherPaymentSwiper from "../OtherPayments/OtherPaymentSwiper";
import ReactMarkdown from "react-markdown";
import { createHubCardData } from "../../utils/hubFunctions";
import MiniSpinner from "../MiniSpinner/MiniSpinner";
import { addToCart } from "../../redux/actions/basketActions";

interface IProps {
}

const PaymentAdd: FC<IProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const {forms} = useAppSelector((state) => state.masterPassReducer);
    const { language } = useAppSelector(state => state.changeLangSlice)
    const serviceInState = useAppSelector((state) => state.service);
    const {walletList} = useAppSelector((state) => state.walletList);
    const {isPaymentLoading} = useAppSelector((state) => state.loading);
    const {langKey} = useAppSelector((state) => state.changeLangSlice);
    const {bonusServices} = useAppSelector((state) => state.bonusServices);
    const {isHubSolutionPaymentMethod} = useAppSelector((state) => state.service);
    const [fields, setFields] = useState<any>([]);
    const [subVal, setSubVal] = useState("");
    const [regExp, setRegExp] = useState<any>(null);
    const [subName, setSubName] = useState("");
    const [comment, setComment] = useState("");
    const [groupId, setGroupId] = useState(null);
    const [checkData, setCheckData] = useState(null);
    const [payLoading, setPayLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [instrument, setInstrument] = useState<any>(null);
    const [amountValue, setAmountValue] = useState("");
    const [isClientExist, setIsClientExist] = useState(false);
    const [isAmountCorrect, setIsAmountCorrect] = useState(false);
    const [isServiceLoading, setIsServiceLoading] = useState(false);
    const [addToFavoriteIsChecked, setAddToFavoriteIsChecked] = useState(false);
    const [canBonus, setCanBonus] = useState(false);
    const [dataFromChild, setDataFromChild] = useState(true);
    const [debtInfoClient, setDebtInfoClient] = useState(null);
    const [service, setService] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const isAuthToken = localStorage.getItem("token") || "";
    const {state} = location;
    // const { service } = serviceInState;
    const fieldsJSON = JSON.stringify({fields});
    const {isAuth} = useAppSelector((state: any) => state.authReducer);
    const { browserToken } = useAppSelector(state => state.browserToken);
    const token = localStorage.getItem("token");
    //@ts-ignore
    const { basket } = useAppSelector((state) => state.basketSlice);
    const [isFieldsValid, setIsFieldsValid] = useState<boolean | null>(null);

    const handleDataFromChild = (data: boolean) => {
        setDataFromChild(data);
    };

    useEffect(() => {
        const modalShown = localStorage.getItem("modalShown");

        if (!modalShown) {
            setActiveModal(true);
            localStorage.setItem("modalShown", "true");
        }
    }, []);
    useEffect(() => {
        if (!isAuth) {
            navigate(`/epay/${language}/${params?.categoryName}/${params?.serviceName}`, {
                state: {
                    serviceId: service.id,
                },
            })
        }
    }, []);

    useEffect(() => {
        if (service && bonusServices?.length) {
            const hasBonus = bonusServices.find((bonusService: any) => bonusService?.id === service?.id);
            setCanBonus(hasBonus)
        }
    }, [bonusServices, service]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params?.categoryName && params?.serviceName) {
            const categoriesLocale = localStorage.getItem('categories');
            const servicesLocale = localStorage.getItem('services');
            if (servicesLocale !== null && categoriesLocale !== null) {
                const findCategory = JSON.parse(categoriesLocale)?.find((category: any) => category?.name === params?.categoryName);
                const findService = JSON.parse(servicesLocale)?.find((service: any) => findCategory.id === service.categoryId && service.friendlyName === params?.serviceName);
                setService(findService)
            } else {
                setIsLoading(true)
                const {serviceId} = params;
                client.post<any>(`digest/guestListPaymentServicesSelected/${serviceId}`, {
                    selected: [serviceId && +serviceId]
                }).then((res: any) => {
                    if (res.status === 200) {
                        setService(res.data.list.service[0])
                        setIsLoading(false)
                    }
                })

            }
        }
    }, [dispatch, params]);

    useEffect(() => {
        if (service && fields) {
            const id = fields[0];
            const reg = service?.serviceFields?.serviceField?.find(
                (field: any) => field.id === Number(id?.id)
            );
            setRegExp(reg?.regExp);
        }
    }, [fields, service]);

    useEffect(() => {
        if (state?.fields) {
            checkIfUserExist(
                isAuthToken,
                state?.serviceId,
                JSON.stringify({fields: state?.fields}),
                state?.groupId || 0
            );
            setFields(state?.fields);
        }
        if (state?.groupId) {
            setGroupId(state?.groupId);
        }
    }, [state, state?.fields, state?.groupId, state?.serviceId, isAuthToken]);

    useEffect(() => {
        if (isFieldsValid === false) {
            setIsDisabled(true);
        } else if (state?.fields) {
            if (isClientExist && isAmountCorrect) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        } else {
            if (isClientExist) {
                if (new RegExp(regExp).test(fields[0]?.value) && isAmountCorrect) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            } else {
                if (fields.length && (fields?.[0]?.name == "prefix" ? (new RegExp(regExp).test(fields[0]?.value) && fields[1]?.value.length == 7) : new RegExp(regExp).test(fields[0]?.value))) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            }
        }
    }, [
        isAmountCorrect,
        isClientExist,
        state?.fields,
        fields,
        fields[0]?.value,
        amountValue,
        regExp,
        isFieldsValid
    ]);

    useEffect(() => {
        return () => {
            dispatch(ServiceActionCreators.setService({}));
        };
    }, [dispatch]);

    const checkIfUserExist = async (
        sessionId: string,
        serviceId: any,
        fields: any,
        selectedGroupId: any
    ) => {
        try {
            setBtnLoading(true)
            const response = await client.get(
                `categories/client_info_with_group_id?fields=${encodeURIComponent(
                    fields
                )}&selectedGroupId=${selectedGroupId}&serviceId=${serviceId}&sessionId=${sessionId}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );

            setDebtInfoClient(response.data)

            // if (response.status === 200) {
            //   const json = await response.json()

            if (response.data?.resultCodes === "OK") {
                setIsClientExist(true);
                setCheckData(response.data);
            } else if (
                response.data?.resultCodes === "NOT_FOUND" ||
                response.data?.resultCodes === "INVALID_PARAMETERS" ||
                response.data?.resultCodes === "UNKNOWN_ERROR"
            ) {
                // setMsgType(1);
                // notify();
                notify(`${t("notFound")}`, false);
            } else if (
                response.data?.resultCodes === "UNDECIDED_PROTOCOL" ||
                response.data?.resultCodes === "PROTOCOL_CANT_DECIDED"
            ) {
            }
            // } else {
            //   // setMsgType(0);
            //   notify(`${t('unknownError')}`, false)
            // }
        } catch (e) {
            notify(`${t("unknownError")}`, false);
        } finally {
            setBtnLoading(false)
        }
    };


    const sendPurchaseEvent = async () => {
        const {orderId, historyId} = getQueryParams();
        const response = await client.get(
            `history/receipt2/${orderId}/${historyId}?sessionId=${localStorage.getItem(
                "token"
            )}&afterPayment=true`,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );

        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id: response.data?.item.orderId,
                value: response.data?.item.amount,
                currency: response.data?.item.currency,
                items: [{
                    item_name: response.data?.item.name[langKey as keyof LocaleName],
                    item_id: response.data?.item.id,
                    price: response.data?.item.bonusAmount,
                    item_category: service.localName[langKey as keyof LocaleName],
                    affiliation: response.data?.item.assemblyId,
                    quantity: 1
                }]
            }
        });
    };

    const onBtnClick = async () => {
        setBtnLoading(true)
        try {
            if (addToFavoriteIsChecked) {
                dispatch(
                    addToFavoritesFetch({
                        comment,
                        serviceId: service?.id,
                        selectedGroupId: groupId,
                        fields: JSON.stringify({fields}),
                    })
                );
            }

            if (isClientExist) {
                if (isAmountCorrect) {
                    if (instrument) {
                        await payment();
                    }
                }
            } else {
                await checkIfUserExist(
                    isAuthToken,
                    service?.id,
                    fieldsJSON,
                    groupId || 0
                );
            }
        } catch (error) {
            notify(`${t("error")}`, false);
            setIsDisabled(false);
        } finally {
            setBtnLoading(false)
            setIsDisabled(false);
        }
    };

    const onBtnClickBasket = async() => {
        const amountValueNumber = Number(amountValue)
        //@ts-ignore
        const updatedFields = fields.map(({ qrfield, barfield, id, ...rest }) => {
            return { id: Number(id), ...rest };
        });
        const data = {
            isAuth: true,
            сartItemId: 0,
            cartId: basket?.cart?.id || "",
            amount: amountValueNumber*100,
            serviceId: service.id,
            fields: JSON.stringify(updatedFields),
            subName: subName,
            subValue: subVal,
            selectedGroup: groupId,
            token
        }
        const response = await dispatch(addToCart(data))
        if (response.payload?.ResultCodes === "ok") {
            notify(`${t("addedToCart")}`, true);
        } else {
            notify(`${t("notAddedToCart")}`, false);
        }
    }

    const payment = async () => {
        if (instrument?.card || instrument?.newCardData) {
            payWithCard();
        } else if (instrument?.wallet) {
            await payWithWallet();
        } else if (instrument?.bonus) {
            await payWithBonus();
        }
        return;
    };

    const payWithCard = async () => {
        if (instrument?.isNewCard) {
            payWithNewCard();
        } else {
            if (instrument?.card?.isMasterPassMember) {
                forms?.payWithSavedCard(instrument.card.name, {
                    serviceId: service.id,
                    amount: Number(amountValue),
                    fields: fieldsJSON,
                    payAllDebt: false,
                    subName: subName,
                    subVal: subVal,
                    desc: "",
                    lng: language,
                    selectedGroupId: groupId || 0,
                });
            } else {
                try {
                    const { payload: cardToken } = await dispatch(
                      fetchGetCardToken({
                        body: {
                          CardId: instrument.card.id,
                        },
                        browserToken: browserToken,
                      }),
                    )

                    let body: any = {
                    returnUrl: getResultPaymentUrl(language),
                      sessionId: localStorage.getItem('token'),
                      serviceId: service.id,
                      amount: Number(amountValue),
                      fields: fieldsJSON,
                      selectedGroupId: groupId || 0,
                      bindingId: cardToken,
                      subName,
                      subVal,
                      desc: '',
                      lng: language,
                      tokenType: 4,
                    }
                    setBtnLoading(true)
                    const res = await client.post(
                      `payment/do_pay_with_group_id/${localStorage.getItem(
                        'token',
                      )}?${toQueryParams(body)}`,
                      {},
                      {
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                      },
                    )
                    setBtnLoading(false)

                    if(res.data.formUrl) {
                        window.location.href = res.data.formUrl
                    }

                  } catch (error) {
                    console.log('error')
                  }
            }
        }
    };
    const payWithNewCard = async () => {
        if(!isHubSolutionPaymentMethod) {
            const payWithNewCard = async () => {
                const {newCardData} = instrument;

                const cardData: any = {
                    cvv: newCardData.cvc,
                    year: newCardData.expiryDate.substring(4, 7).trim(),
                    month: newCardData.expiryDate.substring(0, 3).trim(),
                    cardNumber: newCardData.cardNumber,
                };

                try {
                    if (newCardData.cardName) {
                        cardData.cardName = newCardData.cardName;
                    }

                    await forms?.payWithNewCard(
                        cardData,
                        {
                            serviceId: service?.id,
                            amount: Number(amountValue),
                            fields: fieldsJSON,
                            payAllDebt: false,
                            subName: subName,
                            subVal: subVal,
                            desc: comment,
                            lng: language,
                            selectedGroupId: groupId || 0,
                        },
                        () => {
                            console.log("success");
                        }
                    );
                } catch (e) {
                    notify(`${t("error")}`, false);
                }
            };

            payWithNewCard()

            return
        }

        const {newCardData} = instrument;

        try {
            const { payload: cardToken } = await dispatch(
                fetchTokenize({
                    body: {
                        ...createHubCardData(newCardData),
                    },
                    browserToken: browserToken,
                }),
            )

            let body: any = {
                returnUrl: getResultPaymentUrl(language),
                sessionId: localStorage.getItem('token'),
                serviceId: service.id,
                amount: Number(amountValue),
                fields: fieldsJSON,
                selectedGroupId: groupId || 0,
                bindingId: cardToken,
                subName,
                subVal,
                desc: '',
                lng: language,
                tokenType: 4,
            }
            setBtnLoading(true)
            const res = await client.post(
                `payment/do_pay_with_group_id/${localStorage.getItem(
                'token',
                )}?${toQueryParams(body)}`,
                {},
                {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                },
            )
            setBtnLoading(false)

            if(res.data.formUrl) {
                window.location.href = res.data.formUrl
            }
        } catch (e) {
            notify(`${t("error")}`, false);
        }
    };

    const payWithWallet = async () => {
        const wallet = walletList?.wallet?.find(
            (wallet) => wallet.currency === "AZN"
        );
        if (Number((wallet?.balance || 0) >= Number(amountValue) * 100)) {
            await forms?.doPayWithWallet(
                service.id,
                "AZN",
                Number(amountValue),
                fieldsJSON,
                false,
                subName,
                subVal,
                comment,
                groupId || 0,
                (orderId, historyId) => {
                    navigate(
                        `/epay/${language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`
                    );
                }
            );
        }
    };

    const payWithBonus = async () => {
        await forms?.doPayByBonuses(
            service.id,
            Number(amountValue),
            fieldsJSON,
            false,
            subName,
            subVal,
            comment,
            groupId || 0,
            (orderId, historyId) => {
                navigate(
                    `/epay/${language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`
                );
            },
            () => {
            }
        );
    };

    if (isServiceLoading || service === null) {
        return <LoadingLocal/>;
    }

    // @ts-ignore
    const infoDebtClient = debtInfoClient?.info?.values?.serviceInfoItem[0]?.additionalInfoBasedOnServiceInfoItem;

    const closeInsuranceModal  = () => {
        setActiveModal(false)
    }

    return (
        <LayoutEmpty goBack={() => navigate(-1)}>
            <div style={{
                display: 'flex',
                width: infoDebtClient?.lenght > 0 ? '' : '100%',
                overflow: 'auto',
                height: '100%',
                justifyContent: 'space-between'
            }}>
                {service?.id === 715 ?
                    <CustomModal active={activeModal} setActive={setActiveModal}>
                        <div className="insurance-modal">
                            <div className="insurance-modal-body">
                                {"İcbari Həyat Sığortası ödənişlərini icra etmək üçün digər bölməyə - "}
                                <Link to={''} className="insurance-modal-body-link">{"İcbari Sığorta Bürosuna "}</Link>
                                <span>{"daxil olmalısınız"}</span>
                            </div>
                            <div className="insurance-modal-close">
                                <img
                                    src={require("../../img/close-icon.svg").default}
                                    alt="Close"
                                    width={15}
                                    height={15}
                                    loading={"lazy"}
                                    onClick={closeInsuranceModal}
                                />
                            </div>
                        </div>
                    </CustomModal> : <></>
                }
                <div className="payment-add">
                    {(isPaymentLoading || isLoading) && (
                        <LoadingLocal/>
                    )}
                    {service ?
                        <>
                            <h1 className="payment-add__service-title">
                                {service?.localName
                                    ? service?.localName[langKey as keyof LocaleName]
                                    : ""}
                            </h1>
                            {Object.values(service?.localDesc as Record<string, string>).every((value: any) => !value.trim()) ? null : (
                                <div className="payment-add-alert">
                                    <div className="payment-add-alert-info">{`${t('information')}`}</div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: service?.localDesc[langKey as keyof LocaleName]}}/>
                                </div>
                            )}

                            <div className="payment-wrap">
                                <div className="payment-add__content">
                                    <PaymentFields
                                        service={service && service}
                                        onValueChange={(data: any) => {
                                            data.hasOwnProperty("fields") && setFields(data.fields);
                                            data.hasOwnProperty("groupId") && setGroupId(data.groupId);
                                        }}
                                        isClientExist={isClientExist}
                                        azercell={service?.id === 976}
                                        fieldsForPicker={fieldsForPicker}
                                        defaultFields={state?.fields}
                                        defaultGroupId={groupId}
                                        onFieldsValidationChange={(isValid: boolean | null) => setIsFieldsValid(isValid)}
                                    />
                                    {isClientExist ? (
                                        <>
                                            <AdditionalFields
                                                service={service}
                                                checkData={checkData}
                                                defaultValue={amountValue}
                                                isAmountCorrect={isAmountCorrect}
                                                onChange={(data: any) => {
                                                    data.hasOwnProperty("subVal") && setSubVal(data.subVal);
                                                    data.hasOwnProperty("subName") &&
                                                    setSubName(data.subName);
                                                    data.hasOwnProperty("amountValue") &&
                                                    setAmountValue(data?.amountValue);
                                                    data.hasOwnProperty("isAmountCorrect") &&
                                                    setIsAmountCorrect(data.isAmountCorrect);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </div>

                                {infoDebtClient && infoDebtClient?.length > 0 &&
                                    <div className='paymnet-add__right-block'>
                                        <div className="paymnet-add__right-block-info">
                                            {infoDebtClient && infoDebtClient.map(({
                                                                                       key,
                                                                                       value,
                                                                                       mutliLangValueString
                                                                                   }: any, index: number) => (
                                                <div key={index} className="debt-item">
                                                    <div
                                                        className='multilangValue'>{mutliLangValueString[langKey as keyof LocaleName]}</div>
                                                    <div className='value-debt'>{value}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                            {isClientExist && <div style={{width: "100%", marginBottom: "20px"}}>
                                <div className="payment-add__card-box">
                                    <CardsBox
                                        canBonus={canBonus}
                                        canWallet
                                        setInstrument={(data: IInstrument) => {
                                            setInstrument(data);
                                        }}
                                        currentHubSolutionPaymentMethodHidden={true}
                                    />
                                </div>

                                {!state?.favorite && (
                                    <AddCheckboxIn
                                        onClick={() =>
                                            setAddToFavoriteIsChecked((prevS) => !prevS)
                                        }
                                        isChecked={addToFavoriteIsChecked}
                                        title={`${t("addFavourites")}`}
                                        padding={"30px 0 5px 0"}
                                    />
                                )}
                            </div>}
                            <div className="payment-add__btns">
                                <button
                                    onClick={() => onBtnClick()}
                                    className={
                                        isDisabled || isPaymentLoading || btnLoading
                                            ? "payment-add__btn payment-add__btn--disabled"
                                            : "payment-add__btn"
                                    }
                                >
                                    {btnLoading ? (
                                        <MiniSpinner/>
                                    ) : (
                                        isClientExist ? t("pay") : t("check")
                                    )}
                                </button>
                                {isClientExist &&
                                    <button
                                        onClick={() => onBtnClickBasket()}
                                        className={
                                            isDisabled || isPaymentLoading
                                                ? "payment-add__btn payment-add__btn--disabled-guest"
                                                : "payment-add__btn-guest"
                                        }
                                    >
                                        {" "}
                                        {t("addToBasket")}
                                    </button>
                                }
                            </div>
                        </> : <p style={{textAlign: "center"}}>{t('serviceNotFound')}</p>
                    }
                </div>

                <>
                    <div className="payment-add__other" style={{height: dataFromChild ? "700px" : "auto"}}>
                        <OtherPayments handleDataFromChild={handleDataFromChild}/>
                    </div>
                    <div className="payment-add__swiper">
                        <div className="layout__app-otherWrap">
                            <div className='layout__app-otherWrap-border'></div>
                            <div className="layout__app-otherSwiper">
                                <OtherPaymentSwiper/>
                            </div>
                        </div>
                    </div>
                </>

            </div>
        </LayoutEmpty>
    );
};

export default PaymentAdd;
