import client, { BASE_URL } from "../../../axios";
import { fetchWithTimeout, toQueryParams } from "../../../utils/query";
import { GuestPaymentActionCreators } from "../guestPayment/action-creators";
import { LoadingActionCreators } from "../loading/action-creators";
import { MsgToUserActionCreators } from "../msgToUser/action-creators";
import { MessageType } from "../msgToUser/types";

export const GuestActionCreators = {
  doGuestPay:
    (
      serviceId: number,
      amount: number,
      fields: string,
      payAllDebt: boolean,
      subName: string,
      subVal: string,
      selectedGroupId: number,
      returnUrl: string,
      cardPanId: string,
      currency?: string,
      tokenType?: number,
    ) =>
      async (dispatch: any) => {
        dispatch(LoadingActionCreators.loadingPaymentState(true));
        try {
          // const response = await fetchWithTimeout(
          //   BASE_URL + "/guest/do_pay_with_group_id",
          //   {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/x-www-form-urlencoded",
          //       "Payment-Origin": "Web",
          //     },
          //     body: toQueryParams({
          //       serviceId: serviceId,
          //       amount: amount,
          //       fields: fields,
          //       payAllDebt: payAllDebt,
          //       subName: subName,
          //       subVal: subVal,
          //       selectedGroupId: selectedGroupId,
          //       returnUrl: returnUrl,
          //       cardPanId: cardPanId,
          //       currency,
          //     }),
          //   }
          // );

          const paymentData = toQueryParams({
            serviceId: serviceId,
            amount: amount,
            fields: fields,
            payAllDebt: payAllDebt,
            subName: subName,
            subVal: subVal,
            selectedGroupId: selectedGroupId,
            returnUrl: returnUrl,
            cardPanId: cardPanId,
            currency,
            tokenType,
          })

          const response = await client.post(
            `/guest/do_pay_with_group_id?${paymentData}`,
            {},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          // const response = await client.post(
          //   "/guest/do_pay_with_group_id",
          //   {
          //     body: toQueryParams({
          //       serviceId: serviceId,
          //       amount: amount,
          //       fields: fields,
          //       payAllDebt: payAllDebt,
          //       subName: subName,
          //       subVal: subVal,
          //       selectedGroupId: selectedGroupId,
          //       returnUrl: returnUrl,
          //       cardPanId: cardPanId,
          //       currency,
          //     }),
          //   },
          //   {
          //     headers: {
          //       "Content-Type": "application/x-www-form-urlencoded",
          //     },
          //   }
          // );
          // if (response.status === 200) {
          //   const json = await response.json();
          if (response.data?.resultCodes === "OK") {
            dispatch(
              GuestPaymentActionCreators.saveUrlOrder(
                response.data?.formUrl,
                response.data?.historyId,
                response.data?.orderId
              )
            );
            return response.data?.formUrl;
          } else {
            // dispatch(MsgToUserState(MessageType.SomeError));
          }
          // } else {
          //   // dispatch(MsgToUserState(MessageType.SomeError));
          // }
        } catch (e) {
          console.warn("doGuestPay catch error", e);
          dispatch(
            MsgToUserActionCreators.msgToUserState(MessageType.CatchError)
          );
        } finally {
          dispatch(LoadingActionCreators.loadingPaymentState(false));
        }
      },

    doInvoicePay:
    (
      tokenType: number,
      cardPanId: string,
      amount: number,
      lng: string,
      historyId: number,
      returnUrl: string,
      invoiceUnique: string
    ) =>
      async (dispatch: any) => {
        dispatch(LoadingActionCreators.loadingPaymentState(true));
        try {
          const paymentData = toQueryParams({
            tokenType: tokenType,
            cardPanId: cardPanId,
            amount: amount,
            lng: lng,
            historyId: historyId,
            returnUrl: returnUrl,
            invoiceUnique: invoiceUnique
          })
          const response = await client.post(
            `/guest/do_invoice_pay?${paymentData}`,
            {},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          if (response.data?.resultCodes === "OK") {
            // dispatch(
            //   GuestPaymentActionCreators.saveUrlOrder(
            //     response.data?.formUrl,
            //     response.data?.historyId,
            //     response.data?.orderId
            //   )
            // );
            return response.data?.formUrl;
          }
        } catch (e) {
          console.warn("doGuestPay catch error", e);
          dispatch(
            MsgToUserActionCreators.msgToUserState(MessageType.CatchError)
          );
        } finally {
          dispatch(LoadingActionCreators.loadingPaymentState(false));
        }
      },
};
