import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import App from "./Components/App/App";
import { setupStore } from "./redux/store";
import { createTheme, ThemeProvider } from "@material-ui/core";
import store from "./redux/index";
import './i18n'
import Loading from "./Components/Loading/Loading";
import { setShowUnAuthModal, signOut} from "./redux/actions/authActions";
import {BASE_URL} from "./axios";
import axios from "./axios";
import {orange} from "@material-ui/core/colors";
import "./index.scss";

const theme = createTheme({
    palette: {
        // primary:{
        //      main:'#f12626'
        // },
        secondary: orange,
    },
});
const store2 = setupStore();
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

(window as any).store = store;

const token = localStorage.getItem('token')


axios.post(`${BASE_URL}user/check_session?sessionId=${token}`).then((res) => {

        if(res?.data?.resultCodes !== "OK") {
            if(localStorage.getItem('token')) {
                store.dispatch(signOut());
                store.dispatch(setShowUnAuthModal(false));
                window.location.search
                    ? window.history.pushState({}, "", window.location.protocol + "//" + window.location.host + window.location.search)
                    : window.history.pushState({}, "", window.location.protocol + "//" + window.location.host)
            }
        }

    root.render(
        <ThemeProvider theme={theme}>
            <Provider store={{ ...store2, ...store }}>
                <BrowserRouter>
                        <App/>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    );
})

