import React from 'react';
import '../index.scss'

const AdvantAutoPayment = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Avtomatik ödəniş nədir?"}</div>
                <div
                    className="about__right-text">{"Avtomatik ödəniş  çox rahat bir xidmət növüdür. Bu servis, E-Pul hesabı olan istifadəçiyə özünün bilavasitə iştirakı və hər hansı hərəkəti olmadan sistematik ödənişlərin avtomatik silinməsini həyata keçirməyə imkan verir. Bir qayda olaraq, bu növ ödənişlər – internet və kabel televiziyası xidmətlərinin abunə haqları, dövri kommunal xidmətlər, telefon hesablarının ödənməsi kimi sistematik ödəniş tələb edən xidmətlərin ödənməsində istifadə edilir. E-Pul.az istifadəçisinin nəinki avtomatik ödənişin dövriliyini (hər ay, hər həftə, hər gün), hətta ödənişin baş vermə vaxtını və saatını seçmək imkanı vardır. Əgər  istifadəçisi dövri ödənişləri saxlamaq qərarına gələrsə, bu halda onun şəxsi kabinetində müxtəlif imkanlar yaranır. Məsələn, o, hansısa müəyyən bir xidmət üzrə avtomatik ödənişi həm müvəqqəti saxlaya bilər, həm də onu avtomatik ödənişlər siyahısından çıxara bilər."}
                </div>
            </div>
        </div>
    );
};

export default AdvantAutoPayment;