import React from "react"
import ContentLoader from "react-content-loader"

const SliderSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={320}
        viewBox="0 0 500 320"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="19" rx="10" ry="10" width="100" height="292" />
        <rect x="116" y="21" rx="10" ry="10" width="255" height="292" />
        <rect x="388" y="20" rx="10" ry="10" width="100" height="292" />
    </ContentLoader>
)

export default SliderSkeleton