import React, { FC } from 'react';

import './index.scss';
import CustomModal from "../ModalComponent/CustomModal/CustomModal";
import {useTranslation} from "react-i18next";


interface IProps {
    titleText: string;
    isModalOpen: boolean;
    clickToDelete: () => void;
    setIsModalOpen: () => void;
}

const CustomDeleteModal: FC<IProps> = ({ titleText, isModalOpen, setIsModalOpen, clickToDelete }) => {
    const { t } = useTranslation();

    return(
        <CustomModal setActive={setIsModalOpen} active={isModalOpen}>
            <div className="delete-modal">
                <div className="delete-modal__close-icon"
                     onClick={() => setIsModalOpen()}>
                    <img className="delete-modal__close"
                         src={require('../../img/close-icon.svg').default} alt="close-icon"/>
                </div>
                <div className="delete-modal__title">
                    {titleText}
                </div>
                <div className="delete-modal__btns">
                    <button
                        className="delete-modal__btn delete-modal__btn--delete"
                        onClick={() => clickToDelete()}
                    >
                        {t("delete")}
                    </button>
                    <div className="delete-modal__btn delete-modal__btn--cancel"
                         onClick={() => setIsModalOpen()}>{t("canceling")}</div>
                </div>
            </div>
        </CustomModal>

    )
}

export default CustomDeleteModal;