const useWalletServiceData = (amountValue: string, c2w: any, language: string) => {
  return () => {
    if (!c2w) {
      return;
    }

    const serviceFields = c2w.serviceFields.serviceField;
    let fieldsService = serviceFields.map((item: any) => ({ id: item.id.toString(), name: item.name, value: 'AZN' }));

    return {
      serviceId: c2w.id,
      amount: Number(amountValue),
      fields: JSON.stringify({ fields: fieldsService }),
      payAllDebt: c2w.hasDebt,
      subName: c2w.subName,
      subVal: '',
      desc: '',
      lng: language,
      selectedGroupId: 0,
      availablePaymentSystem: c2w?.availablePaymentSystem,
    };
  };
};

export default useWalletServiceData;
