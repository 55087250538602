import React from "react"
import ContentLoader from "react-content-loader"

const MediaLess610FavSkeleton = () => (
    <ContentLoader
        speed={2}
        width={610}
        height={70}
        viewBox="0 0 610 70"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="19" y="66" rx="0" ry="0" width="600" height="5" />
        <circle cx="51" cy="34" r="25" />
        <rect x="90" y="17" rx="0" ry="0" width="160" height="10" />
        <rect x="90" y="40" rx="0" ry="0" width="148" height="10" />
        <rect x="420" y="23" rx="10" ry="10" width="120" height="24" />
        <rect x="560" y="24" rx="5" ry="5" width="32" height="24" />
    </ContentLoader>
)

export default MediaLess610FavSkeleton