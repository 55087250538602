import React, {FC} from 'react';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./index.scss"

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 367,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
};

interface ModalRenameCardComponentProps {

}

const ModalRenameCardComponent: FC = (props: ModalRenameCardComponentProps) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal">
                        <div className="modal__head"></div>
                        <div onClick={handleClose}><img className="modal__head-icon" src={require("../../img/close-icon.svg").default}/></div>
                    </div>
                    <div className="modal__body">
                        <span className="modal__body-name">{"Название карты"}</span>
                        <div className="modal__body-card">
                            <input type="text" className="no-outline" placeholder="Моя карта 1"/>
                        </div>
                    </div>
                    <div>
                        <div className="modal__footer-delete">
                            <button className="modal__footer-button">{"Удалить"}</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ModalRenameCardComponent;