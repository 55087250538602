import React from 'react';
import "../../pages/MessageInfoPage/index.scss"
import {NavLink} from "react-router-dom";

interface NavigationComponentProps{
    title: string
    onClick?:()=>void
}

const NavigationComponent = ({title, onClick}: NavigationComponentProps) => {
    return (
        <div onClick={onClick} className="message-info__header-back">
            <div className="message-info__header-back-icon">
                <img className="message-info__header-back-img"
                     src={require("../../img/back-left-icon.svg").default} alt=""/>
            </div>
            <div className="message-info__header-back-text">{title}</div>
        </div>
    );
};

export default NavigationComponent;
