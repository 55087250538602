import { useLocation } from "react-router-dom"
import AdvertisingBanner from "../CustomsComponents/InstallAppAds/AdvertisingBanner";

export const HeadBanner = () => {
    const location = useLocation();
    if(location.pathname === '/epay/:lang/sign/in'){
        return <></>
    }

    return (
        <AdvertisingBanner />
    )
}