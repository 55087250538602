import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICategories } from "../../models/Categories";
import client, { BASE_URL } from "../../axios";
import { categoriesSliceActions } from "../slices/categoriesSlice";
import { fetchWithTimeout } from "../../utils/query";
import { bonusServicesActionCreators } from "../reducers/bonusServices/action-creators";

// const baseUrl = 'https://www.e-pul.az/epul_api/'
// const token = localStorage.getItem("token")

// function saveCategoriesDigests(
//     categoriesDigests: any,
//     categoriesDigestMap: {[key: number]: number},
//     servicesDigests: any,
//     servicesDigestMap: {[key: number]: number},
//     categories: any,
//     services: any,
// ) {
//     return {
//         type: 'SAVE_CATEGORIES_DIGESTS',
//         categoriesDigests,
//         categoriesDigestMap,
//         servicesDigests,
//         servicesDigestMap,
//         categories,
//         services,
//     };
// }

export const saveCategoriesDigests = (payload: any) => ({
    type: "SAVE_CATEGORIES_DIGESTS",
    payload,
});

export const fetchAllCategories = createAsyncThunk('categories/fetchAllCategories',
    async (_, thunkAPI) => {
        try {
            const response = await client.get<ICategories>(`guest/categories/list`)
            if (response.status === 200 && response.data.resultCodes === 'OK') return response.data

        } catch (e) {

        }
    })

export const fetchAllCategoriesIsAuth = createAsyncThunk('categories/fetchCategories',
    async (_, thunkAPI) => {
        try {
            const resCategories = await client.get<ICategories>(`categories/list`)
            if (resCategories.status === 200 && resCategories.data.resultCodes === 'OK') return resCategories.data

        } catch (e) {

        }
    })

export const checkCategoriesDigests = createAsyncThunk('categories/checkCategoriesDigests',
    async (_, thunkApi) => {
        try {
            const localeCategories = localStorage.getItem('categories');
            const localeServices = localStorage.getItem('services');
            const localeCategoriesDigests = localStorage.getItem('categoriesDigests');
            const localeCategoriesDigestMap = localStorage.getItem('categoriesDigestMap');
            const localeServicesDigests = localStorage.getItem('servicesDigests');
            const localeServicesDigestMap = localStorage.getItem('servicesDigestMap');

            if (localeCategories !== null && localeServices !== null) {
                thunkApi.dispatch(categoriesSliceActions.saveCategoriesDigest({
                    categories: JSON.parse(localeCategories),
                    services: JSON.parse(localeServices)
                }))
            } else {
                console.log('checkCategoriesDigests thunk');
                thunkApi.dispatch(fetchCategoriesDigestQuick())
            }

            if (localeCategoriesDigests !== null && localeCategoriesDigestMap !== null && localeServicesDigests !== null && localeServicesDigestMap !== null) {
                thunkApi.dispatch(categoriesSliceActions.setDigestInfo(
                    {
                        categories: localeCategories ? JSON.parse(localeCategories) : [],
                        services: localeServices ? JSON.parse(localeServices) : [],
                        categoriesDigests: JSON.parse(localeCategoriesDigests),
                        categoriesDigestMap: JSON.parse(localeCategoriesDigestMap),
                        servicesDigests: JSON.parse(localeServicesDigests),
                        servicesDigestMap: JSON.parse(localeServicesDigestMap),
                    }
                ))
            }

            thunkApi.dispatch(fetchCategoriesDigestLocale())

        } catch (e) {
            console.log('checkCategoriesDigests catch error', e);
        }
    }
)

export const fetchCategoriesDigestQuick = createAsyncThunk('categories/fetchCategoriesDigestQuick',
    async (_, thunkApi) => {
        try {
            const categoriesRes = await client.get<any>(`digest/guestListPaymentCategoriesOnly`);

            if (categoriesRes.status === 200) {
                if (categoriesRes.data.resultCodes !== 'OK') {
                    return;
                }
                const newCategories = categoriesRes.data.categories.category;

                thunkApi.dispatch(categoriesSliceActions.saveCategoriesDigest({
                    categories: newCategories,
                    services: [],
                }))
            }
        } catch (e) {
            console.log('fetchCategoriesDigestQuick catch error', e)
        }
    }
)

export const fetchCategoriesDigestLocale = createAsyncThunk('categories/fetchCategoriesDigestLocale',
    async (_, thunkApi) => {
        try {
            const response = await client.get<any>(`digest/guestListPaymentCategoriesDigest`);

            if (response.status === 200) {

                if (response.data.resultCodes !== "OK") {
                    return;
                }

                const {
                    categoriesDigests,
                    categoriesDigestMap,
                    servicesDigests,
                    servicesDigestMap,
                    services,
                    categories
                } = (thunkApi.getState() as any).categoriesReducer;


                console.log(`categoriesReducer:`, (thunkApi.getState() as any).categoriesReducer)

                const newCategoriesDigests = response.data.categories;

                //эта штука превращает массив категорий в поле объекта {[categoryId]: categoryHash}
                const newCategoriesDigestMap = response.data.categories.reduce((a: any, b: any) => {
                    a[b.id] = b.hash;
                    return a;
                }, {} as { [key: number]: number });

                const newServicesDigests = response.data.services;
                //эта штука превращает массив сервисов в поле объекта {[serviceId]: serviceHash}
                const newServicesDigestMap = response.data.services.reduce((a: any, b: any) => {
                    a[b.id] = b.hash;
                    return a;
                }, {} as { [key: number]: number });

                //это ищет среди новых сервисов, тех кто не присутствует в сторе
                const processServices = newServicesDigests.filter((s: any) => servicesDigestMap[s.id] !== s.hash).map((s: any) => s.id);

                //если сервис уже присутствует, то он превращается в поле объекта {[serviceId]: true}
                const processServicesMap = processServices.reduce((a: any, b: any) => {
                    a[b] = true;
                    return a;
                }, {} as { [key: number]: boolean });

                //это ищет среди новых категорий, тех кто не присутствует в сторе
                const processCategories = newCategoriesDigests.filter((s: any) => categoriesDigestMap[s.id] !== s.hash).map((s: any) => s.id);
                //если категория уже присутствует, то он превращается в поле объекта {[categoryId]: true}
                const processCategoriesMap = processCategories.reduce((a: any, b: any) => {
                    a[b] = true;
                    return a;
                }, {} as { [key: number]: boolean });

                const dropServices = servicesDigests.filter((s: any) => newServicesDigestMap[s.id] === undefined);
                const dropServicesMap = dropServices.reduce((a: any, b: any) => {
                    a[b.id] = true;
                    return a;
                }, {} as { [key: number]: boolean });

                const dropCategories = categoriesDigests.filter((s: any) => newCategoriesDigestMap[s.id] === undefined);


                categories.forEach((c: any) => {
                    if (newCategoriesDigestMap[c.id] === undefined) {
                        dropCategories.push({ id: c.id })
                    }
                })

                const dropCategoriesMap = dropCategories.reduce((a: any, b: any) => {
                    a[b.id] = true;
                    return a;
                }, {} as { [key: number]: boolean });


                if (!dropServices.length && !dropCategories.length && !processCategories.length && !processServices.length) {
                    // Nothing changed;
                    return;
                }

                const process = async (
                    dropServicesMap: { [key: number]: boolean },
                    dropCategoriesMap: { [key: number]: boolean },
                    loadedServices: any[],
                    loadedCategories: any[],
                ) => {
                    const newCategories = categories.filter((c: any) => !dropCategoriesMap[c.id] && !processCategoriesMap[c.id]).concat(loadedCategories);
                    const newServices = services.filter((c: any) => !dropServicesMap[c.id] && !processServicesMap[c.id]).concat(loadedServices);

                    localStorage.setItem('categoriesDigests', JSON.stringify(newCategoriesDigests));
                    localStorage.setItem('categoriesDigestMap', JSON.stringify(newCategoriesDigestMap));
                    localStorage.setItem('servicesDigests', JSON.stringify(newServicesDigests));
                    localStorage.setItem('servicesDigestMap', JSON.stringify(newServicesDigestMap));
                    localStorage.setItem('categories', JSON.stringify(newCategories));
                    localStorage.setItem('services', JSON.stringify(newServices));

                    const categoriesLocale = localStorage.getItem('categories');
                    const servicesLocale = localStorage.getItem('services');
                    const categoriesDigests = localStorage.getItem('categoriesDigests');
                    const categoriesDigestMap = localStorage.getItem('categoriesDigestMap');
                    const servicesDigests = localStorage.getItem('servicesDigests');
                    const servicesDigestMap = localStorage.getItem('servicesDigestMap');

                    if (categoriesLocale && servicesLocale && categoriesDigests && categoriesDigestMap && servicesDigests && servicesDigestMap) {
                        thunkApi.dispatch(categoriesSliceActions.setDigestInfo(
                            {
                                categories: JSON.parse(categoriesLocale),
                                services: JSON.parse(servicesLocale),
                                categoriesDigests: JSON.parse(categoriesDigests),
                                categoriesDigestMap: JSON.parse(categoriesDigestMap),
                                servicesDigests: JSON.parse(servicesDigests),
                                servicesDigestMap: JSON.parse(servicesDigestMap),
                            }
                        ))
                    }


                }

                if (!processCategories.length && !processServices.length) {
                    await process(dropServicesMap, dropCategoriesMap, [], []);
                    return;
                }

                const categoriesHash = encodeURI(processCategories.toString())
                const servicesHash = encodeURI(processServices.toString())

                const [loadedServicesResponse, loadedCategoriesResponse] = await Promise.all(
                    [processServices.length ? client.post<any>(`digest/guestListPaymentServicesSelected/${servicesHash}`, {
                        selected: processServices
                    }) : Promise.resolve(undefined),
                    processCategories.length ? client.post<any>(`digest/guestListPaymentCategoriesSelected/${categoriesHash}`, {
                        selected: processCategories
                    }) : Promise.resolve(undefined)]);

                if (loadedServicesResponse && loadedServicesResponse.status !== 200) {
                    console.log(loadedServicesResponse);
                    return;
                }
                if (loadedCategoriesResponse && loadedCategoriesResponse.status !== 200) {
                    console.log(loadedCategoriesResponse);
                    return;
                }

                const loadedServices = loadedServicesResponse ? await loadedServicesResponse.data.list.service.map((s: any) => {
                    s.iconHash = 'service_' + s.iconHash;
                    return s;
                }) : [];
                const loadedCategories = loadedCategoriesResponse ? await loadedCategoriesResponse.data.categories.category : [];

                await process(dropServicesMap, dropCategoriesMap, loadedServices, loadedCategories);

            } else {
                console.warn('error')
                //notify some error
            }

        } catch (e) {
            console.log('fetchCategoriesDigestLocale catch error', e)
        }
    }
)

export const fetchServicesForCategories = createAsyncThunk('categories/fetchServicesForCategories',
    async (categoriesIds: any, thunkApi) => {
        try {
            const servicesRes = await client.post<any>(`digest/guestListPaymentServicesForCategoriesSelected`, {
                selected: categoriesIds,
            });

            if (servicesRes.status !== 200) {
                return;
            }

            return servicesRes

        } catch (e) {
            console.log('checkCategoriesDigests catch error', e);
        }
    }
)

export const getServices = createAsyncThunk('categories/getServices',
    async (categoryName: any, thunkApi) => {
        try {
            if (localStorage.getItem('services') !== null) {
                const servicesLocal = localStorage.getItem('services');
                const category = localStorage.getItem('categories');
                if (servicesLocal && category) {
                    const parsedServices = JSON.parse(servicesLocal);
                    const parsedCategories = JSON.parse(category);
                    const findCategory = parsedCategories?.find((item: any) => item.name === categoryName);
                    return parsedServices?.filter((service: any) => service.categoryId === findCategory.id);
                }
            } else {
                await thunkApi.dispatch(fetchCategoriesDigestQuick());
                const { categories } = (thunkApi.getState() as any).categoriesReducer;
                const category = categories?.find((item: any) => item?.name === categoryName);

                const services: any = await thunkApi.dispatch(fetchServicesForCategories([category?.id]))

                return services.payload.data.list.service;

            }

        } catch (e) {
            console.log('checkCategoriesDigests catch error', e);
        }
    }
)

export const fetchBonusService = () => async (dispatch: any, getState: any) => {
    try {
        console.log('checkBonusServiceDigest start');
        const response = await fetchWithTimeout(BASE_URL + 'digest/listBonusServicesDigest', {
            method: 'GET'
        });

        if (response.status === 200) {
            const json = await response.json();
            console.log('checkBonusServiceDigest resultCodes' + json.resultCodes);
            if (json.resultCodes !== "OK") {
                return;
            }

            const { bonusServicesDigests, bonusServicesDigestMap, bonusServices } = (getState() as any).bonusServices;

            const newServicesDigests = json.list;
            const newServicesDigestMap =  json.list.reduce((a: any, b: any) => {
                a[b.id] = b.hash;
                return a;
            }, {} as {[key: number]: number});

            const processServices = newServicesDigests.filter((s: any) => bonusServicesDigestMap[s.id] !== s.hash).map((s: any) => s.id);
            const processMap = processServices.reduce((a: any, b: any) => {
                a[b] = true;
                return a;
            }, {} as {[key: number]: boolean});

            const dropServices = bonusServicesDigests.filter((s: any) => newServicesDigestMap[s.id] === undefined);
            const dropServicesMap = dropServices.reduce((a: any, b: any) => {
                a[b.id] = true;
                return a;
            }, {} as {[key: number]: boolean});

            console.log('checkBonusServiceDigest dropServices.length=' + dropServices.length + ', proccessServices.length=' + processServices.length);

            if (!dropServices.length && !processServices.length) {
                // Nothing changed;
                return;
            }

            const process = async (
                dropServicesMap: { [key: number]: boolean },
                loadedServices: any[],
            ) => {
                const newServices = bonusServices.filter((c: any) => !dropServicesMap[c.id] && !processMap[c.id]).concat(loadedServices);

                localStorage.setItem('bonusServicesDigests', JSON.stringify(newServicesDigests));
                localStorage.setItem('bonusServicesDigestMap', JSON.stringify(newServicesDigestMap));
                localStorage.setItem('bonusServices', JSON.stringify(newServices));

                const servicesLocale = localStorage.getItem('bonusServices');
                const servicesDigests = localStorage.getItem('bonusServicesDigests');
                const servicesDigestMap = localStorage.getItem('bonusServicesDigestMap');

                if (servicesLocale && servicesDigests && servicesDigestMap) {
                    dispatch(bonusServicesActionCreators.setBonusServices(
                        {
                            services: JSON.parse(servicesLocale),
                            servicesDigests: JSON.parse(servicesDigests),
                            servicesDigestMap: JSON.parse(servicesDigestMap),
                        }
                    ))
                }

                dispatch(bonusServicesActionCreators.setBonusServices({ newServicesDigests, newServicesDigestMap, newServices }));
                // console.log('checkBonusServiceDigest', newServicesDigests, newServicesDigestMap, newServices);
            }

            if (!processServices.length) {
                await process(dropServicesMap, []);
                return;
            }

            const servicesHash = encodeURI(processServices.toString())

            const [loadedServicesResponse] = await Promise.all(
                [processServices.length ? client.post<any>(`digest/guestListPaymentServicesSelected/${servicesHash}`, {
                    selected: processServices
                }) : Promise.resolve(undefined)]);

            console.log('checkBonusServiceDigest loadedServicesResponse.status=' + loadedServicesResponse?.status);

            if (loadedServicesResponse?.status !== 200) {
                console.log(loadedServicesResponse);
                return;
            }

            const loadedServices = await loadedServicesResponse.data.list.service.map((s: any) => {
                s.iconHash = 'service_' + s.iconHash;
                return s;
            });;

            await process(dropServicesMap, loadedServices);
        }
        else {
            console.warn('error')
        }
    }
    catch (e) {
        console.warn('error', e);
        return;
    }
}

export const checkBonusServicesDigests = () => async (dispatch: any) => {
    try {
        const localeServices = localStorage.getItem('bonusServices');
        const localeServicesDigests = localStorage.getItem('bonusServicesDigests');
        const localeServicesDigestMap = localStorage.getItem('bonusServicesDigestMap');

        if (localeServicesDigests !== null && localeServicesDigestMap !== null) {
            dispatch(bonusServicesActionCreators.setBonusServices({
                newServicesDigests: JSON.parse(localeServicesDigests),
                newServicesDigestMap: JSON.parse(localeServicesDigestMap),
                newServices: localeServices ? JSON.parse(localeServices) : [],
            }));

        }

        dispatch(fetchBonusService())

    } catch (e) {
        console.log('checkBonusServiceDigest catch error', e);
    }
}

