import React from "react"
import ContentLoader from "react-content-loader"

const SliderSkeleton885 = () => (
    <ContentLoader
        speed={2}
        width={300}
        height={300}
        viewBox="0 0 300 400"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="9" rx="0" ry="0" width="3" height="378" />
        <rect x="270" y="7" rx="0" ry="0" width="3" height="378" />
        <rect x="2" y="385" rx="0" ry="0" width="270" height="3" />
        <rect x="-1" y="6" rx="0" ry="0" width="270" height="3" />
        <rect x="15" y="19" rx="0" ry="0" width="240" height="240" />
        <rect x="15" y="277" rx="0" ry="0" width="240" height="40" />
        <rect x="75" y="340" rx="0" ry="0" width="112" height="26" />
    </ContentLoader>
)

export default SliderSkeleton885
