import {createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {fetchCategoriesDigestQuick, fetchServicesForCategories, getServices} from "../actions/CategoriesActions";
import {ICategories} from "../../models/Categories";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitialState {
    isLoading: boolean,
    data: ICategories | null,
    categoriesDigests: any,
    categoriesDigestMap: any,
    servicesDigests: any,
    servicesDigestMap: any,
    categories: any,
    services: any,
}

const initialState: InitialState = {
    isLoading: false,
    data: null,
    categoriesDigests: [],
    categoriesDigestMap: {},
    servicesDigests: [],
    servicesDigestMap: {},
    categories: [],
    services: [],
}
export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        saveCategoriesDigest (state, action: PayloadAction<any>) {
            state.categories = action.payload.categories;
            state.services = action.payload.services;
        },
        setData(state, action) {
            state.data = action.payload
        },
        setDigestInfo (state, action) {
            state.categories = action.payload.categories;
            state.services = action.payload.services;
            state.categoriesDigests = action.payload.categoriesDigests
            state.categoriesDigestMap = action.payload.categoriesDigestMap
            state.servicesDigests = action.payload.servicesDigests
            state.servicesDigestMap = action.payload.servicesDigestMap
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesDigestQuick.pending.type, (state) => {
            state.isLoading = true
        })
        .addCase(fetchCategoriesDigestQuick.fulfilled.type, (state) => {
                state.isLoading = false
        })
        .addCase(fetchCategoriesDigestQuick.rejected.type, (state) => {
            state.isLoading = false
        })
        .addCase(fetchServicesForCategories.pending.type, (state) => {
            state.isLoading = true
        })
        .addCase(fetchServicesForCategories.fulfilled.type, (state) => {
            state.isLoading = false
        })
        .addCase(fetchServicesForCategories.rejected.type, (state) => {
            state.isLoading = false
        })

        // [fetchCategoriesDigestQuick.pending.type]: (state: InitialState) => {
        //     state.isLoading = true
        // },
        // [fetchCategoriesDigestQuick.fulfilled.type]: (state: InitialState) => {
        //     state.isLoading = false
        // },
        // [fetchCategoriesDigestQuick.rejected.type]: (state: InitialState) => {
        //     state.isLoading = false
        // },

    }
})

export const categoriesSliceActions = {
    ...categoriesSlice.actions
}

const selectCategoriesState = (state: RootState) => state.categoriesReducer;

export const categoriesSelector = createSelector(
    [selectCategoriesState],
    (categoriesReducer) => categoriesReducer
  );
  

export default categoriesSlice.reducer