import React from "react";
import { useLocation, Location } from "react-router-dom";
import OtherPayments from "../../Components/OtherPayments/OtherPayments";
import InstallApp from "../../Components/CustomsComponents/InstallApp";
import SideMenu from "../../Components/Ewallet/SideMenu/SideMenu";
import { CustomState } from "../../models/TransferCard";

const TransferCardSideMenu: React.FC = () => {
    const location: Location<CustomState> = useLocation();
    return (
        <div className="transfer-card__app">
            {location.state?.fromSideMenu ? (
                <SideMenu />
            ) : (
                <>
                    <div className="transfer-card__app-other">
                        <OtherPayments />
                    </div>
                    <div className="transfer-card__app-install">
                        <InstallApp />
                    </div>
                </>
            )}
        </div>
    );
};

export default TransferCardSideMenu;
