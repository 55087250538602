import { IBonusCategory } from  "../../models/BonusCategory";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchBonusCategory} from "../actions/bonusesAction";

interface InitialState {
    sid: string,
    error: string,
    bonusCategory: IBonusCategory[] | [],
    isLoadingCategory: boolean,
    selectedCategoryId: number
}

const initialState: InitialState = {
    sid: "",
    error: "",
    bonusCategory: [],
    isLoadingCategory: false,
    selectedCategoryId: -1
}

export const bonusCategory = createSlice({
    name: "bonusCategory",
    initialState,
    reducers: {
        setCategoryId(state, action) {
            state.selectedCategoryId = action.payload
        },

    },
    extraReducers: {
        [fetchBonusCategory.pending.type]: (state) => {
            state.isLoadingCategory = true
        },
        [fetchBonusCategory.fulfilled.type]: (state, action: PayloadAction<IBonusCategory[]>) => {
            state.isLoadingCategory = false
            state.error = ""
            state.bonusCategory = action.payload
        },
        [fetchBonusCategory.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoadingCategory = false
            state.error = action.payload
        }
    }
})

export default bonusCategory.reducer
export const setCategoryIdAction = (selectedCategoryId: number) => ({
    type: "bonusCategory/setCategoryId",
    payload: selectedCategoryId
})

