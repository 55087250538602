import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import Button from "../../Button/Button";
import "./Catalogue.scss";
import {useAppSelector} from "../../../hooks/redux";
import {fetchBonusCategory, fetchBonusStore,} from "../../../redux/actions/bonusesAction";
import {AppDispatch} from "../../../redux/store";
import {clearBonusStoreAction} from "../../../redux/slices/bonusStoreSlice";
import {useTranslation} from "react-i18next";
import BonusCatalogueSkeleton from "../../Sceletons/BonusCatalogueSkeleton/BonusCatalogueSkeleton";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import CategoriesComponent from "./CategoriesComponent";

const image = require("../../../img/hQCG.png");
const gift = require("../../../img/gift.svg").default;
const show = require("../../../img/show.svg").default;

const newCategory = [
  {
    id: -1,
    active: true,
    localName: {valueEn: "All", valueRu: "Все", valueAz: "Hamısı"},
  },
];

const mockArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Catalogue = () => {
  const {t} = useTranslation()
  const [offset, setOffset] = useState(0);
  const [categoryProductId, setCategoryProductId] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(0);

  // const [localeLoad, setLocaleLoad] = useState(false);
  const [clickLocaleLoad, setClickLocaleLoad] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {bonusStore, isLoading} = useAppSelector((state) => state.bonusStore);
  const {profile} = useAppSelector((state) => state.authReducer);
  const {bonusCategory, selectedCategoryId, isLoadingCategory} = useAppSelector((state) => state.bonusCategory);
  const { language} = useAppSelector(state => state.changeLangSlice)

  useEffect(() => {
    // setLocaleLoad(true);
    window.scrollTo(0, 0);
    dispatch(fetchBonusCategory());
    // setLocaleLoad(false);
    return () => {
      dispatch(clearBonusStoreAction());
    };
  }, []);

  useEffect(() => {
    setClickLocaleLoad(true);
    if (offset) {
      dispatch(
          fetchBonusStore({offset, selectedCategoryId, categoryProductId})
      );
    }
    setClickLocaleLoad(false);
  }, [selectedCategoryId, offset]);

  useEffect(() => {
    // setLocaleLoad(true);
    dispatch(clearBonusStoreAction());
    setOffset(0);
    dispatch(
      fetchBonusStore({selectedCategoryId, categoryProductId, offset })
    );
    // setLocaleLoad(false);
  }, [categoryProductId]);

  const handleMoreImage = () => {
    setClickLocaleLoad(true);
    setOffset((prevState) => prevState + 12);
    setClickLocaleLoad(false);
  };

  const navigate = useNavigate();
  const goBack = () => navigate(`/epay/${language}/bonuses`);
  const resCategory = [...newCategory, ...bonusCategory]

  const chooseCategory = (id: number) => {
    setCategoryProductId(id);
  };

  return (
      <LayoutEmpty goBack={goBack} navTitle={t("bonus")}>
        <div className="catalogue">
          <div className="catalogue-body">
            <div className="catalogue-body__head">
              <div style={{padding: "10px 0"}}>
              </div>
              <div className="catalogue-title">
                {" "}
                <h2 className="catalogue-body__title">{t("marketplace")}</h2>
                <div className="ads-blocks__bonus color">
                  {profile?.bonusValue}
                  <img src={gift} alt="bonus" className="ads-blocks__bonus-icon"/>
                </div>
              </div>
            </div>
              <CategoriesComponent
                  resCategory={resCategory}
                  chooseCategory={chooseCategory}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  isLoadingCategory={isLoadingCategory}
              />
            <>
              <div className="ads-blocks__catalogue">
                <div className="ads-blocks__body">
                  {
                    clickLocaleLoad ?
                      mockArr.map((item) => {
                        return <div className="skeleton-wrap-catalogue"><BonusCatalogueSkeleton key={item}/></div>
                      })
                      : (bonusStore.map((item: any, id: number) => (
                          <NavLink
                              to={`/epay/${language}/product/${item.id}`}
                              // state={{ bonusStore: item }}
                              key={id}
                              className="ads-blocks__item-wrapper catalogue__item"
                          >
                            <div className="image-wrap">
                              <img
                                  src={`data:image/png;base64, ${item.image}`}
                                  width={211}
                                  height={187}
                                  alt=""
                                  className="ads-blocks__image"
                              />
                            </div>

                              <div className="catalogue__item-name">{item.name}</div>

                            <div className="ads-blocks__bonus">
                              {item.bonusValue}
                              <img
                                  src={require("../../../img/gift.svg").default}
                                  alt="bonus"
                                  className="ads-blocks__bonus-icon"
                              />
                            </div>
                            {!!item.price &&
                                <div className="ads-blocks__price">
                                  {item.price / 100}
                                  <div className="ads-blocks__price__currency">AZN</div>
                                </div>
                            }
                          </NavLink>
                      )))
                  }
                  <div className="skeleton-wrap-footer">
                    {isLoading &&
                        mockArr.map((item: number) =>
                            <div className="skeleton-wrap-footer-wrap"><div className="skeleton-wrap-load"><BonusCatalogueSkeleton key={item}/></div></div>
                        )}
                  </div>
                  <div className="catalogue-blocks__wrap">
                    {offset + 12 <= bonusStore?.length && (
                        <div className="catalogue-blocks__button">
                          <Button
                              buttonText={`${t("uploadMore")}`}
                              background={"none"}
                              color="#EF5C27"
                              padding="16.5px 27px 16.5px 27px"
                              borderRadius={15}
                              margin="0 10px 0 0"
                              icon={show}
                              onClick={handleMoreImage}
                          />
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </LayoutEmpty>
  );
};

export default Catalogue;


// <div className="categories__no-category">
//   {t("noProductsCategory")}
// </div>
