import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../axios";
import { IHistoryList } from "../../models/HistoryList";
import moment from "moment";

const token = localStorage.getItem("token");

export const fetchHistoryList = createAsyncThunk(
  "historyList/fetchHistoryList",
  async (
    params:
      | {
          // pan: string | null | undefined;
          // serviceId: number | null | undefined;
          dt_from: string | null | undefined;
          dt_to: string | null | undefined;
          // sid: string | null | undefined;
        }
      | undefined,
    thunkAPI
  ) => {
    try {
        const resHistory = await client.get<IHistoryList, any>(
        `history/list/1?pan=all&serviceId=0&dt_from=${params?.dt_from}&dt_to=${params?.dt_to}&sessionId=${localStorage.getItem("token")}`,
          {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        return resHistory.data;
    } catch (e) {
        return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);
