import React from "react"
import ContentLoader from "react-content-loader"

const MyCardSkeleton = () => (
    <ContentLoader
        speed={2}
        width={280}
        height={110}
        viewBox="0 0 280 110"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="10" y="5" rx="10" ry="10" width="270" height="101" />
    </ContentLoader>
)

export default MyCardSkeleton