import { createSlice } from "@reduxjs/toolkit";
import { deleteFromCart, getCart, updateCartItem } from "../actions/basketActions";

interface InitialState {
    error: string,
    basket: {
        cartItems: [],
    } | [],
    isLoading: boolean
}

const initialState: InitialState = {
    error: "",
    basket: [],
    isLoading: false
}

export const basketSlice = createSlice({
    name: "basketSlice",
    initialState,
    reducers: {
        setBasket(state, action) {
            state.basket = action.payload
        },
        clearBasket(state) {
            state.basket = []
        },
        toggleToPay(state, action) {  
            //@ts-ignore
            state.basket.cart.cartItems = state.basket.cart.cartItems.map(item => {
                if (item.id === action.payload) {
                    return { ...item, toPay: !item.toPay };
                }
                return item;
            });
        },
        updateBasketItem(state, action) {  
            const { id, value, amount } = action.payload;
            //@ts-ignore
            state.basket.cart.cartItems = state.basket.cart.cartItems.map(item => {
                if (item.id === id) {
                  return { ...item, value: value, amount: amount };
                }
                return item;
              });
        },
        deleteFromBasket(state, action) {
            //@ts-ignore
            state.basket.cart.cartItems = state.basket.cart.cartItems.filter((item: any) => item.id !== action.payload);
        },
        setError(state, action) {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCart.pending, (state) => {
                state.isLoading = true;
                state.error = "";
            })
            .addCase(getCart.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isLoading = false;
            })
            .addCase(getCart.rejected, (state, action) => {
                state.error = action.payload as string;
                state.isLoading = false;
            })
            .addCase(updateCartItem.pending, (state) => {
                state.isLoading = true;
                state.error = "";
            })
            .addCase(updateCartItem.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateCartItem.rejected, (state, action) => {
                state.error = action.payload as string;
                state.isLoading = false;
            })
            .addCase(deleteFromCart.pending, (state) => {
                state.isLoading = true;
                state.error = "";
            })
            .addCase(deleteFromCart.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteFromCart.rejected, (state, action) => {
                state.error = action.payload as string;
                state.isLoading = false;
            });
    }
})

export default basketSlice.reducer
export const basketSliceActions = {
    ...basketSlice.actions
};
