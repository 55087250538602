export const categoriesInfo: any = {
    1: {
        img: require("./img/categories/mobile-icon.svg").default,
        name: "rechargingYourMobilePhone",
        backGround: "#FFEAE9",

    },
    2: {
        img: require("./img/categories/communal-icon.svg").default,
        name: "payUtilityBill",
        backGround: "#F0FFD9",

    },
    99: {
        img: require("./img/categories/card-to-card-icon.svg").default,
        name: "transferToCard",
        backGround: "#F3EFFF",
    },
    3: {
        img: require("./img/categories/network-icon.svg").default,
        name: "payInternet",
        backGround: "#E3EEFF",

    },
    9: {
        img: require("./img/categories/service-icon.svg").default,
        name: "bankingServices",
        backGround: "#FFF6E4",

    },
    5: {
        img: require("./img/categories/paymend-icon.svg").default,
        name: "paymentSystems",
        backGround: "#E8FFF1",

    },
    16: {
        name: "Магазинные сети",
        img: require("./img/categories/magazineNet-icon.svg").default,
        backGround: "#FFEAE9",
    },
    4: {
        name: "TV провайдеры",
        img: require("./img/categories/tv.svg").default,
        backGround: "#E3EEFF",
    },
    6: {
        name: "Стационарный телефон",
        img: require("./img/categories/station-mob-icon.svg").default,
        backGround: "#F0FFD9",
    },
    14: {
        name: "Транспорт",
        img: require("./img/categories/transport-icon.svg").default,
        backGround: "#FFF6E4",
    },
    12: {
        name: "Штрафы",
        img: require("./img/categories/penalty.svg").default,
        backGround: "#F3EFFF",
    },
    7: {
        name: "Развлекательные сервисы",
        img: require("./img/categories/inter-icon.svg").default,
        backGround: "#E8FFF1",
    },
    11: {
        name: "Образование",
        img: require("./img/categories/education-icon.svg").default,
        backGround: "#E3EEFF",
    },
    18: {
        name: "Отели и туризм",
        img: require("./img/categories/hotel-icon.svg").default,
        backGround: "#FFF6E4",
    },
    10: {
        name: "Страхование",
        img: require("./img/categories/insurance-icon.svg").default,
        backGround: "#FFEAE9",
    },
    13: {
        name: "Программное обеспечение",
        img: require("./img/categories/program-icon.svg").default,
        backGround: "#F3EFFF",
    },
    15: {
        name: "Гос. услуги",
        img: require("./img/categories/department-service-icon.svg").default,
        backGround: "#F0FFD9",
    },
    8: {
        name: "Другие",
        img: require("./img/categories/other-icon.svg").default,
        backGround: "#E8FFF1",
    },
    19: {
        name: "Другие",
        img: require("./img/categories/charity.svg").default,
        backGround: "#F3EFFF",
    },
    41: {
        name: "Другие",
        img: require("./img/categories/gas_station.svg").default,
        backGround: "#F3EFFF",
    },
    17: {
        name: "Другие",
        img: require("./img/categories/parking.svg").default,
        backGround: "#F3EFFF",
    },
    20: {
        name: "Другие",
        img: require("./img/categories/union.svg").default,
        backGround: "#E8FFF1",
    },
    42: {
        name: "Подарки",
        img: require("./img/bonus.svg").default,
        backGround: "#E8FFF1",
    },
}

export const bonusConvertTable=[
    {manat: 1, bns:40000},
    {manat: 2, bns:75000},
    {manat: 5, bns:145000},
    {manat: 8, bns:200000},
    {manat: 20, bns:400000},
    {manat: 40, bns:750000},
    {manat: 100, bns:1500000},
    {manat: 200, bns:2900000},
]