import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchBrowserToken } from '../actions/browserToken'
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitialState {
  browserToken: string
  isAuthBrowserToken: boolean | null
  isLoading: boolean
  error: string
}

const initialState: InitialState = {
  browserToken: '',
  isAuthBrowserToken: null,
  isLoading: false,
  error: '',
}

export const browserTokenSlice = createSlice({
  name: 'browserToken',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBrowserToken.pending.type]: state => {
      state.browserToken = ''
      state.isAuthBrowserToken = null
      state.isLoading = true
      state.error = ''
    },
    [fetchBrowserToken.fulfilled.type]: (state, action: PayloadAction<any>) => {
      console.log(`state:`, state)
      console.log(`fetchBrowserToken:`, action)
      state.isLoading = false
      state.error = ''
      state.browserToken = action.payload.data.browserToken
      state.isAuthBrowserToken = action.payload.isAuthBrowserToken
    },
    [fetchBrowserToken.rejected.type]: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.browserToken = ''
      state.isAuthBrowserToken = null
      state.isLoading = false
      state.error = action.payload
    },
  },
})

const selectBrowserTokenState = (state: RootState) => state.browserToken;

export const browserTokenSelector = createSelector(
    [selectBrowserTokenState],
    (browserToken) => browserToken
  );
 
export default browserTokenSlice.reducer
