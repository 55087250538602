import * as React from "react";
import {useEffect} from "react";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import SideMenu from "../SideMenu/SideMenu";
import "./WalletDetails.scss";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../hooks/redux";
import {fetchWalletList} from "../../../redux/actions/walletListAction";
import {AppDispatch} from "../../../redux/store";
import {useTranslation} from "react-i18next";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import { createWallet } from "../../../redux/actions/walletAction";
import Button from "../../Button/Button";
import { notify } from "../../../helper";

const WalletDetails = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {walletList} = useAppSelector((state) => state.walletList);
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const sid = localStorage.getItem("token");

  useEffect(() => {
    dispatch(fetchWalletList());
  }, []);

  const navigate = useNavigate();
  const goBack = () => navigate(`/epay/${language}/home`);

  const onCreateWallet = async () => {
    try {
      //@ts-ignore
      const result = await dispatch(createWallet(sid));
      //@ts-ignore
      if (result?.payload?.resultCodes === "OK") {
        notify(`${t("walletCreated")}`, true);
        dispatch(fetchWalletList());
      } else {
        notify(`${t("error")}`, false);
      }
    } catch (error) {
      notify(`${t("error")}`, false);
    }
  };

  return (
      <LayoutEmpty goBack={goBack} navTitle={t("back")}>
        <div className="wallet-details">
          <div className="wallet-details-body">
            <div>
              {/*<NavigationComponent onClick={goBack} title={t("back")}/>*/}
            </div>
            <h2 className="wallet-details__title">{t("e-wallet")}</h2>
            <div className="wallet-details__content">
              <div className="wallet-details__content-wallets">
                {walletList?.wallet?.length ? walletList?.wallet?.map((wallet) => (
                  <div className="auto-payments__header-create-payment bg-image wallet-details__content-inner">
                    <div className="wallet-details__content-inner-count">
                      <span className="wallet-details__header">
                        {t("wallet")} {wallet.currency}{" "}
                      </span>
                          <span className="wallet-details__balance">
                        {wallet.balance / 100}
                      </span>
                      </div>
                      <div className="wallet-details__content-inner-picture">
                        <img
                          src={require("../../../img/vector-epul.svg").default}
                          alt=""
                        />
                      </div>
                    </div>
              ))
              : 
              <Button
                buttonText={`${t("createWallet")}`}
                minWidth={368}
                background="#EF5C27"
                padding="14px 133px"
                color="#fff"
                borderRadius={15}
                onClick={()=>onCreateWallet()}
              />
              }
              </div>
              <div className="wallet-details__content-banner">
                <div className="wallet-details__content-banner-text">
                  {t("depositMore")}
                </div>
              </div>
            </div>
          </div>
          <div className="wallet-details-body__sideMenu">
            <SideMenu/>
          </div>
        </div>
      </LayoutEmpty>
  );
};

export default WalletDetails;
