import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslation } from "react-i18next";
import { getQrCode } from "../../redux/actions/walletAction";
import './index.scss'
import 'react-datepicker/dist/react-datepicker.css'
import InvoiceFields from "./InvoiceFields";
import InvoiceQR from "./InvoiceQR";
import { formatDate } from "../../utils/functions";
import { LoadingLocal } from "../Loading/Loading"

const Invoice = ({amount}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch();
    const [showInfo, setShowInfo] = useState(false);
    const [invoiceUnique, setInvoiceUnique] = useState(null);
    const { isLoading } = useAppSelector((state) => state.wallet);

    useEffect(() => {
        return () => { 
            setShowInfo(false)
        };
    }, [amount])

    const getQr = (description, date) => {
        const queryParams = {
            sessionId: localStorage.getItem('token'),
            amount: amount,
            phone: encodeURIComponent("+994000000001"),
            activeTill: formatDate(date),
            wallet_currency: 'AZN',
            description: description
        }
            dispatch(getQrCode(queryParams)).then((res) => {
            setShowInfo(true)       
            if(res.payload.status === 200) {
                setInvoiceUnique(res.payload.data.invoiceUnique)
            } else {

            }
        })
    }
    return (
        <div className="invoice">
            {isLoading ?
                <div className="card-loading"><LoadingLocal /></div>
                : 
                showInfo ? 
                    invoiceUnique ? 
                        <InvoiceQR invoiceUnique={invoiceUnique}/>
                        : 
                        <p className="failedInvoice">{t('failedToCreateInvoice')}</p>
                    : 
                    <div>
                        <InvoiceFields getQr={getQr}/>
                    </div>
            }
        </div>
    )
};

export default Invoice;