import React, {useEffect, useState, useRef} from "react";
import CustomMenuRegistration from "../../CustomMenuRegistration/CustomMenuRegistration";
import "./AsideSettings.scss";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import Button from "../../Button/Button";
import { signOut } from "../../../redux/actions/authActions";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {getUserPersonalLinkFetch} from "../../../redux/actions/getUserPersonalLinkAction";
import QRCode from "react-qr-code";

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import {t} from "i18next";
import ReactToPrint from "react-to-print";
import { cardsSliceActions } from "../../../redux/slices/cardsSlice";
import ModalButtons from "../../ModalButtons/ModalButtons";

interface AsideSettings {
  title?: string;
  profile?: any;
  value?: string;
}

const smsIcon = require('../../../img/message-icon.svg').default;
const facebookIcon = require('../../../img/facebook.png');
const whatsappIcon = require('../../../img/whatsapp.svg').default;
const telegramIcon = require('../../../img/telegram.svg').default;
const emailIcon = require('../../../img/email.svg').default;

const AsideSettings = ({ value}: any) => {
  // console.log("qrcode", value)
  //
  // console.log("profile AsideSettings", profile);
  const {t} = useTranslation();
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const printEl = useRef<any>();
  const [open, setOpen] = React.useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  // const handleOpen = () => setLogOutModal(true);
  // const handleClose = () => setLogOutModal(false);
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const {userLink, isLoading} = useAppSelector(state => state.getUserPersonalLink)
  const {profile} = useAppSelector(state => state.authReducer)

  useEffect(()=> {
    dispatch(getUserPersonalLinkFetch())
  },[dispatch])

  useEffect(() => {
    const close = (e: any) => {
      if(e.keyCode === 27){
        setLogOutModal(false)
        setActive(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[])

  const settingsMenuItem = [
    {
      id: 2,
      img: require('../../../img/notificationIcon.svg').default,
      title: t('notifications'),
      path: 'notifications',
    },
    {
      id: 3,
      img: require('../../../img/secure.svg').default,
      title: t("security"),
      path: 'security',
    },
    {
      id: 4,
      img: require('../../../img/quest.svg').default,
      title: t("help"),
      path: 'faq',
    },
    {
      id: 5,
      img: require('../../../img/asan-imza.png'),
      title: t("Asan Imza"),
      path: 'asan-imza',
    },
  ]

  const onPrintClick = () => {
    if (printEl.current) {
      printEl.current.style.display = "block";
    }
  };

  const handleSignOut = () => {
    dispatch(signOut());
    navigate(`/epay/${language}/home`);
  };

  let activeStyle = {
    textDecoration: "none",
    backgroundColor: "#FFEAE9",
    borderLeft: "5px solid red",
  };

  const copy = async () => {
    await navigator.clipboard.writeText(`https://e-pul.az/qr/${userLink?.personalLink}`);
    toast(`${t("successfullyCopied")}`);
  };
  const handleCloseLogout = () => setLogOutModal(false);

  return (
      <>
        <div className="registration-block">
          <div className="registration-block__header-item">
            <div
                className="menu-registration"
                style={open ? activeStyle : undefined}
            >
              <div
                  onClick={() => setActive(true)}
                  className="menu-registration__image-main"
              >
                <img
                    src={require("../../../img/qr2.svg").default}
                    className="menu-registration__image"
                    alt=""
                />
                <p className="menu-registration__text">{t("QRCard")} </p>
              </div>
              <CustomModal active={active} setActive={setActive}>
                <div className="qr-modal">
                  <div className="qr-modal__header">
                    <div className="qr-modal__close" onClick={() => setActive(false)}>
                      <img src={require("../../../img/close-icon.svg").default} alt=""/>
                    </div>
                  </div>
                  <div className="qr-modal__content">
                    <QRCode size={230} value={`https://e-pul.az/qr/${userLink?.personalLink}`} />
                    <div
                        className="qr-modal__user"
                        title={profile?.user?.firstName || "User"}
                    >
                      {isLoading ? 'Loading...' : profile?.user?.firstName || "User"}
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <div
                                className="qr-modal__print"
                                onClick={() => onPrintClick()}
                            >
                              {t('print')}
                            </div>
                        )}
                        content={() => printEl?.current}
                        pageStyle="print"
                        documentTitle="Onlayn Ödə | Onlayn Ödəmə Sistemi - EPUL"
                    />

                  </div>
                  <div className="qr-modal__footer">
                    <div className="qr-modal__links-title">
                      {t("yourLink")}
                    </div>
                    <div className="qr-modal__copy">
                      <div className="qr-modal__copy-link">
                        {userLink?.personalLink ? `https://e-pul.az/qr/${userLink?.personalLink}` : `${t("empty")}`}
                      </div>
                      <div className="qr-modal__copy-img" onClick={copy}>
                        <img src={require("../../../img/copy.svg").default} alt=""/>
                      </div>
                    </div>
                    <div className="qr-modal__links">
                      <div className="qr-modal__links-title">
                        {t("shareLink")}
                      </div>
                      <div className="qr-modal__links-box">
                        <div className="qr-modal__link">
                          <div className="qr-modal__link-img">
                            <img src={smsIcon} alt=""/>
                          </div>
                          <div className="qr-modal__link-title">SMS</div>
                        </div>
                        <div className="qr-modal__link">
                          <div className="qr-modal__link-img">
                            <EmailShareButton
                                children={<img src={emailIcon} alt=""/>}
                                url={`https://e-pul.az/qr/${userLink?.personalLink}`}
                            />
                          </div>
                          <div className="qr-modal__link-title">Email</div>
                        </div>
                        <div className="qr-modal__link">
                          <div className="qr-modal__link-img">
                            <WhatsappShareButton
                                children={<img src={whatsappIcon} alt=""/>}
                                url={`https://e-pul.az/qr/${userLink?.personalLink}`} />
                          </div>
                          <div className="qr-modal__link-title">Whatsapp</div>
                        </div>
                        <div className="qr-modal__link">
                          <div className="qr-modal__link-img">
                            <TelegramShareButton
                                children={ <img src={telegramIcon} alt=""/>}
                                url={`https://e-pul.az/qr/${userLink?.personalLink}`}
                            />
                          </div>
                          <div className="qr-modal__link-title">Telegram</div>
                        </div>
                        <div className="qr-modal__link">
                          <div className="qr-modal__link-img">
                            <FacebookShareButton
                                children={ <img src={facebookIcon} alt=""/>}
                                url={`https://e-pul.az/qr/${userLink?.personalLink}`}
                            />
                          </div>
                          <div className="qr-modal__link-title">Facebook</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomModal>
            </div>
            {settingsMenuItem.map((item, index) => (
                <CustomMenuRegistration key={index} item={item} />
            ))}
            <div
                onClick={() => setLogOutModal(true)}
                className="menu-registration"
                style={open ? activeStyle : undefined}
            >
              <div className="menu-registration__image-main">
                <CustomModal active={logOutModal} setActive={setLogOutModal}>
                  <ModalButtons text={'quitProfile'} buttonText={'logOut'} handleInstruction={handleSignOut} handleCancel={handleCloseLogout}/>
                </CustomModal>
                <img
                    src={require("../../../img/exit-icon.svg").default}
                    className="menu-registration__image"
                    alt=""
                />
                <p className="menu-registration__text">{t("logout")} </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{display: "none"}}>
          <div ref={printEl} className="qr-print" >
            <div className="qr-print__header">
              <div className="qr-print__logo">
                <img src={require('../../../img/logo-EPUL.svg').default} alt=""/>
              </div>
              { profile?.user.phone
                  ? <div className="qr-print__phone">{profile?.user.phone}</div>
                  : null
              }
            </div>
            <div className="qr-print__qr">
              <QRCode
                  size={256}
                  value={`https://e-pul.az/qr/${userLink?.personalLink}`}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>

      </>

  );
};

export default AsideSettings;
