import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { fetchTokenize } from '../actions/tokenize'

interface InitialState {
  cardToken: string
  isLoading: boolean
  error: string
}

const initialState: InitialState = {
  cardToken: '',
  isLoading: false,
  error: '',
}

export const tokenizeSlice = createSlice({
  name: 'tokenize',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTokenize.pending.type]: state => {
      state.cardToken = ''
      state.isLoading = true
      state.error = ''
    },
    [fetchTokenize.fulfilled.type]: (state, action: PayloadAction<string>) => {
      console.log(`fetchTokenize.fulfilled.type:`, fetchTokenize.fulfilled.type)
      console.log(`action:`, action)
      state.cardToken = action.payload
      state.isLoading = false
      state.error = ''
    },
    [fetchTokenize.rejected.type]: (state, action: PayloadAction<string>) => {
      console.log(`fetchTokenize.rejected.type:`, fetchTokenize.rejected.type)
      console.log(`action:`, action)
      state.cardToken = ''
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default tokenizeSlice.reducer
