import { MessageType, MsgToUserActionTypes } from './types';

const initialState = {
  msgNumber: undefined,
};

const msgToUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case MsgToUserActionTypes.MSGTOUSER: {
      if (action.msgNumber === MessageType.CatchError) {
        return { ...state };
      }
      return { ...state, msgNumber: action.msgNumber, details: action.details };
    }

    default:
      return state;
  }
};

export default msgToUserReducer;
