import React from "react"
import ContentLoader from "react-content-loader"

const MediaLess450HistorySkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={80}
        viewBox="0 0 410 80"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="6" y="65" rx="0" ry="0" width="400" height="5" />
        <circle cx="38" cy="29" r="28" />
        <rect x="80" y="16" rx="0" ry="0" width="140" height="8" />
        <rect x="80" y="34" rx="0" ry="0" width="130" height="8" />
        <rect x="284" y="21" rx="0" ry="0" width="77" height="12" />
        <rect x="376" y="24" rx="0" ry="0" width="20" height="7" />
    </ContentLoader>
)

export default MediaLess450HistorySkeleton