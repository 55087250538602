import React from 'react';
import {useNavigate} from "react-router-dom";
import {usePaymentInputs} from "react-payment-inputs";
import images from "react-payment-inputs/images";
import "./index.scss";
import NavigationComponent from "../CustomsComponents/NavigationComponent";
import HeaderText from "../CustomsComponents/HeaderText";
import AddNewCard from "../MyCardComponent/AddNewCard";
import CenterMode from "../MyCardComponent/CenterMode";
import BasicTabs from "../AutoPayments/Tabs/TabsComponent";
import InstallApp from "../CustomsComponents/InstallApp";
import AddCheckboxIn from "../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import Button from "../Button/Button";
import FormattedInputs from "../inputComponent/inputComponent";
import {useTranslation} from "react-i18next";

const Service = () => {
    const {t} = useTranslation()
    const [cardNumber, setCardNumber] = React.useState('')
    const [isChecked, setIsChecked] = React.useState(false)
    const navigate = useNavigate();


    const goBack = () => navigate(-1)

    const ERROR_MESSAGES = {
        emptyCardNumber: 'do111111111',
        invalidCardNumber: '12o',
    }

    const {
        getCardNumberProps,
        getCardImageProps,
    } = usePaymentInputs({
        errorMessages: ERROR_MESSAGES,
    })

    const handleChangeCardNumber = ({target: {value}}) => {
        setCardNumber(value)
    }

    return (
        <div className="service">
            <NavigationComponent onClick={goBack} title={"Услуги"}/>
            <div className="service__wrap">
                <div className="service__left">
                    <HeaderText title={"Перевод на карту"}/>
                    <AddNewCard/>
                    <CenterMode/>
                    <div className="service__body">
                        <p>{"На карту"}</p>
                        <div className="service__body-wrap">
                            <div className="service__body-wrap-input">
                                <label className='service__body-wrap-inputLabel'>
                                    <svg
                                        className='service__body-wrap-inputLabel-icon'
                                        {...getCardImageProps({images})}
                                    />
                                    <input
                                        className='service__body-wrap-inputLabel-inp'
                                        {...getCardNumberProps({onChange: handleChangeCardNumber})}
                                        value={cardNumber}
                                        placeholder='••••  ••••  ••••  ••••'
                                    />
                                </label>
                            </div>
                            <div
                                className="service__body-wrap-text">{"Перевод с карты на карту доступен только для карт банков Азербайджана"}</div>
                        </div>
                    </div>
                    <div className="service__body-input-wrap">
                        <div className="service__body-input">
                            <p className="service__sum">{"Сумма, AZN"}</p>
                            <FormattedInputs inputNumber/>
                            <p>{"+2 AZN комиссия"}</p>
                        </div>
                        <div className="service__body-input-text">{"Внимание! Коммисия 1%, минимум 1 AZN. Мы не несем ответсвенности за комиссии взимаемые банком"}</div>
                    </div>
                    <div className="service__body-comment">
                        <input className="no-outline" placeholder="Комментарий"/>
                    </div>
                    <AddCheckboxIn onClick={() => setIsChecked(!isChecked)} title={"Добавить платеж в избранное"}/>
                    <div className="service__footer-btn">
                        <Button
                            disabled={isChecked}
                            buttonText={`${t("pay")}`}
                            padding="14px 139px"
                            borderRadius={15}
                            color="#FFFFFF"
                            background={ "rgba(239, 92, 39, 1)"}
                        />
                    </div>
                </div>
                <div className="service__right">
                    <BasicTabs/>
                    <div className="service__right-install">
                        <InstallApp/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
