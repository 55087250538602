import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getUserPersonalLinkFetch} from "../actions/getUserPersonalLinkAction";

interface InitialState {
    userLink: any,
    error: string,
    isLoading: boolean
}

const initialState: InitialState = {
    userLink: null,
    error: "",
    isLoading: false
}

export const getUserPersonalLinkSlice = createSlice({
    name: "getUserPersonalLink",
    initialState,
    reducers: {},
    extraReducers: {
        [getUserPersonalLinkFetch.pending.type]: (state) => {
            state.isLoading = true
            state.userLink = null
        },
        [getUserPersonalLinkFetch.fulfilled.type]: (state, action) => {
            state.isLoading = false
            state.error = ""
            state.userLink = action.payload
        },
        [getUserPersonalLinkFetch.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.userLink = null
        }
    }
})

export default getUserPersonalLinkSlice.reducer