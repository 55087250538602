import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "./index.scss"
import Button from "../../Button/Button";
import {FC} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useAppSelector } from '../../../hooks/redux';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 406,
    height: 268,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 3,
    flexDirection: "column"
};

type Props = {
    handleOpen?: ()=>void,
    open?: any,
    handleClose?: ()=>void,
    setOpen?:any,
}

const ModalVirtualCard: FC<Props> = ({open, handleClose}) => {
    const {t} = useTranslation();
    const [isOTP, setIsOTP] = React.useState(true)
    const { language } = useAppSelector((state): any => state.changeLangSlice);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-virtual-card">
                        <p>{t("enterCode")}</p>
                        <img onClick={handleClose} src={require("../../../img/close-icon.svg").default} alt=""/>
                    </div>
                    <div className="modal-virtual-card__text">{t("sentNumber")}</div>
                    <div>{"(+994) 050 111 22 33"}</div>
                    <div className="modal-virtual-card__input-number">
                        <input placeholder="0"/>
                        <input placeholder="0"/>
                        <input placeholder="0"/>
                        <input placeholder="0"/>
                    </div>
                    <Button
                        buttonText={`${t("createVirtualCard")}`}
                        minWidth={348}
                        background="#EF5C27"
                        color="#FFFFFF"
                        borderRadius={15}
                        padding="14px 42px"
                        onClick={() => isOTP ? <Link to={`/epay/${language}/my-card`} /> : console.log("net")}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default ModalVirtualCard;

