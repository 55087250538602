import React from "react"
import ContentLoader from "react-content-loader"

const MessageSkeleton = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={100}
        viewBox="0 0 753 100"
        backgroundColor="#f5f5f4"
        foregroundColor="#efebeb"
    >
        <rect x="15" y="10" rx="0" ry="0" width="187" height="15" />
        <rect x="15" y="42" rx="0" ry="0" width="427" height="65" />
        <rect x="650" y="10" rx="0" ry="0" width="115" height="10" />
    </ContentLoader>
)

export default MessageSkeleton