import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchBanner} from "../actions/bannerAction";

interface InitialState {
    sid: string,
    error: string,
    isLoading: boolean,
    bannerList: null,
}

const initialState: InitialState = {
    sid: "",
    error: "",
    isLoading: false,
    bannerList: null,
}

export const bannerListSlice = createSlice({
    name: "banList",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchBanner.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBanner.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false
            state.error = ""
            state.bannerList = action.payload
        },
        [fetchBanner.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
    }
})

export default bannerListSlice.reducer
