import React, {useEffect, useState, useRef} from 'react';
import HeaderText from "../../Components/CustomsComponents/HeaderText";
import AddCheckboxIn from "../../Components/CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import {Link, useNavigate} from "react-router-dom";
import {categoriesInfo} from "../../consts";
import {Category, LocaleName, Service} from "../../models/Categories";
import LayoutWithSidebar from "../../Components/layouts/LayoutWithSidebar/LayoutWithSidebar";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {useAppSelector} from "../../hooks/redux";
import { deleteFromCart, updateCartItem } from "../../redux/actions/basketActions";
import { notify } from '../../helper';
import { basketSliceActions } from '../../redux/slices/backetSlice';
import "./index.scss";
import { LoadingLocal } from '../../Components/Loading/Loading';
import SliderPopularCategories from "./SliderPopularCategories";
import HomeService from "../../Components/HomePage/HomeService";
import {categoriesSelector} from "../../redux/slices/categoriesSlice";

const hint = ["azercell", "yelobank", "uninet"];
const BasketPage = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [inputString, setInputString] = useState<string>("");
    const [allServices, setAllServices] = useState<Category[]>([]);
    const { isAuth } = useAppSelector((state) => state.authReducer)
    const {categories, services} = useAppSelector(state => state.categoriesReducer);
    const auth = useAppSelector((state) => state.authReducer);
    const {language, langKey} = useAppSelector(state => state.changeLangSlice)

    const [openModals, setOpenModals] = useState<{ [key: number]: boolean }>({});
    const savedPath = auth.isAuth ? "new-payment" : "guest-payment"
    const modalRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    //@ts-ignore
    const { basket, isLoading } = useAppSelector((state) => state.basketSlice);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const cartItems = basket?.cart?.cartItems;
    const token = localStorage.getItem("token");
    const data = categories;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            Object.keys(modalRefs.current).forEach((key) => {
                //@ts-ignore
                if (modalRefs.current[key] && !modalRefs.current[key]!.contains(event.target as Node)) {
                    setOpenModals((prev) => ({ ...prev, [key]: false }));
                }
            });
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const popularity = [1, 2, 3, 5, 9];

        const main = data?.filter((item: any) => popularity?.includes(item.id))
        const others = data?.filter((item: any) => !popularity?.includes(item.id))
        const all = main.concat(others)

        if (data) {
            setAllServices(all)
        }
    }, [data, allServices.length]);

    const openInfo = (id: number) => {
        setOpenModals((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleToPay = async (item: any) => {
        const updatedFields = item?.fields.map((field: any) => ({
            id: Number(field.fieldId),
            name: field.name,
            value: field.value
        }));
        const data = {
            isAuth,
            cartId: item.cartId,
            id: item.id,
            amount: item.amount,
            serviceId: item.serviceId,
            fields: JSON.stringify(updatedFields),
            subName: item.paymentInfoServiceItem.name,
            subValue: item.paymentInfoServiceItem.value,
            selectedGroup: item.selectedGroup,
            toPay: !item.toPay,
            token
        };
        try {
            const response = await dispatch(updateCartItem(data));
            if (response?.payload.ResultCodes === "ok") {
                dispatch(basketSliceActions.toggleToPay(item.id));
            } else {
                notify(`${t("error")}`, false);
            }
        } catch (error) {
            console.error("Error editing item in cart:", error);
        }
    };

    const deleteItem = async (id: number) => {
        try {
          const response = await dispatch(deleteFromCart({ isAuth, cartId: basket.cart.id, cartItemId: id, token }));
          if (response?.payload.ResultCodes === "ok") {
            dispatch(basketSliceActions.deleteFromBasket(id));
            notify(`${t("removedFromCart")}`, true);
          } else {
            notify(`${t("notRemovedFromCart")}`, false);
          }
        } catch (error) {
          console.error("Error deleting item from cart:", error);
        }
      };

    const changeItem = (id: number) => {
        console.log("id basket", id)
        const itemData = cartItems.find((item: any) => item.id === id);
        console.log("itemData", itemData)
        const path = `/epay/${language}/basket/edit-item/${id}`;
        navigate(path, {state: {itemData} });
    };

    const onClickClear = () => {
        setInputString("");
        inputRef.current?.focus();
    };

    const filteredList = inputString?.trim()
        ? services?.filter((ser: Service) =>
            Object.values(ser.localName).some(name =>
                name.toLowerCase().includes(inputString.trim().toLowerCase())
            )
        )
        : [];

    return (
        <LayoutWithSidebar goBack={() => navigate(-1)} navTitle={t("home")}>
            <div className="basket-wrapper">
                {isLoading && <div className="basket-loading"><LoadingLocal /></div>}
                <div className="basket_content">
                    {cartItems?.length ? (
                        cartItems?.map((item: any, index: number) => {
                            return (
                                <div key={index} className="basket_content__wrap">
                                    <div className="basket_content__item">
                                        <div className="basket_content__item-check">
                                            <AddCheckboxIn
                                                isChecked={item.toPay}
                                                onClick={()=>handleToPay(item)}
                                            />
                                        </div>
                                        <div className="basket_content__item-image">
                                            <img src={`https://www.e-pul.az/epul_api/images/${item.friendlyName}.png`} />
                                        </div>
                                        <div className="basket_content__item-info">
                                            <div className="basket_content__item-info-field">
                                                {item?.fields?.slice(0, 2).map((field: any) => field?.value).join('')}
                                            </div>
                                            <div className="basket_content__item-info-serv">
                                                {item.serviceName?.[langKey]}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basket_content__right">
                                        <div className="basket_content__right-price">
                                            <span>{(item.amount / 100).toFixed(2)}</span>
                                            <span className="basket_content__right-price-item">{item.currency}</span>
                                        </div>
                                        <img className="basket-edit" src={require("./../../img/edit2.svg").default} alt={"trash"} onClick={() => changeItem(item.id)} />
                                        <img className="basket-delete" src={require("./../../img/trash.svg").default} alt={"trash"} onClick={() => deleteItem(item.id)} />
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="basket_content-empty">
                            <div className="basket_content-empty-head">{t('cartEmpty')}</div>
                            <div className="basket_content-empty-desc">
                                {t('selectPayment')}
                            </div>
                        </div>
                    )}
                </div>
                {cartItems?.length ? <div className="basket-wrapper-footer">
                    <div className="basket-wrapper-footer__title">{t('popularPayments')}</div>
                    <div className="basket-wrapper-footer-categories">
                        {categories.slice(0, 12).map((item: any, id: number) => {
                            return (
                                <Link
                                    state={{category: item, savedPath, processName: savedPath}}
                                    to={`/epay/${language}/category/${item.name}`}
                                    key={item.id}
                                    className="basket-wrapper-footer-categories__item"
                                >
                                    <div className="basket-wrapper-footer-categories__item-icon"
                                         style={{background: categoriesInfo[item.id]?.backGround}}>
                                        <div className="basket-wrapper-footer-categories__item-image">
                                            <img src={categoriesInfo[item.id]?.img} alt="Logo"/>
                                        </div>
                                    </div>
                                    <div className="basket-wrapper-footer-categories__item-text">
                                        {item.localName[langKey as keyof LocaleName]}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>:

                <HomeService
                    inputString={inputString}
                    setInputString={setInputString}
                    onClickClear={onClickClear}
                    hint={hint}
                    isLoading={isLoading}
                    filteredList={filteredList}
                    allServices={allServices}
                />
                }
                <SliderPopularCategories/>
            </div>
        </LayoutWithSidebar>
    );
};

export default BasketPage;
