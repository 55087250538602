import React from 'react';
import {Link} from "react-router-dom";
import "./index.scss"
import { useAppSelector } from '../../hooks/redux';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
    const { language } = useAppSelector((state) => state.changeLangSlice);

    return (
        <div className="not-found">
            <h1>404</h1>
            <Link to={`/epay/${language}/home`} className="not-found__link">{t('goHome')}</Link>
        </div>
    );
};

export default NotFound;
