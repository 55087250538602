import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IHistoryList} from "../../models/HistoryList";
import {fetchHistoryList} from "../actions/historyAction";
import {t} from "i18next";
import {cardsSlice} from "./cardsSlice";

interface InitialState {
    sid: string,
    error: string,
    historyList: IHistoryList | null,
    isLoading: boolean,
    listType: string,
}

const initialState: InitialState = {
    sid: "",
    error: "",
    historyList: null,
    isLoading: false,
    listType: t('general'),
}

export const historyListSlice = createSlice({
    name: "debtsList",
    initialState,
    reducers: {
        setListType(state, action) {
            state.listType = action.payload
        }
    },
    extraReducers: {
        [fetchHistoryList.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchHistoryList.fulfilled.type]: (state, action: PayloadAction<IHistoryList>) => {
            state.isLoading = false
            state.error = ""
            state.historyList = action.payload
        },
        [fetchHistoryList.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export default historyListSlice.reducer
export const historyListSliceActions = {
    ...historyListSlice.actions
};