import React, { FC } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";

interface IProps {}

export const BottomBar: FC<IProps> = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { language } = useAppSelector(state => state.changeLangSlice);

  const marketIcon =
  pathname === "/catalogue"
    ? require("../../img/market.svg").default
    : require("../../img/market-light.svg").default;
  const favIcon =
    pathname === "/favorites"
      ? require("../../img/favorites.svg").default
      : require("../../img/favorites-light.svg").default;
  const moreIcon =
    pathname === "/more-page"
      ? require("../../img/more-orange.svg").default
      : require("../../img/more-light.svg").default;
  const homeIcon = require("../../img/Ellipse.svg").default;
  const historyIcon =
    pathname === "/history"
      ? require("../../img/history-icon.svg").default
      : require("../../img/history-light.svg").default;

  return (
    <div className="bottom-bar">
      <div className="bottom-bar__content">
        <div className="bottom-bar__item">
          <Link to={`/epay/${language}/catalogue`} className="bottom-bar__tab">
            <div className="bottom-bar__tab-image">
              <img className="bottom-bar__icon" src={marketIcon} alt="" />
            </div>
            <div
              className="bottom-bar__tab-title"
              style={
                pathname === "/catalogue"
                  ? { color: "#EF5C27" }
                  : { color: "#888888" }
              }
            >
              {t("market")}
            </div>
          </Link>
        </div>
        <div className="bottom-bar__item">
          <Link to={`/epay/${language}/favorites`} className="bottom-bar__tab">
            <div className="bottom-bar__tab-image">
              <img className="bottom-bar__icon" src={favIcon} alt="" />
            </div>
            <div
              className="bottom-bar__tab-title"
              style={
                pathname === "/favorites"
                  ? { color: "#EF5C27" }
                  : { color: "#888888" }
              }
            >
              {t("favorites")}
            </div>
          </Link>
        </div>
        <div className="bottom-bar__item bottom-bar__item--home">
          <Link to={`/epay${language}/home`} className="bottom-bar__tab">
            <div className="bottom-bar__image">
              <img className="bottom-bar__icon" src={homeIcon} alt="" />
            </div>
          </Link>
        </div>
        <div className="bottom-bar__item">
          <Link to={`/epay/${language}/history`} className="bottom-bar__tab">
            <div className="bottom-bar__tab-image">
              <img className="bottom-bar__icon" src={historyIcon} alt="" />
            </div>
            <div
              className="bottom-bar__tab-title"
              style={
                pathname === "/history"
                  ? { color: "#EF5C27" }
                  : { color: "#888888" }
              }
            >
              {t("history")}
            </div>
          </Link>
        </div>
        <div className="bottom-bar__item">
          <Link to={`/epay/${language}/more-page`} className="bottom-bar__tab">
            <div className="bottom-bar__tab-image">
              <img className="bottom-bar__icon" src={moreIcon} alt="" />
            </div>
            <div
              className="bottom-bar__tab-title"
              style={
                pathname === "/more-page"
                  ? { color: "#EF5C27" }
                  : { color: "#888888" }
              }
            >
              {t("more")}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};