import React, { FC } from 'react';
import "./index.scss"
import VerticalIcon from "../CustomsComponents/VerticalIcon/VerticalIcon";
import {useAppSelector} from "../../hooks/redux";

interface CustomMyCardProps {
    cardName: string,
    cardNumber: string,
    cardPrice?: string,
    options?: string[],
    getSelectOption?: (value: string, item: any) => void;
    setCard?: any;
    item?: any;
    isDefault: boolean;
}

const checkedIcon = require('../../img/check.svg').default;

const CustomMyCard: FC<CustomMyCardProps> = (props) => {
    const { cardName, cardNumber, cardPrice, options, getSelectOption, item, isDefault} = props;

    const setData = (value: string) => {
        // setCard && setCard(item)
        getSelectOption && getSelectOption(value, item)
    }

    const getCardIcon = () => {
        if(cardNumber?.startsWith('2') || cardNumber?.startsWith('5')) {
            return isDefault ? require("../../img/master-silver.svg").default :  require("../../img/master-icon.svg").default
        } else if(cardNumber?.startsWith('4')) {
            return isDefault ? require("../../img/visa-silver.svg").default :  require("../../img/visa-icon.svg").default
        }
    }

    return (
        <div
            className={isDefault ? 'my-cards__card my-cards__card--active' : 'my-cards__card'}
        >
            <div className="my-cards__card-header">
                {
                    isDefault
                        ?
                        <div className="my-cards__card-check">
                            <img className="my-cards__img" src={checkedIcon} alt="icon" />
                        </div>
                        : null
                }

                <div
                    className={isDefault ? 'my-cards__card-title my-cards__card-title--active' : 'my-cards__card-title'}
                >
                    {cardName || 'Без имени'}
                </div>
                <div className="my-cards__card-icon">
                    <VerticalIcon options={options} getSelectOption={(data: string) => setData(data)}/>
                </div>

            </div>
            <div className="my-cards__card-body">
                <div className="my-cards__card-img">
                    <img className="my-cards__img" src={getCardIcon()} alt="icon" />
                </div>
                <div
                    className={isDefault ? 'my-cards__card-number my-cards__card-number--active' : 'my-cards__card-number'}
                >
                    {cardNumber}
                </div>
            </div>
            { //footer block for digital card
                 }
            {cardPrice ? (
                <div className="my-cards__card-footer">
                    <div className="my-cards__card-amount">
                        <div className="my-cards__card-count">454</div>
                        <div className="my-cards__card-currency">AZN</div>
                    </div>
                </div>
            ): null}

        </div>
    );
};

export default CustomMyCard;
