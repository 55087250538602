import React, { FC, useEffect, useState } from "react";
import './index.scss';
import LayoutWithApp from "../../layouts/LayoutWithApp/LayoutWithApp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
    commitRecurring,
    deleteAutoPayment,
    editAutoPayments,
    getSingleAutoPayment
} from "../../../redux/actions/autoPayments";
import RecurringPeriod from "../RecurringPeriod/RecurringPeriod";
import { useTranslation } from "react-i18next";
import { azercellId } from "../consts";
import { getPrice, notify } from "../../../helper";
import Button from "../../Button/Button";
import { ICard } from "../../../models/Card";
import moment from "moment/moment";
import { LoadingLocal } from "../../Loading/Loading";
import { LocaleName } from "../../../models/Categories";
import CustomDeleteModal from "../../CustomDeleteModal/CustomDeleteModal";
import { fetchRecurringPaymentEdit, fetchRecurringPaymentRemove } from "../../../redux/actions/habStorageAction";
import { FREE_PAY_URL, recurringPaymentCommitUrl } from "../../../utils/consts";

interface IProps { }

const RecurringEdit: FC<IProps> = () => {
    const { t } = useTranslation()
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [dataRec, setDataRec] = useState<any>(null);
    const { langKey } = useAppSelector(state => state.changeLangSlice)
    const { isPaymentLoading } = useAppSelector(state => state.loading);
    const serviceInState = useAppSelector(state => state.service);
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const serviceCurrent = location?.state?.service ? location?.state?.service : serviceInState?.service
    const [periods, setPeriods] = useState<any>('')
    const [clock, setClock] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [repeatingType, setRepeatingType] = useState('');
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const { browserToken } = useAppSelector(state => state.browserToken)
    const time = dataRec?.periods?.autoOrderPeriods?.map((period: any) => period.atClock)
    const mainField = dataRec?.fields?.autoOrderField?.find((field: any) => field?.isMainField);
    const correctFields = dataRec?.fields?.autoOrderField?.map((field: any) => (
        {
            id: field.id || field.fieldId.toString(),
            name: field.name,
            value: field.value,
        }
    ))

    useEffect(() => {
        const fetchPayment = async () => {
            if (params?.id && !dataRec) {
                setIsLoading(true)
                const result = await dispatch(getSingleAutoPayment(params?.id))
                setDataRec(result.payload.items.autoOrder[0])
                setIsLoading(false)
            }
        }
        fetchPayment()
    }, [dispatch, params?.id, dataRec?.serviceId]);

    useEffect(() => {
        const { state } = location;
        const recurringPeriods = dataRec?.periods?.autoOrderPeriods?.map((period: any) => period.day)
        const recurringTime = dataRec?.periods?.autoOrderPeriods?.map((period: any) => period.atClock)
        const isCardChange = state?.cardInfo?.pan ? dataRec?.maskedPan !== state?.cardInfo?.pan : false;
        const isAmountChange = state?.amount ? (dataRec?.amount / 100).toFixed() !== state?.amount : false;
        const isFieldsChange = state?.fields ? JSON.stringify(correctFields) !== JSON.stringify(state?.fields) : false;
        const isRepeatingTypeChange = dataRec?.repeatingType !== Number(repeatingType);
        const isPeriodsChange = JSON.stringify(recurringPeriods) !== JSON.stringify(periods)
        const isClockChange = JSON.stringify(clock) !== JSON.stringify(recurringTime)

        if (periods.length && (isCardChange || isAmountChange || isFieldsChange || isRepeatingTypeChange || isPeriodsChange || isClockChange)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }

    }, [correctFields, location, periods, dataRec?.amount, dataRec?.maskedPan, dataRec?.periods?.autoOrderPeriods, dataRec?.repeatingType, repeatingType, clock])

    const onEditPayment = (value: boolean) => {
        navigate(
            `/epay/${language}/recurring-transaction/edit`,
            {
                state: {
                    dataRec,
                    service: serviceCurrent,
                    isEdit: value,
                }
            }
        )
    }

    const getFieldValue = () => {
        let fields: any = [];
        if (dataRec?.fields) {
            fields = dataRec?.fields?.autoOrderField
        }
        if (fields?.length) {
            return fields.map((field: any, index: number, fieldsArr: any) => {
                if (azercellId === dataRec?.serviceId) {
                    if (index === 1) {
                        return fieldsArr[index - 1].value + field.value;
                    }
                } else {
                    return field?.value
                }
            })
        }
    }

    const deleteRecurring = async () => {

        const { payload } = await dispatch(
            fetchRecurringPaymentRemove({
                body: {
                    RecurringPaymentId: dataRec?.historyId.toString(),
                },
                browserToken: browserToken,
            }),
        )

        await dispatch(deleteAutoPayment({ id: dataRec?.id, token: localStorage.getItem('token') }));
        setDeleteModalActive(false);
        navigate(`/epay/${language}/recurring-payments`)
    }

    const saveRecurring = async () => {
        const { state } = location;
        const sessionId = localStorage.getItem('token');
        const fieldsJSON = state?.fields ? JSON.stringify({ fields: state?.fields }) : JSON.stringify({ fields: correctFields });
        const atClock = moment(new Date()).format('HH:mm');
        const mirrorId = dataRec?.mirrorId;
        const { cardInfo } = state;
        const isAmountIncreased = state?.amount * 100 > dataRec?.amount;
        const isCardChanged = cardInfo && cardInfo?.maskedPan !== dataRec?.maskedPan;

        setIsDisabled(true)
        setIsLoading(true)

        const data = {
            amount: state?.amount || (dataRec?.amount / 100).toFixed(),
            fields: fieldsJSON,
            atClock: clock,
            mirrorId,
            periods: periods,
            sessionId,
            serviceId: dataRec?.serviceId,
            bindingId: dataRec?.historyId.toString(),
            maskedPan: state?.cardInfo?.pan || dataRec?.maskedPan,
            paymentMode: 2,
            repeatingType: repeatingType,
            subServiceName: dataRec?.subServiceName,
            payCurrentDebt: false,
            maxAllowAmount: dataRec?.maxAllowAmount,
            selectedGroupId: dataRec?.groupId,
            subServiceValue: dataRec?.subServiceValue,
        }

        if (isAmountIncreased || isCardChanged) {
            const { payload: recurringData } = await dispatch(
                fetchRecurringPaymentEdit({
                    body: {
                        RecurringPaymentId: dataRec?.historyId.toString(),
                        CardId: cardInfo.id,
                        Amount: (+state.amount) * 100,
                        Currency: 'AZN',
                        Password: mirrorId,
                        RedirectUrl: `${recurringPaymentCommitUrl}${sessionId}?mirrorId=${mirrorId}&return=${window.location.host}`,
                    },
                    browserToken: browserToken,
                }),
            )

            await dispatch(editAutoPayments({ data, id: dataRec?.id }));

            if (recurringData?.redirectUrl) {
                window.location = `${FREE_PAY_URL}${recurringData?.redirectUrl}?lang=${language}` as any
            } else {
                window.location = `${recurringPaymentCommitUrl}${sessionId}?mirrorId=${mirrorId}&lang=${language}&paymentStatus=15` as any
            }
        } else {
            const redirectUrl = await dispatch(commitRecurring(mirrorId))

            await dispatch(editAutoPayments({ data, id: dataRec?.id }));

            if (redirectUrl?.payload) {
                window.location = redirectUrl.payload as any;
            }
        }
    }

    return (
        <LayoutWithApp goBack={() => navigate(`/epay/${language}/recurring-payments`)}>
            <div className='recurring-edit'>
                {
                    isLoading || isPaymentLoading
                        ? <LoadingLocal />
                        :
                        <>
                            <div className="recurring-edit__content-title">
                                {dataRec?.localName?.valueAz}
                            </div>
                            <div className="recurring-edit__content-box">
                                <div className="recurring-edit__content-field">
                                    <div className="recurring-edit__content-field-title">{t("service")}</div>
                                    <div
                                        className="recurring-edit__content-field-value">{dataRec?.localName?.valueAz}</div>
                                </div>
                                <div className="recurring-edit__content-field">
                                    <div
                                        className="recurring-edit__content-field-title"
                                    >
                                        {mainField
                                            ? mainField?.localName[langKey as keyof LocaleName]
                                            : dataRec?.fields?.autoOrderField[0]?.localName[langKey as keyof LocaleName]
                                        }
                                    </div>
                                    <div className="recurring-edit__content-field-value">
                                        {getFieldValue()}
                                        <img
                                            className='recurring-edit__icon'
                                            src={require("../../../img/edit-icon.svg").default}
                                            onClick={() => onEditPayment(false)}
                                        />
                                    </div>
                                </div>
                                <div className="recurring-edit__content-field">
                                    <div className="recurring-edit__content-field-title">{t("amountWithCommission")}</div>
                                    <div className="recurring-edit__content-field-value">
                                        {
                                            getPrice(location?.state?.amount * 100 || dataRec?.amount)
                                        }
                                        <img
                                            className='recurring-edit__icon'
                                            src={require("../../../img/edit-icon.svg").default}
                                            onClick={() => onEditPayment(true)}
                                        />
                                    </div>
                                </div>
                                <div className="recurring-edit__content-field">
                                    <div className="recurring-edit__content-field-title">{t("paymentMethod")}</div>
                                    <div className="recurring-edit__content-field-value">
                                        {
                                            location?.state?.cardInfo?.pan || dataRec?.maskedPan
                                        }
                                        <img
                                            className='recurring-edit__icon'
                                            src={require("../../../img/edit-icon.svg").default}
                                            onClick={() => onEditPayment(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="recurring-edit__period-box">
                                <RecurringPeriod
                                    cb={(type, period, clock) => {
                                        setRepeatingType(type)
                                        setPeriods(period)
                                        setClock(clock)
                                    }}
                                    recurring={dataRec}
                                    clock={time}
                                />
                            </div>
                            <div className="recurring-edit__btns">
                                <Button
                                    buttonText={`${t("save")}`}
                                    padding="14px 67px"
                                    color="#fff"
                                    borderRadius={15}
                                    background="#EF5C27"
                                    onClick={() => saveRecurring()}
                                    disabled={isDisabled}
                                />
                                <Button
                                    buttonText={`${t("delete")}`}
                                    padding="14px 67px"
                                    color="#EF5C27"
                                    border="1px solid #EF5C27"
                                    borderRadius={15}
                                    background="#fff"
                                    onClick={() => setDeleteModalActive((pr) => !pr)}
                                />
                                <CustomDeleteModal
                                    titleText={t("areDeleteAutopay")}
                                    isModalOpen={deleteModalActive}
                                    clickToDelete={() => deleteRecurring()}
                                    setIsModalOpen={() => setDeleteModalActive((pr) => !pr)}
                                />
                            </div>
                        </>
                }
            </div>
        </LayoutWithApp>
    )
}

export default RecurringEdit;