import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AsideSettings from "../../AsideSettings/AsideSettings";
import Button from "../../../Button/Button";
import NavigationComponent from "../../../CustomsComponents/NavigationComponent";
import "./Cvv.scss";
import AddCheckboxIn from "../../../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { changeCvvType } from "../../../../redux/actions/profileActions";
import {useTranslation} from "react-i18next";

const Cvv = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [paymentWithoutCvv, setPaymentWithoutCvv] = useState(false);
  const { language } = useAppSelector((state) => state.changeLangSlice);
  const goBack = () => navigate(`/epay/${language}/security`);
  const changeCvvPaymentType = () => {

    dispatch(changeCvvType({ params: { paymentWithoutCvv } }));
  };
  return (
    <div className="cvv">
      <div className="cvv-body">
        <NavigationComponent onClick={goBack} title={t("security")} />
        <h2 className="cvv-body__title">{t("changeCVV")}</h2>
        <div className="cvv__item-cvv">
          <span className="warning-message">
            {t("disableVerification")}{" "}
          </span>{" "}
          <AddCheckboxIn
            onClick={() => setPaymentWithoutCvv(!paymentWithoutCvv)}
            isChecked={paymentWithoutCvv}
            title={`${t("payWithout")}`}
            padding="25px 0 30px 0"
          />
          {/*<label className="container">*/}
          {/*  Платить без CVV / 3ds <input type="checkbox" />*/}
          {/*  <span className="checkmark"></span>*/}
          {/*</label>*/}
        </div>
        <Button
          onClick={() => changeCvvPaymentType()}
          buttonText={`${t("save")}`}
          color="white"
          background="#EF5C27"
          border="1px solid #fff"
          padding="14px 25px 14px 25px"
          borderRadius={15}
          margin="0 10px 0 0"
        />
      </div>

      <AsideSettings />
    </div>
  );
};

export default Cvv;
