import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IBonusStore } from "../../models/BonusStore";
import client from "../../axios";

const token = localStorage.getItem("token");

export const fetchProduct = createAsyncThunk(
  "product/fetchProductStatus",
  async () => {
    const { data } = await axios.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
    return data;
  }
);

export const fetchBonusStore = createAsyncThunk(
  "bonusStore/fetchBonusStore",
  async (sid: string | undefined, thunkAPI) => {
    const { data } = await client.get<IBonusStore, any>(
      "bonus/store/" + (sid || token)
    );
    return data;
  }
);

const initialState = {
  itemsProduct: [],
  status: "loading",
};

const productCatalogueSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductItems(state, action) {
      state.itemsProduct = action.payload;
    },
  },
  // extraReducers: {
  //     [fetchBonusStore.pending]: (state) => {
  //         state.status = "loading";
  //         state.itemsProduct = []
  //     },
  //     [fetchBonusStore.fulfilled]: (state, action) => {
  //         state.items = action.payload;
  //         state.status = 'success'
  //     },
  //     [fetchBonusStore.rejected]: (state) => {
  //         state.status = "error";
  //         state.itemsProduct = []
  //     }
  // }
});

export const { setProductItems } = productCatalogueSlice.actions;

export default productCatalogueSlice.reducer;
