import React, {useEffect, useState} from "react";
import "./History.scss";
import Button from "../Button/Button";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Search from "../Search/Search";
import {fetchHistoryList} from "../../redux/actions/historyAction";
import {useTranslation} from "react-i18next";
import HistoryItem from "./components/HistoryItem";
import HistorySkeleton from "../Sceletons/HistorySkeleton/HistorySkeleton";
import {fetchFavoritesList} from "../../redux/actions/favoritesListAction";
import LayoutWithSidebar from "../layouts/LayoutWithSidebar/LayoutWithSidebar";
import MediaHistorySkeleton from "../Sceletons/HistorySkeleton/MediaHistorySkeleton";
import MediaLessHistorySkeleton from "../Sceletons/HistorySkeleton/MediaLessHistorySkeleton";
import MediaLess450HistorySkeleton from "../Sceletons/HistorySkeleton/MediaLess450HistorySkeleton";
import {getDigitalCardStatementsThunk} from "../../redux/actions/cardsActions";
import DigitalHistoryItem from "./components/DigitalHistoryItem";
//@ts-ignore
import DatePicker from 'react-datepicker/dist/es/index.js';
import 'react-datepicker/dist/react-datepicker.min.css';
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { languageSelector } from "../../redux/reducers/language";

const show = require("../../img/show.svg").default;
const imagePerRow = 7;
const mockArr = [1, 2, 3, 4, 5, 6, 7];

const History = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [next, setNext] = useState(imagePerRow);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const { digitalCard } = useAppSelector(state => state.cardsReducer);
  const {historyList, isLoading, listType} = useAppSelector((state) => state.historyList);
  const [filterHistoryList, setfilterHistoryList] = useState(historyList?.items?.history);
  const [digitalCardData, setDigitalCardData] = useState(digitalCard?.statements?.data)
  const today: any = new Date();
  const minDate: any = new Date(new Date().setDate(today.getDate() - 29));
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(today);
  
  const { language } = useAppSelector(languageSelector);
  const navigate = useNavigate();
  const goBack = () => navigate(`/epay/${language}/home`);

  const dateConvert = (date: any) => {
    const dt_to = new Date(date);
    const day = String(dt_to.getDate()).padStart(2, '0');
    const month = String(dt_to.getMonth() + 1).padStart(2, '0');
    const year = dt_to.getFullYear();
    if (activeTab === 1) {
      return `${day}.${month}.${year}`
    } else {
      return `${year}-${month}-${day}`
    }
  };

  const handleChange = (range: any) => {
    const [startDate, endDate] = range;
    if (startDate == null && endDate == null) {
      setStartDate(minDate);
      setEndDate(today);
    } else {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const tabs = [
      {
        id: 1,
        value: t('general'),
      },
      {
        id: 2,
        value: t('virtualCard'),
      }
  ]
  
  useEffect(() => {
    if(listType === t('virtualCard')) {
      setActiveTab(2)
    } else {
      setActiveTab(1)
    }
  }, [digitalCard?.card?.id, listType, t])
  
  useEffect(() => {
    if (activeTab === 2) {
      if (startDate && endDate) {
        dispatch(getDigitalCardStatementsThunk({id: digitalCard.card?.id, dateFrom: dateConvert(startDate), dateTo: dateConvert(endDate)}))
      }
    }
  }, [activeTab, digitalCard.card?.id, dispatch, startDate, endDate])


  useEffect(() => {
    // dispatch(fetchBonuses());
    dispatch(fetchFavoritesList());
    if (activeTab === 1) {
      if (startDate && endDate) {
        dispatch(fetchHistoryList({dt_from: dateConvert(startDate), dt_to: dateConvert(endDate)}));
      }
    }
  }, [dispatch, activeTab, startDate, endDate]);

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  useEffect(() => {
    setfilterHistoryList(historyList?.items?.history)
  }, [historyList]);

  
  useEffect(() => {
    setDigitalCardData(digitalCard?.statements?.data)
  }, [digitalCard]);


useEffect(() => {
  if (activeTab === 2 && startDate && endDate) {
    setDigitalCardData(digitalCard?.statements?.data?.filter((item: any) => {
      const fullHistoryDigitalPayment = (
          item.name +
          item.currency +
          item.amount
      ).toLowerCase();
      if (fullHistoryDigitalPayment.includes(searchValue.toLowerCase())) {
        return true;
      }
    }));
  } else if (startDate && endDate) {
    setfilterHistoryList(historyList?.items?.history?.filter((item) => {
      const fullHistoryPayment = (
      //@ts-ignore
          item.localName["value" + langHistory] +
          item.currency +
          item.amount
      ).toLowerCase();
      if (fullHistoryPayment.includes(searchValue.toLowerCase())) {
        return true;
      }
    }));
  }
}, [searchValue, historyList, digitalCard]);

  const clickToTab = (id: number) => {
    setActiveTab(id)
    setSearchValue('')
    setStartDate(minDate);
    setEndDate(today);
  }

  const renderAllHistory = () => {
    return(
        isLoading ? (
            mockArr.map((item: any) => {
              return <>
                <div className="history-skeleton"><HistorySkeleton key={item}/></div>
                <div className="media-history-skeleton"><MediaHistorySkeleton key={item}/></div>
                <div className="media-less-history-skeleton"><MediaLessHistorySkeleton key={item}/></div>
                <div className="media-less450-history-skeleton"><MediaLess450HistorySkeleton key={item}/></div>
              </>
            })
        ) : historyList?.totalCount === 0 ? (
            <div className="history-left__body-empty">{t("empty")}</div>
        ) : (
            !isLoading &&
            filterHistoryList
                ?.slice(0, next)
                ?.map((item: any) => (
                    <HistoryItem
                        key={item.id}
                        item={item}
                        historyItemLoading={isLoading}
                    />
                ))
        )
    )
  }

const currentLang = i18next.language;
const langHistory = currentLang[0].toUpperCase() + currentLang.slice(1);

  const renderDigitalCardHistory = () => {
      const data = digitalCardData;

      return(
          digitalCard?.isProcessLoading ? <>
                  <div className="history-skeleton"><HistorySkeleton /></div>
                  <div className="media-history-skeleton"><MediaHistorySkeleton /></div>
                  <div className="media-less-history-skeleton"><MediaLessHistorySkeleton /></div>
                  <div className="media-less450-history-skeleton"><MediaLess450HistorySkeleton /></div>
              </> : (//@ts-ignore
                      !data?.length
                      ? <div className="history-left__body-empty">{t("empty")}</div>
                      : data?.map((item: any) => (
                          <DigitalHistoryItem
                              key={item.id}
                              item={item}
                              historyItemLoading={false}
                          />
                      )))
      )
  }

  return (
    <LayoutWithSidebar goBack={goBack} noBack={true} navTitle={t("home")}>
      <div className="history">
        <div className="history-left">
          <div className="history-left__title-wrapper">
            <div className="history-left__title">{t("paymentHistory")}</div>
            <div className="history-left__wrap">
              <Search
                  onChangeSearchValue={onChangeSearchValue}
                  inputString={searchValue}
              />
            </div>
          </div>
          <div className="history-left__body">
            <div className="history__tabs">
              <div className="tabs">
              {tabs?.map((tab: any) => (
                  <div
                      key={tab.id}
                      className={activeTab === tab.id ? "history__tab history__tab--active" : "history__tab"}
                      onClick={() => clickToTab(tab.id)}
                  >
                    {tab.value}
                  </div>
              ))}
              </div>
              <div className="history-picker">
                <DatePicker
                  selected={startDate}
                  onChange={handleChange}
                  isClearable
                  maxDate={today}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd.MM.yyyy" 
                />
              </div>
            </div>
            <p className="history__30days">{t('history30days')}</p>
            {
              activeTab === 1 ? renderAllHistory() : renderDigitalCardHistory()
            }
          </div>

          {//@ts-ignore
          !isLoading && activeTab === 1 && next < historyList?.items?.history?.length && filterHistoryList?.length >= imagePerRow && (
                  <div className="catalogue-blocks__button">
                    <Button
                        buttonText={`${t("uploadMore")}`}
                        background={"none"}
                        color="#EF5C27"
                        padding="16.5px 27px 16.5px 27px"
                        borderRadius={15}
                        margin="0 10px 0 0"
                        icon={show}
                        onClick={handleMoreImage}
                    />
                  </div>
              )
            }
        </div>
      </div>
      </LayoutWithSidebar>
  );
};

export default History;
