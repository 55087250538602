import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import "./index.scss"
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import HeaderText from "../../CustomsComponents/HeaderText";
import BasicTabs from "../BasicTabs/BasicTab";
import {fetchDebts} from "./../../../redux/actions/debtsAction";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import OtherPayments from "../../OtherPayments/OtherPayments";

type Props = {
    onClick?: (event: MouseEvent) => void,
}
const Debt: FC<Props> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [modalActive, setModalActive] = useState(false)
    const [modalPage, setModalPage] = React.useState(1);
    const {language} = useAppSelector((state) => state.changeLangSlice);

    useEffect(() => {
        dispatch(fetchDebts(localStorage.getItem("token") || ""))
    }, [dispatch]);

    const isModalView = false;

    const goBack = () => navigate(`/epay/${language}/home`)

    return (
        <LayoutEmpty goBack={goBack} navTitle={t("home")}>
            <div className="debt">
                <div className="debt__header">
                    {/*<NavigationComponent onClick={goBack} title={t("home")}/>*/}
                </div>
                <div className="debt__body">
                    <div className="debt__body-left">
                        <div className="debt__body-left-head">
                            <div className="debt__body-left-head-text">
                                <HeaderText title={t("debt")}/>
                            </div>
                            {/*пока не входит в бета-версию*/}
                            {/*<Button*/}
                            {/*    minWidth={237}*/}
                            {/*    buttonText={"Выставить счет-фактуру"}*/}
                            {/*    color="#fff"*/}
                            {/*    background="#EF5C27"*/}
                            {/*    padding="17px 29px"*/}
                            {/*    borderRadius={15}*/}
                            {/*    onClick={() => setModalActive(true)}*/}
                            {/*/>*/}
                        </div>
                        {/*пока не входит в бета-версию*/}
                        {/*{*/}
                        {/*    modalPage === 1*/}
                        {/*        ?*/}
                        {/*        <CustomModal active={modalActive} setActive={setModalActive}>*/}
                        {/*            <div className="modal-wrap">*/}
                        {/*                <div className="modal-debt">*/}
                        {/*                    <div className="modal-debt__head-text">{'Выставить счет-фактуру'}</div>*/}
                        {/*                    <img onClick={() => setModalActive(false)} className="modal-debt__image"*/}
                        {/*                         src={require("../../../img/close-icon.svg").default} alt=""/>*/}
                        {/*                </div>*/}
                        {/*                <div className="modal-debt__body">*/}
                        {/*                    <div className="modal-debt__body-number">{"Номер телефона"}</div>*/}
                        {/*                    <div className="modal-debt__body-numinput">*/}
                        {/*                        /!*<input placeholder="(+994) 000 000 00 00"/>*!/*/}
                        {/*                        <FormattedInputs inputPhone/>*/}
                        {/*                    </div>*/}
                        {/*                    <div onClick={() => setModalPage(2)}  className="modal-debt__body-btn">*/}
                        {/*                        <Button*/}
                        {/*                            buttonText="Далее"*/}
                        {/*                            padding="14px 133px"*/}
                        {/*                            background="#EF5C27"*/}
                        {/*                            borderRadius={15}*/}
                        {/*                            color="#FFFFFF"*/}
                        {/*                            minWidth={367}*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </CustomModal>*/}
                        {/*        :*/}
                        {/*        <CustomModal active={modalActive} setActive={setModalActive}>*/}
                        {/*            <div className="modal-wrap">*/}
                        {/*                <div className="modal-debt">*/}
                        {/*                    <div className="modal-debt__head-text">{'Выставить счет-фактуру'}</div>*/}
                        {/*                    <img onClick={() => setModalActive(false)} className="modal-debt__image"*/}
                        {/*                         src={require("../../../img/close-icon.svg").default} alt=""/>*/}
                        {/*                </div>*/}
                        {/*                <div className="modal-wrap__body">*/}
                        {/*                    <div className="modal-wrap__body-text">*/}
                        {/*                        <div className="modal-wrap__body-text-txt">{"Номер плательщика"}</div>*/}
                        {/*                        <div className="modal-wrap__body-text-txt">{"(+994) 050 111 22 33"}</div>*/}
                        {/*                    </div>*/}
                        {/*                    <Link to="/debts-invoice" className="modal-wrap__body-text-pay">{"Для оплаты услуг E-pul"}</Link>*/}
                        {/*                    <div style={{ border: "1px solid #F1F1F1", margin: "20px 0"}}></div>*/}
                        {/*                    <Link to="/debt-mobile" className="modal-wrap__body-text-pay">{"На пополнения Е-кошелека"}</Link>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </CustomModal>*/}
                        {/*}*/}
                        {/*<div className="debt__body-left-tabs">*/}
                        {/*    <BasicTabs/>*/}
                        {/*</div>*/}
                        <div className="debt__body-left-content">
                            <div className="debt__body-left-content-left">
                                <BasicTabs/>
                            </div>
                            <div className="debt__body-left-content-right">
                                <OtherPayments/>
                            </div>
                        </div>
                    </div>
                    {/*<HistoryDebt/>*/}
                </div>

            </div>
        </LayoutEmpty>
    );
};

export default Debt;
