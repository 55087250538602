import { FC } from "react";
import { useTranslation } from "react-i18next";

const AddNewCardButton: FC<{ isNewCard: boolean, toggleNewCard: () => void, noNewCard?: boolean }> = ({ isNewCard, toggleNewCard, noNewCard }) => {
    const { t } = useTranslation();
    const icon = isNewCard && !noNewCard ? require('../../img/cancel-icon.svg').default : require('../../img/plus-card-icon.svg').default;

    return (
        <>
            <div className="cards-box__image">
                <img className="cards-box__icon" src={icon} alt="icon" />
            </div>
            <div
                className="cards-box__text cards-box__text--orange"
                onClick={toggleNewCard}
            >
                {isNewCard ? `${t("cancel")}` : `${t("addNewCard")}`}
            </div>
        </>
    );
};

export default AddNewCardButton;