import { createAsyncThunk } from "@reduxjs/toolkit";
import { IWalletList } from "../../models/WalletList";
import client, { BASE_URL } from "../../axios";
import { fetchWithTimeout } from "../../utils/query";

const token = localStorage.getItem("token");

export const fetchWalletList = createAsyncThunk(
  "walletList/fetchWalletList",
  async (sid: string | undefined | null, thunkAPI) => {
    try {
      const resWalletList = await client.get<IWalletList>(
        "wallet/list/" + localStorage.getItem("token")
      );
      return resWalletList.data;
    } catch (e) {
      return thunkAPI.rejectWithValue("Не удалось загрузить");
    }
  }
);

