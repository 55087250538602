import React, { useEffect, useState } from "react";
import "./Settings.scss";
import Button from "../../Button/Button";
import AsideSettings from "../AsideSettings/AsideSettings";
import NavigationComponent from "../../CustomsComponents/NavigationComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import {
  requestChangePhone,
  removeAvatar,
  setAvatar,
  updateProfileData,
  updateProfileEmail,
  updateProfilePhone,
} from "../../../redux/actions/profileActions";
import ProfileDetails from "./ProfileDetails";
import Loading from "../../Loading/Loading";
import { useTranslation } from "react-i18next";
import { ProfileServerResponse } from "../../../models/Profile";
import { notify } from "../../../helper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomDeleteModal from "../../CustomDeleteModal/CustomDeleteModal";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";
import SettingsSkeleton from "../../Sceletons/SettingsSkeletons/SettingsSkeleton";
import SettingsLess900Skeleton from "../../Sceletons/SettingsSkeletons/SettingsLess900Skeleton";
import SettingsSkeleton768 from "../../Sceletons/SettingsSkeletons/SettingsSkeleton768";
import CustomModal from "../../ModalComponent/CustomModal/CustomModal";
import OtpCode from "./OtpCode";
import { signOut } from "../../../redux/actions/authActions";

const defaultAvatar = require("../../../img/authIcon.svg").default;
const loading = require("../../../img/loading.mp4");
const add = require("../../../img/addNew.svg").default;
const edit = require("../../../img/edit.svg").default;
const paysBlue = require("../../../img/paysBlue.svg").default;
const auth = require("../../../img/auth.svg").default;
const phone = require("../../../img/phone.svg").default;
const email = require("../../../img/email.svg").default;

interface ISettings {
  title?: string;
}

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [changePhone, setChangePhone] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [encodedAvatarImg, setEncodedAvatarImg] = useState("");
  const [isDeleteAva, setIsDeleteAva] = useState(false);
  const [localeLoadingEmail, setLocaleLoadingEmail] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const { language } = useAppSelector(state => state.changeLangSlice);
  const { profile, userAvatarSource, userAvatar, isLoading } = useAppSelector(
    (state) => state.authReducer
  );
  const sid = localStorage.getItem("token")

  const imgSelectClassname = isModalOpen
    ? "avatar__select avatar__select--open"
    : "avatar__select";

  useEffect(() => {
    const data = {
      sid: localStorage.getItem("token"),
      photo: encodedAvatarImg?.split("base64,")[1],
    };
    if (encodedAvatarImg) {
      dispatch(setAvatar(data));
      setIsModalOpen(false);
    }
  }, [dispatch, encodedAvatarImg]);

  const encodeImageFileAsURL = (file: any, cb: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      cb(reader.result);
    };
  };

  const imageUpload = (event: any, cb: (value: ((prevState: string) => string) | string) => void) => {
    const imgSizeLimit = 5242880;

    if (event.target.files[0].size > imgSizeLimit) {
      notify(`${t("largeSizeImage")}`);
    } else {
      encodeImageFileAsURL(event.target.files[0], cb);
    }
  };

  useEffect(() => {
    nameForm.reset({
      name: profile?.user?.firstName + ' ' + profile?.user?.lastName,
    });
    phoneForm.reset({
      newPhone: profile?.user?.phone,
    });
    emailForm.reset({
      email: profile?.user?.email,
    });
    // dispatch(getUserAvatar());
  }, [profile]);

  const navigate = useNavigate();
  const goBack = () => navigate(`/epay/${language}/home`);

  const nameForm = useForm({
    defaultValues: {
      name: profile?.user?.firstName + ' ' + profile?.user?.lastName,
    },
  });
  const phoneForm = useForm({
    defaultValues: {
      newPhone: profile?.user?.phone || "",
    },
  });
  const emailForm = useForm({
    defaultValues: {
      email: profile?.user?.email || "",
    },
  });

  const OtpMethod = (profile: ProfileServerResponse | null) => {
    if (profile?.user?.preferredOtp === "SMS") {
      return "0";
    } else if (profile?.user?.preferredOtp === "EMAIL") {
      return "1";
    } else return "-999";
  };

  const onSubmitChangeName = (values: { name?: string }) => {
    // const otp = OtpMethod(profile);
    setLocaleLoadingEmail(true);
    dispatch(
      updateProfileData({
        fullName: values.name,
        otp: profile?.user?.preferredOtp,
      })
    ).then((resUpdateName: any) => {
      if (resUpdateName.payload.data.resultCodes === "OK") {
        notify(t("nameChangedSuccessfully"), true);
      } else {
        notify(t("anErrorHasOccurred"), false);
        setLocaleLoadingEmail(false);
      }
    });
    toggleChangeName();
  };

  const onSubmitChangePhone = async (values: { newPhone: string }) => {
    const newPhone = values.newPhone
    if (profile?.user.phone !== newPhone) {
      setNewPhone(newPhone)
      try {
        //@ts-ignore
        const result = await dispatch(requestChangePhone({sid, language, newPhone}))
        console.log('test', result, sid)
        if(result.payload == undefined) {
          setOpenOTPModal(true)
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const closeOTPModal = () => {
    setOpenOTPModal(false);
  };

  const onSubmitChangeEmail = async (values: { email: string }) => {
    toggleChangeEmail();
    setLocaleLoadingEmail(true);
    dispatch(updateProfileEmail({ params: { email: values.email } })).then(
      (resUpdateName: any) => {
        if (resUpdateName.payload.data.resultCodes === "OK") {
          notify(t("successfullyModified"), true);
        } else {
          notify(t("error"), false);
        }
        setLocaleLoadingEmail(false);
      }
    );
  };
  const toggleChangePhone = () => {
    setChangePhone(!changePhone);
  };
  const toggleChangeEmail = () => {
    setChangeEmail(!changeEmail);
  };
  const toggleChangeName = () => {
    setChangeName(!changeName);
  };

  const clickToDeleteModal = () => {
    setIsDeleteAva(true);
  };

  const deleteAvatar = () => {
    dispatch(removeAvatar(localStorage.getItem("token")));
    setIsModalOpen(false);
    setIsDeleteAva(false);
  };

  const userAva = userAvatarSource || defaultAvatar;

  return (
    <>
      <LayoutEmpty goBack={goBack}>
        {
          !profile?.user.firstName ? (
              <>
                <div className="settings__skeleton">
                  <SettingsSkeleton />
                </div>
                <div className="settings__skeleton-900">
                  <SettingsLess900Skeleton />
                </div>
                <div className="settings__skeleton-768">
                  <SettingsSkeleton768 size={768}/>
                </div>
                <div className="settings__skeleton-560">
                  <SettingsSkeleton768 size={560}/>
                </div>
                <div className="settings__skeleton-425">
                  <SettingsSkeleton768 size={425}/>
                </div>
                <div className="settings__skeleton-375">
                  <SettingsSkeleton768 size={375}/>
                </div>
                <div className="settings__skeleton-320">
                  <SettingsSkeleton768 size={320}/>
                </div>

              </>
          ) : (
              <div className="settings">
                <div className="settings-body">
                  {/*<NavigationComponent />*/}
                  <h2 className="settings-body__title">{t("settings")}</h2>
                  <div className="settings-body__wrapper">
                    <div className="settings__avatar-wrapper">
                      <ClickAwayListener onClickAway={() => setIsModalOpen(false)}>
                        <div className="settings__avatar-box avatar-box__border">

                          <div className="settings__image">
                            {userAvatar ? <video className="settings__img" autoPlay={true} loop={true}>
                              <source src={loading} type="video/mp4"/>
                            </video> : <img
                                className="settings__img"
                                src={userAva}
                                alt=""
                            />}

                          </div>
                          <div
                              className="settings__img-add"
                              onClick={() => setIsModalOpen((pr) => !pr)}
                          >
                            <img className="settings__img-btn" src={add} alt="" />
                          </div>
                          <div className={imgSelectClassname}>
                            <div className="avatar__select-option">
                              <label className="avatar__upload-label" htmlFor="avaImg">
                                {t("addAvatar")}
                                <input
                                    className="avatar__upload-input"
                                    id="avaImg"
                                    type="file"
                                    accept="image/png"
                                    name="avaImg"
                                    onChange={(event: any) => {
                                      imageUpload(event, setEncodedAvatarImg);
                                    }}
                                />
                              </label>
                            </div>
                            {userAvatarSource ? (
                                <div
                                    className="avatar__select-option"
                                    onClick={() => clickToDeleteModal()}
                                >
                                  {t("removeAvatar")}
                                </div>
                            ) : null}
                            <div
                                className="avatar__select-cancel"
                                onClick={() => setIsModalOpen((pr) => !pr)}
                            >
                              {t("cancel")}
                            </div>
                          </div>
                        </div>
                      </ClickAwayListener>
                    </div>


                    <div className="editable-form">
                      <form onSubmit={nameForm.handleSubmit(onSubmitChangeName)}>
                        <div className="editable-form__content-fullName">
                          <div className="editable-form__inner">
                            <span className={"editable-form__label"}>
                              {" "}
                              {t("fullName")}
                            </span>
                            <img
                                className={"editable-form__icon"}
                                src={auth}
                                alt=""
                            />
                            <TextField
                                InputLabelProps={{ shrink: false }}
                                color="warning"
                                variant="standard"
                                margin="normal"
                                InputProps={{ disableUnderline: true }}
                                disabled={!changeName}
                                // label={t("fullName")}
                                fullWidth
                                helperText={nameForm.formState.errors.name?.message}
                                error={Boolean(nameForm.formState.errors.name?.message)}
                                {...nameForm.register("name", {
                                  required: `${t("enterFirstLastName")}`,
                                })}
                            />
                          </div>

                          {/*<div>*/}
                          {/*  {localeLoadingEmail && (*/}
                          {/*      <div style={{position: "relative", top: -7, right: 22}}>*/}
                          {/*        <div className="spin"></div>*/}
                          {/*      </div>*/}
                          {/*  )}*/}
                          {/*</div>*/}

                          {!changeName && (
                              <Button
                                  // disabled={localeLoadingEmail}
                                  buttonText={""}
                                  background={"none"}
                                  color={"#EF5C27"}
                                  icon={edit}
                                  onClick={toggleChangeName}
                              />
                          )}
                          {changeName && (
                              <div className="editable-form__btns">
                                <div className="editable-form__btns-wrap">
                                  <button
                                      onClick={toggleChangeName}
                                      className="editable-form__btn product-body__button"
                                  >
                                    {t("cancel")}
                                  </button>
                                  <button className="editable-form__btn product-body__button">
                                    {t("save")}
                                  </button>
                                </div>
                              </div>
                          )}
                        </div>
                      </form>
                      <form onSubmit={phoneForm.handleSubmit(onSubmitChangePhone)}>
                        <div className="editable-form__content">
                          <div className="editable-form__inner">
                  <span className={"editable-form__label"}>
                    {t("phoneNumber")}
                  </span>
                            <img
                                className={"editable-form__icon"}
                                src={phone}
                                alt=""
                            />
                            <TextField
                                color="warning"
                                variant="standard"
                                margin="normal"
                                InputLabelProps={{ shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                disabled={!changePhone}
                                // label={t("phoneNumber")}
                                fullWidth
                                helperText={
                                  phoneForm.formState.errors.newPhone?.message
                                }
                                error={Boolean(
                                    phoneForm.formState.errors.newPhone?.message
                                )}
                                {...phoneForm.register("newPhone", {
                                  required: `${t("enterPhoneNumber")}`,
                                })}
                            />
                          </div>
                          {!changePhone && (
                              <Button
                                  buttonText={""}
                                  background={"none"}
                                  color={"#EF5C27"}
                                  icon={edit}
                                  onClick={toggleChangePhone}
                              />
                          )}
                          {changePhone && (
                              <div className="editable-form__btns">
                                <div className="editable-form__btns-wrap">
                                  <button
                                      onClick={toggleChangePhone}
                                      className="editable-form__btn product-body__button"
                                  >
                                    {t("cancel")}
                                  </button>
                                  <button className="editable-form__btn product-body__button">
                                    {t("save")}
                                  </button>
                                </div>
                              </div>
                          )}
                        </div>
                      </form>
                      <form onSubmit={emailForm.handleSubmit(onSubmitChangeEmail)}>
                        <div className="editable-form__content">
                          <span className={"editable-form__label"}>{t("Email")}</span>
                          <img className={"editable-form__icon"} src={email} alt="" />
                          <TextField
                              color="warning"
                              variant="standard"
                              margin="normal"
                              InputLabelProps={{ shrink: false }}
                              InputProps={{ disableUnderline: true }}
                              disabled={!changeEmail}
                              // label="Email"
                              fullWidth
                              helperText={emailForm.formState.errors.email?.message}
                              error={Boolean(emailForm.formState.errors.email?.message)}
                              {...emailForm.register("email", {
                                required: `${t("specifyEmail")}`,
                              })}
                          />

                          <div>
                            {localeLoadingEmail && (
                                <div
                                    style={{ position: "relative", top: -7, right: 22 }}
                                >
                                  <div className="spin"></div>
                                </div>
                            )}
                          </div>

                          {!changeEmail ? (
                              <Button
                                  buttonText={""}
                                  background={"none"}
                                  color={"#EF5C27"}
                                  icon={edit}
                                  disabled={localeLoadingEmail}
                                  onClick={toggleChangeEmail}
                              />
                          ) : (
                              <div className="editable-form__btns">
                                <div className="editable-form__btns-wrap">
                                  <button
                                      onClick={toggleChangeEmail}
                                      className="editable-form__btn product-body__button"
                                  >
                                    {t("cancel")}
                                  </button>
                                  <button className="editable-form__btn product-body__button">
                                    {t("save")}
                                  </button>
                                </div>
                              </div>
                          )}
                        </div>
                      </form>
                      {/* <span className="warning-message">{t("applyMessage")} </span>{" "}
                      <span className="warning-message">
                        <Button
                          buttonText={""}
                          background={"none"}
                          color={""}
                          icon={paysBlue}
                        />
                          {t("confirmLetter")}
                      </span> */}
                    </div>
                  </div>
                  <ProfileDetails/>
                </div>
                <div className="settings-right">
                  <AsideSettings profile={profile}/>
                </div>
              </div>
          )
        }
      </LayoutEmpty>
      <CustomDeleteModal
        isModalOpen={isDeleteAva}
        setIsModalOpen={() => {
          setIsModalOpen(false);
          setIsDeleteAva((pr) => !pr);
        }}
        titleText={t("avatarDeleteModal")}
        clickToDelete={() => deleteAvatar()}
      />
      {openOTPModal && <CustomModal active={openOTPModal} setActive={setOpenOTPModal}>
        <div className="otp-modal">
          <OtpCode newPhone={newPhone} closeOTPModal={closeOTPModal}/>
        </div>
      </CustomModal>}
    </>
  );
};

export default React.memo(Settings);
