import React, {FC, useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "./Product.scss";
import {useForm} from "react-hook-form";
import CardsBox, { IInstrument } from "../../CardsBox/CardsBox";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { GuestActionCreators } from "../../../redux/reducers/guest/action-creators";
import { fetchBonusGetDetail } from "../../../redux/actions/bonusesAction";
import Loading from "../../Loading/Loading";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {LocaleName} from "../../../models/Categories";
import LayoutEmpty from "../../layouts/LayoutEmpty/LayoutEmpty";

const Product: FC = () => {
    const {t} = useTranslation()
    const {langKey} = useAppSelector(state => state.changeLangSlice)
    const {isAuth, user, profile} = useAppSelector(state => state.authReducer);
    const {language} = useAppSelector(state => state.language);
    const {forms} = useAppSelector(state => state.masterPassReducer);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const [phone, setPhone] = useState<string | undefined>('');
    const [address, setAddress] = useState<string | undefined>('');
    const [email, setEmail] = useState<string | undefined>('');
    const [minutes, setMinutes] = useState<number | undefined>(10);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const item: IBonusGift = location.state.bonusStore
    const goBack = () => navigate(-1);
    const [instrument, setInstrument] = useState(undefined as IInstrument | undefined);
    const {bonus:item, isLoading}  = useAppSelector((state) => state.bonList);
    const params = useParams()

  useEffect(()=>{
    dispatch(fetchBonusGetDetail({id:+params.id!}))
  },[dispatch, params.id])

    const {register, formState: {errors, isValid}} = useForm({
        defaultValues: {
            phone: '',
            password: ''
        },
        mode: 'onChange'
    })

    useEffect(() => {
        if(isAuth) {
            setPhone(profile?.user.phone || '');
            setEmail(profile?.user?.email || '');
        }

    }, [profile?.user?.email, profile?.user.phone])


    const lang = language;


    if(isLoading || item === null) {
        return <Loading/>
    }

    const checkNewCard = () => {
        return true;
    }

    const notify = (msg: string) => {
        toast.error(
            msg,
            {
                position: toast.POSITION.TOP_CENTER,
            }
        );
    }

    const transfer = async () => {

        if(item===null) return

        const serviceId = 1454;
        const fieldsJSON = JSON.stringify({
            fields: [{
                id: '2204',
                name: 'phone',
                value: phone, //TODO: place phone
            }, {
                id: '2205',
                name: 'EMAIL',
                value: email, //TODO: place email
            }, {
                id: '2206',
                name: 'address',
                value: 'from mobile app',
            }, {
                id: '2207',
                name: 'GIFT_ID',
                value: item.id.toString(),
            }, {
                id: '2208',
                name: 'USER_ID',
                value: user?.id?.toString() || '',
            }, {
                id: '2209',
                name: 'LANG',
                value: lang
            }]
        });

        if (!isAuth && instrument?.anonymous) {
            if (!item.price) {
                return;
            }
            const url = await dispatch(GuestActionCreators.doGuestPay(
                serviceId,
                Number(item.price),
                fieldsJSON,
                false,
                '',
                '',
                0,
                `${window.location.origin}/epay/${language}/result/payment/check`,
                '',
                "AZN",
            ));

            if (url) {
                window.location = url;
            }
        } else if (instrument?.wallet) {
            if (!item.price) {
                return;
            }
            const wallet = instrument?.wallet;
            if (Number((wallet?.balance || 0) >= Number(item.price))) {
                forms?.doPayWithWallet(
                    serviceId,
                    'wallet',
                    Number(item.price) / 100,
                    fieldsJSON,
                    false,
                    '',
                    '',
                    '',
                    0,
                    (orderId: string, historyId: number) => {
                        navigate(`/epay/${language}/result/payment?isPayHistory=false&historyId=${historyId}&orderId=${orderId}`);
                    },
                );
            }
        } else if (instrument?.isNewCard) {
            if (!item.price) {
                return;
            }
            if (!checkNewCard() || !instrument.newCardData) {
                return;
            }
            const splt = instrument.newCardData.expiryDate.split('/');
            forms?.payWithNewCard(
                {
                    year: splt[1].trim(), month: splt[0].trim(), cardNumber: instrument.newCardData.cardNumber,
                    cvv: instrument.newCardData.cvc,
                    cardName: instrument.newCardData.cardName,
                },
                {
                    serviceId: serviceId,
                    amount: Number(item.price),
                    fields: fieldsJSON,
                    payAllDebt: false,
                    subName: '',
                    subVal: '',
                    desc: '',
                    lng: lang,
                    selectedGroupId: 0,
                },
                () => {

                },
            );
        } else if (instrument?.bonus) {
            if (!profile?.bonusValue || item.bonusValue > profile?.bonusValue) {
                // TODO: show message insufficient bonuses
                notify('Бонусов недостаточно!')
            }
            else {
                // TODO: place id, phone and minutes
                forms?.giftToUser(
                    item.id,
                    phone,
                    minutes,
                    () => {
                        navigate(`/epay/${language}/bonuses/payment/success/${item.id}`)
                    },
                )
            }
            return;
        } else if (instrument?.pulpal) {
            if (!item.price) {
                return;
            }
            forms?.payWithPulpal(
                item.id,
                {
                    serviceId: serviceId,
                    amount: Number(item.price) / 100,
                    fields: fieldsJSON,
                    payAllDebt: false,
                    subName: '',
                    subVal: '',
                    desc: '',
                    lng: lang,
                    selectedGroupId: 0,
                },
                (uri) => {
                    window.location = uri as any;
                },
            )
            return;
        } else if (instrument?.card) {
            if (!item.price) {
                return;
            }
            forms?.payWithSavedCard(
                instrument?.card.name,
                {
                    serviceId: serviceId,
                    amount: Number(item.price) / 100,
                    fields: fieldsJSON,
                    payAllDebt: false,
                    subName: '',
                    subVal: '',
                    desc: '',
                    lng: lang,
                    selectedGroupId: 0,
                },
                () => {
                },
            );
        }
    }


    return (
        <LayoutEmpty goBack={goBack} navTitle={t("productCatalog")}>
                <div className="bonus-product__body">
                    <div className="bonus-product__image-box">
                        <img className="bonus-product__image" src={`data:image/png;base64, ${item.image}`} alt="bonus image" />
                    </div>
                    <form className="bonus-product__content" onSubmit={(e)=>{
                        e.preventDefault()
                        if(isAuth) {
                            transfer()
                        } else {
                            navigate(`/epay/${language}/sign/in`)
                        }
                        setButtonIsDisabled(true);
                        setTimeout(() => {
                            setButtonIsDisabled(false);
                        }, 2000);
                    }}>
                        <div className="bonus-product__content-title"> {item.name}</div>
                        <div className="bonus-product__form">
                            <div className="bonus-product__form-field">
                                <div className="bonus-product__field-label">{t("yourPhoneNumber")}</div>
                                <div
                                    className="bonus-product__field-input"
                                >
                                    <input
                                        className="bonus-product__input"
                                        type="text"
                                        // defaultValue={profile?.user?.phone || ''}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="bonus-product__form-field">
                                <div className="bonus-product__field-label">Email</div>
                                <div className="bonus-product__field-input">
                                    <input
                                        className="bonus-product__input"
                                        type="email"
                                        // defaultValue={profile?.user?.email || ''}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="bonus-product__form-field">
                                <div className="bonus-product__field-label">{t("address")}</div>
                                <div className="bonus-product__field-input">
                                    <input
                                        className="bonus-product__input"
                                        type="text"
                                        // defaultValue={profile?.user?.phone || ''}
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            {(item.id === 283 || item.id === 284) &&
                                <div className="bonus-product__form-field">
                                    <div className="bonus-product__field-label">{t("minutes")}</div>
                                    <div className="bonus-product__field-input">
                                        <input
                                            className="bonus-product__input"
                                            type="text"
                                            // defaultValue={profile?.user?.phone || ''}
                                            value={minutes}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setMinutes(+e.target.value)
                                                }
                                                if(+e.target.value>100) {
                                                    setMinutes(100)
                                                    return
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                            {isAuth ? <div className="bonus-product__cards-box">
                                <CardsBox
                                    canAnonymous={!isAuth && !!item.price}
                                    canPulpal={!!item.price}
                                    noCards={!item.price || !isAuth}
                                    canWallet={!!item.price && isAuth}
                                    canBonus={isAuth}
                                    noNewCard={!item.price || !isAuth}
                                    setInstrument={(data) => setInstrument(data)}
                                    onlyBonus={true}
                                />
                            </div> :  null}

                            <div className="bonus-product__btn-box">
                                <input className="bonus-product__btn" type="submit" disabled={buttonIsDisabled} value={`${t("pay")}`}/>
                                <div className="bonus-product__bonus-count">
                                    <div className="bonus-product__bonus">
                                        <span className="bonus-product__count">{item?.bonusValue}</span>
                                        <img className="bonus-product__bonus-icon" src={require("../../../img/gift.svg").default} alt="bonus icon" />
                                    </div>
                                    {
                                        item?.price ? <div className="bonus-product__bonus-balance">
                                            <span className="bonus-product__balance">{item?.price / 100}</span>
                                            AZN
                                        </div> : null
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="bonus-product__bonus-info">
                            <div className="bonus-product__info-title">{t("aboutProduct")}</div>
                            <p className="bonus-product__text">{item.information[langKey as keyof LocaleName]}</p>
                            <a className="bonus-product__bonus-link" href={item.url}>{t("productDetails")}</a>
                        </div>
                        <div className="bonus-product__bonus-info">
                            <div className="bonus-product__info-title">{t("howProduct")}</div>
                            <p className="bonus-product__text">{item.description[langKey as keyof LocaleName]}</p>
                        </div>
                    </form>
                </div>
        </LayoutEmpty>

        // <div className="product">
        //     <NavigationComponent onClick={goBack} title={"Каталог товаров"}/>
        //
        //     <div className="product-body">
        //         <div className="product-body__product-img">
        //             <div className="product-body__img-wrap">
        //                 <img src={`data:image/png;base64, ${item.image}`} width={317} height={317} alt=""/>
        //                 {/*<img src={item.image} alt=""/>*/}
        //             </div>
        //         </div>
        //         <div>
        //             <h2 className="product-body__product-name">
        //                 {item.name}
        //             </h2>
        //
        //             {showForm ?
        //                 <ButtonsPayment item={item} showForm={showForm} setShowForm={setShowForm}/>
        //                 :
        //                 <>
        //                     <form action="" className="product-body__form">
        //                         <div className="product-body__form-content">
        //                             <div className="product-body__form-label" >
        //                                 Ваш номер телефона
        //                             </div>
        //                             <TextField color="warning"
        //                                        variant="standard"
        //                                        margin="normal"
        //                                 // label='Номер телефона' fullWidth
        //                                        InputProps={{
        //                                            startAdornment: <InputAdornment
        //                                                position="start">+994</InputAdornment>,
        //                                        }}
        //                                        helperText={errors.phone?.message}
        //                                        error={Boolean(errors.phone?.message)}
        //                                        {...register('phone', {required: 'Укажите номер телефона'})}
        //                             />
        //                         </div>
        //                         <div className="product-body__form-content">
        //                             <div className="product-body__form-label" >
        //                                 Email
        //                             </div>
        //                             <input
        //                                 className="product-body__form-input"
        //                                 type="text"
        //                                 // value={data.email}
        //                             />
        //                         </div>
        //                         <div className="product-body__form-content">
        //                             <div className="product-body__form-label">
        //                                 Address
        //                             </div>
        //                             <input
        //                                 className="product-body__form-input"
        //                                 type="text"
        //                                 // value={data.address}
        //                             />
        //                         </div>
        //                         <CardsBox noCards={!item.price || !isAuth} canWallet={!!item.price && isAuth} canBonus={isAuth} setInstrument={(data) => setInstrument(data)}/>
        //                     </form>
        //                     <ButtonsPayment item={item} showForm={showForm} setShowForm={setShowForm} />
        //                 </>
        //
        //             }
        //             <div className="product-body__border"></div>
        //             <h5>О товаре</h5>
        //             <p className="product-body__context">
        //                 {item.information.valueAz}
        //             </p>
        //
        //             <button className="product-body__button">
        //                 <a className="product-body__link" target="_blank" href={item.url}>Подробнее о продукте</a>
        //             </button>
        //
        //             <h5>Как получить товар</h5>
        //
        //             <p className="product-body__context">
        //                 {item.description.valueAz}
        //             </p>
        //         </div>
        //     </div>
        // </div>
    );
};

export default Product;
