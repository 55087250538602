import React from 'react';
import "./index.scss"
import {useTranslation} from "react-i18next";

const DontInformation = () => {
    const {t} = useTranslation()
    return (
        <div className="dont-information">
            {t("noInformation")}
        </div>
    );
};

export default DontInformation;