import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithTimeout } from "../../utils/query";
import { baseURL } from "../../http";
import client from "../../axios";

const token = localStorage.getItem("token");

export const getW2cService = createAsyncThunk(
  "card2card/getW2cService",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      // const response = await fetchWithTimeout(baseURL + 'categories/service/w2c/' + (sid || token), {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded', 'Payment-Origin': 'Web',
      //     },
      // });
      const response = await client.get(
        "categories/service/w2c/" + (sid || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        if (response.data?.id) {
          return response.data;
        }
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const getCcService = createAsyncThunk(
  "card2card/getCcService",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      // const response = await fetchWithTimeout(baseURL + 'categories/service/currencyConverter/' + (sid || token), {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded', 'Payment-Origin': 'Web',
      //     },
      // });
      const response = await client.get(
        "categories/service/currencyConverter/" + (sid || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        // const json = await response.json();
        if (response.data?.id) {
          return response.data;
        }
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const getRcService = createAsyncThunk(
  "card2card/getRcService",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      // const response = await fetchWithTimeout(baseURL + 'categories/service/refillCard/' + (sid || token), {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded', 'Payment-Origin': 'Web',
      //     },
      // });
      const response = await client.get(
        "categories/service/refillCard/" + (sid || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        // const json = await response.json();
        if (response.data?.id) {
          return response.data;
        }
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);

export const getC2cService = createAsyncThunk(
  "card2card/getC2cService",
  async (sid: string | undefined | null, thunkApi) => {
    try {
      const state = thunkApi.getState() as any;
      const dispatch = thunkApi.dispatch as any;

      const hash = state.categoriesReducer?.serviceHash;

      if (state.card2cardReducer?.service && state.card2cardReducer?.w2c) {
        if (
          hash === state.card2cardReducer?.hash &&
          state.card2cardReducer?.hash
        ) {
          return;
        }
      }

      const s = sid || token;

      if (!(await dispatch(getW2cService(s)))) {
        return;
      }
      if (!(await dispatch(getCcService(s)))) {
        return;
      }
      if (!(await dispatch(getRcService(s)))) {
        return;
      }

      // const response = await fetchWithTimeout(
      //   baseURL + "categories/service/c2c/" + (s || token),
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Payment-Origin": "Web",
      //     },
      //   }
      // );
      const response = await client.get(
        "categories/service/c2c/" + (s || token),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        // const json = await response.json();
        if (response.data?.id) {
          return {
            json: response?.data,
            hash,
          };
        } else {
          if (!state.card2cardReducer.hash) {
            // dispatch(MsgToUserState(MessageType.SomeError));
          }
        }
      } else {
        // dispatch(MsgToUserState(MessageType.SomeError));
      }
    } catch (e) {
      return thunkApi.rejectWithValue("Не удалось загрузить");
    }
  }
);
