import React, {FC} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./index.scss";
import {makeStyles} from "@material-ui/core/styles";
import RegistrationForm from "../RegistrationForm";
import AuthorizationForm from "../AuthorizationForm";
import AsanAuthorizationForm from "../AsanAuthorizationForm";
import {useTranslation} from "react-i18next";
import RegisterForm from "../AuthorizationForm/RegisterForm";
import OtherPayments from "../OtherPayments/OtherPayments";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },

    tabs: {
        "& .MuiTabs-flexContainer ": {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
        },
    },
    "MuiBox-root": {
        backgroundColor: "#EF5C27"
    },
    tabTwo: {
        "&.MuiButtonBase-root.MuiTab-root": {
            // width: "50%",
            width: "31%",
            display: "flex",
            flexDirection: "row",


            "&.MuiButtonBase-root.MuiTab-root svg": {
                marginRight: "10px",
            },

            "&.MuiTab-root:nth-child(1) path": {
                fill: "rgba(136, 136, 136, 1)",
            },
            "&.MuiTab-root:nth-child(2) path": {
                stroke: "rgba(136, 136, 136, 1)",
            },
            "&.MuiTab-root:nth-child(3) path": {
                stroke: "rgba(136, 136, 136, 1)",
            },

            "&.Mui-selected": {
                color: "#EF5C27",
                display: "flex",
                flexDirection: "row",
            },

            "&.Mui-selected:nth-child(1) path": {
                fill: "#EF5C27",
            },
            "&.Mui-selected:nth-child(2) path": {
                stroke: "#EF5C27",
            },
            "&.Mui-selected:nth-child(3) path": {
                stroke: "#EF5C27",
            },
        },
    },
}));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface RegistrationLeftMainProps {
    onlyEntry?: boolean
}

const RegistrationLeftMain: FC<RegistrationLeftMainProps> = ({onlyEntry}) => {
    const {t} = useTranslation()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const url = window.location.pathname;
    const hasCategory = url.includes("category");

    return (
        <div className="no-registration-main">
            <div className="no-registration-main__header">
                <div className="registerTab">
                    <Box sx={{width: '100%'}}>
                        {onlyEntry &&
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    {onlyEntry &&
                                        <Tab className={classes.tabTwo} label={`${t("logIn")}`}  {...a11yProps(1)} />}

                                    {onlyEntry &&
                                        <Tab className={classes.tabTwo}
                                             label={`${t("registration")}`} {...a11yProps(0)} />
                                    }

                                    {onlyEntry &&
                                        <Tab className={classes.tabTwo}
                                             icon={<img className="image-asan" alt="asan-imza" loading={"lazy"}
                                                        src={require("../../img/asan-imza-icon.png")}/>} {...a11yProps(2)}/>
                                    }
                                </Tabs>

                            </Box>
                        }
                        {!onlyEntry &&
                            <TabPanel value={value} index={0}>
                                {hasCategory ?
                                    <OtherPayments/>
                                    :
                                    <RegistrationForm/>
                                }
                            </TabPanel>
                        }
                        {onlyEntry &&
                            <>
                                <TabPanel value={value} index={!onlyEntry ? 2 : 1}>
                                    <RegisterForm/>
                                </TabPanel>
                                <TabPanel value={value} index={!onlyEntry ? 1 : 0}>
                                    <AuthorizationForm/>
                                </TabPanel>
                                <TabPanel value={value} index={!onlyEntry ? 3 : 2}>
                                    <AsanAuthorizationForm/>
                                </TabPanel>
                            </>
                        }

                    </Box>
                </div>
            </div>
        </div>
    );
};

export default RegistrationLeftMain;
