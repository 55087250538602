import React from 'react';
import '../index.scss'

const AdvantHistory = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Ödəmələrin tarixçəsi"}</div>
                <div
                    className="about__right-text">{"Ödəmələrin tarixçəsi (Tarixçə) E-Pul istifadəçisinin məlumat əldə edəcəyi bir bölümdür. Bu bölmədə istifadəçinin E-Pul sistemində qeydiyyata düşdükdən sonra etdiyi bütün ödəmələr haqqında məlumat toplanmışdır. Adi axtarışın heç bir nəticə vermədiyi halda, hər hansı konkret ödəniş barədə məlumat almaq istəyən E-Pul istifadəçisinin ixtiyarında bütün mümkün filtrlərin tətbiqi ilə rahat interfeys vardır. E-Pul istifadəçisi zəruri ödəməni onun edildiyi tarix, şirkətin adı, məbləğ və s. filtrlər üzrə tapa bilər. Axtarılan ödənişə müraciətdən sonra əldə edilən məlumatlar aşağıdakılardan ibarətdir:"}
                </div>
                <div className="about__right-text">
                    {'Keçmiş dövrlər üzrə edilmiş ödənişlərin tarixi;'}
                </div>
                <div className="about__right-text">
                    {'Ödəniş edilmiş xidmətin adı;'}
                </div>
                <div className="about__right-text">
                    {'Ödəniş edilmiş xidmətin məbləği;'}
                </div>
                <div className="about__right-text">
                    {'Tranzaksiya həyata keçirilmiş kart barədə məlumat;'}
                </div>
                <div className="about__right-text">
                    {'Ödənişi almış təşkilatın adı və ya şəxsin məlumatları.'}
                </div>
            </div>
        </div>
    );
};

export default AdvantHistory;