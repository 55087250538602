import LoadingActionTypes from './types';

export const LoadingActionCreators = {
  loadingState: (isLoading) => {
    return {
      type: LoadingActionTypes.LOADING,
      isLoading,
    };
  },

  loadingNext: (isLoading) => {
    return {
      type: LoadingActionTypes.LOADING_NEXT,
      isLoading,
    };
  },

  loadingPaymentState: (isPaymentLoading) => {
    return {
      type: LoadingActionTypes.PAYMENT_LOADING,
      isPaymentLoading,
    };
  },
};
