import { IDebts } from "../../models/Debts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDebts } from "../actions/debtsAction";

interface InitialState {
  sid: string;
  error: string;
  debtsList: IDebts | null;
  isLoading: boolean;
  usdTotalDebts: number;
  aznTotalDebts: number;
}

const initialState: InitialState = {
  sid: "",
  error: "",
  debtsList: null,
  isLoading: false,
  usdTotalDebts: 0,
  aznTotalDebts: 0,
};

export const debtsListSlice = createSlice({
  name: "debtsList",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDebts.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchDebts.fulfilled.type]: (state, action: PayloadAction<IDebts>) => {
      state.isLoading = false;
      state.error = "";
      state.debtsList = action.payload;
      state.usdTotalDebts = action.payload?.items?.paymentListItem.reduce(
        (acc, item) => {
          if (item.currency === "USD") {
            return acc + item?.lastDebt;
          }
          return acc;
        },
        0
      );
      state.aznTotalDebts = action.payload?.items?.paymentListItem.reduce(
        (acc, item) => {
          if (item.currency === "AZN") {
            return acc + item?.lastDebt;
          }
          return acc;
        },
        0
      );
    },
    [fetchDebts.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default debtsListSlice.reducer;
