import React from "react"
import ContentLoader from "react-content-loader"

const SliderSkeleton460 = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={260}
        viewBox="0 0 530 320"
        backgroundColor="#efebeb"
        foregroundColor="#efebeb"
    >
        <rect x="0" y="19" rx="10" ry="10" width="100" height="292" />
        <rect x="123" y="21" rx="10" ry="10" width="265" height="292" />
        <rect x="410" y="20" rx="10" ry="10" width="100" height="292" />
    </ContentLoader>
)

export default SliderSkeleton460