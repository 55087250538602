import React, {FC, useState} from 'react';
import LayoutWithApp from "../../layouts/LayoutWithApp/LayoutWithApp";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import Button from "../../Button/Button";
import './index.scss';
import {commitRecurring, saveAutoPayments, cacheAutoPayments} from "../../../redux/actions/autoPayments";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import RecurringPeriod from "../RecurringPeriod/RecurringPeriod";
import {useAppSelector} from "../../../hooks/redux";
import {notify} from "../../../helper";
import { fetchRecurringPaymentAdd } from '../../../redux/actions/habStorageAction';


interface IProps {

}
const RecurringCreate: FC<IProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const {forms} = useAppSelector(state => state.masterPassReducer)
    const [periods, setPeriods] = useState<any>('')
    const [repeatingType, setRepeatingType] = useState('');
    const [clock, setClock] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const { language } = useAppSelector((state) => state.changeLangSlice);
    const { browserToken } = useAppSelector(state => state.browserToken)
    const sessionId = localStorage.getItem('token')

    const resetProcess = () => {
        navigate(`/epay/${language}/recurring-payments`)
    }

    const saveRecurring = async () => {
        try {
          const { state } = location
          const fieldsJSON = JSON.stringify({ fields: state?.fields })

          if (location?.state?.isCreate) {
            const atClock = moment(new Date()).format('HH:mm')
            const mirrorId = new Date().getTime().toString()
            const { cardInfo } = state
            setIsDisabled(true)

            const { payload: recurringData } = await dispatch(
              fetchRecurringPaymentAdd({
                body: {
                  CardId: cardInfo.id,
                  Amount: (+state.amount)*100,
                  Currency: 'AZN',
                  Password: mirrorId,
                  RedirectUrl: `${
                    process.env.REACT_APP_CLIENT_URL
                  }recurring_payment/commit/${sessionId}?mirrorId=${new Date()
                    .getTime()
                    .toString()}&return=${window.location.host}/epay/${language}/recurring-payments`,
                },
                browserToken,
              }),
            )

            const { recurringPaymentId, redirectUrl } = recurringData

            const data = {
              sessionId,
              serviceId: state?.service?.id,
              bindingId: recurringPaymentId,
              amount: state?.amount,
              maxAllowAmount: state?.service?.maxAmount,
              atClock: clock,
              fields: fieldsJSON,
              payCurrentDebt: false,
              mirrorId,
              selectedGroupId: state?.groupId,
              paymentMode: 2,
              subServiceName: state?.subName,
              subServiceValue: state?.subVal,
              maskedPan: state?.cardInfo?.pan,
              repeatingType: repeatingType,
              periods: periods,
            }

            await dispatch(cacheAutoPayments(data))

            if (redirectUrl) {
              window.location = `https://api.freepay.az${redirectUrl}` as any
            }

          } else {
            console.log('isEdit')
          }
        } catch (error) {

        }
      }

    return(
        <LayoutWithApp goBack={() => navigate(-1)}>
            <div className="recurring-create">
                <div className="recurring-create__title">
                    {t("paymentPeriod")}
                </div>
                <div className="recurring-create__content">
                    <RecurringPeriod
                        cb={(type, period, time) => {
                            setRepeatingType(type)
                            setPeriods(period)
                            setClock(time)
                        }}
                    />
                </div>
                <div className="recurring-create__btns">
                    {isDisabled
                        ? <div style={{ position: "absolute", top: 20, left: 45 }}>
                            <div className="spin"></div>
                        </div>
                        :  null}

                    <Button
                        buttonText={`${t("save")}`}
                        padding="14px 67px"
                        color={isDisabled ? '#a3a3a3' : '#fff'}
                        borderRadius={15}
                        background={isDisabled ? "#ece8e8" : "#EF5C27"}
                        onClick={() => saveRecurring()}
                        disabled={isDisabled}
                    />
                    <Button
                        buttonText={`${t("reset")}`}
                        padding="14px"
                        color="#EF5C27"
                        borderRadius={15}
                        background="#fff"
                        icon={require("../../../img/reset.svg").default}
                        onClick={() => resetProcess()}
                    />
                </div>
            </div>
        </LayoutWithApp>
    )
}

export default RecurringCreate;