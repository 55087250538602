import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import InputFieldComponent from "../MyCardComponent/InputField/InputField";
import AddCheckboxIn from "../CustomsComponents/AddCheckboxIn/AddCheckboxIn";
import { IInstrument } from "./CardsBox";

const NewCardInput: FC<{ setInstrument: (data: IInstrument) => void }> = ({ setInstrument }) => {
    const { t } = useTranslation();
    const [isCardSaved, setIsCardSaved] = useState(false);

    return (
        <div className="cards-box__another-payment">
            <InputFieldComponent
                infoImage
                cardDataCb={(data) => setInstrument({ newCardData: data, isNewCard: true })}
                isCardSaved={isCardSaved}
            />
            <AddCheckboxIn
                height={30}
                width={40}
                onClick={() => {
                    setIsCardSaved((prevState: any) => !prevState);
                    setInstrument({ isNewCard: true });
                }}
                isChecked={isCardSaved}
                fontSize={14}
                title={`${t("wantToAdd")}`}
            />
        </div>
    );
};

export default NewCardInput;