import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import {Link, useParams} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.scss';
import {useAppSelector} from "../../hooks/redux";
import {categoriesInfo} from "../../consts";
import {Category, LocaleName} from "../../models/Categories";
import {useTranslation} from "react-i18next";


function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        ><img  style={{ height: 26, width: 26}} src={require('../../img/arrow-down-slider.svg').default}/></div>
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        ><img style={{ height: 26, width: 26}} src={require('../../img/arrow-up-slider.svg').default}/></div>
    );
}

const OtherPaymentSwiper = () => {
    const params = useParams()
    const {t} = useTranslation()
    const [allServices, setAllServices] = useState<Category[]>([]);
    const { language, langKey } = useAppSelector(state => state.changeLangSlice)
    const {categories, services} = useAppSelector((state) => state.categoriesReducer);

    const data = categories;

    useEffect(() => {
        const popularity = [1, 2, 3, 5, 9];

        const main = data?.filter((item: any) => popularity?.includes(item.id))
        const others = data?.filter((item: any) => !popularity?.includes(item.id))
        const all = main.concat(others)

        if (data) {
            setAllServices(all)
        }
    }, [data, allServices.length]);

    const resultOtherPayment = allServices.filter(item => item.name !== params.category)

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <div className="slider-container">
            <div className="slider-other-payment">{t('otherPayments')}</div>
            <Slider {...settings}>
                {resultOtherPayment.map((item: Category) => {
                    return (
                        <Link
                              to={`/epay/${language}/category/${item.name}`} key={item.id} className="slider-container-item">
                            <div className="slider-container-item__left" style={{ background: categoriesInfo[item.id]?.backGround }}>
                                <img src={categoriesInfo[item.id]?.img} alt="Logo"/>
                            </div>
                            <div className="slider-container-item__right">
                                {item.localName[langKey as keyof LocaleName]}
                            </div>
                        </Link>
                    )
                })}
            </Slider>
        </div>
    );
};

export default OtherPaymentSwiper;
