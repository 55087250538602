import {createAsyncThunk} from "@reduxjs/toolkit";
import {IMessages} from "../../models/Messages";
import client from "../../axios";

const token = localStorage.getItem("token")

export const fetchMessages = createAsyncThunk(
    'messages/fetchMessages',
    async (sid: string | undefined | null, thunkApi) => {
        try {
            let count = 0
            const resMessages = await client.get<IMessages>(`message/list/` + (sid || localStorage.getItem("token")))
            console.log("resMessages", resMessages)
            resMessages.data.list.privateMessage.forEach(message => {
                    if (!message.isReaded) count++
                }
            )
            thunkApi.dispatch(unReadCount(count))
            return resMessages.data
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось загрузить")
        }
    }
)
export const unReadCount = (count: number) => ({
  type: "auth/unReadCount",
  payload: count,
});
