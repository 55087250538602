export const createHubCardData = (newCardData) => {
  const cardData = {
    cardData: {
      Cvv2: newCardData?.cvc,
      ExpiryMonth: newCardData?.expiryDate.substring(0, 3).trim(),
      ExpiryYear: newCardData?.expiryDate.substring(4, 7).trim(),
      Pan: newCardData?.cardNumber,
    },
  };

  if (newCardData?.cardName) {
    cardData.cardData['CardHolder'] = 'e-pul';
  }

  if (newCardData?.cardName) {
    cardData.Name = newCardData.cardName;
  }
  return cardData;
};
