import React from 'react';
import '../index.scss'

const AdvantCartRefister = () => {
    return (
        <div className="about">
            <div className="about__left">
                {/*<img src={require('../../../img/e-walletpull.jpg').default} alt="wallet"/>*/}
            </div>
            <div className="about__right">
                <div className="about__right-head">{"Kartın qeydiyyatdan keçirilməsi"}</div>
                <div
                    className="about__right-text">{"Portalımızda ödənişlərinizi daha rahat, təhlükəsiz və vaxtınıza qənaət edərək həyata keçirə bilərsiniz. Ödənişləri yerinə yetirmək üçün kartınızı qeydiyyatdan keçirirsiniz. Kartın qeydiyyatı üçün HESABLARIM bölməsinə daxil olaraq, kart əlavə olunur. Kartın nömrəsi düzgün və arada boşluq olmadan yazılır:\n"}
                </div>
                <div className="about__right-text">
                    {'1. AD və SOYADINIZ kartın üzərində yazıldığı şəkildə English şirifti ilə böyük hərflərlə yazılır;'}
                </div>
                <div className="about__right-text">
                    {'2. Kartın bitmə ayı, ili və CVV/CVC kodu yazılır.'}
                </div>
                <div className="about__right-text">
                    {'Bu prosesi icra etmək üçün Kart hesabında  0.05 AZN məbləğ bloklaşdırılır və kart əlavə olunduqdan sonra  həmin məbləğ dərhal blokdan çıxarılır.'}
                </div>
                <div className="about__right-text">
                    <a target="_blank" href="/" style={{fontSize: "24px", color: "#F78C40"}}>www.EPUL.az</a>
                </div>
            </div>
        </div>
    );
};

export default AdvantCartRefister;