export const createFavoriteLink = (fav, categories, services, language) => {
  const categoryName = categories?.find(
    (category) => category.id === fav.categoryId,
  )?.name;
  const serviceName = services?.find((service) => service.id === fav.serviceId);
  return `/epay/${language}/payment-add/category/${categoryName}/${serviceName?.friendlyName}`;
};

export const createFavoriteFields = (item) => {
  return item?.fields?.paymentListField.map((item) => ({
    id: item.fieldId.toString(),
    value: item.value,
    name: item.name,
  }));
};
