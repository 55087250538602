import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import './index.scss'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {generateInvitationLinkFetch} from "../../redux/actions/generateInvitationLink";
import LayoutWithSidebar from "../../Components/layouts/LayoutWithSidebar/LayoutWithSidebar";
import InvitationSkeleton from "../../Components/Sceletons/InvitationSkeleton/InvitationSkeleton";

const InviteFriend = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const {data, isLoading} = useAppSelector((state) => state.generateInvitation);
    const { language } = useAppSelector((state) => state.changeLangSlice);

    useEffect(() => {
        dispatch(generateInvitationLinkFetch());
    },[dispatch])

    const goBack = () => navigate(`/epay/${language}/home`)

    const copy = async () => {
        await navigator.clipboard.writeText(data?.invitationLink);
        toast(t("successfullyCopied"));
    };
    return (
        <LayoutWithSidebar goBack={goBack} navTitle={t("home")}>
            <div className="invite-friend__wrap">
                <div className="invite-friend__cont">
                    <div className="invite-friend__wrap-text">
                        <div
                            className="invite-friend__wrap-text-head">{t("InviteFriends")}</div>
                        <div
                            className="invite-friend__wrap-text-txt">{t("inviteFriendsRegister")}</div>
                        <ul className="invite-friend__body">
                            <li className="invite-friend__wrap-text-txt">{t("receive")}</li>
                            <li className="invite-friend__wrap-text-txt">{t("eachPayment")}</li>
                        </ul>

                        <div
                            className="invite-friend__wrap-text-txt">{t("sendInvitation")}</div>
                    </div>
                    <div className="invite-friend__wrap-text-w">
                        <div className="invite-friend__wrap-text-head">{t("howInvite")}</div>
                        <div
                            className="invite-friend__wrap-text-txt">{t("numberFriends")}</div>
                        <div
                            className="invite-friend__wrap-text-txt">{t("invitedFriends")}</div>
                        <div className="invite-friend__wrap-text-txt">{t("usersAccept")}</div>
                    </div>
                </div>

                <div className="invite-friend__cont">
                    <div className="invite-friend__input-wrap">
                        <div className="invite-friend__input-wrap-link">
                            <span className="invite-friend__input-wrap-link">{t("inviteLink")}</span>
                            {isLoading ? ( <InvitationSkeleton /> ) : (<input value={data?.invitationLink} className="invite-friend__inp"/>)}
                            <div className="invite-friend__input-wrap-icon">
                                <img
                                    className="invite-friend__copy"
                                    src={require('../../img/copy.png')}
                                    alt="copy"
                                    title="copy"
                                    onClick={copy}
                                />
                                <a style={{alignSelf: "center"}} href="https://www.facebook.com/" target="_blank"
                                   rel="noreferrer">
                                    <img
                                        className="invite-friend__copy"
                                        src={require('../../img/facebook.png')}
                                        alt="facebook"
                                        title={"facebook"}
                                    />
                                </a>
                                <a style={{alignSelf: "center"}} href="https://twitter.com/?lang=ru" target="_blank"
                                   rel="noreferrer">
                                    <img
                                        className="invite-friend__copy"
                                        src={require('../../img/twitter.png')}
                                        alt="twitter"
                                        title={"twitter"}
                                    />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </LayoutWithSidebar>

    );
};

export default InviteFriend;
